
import React, { useState, useEffect } from 'react';
import { Box, IconButton, Typography, MenuItem, Divider } from '@mui/material';
import '@fontsource/nunito';
import styles from '../../custom/style.module.css';
import headerStyles from './header.module.css';
import FormControl from '@mui/material/FormControl';
import { sideBarLogo, headerBox } from './headerconstants';
import { SelectLocation } from '../../custom/components';
import { appName, pagelist } from 'custom/constants';
import Logo from '../../assets/images/orgaLogo.svg';
import cookies from 'utils/cookies';
import moment from 'moment';
import { grey } from '@mui/material/colors';
import Loader from './loader';

const Header = (props) => {
  const currentPath = window.location.pathname;
  const pageTitle = pagelist.find((page) => page.link === currentPath)?.title;
  const [time, setTime] = useState(cookies.get('timezone') ? moment().tz(cookies.get('timezone')).format('DD MMM YYYY | HH:mm A') : null);


  useEffect(() => {
    const interval = setInterval(() => {
      setTime(cookies.get('timezone') ? moment().tz(cookies.get('timezone')).format('DD MMM YYYY | HH:mm A') : null);
    }, 60000); // Update every minute

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []);

  document.title = pageTitle ? pageTitle.split('|')[0] + ' | ' + appName : appName;
  return (
    <div className={headerStyles.headerSidbarWrapper}>
      <Loader />
      <header className={headerStyles.header}>
        <Box sx={{
          ...headerBox,
          display: 'flex',
          position: 'relative'
        }} className={`${headerStyles.leftHdBox}`}>
          <img src={Logo} alt='logo'
            style={props.open ? sideBarLogo : {
              height: '0',
              opacity: '0',
              position: 'fixed',
              left: '0'
            }}
          />
          <IconButton disableRipple aria-label='menu' sx={{ height: '100%' }} onClick={props.handleDrawerOpen} size='large' className={`${headerStyles.printSideLogo}`}>
            <img src={Logo} alt='logo' className={`${headerStyles.sideLogo}`} />
          </IconButton>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}>
            <Typography variant='h6' component='h6' fontSize={'1.1rem'} lineHeight='normal' textTransform='uppercase'>
              {appName}
            </Typography>
            <Typography variant='caption' component='span' lineHeight='normal' sx={{ textShadow: '0 0 white' }}>
              {pageTitle}
            </Typography>
          </Box>
          <Typography variant='caption' component={'p'} marginBottom={0} marginTop={'4px'}
            color={grey[300]} fontSize={'8.9px'} fontWeight={800}
            className={headerStyles.showTime}
          >
            {time}
          </Typography>
        </Box>
        <Box sx={{ flex: 'auto' }} className={headerStyles.midHdBox}></Box>
        <Box sx={headerBox} className={headerStyles.rgtHdBox}>
          <div className={headerStyles.leftHdDiv}>
            <p className={headerStyles.leftPTag}>
              {cookies.get('company_name')}
            </p>
            <FormControl
              fullWidth
              className={headerStyles.locationSelector}
            >
              <SelectLocation
                displayEmpty
                variant='standard'               
                value={props.station}
                onChange={(e) => props.handleStation(e)}
                inputProps={{ 'aria-label': 'Without label' }}
                classes={{
                  select: headerStyles.locSelect,
                  iconStandard: headerStyles.locIconStandard,
                }}
                className={`${styles.mauto} ${headerStyles.hidePrint}`}
              >
                {props.stations && props.stations.map((stn) => (
                  <MenuItem
                    key={stn.id}
                    value={stn.id}
                    className={headerStyles.selectOption}
                  >
                    {stn.code}
                  </MenuItem>
                ))}
              </SelectLocation>
            </FormControl>
            <span className={headerStyles.showPrint}>{cookies.get('station_code')}</span>
          </div>
        </Box>
      </header>
      <Divider className={headerStyles.showPrint} />
    </div>
  );
};

export default Header;
