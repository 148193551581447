import React from 'react';
import DocArchive from './DocArchive';
// import http from 'utils/http';
// import { connect } from 'react-redux';
// import { setStationAction } from 'store/actions/companyAction';
class AdminContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.match.params.tab || 'awbs' };
  }
  componentDidMount() {
    // http.get('/station').then(({ data }) => {
    //   this.props.setStations(data.data);
    // });
  }

  handleChange = (newValue) => {
    if (this.props.match.url.includes('doc_archive')) {
      this.props.history.replace(`${newValue}`);
    } else {
      this.props.history.replace(`doc_archive/${newValue}`);
    }
    this.setState({ value: newValue });
  };

  render() {
    return (
      <>
        <DocArchive handleChange={this.handleChange} value={this.state.value} />
      </>
    );
  }
}

// const mapDispatchToProps = (dispatch) => {
//   return { setStations: (stations) => dispatch(setStationAction(stations)) };
// };

export default AdminContainer;
// export default connect(null, mapDispatchToProps)(AdminContainer);
