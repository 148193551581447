import React from 'react';
// import ReactDOM from 'react-dom';
// import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import styles from '../../../../../../custom/style.module.css';
import MyDocument from './Doc';
import { Font, PDFDownloadLink } from '@react-pdf/renderer';
import { CustomButton } from 'custom/components';
import { DownloadIcon } from 'custom/constants';

const getFilename = (file_name, period, billingFields) => {
  if(!billingFields?.exclude_disc){
    file_name = file_name+ 'DIS';
  }
  if(period === 'first'){
    file_name = file_name + '-01';
  } else if(period === 'second'){  
    file_name = file_name + '-02';
  }
  return file_name;
};

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf', },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
      fontWeight: 600 
    }
  ]
});
const Invoice = (props) => {
  console.log('invoice',props);
  return (<>
    <PDFDownloadLink document={<MyDocument
      invoice_no = { getFilename(props.invoice_info.file_name, props.searchFields.period, props.billingFields ) || 'invoice.pdf'}
      flightdata={props.flightdata}
      daylogdata= {props.daylogdata}
      monthlogdata= {props.monthlogdata}
      invoiceData={props.invoiceData}
      dayContractData={props.dayContractData}
      monthContractData={props.monthContractData}
      invoice_info={props.invoice_info}
      airlineCode={props.airlineCode}
      cont_item={props.cont_item}
      searchFields={props.searchFields}
      billingFields={props.billingFields}
      oneOff={props.oneOff}
    />} fileName={ getFilename(props.invoice_info.file_name, props.searchFields.period, props.billingFields ) || 'invoice.pdf'} // handle for cycles.
    className={styles.linkNoUnderline}>

      <CustomButton variant='contained' size='small' className={`${styles.mlauto} ${styles.dBlock} ${styles.mr2}`} disabled={props.progress < 100}>
        <DownloadIcon className={`${styles.whiteSvg} ${styles.mr1} ${styles.btnIcons}`} /> {props.title}</CustomButton>
    </PDFDownloadLink>
  </>);
};

// ReactDOM.render(<App />, document.getElementById('root'));
export default Invoice;