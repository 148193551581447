import React from 'react';
import { Box, Typography, TextField, Stack, InputAdornment, Checkbox } from '@mui/material';
import orgaLogo from '../../../assets/images/orgaLogoFull.png';
import { CustomButton, CustomCheckbox } from '../../../custom/components';
import loginStyles from './login.module.css';
import styles from '../../../custom/style.module.css';
import { Apartment, Lock, PersonRounded } from '@mui/icons-material';
import { appName } from 'custom/constants';

const Login = (props) => {
  return(
    <Box className={loginStyles.loginWrapper}>
      <img src={orgaLogo} alt='orgadynamics Logo' className={`${loginStyles.loginLogo} ${styles.mb3}`} />
      <Stack spacing={3} alignItems='center' className={`${styles.w100} ${styles.mb4}`}>
        <Typography variant='h5' component='span' lineHeight='normal' className={`${loginStyles.appName} ${styles.mb3}`} color='text.secondary'>{appName}</Typography>
        {/* <Typography variant='h6' component='span' lineHeight='normal'>LOGIN</Typography> */}
      </Stack>
      
      <form onSubmit={props.onSubmit} className={styles.w100}>
        <Stack spacing={5} alignItems='center'>
          {!props.company ? 
            <TextField
              disabled={props.company}
              hiddenLabel
              id='company'
              variant='standard'
              className={styles.w100}
              placeholder='Company Name'
              type='text'
              size='small'
              InputLabelProps={{ shrink: false }}
              classes={{ root: loginStyles.textfield }}
              onChange={props.onChange}
              name='company'
              value={props.fields.company}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start' className={ loginStyles.materialIconContainer }>
                    <Apartment color='primary' className={ loginStyles.materialIcon } />
                  </InputAdornment>
                ),
              }}
              error={props.errors.company}
              helperText={props.errors.company}
            />
            : null} 
          <TextField
            hiddenLabel
            id='username'
            variant='standard'
            className={styles.w100}
            placeholder='User Name'
            type='text'
            size='small'
            InputLabelProps={{ shrink: false }}
            classes={{ root: loginStyles.textfield }}
            onChange={props.onChange}
            name='username'
            value={props.fields.username}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start' className={ loginStyles.materialIconContainer }>
                  <PersonRounded color='primary' className={ loginStyles.materialIcon } />
                </InputAdornment>
              ),
            }}
            error={props.errors.username}
            helperText={props.errors.username}
          />
          <TextField
            hiddenLabel
            id='password'
            variant='standard'
            className={styles.w100}
            placeholder='Password'
            type='password'
            size='small'
            InputLabelProps={{ shrink: false }}
            classes={{ root: loginStyles.textfield }}
            onChange={props.onChange}
            name='password'
            value={props.fields.password}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start' className={ loginStyles.materialIconContainer }>
                  <Lock color='primary' className={ loginStyles.materialIcon } />
                </InputAdornment>
              ),
            }}
            error={props.errors.password}
            helperText={props.errors.password}
          />
          {props.fields.company?.toUpperCase() == 'TRISTAR' && <CustomCheckbox control={<Checkbox />} label='Trypod Credential' name='trypod' checked={props.fields.trypod} onChange={props.onChange} />}
          <CustomButton variant='contained' className={`${styles.w100} ${loginStyles.submitBtn}`} type='submit' disabled={props.submit} >Submit</CustomButton>
          <Typography variant='caption' component='span' lineHeight='normal' className={styles.link} onClick={()=>{
            window.location.href = '/reset-password';
          }}>Forgot Password</Typography>
        </Stack>
      </form>
    </Box>
  );
};

export default Login;