/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';
import { Grid, RadioGroup, Stack, Radio, FormControl, MenuItem } from '@mui/material';
import styles from 'custom/style.module.css';
import docStyles from '../docs.module.css';
import { CustomTextField, CustomInputLabel, StyledCard, InputProps, CustomRadio, CustomSelect, SelectInputProps, CustomDatePicker } from 'custom/components';
import { countryCode } from 'custom/constants';

const PartyDoc = (props) => {
  const { fields, onChange } = props;

  const AWB = <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={styles.w100}>
    <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
      <CustomInputLabel htmlFor='awb' aria-label='awb'>AWB #</CustomInputLabel>
      <CustomTextField
        {...InputProps}
        id='awb'
        fullWidth
        placeholder='AWB #'
        name='awb'
        InputLabelProps={{ shrink: false }}
      />
    </Stack>
  </Grid>;

  const Flight = <Fragment>
    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
        <CustomInputLabel aria-label='airline'>Airline</CustomInputLabel>
        <FormControl variant='standard' fullWidth>
          <CustomSelect
            {...SelectInputProps}
            id='airline'
            name='airlineId'
          // onChange={(e) => props.handleChangeForm(e, 'airlineId')}
          // value={Number(props.formFields.airlineId) || ''}
          >
            {/* {props.airlines?.map((airline, i) => (
                <MenuItem key={i} value={airline.id}>{airline.code}</MenuItem>
              ))} */}
          </CustomSelect>
        </FormControl>
      </Stack>
    </Grid>
    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
        <CustomInputLabel aria-label='flight_no' htmlFor='flight_no'>Flight Number</CustomInputLabel>
        <CustomTextField
          {...InputProps}
          id='flight_no'
          fullWidth
          placeholder='Flight Number'
          type='text'
          name='flight_no'
        />
      </Stack>
    </Grid>
    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
        <CustomInputLabel aria-label=''>Flight Date</CustomInputLabel>
        <CustomDatePicker
          slotProps={{
            textField: {
              ...InputProps,
              fullWidth: true,
              placeholder: 'Flight Date'
            },
          }}
          format='DD MMM YYYY'
          name='flight_date'
          className={styles.w100}
        />
      </Stack>
    </Grid>
    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
        <CustomInputLabel aria-label='origin' htmlFor='origin'>Origin</CustomInputLabel>
        <CustomTextField
          {...InputProps}
          id='origin'
          fullWidth
          placeholder='Origin'
          type='text'
          name='org'
        />
      </Stack>
    </Grid>

    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
        <CustomInputLabel aria-label='destination' htmlFor='destination'>Destination</CustomInputLabel>
        <CustomTextField
          {...InputProps}
          id='destination'
          fullWidth
          placeholder='Destination'
          type='text'
          name='dest'
        />
      </Stack>
    </Grid>
  </Fragment>;

  const OtherDoc = <Fragment>
    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
        <CustomInputLabel htmlFor='attachto-label' aria-label='type'>Attach To</CustomInputLabel>
        <FormControl variant='standard' fullWidth>
          <CustomSelect
            {...SelectInputProps}
            labelId='attachto-label'
            id=''
            name='attachTo'
            value={props.attachTo}
            onChange={(e) => props.onhandleChange(e)}
          >
            <MenuItem value='AWB'>Airwaybill</MenuItem>
            <MenuItem value='FFM'>Flight Manifest</MenuItem>
            <MenuItem value='OTHER'>Do not attach: File as Other</MenuItem>
          </CustomSelect>
        </FormControl>
      </Stack>
    </Grid>
  </Fragment>;

  const AttachOtherDoc = <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
    <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
      <CustomInputLabel htmlFor='ref_id' aria-label='Refrence Id'>Reference # (optional)</CustomInputLabel>
      <CustomTextField
        {...InputProps}
        id='ref_id'
        fullWidth
        placeholder='Reference #'
        type='text'
        name='ref_id'
        inputProps={{ 'aria-label': 'Reference-id' }}
      />
    </Stack>
  </Grid>;


  return (
    <div>
      <StyledCard>
        <Grid container spacing={2.5} flexWrap='wrap'>
          <Grid item xs={12} md={12} className={styles.w100}>
            <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
              <CustomInputLabel htmlFor='name' aria-label='name'>Name of 3rd Party</CustomInputLabel>
              <CustomTextField
                {...InputProps}
                id='name'
                fullWidth
                placeholder='Name of 3rd Party'
                name='party_name'
                value={fields.party_name}
                onChange={onChange}
                InputLabelProps={{ shrink: false }}
              />
            </Stack>
          </Grid>
          {fields?.request &&
          <Grid item xs={12} md={12} className={styles.w100}>
            <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
              <CustomInputLabel htmlFor='name' aria-label='name'>Name of Requester</CustomInputLabel>
              <CustomTextField
                {...InputProps}
                id='name'
                fullWidth
                placeholder='Name of Requester'
                name='requester_name'
                value={fields.added_by ? `${fields.added_by.first_name} ${fields.added_by.last_name}` : ''}
                onChange={onChange}
                InputLabelProps={{ shrink: false }}
                disabled={true}
              />
            </Stack>
          </Grid>}

          <Grid item xs={12} md={12} className={styles.w100}>
            <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
              <CustomInputLabel htmlFor='type' aria-label='type' className={`${styles.mb1}`}>Document Type</CustomInputLabel>
              <FormControl variant='standard' fullWidth>
                <CustomSelect
                  {...SelectInputProps}
                  labelId='doc-type-label'
                  id=''
                  name='type'
                  value={fields.type}
                  onChange={onChange}
                >
                  <MenuItem value='AWB'>Airwaybill</MenuItem>
                  <MenuItem value='FFM'>Flight Manifest</MenuItem>
                  <MenuItem value='OTHER'>Other</MenuItem>
                </CustomSelect>
              </FormControl>
            </Stack>
          </Grid>

          {/* { returnForm('addDoc' , props.addDocType)}

                      { props.addDocType === 'OTHER' && returnForm('attachTo' , props.attachTo)} */}

          <Grid item xs={12} md={12}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} className={`${styles.mb1}`}>
              <CustomInputLabel id='sms_email'>Contact Information</CustomInputLabel>
              <RadioGroup
                aria-labelledby='' name='contact_type' onChange={onChange} defaultValue='sms' value={fields.contact_type} row className={`${docStyles.docRadioWrapper} ${docStyles.addTextLabel}${styles.ml0}`}>
                <CustomRadio control={<Radio size='small' className={styles.py0} />} value='sms' label='SMS' />
                <CustomRadio control={<Radio size='small' className={styles.py0} />} value='email' label='E-mail' className={`${styles.mr0}`} />
              </RadioGroup>
            </Stack>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={styles.w100}>
                <Stack direction={'row'} spacing={1}>
                  {fields.contact_type === 'sms' && 
                    <CustomSelect
                      {...SelectInputProps}
                      id='country_code'
                      labelId='country_code_label'
                      onChange={onChange}
                      name='country_code'
                      value={!fields.country_code ? '+1' : fields.country_code}
                      renderValue={(selected) => {
                        if (!selected) {
                          return 'Select a Country Code';
                        }
                        return selected ;
                      }}
                    >
                      <MenuItem value='placeholder' disabled>Select a Country Code</MenuItem>
                      {countryCode.map((code) => (
                        <MenuItem key={code.code} value={code.dial_code}>
                          {code.name} ({code.dial_code})
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  }
                  <CustomTextField
                    {...InputProps}
                    id='sms_email'
                    fullWidth
                    placeholder={fields.contact_type === 'sms' ? 'SMS' : 'Email'}
                    name='contact'
                    value={fields.contact}
                    onChange={onChange}
                    inputProps={{ 'aria-label': 'SMS/Email' }}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12} lg={12} xl={12} className={styles.w100}>
            <Stack direction='row' justifyContent='space-between' alignItems='flex-start' useFlexGap flexWrap='wrap'>
              <CustomInputLabel htmlFor='notes' aria-label='notes'>Notes</CustomInputLabel>
              <CustomTextField
                {...InputProps}
                id='notes'
                multiline
                fullWidth
                placeholder='Notes'
                type='text'
                name='note'
                value={fields.note}
                onChange={onChange}
                minRows={3}
              />
            </Stack>
          </Grid>
        </Grid>
      </StyledCard>
    </div>
  );
};
/* eslint-enable no-unused-vars */
export default PartyDoc;