import React from 'react';
import { Dialog } from '@mui/material';
import styles from '../../../../../custom/style.module.css';
import { CustomButton, CustomTextField, CustomDialogTitle, CustomDialogAction, CustomInputLabel, StyledCard, InputProps, CustomDialogContent } from '../../../../../custom/components';
import { CloseIconBtn } from 'custom/constants';

const AddMethodsModal = (props) => {
  const { action, addSpecials, editSpecials, onChangeSpecials } = props;
  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={props.modal === 'specials'} onClose={props.onClose} maxWidth={'sm'} fullWidth>
        <CustomDialogTitle id='form-dialog-title'>
          {action == 'add' ? 'Add' : 'Edit'} Specials
          <CloseIconBtn onClick={props.onClose} />
        </CustomDialogTitle>
        <form className={styles.w100} onSubmit={action == 'add' ? addSpecials : editSpecials} onReset={props.onClose}>
          <CustomDialogContent>
            <StyledCard>
              <div>
                <CustomInputLabel htmlFor='spl_code'>Special Code</CustomInputLabel>
                <CustomTextField
                  {...InputProps}
                  fullWidth
                  id='spl_code'
                  className={`${styles.mb3}`}
                  placeholder='Special Code'
                  type='text'
                  name='name'
                  onChange={onChangeSpecials}
                  value={props.specialsField.name?.toUpperCase() || ''}
                  error={props.specialsErrors.name}
                  helperText={props.specialsErrors.name}
                  autoFocus
                />
              </div> 
            </StyledCard>
          </CustomDialogContent>
          <CustomDialogAction className={`${styles.fSpaceBtw}`}>
            <CustomButton variant='contained' type='reset'> Close</CustomButton>
            <CustomButton variant='contained' type='submit'>{action == 'add' ? 'Submit' : 'Update'}</CustomButton>
          </CustomDialogAction>
        </form>
      </Dialog>
    </div>
  );
};
export default AddMethodsModal;