import React, { createRef } from 'react';
import Billing from './Billing';
import { contractType, pageHandler } from 'custom/constants';
import http from 'utils/http';
import cookies from 'utils/cookies';
import moment from 'moment';
import events from 'utils/evemts';
import { toast } from 'react-toastify';

const timezone = cookies.get('timezone') || null;
const station_code = cookies.get('station_code') || null;
class BillingContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.match.params.tab || 'flight_load_info',
      contract_Type: null,
      // statType: null,
      cont_item: null,
      airlineCode: null,
      userContractTypes: [],

      airlines: [],
      searchFields: {
        // from: moment().tz(timezone).format('YYYY-MM-DD'),
        // to: moment().add(1,'days').tz(timezone).format('YYYY-MM-DD'),
        month: moment().format('YYYY-MM'),
        period: 'month',
        airline: null,
      },
      pageFilters: [],
      loadInfo: 'contract_data',
    };
    this.eventSource = null;
    this.flightLoadInfoRef = createRef();
    this.serviceLogRef = createRef();
  }

  handleContractType = (e) => {
    let cont_item = contractType[e.target.value];
    this.setState({ 
      contract_Type: e.target.value,
      cont_item: cont_item,
    },()=>{
      this.setPageFilter();
    });
  };

  handleSubToggle = (e) => {
    this.setState({ loadInfo: e.target.value });
  };

  componentDidMount() {
    this.eventSource = events('billing', null);
    console.log('ServiceLogContainer.js componentDidMount()');
    const currentPath = window.location.pathname;
    pageHandler(currentPath, cookies.get('role'));
    if(timezone){
      this.setState({
        searchFields: {
          // from: moment().tz(timezone).format('YYYY-MM-DD'),
          // to: moment().add(1,'days').tz(timezone).format('YYYY-MM-DD'),
          month: moment().tz(timezone).format('YYYY-MM'),
          period: 'month',
          airline: null,
        }
      },()=>{
        this.fetchUser();
        this.fetchAirlines();
      });
    }
    this.eventSource.onmessage = ({ data }) => {
      let dataObj = data ? JSON.parse(data): null; 
      if (dataObj?.type == 'billing') {
        let startDate = moment(this.state.searchFields.month).startOf('month').format('YYYY-MM-DD');
        let endDate = moment(this.state.searchFields.month).endOf('month').format('YYYY-MM-DD');
        let startdiff = moment(dataObj?.data?.from).diff(startDate, 'days');
        let enddiff = moment(dataObj?.data?.to).diff(endDate, 'days');
        if( startdiff >= 0 && enddiff <= 0 
        && this.state.searchFields.airline == dataObj?.data?.airlineId 
        && this.state.cont_item === dataObj?.data?.ctype
        ){
          toast.success('Billing Data has been updated!');
          if (this.flightLoadInfoRef.current) {
            this.flightLoadInfoRef.current.fetchInvoiceData();
          } else if (this.serviceLogRef.current) {
            this.serviceLogRef.current.fetchInvoiceData();
          }
        }
      }
    };
  }

  fetchUser = () => {
    http.get(`/user?id=${cookies.get('user')}`).then(({ data }) => {
      if(data && data.data && data.data.length > 0){
        // let statType = null;
        let cont_item = null;
        let contract_Type = null;
        let userContractTypes = data.data[0]?.contract_types || [];
        //get first key and value from contractType
        let firstRecord = Object.entries(contractType).find(([ , value]) => userContractTypes.includes(value));
        if (firstRecord) {
          // statType = 0;
          cont_item = firstRecord[1];
          contract_Type = firstRecord[0];
        }
        this.setState({ 
          userContractTypes : data.data[0]?.contract_types || [],
          // statType: statType,
          cont_item: cont_item,
          contract_Type: contract_Type,
        });
      }
    });
  };

  fetchAirlines = () => {
    http.get(`/airline?active=${true}`)
      .then((response) => {
        this.setState({
          airlines: response.data.data,
          searchFields: {
            ...this.state.searchFields,
            airline: response.data.data[0].id,
          },
          airlineCode: response.data.data[0].code
        },
        ()=> this.fetchPageFilter()
        // () => {
        //   if (this.state.loadInfo === 'statistics') {
        //     this.fetchProducts();
        //   } else if (this.state.loadInfo === 'contract_data') {
        //     this.fetchContractData();
        //   }
        // }
        );
      }
      );
  };

  setPageFilter = () => {
    let data = {
      searchFields: this.state.searchFields,
      cont_item: this.state.cont_item,
      contract_Type: this.state.contract_Type,
      airlineCode: this.state.airlineCode,
      station: station_code,
    };
    let pageFilters = this.state.pageFilters;
    let billing_page_filter = pageFilters?.billing_page_filter || [];

    // find index
    let index = billing_page_filter?.findIndex((rec) => rec.station === station_code);
    if (index > -1) {
      // update
      billing_page_filter[index] = data;
    } else {
      // add
      billing_page_filter.push(data);
    }
    if(!pageFilters.billing_page_filter){
      pageFilters.billing_page_filter = billing_page_filter;
    }
    http.put(`/pagefilter/${cookies.get('user')}`, pageFilters, { headers: { ntoast: true } })
      .then(() => {
        // Do nothing
      });

  };
  fetchPageFilter = () => {
    http.get(`/pagefilter/${cookies.get('user')}`)
      .then(({ data }) => {
        let pageFilters = data.data?.page_filters || {};
        // get pagefilter based on station_code
        let pageFilter = {};
        if (pageFilters && pageFilters.billing_page_filter && pageFilters.billing_page_filter.length > 0) {
          pageFilters.billing_page_filter.map((rec) => {
            if (rec.station === station_code) {
              pageFilter = rec;
            }
          });
        }
        this.setState({ 
          pageFilters: pageFilters,
          searchFields: pageFilter.searchFields || this.state.searchFields,
          cont_item: pageFilter.cont_item || this.state.cont_item,
          airlineCode: pageFilter.airlineCode || this.state.airlineCode,
          contract_Type: pageFilter.contract_Type || this.state.contract_Type,
        });
      });
  };

  handleChange = (newValue) => {
    if (this.props.match.url.includes('billing')) {
      this.props.history.replace(`${newValue}`);
    } else {
      this.props.history.replace(`billing/${newValue}`);
    }
    this.setState({ value: newValue });
  };


  // handleContractType = (e) => {
  //   let cont_item = contractType[e.target.value];
  //   this.setState({ 
  //     contract_Type: e.target.value,
  //     cont_item: cont_item,
  //   });
  // };
  onFilterMain = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState((prev) => ({
      searchFields: {
        ...prev.searchFields,
        [name]: value
      }
    }), () => this.setPageFilter());
    if (name === 'airline') {
      let airlineCode = this.state.airlines.find(airline => airline.id === value).code;
      this.setState({ airlineCode: airlineCode },()=>this.setPageFilter());
    }
  };


  render() {
    return (
      <>
        <Billing
          ref={this.flightLoadInfoRef}
          ref2={this.serviceLogRef}
          handleChange={this.handleChange} 
          value={this.state.value} 
          handleContractType={this.handleContractType}
          // contractType={this.state.contractType}
          // contract_Type={this.state.contract_Type}
          cont_item={this.state.cont_item}
          userContractTypes={this.state.userContractTypes}
          onFilterMain={this.onFilterMain}
          searchFields={this.state.searchFields}
          airlines={this.state.airlines}
          airlineCode={this.state.airlineCode}
          contract_Type={this.state.contract_Type}
          handleSubToggle={this.handleSubToggle}
          loadInfo={this.state.loadInfo}
        />
      </>
    );
  }
}

export default BillingContainer;
