import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const textColor = '#393939';
const borderColor = 'lightgrey';

const styles = StyleSheet.create({
  headerContainer: { marginTop: 10 },
  label: {
    fontSize: 10,
    paddingBottom: 3,
    lineHeight: 'normal',
  },
  data: {
    fontSize: 10,
    paddingBottom: 3,
    lineHeight: 'normal',
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 20,
    borderWidth: 1,
    borderColor: 'lightgrey',
    // borderColor: borderColor,
  },
  container: {
    flexDirection: 'row',
    borderBottomColor: 'lightgrey',
    backgroundColor: '#eaeaea',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 20,
    textAlign: 'center',
    // fontStyle: 'bold',
    flexGrow: 1,
    // color: 'black'
  },
  row: {
    flexDirection: 'row',
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: 'center',
    fontStyle: 'bold',
  },
  description: {
    width: '20%',
    // width: '20%',
    textAlign: 'left',
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
    padding: 4,
    paddingLeft: 8,
    fontSize: 9,
    color: textColor,
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  inlinedesc: {
    color: 'grey',
    fontSize: 8,
  },
  qty: {
    width: '15%',
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
    fontSize: 9,
    color: textColor,

    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  rate: {
    width: '15%',
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
    paddingRight: 8,
    paddingLeft: 14,
    fontSize: 9,
    color: textColor,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  amount: {
    width: '20%',
    paddingRight: 8,
    fontSize: 9,
    color: textColor,
  },
  pcsHead: {
    width: '10%',
    paddingRight: 8,
    fontSize: 9,
    color: textColor,
  },
  pcs: {
    width: '10%',
    fontSize: 9,
    padding: 4,
    paddingLeft: 8,

    color: textColor,
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  inlinedescWrapper: { flexDirection: 'row', },
  divider: {
    color: 'grey',
    fontSize: 8,
    margin: '0 4px' 
  },
  wrapSPL: { flexWrap: 'wrap', },
});

const Awbs = (props) => {
  const { manifestData } = props;

  return (
    <View style={styles.tableContainer}>
      <View style={styles.container}>
        <Text style={styles.amount}>Awb #</Text>
        <Text style={styles.amount}>Org-Dest</Text>
        <Text style={styles.pcsHead}>Pcs</Text>
        <Text style={styles.amount}>Remark</Text>
        <Text style={styles.pcsHead}>Prod</Text>
        <Text style={styles.amount}>SPL Code</Text>
      </View>
      {manifestData && manifestData?.map((uld) => {
        return uld?.flight_awbs?.map((awb) => {
          if (!awb?.awbId) {
            return (
              <View style={styles.row} key={awb.id}>
                <Text style={styles.description}>{awb.awb_no}</Text>
                <Text style={styles.description}>{awb.org + '-' + awb.dest}</Text>
                <Text style={styles.pcs}>{awb.pcs}</Text>
                <Text style={styles.description}>{awb.remark}</Text>
                <Text style={styles.pcs}>{awb?.product ? awb?.product?.name : '-'}</Text>
                <Text style={[styles.description, styles.wrapSPL]}>{awb?.spl_code ? awb?.spl_code.length > 0 && awb?.spl_code.split(',').join(', ') : '-'}</Text>
              </View>);
          }
        });
      })}
    </View>
  );
};
  
export default Awbs;