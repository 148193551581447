import React, { forwardRef } from 'react';
import { connect } from 'react-redux';
import { setDeleteAction } from 'store/actions/deleteAction';
import cookies from 'utils/cookies';
import http from 'utils/http';
import moment from 'moment-timezone';
import cloneDeep from 'lodash/cloneDeep';
import styles from '../../../../../custom/style.module.css';
import fltLoadInfoStyles from './fltloadinfo.module.css';
import { contractType, discrepancyType, pageHandler } from 'custom/constants';
import FlightLoadInfo from './FlightLoadInfo';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';

// const station = cookies.get('station_code') || null;
// const timezone = cookies.get('timezone') || null;

class FlightLoadInfoContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // contract_Type: null,
      // cont_item: null,
      // airlines: [],
      // searchFields: {
      //   // from: moment().tz(timezone).format('YYYY-MM-DD'),
      //   // to: moment().add(1,'days').tz(timezone).format('YYYY-MM-DD'),
      //   month: moment().tz(timezone).format('YYYY-MM'),
      //   period: 'month',
      //   airline: null
      // },
      genInvProgress: false,
      userContractTypes: this.props.userContractTypes,
      contract_Type: this.props.contract_Type,
      cont_item: this.props.cont_item,
      airlines: this.props.airlines,
      searchFields: this.props.searchFields,
      loadInfo: this.props.loadInfo,
      airlineCode: this.props.airlineCode,

      modal: false,
      action: null,

      invoicedata: [],
      flightData: [],
      flight: [],
      flightDetails: [],
      showflightDetails: false,
      flightID: null,
      flightFields: {},
      flightErrors: {},

      flightStatus: {},
      // startDate: moment('2023-10-03 11:11:00', 'YYYY-MM-DD HH:mm:ss'),
      // endDate: moment('2023-10-04 10:10:00', 'YYYY-MM-DD HH:mm:ss'),
      // difference: {},
      flightList: [],
      billingData: [],
      filteredData: [],
      billingColumns: [],
      prodkeys: [],
      prodkeysDisc: [],
      billingFields: {},

      expanded: '',
      // loadInfo: 'contract_data',
      flightdata: [],
      daylogdata: [],
      monthlogdata: [],
      alcontractsData: [],
      contractData: [],
      dayContractData: [],
      monthContractData: [],
      invoice_info: null,
      accord_id: null,
      accord_type: null,
      summaryData: [],
      showSummary: true,
      billingCol: [],
      modalOpen: false,
      flightReportData: '',
      discrepancyCount: 0,
      progress: 10,
      showOneOff: false,
      oneOffData: [],
      oneOffEdit: {
        index: null,
        id: null,
        label: '',
        amount: '',
        operation: '',
        cycle: '',
      },
      timer: null,
      sessionExpired: false,
    };
    this.station_code = cookies.get('station_code') || null;
    this.interval = null;
  }

  async componentDidMount() {
    this.startTimer();
    this.fetchDiscrepancies('count');
    const currentPath = window.location.pathname;
    await pageHandler(currentPath, cookies.get('role'));
    // this.eventSource = events('admin', 'method');
    // this.fetchUser();
    if (this.state.searchFields.airline) {
      this.fetchOneOff();
      if (this.state.loadInfo === 'statistics') {
        this.fetchProducts();
      } else if (this.state.loadInfo === 'contract_data') {
        // this.fetchContractData();
        this.fetchInvoiceData();
      } else if (this.state.loadInfo === 'discrepancy') {
        this.fetchDiscrepancies();
      }
    }
    // this.fetchAirlines();
  }


  // Clear the timer when the component unmount

  componentWillUnmount() {
    // this.eventSource.close();
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.progress !== this.props.progress) {
  //     this.setState({ displayProgress: this.props.progress });
  //   }
  // }

  // fetchUser = () => {
  //   http.get(`/user?id=${cookies.get('user')}`).then(({ data }) => {
  //     if (data && data.data && data.data.length > 0) {
  //       // let statType = null;
  //       let cont_item = null;
  //       let contract_Type = null;
  //       let userContractTypes = data.data[0]?.contract_types || [];
  //       //get first key and value from contractType
  //       let firstRecord = Object.entries(contractType).find(([, value]) => userContractTypes.includes(value));
  //       if (firstRecord) {
  //         // statType = 0;
  //         cont_item = firstRecord[1];
  //         contract_Type = firstRecord[0];
  //       }
  //       this.setState({
  //         userContractTypes: data.data[0]?.contract_types || [],
  //         // statType: statType,
  //         cont_item: cont_item,
  //         contract_Type: contract_Type,
  //       });
  //     }
  //   });
  // };

  // fetchAirlines = () => {
  //   http.get(`/airline?active=${true}`)
  //     .then((response) => {
  //       this.setState({
  //         airlines: response.data.data,
  //         searchFields: {
  //           ...this.state.searchFields,
  //           airline: response.data.data[0].id
  //         },
  //         airlineCode: response.data.data[0].code
  //       },
  //       () => {
  //         if (this.state.loadInfo === 'statistics') {
  //           this.fetchProducts();
  //         } else if (this.state.loadInfo === 'contract_data') {
  //           this.fetchContractData();
  //         }
  //       });
  //     });
  // };

  fetchProducts = (callback) => {
    this.setState({
      products: [],
      activeProd: null,
    });
    http.get(`/products?airlineId=${this.state.searchFields?.airline}`)
      .then(response => {
        const { data } = response;
        if (data.status === 'success') {
          if (data.data && data.data.length > 0) {
            this.setState({
              products: data.data,
              activeProd: data.data[0],
            }, () => {
              this.generateBillingColumns('fetch', (v) => {
                console.log('generateBillingColumns', v);
                return callback && callback(true);
              });
            });
          } else {
            this.setState({
              activeProd: null,
              products: []
            }, () => {
              this.generateBillingColumns('fetch', (v) => {
                console.log('generateBillingColumns', v);
                return callback && callback(true);
              });
            });
          }
        }
      })
      .catch(error => {
        this.setState({ products: [] });
        console.error('Error fetching data:', error);
        return callback && callback(false);
      });
  };

  fetchBillingReport = (callback) => {
    let from = this.state.searchFields.from;
    let to = this.state.searchFields.to;
    if (this.state.searchFields.month) {
      if (this.state.searchFields.period === 'month') {
        from = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
        to = moment(this.state.searchFields.month, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
      } else if (this.state.searchFields.period === 'first') {
        from = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
        to = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').add(14, 'days').format('YYYY-MM-DD');
      } else {
        from = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').add(15, 'days').format('YYYY-MM-DD');
        to = moment(this.state.searchFields.month, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
      }
    }
    if (from && to && this.state.searchFields.airline) {
      http.get(`/billing?from=${from}&to=${to}&station=${this.station_code}&airline=${this.state.searchFields.airline}&type=${this.state.cont_item}`)
        .then(({ data }) => {
          this.setState({ flightList: data.data });
          if (data.data && data.data.length > 0) {
            let billingData = [];
            data.data.forEach((airline) => {

              let inbound = {};
              let outbound = {};
              let inbound_data = [];
              let outbound_data = [];

              let ib_flight_count = 0;
              let ib_awb_count = 0;
              let ib_awb_count_disc = 0;
              let ib_sum_awb_local = 0;
              let ib_sum_awb_local_disc = 0;
              let ib_sum_awb_export = 0;
              let ib_sum_awb_export_disc = 0;
              let ib_sum_awb_transit = 0;
              let ib_sum_awb_transit_disc = 0;
              let ib_sum_awb_bup = 0;
              let ib_sum_awb_comat = 0;
              let ib_sum_manifested = 0;
              let ib_prodvals = cloneDeep(this.state.prodkeys).map((rec) => {
                return {
                  key: rec.key,
                  id: rec.id,
                  sum: 0
                };
              });
              let ib_prodvals_disc = cloneDeep(this.state.prodkeysDisc).map((rec) => {
                return {
                  key: rec.key,
                  id: rec.id,
                  sum: 0
                };
              });

              let ib_awbs = [];
              let ob_awbs = [];

              if (airline?.inbound?.length > 0) {
                ib_flight_count = airline?.inbound?.length;
                airline?.inbound.map((inflight) => {
                  let flight_discrepancies = inflight?.flight_discrepancies || [];
                  console.log('inbound flight_discrepancies', flight_discrepancies);
                  let awb_count = 0;
                  let awb_count_disc = 0;
                  let sum_awb_local = 0;
                  let sum_awb_local_disc = 0;
                  let sum_awb_export = 0;
                  let sum_awb_export_disc = 0;
                  let sum_awb_transit = 0;
                  let sum_awb_transit_disc = 0;
                  let sum_awb_bup = 0;
                  let sum_awb_comat = 0;
                  let sum_manifested = 0;
                  let sum_prodvals = cloneDeep(this.state.prodkeys).map((rec) => {
                    return {
                      key: rec.key,
                      id: rec.id,
                      sum: 0
                    };
                  });
                  let sum_prodvals_disc = cloneDeep(this.state.prodkeysDisc).map((rec) => {
                    return {
                      key: rec.key,
                      id: rec.id,
                      sum: 0
                    };
                  });
                  let awbs = [];
                  //Map ULDs
                  if (inflight?.flight_uld.length > 0) {
                    inflight?.flight_uld.map((uld) => {

                      if (uld?.flight_awbs.length > 0) {
                        uld?.flight_awbs.map((awb) => {
                          let awb_data = {
                            id: awb?.id,
                            awb_no: awb?.awb_no,
                            label: awb?.awb_no,
                            flight_count: '-',
                            awb_count: 0,
                            awb_count_disc: 0,
                            sum_awb_local: 0,
                            sum_awb_local_disc: 0,
                            sum_awb_export: 0,
                            sum_awb_export_disc: 0,
                            sum_awb_transit: 0,
                            sum_awb_transit_disc: 0,
                            sum_awb_bup: 0,
                            sum_awb_comat: 0,
                            sum_manifested: 0,
                          };
                          if (awb?.dest === this.station_code) {
                            sum_awb_local += parseFloat(awb?.wgt || 0);
                            sum_awb_local_disc += parseFloat(awb?.wgt || 0);
                            awb_data.sum_awb_local = parseFloat(awb?.wgt || 0);
                            awb_data.sum_awb_local_disc = parseFloat(awb?.wgt || 0);
                          } else if (awb?.org === this.station_code) {
                            sum_awb_export += parseFloat(awb?.wgt || 0);
                            sum_awb_export_disc += parseFloat(awb?.wgt || 0);
                            awb_data.sum_awb_export = parseFloat(awb?.wgt || 0);
                            awb_data.sum_awb_export_disc = parseFloat(awb?.wgt || 0);
                          } else if (awb?.org && awb?.dest) {
                            sum_awb_transit += parseFloat(awb?.wgt || 0);
                            sum_awb_transit_disc += parseFloat(awb?.wgt || 0);
                            awb_data.sum_awb_transit = parseFloat(awb?.wgt || 0);
                            awb_data.sum_awb_transit_disc = parseFloat(awb?.wgt || 0);
                          }
                          if (awb?.type === 'BUP') {
                            sum_awb_bup += parseFloat(awb?.wgt || 0);
                            awb_data.sum_awb_bup = parseFloat(awb?.wgt || 0);
                          } else if (awb?.type === 'COMAT') {
                            sum_awb_comat += parseFloat(awb?.wgt || 0);
                            awb_data.sum_awb_comat = parseFloat(awb?.wgt || 0);
                          }
                          if (awb) {
                            awb_count += 1;
                            awb_count_disc += 1;
                            awb_data.awb_count = 1;
                            awb_data.awb_count_disc = 1;

                            sum_manifested += parseFloat(awb?.wgt || 0);
                            awb_data.sum_manifested = parseFloat(awb?.wgt || 0);
                          }
                          sum_prodvals.map((rec) => {
                            if (awb?.product?.id === rec.id) {
                              rec.sum += 1;
                              awb_data[rec.key] = 1;
                            } else {
                              awb_data[rec.key] = 0;
                            }
                          });
                          sum_prodvals_disc.map((rec) => {
                            if (awb?.product?.id === rec.id) {
                              rec.sum += 1;
                              awb_data[rec.key] = 1;
                            } else {
                              awb_data[rec.key] = 0;
                            }
                          });
                          awbs.push(awb_data);
                          ib_awbs.push({ awb_no: awb?.awb_no });
                        });
                      }
                    });
                  }
                  if (flight_discrepancies.length > 0) {
                    flight_discrepancies.map((rec) => {
                      if (rec?.type === 'Missing Cargo' || rec?.type === 'Short Shipped') {
                        let awb_data = {
                          id: rec?.id,
                          awb_no: rec?.awb_no,
                          label: <>{rec?.awb_no} &nbsp; <span className={fltLoadInfoStyles.discType}>{rec?.type}</span></>,
                          flight_count: '-',
                          awb_count_disc: 0,
                          sum_awb_local_disc: 0,
                          sum_awb_export_disc: 0,
                          sum_awb_transit_disc: 0,
                          sum_manifested: 0,
                          prod_code: rec?.prod_code,
                        };
                        sum_prodvals_disc.map((rec1) => {
                          let prod = rec1.key.split('_')[1].toUpperCase();
                          if (awb_data.prod_code && prod && awb_data.prod_code.toUpperCase() === prod.toUpperCase()) {
                            rec1.sum += 1;
                            awb_data[rec1.key] = 1;
                          } else {
                            awb_data[rec1.key] = 0;
                          }
                        });

                        if (rec?.dest === this.station_code) {
                          awb_data.awb_count_disc += 1;
                          awb_count_disc += 1;
                          awb_data.sum_awb_local_disc -= parseFloat(rec?.d_wgt);
                          sum_awb_local_disc -= parseFloat(rec?.d_wgt);
                        } else if (rec?.org === this.station_code) {
                          awb_data.awb_count_disc += 1;
                          awb_count_disc += 1;
                          awb_data.sum_awb_export_disc -= parseFloat(rec?.d_wgt);
                          sum_awb_export_disc -= parseFloat(rec?.d_wgt);
                        } else if (rec?.org && rec?.dest) {
                          awb_data.awb_count_disc += 1;
                          awb_count_disc += 1;
                          awb_data.sum_awb_transit_disc -= parseFloat(rec?.d_wgt);
                          sum_awb_transit_disc -= parseFloat(rec?.d_wgt);
                        }
                        awbs.push(awb_data);
                        ib_awbs.push({ awb_no: rec?.awb_no });
                      } else if (rec?.type === 'Found Cargo' || rec?.type === 'Over Carried') {
                        let awb_data = {
                          id: rec?.id,
                          awb_no: rec?.awb_no,
                          label: <>{rec?.awb_no} &nbsp; <span className={fltLoadInfoStyles.discType}>{rec?.type}</span></>,
                          flight_count: '-',
                          awb_count_disc: 0,
                          sum_awb_local_disc: 0,
                          sum_awb_export_disc: 0,
                          sum_awb_transit_disc: 0,
                          sum_manifested: 0,
                          prod_code: rec?.prod_code,
                        };

                        sum_prodvals_disc.map((rec1) => {
                          let prod = rec1.key.split('_')[1].toUpperCase();
                          if (awb_data.prod_code && prod && awb_data.prod_code.toUpperCase() === prod.toUpperCase()) {
                            rec1.sum += 1;
                            awb_data[rec1.key] = 1;
                          } else {
                            awb_data[rec1.key] = 0;
                          }
                        });
                        if (rec?.dest === this.station_code) {
                          awb_data.awb_count_disc += 1;
                          awb_count_disc += 1;
                          awb_data.sum_awb_local_disc += parseFloat(rec?.d_wgt);
                          sum_awb_local_disc += parseFloat(rec?.d_wgt);
                        } else if (rec?.org === this.station_code) {
                          awb_data.awb_count_disc += 1;
                          awb_count_disc += 1;
                          awb_data.sum_awb_export_disc += parseFloat(rec?.d_wgt);
                          sum_awb_export_disc += parseFloat(rec?.d_wgt);
                        } else if (rec?.org && rec?.dest) {
                          awb_data.awb_count_disc += 1;
                          awb_count_disc += 1;
                          awb_data.sum_awb_transit_disc += parseFloat(rec?.d_wgt);
                          sum_awb_transit_disc += parseFloat(rec?.d_wgt);
                        }
                        awbs.push(awb_data);
                        ib_awbs.push({ awb_no: rec?.awb_no });
                      }
                    });
                  }

                  let flight = {
                    id: inflight?.id,
                    flight_no: inflight?.flight_no,
                    label: `${airline?.code}${inflight?.flight_no}/${moment(inflight?.flight_date, 'YYYY-MM-DD').format('DDMMMYY')}`,
                    url: `/flight_folder/notes?id=${inflight.id}`,
                    flight_schedule_id: inflight?.flight_schedule_id,
                    contractData: awbs,
                    flight_count: 1,
                    awb_count: awb_count,
                    awb_count_disc: awb_count_disc,
                    sum_awb_local: sum_awb_local,
                    sum_awb_local_disc: sum_awb_local_disc,
                    sum_awb_export: sum_awb_export,
                    sum_awb_export_disc: sum_awb_export_disc,
                    sum_awb_transit: sum_awb_transit,
                    sum_awb_transit_disc: sum_awb_transit_disc,
                    sum_awb_bup: sum_awb_bup,
                    sum_awb_comat: sum_awb_comat,
                    sum_manifested: sum_manifested,
                    subRows: awbs,
                  };
                  sum_prodvals.map((rec) => {
                    flight[rec.key] = rec.sum;
                  });
                  sum_prodvals_disc.map((rec) => {
                    flight[rec.key] = rec.sum;
                  });

                  ib_awb_count += awb_count;
                  ib_awb_count_disc += awb_count_disc;
                  ib_sum_awb_local += sum_awb_local;
                  ib_sum_awb_local_disc += sum_awb_local_disc;
                  ib_sum_awb_export += sum_awb_export;
                  ib_sum_awb_export_disc += sum_awb_export_disc;
                  ib_sum_awb_transit += sum_awb_transit;
                  ib_sum_awb_transit_disc += sum_awb_transit_disc;
                  ib_sum_awb_bup += sum_awb_bup;
                  ib_sum_awb_comat += sum_awb_comat;
                  ib_sum_manifested += sum_manifested;
                  ib_prodvals.map((rec) => {
                    rec.sum += flight[rec.key];
                  });
                  ib_prodvals_disc.map((rec) => {
                    rec.sum += flight[rec.key];
                  });
                  inbound_data.push(flight);
                });
              }
              let inboundgroupedDataArr = inbound_data.reduce((acc, obj) => {
                let key = `${airline?.code}${obj.flight_no}`;
                if (!acc[key]) {
                  acc[key] = [];
                }
                acc[key].push(obj);
                return acc;
              }, {});
              let inboundParent = [];  // Flight Num grouping
              if (Object.keys(inboundgroupedDataArr).length > 0) {
                Object.entries(inboundgroupedDataArr).map(([key, rec]) => {
                  let inboundParentObj = {
                    label: key,
                    flight_count: 0,
                    awb_count: 0,
                    awb_count_disc: 0,
                    sum_awb_local: 0,
                    sum_awb_local_disc: 0,
                    sum_awb_export: 0,
                    sum_awb_export_disc: 0,
                    sum_awb_transit: 0,
                    sum_awb_transit_disc: 0,
                    sum_awb_bup: 0,
                    sum_awb_comat: 0,
                    sum_manifested: 0,
                    subRows: rec,
                  };
                  if (rec && rec.length > 0) {
                    rec.map((rec) => {
                      inboundParentObj.flight_count += rec.flight_count;
                      inboundParentObj.awb_count += rec.awb_count;
                      inboundParentObj.awb_count_disc += rec.awb_count_disc;
                      inboundParentObj.sum_awb_local += rec.sum_awb_local;
                      inboundParentObj.sum_awb_local_disc += rec.sum_awb_local_disc;
                      inboundParentObj.sum_awb_export += rec.sum_awb_export;
                      inboundParentObj.sum_awb_export_disc += rec.sum_awb_export_disc;
                      inboundParentObj.sum_awb_transit += rec.sum_awb_transit;
                      inboundParentObj.sum_awb_transit_disc += rec.sum_awb_transit_disc;
                      inboundParentObj.sum_awb_bup += rec.sum_awb_bup;
                      inboundParentObj.sum_awb_comat += rec.sum_awb_comat;
                      inboundParentObj.sum_manifested += rec.sum_manifested;
                    });
                  }
                  inboundParent.push(inboundParentObj);
                });
              }
              inbound = {
                id: `${airline?.id}_inbound`,
                label: `${ib_flight_count} Inbound Flights`,
                ex_label: 'Import',
                flight_count: ib_flight_count,
                flights: inbound_data,
                contractData: inbound_data?.map(obj => obj.subRows).flat(),
                awb_count: ib_awb_count,
                awb_count_disc: ib_awb_count_disc,
                sum_awb_local: ib_sum_awb_local,
                sum_awb_local_disc: ib_sum_awb_local_disc,
                sum_awb_export: ib_sum_awb_export,
                sum_awb_export_disc: ib_sum_awb_export_disc,
                sum_awb_transit: ib_sum_awb_transit,
                sum_awb_transit_disc: ib_sum_awb_transit_disc,
                sum_awb_bup: ib_sum_awb_bup,
                sum_awb_comat: ib_sum_awb_comat,
                sum_manifested: ib_sum_manifested,
                subRows: inboundParent,
                ib_awbs: ib_awbs,
              };
              ib_prodvals.map((rec) => {
                inbound[rec.key] = rec.sum;
              });
              ib_prodvals_disc.map((rec) => {
                inbound[rec.key] = rec.sum;
              });


              let ob_flight_count = 0;
              let ob_awb_count = 0;
              let ob_awb_count_disc = 0;
              let ob_sum_awb_local = 0;
              let ob_sum_awb_local_disc = 0;
              let ob_sum_awb_export = 0;
              let ob_sum_awb_export_disc = 0;
              let ob_sum_awb_transit = 0;
              let ob_sum_awb_transit_disc = 0;
              let ob_sum_awb_bup = 0;
              let ob_sum_awb_comat = 0;
              let ob_sum_manifested = 0;
              let ob_prodvals = cloneDeep(this.state.prodkeys).map((rec) => {
                return {
                  key: rec.key,
                  id: rec.id,
                  sum: 0
                };
              });
              let ob_prodvals_disc = cloneDeep(this.state.prodkeysDisc).map((rec) => {
                return {
                  key: rec.key,
                  id: rec.id,
                  sum: 0
                };
              });

              if (airline?.outbound?.length > 0) {
                ob_flight_count = airline?.outbound?.length;
                airline?.outbound.map((outflight) => {
                  let flight_discrepancies = outflight?.flight_discrepancies;
                  let awb_count = 0;
                  let awb_count_disc = 0;
                  let sum_awb_local = 0;
                  let sum_awb_local_disc = 0;
                  let sum_awb_export = 0;
                  let sum_awb_export_disc = 0;
                  let sum_awb_transit = 0;
                  let sum_awb_transit_disc = 0;
                  let sum_awb_bup = 0;
                  let sum_awb_comat = 0;
                  let sum_manifested = 0;
                  let sum_prodvals = cloneDeep(this.state.prodkeys).map((rec) => {
                    return {
                      key: rec.key,
                      id: rec.id,
                      sum: 0
                    };
                  });
                  let sum_prodvals_disc = cloneDeep(this.state.prodkeysDisc).map((rec) => {
                    return {
                      key: rec.key,
                      id: rec.id,
                      sum: 0
                    };
                  });
                  let awbs = [];
                  //Map ULDs
                  if (outflight?.flight_uld.length > 0) {
                    outflight?.flight_uld.map((uld) => {

                      if (uld?.flight_awbs.length > 0) {
                        uld?.flight_awbs.map((awb) => {
                          let awb_data = {
                            id: awb?.id,
                            awb_no: awb?.awb_no,
                            label: awb?.awb_no,
                            flight_count: '-',
                            awb_count: 0,
                            awb_count_disc: 0,
                            sum_awb_local: 0,
                            sum_awb_local_disc: 0,
                            sum_awb_export: 0,
                            sum_awb_export_disc: 0,
                            sum_awb_transit: 0,
                            sum_awb_transit_disc: 0,
                            sum_awb_bup: 0,
                            sum_awb_comat: 0,
                            sum_manifested: 0,
                          };
                          if (awb?.dest === this.station_code) {
                            sum_awb_local += parseFloat(awb?.wgt || 0);
                            sum_awb_local_disc += parseFloat(awb?.wgt || 0);
                            awb_data.sum_awb_local = parseFloat(awb?.wgt || 0);
                            awb_data.sum_awb_local_disc = parseFloat(awb?.wgt || 0);
                          } else if (awb?.org === this.station_code) {
                            sum_awb_export += parseFloat(awb?.wgt || 0);
                            sum_awb_export_disc += parseFloat(awb?.wgt || 0);
                            awb_data.sum_awb_export = parseFloat(awb?.wgt || 0);
                            awb_data.sum_awb_export_disc = parseFloat(awb?.wgt || 0);
                          } else if (awb?.org && awb?.dest) {
                            sum_awb_transit += parseFloat(awb?.wgt || 0);
                            sum_awb_transit_disc += parseFloat(awb?.wgt || 0);
                            awb_data.sum_awb_transit = parseFloat(awb?.wgt || 0);
                            awb_data.sum_awb_transit_disc = parseFloat(awb?.wgt || 0);
                          }
                          if (awb?.type === 'BUP') {
                            sum_awb_bup += parseFloat(awb?.wgt || 0);
                            awb_data.sum_awb_bup = parseFloat(awb?.wgt || 0);
                          } else if (awb?.type === 'COMAT') {
                            sum_awb_comat += parseFloat(awb?.wgt || 0);
                            awb_data.sum_awb_comat = parseFloat(awb?.wgt || 0);
                          }
                          if (awb) {
                            awb_count += 1;
                            awb_count_disc += 1;
                            awb_data.awb_count = 1;
                            awb_data.awb_count_disc = 1;
                            sum_manifested += parseFloat(awb?.wgt || 0);
                            awb_data.sum_manifested = parseFloat(awb?.wgt || 0);
                          }
                          sum_prodvals.map((rec) => {
                            if (awb?.product?.id === rec.id) {
                              rec.sum += 1;
                              awb_data[rec.key] = 1;
                            } else {
                              awb_data[rec.key] = 0;
                            }
                          });
                          sum_prodvals_disc.map((rec) => {
                            if (awb?.product?.id === rec.id) {
                              rec.sum += 1;
                              awb_data[rec.key] = 1;
                            } else {
                              awb_data[rec.key] = 0;
                            }
                          });
                          awbs.push(awb_data);
                          ob_awbs.push({ awb_no: awb?.awb_no });
                        });
                      }
                    });
                  }

                  if (flight_discrepancies.length > 0) {
                    flight_discrepancies.map((rec) => {
                      if (rec?.type === 'Missing Cargo' || rec?.type === 'Short Shipped') {
                        let awb_data = {
                          id: rec?.id,
                          awb_no: rec?.awb_no,
                          label: `${rec?.awb_no} - ${rec?.type}`,
                          flight_count: '-',
                          sum_awb_local_disc: 0,
                          sum_awb_export_disc: 0,
                          sum_awb_transit_disc: 0,
                          sum_manifested: 0,
                          prod_code: rec?.prod_code,
                        };
                        sum_prodvals_disc.map((rec1) => {
                          let prod = rec1.key.split('_')[1].toUpperCase();
                          if (awb_data.prod_code && prod && awb_data.prod_code.toUpperCase() === prod.toUpperCase()) {
                            rec1.sum += 1;
                            awb_data[rec1.key] = 1;
                          } else {
                            awb_data[rec1.key] = 0;
                          }
                        });
                        if (rec?.dest === this.station_code) {
                          awb_data.awb_count_disc += 1;
                          awb_count_disc += 1;
                          awb_data.sum_awb_local_disc -= parseFloat(rec?.d_wgt);
                          sum_awb_local_disc -= parseFloat(rec?.d_wgt);
                        } else if (rec?.org === this.station_code) {
                          awb_data.awb_count_disc += 1;
                          awb_count_disc += 1;
                          awb_data.sum_awb_export_disc -= parseFloat(rec?.d_wgt);
                          sum_awb_export_disc -= parseFloat(rec?.d_wgt);
                        } else if (rec?.org && rec?.dest) {
                          awb_data.awb_count_disc += 1;
                          awb_count_disc += 1;
                          awb_data.sum_awb_transit_disc -= parseFloat(rec?.d_wgt);
                          sum_awb_transit_disc -= parseFloat(rec?.d_wgt);
                        }
                        awbs.push(awb_data);
                        ob_awbs.push({ awb_no: rec?.awb_no });
                      } else if (rec?.type === 'Found Cargo' || rec?.type === 'Over Carried') {
                        let awb_data = {
                          id: rec?.id,
                          awb_no: rec?.awb_no,
                          label: `${rec?.awb_no} - ${rec?.type}`,
                          flight_count: '-',
                          awb_count_disc: 0,
                          sum_awb_local_disc: 0,
                          sum_awb_export_disc: 0,
                          sum_awb_transit_disc: 0,
                          sum_manifested: 0,
                          prod_code: rec?.prod_code,
                        };
                        sum_prodvals_disc.map((rec1) => {
                          let prod = rec1.key.split('_')[1].toUpperCase();
                          if (awb_data.prod_code && prod && awb_data.prod_code.toUpperCase() === prod.toUpperCase()) {
                            rec1.sum += 1;
                            awb_data[rec1.key] = 1;
                          } else {
                            awb_data[rec1.key] = 0;
                          }
                        });
                        if (rec?.dest === this.station_code) {
                          awb_data.awb_count_disc += 1;
                          awb_count_disc += 1;
                          awb_data.sum_awb_local_disc += parseFloat(rec?.d_wgt);
                          sum_awb_local_disc += parseFloat(rec?.d_wgt);
                        } else if (rec?.org === this.station_code) {
                          awb_data.awb_count_disc += 1;
                          awb_count_disc += 1;
                          awb_data.sum_awb_export_disc += parseFloat(rec?.d_wgt);
                          sum_awb_export_disc += parseFloat(rec?.d_wgt);
                        } else if (rec?.org && rec?.dest) {
                          awb_data.awb_count_disc += 1;
                          awb_count_disc += 1;
                          awb_data.sum_awb_transit_disc += parseFloat(rec?.d_wgt);
                          sum_awb_transit_disc += parseFloat(rec?.d_wgt);
                        }
                        awbs.push(awb_data);
                        ob_awbs.push({ awb_no: rec?.awb_no });
                      }
                    });
                  }

                  let flight = {
                    id: outflight?.id,
                    label: `${airline?.code}${outflight?.flight_no}/${moment(outflight?.flight_date, 'YYYY-MM-DD').format('DDMMMYY')}`,
                    url: `/flight_folder/notes?id=${outflight.id}`,
                    flight_schedule_id: outflight?.flight_schedule_id,
                    contractData: awbs,
                    flight_no: outflight?.flight_no,
                    flight_count: 1,
                    awb_count: awb_count,
                    awb_count_disc: awb_count_disc,
                    sum_awb_local: sum_awb_local,
                    sum_awb_local_disc: sum_awb_local_disc,
                    sum_awb_export: sum_awb_export,
                    sum_awb_export_disc: sum_awb_export_disc,
                    sum_awb_transit: sum_awb_transit,
                    sum_awb_transit_disc: sum_awb_transit_disc,
                    sum_awb_bup: sum_awb_bup,
                    sum_awb_comat: sum_awb_comat,
                    sum_manifested: sum_manifested,
                    subRows: awbs,
                  };
                  sum_prodvals.map((rec) => {
                    flight[rec.key] = rec.sum;
                  });
                  sum_prodvals_disc.map((rec) => {
                    flight[rec.key] = rec.sum;
                  });

                  ob_awb_count += awb_count;
                  ob_awb_count_disc += awb_count_disc;
                  ob_sum_awb_local += sum_awb_local;
                  ob_sum_awb_local_disc += sum_awb_local_disc;
                  ob_sum_awb_export += sum_awb_export;
                  ob_sum_awb_export_disc += sum_awb_export_disc;
                  ob_sum_awb_transit += sum_awb_transit;
                  ob_sum_awb_transit_disc += sum_awb_transit_disc;
                  ob_sum_awb_bup += sum_awb_bup;
                  ob_sum_awb_comat += sum_awb_comat;
                  ob_sum_manifested += sum_manifested;
                  ob_prodvals.map((rec) => {
                    rec.sum += flight[rec.key];
                  });
                  ob_prodvals_disc.map((rec) => {
                    rec.sum += flight[rec.key];
                  });
                  outbound_data.push(flight);

                });
              }
              let outboundgroupedDataArr = outbound_data.reduce((acc, obj) => {
                let key = `${airline?.code}${obj.flight_no}`;
                if (!acc[key]) {
                  acc[key] = [];
                }
                acc[key].push(obj);
                return acc;
              }, {});
              let outboundParent = [];  // Flight Num grouping
              if (Object.keys(outboundgroupedDataArr).length > 0) {
                Object.entries(outboundgroupedDataArr).map(([key, rec]) => {
                  let outboundParentObj = {
                    label: key,
                    flight_count: 0,
                    awb_count: 0,
                    awb_count_disc: 0,
                    sum_awb_local: 0,
                    sum_awb_local_disc: 0,
                    sum_awb_export: 0,
                    sum_awb_export_disc: 0,
                    sum_awb_transit: 0,
                    sum_awb_transit_disc: 0,
                    sum_awb_bup: 0,
                    sum_awb_comat: 0,
                    sum_manifested: 0,
                    subRows: rec,
                  };
                  if (rec && rec.length > 0) {
                    rec.map((rec) => {
                      outboundParentObj.flight_count += rec.flight_count;
                      outboundParentObj.awb_count += rec.awb_count;
                      outboundParentObj.awb_count_disc += rec.awb_count_disc;
                      outboundParentObj.sum_awb_local += rec.sum_awb_local;
                      outboundParentObj.sum_awb_local_disc += rec.sum_awb_local_disc;
                      outboundParentObj.sum_awb_export += rec.sum_awb_export;
                      outboundParentObj.sum_awb_export_disc += rec.sum_awb_export_disc;
                      outboundParentObj.sum_awb_transit += rec.sum_awb_transit;
                      outboundParentObj.sum_awb_transit_disc += rec.sum_awb_transit_disc;
                      outboundParentObj.sum_awb_bup += rec.sum_awb_bup;
                      outboundParentObj.sum_awb_comat += rec.sum_awb_comat;
                      outboundParentObj.sum_manifested += rec.sum_manifested;
                    });
                  }
                  outboundParent.push(outboundParentObj);
                });
              }
              outbound = {
                id: `${airline?.id}_outbound`,
                label: `${ob_flight_count} Outbound Flights`,
                ex_label: 'Export',
                flight_count: ob_flight_count,
                flights: outbound_data,
                contractData: outbound_data?.map(obj => obj.subRows).flat(),
                awb_count: ob_awb_count,
                awb_count_disc: ob_awb_count_disc,
                sum_awb_local: ob_sum_awb_local,
                sum_awb_local_disc: ob_sum_awb_local_disc,
                sum_awb_export: ob_sum_awb_export,
                sum_awb_export_disc: ob_sum_awb_export_disc,
                sum_awb_transit: ob_sum_awb_transit,
                sum_awb_transit_disc: ob_sum_awb_transit_disc,
                sum_awb_bup: ob_sum_awb_bup,
                sum_awb_comat: ob_sum_awb_comat,
                sum_manifested: ob_sum_manifested,
                subRows: outboundParent,
                ob_awbs: ob_awbs,
              };
              ob_prodvals.map((rec) => {
                outbound[rec.key] = rec.sum;
              });
              ob_prodvals_disc.map((rec) => {
                outbound[rec.key] = rec.sum;
              });

              // let mainParent = [];  // Flight Num grouping
              let airline_data = {
                id: airline?.id,
                label: `${parseInt(ib_flight_count) + parseInt(ob_flight_count)} ${airline?.code} Flights`,
                ex_label: airline?.code,
                flight_count: ib_flight_count + ob_flight_count,
                inbound: inbound,
                outbound: outbound,
                contractData: [
                  ...inbound.contractData,
                  ...outbound.contractData
                ],
                awb_count: ib_awb_count + ob_awb_count,
                awb_count_disc: ib_awb_count_disc + ob_awb_count_disc,
                sum_awb_local: ib_sum_awb_local + ob_sum_awb_local,
                sum_awb_local_disc: ib_sum_awb_local_disc + ob_sum_awb_local_disc,
                sum_awb_export: ib_sum_awb_export + ob_sum_awb_export,
                sum_awb_export_disc: ib_sum_awb_export_disc + ob_sum_awb_export_disc,
                sum_awb_transit: ib_sum_awb_transit + ob_sum_awb_transit,
                sum_awb_transit_disc: ib_sum_awb_transit_disc + ob_sum_awb_transit_disc,
                sum_awb_bup: ib_sum_awb_bup + ob_sum_awb_bup,
                sum_awb_comat: ib_sum_awb_comat + ob_sum_awb_comat,
                sum_manifested: ib_sum_manifested + ob_sum_manifested,
                subRows: [inbound, outbound]
              };
              ib_prodvals.map((rec, index) => {
                airline_data[rec.key] = ib_prodvals[index].sum + ob_prodvals[index].sum;
              });
              ib_prodvals_disc.map((rec, index) => {
                airline_data[rec.key] = ib_prodvals_disc[index].sum + ob_prodvals_disc[index].sum;
              });
              billingData.push(airline_data);
              // mainParent.push(airline_data);
              // mainParent.inbound = inboundParent;
              // mainParent.outbound = outboundParent;
              // this.setSummary(mainParent, 'airline');
            });
            // let summaryData = billingData;
            console.log('billingData', billingData);
            this.setState({ billingData: billingData }, () => {
              this.setSummary(this.state.billingData, 'airline');
              // this.setSummary(summaryData, 'airline');
              if (this.state.billingFields.transit === 'inbound' || this.state.billingFields.transit === 'outbound') {
                this.filterData();
                if (callback) return callback(true);
              } else {
                if (callback) {
                  this.filterData();
                  return callback(true);
                }
              }
            });
          } else {
            this.setState({ billingData: [] });
            return callback(true);
          }
        });
    }
  };

  filterData = () => {
    const subtractValues = (obj, awb, fields) => {
      fields.forEach(field => {
        obj[field] = parseFloat(obj[field]) - (parseFloat(awb[field]) || 0);
      });
    };

    // const fields = ['sum_awb_local', 'sum_awb_local_disc', 'sum_awb_transit', 'sum_awb_transit_disc', 'sum_awb_bup', 'sum_awb_comat', 'sum_manifested'];
    const fields = [/*'sum_awb_local', 'sum_awb_local_disc',*/ 'sum_awb_transit', 'sum_awb_transit_disc', /*'sum_awb_bup', 'sum_awb_comat', 'sum_manifested'*/];
    let filteredData = cloneDeep(this.state.contractData);

    for (let airline of filteredData) {
      if (airline?.subRows?.length > 0) {
        let inbound = airline?.subRows.find((row) => row.label === 'Inbound');
        let outbound = airline?.subRows.find((row) => row.label === 'Outbound');
        let ib_awbs = inbound?.ib_awbs || [];
        let ob_awbs = outbound?.ob_awbs || [];

        switch (this.state.billingFields.transit) {
          case 'inbound':
            if (ib_awbs.length > 0) {
              for (let flightParent of outbound?.subRows || []) {
                for (let flight of flightParent?.subRows || []) {
                  let awbs = [];
                  for (let awb of flight?.subRows || []) {
                    let awb_index = ib_awbs.findIndex((rec) => rec.awb_no === awb.awb_no);
                    if (awb_index > -1) {
                      subtractValues(flight, awb, fields);
                      subtractValues(flightParent, awb, fields);
                      subtractValues(outbound, awb, fields);
                      subtractValues(airline, awb, fields);
                      awbs.push(awb);
                    } else {
                      awbs.push(awb);
                    }
                  }
                  flight.subRows = awbs;
                }
              }
            }
            break;
          case 'outbound':
            if (ob_awbs.length > 0) {
              for (let flightParent of inbound?.subRows || []) {
                for (let flight of flightParent?.subRows || []) {
                  let awbs = [];
                  for (let awb of flight?.subRows || []) {
                    let awb_index = ob_awbs.findIndex((rec) => rec.awb_no === awb.awb_no);
                    if (awb_index > -1) {
                      subtractValues(flight, awb, fields);
                      subtractValues(flightParent, awb, fields);
                      subtractValues(inbound, awb, fields);
                      subtractValues(airline, awb, fields);
                      awbs.push(awb);
                    } else {
                      awbs.push(awb);
                    }
                  }
                  flight.subRows = awbs;
                }
              }
            }
            break;
          default:
            break;
        }
        airline.subRows = [inbound, outbound];
      }
    }
    this.setState({ filteredData: filteredData });
  };

  // generateBillingReport = () => {
  //   if (this.state.searchFields.month && this.state.searchFields.airline) {
  //     http.get(`/generate_billing?month=${this.state.searchFields.month}&station=${this.station_code}&airline=${this.state.searchFields.airline}`)
  //       .then(({ data }) => {
  //         console.log('data', data);
  //       });
  //   }
  // };

  generateBillingColumns = (type, callback) => {
    function formatNumber(num) {
      return num % 1 === 0 ? Math.round(num) : num.toFixed(2);
    }
    let prodkeys = [];
    let prodkeysDisc = [];
    let columns = [];
    if (this.state.billingFields.exclude_disc) {
      columns.push(
        {
          field: 'awb_count',
          headerName: 'AWBS',
          className: styles.rowLabels,
          width: 200,
          flex: 1,
          renderCell: (data) => {
            return (
              <div>
                {data.row.label}
              </div>
            );
          },
        },
        {
          field: 'sum_awb_local',
          headerName: 'AWBS local (KG)',
          width: 200,
          flex: 1,
          renderCell: ({ row }) => {
            let value = typeof row.sum_awb_local === 'number' ? formatNumber(Math.round(row.sum_awb_local * 100) / 100) : row.sum_awb_local;
            return value === 0 ? 'Nil' : value;
          },
        },
        {
          field: 'sum_awb_transit',
          headerName: 'AWBS transit (KG)',
          width: 200,
          flex: 1,
          renderCell: ({ row }) => {
            let value = typeof row.sum_awb_transit === 'number' ? formatNumber(Math.round(row.sum_awb_transit * 100) / 100) : row.sum_awb_transit;
            return value === 0 ? 'Nil' : value;
          },
        },
        {
          field: 'sum_awb_export',
          headerName: 'AWBS export (KG)',
          width: 200,
          flex: 1,
          renderCell: ({ row }) => {
            let value = typeof row.sum_awb_export === 'number' ? formatNumber(Math.round(row.sum_awb_export * 100) / 100) : row.sum_awb_export;
            return value === 0 ? 'Nil' : value;
          }
        },
      );
    } else {
      columns.push(
        {
          field: 'awb_count_disc',
          headerName: 'AWBS',
          className: styles.rowLabels,
          width: 200,
          flex: 1,
          renderCell: (data) => {
            return (
              <div>
                {data.row.label}
              </div>
            );
          },
        },
        {
          field: 'sum_awb_local_disc',
          headerName: 'AWBS local (KG)',
          width: 200,
          flex: 1,
          renderCell: ({ row }) => {
            let value = typeof row.sum_awb_local_disc === 'number' ? formatNumber(Math.round(row.sum_awb_local_disc * 100) / 100) : row.sum_awb_local_disc;
            value = value < 0 ? value * -1 : value;
            return value === 0 ? 'Nil' : value;
          },
        },
        {
          field: 'sum_awb_transit_disc',
          headerName: 'AWBS transit (KG)',
          width: 200,
          flex: 1,
          renderCell: ({ row }) => {
            let value = typeof row.sum_awb_transit_disc === 'number' ? formatNumber(Math.round(row.sum_awb_transit_disc * 100) / 100) : row.sum_awb_transit_disc;
            value = value < 0 ? value * -1 : value;
            return value === 0 ? 'Nil' : value;
          },
        },
        {
          field: 'sum_awb_export_disc',
          headerName: 'AWBS export (KG)',
          width: 200,
          flex: 1,
          renderCell: ({ row }) => {
            let value = typeof row.sum_awb_export_disc === 'number' ? formatNumber(Math.round(row.sum_awb_export_disc * 100) / 100) : row.sum_awb_export_disc;
            value = value < 0 ? value * -1 : value;
            return value === 0 ? 'Nil' : value;
          }
        },
      );
    }
    columns.push(
      {
        field: 'sum_manifested',
        headerName: 'TOTAL CARGO manifested (KG)',
        width: 200,
        flex: 1,
        renderCell: ({ row }) => {
          let value = typeof row.sum_manifested === 'number' ? formatNumber(Math.round(row.sum_manifested * 100) / 100) : row.sum_manifested;
          value = value < 0 ? value * -1 : value;
          return value === 0 ? 'Nil' : value;
        },
      },
    );
    if (this.state.products && this.state.products.length > 0) {
      columns.push(
        {
          field: 'product',
          headerName: 'Product',
          width: 200,
          flex: 1,
          renderCell: ({ row }) => {
            let a = this.state.products.map((product) => {
              let prod = row[`sum_${product.name.trim()?.toLowerCase()}`];
              return prod && prod !== 0 ? `${product.name} (${prod})` : row.prod_code;
            });
            let prod_code = a && a.filter((item, index, inputArray) => {
              return inputArray.indexOf(item) == index;
            });
            let hasNullElements = a && a.every((item) => item == null || item == undefined);
            return hasNullElements ? '-' : prod_code;
          },
        },
      );
      this.state.products.map((product) => {
        if (this.state.billingFields.exclude_disc) {
          columns.push({
            field: `sum_${product.name?.trim()?.toLowerCase()}`,
            headerName: `# of ${product.name}`,
            width: 200,
            flex: 1,
          });
        }
        else {
          columns.push({
            field: `sum_${product.name?.trim()?.toLowerCase()}_disc`,
            headerName: `# of ${product.name}`,
            width: 200,
            flex: 1,
          });
        }
        prodkeys.push(
          {
            key: `sum_${product.name?.trim()?.toLowerCase()}`,
            id: product.id
          }
        );
        prodkeysDisc.push(
          {
            key: `sum_${product.name?.trim()?.toLowerCase()}_disc`,
            id: product.id
          }
        );
      });
    }
    //array without products
    let cloneColumn = cloneDeep(columns);
    let columLength = cloneColumn.length;
    let prodLength = this.state?.products?.length || 0;
    let total = columLength - prodLength;
    cloneColumn.splice(total, prodLength);

    //array without products column
    let billingColumns = cloneDeep(columns);
    let productIndex = billingColumns.findIndex((item) => item.field === 'product');
    if (productIndex !== -1) {
      billingColumns.splice(productIndex, 1);
    }

    this.setState({
      billingCol: cloneColumn,
      billingColumns: billingColumns,
      prodkeys: prodkeys,
      prodkeysDisc: prodkeysDisc,
    }, () => {
      if (type === 'fetch') {
        this.fetchBillingReport(() => {
          if (callback) return callback(true);
        }
        );
      }
    });
  };

  triggerNewBilling = (regenerate = false,) => {
    let from = moment(this.state.searchFields.from).format('YYYY-MM-DD');
    let to = moment(this.state.searchFields.to).format('YYYY-MM-DD');
    if (this.state.searchFields.month) {
      if (this.state.searchFields.period === 'month') {
        from = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
        to = moment(this.state.searchFields.month, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
      } else if (this.state.searchFields.period === 'first') {
        from = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
        to = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').add(14, 'days').format('YYYY-MM-DD');
      } else {
        from = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').add(15, 'days').format('YYYY-MM-DD');
        to = moment(this.state.searchFields.month, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
      }
    }
    if (this.state.searchFields.airline) {
      http.get(`/populate_billing_queues?from=${from}&to=${to}&station=${this.station_code}&airline=${this.state.searchFields.airline}&type=${this.state.cont_item}&regenerate=${regenerate}`)  
        .then(({ data }) => {
          console.log('data', data);
          if(data?.data.length > 2){
            toast.success(data?.message);
          } else {
            toast.warning(data?.message);
          }
        });
    }
  };
  fetchInvoiceData = (regenerate = false, callback = false) => {
    this.setState({ genInvProgress: true });
    console.log('fetchInvoiceData start', new Date().getHours(), new Date().getMinutes(), new Date().getSeconds(), new Date().getMilliseconds());
    let from = moment(this.state.searchFields.from).format('YYYY-MM-DD');
    let to = moment(this.state.searchFields.to).format('YYYY-MM-DD');
    if (this.state.searchFields.month) {
      if (this.state.searchFields.period === 'month') {
        from = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
        to = moment(this.state.searchFields.month, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
      } else if (this.state.searchFields.period === 'first') {
        from = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
        to = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').add(14, 'days').format('YYYY-MM-DD');
      } else {
        from = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').add(15, 'days').format('YYYY-MM-DD');
        to = moment(this.state.searchFields.month, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
      }
    }
    if (this.state.searchFields.airline) {
      http.get(`/generate_invoice?from=${from}&to=${to}&station=${this.station_code}&airline=${this.state.searchFields.airline}&type=${this.state.cont_item}&regenerate=${regenerate}`)
        .then(({ data }) => {
          let flightdata = [];
          let invoicedata = data.data;
          let billingData = [];
          let alcontractsData = [];
          let invoice_info = data.data.invoice_infos[0] || null;
          //getinboundContractData
          let inbound_contractData = [];
          data.data.inflightcontracts.map((item, i) => {
            data.data.inflightcontracts[i].contractData = data.data.inflightcontracts[i].contractData.map((item, index) => ({
              ...cloneDeep(item),
              id: `${index + 1}_inflight`,
              item: item.label,
              driver: item.applied_on?.split('|')[0].trim(),
              source: item.applied_on?.split('|')[1].trim(),
            }));
            item.contractData.map((rec) => {
              let ind = inbound_contractData.findIndex((obj) => obj.contractId === rec.contractId && obj.cont_rowId === rec.cont_rowId);
              if (ind === -1) {
                inbound_contractData.push(cloneDeep(rec));
              }
              else {
                inbound_contractData[ind].quantity = (!isNaN(inbound_contractData[ind].quantity) ? parseFloat(inbound_contractData[ind].quantity) : 0) + parseFloat(rec.quantity) || 0;
                inbound_contractData[ind].totalrate = (!isNaN(inbound_contractData[ind].totalrate) ? parseFloat(inbound_contractData[ind].totalrate) : 0) + parseFloat(rec.totalrate) || 0;
                inbound_contractData[ind].dis_quantity = (!isNaN(inbound_contractData[ind].dis_quantity) ? parseFloat(inbound_contractData[ind].dis_quantity) : 0) + parseFloat(rec.dis_quantity) || 0;
                inbound_contractData[ind].dis_totalrate = (!isNaN(inbound_contractData[ind].dis_totalrate) ? parseFloat(inbound_contractData[ind].dis_totalrate) : 0) + parseFloat(rec.dis_totalrate) || 0;
                inbound_contractData[ind].in_quantity = (!isNaN(inbound_contractData[ind].in_quantity) ? parseFloat(inbound_contractData[ind].in_quantity) : 0) + parseFloat(rec.in_quantity) || 0;
                inbound_contractData[ind].in_totalrate = (!isNaN(inbound_contractData[ind].in_totalrate) ? parseFloat(inbound_contractData[ind].in_totalrate) : 0) + parseFloat(rec.in_totalrate) || 0;
                inbound_contractData[ind].out_quantity = (!isNaN(inbound_contractData[ind].out_quantity) ? parseFloat(inbound_contractData[ind].out_quantity) : 0) + parseFloat(rec.out_quantity) || 0;
                inbound_contractData[ind].out_totalrate = (!isNaN(inbound_contractData[ind].out_totalrate) ? parseFloat(inbound_contractData[ind].out_totalrate) : 0) + parseFloat(rec.out_totalrate) || 0;
                inbound_contractData[ind].dis_in_quantity = (!isNaN(inbound_contractData[ind].dis_in_quantity) ? parseFloat(inbound_contractData[ind].dis_in_quantity) : 0) + parseFloat(rec.dis_in_quantity) || 0;
                inbound_contractData[ind].dis_in_totalrate = (!isNaN(inbound_contractData[ind].dis_in_totalrate) ? parseFloat(inbound_contractData[ind].dis_in_totalrate) : 0) + parseFloat(rec.dis_in_totalrate) || 0;
                inbound_contractData[ind].dis_out_quantity = (!isNaN(inbound_contractData[ind].dis_out_quantity) ? parseFloat(inbound_contractData[ind].dis_out_quantity) : 0) + parseFloat(rec.dis_out_quantity) || 0;
                inbound_contractData[ind].dis_out_totalrate = (!isNaN(inbound_contractData[ind].dis_out_totalrate) ? parseFloat(inbound_contractData[ind].dis_out_totalrate) : 0) + parseFloat(rec.dis_out_totalrate) || 0;
              }
            });
          });
          let outbound_contractData = [];
          data.data.outflightcontracts.map((item, i) => {
            data.data.outflightcontracts[i].contractData = data.data.outflightcontracts[i].contractData.map((item, index) => ({
              ...cloneDeep(item),
              id: `${index + 1}_outflight`,
              item: item.label,
              driver: item.applied_on?.split('|')[0].trim(),
              source: item.applied_on?.split('|')[1].trim(),
            }));
            item.contractData.map((rec) => {
              let ind = outbound_contractData.findIndex((obj) => obj.contractId === rec.contractId && obj.cont_rowId === rec.cont_rowId);
              if (ind === -1) {
                outbound_contractData.push(cloneDeep(rec));
              }
              else {
                outbound_contractData[ind].quantity = (!isNaN(outbound_contractData[ind].quantity) ? parseFloat(outbound_contractData[ind].quantity) : 0) + parseFloat(rec.quantity) || 0;
                outbound_contractData[ind].totalrate = (!isNaN(outbound_contractData[ind].totalrate) ? parseFloat(outbound_contractData[ind].totalrate) : 0) + parseFloat(rec.totalrate) || 0;
                outbound_contractData[ind].dis_quantity = (!isNaN(outbound_contractData[ind].dis_quantity) ? parseFloat(outbound_contractData[ind].dis_quantity) : 0) + parseFloat(rec.dis_quantity) || 0;
                outbound_contractData[ind].dis_totalrate = (!isNaN(outbound_contractData[ind].dis_totalrate) ? parseFloat(outbound_contractData[ind].dis_totalrate) : 0) + parseFloat(rec.dis_totalrate) || 0;
                outbound_contractData[ind].in_quantity = (!isNaN(outbound_contractData[ind].in_quantity) ? parseFloat(outbound_contractData[ind].in_quantity) : 0) + parseFloat(rec.in_quantity) || 0;
                outbound_contractData[ind].in_totalrate = (!isNaN(outbound_contractData[ind].in_totalrate) ? parseFloat(outbound_contractData[ind].in_totalrate) : 0) + parseFloat(rec.in_totalrate) || 0;
                outbound_contractData[ind].out_quantity = (!isNaN(outbound_contractData[ind].out_quantity) ? parseFloat(outbound_contractData[ind].out_quantity) : 0) + parseFloat(rec.out_quantity) || 0;
                outbound_contractData[ind].out_totalrate = (!isNaN(outbound_contractData[ind].out_totalrate) ? parseFloat(outbound_contractData[ind].out_totalrate) : 0) + parseFloat(rec.out_totalrate) || 0;
                outbound_contractData[ind].dis_in_quantity = (!isNaN(outbound_contractData[ind].dis_in_quantity) ? parseFloat(outbound_contractData[ind].dis_in_quantity) : 0) + parseFloat(rec.dis_in_quantity) || 0;
                outbound_contractData[ind].dis_in_totalrate = (!isNaN(outbound_contractData[ind].dis_in_totalrate) ? parseFloat(outbound_contractData[ind].dis_in_totalrate) : 0) + parseFloat(rec.dis_in_totalrate) || 0;
                outbound_contractData[ind].dis_out_quantity = (!isNaN(outbound_contractData[ind].dis_out_quantity) ? parseFloat(outbound_contractData[ind].dis_out_quantity) : 0) + parseFloat(rec.dis_out_quantity) || 0;
                outbound_contractData[ind].dis_out_totalrate = (!isNaN(outbound_contractData[ind].dis_out_totalrate) ? parseFloat(outbound_contractData[ind].dis_out_totalrate) : 0) + parseFloat(rec.dis_out_totalrate) || 0;
              }
            });
          });
          flightdata = data.data.inflightcontracts.concat(data.data.outflightcontracts);

          let day_contractData = [];
          data.data.daycontracts.map((item, i) => {
            data.data.daycontracts[i].contractData = data.data.daycontracts[i].contractData.map((item, index) => ({
              ...cloneDeep(item),
              id: `${index + 1}_daylog`,
              item: item.label,
              driver: item.applied_on?.split('|')[0].trim(),
              source: item.applied_on?.split('|')[1].trim(),
            }));

            item.contractData.map((rec) => {
              let index = day_contractData.findIndex((obj) => obj.contractId === rec.contractId && obj.cont_rowId === rec.cont_rowId);
              if (index === -1) {
                day_contractData.push(cloneDeep(rec));
              }
              else {
                day_contractData[index].quantity = rec.quantity ? parseFloat(day_contractData[index].quantity) + parseFloat(rec.quantity) : parseFloat(day_contractData[index].quantity);
                day_contractData[index].totalrate = rec.totalrate ? parseFloat(day_contractData[index].totalrate) + parseFloat(rec.totalrate) : parseFloat(day_contractData[index].totalrate);
              }
            });
          });
          let month_contractData = [];
          data.data.monthcontracts.map((item, i) => {
            data.data.monthcontracts[i].contractData = data.data.monthcontracts[i].contractData.map((item, index) => ({
              ...cloneDeep(item),
              id: `${index + 1}_monthlog`,
              item: item.label,
              driver: item.applied_on?.split('|')[0].trim(),
              source: item.applied_on?.split('|')[1].trim(),
            }));

            item.contractData.map((rec) => {
              let index = month_contractData.findIndex((obj) => obj.contractId === rec.contractId && obj.cont_rowId === rec.cont_rowId);
              if (index === -1) {
                month_contractData.push(rec);
              }
              // else {
              //   month_contractData[index].quantity = parseFloat(month_contractData[index].quantity) + parseFloat(rec.quantity);
              //   month_contractData[index].totalrate = parseFloat(month_contractData[index].totalrate) + parseFloat(rec.totalrate);
              // }
            });
          });

          let inbound = {
            id: 'inbound',
            label: `${data.data.inflightcontracts.length} Inbound Flights`,
            flight_count: data.data.inflightcontracts.length,
            flights: data.data.inflightcontracts,
            contractData: inbound_contractData.map((item, index) => ({
              ...item,
              id: `${index + 1}_daylog`,
              item: item.label,
              driver: item.applied_on?.split('|')[0].trim(),
              source: item.applied_on?.split('|')[1].trim(),
            })), // Need to add ids
          };
          let outbound = {
            id: 'outbound',
            label: `${data.data.outflightcontracts.length} Outbound Flights`,
            flight_count: data.data.outflightcontracts.length,
            flights: data.data.outflightcontracts,
            contractData: outbound_contractData.map((item, index) => ({
              ...item,
              id: `${index + 1}_daylog`,
              item: item.label,
              driver: item.applied_on?.split('|')[0].trim(),
              source: item.applied_on?.split('|')[1].trim(),
            })), // Need to Sum up all outbound data
          };

          let daylog = {
            id: 'daylog',
            label: `${data.data.daycontracts.length} Day Logs`,
            daylog_count: data.data.daycontracts.length, // Need to replace this with alldaylogs count
            daylogs: data.data.daycontracts,
            alldaylogs: data.data.daycontracts, // need to change this
            contractData: day_contractData.map((item, index) => ({
              ...item,
              id: `${index + 1}_daylog`,
              item: item.label,
              driver: item.applied_on?.split('|')[0].trim(),
              source: item.applied_on?.split('|')[1].trim(),
            })), // Need to Sum up all daylogs data
          };
          let monthlog = {
            id: 'monthlog',
            label: `${data.data.monthcontracts.length} Month Logs`,
            monthlog_count: 1,
            monthlogs: data.data.monthcontracts,
            contractData: month_contractData.map((item, index) => ({
              ...item,
              id: `${index + 1}_monthlog`,
              item: item.label,
              driver: item.applied_on?.split('|')[0].trim(),
              source: item.applied_on?.split('|')[1].trim(),
            })), // Need to Sum up all monthlogs data
          };
          let airline_data = {
            id: data.data?.airlinecontracts[0]?.label,
            label: `${parseInt(data.data.inflightcontracts.length) + parseInt(data.data.outflightcontracts.length)} ${(this.state.airlineCode + ' Flights')}`,
            flight_count: parseInt(data.data.inflightcontracts.length) + parseInt(data.data.outflightcontracts.length),
            inbound: inbound,
            outbound: outbound,
            contractData: data.data.airlinecontracts[0]?.contractData.map((item, index) => ({
              ...item,
              id: `${index + 1}_daylog`,
              item: item.label,
              driver: item.applied_on?.split('|')[0].trim(),
              source: item.applied_on?.split('|')[1].trim(),
            })) || [],
            daylog: daylog,
            monthlog: monthlog,
          };
          billingData.push(airline_data);
          let contractData = [];
          let dayContractData = [];
          let monthContractData = [];
          let accord_id = null;
          let accord_type = null;
          let daylogdata = [];
          let monthlogdata = [];
          if (billingData.length > 0) {
            alcontractsData = billingData[0]?.contractData;
            contractData = billingData[0]?.contractData;
            accord_id = billingData[0]?.id;
            accord_type = 'airline';
            dayContractData = billingData[0]?.daylog?.contractData;
            monthContractData = billingData[0]?.monthlog?.contractData;
            daylogdata = billingData[0]?.daylog?.alldaylogs;
            monthlogdata = billingData[0]?.monthlog?.allmonthlogs;
          }
          // console.log('billingData', billingData);
          // console.log('flightdata', flightdata);
          // console.log('invoicedata', invoicedata);
          // console.log('daylogdata*************', daylogdata);
          // console.log('monthlogdata***********', monthlogdata);
          // console.log('contractData', contractData);
          // console.log('dayContractData', dayContractData);
          // console.log('monthContractData', monthContractData);
          this.setState({
            flightdata: flightdata || [],
            invoicedata: invoicedata || [],
            billingData: billingData || [],
            daylogdata: daylogdata || [],
            monthlogdata: monthlogdata || [],
            alcontractsData: alcontractsData,
            contractData: contractData || [],
            dayContractData: dayContractData || [],
            monthContractData: monthContractData || [],
            invoice_info: invoice_info || null,
            accord_id: accord_id,
            accord_type: accord_type,
            genInvProgress: false,
          }, () => {
            console.log('fetchInvoiceData end', new Date().getHours(), new Date().getMinutes(), new Date().getSeconds(), new Date().getMilliseconds());
            if (this.state.billingFields.transit === 'inbound' || this.state.billingFields.transit === 'outbound') {
              this.filterData();
              if (callback) return callback(true);
            } else {
              if (callback) return callback(true);
            }
          });
        });
    } else {
      this.setState({ genInvProgress: false });
      if (callback) return callback(true);
    }
  };
  fetchContractData = () => {
    let flightdata = [];
    let daylogdata = [];
    let monthlogdata = [];
    let from = moment(this.state.searchFields.from).format('YYYY-MM-DD');
    let to = moment(this.state.searchFields.to).format('YYYY-MM-DD');
    if (this.state.searchFields.month) {
      if (this.state.searchFields.period === 'month') {
        from = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
        to = moment(this.state.searchFields.month, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
      } else if (this.state.searchFields.period === 'first') {
        from = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
        to = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').add(14, 'days').format('YYYY-MM-DD');
      } else {
        from = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').add(15, 'days').format('YYYY-MM-DD');
        to = moment(this.state.searchFields.month, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
      }
    }
    if (this.state.searchFields.airline) {
      http.get(`/billing_contract?from=${from}&to=${to}&station=${this.station_code}&airline=${this.state.searchFields.airline}&type=${this.state.cont_item}`)
        .then(({ data }) => {
          this.setState({ flightList: data.data });
          if (data.data && data.data.length > 0) {
            let billingData = [];
            data.data.forEach((airline) => {

              let inbound = {};
              let outbound = {};
              let inbound_data = [];
              let outbound_data = [];

              let ib_flight_count = 0;
              if (airline?.inbound?.length > 0) {
                ib_flight_count = parseInt(airline?.inbound?.length);
                airline?.inbound.map((inflight) => {
                  //map through inflight contracts and create array of contractsData
                  let contractsData = [];
                  let totalfltamt = 0;
                  if (inflight?.contracts?.length > 0) {
                    inflight?.contracts.map((contract) => {
                      if (contract.contractrows && contract?.contractrows.length > 0) {
                        contract.contractrows.map((row) => {
                          totalfltamt += row.totalrate;
                          let [driver, source] = row.applied_on.split('|').map(s => s.trim());
                          let contractData = {
                            key: row.id,
                            id: row.id,
                            item: row.description,
                            driver: driver,
                            source: source,//Auto manual
                            quantity: row?.quantity,
                            dis_quantity: row?.dis_quantity,
                            in_quantity: row?.in_quantity,
                            out_quantity: row?.out_quantity,
                            dis_in_quantity: row?.dis_in_quantity,
                            dis_out_quantity: row?.dis_out_quantity,
                            rate: row?.rate_type === 'stable' ? row?.rate : 'Tiered Rate',
                            totalrate: row?.rate_type === 'stable' ? row.totalrate : 'Tiered Rate',
                            dis_rate: row?.rate_type === 'stable' ? row?.dis_rate : 'Tiered Rate',
                            dis_totalrate: row?.rate_type === 'stable' ? row.dis_totalrate : 'Tiered Rate',
                            in_rate: row?.rate_type === 'stable' ? row?.in_rate : 'Tiered Rate',
                            in_totalrate: row?.rate_type === 'stable' ? row.in_totalrate : 'Tiered Rate',
                            out_rate: row?.rate_type === 'stable' ? row?.out_rate : 'Tiered Rate',
                            out_totalrate: row?.rate_type === 'stable' ? row.out_totalrate : 'Tiered Rate',
                            dis_in_rate: row?.rate_type === 'stable' ? row.dis_in_rate : 'Tiered Rate',
                            dis_in_totalrate: row?.rate_type === 'stable' ? row.dis_in_totalrate : 'Tiered Rate',
                            dis_out_rate: row?.rate_type === 'stable' ? row.dis_out_rate : 'Tiered Rate',
                            dis_out_totalrate: row?.rate_type === 'stable' ? row.dis_out_totalrate : 'Tiered Rate'
                          };
                          contractsData.push(contractData);
                        });
                      }
                    });
                    console.log('contractsData', contractsData);
                    console.log('totalfltamt', totalfltamt);
                  }
                  let flight = {
                    id: inflight?.id,
                    flight_no: inflight?.flight_no,
                    label: `${airline?.code}${inflight?.flight_no}/${moment(inflight?.flight_date, 'YYYY-MM-DD').format('DDMMMYY')}`,
                    url: `/flight_folder/notes?id=${inflight.id}`,
                    flight_count: 1,
                    contractData: contractsData,
                    totalfltamt: '-',
                    pending: inflight?.pending,
                  };
                  inbound_data.push(flight);
                });
              }
              let incontractsData = [];
              if (airline.inboundcontracts && airline.inboundcontracts?.length > 0) {
                airline.inboundcontracts.map((contract) => {
                  if (contract.contractrows && contract?.contractrows.length > 0) {
                    contract.contractrows.map((row) => {
                      let [driver, source] = row.applied_on.split('|').map(s => s.trim());
                      let contractData = {
                        key: row.id,
                        id: row.id,
                        item: row.description,
                        driver: driver,
                        source: source,//Auto manual
                        quantity: row?.quantity || 0,
                        dis_quantity: row?.dis_quantity || 0,
                        in_quantity: row?.in_quantity || 0,
                        out_quantity: row?.out_quantity || 0,
                        dis_in_quantity: row?.dis_in_quantity || 0,
                        dis_out_quantity: row?.dis_out_quantity || 0,
                        rate: row?.rate_type === 'stable' ? row?.rate : 'Tiered Rate',
                        totalrate: row?.rate_type === 'stable' ? row.totalrate : 'Tiered Rate',
                        dis_rate: row?.rate_type === 'stable' ? row?.dis_rate : 'Tiered Rate',
                        dis_totalrate: row?.rate_type === 'stable' ? row.dis_totalrate : 'Tiered Rate',
                        in_rate: row?.rate_type === 'stable' ? row?.in_rate : 'Tiered Rate',
                        in_totalrate: row?.rate_type === 'stable' ? row.in_totalrate : 'Tiered Rate',
                        out_rate: row?.rate_type === 'stable' ? row?.out_rate : 'Tiered Rate',
                        out_totalrate: row?.rate_type === 'stable' ? row.out_totalrate : 'Tiered Rate',
                        dis_in_rate: row?.rate_type === 'stable' ? row.dis_in_rate : 'Tiered Rate',
                        dis_in_totalrate: row?.rate_type === 'stable' ? row.dis_in_totalrate : 'Tiered Rate',
                        dis_out_rate: row?.rate_type === 'stable' ? row.dis_out_rate : 'Tiered Rate',
                        dis_out_totalrate: row?.rate_type === 'stable' ? row.dis_out_totalrate : 'Tiered Rate'
                      };
                      incontractsData.push(contractData);
                    });
                  }
                });
                // console.log('contractsData',contractsData);
                // console.log('totalfltamt',totalfltamt);
              }
              flightdata = [...inbound_data];
              inbound = {
                id: `${airline?.id}_inbound`,
                label: `${ib_flight_count} Inbound Flights`,
                flight_count: ib_flight_count,
                flights: inbound_data,
                contractData: incontractsData,
              };


              let ob_flight_count = 0;
              if (airline?.outbound?.length > 0) {
                ob_flight_count = parseInt(airline?.outbound?.length);
                airline?.outbound.map((outflight) => {
                  //map through inflight contracts and create array of contractsData
                  let contractsData = [];
                  let totalfltamt = 0;
                  if (outflight?.contracts?.length > 0) {
                    outflight?.contracts.map((contract) => {
                      if (contract.contractrows && contract?.contractrows.length > 0) {
                        contract.contractrows.map((row) => {
                          totalfltamt += row.totalrate;
                          let [driver, source] = row.applied_on.split('|').map(s => s.trim());
                          let contractData = {
                            key: row.id,
                            id: row.id,
                            item: row.description,
                            driver: driver,
                            source: source,//Auto manual
                            quantity: row?.quantity || 0,
                            dis_quantity: row?.dis_quantity || 0,
                            in_quantity: row?.in_quantity || 0,
                            out_quantity: row?.out_quantity || 0,
                            dis_in_quantity: row?.dis_in_quantity || 0,
                            dis_out_quantity: row?.dis_out_quantity || 0,
                            // rate: row?.rate || 0,
                            // totalrate: row.totalrate
                            rate: row?.rate_type === 'stable' ? row?.rate : 'Tiered Rate',
                            totalrate: row?.rate_type === 'stable' ? row.totalrate : 'Tiered Rate',
                            dis_rate: row?.rate_type === 'stable' ? row?.dis_rate : 'Tiered Rate',
                            dis_totalrate: row?.rate_type === 'stable' ? row.dis_totalrate : 'Tiered Rate',
                            in_rate: row?.rate_type === 'stable' ? row?.in_rate : 'Tiered Rate',
                            in_totalrate: row?.rate_type === 'stable' ? row.in_totalrate : 'Tiered Rate',
                            out_rate: row?.rate_type === 'stable' ? row?.out_rate : 'Tiered Rate',
                            out_totalrate: row?.rate_type === 'stable' ? row.out_totalrate : 'Tiered Rate',
                            dis_in_rate: row?.rate_type === 'stable' ? row.dis_in_rate : 'Tiered Rate',
                            dis_in_totalrate: row?.rate_type === 'stable' ? row.dis_in_totalrate : 'Tiered Rate',
                            dis_out_rate: row?.rate_type === 'stable' ? row.dis_out_rate : 'Tiered Rate',
                            dis_out_totalrate: row?.rate_type === 'stable' ? row.dis_out_totalrate : 'Tiered Rate'
                          };
                          contractsData.push(contractData);
                        });
                      }
                    });
                    // console.log('contractsData', contractsData);
                    // console.log('totalfltamt', totalfltamt);
                  }
                  let flight = {
                    id: outflight?.id,
                    flight_no: outflight?.flight_no,
                    label: `${airline?.code}${outflight?.flight_no}/${moment(outflight?.flight_date, 'YYYY-MM-DD').format('DDMMMYY')}`,
                    url: `/flight_folder/notes?id=${outflight.id}`,
                    flight_count: 1,
                    contractData: contractsData,
                    totalfltamt: totalfltamt,
                    pending: outflight?.pending,
                  };
                  outbound_data.push(flight);
                });
              }
              let outcontractsData = [];
              if (airline.outboundcontracts && airline.outboundcontracts?.length > 0) {
                airline.outboundcontracts.map((contract) => {
                  if (contract.contractrows && contract?.contractrows.length > 0) {
                    contract.contractrows.map((row) => {
                      let [driver, source] = row.applied_on.split('|').map(s => s.trim());
                      let contractData = {
                        key: row.id,
                        id: row.id,
                        item: row.description,
                        driver: driver,
                        source: source,//Auto manual
                        quantity: row?.quantity || 0,
                        dis_quantity: row?.dis_quantity || 0,
                        in_quantity: row?.in_quantity || 0,
                        out_quantity: row?.out_quantity || 0,
                        dis_in_quantity: row?.dis_in_quantity || 0,
                        dis_out_quantity: row?.dis_out_quantity || 0,
                        rate: row?.rate_type === 'stable' ? row?.rate : 'Tiered Rate',
                        totalrate: row?.rate_type === 'stable' ? row.totalrate : 'Tiered Rate',
                        dis_rate: row?.rate_type === 'stable' ? row?.dis_rate : 'Tiered Rate',
                        dis_totalrate: row?.rate_type === 'stable' ? row.dis_totalrate : 'Tiered Rate',
                        in_rate: row?.rate_type === 'stable' ? row?.in_rate : 'Tiered Rate',
                        in_totalrate: row?.rate_type === 'stable' ? row.in_totalrate : 'Tiered Rate',
                        out_rate: row?.rate_type === 'stable' ? row?.out_rate : 'Tiered Rate',
                        out_totalrate: row?.rate_type === 'stable' ? row.out_totalrate : 'Tiered Rate',
                      };
                      outcontractsData.push(contractData);
                    });
                  }
                });
                // console.log('contractsData',contractsData);
                // console.log('totalfltamt',totalfltamt);
              }
              flightdata = [...flightdata, ...outbound_data];
              outbound = {
                id: `${airline?.id}_outbound`,
                label: `${ob_flight_count} Outbound Flights`,
                flight_count: ob_flight_count,
                flights: outbound_data,
                contractData: outcontractsData,
              };

              let alcontractsData = [];
              if (airline.airlinecontracts && airline.airlinecontracts?.length > 0) {
                airline.airlinecontracts.map((contract) => {
                  if (contract.contractrows && contract?.contractrows.length > 0) {
                    contract.contractrows.map((row) => {
                      let rate = 0;
                      let dis_rate = 0;
                      let in_rate = 0;
                      let out_rate = 0;
                      let dis_in_rate = 0;
                      let dis_out_rate = 0;
                      // let totalrate = 0
                      if (row?.rate_type === 'stable') {
                        rate = row?.rate;
                        dis_rate = row?.dis_rate;
                        in_rate = row?.in_rate;
                        out_rate = row?.out_rate;
                        dis_in_rate = row?.dis_in_rate;
                        dis_out_rate = row?.dis_out_rate;
                        // totalrate = row.quantity * row.rate
                      } else if (row?.rate_type === 'absolute') {
                        rate = row?.rate;
                        dis_rate = row?.dis_rate;
                        in_rate = row?.in_rate;
                        out_rate = row?.out_rate;
                        dis_in_rate = row?.dis_in_rate;
                        dis_out_rate = row?.dis_out_rate;
                        // console.log('absolute ***************************************8')
                        // if(row?.contrates && row?.contrates.length > 0){
                        //   row.contrates.sort(
                        //     (a, b) =>
                        //       parseInt(a.level) - parseInt(b.level)
                        //   );
                        //   for (let i = 0; i < row.contrates.length; i++) {
                        //     console.log('row.contrates[i]', row.contrates[i])
                        //     if (
                        //       row.quantity <
                        //         parseInt(row.contrates[i].level) ||
                        //       i === row.contrates.length - 1
                        //     ) {
                        //       row.rate = parseFloat(
                        //         row.contrates[i].rate
                        //       );
                        //       // totalrate = parseFloat(row.quantity) * parseFloat(row.contrates[i].rate);
                        //       break;
                        //     }
                        //   }
                        // }
                      } else if (row?.rate_type === 'progressive') {
                        rate = 'Tiered Rate';
                        dis_rate = 'Tiered Rate';
                        in_rate = 'Tiered Rate';
                        out_rate = 'Tiered Rate';
                        dis_in_rate = 'Tiered Rate';
                        dis_out_rate = 'Tiered Rate';
                        // if(row?.contrates && row?.contrates.length > 0){
                        //   row.contrates.sort(
                        //     (a, b) =>
                        //       parseInt(a.level) - parseInt(b.level)
                        //   );
                        //   let rate1 = row.contrates[0] && row.contrates[0].rate? parseFloat(row.contrates[0].rate): 0;
                        //   let rate2 = row.contrates[1] && row.contrates[1].rate? parseFloat(row.contrates[1].rate): 0;
                        //   let rate3 = row.contrates[2] && row.contrates[2].rate? parseFloat(row.contrates[2].rate): 0;
                        //   let level1 = row.contrates[0] && row.contrates[0].level? parseInt(row.contrates[0].level): 0;
                        //   let level2 = row.contrates[1] && row.contrates[1].level? parseInt(row.contrates[1].level): 0;
                        //   if (row.quantity <= level1 ||!rate2) {
                        //     rate = rate1;
                        //   } else if (row.quantity <= level2 ||!rate3) {
                        //     // rate = '('+String(rate1) + '$ * '+String(level1)+' + '+ String(rate2)+'$ * '+String(row.quantity - level1)+')';
                        //     rate = String(rate1)+'('+String(level1) +')\n'+String(rate2)+'('+String(row.quantity - level1) +')';
                        //   } else {
                        //     // rate = '('+String(rate1) + '$ * '+String(level1)+' + '+ String(rate2)+'$ * '+String(level2 - level1) + ' + '+ String(rate3)+'$ * '+String(row.quantity - level2)+')';
                        //     rate = String(rate1)+'('+String(level1) +')\n'+String(rate2)+'('+String(level2 - level1) +')\n'+String(rate3)+'('+String(row.quantity - level2) +')';
                        //   }
                        // }
                      }
                      let [driver, source] = row.applied_on.split('|').map(s => s.trim());
                      let contractData = {
                        key: row.id,
                        id: row.id,
                        item: row.description,
                        driver: driver,
                        source: source,//Auto manual
                        quantity: row?.quantity || 0,
                        dis_quantity: row?.dis_quantity || 0,
                        in_quantity: row?.in_quantity || 0,
                        out_quantity: row?.out_quantity || 0,
                        dis_in_quantity: row?.dis_in_quantity || 0,
                        dis_out_quantity: row?.dis_out_quantity || 0,
                        rate: rate,
                        totalrate: parseFloat(row?.totalrate) || 0,
                        dis_rate: dis_rate,
                        dis_totalrate: parseFloat(row?.dis_totalrate) || 0,
                        in_rate: in_rate,
                        in_totalrate: parseFloat(row?.in_totalrate) || 0,
                        out_rate: out_rate,
                        out_totalrate: parseFloat(row?.out_totalrate) || 0,
                        dis_in_rate: dis_in_rate,
                        dis_in_totalrate: parseFloat(row?.dis_in_totalrate) || 0,
                        dis_out_rate: dis_out_rate,
                        dis_out_totalrate: parseFloat(row?.dis_out_totalrate) || 0,
                      };
                      alcontractsData.push(contractData);
                    });
                  }
                });
                // console.log('contractsData',contractsData);
                // console.log('totalfltamt',totalfltamt);
              }

              let daylog = {};
              let daylog_count = 0;
              let daylogs = [];
              let alldaylogs = [];
              let dcontractData = [];
              if (airline.daycontracts && airline.daycontracts?.length > 0) {
                airline.daycontracts.map((contract) => {
                  if (contract.contractrows && contract?.contractrows.length > 0) {
                    contract.contractrows.map((row) => {
                      let [driver, source] = row.applied_on.split('|').map(s => s.trim());
                      let obj = {
                        key: row.id,
                        id: row.id,
                        item: row.description,
                        driver: driver,
                        source: source,//Auto manual
                        quantity: row?.quantity || 0,
                        rate: row?.rate || 0,
                        totalrate: row.totalrate
                      };
                      dcontractData.push(obj);
                    });
                  }
                });
              }
              if (airline?.day_service_logs?.length > 0) {
                // sort day_service_logs by date
                airline?.day_service_logs.sort((a, b) => new Date(a.date) - new Date(b.date));
                daylog_count = parseInt(airline?.day_service_logs?.length);
                airline?.day_service_logs.map((daylog) => {
                  let ddcontractData = [];
                  if (daylog?.contracts?.length > 0) {
                    daylog?.contracts.map((contract) => {
                      if (contract.contractrows && contract?.contractrows.length > 0) {
                        contract.contractrows.map((row) => {
                          let [driver, source] = row.applied_on.split('|').map(s => s.trim());
                          let obj = {
                            key: row.id,
                            id: row.id,
                            item: row.description,
                            driver: driver,
                            source: source,//Auto manual
                            quantity: row?.quantity || 0,
                            rate: row?.rate || 0,
                            totalrate: row.totalrate
                          };
                          ddcontractData.push(obj);
                        });
                      }
                    });

                  }
                  let obj = {
                    id: daylog.id,
                    label: `${moment(daylog?.date, 'YYYY-MM-DD').format('DDMMMYY')}`,
                    count: 1,
                    contractData: ddcontractData,
                  };
                  daylogs.push(obj);

                });
                daylog = {
                  id: `${airline?.id}_daylog`,
                  label: `${daylog_count} Day Logs`,
                  daylog_count: daylog_count,
                  daylogs: daylogs,
                  contractData: dcontractData,
                };
              }
              if (airline?.allday_service_logs?.length > 0) {
                // sort day_service_logs by date
                airline?.allday_service_logs.sort((a, b) => new Date(a.date) - new Date(b.date));
                daylog_count = parseInt(airline?.allday_service_logs?.length);
                airline?.allday_service_logs.map((daylog) => {
                  let obj = {
                    id: daylog.id,
                    label: `${moment(daylog?.date, 'YYYY-MM-DD').format('DDMMMYY')}`,
                    count: 1,
                    contractData: [],
                    pending: daylog?.pending,
                  };
                  alldaylogs.push(obj);

                });
                daylog = {
                  id: `${airline?.id}_daylog`,
                  label: `${daylog_count} Day Logs`,
                  daylog_count: daylog_count,
                  daylogs: daylogs,
                  alldaylogs: alldaylogs,
                  contractData: dcontractData,
                };
              }

              let monthlog = {};
              let monthlog_count = 0;
              let monthlogs = [];
              let mcontractData = [];
              if (airline.monthcontracts && airline.monthcontracts?.length > 0) {
                airline.monthcontracts.map((contract) => {
                  if (contract.contractrows && contract?.contractrows.length > 0) {
                    contract.contractrows.map((row) => {
                      let [driver, source] = row.applied_on.split('|').map(s => s.trim());
                      let obj = {
                        key: row.id,
                        id: row.id,
                        item: row.description,
                        driver: driver,
                        source: source,//Auto manual
                        quantity: row?.quantity || 0,
                        rate: row?.rate || 0,
                        totalrate: row.totalrate
                      };
                      mcontractData.push(obj);
                    });
                  }
                });
              }
              if (airline?.month_service_logs?.length > 0) {
                monthlog_count = parseInt(airline?.month_service_logs?.length);
                airline?.month_service_logs.map((monthlog) => {
                  let mmcontractData = [];
                  if (monthlog?.contracts?.length > 0) {
                    monthlog?.contracts.map((contract) => {
                      if (contract.contractrows && contract?.contractrows.length > 0) {
                        contract.contractrows.map((row) => {
                          let [driver, source] = row.applied_on.split('|').map(s => s.trim());
                          let obj = {
                            key: row.id,
                            id: row.id,
                            item: row.description,
                            driver: driver,
                            source: source,//Auto manual
                            quantity: row?.quantity || 0,
                            rate: row?.rate || 0,
                            totalrate: row.totalrate
                          };
                          mmcontractData.push(obj);
                        });
                      }
                    });

                  }
                  let obj = {
                    id: monthlog.id,
                    label: `${moment(monthlog?.date, 'YYYY-MM-DD').format('MMMYY')}`,
                    count: 1,
                    contractData: mmcontractData,
                  };
                  monthlogs.push(obj);

                });
                monthlog = {
                  id: `${airline?.id}_monthlog`,
                  label: `${monthlog_count} month Logs`,
                  monthlog_count: monthlog_count,
                  monthlogs: monthlogs,
                  contractData: mcontractData,
                };
              }

              let airline_data = {
                id: airline?.id,
                label: `${parseInt(ib_flight_count) + parseInt(ob_flight_count)} ${airline?.code} Flights`,
                flight_count: ib_flight_count + ob_flight_count,
                // subRows: [inbound, outbound]
                inbound: inbound,
                outbound: outbound,
                contractData: alcontractsData,
                daylog: daylog,
                monthlog: monthlog,
              };
              billingData.push(airline_data);

            });
            let contractData = [];
            let dayContractData = [];
            let monthContractData = [];
            let accord_id = null;
            let accord_type = null;
            if (billingData.length > 0) {
              contractData = billingData[0]?.contractData;
              accord_id = billingData[0]?.id;
              accord_type = 'airline';
              dayContractData = billingData[0]?.daylog?.contractData;
              monthContractData = billingData[0]?.monthlog?.contractData;
              daylogdata = billingData[0]?.daylog?.daylogs;
              monthlogdata = billingData[0]?.monthlog?.monthlogs;
            }
            console.log('billingData', billingData);
            console.log('flightdata', flightdata);
            console.log('daylogdata*************', daylogdata);
            console.log('monthlogdata***********', monthlogdata);
            console.log('contractData', contractData);
            console.log('dayContractData', dayContractData);
            console.log('monthContractData', monthContractData);
            this.setState({
              // billingData: billingData,
              // flightdata: flightdata,
              // daylogdata: daylogdata,
              // monthlogdata: monthlogdata,
              // contractData: contractData,
              // dayContractData: dayContractData,
              // monthContractData: monthContractData,
              accord_id: accord_id,
              accord_type: accord_type,
            }, () => {
              if (this.state.billingFields.transit === 'inbound' || this.state.billingFields.transit === 'outbound') {
                this.filterData();
              }
            });
          } else {
            this.setState({ billingData: [] });
          }
        });
    }

  };

  fetchDiscrepancies = (type = null, callback = null) => {
    let from = moment(this.state.searchFields.from).format('YYYY-MM-DD');
    let to = moment(this.state.searchFields.to).format('YYYY-MM-DD');
    if (this.state.searchFields.month) {
      if (this.state.searchFields.period === 'month') {
        from = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
        to = moment(this.state.searchFields.month, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
      } else if (this.state.searchFields.period === 'first') {
        from = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
        to = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').add(14, 'days').format('YYYY-MM-DD');
      } else {
        from = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').add(15, 'days').format('YYYY-MM-DD');
        to = moment(this.state.searchFields.month, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
      }
    }
    if (this.state.searchFields.airline) {
      http.get(`/billing/discrepancy?from=${from}&to=${to}&station=${this.station_code}&airline=${this.state.searchFields.airline}`)
        .then(({ data }) => {
          this.setState({ flightList: data.data });
          let discrepancyCountInbound = data.data[0]?.inbound.map((inbound) => inbound?.flight_discrepancies.length).reduce((a, b) => a + b, 0);
          let discrepancyCountOutbound = data.data[0]?.outbound.map((outbound) => outbound?.flight_discrepancies.length).reduce((a, b) => a + b, 0);
          let discrepancyCount = discrepancyCountInbound + discrepancyCountOutbound;
          if (type === 'count') {
            this.setState({ discrepancyCount });
          }
          else {
            if (data.data && data.data.length > 0) {
              let billingData = [];
              data.data.forEach((airline) => {
                let inbound = {};
                let outbound = {};
                let inbound_data = [];
                let outbound_data = [];

                let ib_flight_count = 0;

                if (airline?.inbound?.length > 0) {
                  ib_flight_count = airline?.inbound?.length;
                  airline?.inbound.map((inflight) => {
                    let flight_discrepancies = inflight?.flight_discrepancies || [];
                    let flight = {
                      id: inflight?.id,
                      flight_no: inflight?.flight_no,
                      label: `${airline?.code}${inflight?.flight_no}/${moment(inflight?.flight_date, 'YYYY-MM-DD').format('DDMMMYY')}`,
                      url: `/flight_folder/discrepancies?id=${inflight.id}`,
                      flight_schedule_id: inflight?.flight_schedule_id,
                      flight_count: 1,
                      contractData: flight_discrepancies,
                    };
                    inbound_data.push(flight);
                  });
                }
                let inboundData = inbound_data.map(obj => obj.contractData).flat();
                let inboundDiscType = [];
                inboundData?.map((discrepancy) => {
                  inboundDiscType.push(discrepancy.type);
                });
                let typeObj = {
                  'Missing Airwaybill': 0,
                  'Missing Cargo': 0,
                  'Found Cargo': 0,
                  'Found Airwaybill': 0,
                  'Short Shipped': 0,
                  'Over Carried': 0,
                  'Damaged Cargo': 0,
                  'Other': 0
                };
                inboundDiscType.forEach(type => {
                  if (typeObj[type]) {
                    typeObj[type]++;
                  }
                  else {
                    typeObj[type] = 1;
                  }
                });
                inbound = {
                  id: `${airline?.id}_inbound`,
                  label: `${ib_flight_count} Inbound Flights`,
                  flight_count: ib_flight_count,
                  flights: inbound_data,
                  contractData: inbound_data.map(obj => obj.contractData).flat(),
                  ...typeObj
                };

                let ob_flight_count = 0;

                if (airline?.outbound?.length > 0) {
                  ob_flight_count = airline?.outbound?.length;
                  airline?.outbound.map((outflight) => {
                    let flight_discrepancies = outflight?.flight_discrepancies || [];
                    console.log('outbound flight_discrepancies', flight_discrepancies);

                    let flight = {
                      id: outflight?.id,
                      flight_no: outflight?.flight_no,
                      label: `${airline?.code}${outflight?.flight_no}/${moment(outflight?.flight_date, 'YYYY-MM-DD').format('DDMMMYY')}`,
                      url: `/flight_folder/discrepancies?id=${outflight.id}`,
                      flight_schedule_id: outflight?.flight_schedule_id,
                      flight_count: 1,
                      contractData: flight_discrepancies,
                    };
                    outbound_data.push(flight);

                  });
                }

                let outboundData = outbound_data.map(obj => obj.contractData).flat();
                let outDiscType = [];
                outboundData?.map((discrepancy) => {
                  outDiscType.push(discrepancy.type);
                });
                let outTypeObj = {
                  'Missing Airwaybill': 0,
                  'Missing Cargo': 0,
                  'Found Cargo': 0,
                  'Found Airwaybill': 0,
                  'Short Shipped': 0,
                  'Over Carried': 0,
                  'Damaged Cargo': 0,
                  'Other': 0
                };
                outDiscType.forEach(type => {
                  if (outTypeObj[type]) {
                    outTypeObj[type]++;
                  }
                  else {
                    outTypeObj[type] = 1;
                  }
                });
                outbound = {
                  id: `${airline?.id}_outbound`,
                  label: `${ob_flight_count} Outbound Flights`,
                  flight_count: ob_flight_count,
                  flights: outbound_data,
                  contractData: outbound_data.map(obj => obj.contractData).flat(),
                  ...outTypeObj
                };

                let airline_data = {
                  id: airline?.id,
                  label: `${parseInt(ib_flight_count) + parseInt(ob_flight_count)} ${airline?.code} Flights`,
                  flight_count: ib_flight_count + ob_flight_count,
                  inbound: inbound,
                  outbound: outbound,
                  contractData: [...inbound.contractData, ...outbound.contractData],
                  'Missing Airwaybill': inbound['Missing Airwaybill'] + outbound['Missing Airwaybill'] || 0,
                  'Missing Cargo': inbound['Missing Cargo'] + outbound['Missing Cargo'] || 0,
                  'Found Cargo': inbound['Found Cargo'] + outbound['Found Cargo'] || 0,
                  'Found Airwaybill': inbound['Found Airwaybill'] + outbound['Found Airwaybill'] || 0,
                  'Short Shipped': inbound['Short Shipped'] + outbound['Short Shipped'] || 0,
                  'Over Carried': inbound['Over Carried'] + outbound['Over Carried'] || 0,
                  'Damaged Cargo': inbound['Damaged Cargo'] + outbound['Damaged Cargo'] || 0,
                  'Other': inbound['Other'] + outbound['Other'] || 0,
                };
                billingData.push(airline_data);
              });
              let contractData = [];
              let accord_id = null;
              let accord_type = null;
              if (billingData.length > 0) {
                contractData = billingData[0]?.contractData;
                accord_id = billingData[0]?.id;
                accord_type = 'airline';
              }
              this.setState({
                billingData: billingData,
                contractData: contractData,
                accord_id: accord_id,
                accord_type: accord_type,
                discrepancyCount
              }, () => {
                if (this.state.billingFields.transit === 'inbound' || this.state.billingFields.transit === 'outbound') {
                  this.filterData();
                  if (callback)
                    return callback(true);
                } else {
                  if (callback) {
                    this.filterData();
                    return callback(true);
                  }
                }
              });
            } else {
              this.setState({ billingData: [] });
              if (callback)
                return callback(true);
            }
          }
        });
    }
  };

  setSummary = (data) => {
    this.setState({ summaryData: data && data[0] });
  };

  setContTBData = (data, type) => {
    console.log('setContTBData', type, 'data', data);
    if (type === 'airline') {
      this.setState({ showSummary: true });
    } else {
      this.setState({ showSummary: false });
    }
    this.setState({ contractData: [], }, () =>
      this.setState({
        summaryData: data,
        contractData: data?.contractData,
        accord_id: data?.id,
        accord_type: type,
      })
    );
  };

  // onFilterMain = (e) => {
  //   console.log('onFilterMain', e);
  //   let name = e.target.name;
  //   let value = e.target.value;
  //   this.setState((prev) => ({
  //     searchFields: {
  //       ...prev.searchFields,
  //       [name]: value
  //     }
  //   }), () => {
  //     if (e.target.name === 'airline') {
  //       let airlinecode = this.state.airlines.find((rec) => rec.id === value)?.code;
  //       this.setState({ airlineCode: airlinecode }, () => {
  //         if(this.state.loadInfo === 'statistics'){
  //           this.fetchProducts();
  //         } else if (this.state.loadInfo === 'contract_data') {
  //           this.fetchContractData();
  //         } else if (this.state.loadInfo === 'discrepancy') {
  //           this.fetchDiscrepancies();
  //         }
  //       });
  //     } else {
  //       if(this.state.loadInfo === 'statistics'){
  //         this.fetchBillingReport();
  //       } else if (this.state.loadInfo === 'contract_data') {
  //         this.fetchContractData();
  //       }else if (this.state.loadInfo === 'discrepancy') {
  //         this.fetchDiscrepancies();
  //       }
  //     }
  //   });
  // };

  changeCheck = (e) => {
    this.setState({
      billingFields: {
        ...this.state.billingFields,
        [e.target.name]: e.target.checked
      }
    }, () => {
      if (e.target.name === 'exclude_disc') {
        this.generateBillingColumns();
      }
    });
  };

  onChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      billingFields: {
        ...this.state.billingFields,
        [name]: value
      }
    }, () => {
      if (name === 'transit' && (value === 'inbound' || value === 'outbound')) {
        this.filterData();
      }
    });
  };

  changeDateAndTime = (element, type) => {
    this.setState({
      flightFields: {
        ...this.state.flightFields,
        [type]: (type == 'sta_time' || type == 'std_time') ? element.target.value : element.format('YYYY-MM-DD')
      }
    });
  };

  handleContractType = (e) => {
    let cont_item = contractType[e.target.value];
    this.setState({
      contract_Type: e.target.value,
      cont_item: cont_item,
    }, () => {
      if (this.state.loadInfo === 'statistics') {
        this.fetchProducts();
      } else if (this.state.loadInfo === 'contract_data') {
        // this.fetchContractData();
        this.fetchInvoiceData();
      }
    });
    //call function to filter by contracttype
  };

  addFlight = (e) => {
    e.preventDefault();
    const { flightFields } = this.state;
    let formdata = {
      airline: flightFields.airline,
      flight_no: flightFields.flight_no,
      flight_date: flightFields.flight_date,
      org: flightFields.org,
      dest: flightFields.dest,
      schdl_dept: flightFields.schdl_dept,
      schdl_arr: flightFields.schdl_arr,
      ac_type: flightFields.ac_type,
      platform: flightFields.platform
    };
    if (this.validation()) {
      http.post('/flight', formdata)
        .then(() => {
          this.setState((prev) => ({
            flightFields: {
              airline: '',
              flight_no: '',
              flight_date: '',
              org: '',
              dest: '',
              schdl_dept: '',
              schdl_arr: '',
              ac_type: '',
              platform: ''
            },
            modal: prev.modal === 'adhoc_flight' ? false : null
          }));
        });
    }
  };

  validation = () => {
    const flightFields = { ...this.state.flightFields };
    const error = {};
    let formValid = true;
    if (!flightFields.airline || (flightFields.airline.trim().length === 0)) {
      formValid = false;
      error.airline = 'Please select an airline';
    }
    if (!flightFields.platform || (flightFields.platform.trim().length === 0)) {
      formValid = false;
      error.platform = 'Please enter a platform';
    }
    if (!flightFields.flight_no || (flightFields.flight_no.trim().length === 0)) {
      formValid = false;
      error.flight_no = 'Please enter flight number';
    }
    if (!flightFields.flight_date || (flightFields.flight_date.trim().length === 0)) {
      formValid = false;
      error.flight_date = 'Please enter flight date';
    }
    if (!flightFields.org || (flightFields.org.trim().length === 0)) {
      formValid = false;
      error.org = 'Please Select org';
    }
    if (!flightFields.dest || (flightFields.dest.trim().length === 0)) {
      formValid = false;
      error.dest = 'Please enter dest';
    }
    if (!flightFields.ac_type || (flightFields.ac_type.trim().length === 0)) {
      formValid = false;
      error.ac_type = 'Please enter Aircraft type';
    }
    if (!flightFields.schdl_dept || (flightFields.schdl_dept.trim().length === 0)) {
      formValid = false;
      error.schdl_dept = 'Please enter a STD';
    }
    if (!flightFields.schdl_arr || (flightFields.schdl_arr.trim().length === 0)) {
      formValid = false;
      error.schdl_arr = 'Please enter a STA';
    }
    this.setState({ flightErrors: error });
    return formValid;
  };


  editFlight = (e) => {
    e.preventDefault();
    const { flightFields } = this.state;
    let formdata = {
      airline: flightFields.airline,
      flight_no: flightFields.flight_no,
      flight_date: flightFields.flight_date,
      org: flightFields.org,
      dest: flightFields.dest,
      schdl_dept: flightFields.schdl_dept,
      schdl_arr: flightFields.schdl_arr,
      ac_type: flightFields.ac_type,
      platform: flightFields.platform
    };
    if (this.validation()) {
      http.put(`/flight/${flightFields.id}`, formdata)
        .then(() => {
          this.setState((prev) => ({
            flightFields: {
              airline: '',
              flight_no: '',
              flight_date: '',
              org: '',
              dest: '',
              schdl_dept: '',
              schdl_arr: '',
              ac_type: '',
              platform: ''
            },
            modal: prev.modal === 'adhoc_flight' ? false : null
          }));
        });
    }
  };

  deleteFlight = (id) => {
    http.delete('/flight/' + id).then(({ data }) => {
      if (data.status == 'success') {
        this.props.setDelete();
      }
    });
  };

  handleDelete = (id, anchorEl) => {
    this.props.setDelete({
      modal: true,
      id,
      anchorEl,
      onConfirm: () => this.deleteFlight(id),
    });
  };

  handleOpen = (modal, action, data = {}) => {
    if(modal === 'downloadProgress') {
      if (this.progressInterval) clearInterval(this.progressInterval);
      this.progressInterval = setInterval(this.progressCount, 1000);
    }
    this.setState({
      modal,
      action,
      flightFields: { ...data }
    });
  };

  onClose = () => {
    this.setState({
      modal: false,
      action: false,
      sessionExpired: false,
    });
  };

  handlePopover = (e) => {
    console.log(e);
    this.setState({ anchorEl: e?.currentTarget });
  };

  closePopover = () => {
    this.setState({ anchorEl: null });
  };

  handleChange = (panel) => (e, newExpanded) => {
    console.log(newExpanded, panel, 'change');
    this.setState({ open: !this.state.open });
    this.setState({ expanded: newExpanded ? panel : !this.state.expanded });
    // setExpanded(newExpanded ? panel : false);
  };

  handleToggle = (e) => {
    // this.setState({ loadInfo: e.target.value, }, () => {
    this.props.handleSubToggle(e);
    console.log('e.target.value', e.target.value);
    if (e.target.value === 'contract_data') {
      //fetch contracts
      // this.fetchContractData();
      this.fetchInvoiceData();
    } else if (e.target.value === 'statistics') {
      this.setState({
        flightList: [],
        billingData: [],
        filteredData: [],
        contractData: [],
        accord_id: null,
        accord_type: null,
      }, () => this.fetchProducts());
      //fetch billing
    } else if (e.target.value === 'discrepancy') {
      //fetch billing
      this.setState({
        flightList: [],
        billingData: [],
        filteredData: [],
        contractData: [],
        accord_id: null,
        accord_type: null,
      }, () => this.fetchDiscrepancies());
    }
    // });
  };

  get_csv_cont = async () => {
    let csv_cont = [];
    this.state.billingData.map((column) => {
      column?.inbound?.flights.map((flight) => {
        let data = { 'Flight': flight.label, };
        flight?.contractData.map((row) => {
          data[row.item] = row.quantity;
        });
        csv_cont.push(data);
      });
      column?.outbound?.flights.map((flight) => {
        let data = { 'Flight': flight.label, };
        flight?.contractData.map((row) => {
          data[row.item] = row.quantity;
        });
        csv_cont.push(data);
      });
    });
    return csv_cont;
  };

  get_csv_stat = async () => {
    let csv_stat = [];
    this.state.billingData.map((column) => {
      csv_stat = [];
      let data = {
        'Lable': column?.ex_label,
        'Flight': column?.flight_count,
      };
      this.state.billingColumns.map((c) => {
        let field = c.field;
        if (this.state.billingFields.exclude_disc) {
          field = field.replace('_disc', '');
        }
        data[c.headerName] = column?.[field];
      });
      csv_stat.push(data);
      data = {};
      data = {
        'Lable': column?.inbound?.ex_label,
        'Flight': column?.inbound?.flight_count,
      };
      this.state.billingColumns.map((c) => {
        let field = c.field;
        if (this.state.billingFields.exclude_disc) {
          field = field.replace('_disc', '');
        }
        data[c.headerName] = column?.inbound?.[field];
      });
      csv_stat.push(data);
      if (column?.inbound?.flights?.length > 0) {
        column?.inbound?.flights.map((flight) => {
          data = {};
          data = {
            'Lable': flight.label,
            'Flight': flight.flight_count,
          };
          this.state.billingColumns.map((c) => {
            let field = c.field;
            if (this.state.billingFields.exclude_disc) {
              field = field.replace('_disc', '');
            }
            data[c.headerName] = flight[field];
          });
          csv_stat.push(data);
        });
      }
      data = {};
      data = {
        'Lable': column?.outbound?.ex_label,
        'Flight': column?.outbound?.flight_count,
      };
      this.state.billingColumns.map((c) => {
        let field = c.field;
        if (this.state.billingFields.exclude_disc) {
          field = field.replace('_disc', '');
        }
        data[c.headerName] = column?.outbound?.[field];
      });
      csv_stat.push(data);
      if (column?.outbound?.flights?.length > 0) {
        column?.outbound?.flights.map((flight) => {
          data = {};
          data = {
            'Lable': flight.label,
            'Flight': flight.flight_count,
          };
          this.state.billingColumns.map((c) => {
            let field = c.field;
            if (this.state.billingFields.exclude_disc) {
              field = field.replace('_disc', '');
            }
            data[c.headerName] = flight[field];
          });
          csv_stat.push(data);
        });
      }
    });
    return csv_stat;
  };

  handleExport = async () => {
    const state = { ...this.state };
    let csv_contract = [{}];
    let csv_stat = [{}];
    let csv_disc = [{}];
    let csv_invoice = [{}];
    this.fetchInvoiceData(false, async (status) => {
      if (status) {
        console.log(this.state.billingData, this.state.contractData);
        if (this.state.contractData && this.state.contractData.length > 0) {
          csv_contract = this.state.contractData.map((row) => {
            return {
              'Item': row.item,
              'Driver': row.driver,
              'Source': row.source,
              'Quantity': row.quantity,
            };
          });
          csv_invoice = await this.get_csv_cont() || [{}];
        }
      }
      this.setState({
        flightList: [],
        billingData: [],
        filteredData: [],
        contractData: [],
        accord_id: null,
        accord_type: null,
      });
      this.fetchProducts(async (status) => {
        if (status) {
          csv_stat = await this.get_csv_stat() || [{}];
        }
        this.setState({
          flightList: [],
          billingData: [],
          filteredData: [],
          contractData: [],
          accord_id: null,
          accord_type: null,
        });
        this.fetchDiscrepancies(null, async (status) => {
          if (status) {
            if (this.state.billingData.length > 0) {
              csv_disc = [];
              discrepancyType.map((type) => {
                csv_disc.push({
                  'Type': type,
                  'Total': this.state.billingData[0][type],
                  'Inbound': this.state.billingData[0]?.inbound[type],
                  'Outbound': this.state.billingData[0]?.outbound[type]
                });
              });
            }
          }
          const worksheet1 = XLSX.utils.json_to_sheet(csv_contract, { header: Object.keys(csv_contract?.[0]) });
          const worksheet2 = XLSX.utils.json_to_sheet(csv_stat, { header: Object.keys(csv_stat[0]) });
          const worksheet3 = XLSX.utils.json_to_sheet(csv_disc, { header: Object.keys(csv_disc[0]) });
          const worksheet4 = XLSX.utils.json_to_sheet(csv_invoice, { header: Object.keys(csv_invoice[0]) });

          // header bold
          worksheet1['!rows'] = [{ bold: true }];

          worksheet1['!cols'] = [
            { wch: 50 },
            { wch: 20 },
            { wch: 20 },
          ];
          worksheet2['!cols'] = [
            { wch: 30 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
          ];

          worksheet3['!cols'] = [
            { wch: 30 },
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
          ];

          worksheet4['!cols'] = [
            { wch: 10 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
          ];

          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet1, 'Contract Data');
          XLSX.utils.book_append_sheet(workbook, worksheet2, 'Statistics');
          XLSX.utils.book_append_sheet(workbook, worksheet3, 'Discrepancy');
          XLSX.utils.book_append_sheet(workbook, worksheet4, 'Invoice');
          // Generate XLSX file and trigger download
          const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
          });
          const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
          saveAs(blob, 'Flight Load Info.xlsx');
          this.setState({ ...state });
        });
      });
    });
  };

  handleModalOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  handleFlight = (type, data) => {
    this.setState({
      modalOpen: true,
      flightReportData: data.id
    });
  };

  progressCount = () => {
    this.setState((prevState) => {
      if (prevState.progress >= 100) {
        clearInterval(this.progressInterval);
        this.progressInterval = null; 
        return null; 
      }
      return { progress: prevState.progress + 10 };
    });
  };


  showHideOneOff = (e) => {
    this.setState({ showOneOff: e.target.checked });
  };
  fetchOneOff = () => {
    // GET /oneoff , invoice_dt
    http.get(`/oneoff?invoice_dt=${this.state.searchFields.month}&airline=${this.state.searchFields?.airline}&contract_type=${this.state.contract_Type}`)
      .then((response) => {
        this.setState({ oneOffData: response.data.data });
      });
  };
  editOneOff = (index, record) => {  // populate oneOffEdit
    this.setState({
      oneOffEdit: {
        ...record,
        index
      }
    });
  };
  deleteOneOff = (index, record) => {
    console.log('deleteOneOff', index, record);

  };
  clearOneOff = () => {
    this.setState({
      oneOffEdit: {
        index: null,
        id: null,
        label: '',
        amount: '',
        operation: '',
        cycle: '',
      }
    });
  };
  handleOneOffChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let oneOffEdit = this.state.oneOffEdit;
    oneOffEdit[name] = value;
    this.setState({ oneOffEdit });
  };
  updateOneOff = () => {
    console.log('updateOneOff&&&&&&&&&&&&&&&&&&&&&&&&7', this.state.oneOffData);
    let records = [];

    let id = null;
    if (this.state.oneOffData && this.state.oneOffData[0]) {
      id = this.state.oneOffData[0]?.id;
      records = this.state.oneOffData[0]?.records;
    }
    console.log('updateOneOff records before change', records);
    console.log('updateOneOff id***********************************', id);
    console.log('updateOneOff oneOffEdit', this.state.oneOffEdit);
    if (this.state.oneOffEdit.index !== null && this.state.oneOffEdit.index !== undefined) {
      records[this.state.oneOffEdit.index] = {
        label: this.state.oneOffEdit.label,
        amount: this.state.oneOffEdit.amount,
        operation: this.state.oneOffEdit.operation,
        cycle: this.state.oneOffEdit.cycle,
      };
    } else {
      records.push({
        label: this.state.oneOffEdit.label,
        amount: this.state.oneOffEdit.amount,
        operation: this.state.oneOffEdit.operation,
        cycle: this.state.oneOffEdit.cycle,
      });
    }
    console.log('updateOneOff records after change', records);
    http.post('/updateOneoff', {
      invoice_dt: this.state.searchFields.month,
      id: id || null,
      records: records,
      airlineId: this.state.searchFields?.airline,
      contract_type: this.state.contract_Type,
    })
      .then((response) => {
        this.setState({
          oneOffData: [response.data.data],
          oneOffEdit: {
            index: null,
            id: null,
            label: '',
            amount: '',
            operation: '',
            cycle: '',
          }
        });
      });
  };
  startTimer = () => {
    if(cookies.get('expiryTimer')) {
      this.timer = setInterval(() => {
        // cookies.remove('expiryTimer', { path: '/' });
        this.setState({ sessionExpired: true });
        this.stopInterval();
      }, 7200000); // 2hrs
    }
  };
  stopInterval = () => {
    clearInterval(this.timer);
    cookies.remove('expiryTimer', { path: '/' });
  };

  render() {
    // const { difference } = this.state;
    return (
      <>
        {/* <BillingReport
          modal={this.state.modal}
          action={this.state.action}
          handleOpen={this.handleOpen}
          onClose={this.onClose}
          flightList={this.state.flightList}
          onFilterMain={this.onFilterMain}
          // generateBillingReport={this.generateBillingReport}
          changeCheck={this.changeCheck}
          onChange={this.onChange}
          changeDateTime={this.changeDateAndTime}
          flightFields={this.state.flightFields}
          flightErrors={this.state.flightErrors}
          searchFields={this.state.searchFields}
          airlines={this.state.airlines}
          addFlight={this.addFlight}
          editFlight={this.editFlight}
          flightData={this.state.flightData}
          stations={this.props.stations}
          handleDelete={this.handleDelete}
          stationCode={this.station_code}
          openFlightsFolder={this.openFlightsFolder}
          flightDetails={this.state.flightDetails}
          flightID={this.state.flightID}
          showflightDetails={this.state.showflightDetails}
          flightStatus={this.state.flightStatus}
          billingData={this.state.billingData}
          filteredData={this.state.filteredData}
          billingColumns={this.state.billingColumns}

          billingFields={this.state.billingFields}
        /> */}
        <FlightLoadInfo
          genInvProgress={this.props.genInvProgress}
          modal={this.state.modal}
          action={this.state.action}
          handleOpen={this.handleOpen}
          onClose={this.onClose}
          handleExport={this.handleExport}
          // onFilterMain={this.onFilterMain}
          // generateBillingReport={this.generateBillingReport}
          changeCheck={this.changeCheck}
          onChange={this.onChange}
          changeDateTime={this.changeDateAndTime}
          flightFields={this.state.flightFields}
          flightErrors={this.state.flightErrors}
          searchFields={this.state.searchFields}
          cont_item={this.state.cont_item}
          airlines={this.state.airlines}
          airlineCode={this.state.airlineCode}
          addFlight={this.addFlight}
          editFlight={this.editFlight}
          flightData={this.state.flightData}
          stations={this.props.stations}
          handleDelete={this.handleDelete}
          stationCode={this.station_code}
          openFlightsFolder={this.openFlightsFolder}
          flightDetails={this.state.flightDetails}
          flightID={this.state.flightID}
          showflightDetails={this.state.showflightDetails}
          flightStatus={this.state.flightStatus}
          billingData={this.state.billingData}
          filteredData={this.state.filteredData}
          billingColumns={this.state.billingColumns}
          billingFields={this.state.billingFields}


          handleChange={this.handleChange}
          expanded={this.state.expanded}
          handleToggle={this.handleToggle}
          loadInfo={this.state.loadInfo}

          handleContractType={this.handleContractType}
          contract_Type={this.state.contract_Type}
          setContTBData={this.setContTBData}
          flightdata={this.state.flightdata}
          daylogdata={this.state.daylogdata}
          monthlogdata={this.state.monthlogdata}
          alcontractsData={this.state.alcontractsData}
          contractData={this.state.contractData}
          dayContractData={this.state.dayContractData}
          monthContractData={this.state.monthContractData}
          invoice_info={this.state.invoice_info}
          accord_id={this.state.accord_id}
          accord_type={this.state.accord_type}
          summaryData={this.state.summaryData}
          showSummary={this.state.showSummary}
          billingCol={this.state.billingCol}

          fetchInvoiceData={this.fetchInvoiceData}
          triggerNewBilling={this.triggerNewBilling}
          handleModalOpen={this.handleModalOpen}
          modalOpen={this.state.modalOpen}
          handleModalClose={this.handleModalClose}
          handleFlight={this.handleFlight}
          flightReportData={this.state.flightReportData}
          discrepancyCount={this.state.discrepancyCount}

          showHideOneOff={this.showHideOneOff}
          showOneOff={this.state.showOneOff}
          updateOneOff={this.updateOneOff}
          editOneOff={this.editOneOff}
          deleteOneOff={this.deleteOneOff}
          clearOneOff={this.clearOneOff}
          oneOffEdit={this.state.oneOffEdit}
          handleOneOffChange={this.handleOneOffChange}

          oneOffData={this.state.oneOffData}
          oneOff={
            this.state.oneOffData && this.state.oneOffData[0]?.records
              ? this.state.oneOffData[0].records.map((record, index) => ({
                ...record,
                id: index + 1, // or any other logic to generate unique keys
              }))
              : []
          }

          handlePopover={this.handlePopover}
          closePopover={this.closePopover}
          anchorEl={this.state.anchorEl}
          progress={this.state.progress}

          expiryTimer={this.state.timer}
          sessionExpired={this.state.sessionExpired}
          startTimer={this.startTimer}
          stopInterval={this.stopInterval}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { stations: state.stations };
};

const mapDispatchToProps = (dispatch) => {
  return { setDelete: (data) => dispatch(setDeleteAction(data)) };
};

// export default connect(mapStateToProps, mapDispatchToProps)(FlightLoadInfoContainer);
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(forwardRef((props, ref) => <FlightLoadInfoContainer {...props} ref={ref} />));