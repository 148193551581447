import React from 'react';
import http from 'utils/http';
import moment from 'moment';
import exportFromJSON from 'export-from-json';
import cookies from 'utils/cookies';
import Unscreened from './Unscreened';
import TopScreener from './TopScrenner';
import Alarms from './Alarms';
import Methods from './Methods';
import Measures from './Measures';
import ReportFilter from './ReportFilter';
import DateFilter from './Datefilter';
import analyticsStyle from './analytics.module.css';
import styles from '../../../custom/style.module.css';
import { Grid } from '@mui/material';
// import { SCREENING_MEASURES } from '../../../custom/constants';

class AnalyticsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      type: false,
      filterDate: {},
      airlineList: {},
      screenDetails: {},
      report: 'pcs',
      unsecreened: [],
      screenedArray: null,
      screenedTotal: null,
      checkedAirlines: {},
      filteredData: null,
      screenedFilter: null,
      measureObj: {},
      methodObj: {},
      filterMethods: [],
      methods: [],
      carrSelect: true,
      screener: '',
      screenerOptions: null,
      measureSelect: {},
      filterMeasures: [],
      alarm: {},
      filterAlarms: [],
      timeFilter: {
        f_time: moment().startOf('day').format('HH:mm'),
        t_time: moment().endOf('day').format('HH:mm')
      }
    };
  }

  componentDidMount() {
    this.setState((prev) => ({
      filterDate: {
        ...prev.filterDate,
        to_date: moment().format('YYYY-MM-DD'),
        from_date: moment().format('YYYY-MM-DD'),
      }
    }), () => {
      this.fetchData();
    });
    
    
    this.fetchAirlines();
    this.handleChangeCarrier();
    this.defaultFilter();
  }

  handlePopover = (type, anchorEl) => {
    // document.body.style.overflow = 'inherit !important';
    // document.body.classList.add(styles.pr24);
    this.setState({
      type,
      anchorEl
    });
  };

  handlePopoverClose = (type) => {
    this.setState({
      type,
      anchorEl: false 
    });
  };

  handleReport = (e) => {
    this.setState({ report: e.target.value }, () => { this.filterData(); });
  };

  handleDateFilter = (e) => {
    this.setState((prev) => ({
      filterDate: {
        ...prev.filterDate,
        [e.target.name] : e.target.value
      }
    }), () => {
      this.fetchData();
    });
    
  };

  fetchData = () => {
    let filterDate = this.state.filterDate; 
    if(filterDate.from_date && filterDate.to_date){ 
      http.get(`/analytics/screen?from=${filterDate.from_date}&to=${filterDate.to_date}`) 
        .then((response) => {
        // handle success
          if (response.data.data) {
            this.setState({
              filteredData: response.data.data,
              screenDetails: {
                details: response.data.data,
                screens: response.data.data?.screens,
              }
            }, () => {
              this.filterData();
            });}
        });

    }
  };

  filterData = () => {
    let filteredData = this.state.screenDetails && this.state.screenDetails?.details && this.state.screenDetails?.details?.filter((item) => this.state.checkedAirlines[item.airline.code] === true );  //DND
    let screensNestedArray = [];
    let screenedArray = null;
    let screenerOptions = [];
    let screens = {
      screenMethods: [],
      screenMeasure: [],
      alarmCount: 0,
      alarmCode: [], 
      topScreeners: null
    };
    let screenedTotal = null;
    let topScreeners = null;
    let filterMeasures = null;
    let filterAlarms = null;
    let measureObj = {
      measureArray: [],
      measureName: []
    };

    let alarm = {};
    filteredData?.map((list)=>{

      list?.screens && list.screens.map((screenList)=> {

        // alarm count
        if(screenList.alarmed === true) {
          screens.alarmCount = screens.alarmCount + 1;
        }
        
        screensNestedArray.push(list?.screens);
        const flattenedArray = screensNestedArray.flat(Infinity);
        screenedArray = flattenedArray.filter((arr, index, self) => {
          return index === self.findIndex((a) => a === arr);
        });
        let optionArray = screenedArray?.filter((s, self) => s.userId !== self.userId );
        screenerOptions = optionArray.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.userId === value.userId
          ))
        );
        this.setState({ screenerOptions: screenerOptions });
        if(this.state.screener) {
          screenedArray = screenedArray && screenedArray?.filter((item) => {
            return item.userId === this.state.screener;
          }
          );  
        }
        
        // Time Filter
        if (this.state.timeFilter.f_time && this.state.timeFilter.t_time) {
          screenedArray = screenedArray && screenedArray.filter((item) => {
            return item.screen_time > this.state.timeFilter.f_time && item.screen_time < this.state.timeFilter.t_time;
          });
        }
        
        // Top Screeners
        topScreeners = this.reduceFunction(screenedArray, 'topscreener');
        
        // measures
        filterMeasures = this.reduceFunction(screenedArray, 'measure');
        this.setState({ filterMeasures: filterMeasures }); //for download measures
        
        measureObj.measureArray = this.onRadioChange(filterMeasures);
        measureObj.measureName = filterMeasures && filterMeasures.map(ar => {
          return ar.measure;
        });
        // measureObj.measureName = ['Screening', 'Exemption', 'Alternative Screening'];
        // let objM = typeof measureObj.measureName;
        // console.log(measureObj.measureName === Object.values(SCREENING_MEASURES));
        // console.log(measureObj.measureName);
        // console.log( Object.values(SCREENING_MEASURES), ' Object.values(SCREENING_MEASURES)');        
        
        // methods
        let measureSelect = {
          measureColor: null,
          measure: null
        };
        let filterMethods= {};
        let methodObj= {
          methodCode: [],
          methodName:[] 
        };
        // measureSelect.measureColor = '#f0f';
        let screenedFilter = screenedArray && screenedArray.filter((item) => item.measure.type === screenedArray[0].measure.type);
        measureSelect.measure = screenedFilter[0]?.measure?.type;
        
        filterMethods = this.reduceFunction(screenedFilter, 'method');
    
        methodObj.methodArray = this.onRadioChange(filterMethods);
        filterMethods && filterMethods.map(ar => {
          methodObj.methodName.push(ar.name);
          methodObj.methodCode.push(ar.method);
        });
        this.setState({ 
          screenedFilter: screenedFilter,
          methodObj: methodObj,
          filterMethods: filterMethods,
          measureSelect: measureSelect,
        }, ()=>{
        });
        // xxx

        // Alarms
        filterAlarms = this.reduceFunction(screenedArray, 'alarms');
        this.setState({ filterAlarms: filterAlarms }); //for download measures
        alarm.alarmArray = this.onRadioChange(filterAlarms);
        alarm.alarmCode = filterAlarms && filterAlarms.map(ar => {
          return ar.alarm;
        });
        if(this.state.report === 'pcs'){
          screenedTotal = topScreeners.sort((a, b) => b.pcs - a.pcs);
        }
        else if(this.state.report === 'wgt'){
          screenedTotal = topScreeners.sort((a, b) => b.wgt - a.wgt);
        }
        else if(this.state.report === 'awb'){
          screenedTotal = topScreeners.sort((a, b) => b.awb - a.awb);
        }
      });
    });

    // CLEAR METHOD WHEN THERE IS NO DATA
    if(!filterMeasures) {
      this.setState({
        filterMethods: [],
        methodObj: {
          ...this.state.methodObj,
          methodArray: [],
          methodCode: [],
          methodName:[] 
        } 
      });
    }

    let screenedFilter = this.state.screenedArray && this.state.screenedArray.filter((item)=>  item.measure.type === this.state.screenedArray[0].measure.type);
    this.state.screenedArray && this.state.screenedArray.map((arr) => {
      screens.alarmCode.push(arr.alarm?.code);
      screens.screenMeasure.push(arr.measure?.type); 
    });
    screenedFilter && screenedFilter.map((arr) => {
      screens.screenMethods.push(arr.method.code); 
    });

    let unscreenedArray = [];
    filteredData && filteredData.map((s)=> {
      if (s?.screens?.length === 0) {
        unscreenedArray.push(s.screens?.length === 0 ? s : []); 
      }
    });

    this.setState((prev) => ({
      screenDetails: {
        ...prev.screenDetails,
        screens: screens,
      },
      filteredData: filteredData,
      unsecreened: unscreenedArray,
      screenedArray: screenedArray,
      screenedTotal: screenedTotal,
      alarm: alarm,
      measureObj: measureObj,
      screenedFilter: screenedFilter 
    }), () => {});
  };


  reduceFunction = (screenedArray, types) => {
    let reducedArray = screenedArray && Object.values(screenedArray?.reduce((pre, cur)=>{
      if(types === 'method') {
        if(cur && cur.method){
          if(pre[cur.method.code]){
            pre[cur.method.code]= {
              pcs: pre[cur.method.code].pcs + parseInt(cur.pcs),
              wgt: pre[cur.method.code].wgt + parseInt(cur.wgt),
              awb: (pre[cur.method.code].awb || 0) + 1,
              method: cur.method.code,
              name: cur.measure.type
            };
          }
          else{
            pre[cur.method.code]= {
              pcs:  parseInt(cur.pcs),
              wgt: parseInt(cur.wgt),
              awb: 1,
              method: cur.method.code,
              name: cur.measure.type
            };
          }
        }
      }
      else if (types === 'measure') {
        if(cur && cur.measure){
          if(pre[cur.measure.type]){
            pre[cur.measure.type]= {
              pcs: pre[cur.measure.type].pcs + parseInt(cur.pcs),
              wgt: pre[cur.measure.type].wgt + parseInt(cur.wgt),
              awb: (pre[cur.measure.type].awb || 0) + 1,
              measure: cur.measure.type
            };
          }
          else{
            pre[cur.measure.type]= {
              pcs:  parseInt(cur.pcs),
              wgt: parseInt(cur.wgt),
              awb: 1,
              measure: cur.measure.type
            };
          }
        }
      }
      else if (types === 'topscreener') {
        if(cur && cur.user){

          if (pre[cur.user.id]) {
            pre[cur.user.id] = {
              pcs: pre[cur.user.id].pcs + parseInt(cur.pcs),
              wgt: pre[cur.user.id].wgt + parseInt(cur.wgt),
              awb: (pre[cur.user.id].awb || 0) + 1,
              user: `${cur.user.first_name} ${cur.user.last_name}`
            };
          } else {
            pre[cur.user.id] = {
              pcs: parseInt(cur.pcs),
              wgt: parseInt(cur.wgt),
              awb: 1,
              user: `${cur.user.first_name} ${cur.user.last_name}`
            };
          }
        }
      }
      else if (types === 'alarms') {
        if(cur && cur.alarm){
          if(pre[cur.alarm?.name]){
            pre[cur.alarm.name]= {
              pcs: pre[cur.alarm.name].pcs + parseInt(cur.pcs),
              wgt: pre[cur.alarm.name].wgt + parseInt(cur.wgt),
              awb: (pre[cur.alarm.name].awb || 0) + 1,
              alarm: cur.alarm.code,
            };
          }
          else{
            pre[cur.alarm.name]= {
              pcs:  parseInt(cur.pcs),
              wgt: parseInt(cur.wgt),
              awb: 1,
              alarm: cur.alarm.code,
            };
          }
        }
      }
      return pre;
    }, {}));
    return reducedArray;
  };

  onRadioChange = (array) => {
    let filteredArray  = array && array.map(ar => {
      if(this.state.report === 'pcs'){
        return ar.pcs;
      }
      else if(this.state.report === 'wgt'){
        return ar.wgt;
      }
      else {
        return ar.awb;
      }
    });
    return filteredArray;
  };


  fetchAirlines = () => {
    http.get(`/airline?active=${true}`)
      .then((response) => {
        this.setState({ airlineList: response.data.data },() => { this.defaultFilter();          
        });
      });
  };

  defaultFilter = () => {
    let checkedAirlines = {};
    this.state.airlineList.length > 0 && this.state.airlineList?.map((item) => {
      checkedAirlines = {
        ...checkedAirlines,
        [item.code]: true,
      };
    });
    this.setState({ 
      carrSelect: true,
      checkedAirlines: checkedAirlines 
    }, () => { this.filterData(); });
  };

  handleChangeCarrier = (e, v) => {  
    this.setState({
      carrSelect: e,
      checkedAirlines: {
        ...this.state.checkedAirlines,
        [v]:this.state.carrSelect === true ? true : e 
      }
    }, () => { this.filterData(); });
  };

  handleChartClick = (event, elements) => {
    let measureSelect = {
      measureColor: null,
      measure: null
    };
    let filterMethods= {};
    let methodObj= {
      methodArray: [],
      methodCode: [],
      methodName:[] 
    };
    measureSelect.measureColor = elements && elements[0]?.element?.options.backgroundColor;
    // measureSelect.measure = elements && elements[0] ? 
    //   this.state.measureObj.measureName[elements[0].index] : 
    //   this.state.measureObj.measureName[0];
    const measureNameArr = this.state.measureObj?.measureName;
    measureSelect.measure = elements && elements[0] && measureNameArr
      ? measureNameArr[elements[0].index] || measureNameArr[0]
      : measureNameArr && measureNameArr[0];
    
    let screenedFilter = this.state.screenedArray && this.state.screenedArray.filter((item) => item.measure.type === measureSelect.measure);
    
    filterMethods = this.reduceFunction(screenedFilter, 'method');

    methodObj.methodArray = this.onRadioChange(filterMethods);
    filterMethods && filterMethods.map(ar => {
      methodObj.methodName.push(ar.name);
      methodObj.methodCode.push(ar.method);
    });
    this.setState({ 
      screenedFilter: screenedFilter,
      methodObj: methodObj,
      // filterMethods: filterMethods,
      measureSelect: measureSelect,
    }, ()=>{
    });
  };

  handleScreener = (e) => {
    this.setState({ screener: e.target.value }, () => { this.filterData(); });
  };

  handleTimeChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      timeFilter: {
        ...this.state.timeFilter,
        [name]: value
      }
    }, () => { this.filterData(); });
  };

  handleExport = (type) => {
    let carr = Object.keys(this.state.checkedAirlines).filter(key => this.state.checkedAirlines[key] === true);
    carr = carr.join(', ');
    let data = [];
    if(type !== 'unscreened') {
      let arrayType = [];
      switch (type) {
        case 'measure':
          arrayType = this.state.filterMeasures;
          break;
        case 'method':
        // arrayType = this.state.filterMethods; //for single measure data
          arrayType = this.state.methods;
          break;
        case 'alarms':
          arrayType = this.state.filterAlarms;
          break;
        case 'top_screener':
          arrayType = this.state.screenedTotal;
          break;
      
        default:
          break;
      }
      arrayType.map(a => {
        data.push({
          'From Date': this.state.filterDate.from_date,
          'To Date': this.state.filterDate.to_date,
          Station: cookies.get('station'),
          'Handled Carriers': carr,
          Airwaybill: a.awb,
          Pieces: a.pcs,
          Weight: a.wgt,
          ...(type === 'measure' ? { 'Security Measures Applies': a.measure } : {}),
          ...(type === 'method' ? { 
            Methods: a.method,
            Measures: a.name,
          } : {}),
          ...(type === 'alarms' ? { Alarm: a.alarm } : {}),
          ...(type === 'top_screener' ? { User: a.user } : {}),

        });
      });
    }
    else {
      this.state.unsecreened.map(a => {
        data.push({
          AWB: `${a.code}-${a.number}`,
          Org: a.org,
          Dest: a.dest,
          Pcs: a.pcs,
          Wgt: a.wgt,
          'Out Flt': `${a.flight_no}/${moment(a.flight_date).format('DD MMM').toUpperCase()}`,
          'FWD/INT/RFS': a.forwarder,
          'Pre-screen': a.pre_screened ? 'Yes' : 'No',
          'Rcv Time': a.received_date
        });
      });
    }

    exportFromJSON({
      data,
      fileName: type,
      exportType: 'csv' 
    });
  };

  render() {
    return (
      <div className={`${styles.mt4}`}>
        <DateFilter 
          handleDateFilter={this.handleDateFilter}
          filterDate={this.state.filterDate}
        />

        <Grid container spacing={3} className={`${analyticsStyle.customerGridContainer}`}>
          <Grid item className={`${analyticsStyle.customerGridItemsFilter}`}>
            <ReportFilter 
              carrSelect={this.state.carrSelect}
              checkedAirlines={this.state.checkedAirlines}
              handleScreener={this.handleScreener}
              airlineList={this.state.airlineList}
              handleReport={this.handleReport}
              report={this.state.report}
              handleTimeChange={this.handleTimeChange}
              timeFilter={this.state.timeFilter}
              screenerOptions={this.state.screenerOptions}
              handleChangeCarrier={this.handleChangeCarrier}
            />
          </Grid>

          <Grid item className={`${analyticsStyle.customerGridItemsData} ${analyticsStyle.securityscreening}`}>
            <div className={`${analyticsStyle.customerGridItems}`}>
              <Grid container spacing={3} className={`${analyticsStyle.wtOuterGridContainer}`}>
                <Grid item xl={4} lg={5} md={6} sm={12} xs={12} className={`${analyticsStyle.wtOuterGridItems}`}>
                  <Measures 
                    handlePopover={this.handlePopover}
                    handlePopoverClose={this.handlePopoverClose}
                    anchorEl={this.state.anchorEl}
                    type={this.state.type}
                    measureObj={this.state.measureObj}
                    handleChartClick={this.handleChartClick}
                    handleExport={this.handleExport}
                  />
                </Grid>

                <Grid item xl={8} lg={7} md={6} sm={12} xs={12} className={`${analyticsStyle.wtOuterGridItems}`}>
                  <Methods 
                    report={this.state.report}
                    handlePopover={this.handlePopover}
                    handlePopoverClose={this.handlePopoverClose}
                    anchorEl={this.state.anchorEl}
                    type={this.state.type}
                    measureObj={this.state.measureObj}
                    methodObj={this.state.methodObj}
                    measureSelect={this.state.measureSelect}
                    filterMethods={this.state.filterMethods}
                    handleExport={this.handleExport}
                  />
                </Grid>

                <Grid item xl={4} lg={5} md={12} sm={12} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xl={12} lg={12} md={6} sm={6} xs={12} className={`${analyticsStyle.wtOuterGridItems}`}>
                      <Alarms 
                        report={this.state.report}
                        screenDetails={this.state.screenDetails}
                        handleExport={this.handleExport}
                        alarm={this.state.alarm}
                      />
                    </Grid>

                    <Grid item xl={12} lg={12} md={6} sm={6} xs={12} className={`${analyticsStyle.wtOuterGridItems}`}>
                      <TopScreener 
                        report={this.state.report}
                        screenedTotal={this.state.screenedTotal}
                        handleExport={this.handleExport}
                      /> 
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xl={8} lg={7} md={12} sm={12} xs={12} className={`${analyticsStyle.wtOuterGridItems}`}>
                  <Unscreened 
                    unsecreened={this.state.unsecreened}
                    handleExport={this.handleExport}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default AnalyticsContainer;
