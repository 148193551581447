import React from 'react';
import { Stack, Grid, FormControl, MenuItem, Box, FormHelperText } from '@mui/material';
import { StyledCard, StyledCardTitle, CustomTextField, InputProps, CustomInputLabel, CustomSelect, SelectInputProps, StyledDataGrid, DataGridProps, CustomButton } from '../../../../../custom/components';
import { AddIcon, EditIcon } from '../../../../../custom/constants';
import taxStyles from './tax.module.css';
import styles from '../../../../../custom/style.module.css';
import TaxModal from './TaxModal';
import TaxDocPreview from './TaxDocPreview';
import cookies from 'utils/cookies';

const station_code = cookies.get('station_code');

const TaxModule = (props) => {
  
  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      minWidth: 100,
      flex: 2,
    },
    {
      field: 'type',
      headerName: 'Type',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'cycle',
      headerName: 'Cycle',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'value',
      headerName: 'Rate',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      width: 90,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (data) => {
        return (
          <Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
            <div onClick={() => props.handleOpen('edit', data.row)} >
              <EditIcon />
            </div>
          </Stack>
        );
      }
    },
  ];

  return (
    <>    
      <Grid container spacing={3}>
        <Grid item container spacing={3} xs={12} sm={12} md={12} lg={6}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <StyledCard className={styles.h100}>
              <Stack className={`${styles.mb3}`} direction='row' spacing={2} alignItems='center'>
                <StyledCardTitle component='div' className={styles.pb2}>Invoice</StyledCardTitle>
                <CustomSelect
                  {...SelectInputProps}
                  id='airlineId'
                  labelId='airline-label'
                  fullWidth
                  autoFocus
                  onChange={props.onChange}
                  name='airlineId'
                  style={{ width: '90px' }}
                  value={!props.airlineId ? 'placeholder' : props.airlineId}
                  // classes={{ select: !props.flightFields.airline ? styles.colorPlaceholder : '' }}
                >
                  <MenuItem value='placeholder' disabled >Select an airline</MenuItem>
                  {props?.airlines?.map((airline, i) => (
                    <MenuItem key={i} value={airline.id}>
                      {airline.code}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </Stack>
              <Grid container spacing={2} columnSpacing={3}>
                <Grid item xs={12}>
                  <CustomInputLabel id='invoice_code_label'>Invoice Code</CustomInputLabel>
                  <FormControl variant='standard' fullWidth>
                    <CustomSelect
                      {...SelectInputProps}
                      id=''
                      labelId='invoice_code_label'
                      fullWidth
                      name='invoice_code'
                      onChange={props.onChange}
                      value={!props.invoice_code ? 'placeholder' : props.invoice_code}
                    >
                      <MenuItem value='placeholder' disabled>Select an Invoice Number format</MenuItem>
                      <MenuItem value='ACSTN01JAN2024'>{`${props.airlineCode}${station_code}01JAN2024`}</MenuItem>
                      <MenuItem value='ACSTNCTJAN2024'>{`${props.airlineCode}${station_code}CHJAN2024`}</MenuItem>
                      <MenuItem value='INV-AC-CT-STN-JAN24'>{`INV-${props.airlineCode}-CH-${station_code}-JAN2024`}</MenuItem>
                      <MenuItem value='INV-Airline Code-0001'>INV-Airline Code-0001</MenuItem>
                      <MenuItem value='INV-AC-Month-01 / INV-AC-Month-02'>INV-AC-Month-01 / INV-AC-Month-02</MenuItem>
                    </CustomSelect>
                    <FormHelperText>{props.invoiceErrors.invoice_code}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item container xs={12} sm={6}  columnSpacing={3}  rowSpacing={2} alignItems={'center'}>
                  <Grid item xs={12}>
                    <CustomInputLabel id='bill_from_details' className={styles.radioLabel}>Bill From Details</CustomInputLabel>
                  </Grid>
                  <Grid item container xs={12} sm={12} md={12} lg={12} direction={{
                    md: 'row',
                    xs: 'column' 
                  }} spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <CustomInputLabel id='name'>Name</CustomInputLabel>
                      <CustomTextField
                        {...InputProps}
                        id='bill_from'
                        placeholder='Name'
                        InputProps={{ disableUnderline: true }}
                        name='from_name'
                        fullWidth
                        onChange={props.onChange}
                        value={props.from_name}
                      />
                      <FormHelperText>{props.invoiceErrors.from_name}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <CustomInputLabel id='phone_from'>Phone Number</CustomInputLabel>
                      <CustomTextField
                        {...InputProps}
                        id='phone_from'
                        placeholder='Phone number'
                        InputProps={{ disableUnderline: true, }}
                        name='from_contact'
                        fullWidth
                        onChange={props.onChange}
                        value={props.from_contact}
                      />
                      <FormHelperText>{props.invoiceErrors.from_contact}</FormHelperText>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CustomInputLabel id='bill_from_address'>Address</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      id='bill_from_address'
                      placeholder='Address'
                      multiline
                      rows={4}
                      name='from_address'
                      fullWidth
                      onChange={props.onChange}
                      value={props.from_address}
                    />
                    <FormHelperText>{props.invoiceErrors.from_address}</FormHelperText>
                  </Grid>
                </Grid>

                <Grid item container xs={12} sm={6} columnSpacing={2} rowSpacing={1} alignItems={'center'}>
                  <Grid item xs={12}>
                    <CustomInputLabel id='bill_to_details' className={styles.radioLabel}>Bill To Details</CustomInputLabel>
                  </Grid>
                  <Grid item container xs={12} sm={12} md={12} lg={12} direction='row' spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <CustomInputLabel id='bill_to'>Name</CustomInputLabel>
                      <CustomTextField
                        {...InputProps}
                        id='bill_to'
                        placeholder='Name'
                        InputProps={{ disableUnderline: true, }}
                        name='to_name'
                        fullWidth
                        onChange={props.onChange}
                        value={props.to_name}
                      />
                      <FormHelperText>{props.invoiceErrors.to_name}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <CustomInputLabel id='phone_to'>Phone Number</CustomInputLabel>
                      <CustomTextField
                        {...InputProps}
                        id='phone_to'
                        placeholder='Phone number'
                        InputProps={{ disableUnderline: true, }}
                        name='to_contact'
                        fullWidth
                        onChange={props.onChange}
                        value={props.to_contact}
                      />
                      <FormHelperText>{props.invoiceErrors.to_contact}</FormHelperText>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CustomInputLabel id='bill_to_address'>Address</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      id='bill_to_address'
                      placeholder='Address'
                      multiline
                      rows={4}
                      name='to_address'
                      fullWidth
                      onChange={props.onChange}
                      value={props.to_address}
                    />
                    <FormHelperText>{props.invoiceErrors.to_address}</FormHelperText>
                  </Grid>
                </Grid>
                <Grid item xs>
                  <CustomButton variant='contained' onClick={()=>props.saveInvoiceDetails('invoice_code')} className={`${taxStyles.submitBtn}`} >Save</CustomButton>
                </Grid>
              </Grid>
            </StyledCard>
          </Grid>
          <Grid item  xs={12} sm={12} md={12} lg={12}>
            <div className={styles.h100}>
              <StyledCard className={styles.h100}>
                <Box className={`${styles.fSpaceBtw} ${styles.mb2}`} >
                  <StyledCardTitle gutterBottom>Invoice Add Ons</StyledCardTitle>
                  <AddIcon className={styles.addIcon} title='Add Tax' onClick={() => props.handleOpen('add')} />
                </Box>

                <div className={styles.tableWrapper}>
                  <Box className={`${styles.tableOuter} ${taxStyles.taxTableOuter}`}>
                    <StyledDataGrid
                      {...DataGridProps}
                      rows={props.taxList}
                      columns={columns}
                      param={props.taxList?.length}
                    />
                  </Box>
                </div>
              </StyledCard>

              <TaxModal
                addEditTax={props.addEditTax}
                onModalChange={props.onModalChange}
                handleClose={props.handleClose}
                openAddTax={props.openAddTax}
                taxFields={props.taxFields}
                taxErrors={props.taxErrors}
                type={props.type}
              />
              
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6}>
          <TaxDocPreview 
            airlineId = {props.airlineId}
            invoice_code={props.invoice_code}
            invoice_code_text={props.invoice_code_text}
            from_details={{ 
              from_name: props.from_name,
              from_contact: props.from_contact,
              from_address: props.from_address,
            }}
            to_details={{ 
              to_name: props.to_name,
              to_contact: props.to_contact,
              to_address: props.to_address,
            }}
            taxList={props.taxList}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default TaxModule;

