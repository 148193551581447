import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const textColor = '#393939';
const borderColor = 'lightgrey';

const cellProperties = {
  padding: 4,
  paddingLeft: 4,
  fontSize: 9,
  color: textColor,
  borderRightColor: borderColor,
  borderRightWidth: 1,
  height: '100%',
  whiteSpace: 'break-spaces',
};
const headProperties = {
  paddingRight: 1,
  paddingLeft: 1,
  fontSize: 9,
  color: textColor,

};

const styles = StyleSheet.create({
  headerContainer: { marginTop: 24 },
  label: {
    fontSize: 10,
    paddingBottom: 3,
    lineHeight: 'normal',
  },
  data: {
    fontSize: 10,
    paddingBottom: 3,
    lineHeight: 'normal',
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 20,
    borderWidth: 1,
    borderColor: 'lightgrey',
    // borderColor: borderColor,
  },
  container: {
    flexDirection: 'row',
    borderBottomColor: 'lightgrey',
    backgroundColor: '#eaeaea',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 20,
    textAlign: 'center',
    // fontStyle: 'bold',
    flexGrow: 1,
    // color: 'black'
  },
  tableRow: {
    flexDirection: 'row',
    borderRight: '1px solid lightgrey',
    alignItems: 'center',
    // height: 30,
    fontStyle: 'bold',
  },
  tableRowOuter: {
    flexDirection: 'column',
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    fontStyle: 'bold',
  },
  description: {
    width: '55%',
    // width: '20%',
    textAlign: 'left',
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
    padding: 4,
    paddingLeft: 8,
    fontSize: 9,

    display: 'flex',
    flexDirection: 'column',
    color: textColor,
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  inlinedesc: {
    color: 'grey',
    fontSize: 8,
  },
  qty: {
    width: '15%',
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
    fontSize: 9,
    color: textColor,

    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  rate: {
    width: '15%',
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
    paddingRight: 8,
    paddingLeft: 14,
    fontSize: 9,
    color: textColor,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  amount: {
    width: '15%',
    paddingRight: 8,
    paddingLeft: 14,
    fontSize: 9,
    color: textColor,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  inlinedescWrapper: { flexDirection: 'row', },
  divider: {
    color: 'grey',
    fontSize: 8,
    margin: '0 4px' 
  },
  title: {
    fontSize: 12,
    fontWeight: 'bold',
    // borderBottom: '1px solid lightgrey',
    paddingBottom: 2,
    marginBottom: '-10px',
  },

  header10: {
    ...headProperties,
    width: '10%',
  },
  cell10: {
    ...cellProperties,
    width: '10%',
  },
  header12: {
    ...headProperties,
    width: '13%',
  },
  cell12: {
    ...cellProperties,
    width: '13%',
  },
  header18: {
    ...headProperties,
    width: '18%',
  },
  cell18: {
    ...cellProperties,
    width: '18%',
  },
  header15: {
    ...headProperties,
    width: '15%',
  },
  cell15: {
    ...cellProperties,
    width: '15%',
  },
  header20: {
    ...headProperties,
    width: '20%',
  },
  cell20: {
    ...cellProperties,
    width: '20%',
  },
  header40: {
    ...headProperties,
    width: '40%',
  },
  cell40: {
    ...cellProperties,
    width: '40%',
  },
  noDataRow: {
    fontSize: 9,
    width: '100%',
    textAlign: 'center',
    padding: 4,
    color: textColor,
    borderColor: borderColor,
    borderWidth: 1,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  remarks: {
    width: '100%',
    padding: 4,
    fontSize: 9,
    color: textColor,
    borderRightColor: borderColor,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTop: '1px dashed grey'
  },
  noBr: { borderRightWidth: 0, }
});


const Discrepancy = (props) => {
  const { discrepancy } = props;

  return (
    <View style={styles.headerContainer}>
      <Text style={styles.title}>Discrepancy</Text>
      <View style={styles.tableContainer}>

        <View style={styles.container}>
          <Text style={styles.header15}>Type</Text>
          <Text style={styles.header15}>ULD</Text>
          <Text style={styles.header10}>ULD Type</Text>
          <Text style={styles.header10}>Handled As</Text>
          <Text style={styles.header18}>Airwaybill</Text>
          <Text style={styles.header10}>Prod</Text>
          <Text style={styles.header12}>Org-Dest</Text>
          <Text style={styles.header10}>Total Pcs</Text>
          <Text style={styles.header10}>Total Wgt</Text>
          <Text style={styles.header10}>Dsc Pcs</Text>
          <Text style={styles.header10}>Dsc Wgt</Text>
        </View>

        {discrepancy && discrepancy.length > 0 ? 
          discrepancy?.map( item =>
            <View style={styles.tableRowOuter} key={item.id}>
              <View style={styles.tableRow} >
                <Text style={styles.cell15}>{item.type}</Text>
                <Text style={styles.cell15}>{item.uld_no}</Text>
                <Text style={styles.cell10}>{item.uld_type}</Text>
                <Text style={styles.cell10}>{item.handled_as}</Text>
                <Text style={styles.cell18}>{item.awb_no}</Text>
                <Text style={styles.cell10}>{item.prod_code}</Text>
                <Text style={styles.cell12}>{item.org} - {item.dest}</Text>
                <Text style={styles.cell10}>{item.t_pcs}</Text>
                <Text style={styles.cell10}>{item.t_wgt}</Text>
                <Text style={styles.cell10}>{item.d_pcs}</Text>
                <Text style={[styles.cell10, styles.noBr]}>{item.d_wgt}</Text>
              </View>
              <Text style={styles.remarks}>Remarks: {item?.remark || '-'}</Text>
            </View>

          ) : <View  style={styles.noDataRow}>
            <Text>No Data</Text>
          </View>}

      </View>
    </View>
  );
};
  
export default Discrepancy;