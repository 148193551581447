import React, { Fragment } from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader';
import InvoiceTableRow from './InvoiceTableRow';
// import InvoiceTableBlankSpace from './InvoiceTableBlankSpace';
import InvoiceTableFooter from './InvoiceTableFooter';
import cookies from 'utils/cookies';

// const tableRowsCount = 11;
const borderColor = 'lightgrey';
// const borderColor = '#39393900';

const subtotal = (items, dayContractData, monthContractData, billingFields) => {
  let service_logs = [ ...dayContractData, ...monthContractData];
  let total = 0;
  if (items && items.length > 0) {
    items.forEach(item => {
      if( billingFields?.exclude_disc){
        if(billingFields?.transit === 'none' || !billingFields?.transit){
          if (!isNaN(item.totalrate) && (item?.totalrate || item?.totalrate === 0)) {
            total += parseFloat(item.totalrate);
          } else if (item.quantity && item.rate && !isNaN(item.rate)) {
            total += item.quantity * item.rate;
          }
        } else if(billingFields?.transit === 'inbound'){
          if (!isNaN(item.in_totalrate) && (item?.in_totalrate || item?.in_totalrate === 0)) {
            total += parseFloat(item.in_totalrate);
          } else if (item.in_quantity && item.in_rate && !isNaN(item.in_rate)) {
            total += item.in_quantity * item.in_rate;
          }
        } else {
          if (!isNaN(item.out_totalrate) && (item?.out_totalrate || item?.out_totalrate === 0)) {
            total += parseFloat(item.out_totalrate);
          } else if (item.out_quantity && item.out_rate && !isNaN(item.out_rate)) {
            total += item.out_quantity * item.out_rate;
          }
        }
      } else {
        if(billingFields?.transit === 'none' || !billingFields?.transit){
          if (!isNaN(item.dis_totalrate) && (item?.dis_totalrate || item?.dis_totalrate === 0)) {
            total += parseFloat(item.dis_totalrate);
          } else if (item.dis_quantity && item.dis_rate && !isNaN(item.dis_rate)) {
            total += item.dis_quantity * item.dis_rate;
          }
        } else if(billingFields?.transit === 'inbound'){
          if (!isNaN(item.dis_in_totalrate) && (item?.dis_in_totalrate || item?.dis_in_totalrate === 0)) {
            total += parseFloat(item.dis_in_totalrate);
          } else if (item.dis_in_quantity && item.dis_in_rate && !isNaN(item.dis_in_rate)) {
            total += item.dis_in_quantity * item.dis_in_rate;
          }
        } else {
          if (!isNaN(item.dis_out_totalrate) && (item?.dis_out_totalrate || item?.dis_out_totalrate === 0)) {
            total += parseFloat(item.dis_out_totalrate);
          } else if (item.dis_out_quantity && item.dis_out_rate && !isNaN(item.dis_out_rate)) {
            total += item.dis_out_quantity * item.dis_out_rate;
          }
        }
      }
    });
  }
  if (service_logs && service_logs.length > 0) {
    service_logs.forEach(item => {
      if (!isNaN(item.totalrate) && (item?.totalrate || item?.totalrate === 0)) {
        total += parseFloat(item.totalrate);
      } else if (item.quantity && item.rate && !isNaN(item.rate)) {
        total += item.quantity * item.rate;
      }
    });
  }
  return total;
};

const grandtotal = (items, dayContractData, monthContractData, tax_details, billingFields, oneOff) => {
  let total = subtotal(items, dayContractData, monthContractData, billingFields) || 0;
  let grandtotal = total;
  if(tax_details && tax_details.length > 0){
    tax_details.forEach(tax => {
      let taxval = 0;
      if(tax.type === 'Percentage'){
        taxval = (total * tax.value) / 100;
      } else {
        taxval = tax.value;
      }
      grandtotal = grandtotal + taxval;
    });
  }
  if(oneOff && oneOff.length > 0){
    oneOff.forEach(oOf => {
      grandtotal = oOf.operation === 'SUB' ? parseFloat(grandtotal) - parseFloat(oOf.amount) : parseFloat(grandtotal) + parseFloat(oOf.amount);
    });
  }
  return grandtotal ;
};

const styles = StyleSheet.create({
  tableWrapper: { marginTop: 0, },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    // marginTop: 24,
    borderWidth: 1,
    borderColor: 'lightgrey',
    // borderColor: borderColor,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 0,
    paddingTop: 20
    // borderBottom: '1px solid lightgrey',
  },
  flightTitle: { fontSize: 14, },
  totalBorder: {
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    borderTopColor: borderColor,
    borderTopWidth: 1,
  },
  subTotalRow: {
    flexDirection: 'row', 
    alignItems: 'center',      
  },
  offSetCol: { width: '60%', },
  description: {
    width: '20%',
    textAlign: 'right',
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    paddingRight: 8,
    padding: 4,
    height: '100%',
    fontSize: 10,
  },
  total: {
    width: '20%',
    fontSize: 10,
    fontWeight: 600,
    padding: 4, 
    paddingRight: 8,
    paddingLeft: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  subTotalContainer: { marginTop: 20, },
  mt2: {
    marginTop: 30,
    borderBottom: '1px solid lightgrey', 
  },
  invoiceNote: {
    fontSize: 8,
    textAlign: 'left',
    color: 'grey',
    marginTop: 'auto',
    padding: 4,
  },
});

const getQuantity = (item, billingFields) => {
  console.log('getQuantity billingFields ******************', billingFields, 'item.dis_quantity', item.dis_quantity, 'item.quantity', item.quantity);
  let quantity = 0;
  if(billingFields?.exclude_disc) {
    if(billingFields?.transit === 'none' || !billingFields?.transit){
      quantity = item.quantity;
    } else if(billingFields?.transit === 'inbound'){
      quantity = item.in_quantity;
    } else {
      quantity = item.out_quantity;
    }
  } else {
    if(billingFields?.transit === 'none' || !billingFields?.transit){
      quantity = item.dis_quantity;
    } else if(billingFields?.transit === 'inbound'){
      quantity = item.dis_in_quantity;
    } else {
      quantity = item.dis_out_quantity;
    }
  }
  console.log('getQuantity quantity', quantity);
  return quantity;
};

const InvoiceItemsTable = ({ invoice }) => (
  console.log('InvoiceItemsTable daylogdata.length', invoice.daylogdata.length),
  console.log('InvoiceItemsTable monthlogdata.length', invoice.monthlogdata.length),
  <Fragment>
    <View style={styles.tableWrapper}>
      {
        invoice?.flightdata && invoice?.flightdata.length > 0 ?
          invoice.flightdata.map((flight, index) => {
            return (
              <View key={index} wrap={false}>
                <View style={styles.titleContainer}>
                  <Text style={styles.flightTitle}>{flight?.label+'('+flight?.org+'-'+flight?.dest+')' || null}</Text>
                </View>
                <View style={styles.tableContainer}>
                  <InvoiceTableHeader borderColor={borderColor} />
                  <InvoiceTableRow localeObj={invoice.localeObj} type={'flights'} items={flight?.contractData?.filter(item => (parseFloat(getQuantity(item, invoice.billingFields )) !== parseFloat(0) && getQuantity(item, invoice.billingFields ))) || []} borderColor={borderColor} searchFields={invoice.searchFields} billingFields={invoice.billingFields} />
                  {/* <InvoiceTableBlankSpace rowsCount={ tableRowsCount - invoice.items.length} borderColor={borderColor} /> */}
                  <InvoiceTableFooter localeObj={invoice.localeObj} items={flight?.contractData?.filter(item => parseFloat(getQuantity(item, invoice.billingFields )) !== parseFloat(0) && getQuantity(item, invoice.billingFields )) || []} borderColor={borderColor} billingFields={invoice.billingFields} />
                </View>
              </View>
            );
          }) : null
      }
      {/* <View break> */}
      {
        invoice.daylogdata && invoice.daylogdata.length > 0 ?
          invoice.daylogdata.map((daylog, index) => {
            let contractData = daylog?.contractData?.filter(item => parseFloat(item.quantity) !== parseFloat(0) && item.quantity) || [];
            return (
              <>
                <View key={index} wrap={false}>
                  <View style={styles.titleContainer}>
                    <Text style={styles.flightTitle}>{daylog?.label || null}</Text>
                  </View>
                  <View style={styles.tableContainer}>
                    <InvoiceTableHeader borderColor={borderColor} />
                    <InvoiceTableRow localeObj={invoice.localeObj} type={'daylogs'} items={contractData} borderColor={borderColor} searchFields={invoice.searchFields} billingFields={{ exclude_disc : true }} />
                    <InvoiceTableFooter localeObj={invoice.localeObj} items={contractData} borderColor={borderColor} billingFields={{ exclude_disc : true }} />
                  </View>
                </View>
              </>
            );
          })
          : null
      }
      {
        invoice.monthlogdata && invoice.monthlogdata.length > 0 ?
          invoice.monthlogdata.map((monthlog, index) => {
            console.log('InvoiceItemsTable monthlog', monthlog);
            let contractData = monthlog?.contractData?.filter(item => parseFloat(item.quantity) !== parseFloat(0) && item.quantity) || [] ;
            console.log('invoice.monthlogdata contractData',contractData);
            if(contractData)
              return (
                <>
                  <View key={index} wrap={false}>
                    <View  style={styles.titleContainer}>
                      <Text style={styles.flightTitle}>{monthlog.label}</Text>
                    </View>
                    <View style={styles.tableContainer}>
                      <InvoiceTableHeader borderColor={borderColor} />
                      <InvoiceTableRow localeObj={invoice.localeObj} type={'monthlogs'} items={contractData} borderColor={borderColor} searchFields={invoice.searchFields} billingFields={{ exclude_disc : true }} />
                      <InvoiceTableFooter localeObj={invoice.localeObj} items={contractData} borderColor={borderColor} billingFields={{ exclude_disc : true }} />
                    </View>
                  </View>
                </>
              );
          })
          : null
      }
      {
        <>
          <View wrap={false}>
            {invoice?.flightdata && invoice?.flightdata.length > 0 ? <Text style={[styles.flightTitle, styles.mt2]}>Summary of all Flights</Text> : null}
            <View style={styles.titleContainer}>
              <Text style={styles.flightTitle}>{invoice.airlines_code}</Text>
              {/* <Text style={styles.flightTitle}>$ 58</Text> */}
            </View>
            <View style={styles.tableContainer}>
              <InvoiceTableHeader borderColor={borderColor} />
              <InvoiceTableRow localeObj={invoice.localeObj} type={'flights'} items={invoice?.invoiceData.filter(item => parseFloat(getQuantity(item, invoice.billingFields )) !== parseFloat(0) && getQuantity(item, invoice.billingFields ))  || []} borderColor={borderColor} searchFields={invoice.searchFields} billingFields={invoice.billingFields} />
              {/* <InvoiceTableBlankSpace rowsCount={ tableRowsCount - invoice.invoiceData.length} borderColor={borderColor} /> */}
              <InvoiceTableFooter localeObj={invoice.localeObj} items={invoice.invoiceData.filter(item => parseFloat(getQuantity(item, invoice.billingFields )) !== parseFloat(0) && getQuantity(item, invoice.billingFields ))  || []} borderColor={borderColor} billingFields={invoice.billingFields} />
              {/* <Text style={styles.invoiceNote}>*** Minimum rate of $99 will be applicable.</Text> */}
            </View>
          </View>
          <View wrap={false}>
            <View style={styles.titleContainer}>
              <Text style={styles.flightTitle}>Day Logs</Text>
              {/* <Text style={styles.flightTitle}>$ 58</Text> */}
            </View>
            <View style={styles.tableContainer}>
              <InvoiceTableHeader borderColor={borderColor} />
              <InvoiceTableRow localeObj={invoice.localeObj} type={'daylogs'} items={invoice?.dayContractData || []} borderColor={borderColor} searchFields={invoice.searchFields} billingFields={{ exclude_disc : true }} />
              {/* <InvoiceTableBlankSpace rowsCount={ tableRowsCount - invoice.items.length} borderColor={borderColor} /> */}
              <InvoiceTableFooter localeObj={invoice.localeObj} items={invoice.dayContractData || []} borderColor={borderColor} billingFields={{ exclude_disc : true }} />
            </View>
          </View>
          <View wrap={false}>
            <View style={styles.titleContainer}>
              <Text style={styles.flightTitle}>Month Logs</Text>
              {/* <Text style={styles.flightTitle}>$ 58</Text> */}
            </View>
            <View style={styles.tableContainer}>
              <InvoiceTableHeader borderColor={borderColor} />
              <InvoiceTableRow localeObj={invoice.localeObj} type={'monthlogs'} items={invoice?.monthContractData || []} borderColor={borderColor} searchFields={invoice.searchFields} billingFields={{ exclude_disc : true }} />
              {/* <InvoiceTableBlankSpace rowsCount={ tableRowsCount - invoice.items.length} borderColor={borderColor} /> */}
              <InvoiceTableFooter localeObj={invoice.localeObj} items={invoice.monthContractData || []} borderColor={borderColor} billingFields={{ exclude_disc : true }} />
            </View>
          </View>
        </>
      }
      {/* </View> */}
    </View>

    <View style={styles.subTotalContainer} wrap={false}>
      <View style={styles.subTotalRow}>
        <Text style={styles.offSetCol}></Text>
        <Text style={[styles.description, styles.totalBorder]}>Sub Total</Text>
        <View style={[styles.total, styles.totalBorder]}>
          <Text>{ cookies.get('currency') }</Text> 
          <Text>{subtotal( invoice?.invoiceData  || [], invoice?.dayContractData || [], invoice?.monthContractData || [], invoice.billingFields ).toLocaleString(invoice.localeObj.locale, invoice.localeObj.options)}</Text>
        </View>
      </View>
      <>
        {
          invoice.invoice_info.tax && invoice.invoice_info.tax.length > 0 ?
            invoice.invoice_info.tax.map((tax, index) => {
              let taxval = 0;
              if(tax.type === 'Percentage'){
                taxval = (subtotal(invoice?.invoiceData  || [], invoice?.dayContractData || [], invoice?.monthContractData || [], invoice.billingFields) * tax.value) / 100;
              } else {
                taxval = tax.value;
              }
              return (
                <View style={styles.subTotalRow} key={index}>
                  <Text style={styles.offSetCol}></Text>
                  <Text style={styles.description} >{tax.title} {tax.type === 'Percentage' ? tax.value + '%' : null}</Text>
                  <View style={styles.total}>
                    <Text>{ cookies.get('currency') }</Text> 
                    <Text>{taxval.toLocaleString(invoice.localeObj.locale, invoice.localeObj.options)}</Text>
                  </View>
                </View>
              );
            }) : null
        } &&
        {
          invoice.oneOff && invoice.oneOff.length > 0 ?
            invoice.oneOff.flatMap((oOf, index) => {
              const repeatCount = oOf?.cycle.includes('C1,C2') && invoice.searchFields.period === 'month' ? 2 : 1;
              return Array.from({ length: repeatCount }).map((_, repeatIndex) => (
                <View style={styles.subTotalRow} key={`${index}-${repeatIndex}`}>
                  <Text style={styles.offSetCol}></Text>
                  <Text style={styles.description}>{oOf.label}</Text>
                  <View style={styles.total}>
                    <Text>{cookies.get('currency')}</Text>
                    <Text>{oOf.operation === 'SUB' ? '-' : ''}{oOf.amount}</Text>
                  </View>
                </View>
              ));
            }) : null
        }
      </>
      <View style={[styles.subTotalRow, styles.row]}>
        <Text style={styles.offSetCol}></Text>
        <Text style={[styles.description, styles.totalBorder]}>GRAND TOTAL</Text>
        <View style={[styles.total, styles.totalBorder]}>
          <Text>{ cookies.get('currency') }</Text> 
          <Text>{grandtotal( invoice?.invoiceData  || [], invoice?.dayContractData || [], invoice?.monthContractData || [], invoice.invoice_info.tax, invoice.billingFields, invoice.oneOff ).toLocaleString(invoice.localeObj.locale, invoice.localeObj.options) }</Text>
        </View>
      </View>
    </View>
  </Fragment>
);

export default InvoiceItemsTable;