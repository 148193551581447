import React from 'react';
import { Dialog, Stack, Grid, Button, Typography, Divider } from '@mui/material';
import styles from '../../../custom/style.module.css';
import contractStyles from '../admin/tabs/managecontracts/contracts.module.css'; // Corrected path
import { 
  CustomTextField, 
  CustomDialogTitle, 
  CustomDialogAction, 
  CustomInputLabel, 
  StyledCard, 
  InputProps, 
  CustomDialogContent, 
  CustomButton 
} from 'custom/components'; // Ensure this path is correct and within src/
import { CloseIconBtn } from 'custom/constants';
import StatusModal from 'components/pages/common/Status';


const EncryptionModal = (props) => {
  const LABEL_PROPS = {
    direction: { xs: 'column', },
    alignItems: { xs: 'flex-start', }
  };
  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={props.modal === 'encryption'} onClose={props.onClose} maxWidth={'sm'} fullWidth>
        <CustomDialogTitle id='form-dialog-title'>
          {!props.showOtp ? 'User Details' : 'OTP Verification'}
          <CloseIconBtn onClick={props.onClose} />
        </CustomDialogTitle>
        <form className={styles.w100}>
          <CustomDialogContent>

            {!props.showOtp &&
            <StyledCard>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Stack {...LABEL_PROPS}>
                    <CustomInputLabel htmlFor='description' required>Username</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      fullWidth
                      id='username'
                      placeholder='Username'
                      type='text'
                      name='username'
                      value={props.username}
                      onChange={props.handleOTPChange}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Stack {...LABEL_PROPS}>
                    <CustomInputLabel htmlFor='password' required>Password</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      fullWidth
                      id='password'
                      placeholder='Password'
                      type='password'
                      name='password'
                      value={props.password}
                      onChange={props.handleOTPChange}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </StyledCard>
            }

            {props.showOtp && (
              <>
                <StyledCard>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <Typography variant='subtitle2' component={'div'} color={'primary'} fontSize={'0.875rem'} textAlign={'center'} pb={2}
                      >OTP has been sent to your email and mobile number.
                      </Typography>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Stack {...LABEL_PROPS}>
                        <div className={styles.fSpaceBtw}>
                          <CustomInputLabel htmlFor='otp' required>One Time Password</CustomInputLabel>
                        </div>
                        <CustomTextField
                          {...InputProps}
                          fullWidth
                          autoFocus
                          id='otp'
                          placeholder='OTP'
                          type='otp'
                          name='otp'
                          value={props.otp}
                          onChange={props.handleOTPChange}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </StyledCard>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mt={2}>
                  <Typography variant='caption' component={'div'} color={'text.secondary'} fontSize={'0.8rem'}>
                Did not receive OTP?
                  </Typography>
                  <Button variant='outlined' size='small' className={contractStyles.resendOtp} onClick={() => props.authenticate()}> Resend OTP</Button>
                </Stack>
              </>
            )}

          </CustomDialogContent>

          <CustomDialogAction className={`${styles.fSpaceBtw}`}>
            <CustomButton variant='contained' type='reset' 
              onClick={()=> props.onClose()}
              className={styles.minWidth125}
            > Close </CustomButton> 
            {!props.showOtp ?
              <CustomButton variant='contained'
                className={styles.minWidth125} 
                onClick={() => props.authenticate()}
              >Submit</CustomButton> :
              <CustomButton variant='contained'
                className={styles.minWidth125} onClick={() => props.validateOTP()}> Submit </CustomButton> 
            }
          </CustomDialogAction>
        </form>
      </Dialog>

      <StatusModal 
        statusModal={props.modal === 'status'}
        onClose={props.onClose}
        statusMessage= {{
          type: 'success',
          size: 'md',
          title: 'OTP Verification',
          mainMessage:'OTP Successfully Verified',
          firstMessage:'This session is valid for the next two hours.',
          secondMessage:''
        }}
        expiryTimer={props.expiryTimer}
      />

    </div>
  );
};
export default EncryptionModal;