
import React, { Component } from 'react';
import { Document, Page } from 'react-pdf';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import commonStyle from './common.module.css';  
import { IconButton, Typography } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

class PDFSlider extends Component {
  state = {
    numPages: 1,
    currentPage: 1,
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  handlePageChange = newPage => {
    this.setState({ currentPage: newPage });
  };

  render() {
    const { numPages, currentPage } = this.state;

    const NextArrow = (props) => {
      const { newClass, onClick } = props;
      return (
        <IconButton
          aria-label='Next'
          onClick={onClick}
          className={newClass}
          classes={{ root: commonStyle.tsrightArrow }}
          size='large'
        >
          <ArrowRight color={currentPage === numPages ? 'disabled': 'primary'} fontSize='large' />
        </IconButton>
      );
    };

    const PrevArrow = (props) => {
      const { newClass, onClick } = props;
      return (
        <IconButton
          aria-label='Prev'
          onClick={onClick}
          className={newClass}
          classes={{ root: commonStyle.tsleftArrow }}
          size='large'
        >
          <ArrowLeft color={currentPage === 1 ? 'disabled': 'primary'} fontSize='large' />
        </IconButton>
      );
    };

    const settings = {
      arrows: true,
      infinite: false,
      dots: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      beforeChange: (oldIndex, newIndex) => {
        this.handlePageChange(newIndex + 1);
      },
    };

    return (
      <div className='pdf-slider-container'>
        <Slider {...settings}>
          {Array.from({ length: numPages }, (_, pageIndex) => (
            <div key={pageIndex} className='pdf-slide'>
              <Document
                file={this.props.file}
                onLoadSuccess={this.onDocumentLoadSuccess}
              >
                <Page pageNumber={pageIndex + 1} 
                  renderAnnotationLayer={false} 
                  renderTextLayer={false}
                  className={commonStyle.attachmentPage}
                  width={500}
                />
              </Document>
            </div>
          ))}
        </Slider>
        <div className={commonStyle.pagination}>
          <Typography variant='caption' component={'p'} textAlign={'center'} color={grey[600]}>
            {currentPage} / {numPages}
          </Typography>
        </div>
      </div>
    );
  }
}

export default PDFSlider;