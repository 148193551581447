import React from 'react';
import { Checkbox, Grid, Stack, InputAdornment, MenuItem } from '@mui/material';
import flightStyles from '../flightsfolder.module.css';
import styles from 'custom/style.module.css';
import { BorderLinearProgress, CustomCheckbox, CustomDateTimePicker, CustomInputLabel, CustomSearch, CustomSelectRounded, CustomTextField, InputProps, StyledCard, StyledCardTitle } from 'custom/components';
import moment from 'moment-timezone';
import Countdown from 'react-countdown';
import { getStationUTC, getStationDate, getStationDateTime } from 'utils/converter';
import { Search } from '@mui/icons-material';
import manifestStyles from '../tabs/manifest/manifest.module.css';
import cookies from 'utils/cookies';
import dayjs from 'dayjs';
import { EditIcon } from 'custom/constants';


const getpercentage = (date1, date2) => {
  let total = moment(date2).diff(date1, 'minutes');
  let current = moment(moment().utc().format('YYYY-MM-DD HH:mm:ss')).diff(date1, 'minutes');
  if (current < 0) {
    return 0;
  } else {
    if (current >= total) return 100;
    let rem = Math.round((current / total) * 100);
    return rem;
  }
};
const getCountdown = (date) => {
  // formate
  let dateUTC = moment.utc(date).local().format('YYYY-MM-DDTHH:mm:ss');
  return new Date(dateUTC);
};

const renderer = ({ hours, minutes, completed }) => {
  if (completed) {
    // Render a completed state
    return '00:00';
  } else {
    // Render a countdown
    return <span>{String(hours).padStart(2, '0')}:{String(minutes).padStart(2, '0')}</span>;
  }
};


const FlightsInfo = (props) => {
  const getGate = (type = 'data', data) => {
    const stn = cookies.get('station_code');
    if (type === 'data') {
      let gate = data?.org === stn ? data?.dept_gate : data?.arr_gate;
      if (props.printFunction) {
        props.printFunction(gate, 'flight_gate');
      }
      return gate;
    } else {
      return data?.org === stn ? 'dept_gate' : 'arr_gate';
    }
  };
  return (
    <>
      {!props.showReportModal &&
      <div className={`${styles.headerFieldContainer} ${styles.flightInfoHeader} ${manifestStyles.hidePrint}`}>
        <Stack direction={'row'} alignItems='center' spacing={2}>
          <CustomSearch
            id='search'
            type='search'
            placeholder='Flight Number'
            fullWidth
            name='awb'
            className={`${styles.brInput} ${styles.minWidth200} ${styles.fOne}`}
            value={props.search}
            onChange={props.onchangeSearch}
            InputProps={{
              classes: { input: styles.pr0 }, 
              endAdornment: (
                <InputAdornment position='end' >
                  <Search onClick={props.handleSearch} fontSize='inherit' />
                </InputAdornment>
              ),
            }}
            onKeyDown={
              (e) => {
                if (e.key === 'Enter') {
                  props.handleSearch();
                }
              }
            }
          />
          <CustomSelectRounded
            id='contract_types'
            name='contract_types'
            label={false}
            value={props.ctype}
            onChange={props.onSelect}
            className={`${styles.minWidth200} ${styles.fOne}`}
          >
            {
              cookies.get('contract_types')?.map((type, index) => (
                <MenuItem key={index} value={type}>{type}</MenuItem>
              ))
            }
          </CustomSelectRounded>
        </Stack>
      </div>
      }
      <div>
        <CustomCheckbox
          control={
            <Checkbox inputProps={{ 'aria-label': 'flight-info', }} checked={props.showFlight} onChange={e => props.changeCheck(e)} name='view_info'
              sx={{
                paddingTop: '6px',
                paddingBottom: '6px',
              }}
            />
          }
          label='Hide Flight Info'
          className={`${styles.mr2} ${styles.fJustifyEnd} ${manifestStyles.hidePrint}`}
        />

        {!props.showFlight &&
        <StyledCard className={`${styles.mb4}`}>
          <StyledCardTitle gutterBottom>Flight Info</StyledCardTitle>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
              <CustomInputLabel htmlFor='flight_no' aria-label='Enter Flight number'>Flight Number</CustomInputLabel>
              <CustomTextField
                {...InputProps}
                id='flight_no'
                fullWidth
                autoFocus
                type='text'
                // value={'LH462/21JAN23'}
                value={props?.flightData?.flight_no ? props?.flightData?.airline?.code + props?.flightData?.flight_no + '/' + moment(props?.flightData?.flight_date).format('DDMMMYY').toUpperCase() : ''}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={1} xl={1}>
              <CustomInputLabel htmlFor='ac_Type' aria-label='Enter ac type' className={styles.fSpaceBtw}>
                <span>AC Type </span>
                {!props.showReportModal &&
                  <EditIcon className={`${styles.smActionIcons} ${styles.p0} ${styles.fs0}`} onClick={() => props.onEditField('ac_type')} />
                }
              </CustomInputLabel>
              <div>
                {!props.showReportModal && props?.editField === 'ac_type' ? (
                  <CustomTextField
                    {...InputProps}
                    id='ac_type'
                    fullWidth
                    autoFocus
                    type='text'
                    defaultValue={props?.flightData?.ac_type}
                    onBlur={e => props.onChange('ac_type', props?.flightData?.ac_type, e.target.value)}
                  />) : (
                  <span className={flightStyles.fields} onClick={() => props.onEditField('ac_type')}>{props?.flightData?.ac_type}</span>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={1} xl={1}>
              <CustomInputLabel htmlFor='reg_no' aria-label='Enter registration' className={styles.fSpaceBtw}>
                <span>Registration</span>
                {!props.showReportModal &&
                  <EditIcon className={`${styles.smActionIcons} ${styles.p0} ${styles.fs0}`} onClick={() => props.onEditField('reg_no')} />
                }
              </CustomInputLabel>
              <div>
                {!props.showReportModal && props?.editField === 'reg_no' ? (
                  <CustomTextField
                    {...InputProps}
                    id='reg_no'
                    fullWidth
                    autoFocus
                    type='text'
                    defaultValue={props?.flightData?.reg_no}
                    onBlur={e => props.onChange('reg_no', props?.flightData?.reg_no, e.target.value)}
                  />) : (
                  <span className={flightStyles.fields} onClick={() => props.onEditField('reg_no')}>{props?.flightData?.reg_no}</span>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={1} xl={1}>
              <CustomInputLabel htmlFor='routing' aria-label='Enter Routing'>Routing</CustomInputLabel>
              <CustomTextField
                {...InputProps}
                id='routing'
                fullWidth
                autoFocus
                type='text'
                value={props?.flightData?.org ? props?.flightData?.org + '-' + props?.flightData?.dest : ''}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
              <CustomInputLabel htmlFor='Departure' aria-label='Enter Departure' className={styles.fSpaceBtw}>
                <span>Departure</span>
                {!props.showReportModal &&
                  <EditIcon className={`${styles.smActionIcons} ${styles.p0} ${styles.fs0}`} onClick={() => props.onEditField('act_dept')} />
                }
              </CustomInputLabel>
              {!props.showReportModal && props?.editField === 'act_dept' ? (
                <CustomDateTimePicker
                  slotProps={{
                    textField: {
                      ...InputProps,
                      placeholder: 'Actual Departure',
                      fullWidth: true
                    },
                  }}
                  format={'DD MMM YY HH:mm'}
                  className={`${styles.w100} ${styles.minWidth125}`}
                  ampm={false}
                  timeSteps={{
                    hours: 1,
                    minutes: 1,
                  }}
                  onClose={() => props.onEditField('')}
                  onAccept={(v) => props.onChange('act_dept', props?.flightData?.act_dept, getStationUTC(props?.flightData?.org, moment(v.$d).format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss'))}
                  value={dayjs(props?.flightData?.act_dept ? getStationDateTime(props?.flightData?.org, props?.flightData?.act_dept) : (props?.flightData?.est_dept ? getStationDateTime(props?.flightData?.org, props?.flightData?.est_dept) : (props?.flightData?.schdl_dept ? getStationDateTime(props?.flightData?.org, props?.flightData?.schdl_dept) : null)))}
                />) : (
                <Stack direction='row' alignItems='center' spacing={1} onClick={() => props.onEditField('act_dept')}>
                  <span className={flightStyles.fields}>{props?.flightData?.act_dept ? getStationDate(props?.flightData?.org, props?.flightData?.act_dept, 'DDMMMYY HHmm') : (props?.flightData?.est_dept ? getStationDate(props?.flightData?.org, props?.flightData?.est_dept, 'DDMMMYY HHmm') : (props?.flightData?.schdl_dept ? getStationDate(props?.flightData?.org, props?.flightData?.schdl_dept, 'DDMMMYY HHmm') : null))}</span>
                  <span className={flightStyles.inlineEl}>{props?.flightData?.act_dept ? 'A' : (props?.flightData?.est_dept ? 'E' : 'S')}</span>
                </Stack>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
              <CustomInputLabel htmlFor='arrival' aria-label='Enter Arrival' className={styles.fSpaceBtw}>
                <span>Arrival</span>
                {!props.showReportModal &&
                  <EditIcon className={`${styles.smActionIcons} ${styles.p0} ${styles.fs0}`} onClick={() => props.onEditField('act_arr')} />
                }
              </CustomInputLabel>
              {!props.showReportModal && props?.editField === 'act_arr' ? (
                <CustomDateTimePicker
                  slotProps={{
                    textField: {
                      ...InputProps,
                      placeholder: 'Actual Arrival',
                      fullWidth: true
                    },
                  }}
                  format={'DD MMM YY HH:mm'}
                  className={`${styles.w100} ${styles.minWidth125}`}
                  ampm={false}
                  timeSteps={{
                    hours: 1,
                    minutes: 1,
                  }}
                  onClose={() => props.onEditField('')}
                  onAccept={(v) => props.onChange('act_arr', props?.flightData?.act_arr, getStationUTC(props?.flightData?.dest, moment(v.$d).format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss'))}
                  value={dayjs(props?.flightData?.act_arr ? getStationDateTime(props?.flightData?.dest, props?.flightData?.act_arr) : (props?.flightData?.est_arr ? getStationDateTime(props?.flightData?.dest, props?.flightData?.est_arr) : (props?.flightData?.schdl_arr ? getStationDateTime(props?.flightData?.dest, props?.flightData?.schdl_arr) : null)))}
                />) : (
                <Stack direction='row' alignItems='center' spacing={1} onClick={() => props.onEditField('act_arr')}>
                  <span className={flightStyles.fields}>{props?.flightData?.act_arr ? getStationDate(props?.flightData?.dest, props?.flightData?.act_arr, 'DDMMMYY HHmm') : (props?.flightData?.est_arr ? getStationDate(props?.flightData?.dest, props?.flightData?.est_arr, 'DDMMMYY HHmm') : (props?.flightData?.schdl_arr ? getStationDate(props?.flightData?.dest, props?.flightData?.schdl_arr, 'DDMMMYY HHmm') : null))}</span>
                  <span className={flightStyles.inlineEl}>{props?.flightData?.act_arr ? 'A' : (props?.flightData?.est_arr ? 'E' : 'S')}</span>
                </Stack>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={1} xl={1}>
              <CustomInputLabel htmlFor='gate' aria-label='Enter gate' className={styles.fSpaceBtw}>
                <span>Gate</span>
                {!props.showReportModal &&
                  <EditIcon className={`${styles.smActionIcons} ${styles.p0} ${styles.fs0}`} onClick={() => props.onEditField('gate')} />
                }
              </CustomInputLabel>
              <div>
                {!props.showReportModal && props?.editField === 'gate' ? (
                  <CustomTextField
                    {...InputProps}
                    id='gate'
                    fullWidth
                    autoFocus
                    type='text'
                    defaultValue={getGate('data', props?.flightData)}
                    onBlur={e => props.onChange(getGate('key', props?.flightData), getGate('data', props?.flightData), e.target.value)}
                  />) : (
                  <span className={flightStyles.fields} onClick={() => props.onEditField('gate')}>{getGate('data', props?.flightData)} </span>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2} xl={2} className={styles.mt2}>
              <div className={flightStyles.progressWrapper}><BorderLinearProgress variant='determinate' value={getpercentage(props?.flightData?.act_dept || props?.flightData?.est_dept || props?.flightData?.schdl_dept, props?.flightData?.act_arr || props?.flightData?.est_arr || props?.flightData?.schdl_arr)} className={styles.w100} /> <small><Countdown date={getCountdown(props?.flightData?.act_arr || props?.flightData?.est_arr || props?.flightData?.schdl_arr)} startTime={getCountdown(props?.flightData?.act_dept || props?.flightData?.est_dept || props?.flightData?.schdl_dept)} renderer={renderer} /></small></div>
            </Grid>
          </Grid>
        </StyledCard>}
      </div>
    </>
  );
};

export default FlightsInfo;
