import React from 'react';
import styles from '../../../../custom/style.module.css';
import docStyle from '../docs.module.css';

const DataTable = ({ type, input, onChange, value, data }) => {
  const getHeader = () => {
    if (type === 'AWB') {
      return (
        <tr>
          <th colSpan={input ? 2 : 1}>Awb no.</th>
          <th>Pcs</th>
          <th>Org</th>
          <th>Dest</th>
        </tr>
      );
    } else if (type === 'FLIGHT') {
      return (
        <tr>
          <th colSpan={input ? 2 : 1}>Org</th>
          <th>Dest</th>
        </tr>
      );
    }
  };

  const handleRowClick = async (data) => {
    if (input) {
      await onChange(data.id, type === 'AWB' ? 'awbId' : 'flightId');
      if (type === 'FLIGHT') {
        await onChange(data.org, 'org');
        await onChange(data.dest, 'dest');
      }
    }
  };

  const getRow = (data) => {
    if (type === 'AWB') {
      return (
        <tr key={data.id} onClick={() => handleRowClick(data)}>
          {input && (
            <td>
              <input
                type='radio'
                name='awbId'
                checked={data.id === value}
                className={docStyle.htmlRadio}
              />
            </td>
          )}
          <td className={styles.textCenter}>{`${data.code}-${data.number}`}</td>
          <td className={styles.textCenter}>{data.pcs}</td>
          <td className={styles.textCenter}>{data.org}</td>
          <td className={styles.textCenter}>{data.dest}</td>
        </tr>
      );
    } else if (type === 'FLIGHT') {
      return (
        <tr key={data.id} onClick={() => handleRowClick(data)}>
          {input && (
            <td>
              <input
                type='radio'
                name='flightId'
                checked={data.id === value}
                className={docStyle.htmlRadio}
              />
            </td>
          )}
          <td className={styles.textCenter}>{data.org}</td>
          <td className={styles.textCenter}>{data.dest}</td>
        </tr>
      );
    }
  };

  return (
    <table className={`${styles.customTable} ${styles.w100}`}>
      <thead>{getHeader()}</thead>
      <tbody>
        {data.map((item) => getRow(item))}
      </tbody>
    </table>
  );
};

export default React.memo(DataTable);