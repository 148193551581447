import React from 'react';
import { Stack, IconButton, Popover, Typography } from '@mui/material';
import { PopoverHead, PopoverContent, CloseIconProps } from 'custom/components';
import styles from 'custom/style.module.css';
import { DeleteIcon, DownloadIcon } from 'custom/constants';
import CloseIcon from '@mui/icons-material/Close';
import environment from 'custom/environments';
import PDF from 'assets/images/pdf.png';

const Attachment = (props) => {
  const { doc } = props;
  return (
    <div>
      <Popover
        open={props.attachment}
        anchorEl={props.anchorEl}
        onClose={() => props.handlePopover(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      // classes={{ paper: reportStyle.attachmentPopover }}
      >
        <PopoverHead>
          <Typography variant='subtitle2' component='span'>Attachments</Typography>
          <IconButton
            aria-label='close'
            onClick={() => props.handlePopover(false)}
            sx={{ ...CloseIconProps }}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        </PopoverHead>
        <PopoverContent className={styles.textCenter}>
          <Stack direction='column' spacing={2} alignItems='center'>
            {/* {props.docs && props.docs.map(doc => ( */}
            <Stack direction='row' spacing={2} alignItems='center'>
              <div>
                {
                  doc?.src?.split('.')?.pop() == 'pdf' ?
                    <img src={PDF} alt='attachment' className={styles.w100} crossOrigin='use-credentials' />
                    :<img src={environment.apiService + 'document?key=' + doc?.src} alt='attachment' className={styles.w100} crossOrigin='use-credentials' />   
                }
              </div>
              <a href={environment.apiService + 'document?key=' + doc?.src} target='new'><DownloadIcon style={{ alignSelf: 'flex-start' }} /></a>
              <DeleteIcon onClick={(e) => props.onDeleteAttachment(doc.id, e.target)} />
            </Stack>
            {/* ))} */}
          </Stack>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default Attachment;