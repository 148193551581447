import React from 'react';
import AwbSearch from './AwbSearch';
import http from 'utils/http';
import cookies from 'utils/cookies';
import moment from 'moment';
import AddNotes from '../common/addnotes';
import evemts from 'utils/evemts';
import AttachmentContainer from '../docArchive/tabs/attachment/AttachmentContainer';

class AwbSearchContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      search: { awb: '', },
      data: false,
      history: [],
      anchorEl: null,
      popup: '',
      notes: [],
      noteFields: { note: '', },
      docs: [],
    };
    this.eventSource = null;
  }

  onChange = (e) => {
    // if (e.target.name === 'awb') then formate the value 006-12345678 using regx

    let value = e.target.value;
    if (e.target.name === 'awb') {
      value = value.replace(/\D/g, '');
      if (value.length > 3) {
        value = `${value.slice(0, 3)}-${value.slice(3)}`;
      }
    }
    this.setState({
      search: {
        ...this.state.search,
        [e.target.name]: value,
      },
    });
  };

  getHistory = () => {
    const { awb, trypod } = this.state.data;
    let stn = cookies.get('station_code');
    let history = [];
    if (awb && awb?.docs?.length > 0) {
      awb.docs.map((doc) => {
        history.push({
          type: `Document ${doc.manual ? 'Added' : 'Scanned'}`,
          data: { type: doc.type },
          date: doc.created,
          attachment: true,
          docs: [{ ...doc }]
        });
        if (!doc.manual) {
          history.push({
            type: 'Document Verified',
            data: {
              type: doc.type,
              awb_no: awb.code + '-' + awb.number,
              org: awb.org,
              dest: awb.dest,
              pcs: awb.pcs,
              wgt: awb.wgt,
            },
            user: doc.verified_by,
            date: doc.verified_date,
            attachment: true,
            docs: [{ ...doc }]
          });
        }
      });
    }
    if (awb?.flight_awbs?.length > 0) {
      awb.flight_awbs.map((awb) => {
        history.push({
          type: 'FFM',
          data: {
            flight: awb.flight?.airline?.code + awb.flight?.flight_no + '/' + moment(awb.flight?.flight_date).format('DDMMMYY') + ' ' + awb.flight?.org + '-' + awb.flight?.dest,
            awb_no: awb.awb_no,
            pcs: awb.pcs,
            wgt: awb.wgt,
            wgt_unit: awb.wgt_unit,
            org: awb.flight?.org,
            dest: awb.flight?.dest,
            spl_code: awb.flight?.spl_code,
          },
          date: (stn == awb.flight?.org ? awb?.flight?.out_ffm?.uploaded_date : awb?.flight?.in_ffm?.uploaded_date) || moment().format('YYYY-MM-DD HH:mm:ss'),
        });
        if (awb.flight && awb.flight?.flight_discrepancies && awb.flight?.flight_discrepancies.length > 0) {
          awb.flight.flight_discrepancies.map((discrepancy) => {
            if (awb.code + awb.number == discrepancy.awb_no?.replace('-', '')) {
              history.push({
                type: 'Discrepancy',
                data: {
                  flight: awb.flight?.airline?.code + awb.flight?.flight_no + '/' + moment(awb.flight?.flight_date).format('DDMMMYY') + ' ' + awb.flight?.org + '-' + awb.flight?.dest,
                  type: discrepancy.type,
                  awb_no: discrepancy.awb_no,
                  org: discrepancy.org,
                  dest: discrepancy.dest,
                  total_pcs: discrepancy.t_pcs,
                  total_wgt: discrepancy.t_wgt,
                  discrepancy_pcs: discrepancy.d_pcs,
                  discrepancy_wgt: discrepancy.d_wgt,
                  remark: discrepancy.remark,
                },
                date: discrepancy.created,
              });
            }
          });
        }
      });
    }
    if (trypod?.length > 0) {
      trypod.map((pod) => {
        history.push({
          type: 'Document',
          data: {
            type: pod.attachment_type,
            name: pod.uploaded_filename,
          },
          trypod:{
            name: pod.user_uploaded_name ||  pod.autodoc_recognition_added,
            company: pod.gha_company_name
          },
          date: pod.timestamp,
          attachment: true,
          docs: [{
            type: pod.attachment_type,
            verified_date: pod.timestamp,
            src: pod.attachment_key,
            trypod: true,
            verified_by: {
              first_name: pod.user_uploaded_name,
              last_name: '',
              master: false,
              app: pod.gha_company_name
            }
          }]
        });
      });
    }

    this.setState({
      history,
      notes: awb?.notes || []
    });
  };

  handleEvent = (event) => {
    let msg = JSON.parse(event.data);
    if (msg.action === 'add_note') {
      this.setState({ notes: [...this.state.notes, msg.data] });
    }
  };


  onSearch = () => {
    this.eventSource && this.eventSource.close();
    this.setState({
      data: false,
      history: [],
      notes: []
    });
    http.get('awb/search?awb=' + this.state.search.awb)
      .then((res) => {
        this.setState({ data: res.data?.data }, () => {
          this.getHistory();
          this.eventSource = evemts('awb', this.state.data.id);
          this.eventSource.onmessage = (event) => this.handleEvent(event);
        });
      });
  };

  openNotes = (e) => {
    this.setState({
      anchorEl: e.currentTarget,
      popup: 'notes',
    });
  };

  addNote = () => {
    if (this.state?.data?.id && this.state.noteFields.note)
      http.post('/note/awb/' + this.state?.data?.id, { note: this.state.noteFields.note, }).then(() => {
        this.setState({ noteFields: { note: '', }, });
      });
  };

  closePopup = () => {
    this.setState({
      anchorEl: null,
      popup: '',
    });
  };

  handleNotes = (e) => {
    this.setState({
      noteFields: {
        ...this.state.noteFields,
        [e.target.name]: e.target.value,
      },
    });
  };

  handlePopover = (popup, anchorEl, docs) => {
    this.setState({
      popup,
      anchorEl,
      docs
    });
  };


  render() {
    return (
      <>
        {this.state.popup === 'attached_docs' &&
          <AttachmentContainer
            handlePopover={this.handlePopover}
            anchorEl={this.anchorEl}
            modal={this.state.popup}
            docs={this.state.docs}
          />}
        {this.state.popup === 'notes' &&
          <AddNotes popup={this.state.popup} anchorEl={this.state.anchorEl} closePopup={this.closePopup} noteFields={this.state.noteFields} handleNotes={this.handleNotes} addNote={this.addNote} notes={this.state.notes} />}
        <AwbSearch
          search={this.state.search}
          history={this.state.history}
          onChange={this.onChange}
          onSearch={this.onSearch}
          openNotes={this.openNotes}
          handlePopover={this.handlePopover}
        />
      </>
    );
  }
}

export default AwbSearchContainer;