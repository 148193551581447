import React from 'react';
import FlightSchedule from './FlightSchedule';
import flightHttp from 'utils/flightHttp';
import http from 'utils/http';
import events from 'utils/evemts';
// import moment from "moment";
import cookies from 'utils/cookies';
import { connect } from 'react-redux';
import { setDeleteAction } from 'store/actions/deleteAction';
class FlightScheduleContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduleList: [],
      locationModal: false,
      scheduleFields: {},
      scheduleErrors: {},
      scheduleId: 0,
      openAddSchedule: false,
      type: 'add',
      flightSettings: {
        mandatory_number: false,
        limit_input: false
      },
      filterData: [],
      filter: {},
      airlines: [],
      airlineList: [],
      locationList: [],
      scheduleOrgDest: {
        org: null,
        dest: null
      },
    };
    this.eventSource = null;
  }

  componentDidMount() {
    this.fetchLocations();
    this.fetchAirlines();
    this.getConfigValues();
    this.fetchSchedules();
    this.eventSource = events('admin', 'flight_schedule');
    this.eventSource.onmessage = (event) => {
      const msg = JSON.parse(event.data || '{}');
      if (msg.data && (msg.data.org == cookies.get('station_code') || msg.data.dest == cookies.get('station_code'))) {
        if (msg.action == 'ADD') {
          this.setState({ scheduleList: [...this.state.scheduleList, msg.data] });
        }
        if (msg.action == 'UPDATE') {
          const scheduleList = [...this.state.scheduleList];
          const index = scheduleList.findIndex(c => msg.data && c.id == msg.data.id);
          if (index > -1) {
            scheduleList[index] = { ...msg.data };
            this.setState({ scheduleList });
          }
        }
      }
      if (msg.data && msg.action && msg.action == 'DELETE') {
        const scheduleList = [...this.state.scheduleList];
        const index = scheduleList.findIndex(c => msg.data && c.id == msg.data.id);
        if (index > -1) {
          scheduleList.splice(index, 1);
          this.setState({ scheduleList });
        }
      }
    };
  }

  componentWillUnmount() {
    this.eventSource.close();
  }

  fetchSchedules = () => {
    flightHttp.get('/flight_schedule?station=' + cookies.get('station_code'))
      .then((response) => {
        this.setState({ scheduleList: response?.data?.data || [] }, () => { this.filterData(); });
      });
  };

  filterData = () => {
    const { scheduleList, filter } = this.state;
    let data = scheduleList;
    let airlines = scheduleList.map((a) => a.airline);
    airlines = airlines.filter((item, index, self) => self.indexOf(item) === index);
    if (filter.airline && filter.airline != '') {
      data = data.filter((a) => a.airline == filter.airline);
    }
    this.setState({
      filterData: data,
      airlines: airlines 
    });
  };

  onChangeFilter = (e) => {
    this.setState({
      filter: {
        ...this.state.filter,
        [e.target.name]: e.target.value
      }
    }, () => this.filterData());
  };


  handleClose = (type) => {
    switch (type) {
      case 'add':
        this.setState({
          openAddSchedule: false,
          scheduleFields: {},
          scheduleErrors: {},
          scheduleOrgDest: {
            org: null,
            dest: null
          },
        });
        break;
      default:
        break;
    }
  };

  fetchLocations = () => {
    http.get('/locations')
      .then((response) => {
        const locationList = response.data.data.filter((item, index, self) =>
          self.findIndex((t) => t.iata_code === item.iata_code) === index
        );
        this.setState({ locationList: locationList });
      });
  };

  fetchAirlines = () => {
    http.get(`/airline?active=${true}`)
      .then((response) => {
        this.setState({ airlineList: response.data.data });
      });
  };

  onChange = (e, newvalue = null) => {
    const { name, id, value: targetValue } = e.target;
    const fieldName = name || id.split('-')[0];
    const value = newvalue?.iata_code || targetValue;
    const stn_code = cookies.get('station_code');

    if (fieldName === 'org' || fieldName === 'dest') {
      const { org, dest } = this.state.scheduleFields;
      let orgval = '';
      let destval = '';

      if (value === stn_code) {
        if (fieldName === 'org') {
          orgval = stn_code;
          destval = dest === stn_code ? '' : dest;
        } else if (fieldName === 'dest') {
          orgval = org === stn_code ? '' : org;
          destval = stn_code;
        }
      } else {
        orgval = fieldName === 'org' ? value : stn_code;
        destval = fieldName === 'dest' ? value : stn_code;
      }

      const getLocation = (iata_code) => this.state.locationList.find(c => c.iata_code === iata_code);

      this.setState({
        scheduleOrgDest: {
          org: getLocation(orgval),
          dest: getLocation(destval)
        },
        scheduleFields: {
          ...this.state.scheduleFields,
          org: orgval,
          dest: destval
        }
      }, () => console.log('this.state.scheduleFields', value, this.state.scheduleFields));
    } else {
      this.setState({
        scheduleFields: {
          ...this.state.scheduleFields,
          [fieldName]: value
        }
      });
    }
  };

  changeDateAndTime = (element, type) => {
    let fields = {};
    if (type == 'from') {
      if (this.state.scheduleFields.to && element.format('YYYY-MM-DD') > this.state.scheduleFields.to) {
        fields = { to: element.format('YYYY-MM-DD'), };
      }
    }
    this.setState({
      scheduleFields: {
        ...this.state.scheduleFields,
        [type]: (type == 'sta' || type == 'std') ? element.target.value : element.format('YYYY-MM-DD'),
        ...fields
      }
    });
  };

  changeCheck = (e) => {
    this.setState({
      scheduleFields: {
        ...this.state.scheduleFields,
        [e.target.name]: e.target.checked
      }
    });
  };

  updateSchedule = async (id, data) => {
    await flightHttp.put('/flight_schedule/' + id, data)
      .then(() => {
        this.setState({
          scheduleFields: {
            airlineId: 0,
            platform: '',
            number: '',
            org: '',
            dest: '',
            type: '',
            std: '',
            sta: '',
            from: '',
            to: ''
          },
          openAddSchedule: false,
          type: 'add'
        });
      });
  };

  deleteSchedule = async (id) => {
    await flightHttp.delete('/flight_schedule/' + id).then(({ data }) => {
      if (data.status == 'success') {
        this.props.setDelete({
          modal: false,
          id: false,
          anchorEl: false,
          onConfirm: () => { },
        });
      }
    });
  };

  handleDelete = (id, anchorEl) => {
    this.props.setDelete({
      modal: true,
      id,
      anchorEl,
      onConfirm: () => this.deleteSchedule(id),
    });
  };


  getConfigValues = async () => {
    http.get('/settings?key=flight_schedule')
      .then((response) => {
        // handle success
        if (response.data.data) {
          this.setState({ flightSettings: response.data.data.value });
        }
      });
  };

  changeSettings = (e) => {
    const name = e.target.name;
    this.setState({
      flightSettings: {
        ...this.state.flightSettings,
        [name]: !this.state.flightSettings[name]
      }
    }, () => this.setSettingsValues(this.state.flightSettings));
  };

  setSettingsValues = async (formData) => {
    await http.put('/settings?key=flight_schedule', formData);
  };

  addEditSchedule = async (e) => {
    e.preventDefault();
    let formdata = { ...this.state.scheduleFields };
    if (this.validation()) {
      if (this.state.scheduleId) {
        this.updateSchedule(this.state.scheduleId, JSON.stringify(formdata));
      } else {
        await flightHttp.post('/flight_schedule', JSON.stringify(formdata))
          .then(() => {
            this.setState({
              scheduleFields: {
                airlineId: 0,
                platform: '',
                number: '',
                org: '',
                dest: '',
                type: '',
                std: '',
                sta: '',
                from: '',
                to: ''
              },
              scheduleOrgDest: {
                org: null,
                dest: null
              },
              openAddSchedule: false
            });
          });
      }
    }
  };

  validation = () => {
    const scheduleFields = { ...this.state.scheduleFields };
    const error = {};
    let formValid = true;
    if (!scheduleFields.airline || (scheduleFields.airline == '')) {
      formValid = false;
      error.airline = 'Please select an airline';
    }
    if (!scheduleFields.flight_no || (scheduleFields.flight_no.trim().length === 0)) {
      formValid = false;
      error.flight_no = 'Please enter a flight number';
    }
    if (!scheduleFields.org || (scheduleFields.org.trim().length === 0)) {
      formValid = false;
      error.org = 'Please enter an origin';
    }
    if (!scheduleFields.dest || (scheduleFields.dest.trim().length === 0)) {
      formValid = false;
      error.dest = 'Please enter a destination';
    }
    if (!scheduleFields.platform || (scheduleFields.platform.trim().length === 0)) {
      formValid = false;
      error.platform = 'Please select a platform';
    }
    if (!scheduleFields.ac_type || (scheduleFields.ac_type.trim().length === 0)) {
      formValid = false;
      error.ac_type = 'Please enter an aircraft type';
    }
    if (!scheduleFields.std || (scheduleFields.std.trim().length === 0)) {
      formValid = false;
      error.std = 'Please enter STD';
    }
    if (!scheduleFields.sta || (scheduleFields.sta.trim().length === 0)) {
      formValid = false;
      error.sta = 'Please enter STA';
    }
    if (!scheduleFields.from || (scheduleFields.from.trim().length === 0)) {
      formValid = false;
      error.from = 'Please enter from date';
    }
    if (!scheduleFields.to || (scheduleFields.to.trim().length === 0)) {
      formValid = false;
      error.to = 'Please enter to date';
    }
    this.setState({ scheduleErrors: error });
    return formValid;
  };

  handleOpen = (type, scheduleFields) => {
    switch (type) {
      case 'add':
        this.setState({
          openAddSchedule: true,
          scheduleFields: '',
          type: 'add',
          scheduleId: 0
        });
        break;
      case 'edit':
        this.setState({
          openAddSchedule: true,
          scheduleId: scheduleFields.id,
          scheduleFields,
          scheduleOrgDest: {
            org: this.state.locationList.filter(c => c.iata_code === scheduleFields.org)[0],
            dest: this.state.locationList.filter(c => c.iata_code === scheduleFields.dest)[0]
          },
          type: 'edit'
        });
        break;
      default:
        break;
    }
  };

  setActive = (id, active) => {
    this.updateSchedule(id, { active });
  };


  render() {
    return (
      <>
        <FlightSchedule
          filter={this.state.filter}
          onChangeFilter={this.onChangeFilter}
          airlines={this.state.airlines}
          scheduleList={this.state.filterData}
          handleClose={this.handleClose}
          locationModal={this.state.locationModal}
          handleOpen={this.handleOpen}
          onChange={this.onChange}
          openAddSchedule={this.state.openAddSchedule}
          scheduleFields={this.state.scheduleFields}
          scheduleErrors={this.state.scheduleErrors}
          addSchedule={this.addEditSchedule}
          type={this.state.type}
          changeDateTime={this.changeDateAndTime}
          changeCheck={this.changeCheck}
          handleDelete={this.handleDelete}
          changeSettings={this.changeSettings}
          flightSettings={this.state.flightSettings}
          airlineList={this.state.airlineList}
          locationList={this.state.locationList}
          scheduleOrgDest={this.state.scheduleOrgDest}
        />
      </>
    );
  }
}


const mapDispatchToProps = (dispatch) => {
  return { setDelete: (data) => dispatch(setDeleteAction(data)) };
};

export default connect(null, mapDispatchToProps)(FlightScheduleContainer);