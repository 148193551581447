import React from 'react';
import { Box, Checkbox, Grid, InputAdornment, Stack, Tooltip } from '@mui/material';
import styles from './../../../../custom/style.module.css';
import logStyle from '../log.module.css';
import { CustomTextField, DataGridProps, InputProps, StyledDataGrid, TableBadge } from 'custom/components';
import { AttachFile as AttachFileIcon } from '@mui/icons-material';
import DocModalContainer from 'components/pages/common/docModal/DocModalContainer';
import moment from 'moment';
import { DateFormat, StickyNoteIcon, statisticsRoles } from 'custom/constants';
import cookies from 'utils/cookies';
const timezone = cookies.get('timezone') || null;

const checkAccessArray = (rowrole, userrole) => {
  let flag = false;
  let foundroles = statisticsRoles.find((statRole) => statRole.role === rowrole);
  if(foundroles?.access.includes(userrole)){ 
    flag = true; 
  }
  return flag;
};

const Summary = (props) => {
  const logCol = [
    {
      field: '',
      headerName: '',
      renderHeader: () => (
        <Checkbox
          onClick={(e) => e.stopPropagation()}
          inputProps={{ 'aria-label': 'Select' }}
          sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}
          className={styles.mr2}
          name='allLogs'
          onChange={(e) => props.onCheckAll(e)}
          checked={props.checkedAll}
          // disabled={props.filterLogs === 'Finalized' ? true : false}
        />
      ),
      width: 50,
      cellClassName: logStyle.fixedFirst,
      headerClassName: logStyle.fixedFirstHead,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return <>
          <Checkbox
            onClick={(e) => e.stopPropagation()}
            inputProps={{ 'aria-label': 'Select' }}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}
            className={styles.mr2}
            disabled={!props.disabledCheckbox?.includes(row?.id) ? true : false}
            checked={row?.id ? props.checked?.includes(row?.id) : false}
            value={row?.id ?? ''}
            name='log'
            onChange={(e) => props.onChange(e)}
            // onChange={(e) => {
            //   console.log('clicking checkbox');
            //   props.onChange(e);
            //   if (row.data && row.data.length === 0) {
            //     console.log('submitting');
            //     // props.onSubmit();
            //     props.handleAllAutos(row);
            //   }
            // }}
          />
        </>;
      }
    },
    {
      field: 'date',
      headerName: (props.search?.type === 'DAY' ? 'Daily ' : 'Monthly ') + 'Service Logs' ,
      // flex: 1,
      minWidth: 180,
      cellClassName: logStyle.fixedSecond,
      headerClassName: logStyle.fixedSecondHead,
      headerAlign: 'left',
      align: 'left',
      renderCell: ({ row }) => {
        return <div> {row.type === 'MONTH' ? moment(row.date).format('MMMM').toUpperCase() : moment(row.date).format(DateFormat).toUpperCase()}
          {row.status &&<span className={`${logStyle.statusTag} ${styles.ml1}`}>{row.status}</span>}
        </div>;
      }
    },
    ...props.columnName.map((columnName, index) => ({
      field: `dynamic_column_${index + 1}`,
      headerName: columnName?.label,
      flex: 1,
      minWidth: 160,
      headerClassName: logStyle.colEllipse,
      headerAlign: 'left',
      align: 'left',
      renderCell: ({ row }) => {
        let item = row.data && row.data.length > 0 && row?.data?.find(d => d.contractId === columnName.contractId && d.contractrowId === columnName.contractrowId) || [];
        let filterNotes = [];
        if (row.notes && row.notes.length > 0) {
          filterNotes = row.notes.filter(note => item.contractrowId === note.cont_rowId);
        }
        return (
          <>
            <Stack direction='row' spacing={1} alignItems='center'>
              <div className={`${logStyle.minIpWidth}`}>
                <CustomTextField
                  {...InputProps}
                  label={false}
                  // InputProps={{ disableUnderline: true }}
                  fullWidth
                  variant='outlined'
                  name={`${row.id}-${columnName?.contractId}-${columnName?.contractrowId}`}
                  value={item?.value ? item?.value : columnName?.applied_on?.includes('Rent | Auto') ? columnName?.value : ''}
                  className={ logStyle.inputLogWrapper}
                  onKeyUp={props.handleKeyDown}
                  onKeyDown={(event) => {
                    event.stopPropagation();
                  }}
                  onChange={e => props.handleChange(e, row)}
                  onBlurCapture={() => {
                    if(
                      !(
                        // props.disabled || 
                        // columnName.label?.includes('Rent') || 
                        (columnName.applied_on?.includes('Auto') && (cookies.get('role') !== 'Senior Accounting' && cookies.get('role') !== 'System Admin')) || 
                        (row.status && (
                          (row?.status === 'Rejected' && row.approved_acc) || 
                          row?.status === 'Finalized' || 
                          row?.status === 'Approved' || 
                          row?.status === 'Pending'
                        )) || 
                        ( columnName.roles && columnName.roles.length>0 && 
                          // !columnName.roles?.includes(cookies.get('role')
                          !checkAccessArray(columnName.roles?.[0], cookies.get('role')
                          )))
                    ){
                      props.onSubmit();}
                  }}
                  // disabled={
                  //   props.disabled || 
                  //   columnName.label?.includes('Rent | Auto') || 
                  //   (row.status && (
                  //   (row?.status === 'Rejected' && row.approved_acc ) || 
                  //   row?.status === 'Finalized' || 
                  //   row?.status === 'Approved' || 
                  //   row?.status === 'Pending' ))
                  // }
                  disabled={
                    // props.disabled || 
                    // columnName.label?.includes('Rent') || 
                    (columnName.applied_on?.includes('Auto') && (cookies.get('role') !== 'Senior Accounting' && cookies.get('role') !== 'System Admin')) || 
                    (row.status && (
                      (row?.status === 'Rejected' && row.approved_acc) || 
                      row?.status === 'Finalized' || 
                      row?.status === 'Approved' || 
                      row?.status === 'Pending'
                    )) || 
                    ( columnName.roles && columnName.roles.length>0 && 
                      // !columnName.roles?.includes(cookies.get('role')
                      !checkAccessArray(columnName.roles?.[0], cookies.get('role')
                      ))
                  }
                  InputProps={{
                    classes: {
                      root: logStyle.inputLogRoot,
                      input: logStyle.inputLogInput,
                      disabled: logStyle.inputLogDisabled,
                    },
                    startAdornment: (
                      <InputAdornment position='start' >
                        {item?.user || !columnName.applied_on?.includes('Auto') ? (
                          <Tooltip title={
                            <>
                              <div>Entered By: {item?.user ? (`${item?.user?.first_name} ${item?.user?.last_name } | ${item?.date ? moment(item.date).tz(timezone).format('DDMMMYY HH:mm').toUpperCase() : '-'}`) : ''}</div>
                              <div>Role: {item?.user?.role || '-'}</div>
                            </>
                          }>
                            <code className={styles.infoIcon}> i </code>
                          </Tooltip>
                        ) : (
                          <div>Auto</div>
                        )}
                      </InputAdornment>
                    ),
            
                    endAdornment: (
                      <InputAdornment position='end' className={logStyle.inputAdornedEnd}>
                        <TableBadge badgeContent={filterNotes?.length} color='error' classes={{ badge: logStyle.badge }}>
                          <StickyNoteIcon title='Add/View Note' 
                            className={(typeof (row.id) !== 'number') ||  row.data.length === 0 || !props.disabledCheckbox?.includes(row?.id) ? styles.disabledImg : ''} onClick={(e) => props.openNotes(e, row, item)} />
                        </TableBadge>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            </Stack>
          </>
        // : '-'
        );
      }
    })),
    {
      field: 'by',
      headerName: 'Submitted By',
      flex: 0.8,
      minWidth: 150,
      headerAlign: 'left',
      align: 'left',
      cellClassName: styles.cellTextWrap,
      // headerClassName: styles.pl4,

      renderCell: ({ row }) => {
        let logs = row.log && row.log[row.log.length - 1];
        return <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} fullWidth useFlexGap className={styles.w100}>
          <span>{row.log  ? `${logs?.user?.role} | ${logs?.user?.first_name}  ${logs?.user?.last_name} ` : '-'}</span>
          <TableBadge badgeContent={row.log?.length || 0} color='error' classes={{ badge: logStyle.badge }}>
            <StickyNoteIcon title='Add/View Note' onClick={(e) => props.openNotes(e, row, 'remarks')} />
          </TableBadge>
        </Stack>;
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      minWidth: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <>
            <div className={styles.pointer}
              onClick={() => props.handleDoc(row)}>
              <Tooltip title='Add/View Attachment'>
                <TableBadge color='error' badgeContent={row?.docs?.length}>
                  <AttachFileIcon fontSize='small' sx={{ marginTop: '-10px' }} />
                </TableBadge>
              </Tooltip></div>
          </>
        );
      }
    },
  ];

  return (
    <Grid container spacing={4}> 
      <Grid item xs={12}>
        <div className={styles.tableWrapper}>
          <Box className={`${styles.tableOuter} ${logStyle.logTableOuter}`}>
            <StyledDataGrid
              {...DataGridProps}
              className={`${styles.customTable} ${logStyle.logTable}`}
              rows={props.filteredData}
              columns={logCol}
              param={props.filteredData?.length}
              // classes={{ 
              //   root: styles.tableRoot,
              //   columnHeaderTitle: styles.tableHeaderTitle 
              // }}
              // getRowClassName={ ({ row }) => !props.disabledCheckbox?.includes(row?.id) ? styles.disabledRow : ''}
              getRowClassName={ ({ row }) => props.emptyLog && props.emptyLog.includes(row?.id) ? styles.inValidRow : ''}
              // getRowClassName={ ({ row }) => row.emptyLog ? styles.errorInput : ''}
              initialState={{
                sorting: {
                  sortModel: [{
                    field: 'date',
                    sort: 'asc' 
                  }], 
                },
              }}
            />
          </Box>
        </div> 
      </Grid>
      <DocModalContainer />
    </Grid>

  );
};

export default Summary;
