import React from 'react';
import TaxModule from './TaxModule';
import cookies from 'utils/cookies';
import http from 'utils/http';

const station_code = cookies.get('station_code');
class TaxModuleContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      airlines: [],
      airlineId: null,
      airlineCode: null,

      invoiceErrors: {},
      invoice_details_id: null,

      invoice_code: null,
      from_name: '',
      from_address: '',
      from_contact: '',
      to_name: '',
      to_address: '',
      to_contact: '',


      taxList: [],
      taxFields: {
        title: '',
        type: '',
        cycle: '',
        value: '',
      },
      taxErrors: {},
      openAddTax: false,
      type: 'add',
      invoice_code_text: ''
    };
  }

  componentDidMount() {
    this.fetchAirlines();
  }

  fetchAirlines = () => {
    http.get(`/airline?active=${true}`)
      .then((response) => {
        this.setState({
          airlines: response.data.data,
          airlineId: response.data.data[0].id,
          airlineCode: response.data.data[0].code
        },
        () => {
          this.fetchInvoiceDetails();
          this.fetchTaxList();
        });
      });
  };

  fetchInvoiceDetails = () => {
    http.get(`/invoice_details?airline=${this.state.airlineId}`)
      .then((response) => {
        if(response.data.data.length > 0 ){
          let record = response.data.data[0];
          this.setState({
            invoice_details_id: record.id || null ,
            invoice_code: record.invoice_code || null,
            invoice_code_text: record.invoice_code && (record.invoice_code === 'ACSTNCTJAN2024' ? `${this.state.airlineCode}${station_code}CHJAN2024` : 
              record.invoice_code === 'ACSTN01JAN2024' ? `${this.state.airlineCode}${station_code}01JAN2024` :
                record.invoice_code === 'INV-AC-CT-STN-JAN24' ? `INV-${this.state.airlineCode}-CH-${station_code}-JAN2024` :
                  record.invoice_code === 'INV-Airline Code-0001' ? 'INV-Airline Code-0001' :
                    record.invoice_code === 'INV-AC-Month-01 / INV-AC-Month-02' ? 'INV-AC-Month-01 / INV-AC-Month-02' : ''),
            from_name: record.from_name || '',
            from_address: record.from_address || '',
            from_contact: record.from_contact || '',
            to_name: record.to_name || '',
            to_address: record.to_address || '',
            to_contact: record.to_contact || ''
          });
        } else {
          this.setState({
            invoice_details_id: null,
            invoice_code: null,
            from_name: '',
            from_address: '',
            from_contact: '',
            to_name: '',
            to_address: '',
            to_contact: ''
          });
        }
      });
  };
  fetchTaxList = () => {
    http.get(`/tax?airline=${this.state.airlineId}`)
      .then((response) => {
        this.setState({ taxList: response.data.data });
      });
  };
  saveInvoiceDetails = (type) => {
    if (this.validate_invoice()) {
      let obj = {
        id: this.state.invoice_details_id,
        airlineId: this.state.airlineId,
        invoice_code: this.state.invoice_code,
        from_name: this.state.from_name,
        from_address: this.state.from_address,
        from_contact: this.state.from_contact,
        to_name: this.state.to_name,
        to_address: this.state.to_address,
        to_contact: this.state.to_contact,
        
        type: type,
      };
      http.post('/invoice_details',obj)
        .then(() => {
          this.fetchInvoiceDetails();
        });
    }
  };

  validate_invoice = () => {
    const error = {};
    let formValid = true;
    if (!this.state.invoice_code || (this.state.invoice_code == '')) {
      formValid = false;
      error.invoice_code = 'Please select an Invoice Code';
    }
    if (!this.state.from_name || (this.state.from_name == '')) {
      formValid = false;
      error.from_name = 'Please enter Name';
    }
    if (!this.state.from_address || (this.state.from_address == '')) {
      formValid = false;
      error.from_address = 'Please enter Address';
    }
    if (!this.state.from_contact || (this.state.from_contact == '')) {
      formValid = false;
      error.from_contact = 'Please enter Phone Number';
    }

    if (!this.state.to_name || (this.state.to_name == '')) {
      formValid = false;
      error.to_name = 'Please enter Name';
    }
    if (!this.state.to_address || (this.state.to_address == '')) {
      formValid = false;
      error.to_address = 'Please enter Address';
    }
    if (!this.state.to_contact || (this.state.to_contact == '')) {
      formValid = false;
      error.to_contact = 'Please enter Phone Number';
    }
    this.setState({ invoiceErrors: error });
    return formValid;
  };

  onChange = (e) => {
    let invoice_code_text = '';
    let name = e.target.name;
    let value = e.target.type == 'checkbox' ? e.target.checked : e.target.value;
    if (e.target.name === 'invoice_code') {
      // invoice_code_text = e.explicitOriginalTarget.innerText;
      invoice_code_text = e.target.value;
    }
    this.setState({
      [name]: value,
      invoice_code_text
    },()=>{
      if(name === 'airlineId'){
        let airlineCode = this.state.airlines.find((row) => row.id === value).code;
        this.setState({ airlineCode: airlineCode },()=>{ 
          this.fetchInvoiceDetails();
          this.fetchTaxList();
        });
      }
    });
  };

  onModalChange = (e) => {
    let value = e.target.type == 'checkbox' ? e.target.checked : e.target.value;
    const name = e.target.name;
    this.setState({
      taxFields: {
        ...this.state.taxFields,
        [name]: value
      }
    });
  };

  handleOpen = (type, taxFields = {}) => {
    this.setState({
      openAddTax: true,
      taxId: taxFields.id || false,
      taxFields,
      type: type
    });
  };

  handleClose = () => {
    this.setState({
      openAddTax: false,
      taxId: false,
      taxFields: {},
      type: false
    });
  };

  validate_tax = () => {
    const taxFields = { ...this.state.taxFields };
    const error = {};
    let formValid = true;
    if (!this.state.airlineId || (this.state.airlineId == '')) {
      formValid = false;
      error.airline = 'Please select an airline';
    }
    if (!taxFields.title || (taxFields.title.trim().length === 0)) {
      formValid = false;
      error.title = 'Please enter a Title for Tax';
    }
    if (!taxFields.type || (taxFields.type.trim().length === 0)) {
      formValid = false;
      error.type = 'Please select a Type for Tax';
    }
    if (!taxFields.cycle || (taxFields.cycle.trim().length === 0)) {
      formValid = false;
      error.cycle = 'Please select a Cycle for Tax';
    }
    if(!taxFields.value || (taxFields.value === null)){
      formValid = false;
      error.value = 'Please enter a Value for Tax';
    }
    this.setState({ taxErrors: error });
    return formValid;
  };

  addEditTax = async (e) => {
    e.preventDefault();
    let formdata = { 
      ...this.state.taxFields, 
      airlineId: this.state.airlineId 
    };
    if (this.validate_tax()) {
      if (this.state.taxId) {
        this.updateTax(this.state.taxId, formdata);
      } else {
        await http.post('/tax', formdata)
          .then(() => {
            this.setState({
              taxFields: {
                title: '',
                type: '',
                cycle: '',
                value: 0,
              },
              openAddTax: false
            },()=>{
              this.fetchTaxList();
            });
          });
      }
    }
  };
  updateTax = (id, formdata) => {
    http.put(`/tax/${id}`, formdata)
      .then(() => {
        this.setState({
          taxFields: {
            title: '',
            type: '',
            cycle: '',
            value: 0,
          },
          openAddTax: false
        },()=>this.fetchTaxList());
      });
  };

  render() {
    return (
      <>
        <TaxModule 
          airlines= {this.state.airlines}
          airlineId= {this.state.airlineId}
          airlineCode= {this.state.airlineCode}

          invoice_code= {this.state.invoice_code}
          from_name= {this.state.from_name}
          from_address= {this.state.from_address}
          from_contact= {this.state.from_contact}
          to_name= {this.state.to_name}
          to_address= {this.state.to_address}
          to_contact= {this.state.to_contact}

          taxList= {this.state.taxList}
          onModalChange= {this.onModalChange}
          handleOpen= {this.handleOpen} 
          openAddTax= {this.state.openAddTax}
          handleClose= {this.handleClose}
          taxFields= {this.state.taxFields}
          taxErrors= {this.state.taxErrors}
          addEditTax={this.addEditTax}
          type={this.state.type}
          invoiceErrors={this.state.invoiceErrors}
          onChange= {this.onChange}
          saveInvoiceDetails= {this.saveInvoiceDetails}
          invoice_code_text= {this.state.invoice_code_text}
        />
      </>
    );
  }
}

export default TaxModuleContainer;
