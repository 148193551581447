import React from 'react';
import { View, StyleSheet, Image } from '@react-pdf/renderer';
import ghaLogo from '../../../../../../assets/images/tristarLogo.png';
import lhLogo from '../../../../../../assets/images/lufthansaCargo.png';
import cookies from 'utils/cookies';

const companyName = (company) => {
  switch (company?.toLowerCase()) {
    case 'tristar ground handling services':
      return <Image style={styles.logo} src={ghaLogo} />;
    case 'lufthansa cargo ag':
      return <Image style={styles.logo2} src={lhLogo} />;
    default:
      return '';
  }
};

const styles = StyleSheet.create({
   
  titleContainer:{
    flexDirection: 'row',
    marginTop: 24,
    width: '50%',
  },
  reportTitle:{
    color: 'black',
    // letterSpacing: 4,
    fontSize: 20,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  logo: { width: 150, },
  logo2: {
    width: 150,
    marginBottom: '12px', 
  }
});


const InvoiceTitle = () => (
  <>
    <View style={styles.titleContainer}>
      {companyName(cookies.get('company_name'))}
    </View>
  </>
);
  
export default InvoiceTitle;
