import React from 'react';
import FlightReportPDFDownload from './FlightReportPDFDownload';
import { PDFIcon } from 'custom/constants';

class FlightReportPDFDownloadContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pdfModal: false,
      flightReportDetails: {
        note: [],
        discrepancy: [],
        flightInfo: [],
        manifestSummary: [],
        flightReport: [],
        statistics: [],
        history: [],
        manifest: [],
      },
      note: [],
      flightInfo: [],
    };
  }
  componentDidMount() {
  }

  handleOpenPDF = () => {
    this.setState({ pdfModal: true, });
  };

  closeModalPDF = () => {
    this.setState({ pdfModal: false, });
  };

  updateFlightReportDetails = (newData, key) => {
    if(key){
      if (JSON.stringify(newData) === JSON.stringify(this.state[key])) {
        return null; // No state update, so no re-render
      }
    }
    this.setState({ [key]: newData },() => {});

  };

  render() {
    return (
      <>
        {this.state.pdfModal &&
        <FlightReportPDFDownload 
          pdfModal={this.state.pdfModal}
          printFunction ={this.updateFlightReportDetails}
          flightReportDetails={this.state.flightReportDetails}
          onClose={this.closeModalPDF}
          flightInfo={this.state.flightInfo}
          note={this.state.note}
          discrepancy={this.state.discrepancy}
          products={this.state.products}
          manifest={this.state.manifest}
          history={this.state.history}
          statistics={this.state.statistics}
          flightReport={this.state.flightReport}
          manifestSummary={this.state.manifestSummary}
          flight_gate={this.state.flight_gate}
        />
        }
        <PDFIcon title='Download File' onClick={this.handleOpenPDF} />
      </>
    );
  }
}


export default FlightReportPDFDownloadContainer;
