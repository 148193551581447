import React from 'react';
import http from 'utils/http';
import { setDeleteAction } from 'store/actions/deleteAction';
import { connect } from 'react-redux';
import cookies from 'utils/cookies';
import SPLMapping from './SPLMapping';
import events from 'utils/evemts';

class SPLMappingContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      station: [],
      airline: [],
      products: [],
      splCodes: [],
      modal: false,
      action: null,
      productField: {},
      productErrors: {},
      specialsField: {},
      specialsErrors: {},
      activeAirline: null, 
      activeProd: null,
      anchorEl: null,
      location: false,
      locationType: null,
    };
    this.eventSource = null;
  }

  componentDidMount() {
    this.fetchAirline();
    this.eventSource = events('admin', 'special');
    this.eventSource.onmessage = (event) => {
      const msg = JSON.parse(event.data || '{}');
      if (msg.data) {
        msg.data.airline={ stations: this.state.activeAirline.stations };
        if (msg.action == 'add_product' && (msg.data.airlineId === this.state.activeAirline?.id ||  msg.data.airlineId === null)) {
          this.setState({ products: [...this.state.products, msg.data] });
        }
        if (msg.action == 'update_product') {
          const products = [...this.state.products];
          const index = products.findIndex(c => msg.data && c.id == msg.data.id);
          if (index > -1) {
            products[index] = { ...msg.data };
            this.setState({ products },()=>{
              if(this.state.activeProd?.id === msg.data.id){
                this.setState({ activeProd: msg.data });
              }
            });
          }
        }
        if (msg.action == 'delete_product') {
          const products = [...this.state.products];
          const index = products.findIndex(c => msg.data && c.id == msg.data.id);
          if (index > -1) {
            products.splice(index, 1);
            this.setState({ 
              products,
              activeProd: products && products.length > 0 ? products[0] : null,
            });
          }
        }
        if (msg.action == 'add_special' && msg.data.productId === this.state.activeProd?.id ) {
          let stations = msg.data.stations.map((item) => { return item.id; });
          if(stations.includes(parseInt(cookies.get('station')))){
            const splCodes = [...this.state.splCodes];
            const index = splCodes.findIndex(c => msg.data && c.id == msg.data.id);
            if (index > -1) {
              splCodes[index] = { ...msg.data };
              this.setState({ splCodes });
            } else {
              this.setState({ splCodes: [...this.state.splCodes, msg.data] });
            }
          }
        }
        if (msg.action == 'update_special' ) {
          const splCodes = [...this.state.splCodes];
          const index = splCodes.findIndex(c => msg.data && c.id == msg.data.id);
          if (index > -1) {
            splCodes[index] = { ...msg.data };
            this.setState({ splCodes });
          }
        }
        if (msg.action == 'delete_special') {
          const splCodes = [...this.state.splCodes];
          const index = splCodes.findIndex(c => msg.data && c.id == msg.data.id);
          if (index > -1) {
            splCodes.splice(index, 1);
            this.setState({ splCodes });
          }
        }
      }
    };
  }

  componentWillUnmount() {
    this.eventSource.close();
  }

  fetchAirline = () => {
    http.get(`/airline?active=${true}`)
      .then(response => {
        const { data } = response;
        if (data.status === 'success') {
          this.setState({ 
            airline: data.data, 
            activeAirline: data.data[0] ? data.data[0] : null 
          },()=>{
            if(data.data && data.data.length>0){
              this.fetchProducts(this.state.activeAirline);
            }
          });
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  fetchProducts = (row={}) => {
    this.setState({ 
      activeAirline: row,
      products: [],
      activeProd: null,
      splCodes: []
    });
    http.get(`/products?airlineId=${row?.id === -1 ? 'ALL' : row?.id || 'ALL'}`)
      .then(response => {
        const { data } = response;
        if (data.status === 'success') {
          if(data.data && data.data.length>0){
            this.setState({ 
              products: data.data,
              activeProd: data.data[0],
              splCodes: []
            },()=>{
              if(data.data && data.data.length>0){
                this.fetchSpecials(this.state.products[0]);
              }
            });
          } else {
            this.setState({ 
              splCodes: [],
              activeProd: null,
              products: []
            });
          } 
          // to remove duploicate
          // if(this.state.products) {
          //   screenedArray = screenedArray && screenedArray?.filter((item) => {
          //     return item.userId === this.state.screener;
          //   }
          //   );  
          // }
        }
      })
      .catch(error => {
        this.setState({ products: [] });
        console.error('Error fetching data:', error);
      });
  };
 
  fetchSpecials = (row = {}) => {
    this.setState({ 
      activeProd: row,
      splCodes: [], 
    });
    let airlineId = row?.airlineId;
    let id = row?.id;
    if(id){
      http.get(`/special_codes?airlineId=${airlineId? airlineId : 'ALL' }&productId=${id}`)
        .then(response => {
          const { data } = response;
          if (data.status === 'success') {
            this.setState({ splCodes: data.data });
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  };
  

  onChangeProduct = (e) => {
    this.setState((prev) => ({
      productField: {
        ...prev.productField,
        [e.target.name]: e.target.value
      }
    }));
  };

  onChangeSpecials = (e) => {
    this.setState((prev) => ({
      specialsField: {
        ...prev.specialsField,
        [e.target.name]: e.target.value
      }
    }));
  };

  validationProducts = () => {
    const productFields = { ...this.state.productField };
    const error = {};
    let formValid = true;
    if (!productFields.name || (productFields.name.trim().length === 0)) {
      formValid = false;
      error.name = 'Please enter a product.';
    }
    // if (!productFields.airlineId || (productFields.airlineId.toString().trim().length === 0)) {
    //   formValid = false;
    //   error.airline = 'Please select an airline.';
    // }
    this.setState({ productErrors: error });
    return formValid;
  };

  addProducts = async (e) => {
    e.preventDefault();
    let formdata = { 
      ...this.state.productField,
      airlineId: this.state.activeAirline.id === -1 ? 'ALL' : this.state.activeAirline?.id
    };
    if (this.validationProducts()) {
      http.post('/product', formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            productField: {},
            productErrors: {},
            action: null,
          });
        }
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  };

  editProducts = async (e) => {
    e.preventDefault();
    let formdata = { 
      id: this.state.productField.id,
      name: this.state.productField.name,
      airlineId: this.state.activeAirline.id === -1 ? 'ALL' : this.state.activeAirline?.id,
    };
    // delete formdata.station;
    if (this.validationProducts()) {
      http.put('/product/' + formdata.id, formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            productField: {},
            action: null,
          });
        }
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  };

  deleteProduct = (id) => {
    http.delete(`/product/${id}`).then(({ data }) => {
      if (data.status == 'success') {
        this.props.setDelete();
      }
    });
  };

  handleDelete = (type, id, anchorEl) => {
    this.props.setDelete({
      modal: true,
      id,
      anchorEl,
      onConfirm: () => {
        switch (type) {
          case 'airline':
            this.deleteAirline(id);
            break;
          case 'product':
            this.deleteProduct(id);
            break;
          case 'specials':
            this.deleteSpecials(id);
            break;
          default:
            break;
        }
      },
    });
  };

  validationSpecials = () => {
    const specialsField = { ...this.state.specialsField };
    const error = {};
    let formValid = true;
    if (!specialsField.name || (specialsField.name.trim().length === 0)) {
      formValid = false;
      error.name = 'Please enter a special code.';
    }
    if (specialsField.name && (specialsField.name.trim().length < 3)) {
      formValid = false;
      error.name = 'Special code should not be more than 3 characters.';
    }
    // if (!specialsField.productId || (specialsField.productId.toString().trim().length === 0)) {
    //   formValid = false;
    //   error.product = 'Please enter a product.';
    // }
    // if (!specialsField.productId || (specialsField.productId.toString().trim().length === 0)) {
    //   formValid = false;
    //   error.product = 'Please enter a product.';
    // }
    // if ((this.state.action === 'add' && (!specialsField.airlineId || (specialsField.airlineId.toString().trim().length === 0)))) {
    //   formValid = false;
    //   error.airline = 'Please select an airline.';
    // }
    this.setState({ specialsErrors: error });
    return formValid;
  };

  addSpecials = async (e) => {
    e.preventDefault();
    let formdata = { 
      ...this.state.specialsField,
      name: this.state.specialsField.name.toUpperCase(),
      productId: this.state.activeProd?.id,
      airlineId: this.state.activeAirline.id === -1 ? 'ALL' : this.state.activeAirline?.id
    };
    if (this.validationSpecials()) {
      http.post('/special_code', formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            specialsField: {},
            specialsErrors: {},
            action: null,
          });
        }
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  };

  editSpecials = async (e) => {
    e.preventDefault();
    let formdata = {
      id: this.state.specialsField.id,
      name: this.state.specialsField.name.toUpperCase(),
      productId: this.state.specialsField.productId,
      airlineId: this.state.specialsField.airlineId 
    };
    // delete formdata.station;
    if (this.validationSpecials()) {
      http.put('/special_code/' + formdata.id, formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            specialsField: {},
            specialsErrors: {},
            action: null,
          });
        }
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    } 
  };

  deleteSpecials = (id) => {
    http.delete(`/special_code/${id}`).then(({ data }) => {
      if (data.status == 'success') {
        this.props.setDelete();
      }
    });
  };

  handleLocation = (locationType, v, data = {}, anchorEl = null) => {
    this.setState({
      location: v,
      locationType: locationType,
      station: data,
      anchorEl
    });
  };

  handleOpen = (modal, action, data={}) => {
    this.setState({
      modal,
      action,
      productField: { ...data, },
      specialsField: { ...data }
    });
  };

  onClose = () => {
    this.setState({
      modal: false,
      action: false,
    });
  };

  render() {
    return (
      <>
        <SPLMapping
          stations={this.props.stations}
          station={this.state.station}
          anchorEl={this.state.anchorEl}
          modal={this.state.modal}
          action={this.state.action}
          location={this.state.location}
          locationType={this.state.locationType}
          handleOpen={this.handleOpen}
          onClose={this.onClose}
          handleLocation={this.handleLocation}
          airline={this.state.airline}
          products={this.state.products}
          splCodes={this.state.splCodes}
          productField={this.state.productField}
          productErrors={this.state.productErrors}
          addProducts={this.addProducts}
          editProducts={this.editProducts}
          onChangeProduct={this.onChangeProduct}
          activeAirline={this.state.activeAirline}
          handleDelete={this.handleDelete}
          fetchProducts={this.fetchProducts}
          specialsField={this.state.specialsField}
          specialsErrors={this.state.specialsErrors}
          addSpecials={this.addSpecials}
          editSpecials={this.editSpecials}
          onChangeSpecials={this.onChangeSpecials}
          fetchSpecials={this.fetchSpecials}
          activeProd={this.state.activeProd}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { stations: state.stations };
};

const mapDispatchToProps = (dispatch) => {
  return { setDelete: (data) => dispatch(setDeleteAction(data)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(SPLMappingContainer);
