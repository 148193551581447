import React from 'react';
import { Dialog, DialogContent, Grid, InputAdornment, FormControl, MenuItem, FormHelperText } from '@mui/material';
import styles from '../../../custom/style.module.css';
import { CustomButton, CustomDialogTitle, StyledCard, CustomTextField, CustomDatePicker, CustomSelect, CustomInputLabel, CustomDialogAction, InputProps, CustomDateTimePicker, SelectInputProps, CustomAutocomplete } from '../../../custom/components';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import moment from 'moment';
import dayjs from 'dayjs';
import { CloseIconBtn, platforms } from 'custom/constants';

// import moment from 'moment';

const AddAdhocFlightModal = (props) => {
  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={props.modal === 'adhoc_flight'} >
        <CustomDialogTitle id='form-dialog-title' className={styles.modalTitle}>{props.type == 'edit' ? 'Edit' : 'Add'} Adhoc Flight/Truck
          <CloseIconBtn onClick={props.onClose} />
        </CustomDialogTitle>
        <form onSubmit={props.type == 'add' ? props.addFlight : props.editFlight} onReset={props.onClose} className={styles.w100}>
          <DialogContent>
            <StyledCard>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CustomInputLabel id='airline-label' aria-label='Select Airline'>Select an Airline</CustomInputLabel>
                  <FormControl variant='standard' fullWidth 
                    error={props.flightErrors.airlineId}
                  >
                    <CustomSelect
                      {...SelectInputProps}
                      id='airline'
                      labelId='airline-label'
                      fullWidth
                      autoFocus={false}
                      onChange={props.onChange}
                      name='airlineId'
                      value={!props.flightFields.airlineId ? 'placeholder' : props.flightFields.airlineId}
                      classes={{ select: !props.flightFields.airlineId ? styles.colorPlaceholder : '' }}
                    >
                      <MenuItem value='placeholder' disabled >Select an Airline</MenuItem>
                      {props.airlines.map((airline, i) => (
                        <MenuItem key={i} value={airline.id}>{airline.code}</MenuItem>
                      ))}
                    </CustomSelect>
                    <FormHelperText>{props.flightErrors.airlineId}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInputLabel id='platform-label' aria-label='Select platform'>Select a Platform</CustomInputLabel>
                  <FormControl variant='standard' fullWidth
                    error={props.flightErrors.platform}
                  >
                    <CustomSelect
                      {...SelectInputProps}
                      id='platform'
                      labelId='platform-label'
                      onChange={props.onChange}
                      name='platform'
                      value={!props.flightFields.platform ? 'placeholder' : props.flightFields.platform}
                      classes={{ select: !props.flightFields.platform ? styles.colorPlaceholder : '' }}
                    >
                      <MenuItem value='placeholder'>Select a Platform</MenuItem>
                      {platforms.map((name) => (
                        <MenuItem key={name} value={name}>{name}</MenuItem>
                      ))}
                    </CustomSelect>
                    <FormHelperText>{props.flightErrors.platform}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInputLabel htmlFor='number' aria-label='Enter flight number'>Flight Number</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='number'
                    fullWidth
                    autoFocus
                    placeholder='Flight Number'
                    type='text'
                    onChange={props.onChange}
                    name='flight_no'
                    value={props.flightFields.flight_no}
                    error={props.flightErrors.flight_no}
                    helperText={props.flightErrors.flight_no}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInputLabel htmlFor='' aria-label='Enter Flight date'>Flight Date</CustomInputLabel>
                  <CustomDatePicker
                    slotProps={{
                      textField: {
                        ...InputProps,
                        placeholder: 'Flight Date',
                        fullWidth: true
                      }, 
                    }}  
                    clearable
                    format='DD MMM YYYY'
                    name='flight_date'
                    value={dayjs(props.flightFields.flight_date, 'YYYY MM DD')}
                    error={props.flightErrors.flight_date}
                    helperText={props.flightErrors.flight_date}
                    onChange={(val) => props.changeDateTime(val, 'flight_date')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end' >
                          <QueryBuilderIcon color='primary' />
                        </InputAdornment>
                      )
                    }}
                    fullWidth
                    inputVariant='outlined'
                    className={`${styles.w100}`}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInputLabel htmlFor='org' aria-label='Enter-origin'>Origin</CustomInputLabel>
                  <FormControl variant='standard' fullWidth error={props.flightErrors.org}>
                    <CustomAutocomplete
                      disablePortal
                      id='org'
                      options={props.locationList}
                      getOptionLabel={(option) => option.iata_code}
                      onChange={props.onChange}
                      name='org'
                      value={props.scheduleOrgDest.org}
                      renderInput={(params) => 
                        <CustomTextField 
                          {...InputProps}
                          {...params} 
                          placeholder='Select an Origin'
                        />}
                      fullWidth
                      slotProps={{ paper: { className: styles.selectPaperIp }, }}
                      error={props.flightErrors.org}
                      helperText={props.flightErrors.org}
                    />
                    <FormHelperText>{props.flightErrors.org}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInputLabel htmlFor='dest' aria-label='Enter-destionation '>Destination</CustomInputLabel>
                  <FormControl variant='standard' fullWidth error={props.flightErrors.dest}>
                    <CustomAutocomplete    
                      disablePortal
                      id='dest'
                      options={props.locationList}
                      getOptionLabel={(option) => option.iata_code}
                      onChange={props.onChange}
                      name='dest'
                      value={ props.scheduleOrgDest.dest}
                      renderInput={(params) => 
                        <CustomTextField 
                          {...InputProps}
                          {...params} 
                          placeholder='Select a Destination'                   
                        />}
                      fullWidth
                      slotProps={{ paper: { className: styles.selectPaperIp } }}
                      className={styles.autoCompleteIp}
                    />
                    <FormHelperText>{props.flightErrors.dest}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <CustomInputLabel htmlFor='type' aria-label='Enter aircraft type'>Aircraft Type</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='type'
                    fullWidth
                    autoFocus
                    placeholder='Aircraft Type'
                    type='text'
                    onChange={props.onChange}
                    name='ac_type'
                    value={props.flightFields.ac_type}
                    error={props.flightErrors.ac_type}
                    helperText={props.flightErrors.ac_type}
                  />
                </Grid>
                <Grid item xs={12} md={6} className={styles.fColumn}>
                  <CustomInputLabel htmlFor='' aria-label='Enter std'>STD</CustomInputLabel>
                  <FormControl variant='standard' fullWidth
                    error={props.flightErrors.schdl_dept}
                  >
                    <CustomDateTimePicker
                      fullWidth
                      clearable
                      format={'DD MMM YYYY HH:mm'}
                      slotProps={{
                        textField: {
                          ...InputProps,
                          fullWidth: true
                        }, 
                      }}  
                      onChange={(v) => props.onChange(
                        {
                          target: {
                            type: 'date',
                            name: 'schdl_dept',
                            value: moment(v.$d).format('YYYY-MM-DD HH:mm')
                          }
                        })}
                      value={dayjs(moment(props.flightFields.schdl_dept).format('YYYY-MM-DD HH:mm')) || {}}
                    />
                    <FormHelperText id=''>{props.flightErrors.schdl_dept}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} className={styles.fColumn}>
                  <CustomInputLabel htmlFor='' aria-label='Enter sta'>STA</CustomInputLabel>
                  <FormControl variant='standard' fullWidth
                    error={props.flightErrors.schdl_arr}
                  >
                    <CustomDateTimePicker
                      fullWidth
                      clearable
                      format={'DD MMM YYYY HH:mm'}
                      slotProps={{
                        textField: {
                          ...InputProps,
                          placeholder: 'From Date',
                          fullWidth: true
                        }, 
                      }}  
                      onChange={(v) => props.onChange(
                        {
                          target: {
                            type: 'date',
                            name: 'schdl_arr',
                            value: moment(v.$d).format('YYYY-MM-DD HH:mm')
                          }
                        })}
                      value={dayjs(moment(props.flightFields.schdl_arr).format('YYYY-MM-DD HH:mm'))}
                    />
                    <FormHelperText>{props.flightErrors.schdl_arr}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </StyledCard>
          </DialogContent>
          <CustomDialogAction className={styles.fSpaceBtw}>
            <CustomButton variant='contained' type='reset'>Close</CustomButton>
            <CustomButton variant='contained' type='submit'>{props.type == 'edit' ? 'Update' : 'Submit'}</CustomButton>
          </CustomDialogAction>
        </form>
      </Dialog>
    </div>
  );
};
export default AddAdhocFlightModal;