import React from 'react';
import FlightManifest from './FlightManifest';
import { Box } from '@mui/material';
import moment from 'moment';
import http from 'utils/http';
import dayjs from 'dayjs';
import AttachmentContainer from '../attachment/AttachmentContainer';
import { getLocatDate } from 'utils/converter';

class FlightManifestContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      modal: false,
      screeningModal: false,
      search: {
        from: getLocatDate('YYYY-MM-DD'),
        to: getLocatDate('YYYY-MM-DD'),
      },
      data: [],
      filterData: [],
      docs: [],
      airline: [],
      flight: [],
      preScreen: [],
      filter: {
        airline: null,
        flight: null,
        flight_search: null,
      },
    };
  }

  componentDidMount() {
    this.fetchFlight();
  }


  handlePopover = (modal, anchorEl, docs) => {
    this.setState({
      modal,
      anchorEl,
      docs
    });

  };

  fetchFlight = (flight) => {
    let query = '';
    if (flight) {
      query = `flight=${this.state.search.search}`;
    } else {
      query = `from=${dayjs(this.state.search.from).format('YYYY-MM-DD')}&to=${dayjs(this.state.search.to).format('YYYY-MM-DD')}`;
    }
    http.get('/archive/flight?' + query).then(({ data }) => {
      if (data.status == 'success') {
        this.setState({ data: data.data }, () => {
          this.getFilter();
          this.filterData();
          this.handlePopover(false);
        });
      }
    });
  };

  handleFilterChange = (event, type) => {
    let value = event.target ? event.target.value : event;
    // const name = event.target ? event.target.name : event;

    this.setState((prevState) => ({
      search: {
        ...prevState.search,
        [type]: value
      }
    }));
  };

  getFilter = () => {
    let airline = [];
    let flight = [];
    this.state.data.map(a => {
      let f = a?.airline?.code + a.flight_no + '/' + moment(a.flight_date).format('DDMMMYY').toUpperCase();
      if (!flight.includes(f)) {
        flight.push(f);
      }
      if (a.airline) {
        if (!airline.includes(a.airline.code)) {
          airline.push(a.airline.code);
        }
      }
    });
    this.setState({
      airline,
      flight
    });
  };

  filterData = (search = false) => {
    let data = [...this.state.data];
    if (this.state.filter.airline && this.state.filter.airline != '') {
      data = data.filter((a) => a.airline.code === this.state.filter.airline);
    }
    if (this.state.filter.flight && this.state.filter.flight != '') {
      data = data.filter((a) => {
        let f = a?.airline?.code + a.flight_no + '/' + moment(a.flight_date).format('DDMMMYY').toUpperCase();
        return f === this.state.filter.flight;
      });
    }
    if (search && this.state.filter.awb && this.state.filter.awb != '') {
      data = data.filter((a) => {
        let f = a?.airline?.code + a.flight_no + '/' + moment(a.flight_date).format('DDMMMYY').toUpperCase();
        return f === this.state.filter.awb;
      });
    }
    this.setState({ filterData: data });
  };

  onChangefilter = (e) => {
    this.setState({
      filter: {
        ...this.state.filter,
        [e.target.name]: e.target.value
      }
    }, () => { e.target.name != 'awb' || (e.target.name == 'awb' && !e.target.value) ? this.filterData(e.target.name == 'awb' || e.target.name == 'awb_search') : {}; });
  };

  handlePopoverClose = () => {
    this.setState({
      attachment: false,
      anchorEl: false
    });
  };

  render() {
    return (
      <Box sx={{ position: 'relative' }}>
        <FlightManifest
          handlePopover={this.handlePopover}
          handlePopoverClose={this.handlePopoverClose}
          anchorEl={this.state.anchorEl}
          modal={this.state.modal}
          data={this.state.filterData}
          searchOptions={this.state.search}
          handleChange={this.handleFilterChange}
          search={this.fetchFlight}
          airline={this.state.airline}
          flight={this.state.flight}
          preScreen={this.state.preScreen}
          onChangefilter={this.onChangefilter}
          filter={this.state.filter}
        />
        {this.state.modal === 'attached_docs' &&
          <AttachmentContainer
            handlePopover={this.handlePopover}
            anchorEl={this.anchorEl}
            modal={this.state.modal}
            type={'FLIGHT'}
            docs={this.state.docs}
          />
        }
      </Box>
    );
  }
}

export default FlightManifestContainer;
