import React from 'react';
import {
  Grid,
  Typography,
  InputAdornment,
  IconButton,
} from '@mui/material';
import {
  CustomButton,
  CustomTextField,
  InputProps
} from '../../../.././custom/components';
import styles from '../../../../custom/style.module.css';
import docStyles from '../docs.module.css';
import { Stack } from '@mui/system';
import { Search } from '@mui/icons-material';

const awbDoc = (props) => {

  return (
    <Grid container spacing={2} flexWrap='wrap'>
      <Grid item xs={12} sm={6} md={12} lg={12} xl={12} className={styles.w100}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
          <Typography variant='caption' component='label' className={docStyles.addTextLabel} htmlFor='awb_no'>Awb Number</Typography>
          <div className={docStyles.addTextField}>
            <CustomTextField
              {...InputProps}
              id='awb_no'
              fullWidth
              placeholder='Airwaybill Number'
              type='text'
              name='awb_no'
              onChange={(e) => props.handleChangeForm(e, 'awb_no')}
              value={props.formFields.awb_no || ''}
              InputProps={{
                disableUnderline: true,
                endAdornment: <InputAdornment position='end'>
                  <IconButton
                    size='small'
                    aria-label='search'
                    edge='end'
                    onClick={() => props.searchAwb()}
                  >
                    <Search fontSize='inherit' />
                  </IconButton>
                </InputAdornment>,
              }}
              InputLabelProps={{ shrink: false }}
            />
          </div>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={6} md={12} lg={12} xl={12} className={styles.w100}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
          <Typography variant='caption' component='label' className={docStyles.addTextLabel} htmlFor='pieces'>Pieces</Typography>
          <div className={docStyles.addTextField}>
            <CustomTextField
              {...InputProps}
              id='pieces'
              fullWidth
              placeholder='Pieces'
              type='text'
              onChange={(e) => props.handleChangeForm(e, 'pcs')}
              value={props.formFields.pcs || ''}
              name='pcs'
            />
          </div>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={6} md={12} lg={12} xl={12} className={styles.w100}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
          <Typography variant='caption' component='label' className={docStyles.addTextLabel} htmlFor='weight'>Weight</Typography>
          <div className={docStyles.addTextField}>
            <CustomTextField
              {...InputProps}
              id='weight'
              fullWidth
              placeholder='Weight'
              type='text'
              name='wgt'
              onChange={(e) => props.handleChangeForm(e, 'wgt')}
              value={props.formFields.wgt || ''}
              inputProps={{ 'aria-label': 'Weight' }}
            />
          </div>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={6} md={12} lg={12} xl={12} className={styles.w100}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
          <Typography variant='caption' component='label' className={docStyles.addTextLabel} htmlFor='origin'>Origin</Typography>
          <div className={docStyles.addTextField}>

            <CustomTextField
              {...InputProps}
              id='origin'
              fullWidth
              placeholder='Origin'
              type='text'
              onChange={(e) => props.handleChangeForm(e, 'org')}
              value={props.formFields.org || ''}
              name='org'
            />
          </div>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={6} md={12} lg={12} xl={12} className={styles.w100}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
          <Typography variant='caption' component='label' className={docStyles.addTextLabel} htmlFor='destination'>Destination</Typography>
          <div className={docStyles.addTextField}>
            <CustomTextField
              {...InputProps}
              id='destination'
              fullWidth
              placeholder='Destination'
              type='text'
              onChange={(e) => props.handleChangeForm(e, 'dest')}
              value={props.formFields.dest || ''}
              name='dest'
            />
          </div>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={6} md={12} lg={12} xl={12} className={styles.w100}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
          <Typography variant='caption' component='label' className={docStyles.addTextLabel} htmlFor='forwarder'>Forwarder</Typography>
          <div className={docStyles.addTextField}>
            <CustomTextField
              {...InputProps}
              id='forwarder'
              fullWidth
              placeholder='Forwarder'
              type='text'
              name='forwarder'
              onChange={(e) => props.handleChangeForm(e, 'forwarder')}
              value={props.formFields.forwarder || ''}
            />
          </div>
        </Stack>
      </Grid>

      <Grid item xs={12} className={`${docStyles.docBtn} ${docStyles.docBtnOnly}`}>
        <CustomButton variant='contained' disabled={!props.docId || props.disableButton} onClick={props.submit}> Update </CustomButton>
      </Grid>
    </Grid>
  );
};

export default awbDoc;