import React from 'react';
import { Dialog } from '@mui/material';
import { CustomDialogTitle, CustomDialogContent } from 'custom/components';
import { CloseIconBtn } from 'custom/constants';
import environment from '../../../../../custom/environments';

const FlightReportModal = (props) => {
  const flightURL = environment.appUrl + `flight_folder/notes?id=${props.flight}&showModal=true`;
  return (
    <div>
      <div>
        <Dialog open={props.modalOpen} onClose={props.handleModalClose} maxWidth={'lg'} fullWidth scroll='paper'>
          <CustomDialogTitle id='form-dialog-title'>
          Flight Report
            <CloseIconBtn onClick={props.handleModalClose} />
          </CustomDialogTitle>
          <CustomDialogContent sx={{ p: 0 }}>
            <iframe
              src={flightURL}
              style={{
                height:'calc(100vh - 180px)',
                width:'100%',
                borderStyle: 'none'
              }}
              title='Flight Report'
              id='iframe_id'
            ></iframe>
          </CustomDialogContent>
        </Dialog>
      </div>
    </div>
  );
};
export default FlightReportModal;