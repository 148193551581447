import React from 'react';
// import ReactDOM from 'react-dom';
// import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import styles from '../../../../../../custom/style.module.css';
import MyDocument from './Doc';
import { Font, PDFDownloadLink } from '@react-pdf/renderer';
import { CustomButton } from 'custom/components';
import { DownloadIcon } from 'custom/constants';

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf', },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
      fontWeight: 600 
    }
  ]
});
const Download = (props) => {
  return (
    <>
      {/* <PDFViewer width='1000' height='600' className='app' >
        <MyDocument {...props} />
      </PDFViewer> */}
  
      <PDFDownloadLink document={<MyDocument
        {...props}
      />} fileName={ props.filename || 'download.pdf'}
      className={styles.linkNoUnderline}>
        {/* {({ blob, url, loading, error }) => {
          console.log(blob, url, loading, error);
          loading ? 'Loading document...' : 'Download now!';
        }
        } */}
        <CustomButton variant='contained' size='small' className={`${styles.mlauto} ${styles.dBlock} ${styles.mr2}`}>
          <DownloadIcon className={`${styles.whiteSvg} ${styles.mr1} ${styles.btnIcons}`} /> {props.title}</CustomButton>
      </PDFDownloadLink>
    </>
  );
};

export default Download;