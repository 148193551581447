import React from 'react';
import { Box, Stack } from '@mui/material';
import { StyledDataGrid, StyledCard, StyledCardTitle, TableBadge, DataGridProps, } from '../../../../../custom/components';
import { EditIcon, LockIcon, LocationIcon, UnlockIcon, AddIcon } from '../../../../../custom/constants';
import AirlineStyles from './airlines.module.css';
import styles from '../../../../../custom/style.module.css';
import LocationContainer from 'components/pages/common/location/LocationContainer';
import AirlineModal from './AirlineModal';

const HandledAirlines = (props) => {
  const { airlineList, handleOpen, handleClose, onChange, setActive } = props;
  const columns = [
    {
      field: 'name',
      headerName: 'Airline Name',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'code',
      headerName: 'Airline Code',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'awb_pre',
      headerName: 'Airwaybill Prefix',
      minWidth: 100,
      flex: 1,

    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      minWidth: 100, 
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (data) => {
        return (
          <Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
            <div onClick={() => setActive(data.row.id, !data.row.active)}>
              {data.row.active ? <LockIcon title='Deactivate Airline' /> : <UnlockIcon title='Reactivate Airline' />}
            </div>
            <TableBadge color='error' badgeContent={data.row.stations && data.row.stations.length}>
              <div onClick={(e) => props.handleLocation('airline/update', true, data.row, e.target)}>
                <LocationIcon />
              </div>
            </TableBadge>
            <div onClick={() => handleOpen('edit', data.row)} >
              <EditIcon />
            </div>
          </Stack>
        );
      }
    },
  ];

  return (
    <div>
      <StyledCard>
        <Box className={`${styles.fSpaceBtw} ${styles.mb2}`} >
          <StyledCardTitle gutterBottom >Handled Airlines</StyledCardTitle>
          <AddIcon className={styles.addIcon} title='Add Airline' onClick={() => props.handleOpen('add')} />
        </Box>

        <div className={styles.tableWrapper}>
          <Box className={`${styles.tableOuter} ${AirlineStyles.airlineTableOuter}`}>
            <StyledDataGrid
              {...DataGridProps}
              rows={airlineList}
              columns={columns}
              param={airlineList?.length}
            />
          </Box>
        </div>
      </StyledCard>

      <AirlineModal
        addAirline={props.addAirline}
        onChange={onChange}
        handleClose={handleClose}
        openAddAirline={props.openAddAirline}
        airlineFields={props.airlineFields}
        handleOpen={handleOpen}
        airlineErrors={props.airlineErrors}
        type={props.type}
      />
          
      <LocationContainer
        anchorEl={props.anchorEl}
        open={props.location}
        type={props.locationType}
        handleClose={props.handleLocation}
        name={props.station.name}
        stations={props.stations}
        data={props.station}
      />
    </div>
  );
};

export default HandledAirlines;