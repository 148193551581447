import React from 'react';
// import ReactDOM from 'react-dom';
// import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import styles from '../../../../custom/style.module.css';
import MyDocument from './Doc';
import { BlobProvider, Font, PDFDownloadLink } from '@react-pdf/renderer';
import { CustomButton } from 'custom/components';
import { DownloadIcon } from 'custom/constants';

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf', },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
      fontWeight: 600 
    }
  ]
});
const Download = (props) => {
  return (
    <>
      <PDFDownloadLink document={<MyDocument
        {...props}
      />} fileName={ props.filename || 'invoice.pdf'}
      className={styles.linkNoUnderline}>
        
        <BlobProvider document={<MyDocument
          {...props}
        />}>
          {({ blob, url, loading, error }) => {
            console.log(blob, url, loading, error);
            return <div>{
              <><CustomButton variant='contained' size='small' className={`${styles.dBlock} ${styles.mr2}`} disabled={loading}>
                <DownloadIcon className={`${styles.whiteSvg} ${styles.mr1} ${styles.btnIcons}`} /> {props.title}</CustomButton></>
            }</div>;
          }}
        </BlobProvider>
        
      </PDFDownloadLink>
    </>
  );
};

export default Download;