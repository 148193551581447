import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { dateTolocale } from 'utils/converter';
import { DateFormat } from 'custom/constants';

const textColor = '#393939';
const borderColor = 'lightgrey';

const styles = StyleSheet.create({
  headerContainer: { marginTop: 24, },
  noteWrapper: { 
    borderTop: '1px dashed lightgrey',
    // borderBottom: '1px dashed lightgrey',
    // marginTop: 10,
    padding: 10,
    paddingBottom: 5, 
  },
  replyWrapper: { 
    borderTop: '1px dashed lightgrey',
    borderBottom: '1px dashed lightgrey',
    padding: 10, 
    paddingBottom: 0
  },
  label: {
    fontSize: 10,
    paddingBottom: 3,
    lineHeight: 'normal',
  },
  data: {
    fontSize: 10,
    paddingBottom: 3,
    lineHeight: 'normal',
  },
  noteBy: { fontSize: 10 },
  note: {
    fontSize: 10,
    color: 'grey'
  },
  title: {
    fontSize: 12,
    fontWeight: 'bold',
    // borderBottom: '1px solid lightgrey',
    paddingBottom: 2,
  },
  noDataRow: {
    fontSize: 9,
    width: '100%',
    textAlign: 'center',
    padding: 4,
    color: textColor,
    borderColor: borderColor,
    borderWidth: 1,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});


const FlightNotes = (props) => {
  const { notes } = props;
  return (
    <View style={styles.headerContainer}>
      <Text style={styles.title}>Notes</Text>
      {notes && notes.length > 0 ? notes.map((list) => {
        return (
          <View key={list?.id} style={styles.noteWrapper}>

            <Text style={styles.data}>{list?.user?.first_name} {list?.user?.last_name} | {dateTolocale(list.created, `${DateFormat} HHmm`)}</Text>
            <Text style={styles.note}>{list?.note}</Text>

            {list?.replys && list?.replys.map((item, index) => (
              <View key={index} style={styles.replyWrapper} >
                <Text style={styles.noteBy}>{item.user?.first_name} {item.user?.last_name} | {dateTolocale(item.created, `${DateFormat} HHmm`)}</Text>
                <Text style={styles.note}>{item.note}</Text>
              </View>
            ))}
          </View>
        );
      }) : <View  style={styles.noDataRow}>
        <Text>No Data</Text>
      </View>
      }
    </View>
  );
};
  
export default FlightNotes;