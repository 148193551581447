import axios from 'axios';
import cookies from './cookies';
import { toast } from 'react-toastify';
import environment from '../custom/environments';
import { loaderRef } from '../components/header/loader';
const ignoreLoader = ['/user/login', '/user/password'];
// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    config.headers.company = cookies.get('company') || null;
    config.headers.station = cookies.get('station') || null;
    config.headers.stationcode = cookies.get('station_code') || null;
    config.headers.user = cookies.get('user') || null;
    config.baseURL = environment.apiService;
    config.withCredentials = true;
    return config;
  }
);

axios.interceptors.request.use((config) => {
  // if (!config.headers.hideloader && !ignoreLoader.includes(config.url) && config.method !== 'get') {
  if (!config.headers.hideloader && !ignoreLoader.includes(config.url)) {
    loaderRef.current.style.display = 'block';
  }
  return config;
});

axios.interceptors.response.use((response) => {
  // if (response && response.config && response.config.method != 'get') {
  //   toast.success(response.data.message);
  // }
  // if (!response.config.headers.hideloader && !ignoreLoader.includes(response.config.url) && response.config.method !== 'get') loaderRef.current.style.display = 'none';
  if(!response.config.headers.hideloader && !ignoreLoader.includes(response.config.url)) loaderRef.current.style.display = 'none';
  if (response?.config?.method === 'put' || response?.config?.method === 'post' || response?.config?.method === 'delete' && response?.data?.message) {
    toast.dismiss();
    if (response.config.headers) {
      response.config.headers?.ntoast ? toast.dismiss() : toast.success(response.data.message);
    }
  }
  return response;
}, error => {
  // if (!error.config.headers.hideloader && !ignoreLoader.includes(error.config.url) && error.config.method !== 'get') loaderRef.current.style.display = 'none';
  if(!error.config.headers.hideloader && !ignoreLoader.includes(error.config.url)) loaderRef.current.style.display = 'none';
  console.log('API ERROR=====>', error);
  const statusCode = error.response ? error.response.status : null;
  // if (error.code && error.code == 'ERR_NETWORK') {
  //   toast.error(error.message);
  // }
  if (error) {
    toast.error(error.response && error.response.data.message || error.message);
  }
  if (statusCode === 999 && cookies.get('login')) {
    // logout user
    cookies.remove('login', { path: '/' });
    cookies.remove('company_name', { path: '/' });
    cookies.remove('company_mail', { path: '/' });
    cookies.remove('timezone', { path: '/' });
    cookies.remove('stations', { path: '/' });
    cookies.remove('role', { path: '/' });
    cookies.remove('station', { path: '/' });
    cookies.remove('company', { path: '/' });
    cookies.remove('station_code', { path: '/' });
    cookies.remove('user', { path: '/' });
    cookies.remove('name', { path: '/' });
    cookies.remove('my_flights', { path: '/' });
    cookies.remove('ismanagec', { path: '/' });
    cookies.remove('managecdt', { path: '/' });
    cookies.remove('contract_types', { path: '/' });
    cookies.remove('currency', { path: '/' });
    setTimeout(() => {
      window.location.href = '/login';
    }, 2000);
  }
  return Promise.reject(error);
});

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch
};