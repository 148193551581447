import React, { Component, Fragment } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Document, Page } from 'react-pdf';
import { isImg, isDoc } from '../../../utils/attachments';
import unrecognizedDocsStyles from './docs.module.css';
import styles from '../../../custom/style.module.css';
import { StyledCard, StyledCardTitle, } from '../../../custom/components';
import environment from '../../../custom/environments';
import { Typography } from '@mui/material';

class ViewDocuments extends Component {

  constructor(props) {
    super(props);
    this.state = { numPages: {} };
  }


  openLink = (link) => {
    window.open(link);
  };

  onDocumentLoadSuccess = (num, index) => {
    const numPages = this.state.numPages;
    numPages[`pdf_${index}`] = num._pdfInfo.numPages;
    this.setState({ numPages });
  };

  onDocumentLoadError = (error) => {
    console.log('🚀 ~ file: DocViewer.js:87 ~ ViewDocuments ~ error:', error);
  };

  render() {

    return (
      <StyledCard className={unrecognizedDocsStyles.h100} classes={{ paper: unrecognizedDocsStyles.h100 }}>
        <div className={unrecognizedDocsStyles.incomingDocHead}>
          <StyledCardTitle gutterBottom>Document Viewer</StyledCardTitle>
        </div>
        <div className={`${unrecognizedDocsStyles.viewDocBody} ${styles.positionRelative}`}>
          {this.props.docData.length > 0 ? 
            this.props.docData.map((item, i) => {
              const itemLink = environment.apiService + 'document?key=' + item.src;
              const fileName = item.src && item.src.split('/').pop();
              if (!item.src && item.request && !item.request_status) {
                return (
                  <Fragment key={i}>
                    <div className={unrecognizedDocsStyles.placeholderText}>
                      <div className={`${styles.fMiddle} ${styles.fColumn}`}>
                        <Typography component={'h5'} variant={'h5'} color='primary'>Document Requested</Typography>
                        <Typography component={'span'} variant={'caption'} color='text.secondary'>The document is yet to be received</Typography>
                      </div>
                    </div>
                  </Fragment>
                );
              }
              return (
                <div key={i} className={`${unrecognizedDocsStyles.canvasDoc} ${unrecognizedDocsStyles.px4}`}>

                  {item.src && isImg(fileName) && (
                    <img
                      src={itemLink}
                      alt={fileName}
                      className={unrecognizedDocsStyles.image}
                    />
                  )}
                  {item.src && isDoc(fileName) && (
                    <Document
                      file={itemLink}
                      onItemClick={() => {
                        this.openLink(itemLink);
                      }}
                      options={{ withCredentials: true }}
                      onLoadSuccess={(page) => this.onDocumentLoadSuccess(page, i)}
                      onLoadError={this.onDocumentLoadError}
                      className={unrecognizedDocsStyles.docDoc}
                    > {
                        Array.from(
                          new Array(this.state.numPages[`pdf_${i}`]),
                          (el, index) => (
                            <Page
                              className={unrecognizedDocsStyles.docPage}
                              key={`page_${index + 1}`}
                              pageNumber={index + 1}
                              renderTextLayer={false}
                              renderAnnotationLayer={false} 
                            />
                          )
                        )
                      }
                    </Document>
                  )}
                </div>
              );
            }) : 
            <div className={unrecognizedDocsStyles.placeholderText}>
              <div className={`${styles.fMiddle} ${styles.fColumn}`}>
                <Typography component={'h5'} variant={'h5'} color='primary'>Document Preview Window</Typography>
                <Typography component={'span'} variant={'caption'} color='text.secondary'>Please select a document to preview</Typography>
              </div>
            </div>}
        </div>
      </StyledCard>

    );
  }

}

export default (ViewDocuments);
