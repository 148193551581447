import React from 'react';
import http from 'utils/http';
import moment from 'moment';
import cookies from 'utils/cookies';
import EncryptionModal from './EncryptionModal';

class EncryptionContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      otp: '',
      timer: null,
      expiryDate: null,
      sessionExpired: false,
      modal: null,
      showOtp: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.modal !== prevProps.modal) {
      this.setState({ modal: this.props.modal });
    }
  }
  handleOTP = () => {
    this.setState({ 
      showOtp: true,
      otp: '',
    });
  };
  handleOTPChange = (e) => {
    console.log('handleOTPChange', e.target.name, e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  };
  authenticate = () => {
    let formdata = {
      username: this.state.username,
      password: this.state.password,
    };
    http
      .post('/gen_contract_otp', formdata)
      .then(({ data }) => {
        if (data.status == 'success') {
          this.handleOTP();
        }
      })
      .catch((error) => {
        console.error('Error sending OTP:', error);
      });
  };
  validateOTP = () => {
    let formdata = { otp: this.state.otp };
    http
      .post('/verify_contract_otp', formdata)
      .then(({ data }) => {
        if (data.status == 'success') {
          // set managecdtcookie with expiry date. for expiry use data?.data?.managecdt
          // subract 2 hours and add 2 mins to managecdt
          // let managecdt = moment.utc(data?.data?.managecdt).subtract(2, 'hours').add(2, 'minutes').format('YYYY-MM-DD HH:mm:ss');
          let managecdt = data?.data?.managecdt;
          let localdt = moment
            .utc(managecdt)
            .local()
            .format('YYYY-MM-DD HH:mm:ss');
          let expiryDate = moment.utc(managecdt).local().toDate(); // Converts to local time and then to a JavaScript Date object
          cookies.set('managecdt', localdt, {
            path: '/',
            expires: expiryDate,
          });
          cookies.set('expiryTimer', expiryDate, { path: '/' });
          this.setState({ 
            expiryDate: expiryDate,
            showOtp: false,
          }, () => {
            this.props.startTimer();
          });
          if (this.props.functionCall) {
            this.props.functionCall();
          }
          this.props.handleOpen('status', null, null, null);
          // show/enable all buttons and fetch data with rates.
        }
      })
      .catch((error) => {
        console.error('Error validating OTP:', error);
      });
  };

  render() {
    return (
      <EncryptionModal

        onClose={this.props.onClose}
        handleOpen={this.props.handleOpen} 
        handleOTPChange={this.handleOTPChange}
        handleOTP={this.handleOTP}
        authenticate={this.authenticate}

        showOtp={this.state.showOtp}
        modal={this.state.modal}
        action={this.state.action}
        username={this.state.username}
        password={this.state.password}
        otp={this.state.otp}
        validateOTP={this.validateOTP}
        expiryTimer={this.state.expiryTimer}
        sessionExpired={this.state.sessionExpired}
      />
    );
  }
}
export default EncryptionContainer;
