import React from 'react';
import FfmDashboard from './FfmDashboard';
import http from 'utils/http';
import allData from 'airline-iata-code';
import { getStationDateTime } from 'utils/converter';


class FfmDashboardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      ffm: []
    };
  }

  componentDidMount() {
    this.getFFM();
  }

  getFFM = () => {
    http.get('/ffm/txt').then(({ data }) => {
      this.setState({ ffm: data.data }, () => {
        this.filterData();
      });
    }
    );
  };

  filterData = () => {
    let data = this.state.ffm;
    let filteredData = [];
    data.forEach((item) => {
      let msg = item.msg.split('\n').reverse();
      let parsedData = {};
      msg.pop();
      let flight_parse = msg.pop();
      console.log(flight_parse);
      parsedData.flight_no = flight_parse.split('/')[1];
      // parsedData.flight_date = moment(flight_parse.split('/')[2], 'DDMMM').format('YYYY-MM-DD');
      parsedData.org = flight_parse.split('/')[3];
      parsedData.dest = msg.pop()?.split('/')[0];
      let name = parsedData.flight_no + '-' + parsedData.org + '-' + parsedData.dest;
      let temp = {
        id: item.id,
        name: name,
        airline: item.airline,
        flight: parsedData.flight_no,
        seg: parsedData.org + '-' + parsedData.dest,
        source: item.source,
        created: item.created
      };
      filteredData.push(temp);
    });

    // remove duplicates by name
    filteredData = filteredData.filter((thing, index, self) =>
      index === self.findIndex((t) => (
        t.name === thing.name
      ))
    );

    filteredData = filteredData.map((item) => {
      let airline = allData(item.flight.trim().substring(0, 2));
      if (item.flight.trim().substring(0, 2) === 'Z0') {
        airline = allData('Z4');
      }
      return {
        ...item,
        airline: airline[0]?.Airline,
        created: getStationDateTime('GOI', item.created, 'YYYY-MM-DD HH:mm:ss') +' IST'
      };
    }
    );
    //sort by airline
    filteredData.sort((a, b) => a.airline.localeCompare(b.airline));
    this.setState({ data: filteredData });
  };


  render() {
    return (
      <FfmDashboard data={this.state.data} />
    );
  }
}

export default FfmDashboardContainer;