import React, { Component } from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';
import Redirect from '../components/pages/redirect/RedirectContainer';
import AdminContainer from '../components/pages/admin/AdminContainer';
import HeaderContainer from '../components/header/HeaderContainer';
import DocsStreamContainer from 'components/pages/docsStream/DocsStreamContainer';
import auth from '../utils/auth';
import theme from '../custom/theme';
import http from '../utils/http';
import styles from '../custom/style.module.css';
import ScreeningReportContainer from 'components/pages/reports/ScreeningReportContainer';
import AnalyticsContainer from 'components/pages/analytics/AnalyticsContainer';
import DocArchiveContainer from 'components/pages/docArchive/DocArchiveContainer';
import FlightsContainer from 'components/pages/flights/FlightsContainer';
import MyFlightsContainer from 'components/pages/myflights/MyFlightsContainer';
import PrintPasswordContainer from 'components/pages/admin/tabs/manageusers/printpassword/PrintPasswordContainer';
import LogsContainer from 'components/pages/servicelogs/LogsContainer';
import BulkApprovalContainer from 'components/pages/bulkapproval/BulkApprovalContainer';
import BillingContainer from 'components/pages/billing/BillingContainer';
import AwbSearchContainer from 'components/pages/awbSearch/AwbSearchContainer';
import DeveloperContainer from 'components/pages/developer/DeveloperContainer';
import cookies from 'utils/cookies';
import FfmDashboardContainer from 'components/pages/ffmDashboard/FfmDashboardContainer';

class Routes extends Component {
  componentDidMount() {
    if (!auth()) {
      this.props.history.push('/login');
    } else {
      if (!cookies.get('password') || cookies.get('password') === 'false') {
        this.props.history.push('/change-password');
      }
      http.get('/constant').then(({ data }) => {
        localStorage.setItem('constant', JSON.stringify(data.data));
      }).catch(() => { });
    }
  }
  render() {
    return (
      <div>
        <HeaderContainer theme={theme} />
        <div className={styles.contentContainer}>
          <Switch>
            <Route exact path='/admin/:tab?' component={AdminContainer} />
            <Route exact path='/docs_stream' component={DocsStreamContainer} />
            <Route exact path='/screening_reports' component={ScreeningReportContainer} />
            <Route exact path='/analytics' component={AnalyticsContainer} />
            <Route exact path='/doc_archive/:tab?' component={DocArchiveContainer} />
            <Route exact path='/my_flights' component={MyFlightsContainer} />
            <Route exact path='/flight_folder/:tab?' component={FlightsContainer} />
            <Route exact path='/service_log' component={LogsContainer} />
            <Route exact path='/bulk_approvals/:tab?' component={BulkApprovalContainer} />
            <Route exact path='/billing/:tab?' component={BillingContainer} />
            <Route exact path='/print_user_details' component={PrintPasswordContainer} />
            <Route exact path='/awb_search' component={AwbSearchContainer} />
            <Route exact path='/product_config' component={DeveloperContainer} />
            <Route exact path='/ffm_dashboard' component={FfmDashboardContainer} />
            <Route exact path='/redirect' component={Redirect} />
            <Redirect from='/*' to='/redirect' />
          </Switch>
        </div>
      </div>
    );
  }
}

export default withRouter(Routes);