import React from 'react';
import { StyledDataGrid, DataGridProps, } from 'custom/components';
import { Box, Grid, Skeleton, Stack, Typography, } from '@mui/material';
import flightStyles from './fltloadinfo.module.css';
import styles from '../../../../../custom/style.module.css';
import { grey } from '@mui/material/colors';

const roundNumber = (num) => {
  // return parseFloat(num).toFixed(2);
  let int = parseInt(num, 10);
  return Math.round(int*100)/100;
};
const Statistics = (props) => {
  console.log(props, 'props');
  const { summaryData, billingColumns, showSummary } = props;

  const summaryProps = {
    variant: 'body2',
    component: 'small',
    color: grey[800],
    fontSize: 13,
  };

  const summaryMainProps = {
    variant: 'body1',
    component: 'p',
    color: 'primary',
    fontWeight: 600,
    fontSize: '0.9rem',
  };

  return <>
    <Grid container spacing={3} className={styles.pt1}>
      { billingColumns && billingColumns.length > 0 ? showSummary && billingColumns.map((column, index) => {
        return <Grid item sm={12} md={6} lg={4} xl={3} key={index}>
          <div className={flightStyles.summaryCard}>
            <div className={`${styles.fStartSpaceBtw} ${styles.pb2}`}>
              <Typography {...summaryMainProps}>{column.headerName}</Typography>
              <Typography {...summaryMainProps}>{roundNumber(summaryData[column.field])}</Typography>
            </div>
            <div className={`${styles.fSpaceBtw} ${styles.mtauto} ${styles.pt2} ${flightStyles.summaryHead}`}>
              <Stack direction='column'>
                <Typography {...summaryProps}>Inbound</Typography>
                <Typography {...summaryProps}>{summaryData.inbound && roundNumber(summaryData.inbound[column.field]) || 0 }</Typography>
              </Stack>
              <Stack direction='column'>
                <Typography {...summaryProps}>Outbound</Typography>
                <Typography {...summaryProps} textAlign='right'>{ summaryData.outbound && roundNumber(summaryData.outbound[column.field]) || 0}</Typography>
              </Stack>
            </div>
          </div>
        </Grid>;
      }) : Array.from({ length: 9 }).map((_, index) => <Grid item sm={12} md={6} lg={4} xl={3} key={index}>
        <Skeleton variant='rectangular' width={'100%'} height={100} /> 
      </Grid>)
      }
    </Grid>
    
    {!showSummary &&
    <div className={`${styles.tableWrapper} ${styles.pt2}`}>
      <Box className={`${styles.tableOuter} ${flightStyles.statTableOuter} ${styles.mt3}`}>
        <StyledDataGrid
          {...DataGridProps}
          rows={props?.contractData || []}
          columns={props.billingCol || []}
          // apiRef={props.apiRef}
          param={props.contractData?.length}
        />
      </Box>
    </div>
    }
  </>;
};

export default Statistics;