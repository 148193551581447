import React from 'react';
import { /*FormControl,*/ Grid, Stack/*, FormHelperText*/, Box/*, IconButton*/ } from '@mui/material';
import { StyledCard, StyledCardTitle, DataGridProps, StyledDataGrid, TableBadge/*, CustomInputLabel, CustomTextField, InputProps, CustomStyle, CustomSelect, SelectInputProps, CustomAutocomplete*/ } from '../../../../../custom/components';
import styles from '../../../../../custom/style.module.css';
import discrepancyStyles from './discrepancy.module.css';
import { AddIcon, AttachmentIcon, DeleteIcon, EditIcon, ExcelIcon } from '../../../../../custom/constants';
import ManifestSummaryContainer from '../../summary/ManifestSummaryContainer';
import Attachment from './Attachment';
import DocModalContainer from 'components/pages/common/docModal/DocModalContainer';
import AddDiscrepancyModal from './AddDiscrepancyModal';
import { CsvToExcel } from 'custom/export';

const Discrepancy = (props) => {

  const { showReportModal } = props;
  const apiRef = React.useRef(null);

  const columns = [
    {
      field: 'type',
      headerName: 'Type',
      minWidth: 130,
      flex: 1,
    },
    {
      field: 'uld_no',
      headerName: 'ULD',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <>
            {row.uld_no && row.uld_no != 'null' ? row.uld_no : <div className={styles.ml4}>-</div>}
          </>
        );
      }
    },
    {
      field: 'uld_type',
      headerName: 'ULD Type',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <>
            {row.uld_type && row.uld_type != 'null' ? row.uld_type : <div className={styles.ml4}>-</div>}
          </>
        );
      }
    },
    {
      field: 'handled_as',
      headerName: 'Handled As',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'awb_no',
      headerName: 'Airwaybill',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'prod_code',
      headerName: 'Product',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'org',
      headerName: 'Origin',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'dest',
      headerName: 'Dest',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 't_pcs',
      headerName: 'Total Pcs',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 't_wgt',
      headerName: 'Total Wgt',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'd_pcs',
      headerName: 'Dscpy Pcs',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'd_wgt',
      headerName: 'Dscpy Wgt',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'remark',
      headerName: 'Remarks',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'docs',
      headerName: 'Attachment',
      minWidth: 100,
      flex: 1,
      disableExport: true,
      align: 'center',
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <>
            <TableBadge color='error' badgeContent={row?.docs?.length} sx={{ position: 'absolute' }}>
              <AttachmentIcon title='Add/View Attachment' onClick={() => props.handleDoc(row)} />
            </TableBadge>
          </>
        );
      }
    },
    ...(!showReportModal ?
      [{
        field: 'action',
        headerName: 'Action',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        disableExport: true,
        renderCell: ({ row }) => {
          return (
            <Stack direction='row' spacing={1} justifyContent='center' className={styles.actionIcons}>
              <EditIcon onClick={() => props.onEdit(row)} />
              <DeleteIcon onClick={(e) => props.onDelete(row.id, e.target)} />
            </Stack>
          );
        }
      },] : []
    )
  ];

  const handleExport = async () => {
    const csvData = await apiRef.current.getDataAsCsv();
    CsvToExcel(csvData, 'Discrepancy');
  };

  return (
    <>
      <Attachment
        handlePopover={props.handlePopover}
        anchorEl={props.anchorEl}
        attachment={props.attachment}
        doc={props.doc}
        onDeleteAttachment={props.onDeleteAttachment}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} className={styles.pt0}>
          <ManifestSummaryContainer showReportModal={props.showReportModal} nil={props.nil_manifest} />
        </Grid>

        <Grid item xs={12} className={styles.pt0}>
          <StyledCard>
            {/* <IconButton onClick={handleExport}> <Download /> </IconButton> */}
            <StyledCardTitle gutterBottom component='div' className={`${styles.fSpaceBtw} ${styles.pb2} ${styles.mb2}`}>Discrepancies
              <Stack direction={'row'} spacing={2}>
                <AddIcon onClick={() => props.handleOpen('add')} title='Add Discrepancy' />
                <ExcelIcon title='Download File' onClick={handleExport} className={`${styles.tableIcon} ${discrepancyStyles.discExcelIcon}`} />
              </Stack>
            </StyledCardTitle>
            <div className={styles.tableWrapper}>
              <Box className={`${styles.tableOuter} ${discrepancyStyles.discTableOuter}`}>
                <StyledDataGrid
                  {...DataGridProps}
                  rows={props.discrepancyList}
                  columns={columns}
                  apiRef={apiRef}
                  param={props.discrepancyList?.length}
                />
              </Box>
            </div>
          </StyledCard>
        </Grid>

      </Grid>

      <DocModalContainer />
      <AddDiscrepancyModal
        modal={props.modal}
        handleOpen={props.handleOpen}
        discrepancyFields={props.discrepancyFields}
        discrepancyErrors={props.discrepancyErrors}
        onClose={props.onClose}
        addEditDiscrepancy={props.addEditDiscrepancy}
        action={props.action}
        onChange={props.onChange}
        onULDChange={props.onULDChange}
        onAWBChange={props.onAWBChange}
        onInputChange={props.onInputChange}
        getULDData={props.getULDData}
        productsList={props.productsList}
      />
    </>
  );
};

export default Discrepancy;