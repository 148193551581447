import React, { Fragment } from 'react';
import Box from '@mui/material/Box';
import ConfirmModalContainer from '../common/confirmModal/ConfirmModalContainer';
import styles from '../../../custom/style.module.css';
import reportStyles from '../flights/tabs/flightreport/reports.module.css';
import { FormControl, FormLabel, Grid, MenuItem, Radio, RadioGroup, Stack, Typography, Divider, Popover } from '@mui/material';
import { CustomDatePicker, CustomInputLabel, CustomRadio, CustomSelect, SelectInputProps, StyledCard, StyledCardTitle, InputProps, CustomButton, CustomTextField } from '../../../custom/components';
import { MonthFormat, DateFormat } from 'custom/constants';
import dayjs from 'dayjs';
import SummeryContainer from './summary/SummaryContainer';
import cookies from 'utils/cookies';
import moment from 'moment';
import ReOpenModal from '../common/ReOpenModal';

const Logs = (props) => {
  const { search, airlines, data, contracts } = props;

  return (
    <>
      <Grid container spacing={3}>
        {/* <Grid item xs={2}>
          { props.airlineId && (<CustomButton variant='contained' className={`${styles.mlauto} ${styles.dFlex} ${reportStyles.finalizeBtn}`} name='finalize'
            onClick={() => props.history.push('/approvals/service_logs')}
          >Back</CustomButton>)}
        </Grid> */}
        <Grid item xs={12}>
          <StyledCard>
            <StyledCardTitle gutterBottom>Service Logs</StyledCardTitle>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                  <CustomInputLabel id='airline-label' aria-label='Select-item'>Airline</CustomInputLabel>
                  <FormControl variant='standard' fullWidth>
                    <CustomSelect
                      {...SelectInputProps}
                      id='airline'
                      labelId='airline-label'
                      onChange={props.onChangeSearch}
                      name='airline'
                      value={!props.search.airline ? 'placeholder' : props.search?.airline}
                      disabled = {props.airlineId ? true : false}
                    >
                      <MenuItem value={false} disabled>Select an Airline</MenuItem>
                      {airlines.map((airline, index) => (
                        <MenuItem key={index} value={airline.id}>{airline.code}</MenuItem>
                      ))}
                    </CustomSelect>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                  <CustomInputLabel id='contract-label' aria-label='Select-contract'>Department</CustomInputLabel>
                  <FormControl variant='standard' fullWidth>
                    <CustomSelect
                      {...SelectInputProps}
                      id='contract'
                      labelId='contract-label'
                      fullWidth
                      className={`${styles.brInput}`}
                      onChange={props.handleContractType}
                      value={props.contract_Type} 
                      // value={props.filterLogs}
                    >
                      <MenuItem value='placeholder' disabled>Select a Department</MenuItem>
                      {/* {props.contractTypesList?.map((type, index) => (
                        <MenuItem key={index} value={type}>{type}</MenuItem>
                      ))} */}
                      {cookies.get('contract_types')?.map((type, index) => (
                        <MenuItem key={index} value={type}>{type}</MenuItem>
                      ))}
                    </CustomSelect>
                  </FormControl>
                </Grid>
                <Grid item>
                  <CustomInputLabel id='bill_from_details' className={`${styles.radioLabel} ${styles.px3}`}>Daily / Monthly Service Log</CustomInputLabel>
                  <RadioGroup aria-labelledby='user-status' name='type' value={search.type} onChange={props.onChangeSearch} row className={`${styles.ml0} ${styles.mb2} ${styles.px3}`}>
                    <Stack direction='row' spacing={3} className={`${styles.fMiddle}`} flexWrap='wrap' sx={{ marginLeft: '-10px' }}>
                      <CustomRadio control={<Radio size='small' />} value='DAY' label='Daily Service Log' />
                      <CustomRadio control={<Radio size='small' />} value='MONTH' label='Monthly Service Log' />
                    </Stack>
                  </RadioGroup>
                </Grid>
                {search.type && search.type === 'DAY' ?
                  <>
                    <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                      <CustomInputLabel htmlFor='date'> From Date</CustomInputLabel>
                      <CustomDatePicker
                        slotProps={{
                          textField: {
                            ...InputProps,
                            placeholder: 'Date',
                            fullWidth: true
                          },
                        }}
                        variant='standard'
                        disableUnderline
                        value={dayjs(search.date)}
                        onChange={(date) => props.onChangeSearch({
                          target: {
                            name: 'start_date',
                            value: date.format('YYYY-MM-DD')
                          }
                        })}
                        format='DD MMM YY'
                        name='day'
                        className={`${styles.w100}`}
                        disabled = {props.airlineId ? true : false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                      <CustomInputLabel htmlFor='date'> To Date</CustomInputLabel>
                      <CustomDatePicker
                        slotProps={{
                          textField: {
                            ...InputProps,
                            placeholder: 'Date',
                            fullWidth: true
                          },
                        }}
                        variant='standard'
                        disableUnderline
                        value={dayjs(search.date)}
                        minDate={dayjs(search.start_date)}
                        maxDate={dayjs(search.start_date).endOf('month')}
                        onChange={(date) => props.onChangeSearch({
                          target: {
                            name: 'end_date',
                            value: date.format('YYYY-MM-DD')
                          }
                        })}
                        format='DD MMM YY'
                        name='day'
                        className={`${styles.w100}`}
                        disabled = {props.airlineId ? true : false}
                      />
                    </Grid>
                  </>
                  :
                  <Grid item xs={2}>
                    <CustomInputLabel htmlFor='date'>Month</CustomInputLabel>
                    <CustomDatePicker
                      slotProps={{
                        textField: {
                          ...InputProps,
                          placeholder: 'Month',
                          fullWidth: true
                        },
                      }}
                      views={['month', 'year']}
                      variant='standard'
                      disableUnderline
                      format={MonthFormat}
                      value={dayjs(search.date)}
                      onChange={(date) => props.onChangeSearch({
                        target: {
                          name: 'month',
                          value: date.format('YYYY-MM-DD')
                        }
                      })}
                      name='month'
                      className={`${styles.w100}`}
                      disabled = {props.airlineId ? true : false}
                    />
                  </Grid>
                }
              </Grid>
            </Box>
          </StyledCard>
        </Grid>

        <Grid item xs={12}>
          <StyledCard className={styles.h100}>
            <Box className={`${styles.mb4} ${styles.fSpaceBtw}`} >
              <StyledCardTitle className={`${styles.pb0}`}>{props.search.type && props.search.type === 'DAY' ? 'Daily Service Logs' : 'Monthly Service Log'} </StyledCardTitle>
              <Stack direction='row' spacing={2} justifyContent='center'
                flexWrap={{
                  xs: 'wrap',
                  sm: 'nowrap'
                }}
                alignItems={'center'}
              >

                <FormLabel component='small' className={`${styles.xsW100} ${styles.pr2}`}><small>Filter:</small> </FormLabel>
                <RadioGroup aria-labelledby='log-filter' name='type' 
                  value={props.value}
                  onChange={props.onChangeFilter} 
                  row className={styles.ml0}
                  defaultValue={'All'}>
                  <Stack direction='row' spacing={2} className={`${styles.fMiddle}`} flexWrap='wrap'>
                    <CustomRadio control={<Radio size='small' />} value='All' label='All' />
                    <CustomRadio control={<Radio size='small' />} value='Pending' label='Pending Logs' />
                    <CustomRadio control={<Radio size='small' />} value='Finalized' label='Finalized' />
                  </Stack>
                </RadioGroup>
        
              </Stack>
            </Box>
            <SummeryContainer 
              data={data} 
              contracts={contracts} 
              fetchLogs={() => props.fetchLogs()}
              cont_item={props.cont_item}
              airlineId={props.airlineId}
              onChange={props.onChange}
              filteredData={props.filteredData}
              onCheckAll={props.onCheckAll}
              checkedAll={props.checkedAll}
              checked={props.checked}
              recentChecked={props.recentChecked}
              disabledCheckbox={props.disabledCheckbox}
              search={props.search}
              contract_Type={props.contract_Type}
              filterLogs={props.value}
              emptyLog={props.emptyLog}
              filterData={props.filterData}
              handleDoc={props.handleDoc}
            />
          </StyledCard>
        </Grid>
        <Grid item container justifyContent={'space-between'} spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} order={{
            xs: 2,
            xl: 1
          }}>
            <div ref={props.refROBrn} className={styles.fColumn}>
              <CustomButton variant='contained' className={`${styles.minWidth280} ${styles.alignSelfStart}`} name='reopen' 
                // disabled={!props.reopen}
                disabled={props.value === 'All' || props.value === 'Pending' }
                // onClick={props.onReopen}
                onClick={() => {props.handleClick(props.refROBrn.current);}}
              > Reopen Logs (Supervisors Only)</CustomButton>
            </div>
          </Grid>

          <Grid item xs={12} xl={4} order={{
            xs: 1,
            xl: 2
          }}>
            <CustomTextField
              variant='outlined'
              id=''
              fullWidth
              placeholder='Enter remarks (Optional)'
              type='remark'
              inputProps={{ 'aria-label': 'remarks' }}
              value={props.remark}
              name='remark'
              onChange={props.onChange}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6} xl={2} order={{ xs: 3 }}>
            <CustomButton variant='contained' className={`${styles.mlauto} ${styles.dFlex} ${reportStyles.finalizeBtn}`} name='finalize'
              onClick={()=>props.onSubmit('Submitted') } disabled={props.value === 'Finalized'}
            >Submit</CustomButton> 
          </Grid>
        </Grid>
      </Grid>

      <ConfirmModalContainer />
      
      <>
        {props.popup === 'finalize' && (
          <div 
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 2,
            }}
            onClick={props.closePopup}
          />
        )}
        <Popover
          open={props.popup === 'finalize'}
          onClose={props.closePopup}
          anchorReference='anchorPosition'
          anchorPosition={{
            top: window.innerHeight / 2,
            left: window.innerWidth / 2,
          }}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          style={{ zIndex: 4 }}
        ><div className={styles.p4}>
            <Typography variant='h6' pb={1}>Confirm Action</Typography>
            <Divider direction='horizontal' className={`${styles.w100}`} />
            <Typography variant='body2' pt={3}>Service Logs data will now be processed for billing and invoicing.</Typography>
            {
              // list all selected logs from filteredData whose ids are present in finalizeLogs
              props.filteredData.filter((log) => props.finalizeLogs.includes(log.id)).sort((a, b) => new Date(a.date) - new Date(b.date)).map((log, index) => (
                <Typography variant='body2' key={index}>• {log.type === 'MONTH' ? moment(log.date).format('MMMM').toUpperCase() : moment(log.date).format(DateFormat).toUpperCase()}</Typography>
              ))
            }
            <Stack pt={'2rem'} spacing={1} direction='row' justifyContent={'space-between'}>
              <CustomButton variant='contained' size='small' onClick={() => {props.closePopup(); props.onSubmit('Finalized'); }} > <Typography variant='body2' lineHeight='normal' fontWeight='bold'>Okay</Typography></CustomButton>
              <CustomButton variant='outlined' size='small' onClick={props.closePopup}> <Typography variant='body2' lineHeight='normal'>Cancel</Typography></CustomButton>
            </Stack>
          </div>
        </Popover>
      </>
      <ReOpenModal 
        anchorEl={props.anchorEl3} 
        submitFunc={props.onReopen} 
        remark={props.remarks} 
        setRemark={props.setRemark}
        modalflight={props.filteredReportLogs}
        handleClose={props.handleClose}
      />
    </>
  );
};

export default Logs;
