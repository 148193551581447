import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';


export function DataToExcel(data, fileName) {
  const worksheet = XLSX.utils.aoa_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Generate XLSX file and trigger download
  const excelBuffer = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array'
  });
  const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
  saveAs(blob, fileName + '.xlsx');
}

export function CsvToExcel(data, fileName) {
  const workbook = XLSX.read(data, {
    type: 'string',
    raw: true 
  });
  const worksheet = workbook.Sheets[workbook.SheetNames[0]];

  // Create a new workbook and append the worksheet
  const newWorkbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(newWorkbook, worksheet, 'Sheet1');

  // Generate XLSX file and trigger download
  const excelBuffer = XLSX.write(newWorkbook, {
    bookType: 'xlsx',
    type: 'array'
  });
  const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
  saveAs(blob, fileName + '.xlsx');
}


export function JsonToExcel(data, fileName) {
  const worksheet = XLSX.utils.json_to_sheet(data, { header: Object.keys(data[0]) });
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Generate XLSX file and trigger download
  const excelBuffer = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array'
  });
  const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
  saveAs(blob, fileName + '.xlsx');
}