import React from 'react';
import { Dialog, Typography } from '@mui/material';
import styles from 'custom/style.module.css';
import flightStyles from './flightsfolder.module.css';
import { CustomButton, CustomDialogTitle, CustomDialogAction, StyledCard, CustomDialogContent } from 'custom/components';
import { CloseIconBtn } from 'custom/constants';

const FlightModal = (props) => {
  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={props.modal} onClose={props.closeModal}>
        <CustomDialogTitle id='form-dialog-title'>
          Choose Flight
          <CloseIconBtn onClick={props.closeModal} />
        </CustomDialogTitle>
        <CustomDialogContent sx={{ width: 600 }}>

          {props.modalData && props.modalData.length === 0 && 
          <div className={`${styles.fMiddle} ${styles.fColumn} ${flightStyles.noFlight}`}>
            <Typography component={'h5'} variant={'h5'} color='primary'>Flight not found</Typography>
            <Typography component={'span'} variant={'caption'} color='text.secondary'>Please try another flight</Typography>
          </div>
          }

          {props.modalData && props.modalData.length > 0 && 
          <StyledCard>
            <table className={`${styles.w100} ${styles.table}`}>
              <thead>
                <th colSpan={2}>Flight No.</th>
                <th>Flight Date</th>
                <th>Origin</th>
                <th>Destination</th>
              </thead>
              <tbody>
                {props.modalData && props.modalData.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td><input type='radio' name='flight' value={item.id} onChange={() => props.handleFlight('flight', item)} />{item?.airline?.code} {item.flight_no}</td>
                      <td>{item.flight_date}</td>
                      <td>{item.org}</td>
                      <td>{item.dest}</td>
                    </tr>
                  );
                })
                }
              </tbody>
            </table>
          </StyledCard>
          }
        </CustomDialogContent>

        {props.modalData && props.modalData.length > 0 &&
        <CustomDialogAction className={`${styles.fSpaceBtw}`}>
          <CustomButton variant='contained' onClick={props.closeModal}>Close</CustomButton>
          <CustomButton variant='contained' onClick={()=>props.handleFlight('confirm', {})} >Confirm</CustomButton>
        </CustomDialogAction>}
      </Dialog>
    </div>
  );
};
export default FlightModal;