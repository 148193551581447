import React from 'react';
import { Dialog, DialogContent, IconButton, Grid, Tooltip, InputAdornment } from '@mui/material';
import styles from 'custom/style.module.css';
import { CustomButton, CustomTextField, CustomDialogTitle, CustomDialogAction, CustomInputLabel, StyledCard, InputProps } from 'custom/components';
import { BrowseIcon, CloseIconBtn } from 'custom/constants';

const AddReportDocModal = (props) => {
  const { modal, onClose, fields, onSubmit, onChange, handleOpen } = props;
  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={modal} onClose={onClose} maxWidth={'sm'} fullWidth>
        <CustomDialogTitle id='form-dialog-title'>
          {'Add New Attachment'}
          <CloseIconBtn onClick={() => handleOpen(false)} />
        </CustomDialogTitle>
        <form className={styles.w100} onSubmit={onSubmit}>
          <DialogContent>
            <StyledCard>
              <Grid container spacing={2}>
                <Grid item xs={12} >
                  <CustomInputLabel htmlFor='name'>Document Type</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    fullWidth
                    id='name'
                    placeholder='Name'
                    type='text'
                    name='name'
                    onChange={onChange}
                    value={fields.name}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Tooltip title={fields?.file?.name}>
                            <span className={styles.fileName}>{fields?.file?.name}</span>
                          </Tooltip>
                          <IconButton size='small'>
                            <input
                              accept='application/pdf'
                              style={{ display: 'none' }}
                              id='file'
                              type='file'
                              name='file'
                              onChange={onChange}
                            />
                            <label htmlFor='file' className={`${styles.fs0} ${styles.pointer}`}>
                              <BrowseIcon />
                            </label>
                             
                          </IconButton> 
                        </InputAdornment>
                      )
                    }}
                    // error={errors.name}
                    // helperText={errors.name}
                  />
                </Grid>
              </Grid>
            </StyledCard>
          </DialogContent>
          <CustomDialogAction className={`${styles.fSpaceBtw}`}>
            <CustomButton variant='contained' type='reset' onClick={() => props.handleOpen(false)}> Close </CustomButton>
            <CustomButton variant='contained' type='submit'> Submit </CustomButton>
          </CustomDialogAction>
        </form>
      </Dialog>
    </div>
  );
};
export default AddReportDocModal;