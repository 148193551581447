import React from 'react';
import { Dialog, MenuItem, Checkbox, Stack, Grid, FormControl } from '@mui/material';
import styles from '../../../../../custom/style.module.css';
// import contractStyles from './contracts.module.css';
import { CustomButton, CustomTextField, CustomDialogTitle, CustomDialogAction, CustomInputLabel, StyledCard, InputProps, CustomSelect, CustomCheckbox, CustomDialogContent, SelectInputProps, CustomDatePicker } from '../../../../../custom/components';
import { CloseIconBtn, contractType } from 'custom/constants';
import dayjs from 'dayjs';


const EditActiveContract = (props) => {
  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={props.modal === 'active_contract' || props.modal === 'contract'} onClose={props.onClose} maxWidth={'sm'} fullWidth>
        <CustomDialogTitle id='form-dialog-title'>
          {props.modal === 'active_contract' ? 'Edit Active Contract' : 'Add Contract'}
          <CloseIconBtn onClick={props.onClose} />
        </CustomDialogTitle>
        <form className={styles.w100}>
          <CustomDialogContent>
            <StyledCard>
              <Stack direction='row'>
                <Grid container spacing={2} columnSpacing={4}>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <CustomInputLabel id='airline'>Airline Name</CustomInputLabel>
                    <FormControl fullWidth>
                      <CustomSelect
                        {...SelectInputProps}
                        fullWidth
                        id='airline'
                        labelId='airline'
                        name='airline'
                        value={props.contractFieldEdit.airline ? props.contractFieldEdit.airline.id : 'placeholder'}
                        onChange={props.onChangecontractFieldEdit}
                        error={props?.contractErrorsEdit?.airline}
                        classes={{ select: !props.contractFieldEdit.airline ? styles.colorPlaceholder : '' }}
                      >
                        <MenuItem value='placeholder' disabled>Select an Airline</MenuItem>
                        {props.airlines.map((airline, i) => (
                          <MenuItem key={i} value={airline.id} selected={props.contractFieldEdit.airline && props.contractFieldEdit.airline.id === airline.id}>
                            {airline.name}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <CustomInputLabel htmlFor='airline_code'>Airline Code</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      id='airline_code'
                      fullWidth
                      autoFocus
                      placeholder='Airline Code'
                      type='text'
                      name='airline_code'
                      value={props.contractFieldEdit.airline_code}
                      disabled
                      // onChange={props.onChangecontractFieldEdit}
                      error={props.contractErrorsEdit?.airline}
                    />
                  </Grid>
                  <Grid item xs={12} xl={12}>
                    <CustomInputLabel id='contract_type'>Department</CustomInputLabel>
                    <FormControl fullWidth>
                      <CustomSelect
                        {...SelectInputProps}
                        fullWidth
                        id='contract_type'
                        labelId='contract_type'
                        name='contract_type'
                        value={props.contractFieldEdit?.contract_type || 'placeholder'}
                        onChange={props.onChangecontractFieldEdit}
                        error={props.contractErrorsEdit?.contract_type}
                        classes={{ select: !props.contractFieldEdit.contract_type ? styles.colorPlaceholder : '' }}
                      >
                        <MenuItem value='placeholder' disabled>Select Department</MenuItem>
                        <MenuItem value={contractType.cargo_handling}>{contractType.cargo_handling}</MenuItem>
                        <MenuItem value={contractType.flight_ops}>{contractType.flight_ops}</MenuItem>
                        <MenuItem value={contractType.drayage}>{contractType.drayage}</MenuItem>
                      </CustomSelect>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <CustomCheckbox
                      control={
                        <Checkbox inputProps={{ 'aria-label': 'contract_minimum', }} 
                          checked={props.contractFieldEdit?.is_minimum} onChange={props.onChangecontractFieldEdit} 
                          name='is_minimum' size='small' />
                      }
                      className={`${styles.spaceNowrap} ${styles.mt3} ${styles.ml0}`}
                      labelPlacement='start'
                      label='Contract Minimum'

                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <CustomInputLabel htmlFor='minimum_rate'>Minimum rate</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      id='minimum_rate'
                      fullWidth
                      autoFocus
                      placeholder='Minimum Rate'
                      type='text'
                      name='minimum_rate'
                      disabled={props.contractFieldEdit.is_minimum ? false : true}
                      value={props.contractFieldEdit.minimum_rate}
                      onChange={props.onChangecontractFieldEdit}
                    />
                  </Grid>
                  <Grid item xs={12} xl={12}>
                    <CustomInputLabel htmlFor='mail'>Billing Email Id </CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      fullWidth
                      id='mail'
                      placeholder='EmailId'
                      type='mail'
                      name='mail'
                      inputProps={{
                        autoComplete: 'new-password',
                        form: { autocomplete: 'off', },
                      }}
                      value={props.contractFieldEdit?.mail}
                      onChange={props.onChangecontractFieldEdit}
                      // value={props.userFields.username ?? ''}
                      // error={props.userErrors.username}
                      // helperText={props.userErrors.username}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <CustomInputLabel htmlFor='' aria-label='Enter to date' className={`${styles.ipLabel}`}>Start Date</CustomInputLabel>
                    <CustomDatePicker
                      slotProps={{
                        textField: {
                          ...InputProps,
                          placeholder: 'Start Date',
                          fullWidth: true
                        },
                      }}
                      format='DD MMM YYYY'
                      name='start_date'
                      error={props.contractErrorsEdit?.start_date}
                      value={dayjs(props.contractFieldEdit?.start_date)}
                      onChange={(val) => props.onChangechangeDateAndTime(val, 'start_date')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <CustomInputLabel htmlFor='' aria-label='Enter to date' className={`${styles.ipLabel}`}>End Date</CustomInputLabel>
                    <CustomDatePicker
                      slotProps={{
                        textField: {
                          ...InputProps,
                          placeholder: 'End Date',
                          fullWidth: true
                        },
                      }}
                      format='DD MMM YYYY'
                      name='end_date'
                      error={props.contractErrorsEdit?.end_date}
                      value={dayjs(props.contractFieldEdit?.end_date)}
                      onChange={(val) => props.onChangechangeDateAndTime(val, 'end_date')}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </StyledCard>

          </CustomDialogContent>
          <CustomDialogAction className={`${styles.fSpaceBtw}`}>
            <CustomButton variant='contained' type='reset' onClick={props.onClose}> Close </CustomButton> 
            <CustomButton variant='contained' type='button' onClick={
              (e) => {
                props.modal === 'active_contract' ?
                  props.editContracts(e)
                  : props?.addContract(e);
              }
            }> {props.modal === 'active_contract' ? 'Update' : 'Submit'} </CustomButton> 
          </CustomDialogAction>
        </form>
      </Dialog>
    </div>
  );
};
export default EditActiveContract;