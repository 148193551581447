import React from 'react';
import { Divider, Grid, Popover, Stack, Typography } from '@mui/material';
import styles from '../../../custom/style.module.css';
import approvalStyles from './common.module.css';
import { PopoverHead, PopoverContent, CustomButton, InputProps, CustomTextField } from '../../../custom/components';
import CloseIcon from '@mui/icons-material/Close';
import { grey } from '@mui/material/colors';
import moment from 'moment';


const ReOpenModal = (props) => {
  const { anchorEl, modalflight, remark, handleClose } = props;
  return (
    <div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{ paper: { className: approvalStyles.popoverWidth, } }}
      >
        <PopoverHead>
          <Stack direction='column'>
            <Typography variant='subtitle1' component='span' fontWeight={'bold'} 
              // marginLeft={'18px'}
            >Reason for Reopen</Typography>
            {/* <Typography variant='caption' component='span' fontWeight={600} sx={{ textShadow: '0 0 #ffffff61' }}>
              <span className={`${approvalStyles.dot} ${approvalStyles.warning}`}></span>
              Finalized by System Admin</Typography> */}
          </Stack>
          <CloseIcon fontSize='small' className={styles.pointer} onClick={handleClose} />
        </PopoverHead>
        <PopoverContent className={styles.textCenter}>
          <Grid container spacing={2}>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <CustomTextField
                  {...InputProps}
                  id='remark'
                  placeholder='Add Reason (Optional)'
                  multiline
                  rows={4}
                  maxRows={Infinity}
                  name='remark'
                  fullWidth
                  inputProps={{ 'aria-label': 'remarks' }}
                  variant='outlined'
                  className={styles.p0}
                  value={remark}
                  onChange={(e) => props.setRemark(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomButton variant='contained' size='small' className={styles.minWidth150} onClick={()=>props.submitFunc()}>Submit</CustomButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider variant='middle' className={styles.mb2} />
              <Stack spacing={1}>
                {/* <Stack direction={'row'} spacing={2}>
                  <Typography variant='caption' component='span' color='primary' whiteSpace={'nowrap'} fontWeight={600} lineHeight='normal' marginBottom={'0.3rem'}>
                  Kevin Vaz 10JUN24 :
                  </Typography>
                  <Typography variant='caption' component='span' color={grey[800]} fontWeight={600} lineHeight='normal' marginBottom={'0.3rem'} textAlign={'left'}>
                    Are you sure you wish to remove the training expiry alert?</Typography>
                </Stack>

                <Stack direction={'row'} spacing={2}>
                  <Typography variant='caption' component='span' color='primary' whiteSpace={'nowrap'} fontWeight={600} lineHeight='normal' marginBottom={'0.3rem'}>
                  Kevin Vaz 10JUN24 :
                  </Typography>
                  <Typography variant='caption' component='span' color={grey[800]} fontWeight={600} lineHeight='normal' marginBottom={'0.3rem'} textAlign={'left'}>
                  Are you sure you wish to remove the training expiry alert?</Typography>
                </Stack> */}
                {
                  modalflight && modalflight?.log && modalflight?.log.length > 0 && modalflight?.log.map((log, index) => {
                    return (
                      <Stack direction={'row'} spacing={2} key={index}>
                        <Typography variant='caption' component='span' color='primary' whiteSpace={'nowrap'} fontWeight={600} lineHeight='normal' marginBottom={'0.3rem'}>
                          {log?.user?.first_name} {log?.user?.last_name} {moment(log?.date).format('DDMMMYY')} :
                        </Typography>
                        <Typography variant='caption' component='span' color={grey[800]} fontWeight={600} lineHeight='normal' marginBottom={'0.3rem'} textAlign={'left'}>
                          {log?.remark}</Typography>
                      </Stack>
                    );
                  })
                }
              </Stack>
            </Grid>
          </Grid>
        </PopoverContent>
      </Popover>
    </div>
  );
};
export default ReOpenModal;