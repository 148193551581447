import React from 'react';
import Airwaybill from './Airwaybill';
import { Box } from '@mui/material';
import AttachmentContainer from '../attachment/AttachmentContainer';
import http from 'utils/http';
import { dateToUTC, getLocatDate } from 'utils/converter';
import cookies from 'utils/cookies';
import { pagelist, pageHandler } from '../../../../../custom/constants';
import dayjs from 'dayjs';
class AirwaybillContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      modal: false,
      screeningModal: false,
      search: {
        from: getLocatDate('YYYY-MM-DD'),
        to: getLocatDate('YYYY-MM-DD'),
        awb: null,
      },
      awbs: [],
      filterData: [],
      docs: [],
      org: [],
      dest: [],
      forwarder: [],
      filter: {
        org: null,
        dest: null,
        forwarder: null
      },
    };
  }

  componentDidMount() {
    // if user does not have permission to view airwaybills, redirect to  next tab in which he has permission or else redirect home.
    const currentPath = window.location.pathname;
    let filteredpagelist = pagelist.filter((page) => page?.roles.includes(cookies.get('role')) && page?.title.includes('Doc Archive'));
    if (filteredpagelist.length > 0) {
      // check if the current path is in the filtered page list
      let index = filteredpagelist.findIndex((page) => page.link === currentPath);
      if (index === -1) {
        // if not found, redirect to the first page in the filtered page list
        window.location.replace(filteredpagelist[0].link);
      } else {
        this.fetchAwbs();
      }
    } else {
      pageHandler(currentPath, cookies.get('role'));
    }
  }

  handlePopover = (modal, anchorEl, docs) => {
    this.setState({
      modal,
      anchorEl,
      docs
    });
  };

  fetchAwbs = (awb = false) => {
    let query = '';
    if (awb) {
      query = 'awb=' + this.state.search.search;
    } else {
      const from = dayjs(this.state.search.from).format('YYYY-MM-DD');
      const to = dayjs(this.state.search.to).format('YYYY-MM-DD');
      query = `from=${dateToUTC(from + ' 00:00:00', 'YYYY-MM-DD HH:mm:ss')}&to=${dateToUTC(to + ' 23:59:59', 'YYYY-MM-DD HH:mm:ss')}`;
    }
    http.get('/archive/awb?' + query).then(({ data }) => {
      if (data.status == 'success') {
        this.setState({ awbs: data.data }, () => {
          this.getFilter();
          this.filterData();
          this.handlePopover(false);
        });
      }
    });
  };

  handleFilterChange = (event, type) => {
    let value = event.target ? event.target.value : event;
    // const name = event.target ? event.target.name : event;

    if (type === 'search') {
      value = value.replace(/\D/g, '');
      if (value.length > 3) {
        value = `${value.slice(0, 3)}-${value.slice(3)}`;
      }
    }

    this.setState((prevState) => ({
      search: {
        ...prevState.search,
        [type]: value
      }
    }));
  };

  getFilter = () => {
    let org = [];
    let dest = [];
    let forwarder = [];
    this.state.awbs.map(a => {
      if (a.org) {
        if (!org.includes(a.org)) {
          org.push(a.org);
        }
      }
      if (a.dest) {
        if (!dest.includes(a.dest)) {
          dest.push(a.dest);
        }
      }
      if (a.forwarder) {
        if (!forwarder.includes(a.forwarder)) {
          forwarder.push(a.forwarder);
        }
      }
    });
    this.setState({
      org,
      dest,
      forwarder
    });
  };

  filterData = (search = false) => {
    let awbs = [...this.state.awbs];
    if (this.state.filter.org && this.state.filter.org != '') {
      awbs = awbs.filter((a) => a.org === this.state.filter.org);
    }
    if (this.state.filter.dest && this.state.filter.dest != '') {
      awbs = awbs.filter((a) => a.dest === this.state.filter.dest);
    }
    if (this.state.filter.forwarder && this.state.filter.forwarder != '') {
      awbs = awbs.filter((a) => a.forwarder === this.state.filter.forwarder);
    }
    if (search && this.state.filter.awb && this.state.filter.awb != '') {
      awbs = awbs.filter((a) => {
        let code = '';
        let number = '';
        if (this.state.filter.awb.includes('-')) {
          code = this.state.filter.awb.split('-')[0];
          number = this.state.filter.awb.split('-')[1];
        } else {
          code = this.state.filter.awb.substring(0, 3);
          number = this.state.filter.awb.substring(3);
        }
        if (a.code == code && a.number == number) {
          return true;
        }

      });
    }
    this.setState({ filterData: awbs });
  };

  onChangefilter = (e) => {
    this.setState({
      filter: {
        ...this.state.filter,
        [e.target.name]: e.target.value
      }
    }, () => { e.target.name != 'awb' || (e.target.name == 'awb' && !e.target.value) ? this.filterData(e.target.name == 'awb' || e.target.name == 'awb_search') : {}; });
  };

  handlePopoverClose = () => {
    this.setState({
      attachment: false,
      anchorEl: false
    });
  };

  render() {
    return (
      <Box sx={{ position: 'relative' }}>
        <Airwaybill
          handlePopover={this.handlePopover}
          handlePopoverClose={this.handlePopoverClose}
          anchorEl={this.state.anchorEl}
          modal={this.state.modal}
          awbs={this.state.filterData}
          searchOptions={this.state.search}
          handleChange={this.handleFilterChange}
          search={this.fetchAwbs}
          org={this.state.org}
          dest={this.state.dest}
          forwarder={this.state.forwarder}
          onChangefilter={this.onChangefilter}
          filter={this.state.filter}
        />
        {this.state.modal === 'attached_docs' &&
          <AttachmentContainer
            handlePopover={this.handlePopover}
            anchorEl={this.anchorEl}
            modal={this.state.modal}
            docs={this.state.docs}
          />
        }
      </Box>
    );
  }
}

export default AirwaybillContainer;
