import React from 'react';
import { Divider, Dialog, DialogContent } from '@mui/material';
import styles from '../../../custom/style.module.css';
import { CustomButton, CustomDialogTitle, CustomDialogAction } from '../../../custom/components';
import { CloseIconBtn } from 'custom/constants';

const AwbModal = (props) => {
  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={props.modal} onClose={props.onClose}>
        <CustomDialogTitle id='form-dialog-title'>
          AWB Already Exists!
          <CloseIconBtn onClick={props.onClose} />
        </CustomDialogTitle>
        <Divider />
        <DialogContent sx={{ width: 600 }}>
            Do you wish to create a duplicate record of this airwaybill or add/edit the existing record?
        </DialogContent>
        <CustomDialogAction className={`${styles.fSpaceBtw}`}>
          <CustomButton variant='contained' onClick={props.attachAwb}>Add/Edit Existing</CustomButton>
          <CustomButton variant='contained' onClick={props.newAWB}> Create Duplicate</CustomButton>
        </CustomDialogAction>
      </Dialog>
    </div>
  );
};
export default AwbModal;