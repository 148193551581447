import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  headerContainer: { marginTop: 10 },
  billTo: {
    fontSize: 10,
    paddingBottom: 3,
    lineHeight: 'normal',
  },
  address: {
    whiteSpace: 'break-spaces',
    maxWidth: '150px' 
  }
});

const BillTo = ({ invoice }) => (
  <View style={styles.headerContainer}>
    {/* <Text style={styles.billTo}>̆Bill From</Text> */}
    <Text style={styles.billTo}>{invoice?.from_name}</Text>
    <Text style={[styles.billTo, styles.address]}>{invoice?.from_address}</Text>
    <Text style={styles.billTo}>{invoice?.from_contact}</Text>
    {/* <Text style={styles.billTo}>{invoice.email}</Text> */}
  </View>
);
  
export default BillTo;