import React, { Fragment } from 'react';
import { Stack, Grid, InputAdornment, IconButton, Box } from '@mui/material';
import { StyledCard, StyledCardTitle, CustomInputLabel, CustomDatePicker, CustomSearch, StyledDataGrid, DataGridProps, InputProps, } from '../../../../../custom/components';
import styles from '../../../../../custom/style.module.css';
import docStyles from './otherdoc.module.css';
import { AttachFile as AttachFileIcon, Search } from '@mui/icons-material';
import dayjs from 'dayjs';
import AttachmentContainer from '../attachment/AttachmentContainer';
import moment from 'moment';

const OtherDoc = (props) => {
  const columns = [
    {
      field: 'type',
      headerName: 'Type',
      type: 'text',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return row?.sub_type == 'AWB' ? 'Airwaybill' : row?.sub_type == 'FLIGHT' ? 'Flight' : 'Other';
      }
    },
    {
      field: 'name',
      headerName: 'Name',
      type: 'text',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'sub_type',
      headerName: 'Attached To',
      type: 'text',
      minWidth: 80,
      flex: 1,
      renderCell: ({ row }) => {
        return row?.sub_type == 'AWB' ? row?.awb?.code + row?.awb?.number : row?.sub_type == 'FLIGHT' ? row?.flight?.airline?.code + row?.flight?.flight_no + '/' + moment(row?.flight?.flight_date).format('DDMMMYY') : row?.data?.ref || row?.name;
      }
    },
    {
      field: 'action',
      headerName: 'Actions',
      align: 'center',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      width: 90,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
            <div onClick={() => props.handlePopover('attached_docs', row)}>
              <AttachFileIcon fontSize='small' className={`${styles.pointer} ${docStyles.attIcon}`} />
            </div>
          </Stack>
        );
      }
    },
  ];

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StyledCard>
            <Stack direction='row' justifyContent={'space-between'}>
              <StyledCardTitle gutterBottom variant='' component='div'>Filter Other Documents List</StyledCardTitle>
              <CustomSearch
                id='search'
                type='search'
                placeholder='AWB Number | Flight Number | Other'
                name='search'
                className={`${styles.brInput} ${styles.width245}`}
                value={props.searchOptions.search}
                onChange={(e) => props.handleChange(e, 'search')}
                InputProps={{
                  classes: { input: styles.pr0 },
                  endAdornment: <InputAdornment position='end'>
                    <IconButton
                      size='small'
                      aria-label='search'
                      edge='end'
                      onClick={props.search}
                    >
                      <Search fontSize='inherit' />
                    </IconButton>
                  </InputAdornment>,
                }}
                onKeyDown={
                  (e) => {
                    if (e.key === 'Enter') {
                      props.search(true);
                    }
                  }
                }
              />
            </Stack>
      
            <Stack direction='row'>
              <Grid container spacing={2} columnSpacing={4}>
                <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                  <CustomInputLabel htmlFor='' aria-label='Enter from date'>From</CustomInputLabel>
                  <CustomDatePicker
                    slotProps={{
                      textField: {
                        ...InputProps,
                        placeholder: 'From Date',
                        fullWidth: true
                      },
                    }}
                    className={styles.w100}
                    format='DD MMM YYYY'
                    name='from'
                    value={dayjs(props.searchOptions.from)}
                    // helperText={}
                    onChange={(e) => props.handleChange(e, 'from')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                  <CustomInputLabel htmlFor='' aria-label='Enter to date '>To</CustomInputLabel>
                  <CustomDatePicker
                    slotProps={{
                      textField: {
                        ...InputProps,
                        placeholder: 'To Date',
                        fullWidth: true
                      },
                    }}
                    className={styles.w100}
                    format='DD MMM YYYY'
                    name='to'
                    value={dayjs(props.searchOptions.to)}
                    // helperText={}
                    onChange={(e) => props.handleChange(e, 'to')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={8} className={styles.sqIconsContainer}>
                  <CustomInputLabel className={styles.colorTransparent}>-</CustomInputLabel>
                  <IconButton disableRipple onClick={() => props.search()} className={styles.p0}>
                    <Search fontSize='1.7rem' classes={{ root: `${styles.sqIcons} ${styles.mb0}` }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Stack>
          </StyledCard>
        </Grid>

        <Grid item xs={12}>
          <StyledCard className={styles.positionRelative}>
            <div className={`${styles.fSpaceBtw} ${styles.mb3}`}>
              <StyledCardTitle variant='' component='div' className={styles.pb0}>Other Documents</StyledCardTitle>
              {/* <CustomSearch
                id='search'
                name='awb'
                value={props.filter.awb}
                variant='outlined'
                size='small'
                placeholder='Enter Name'
                onChange={props.onChangefilter}
                type='search'
                className={styles.brInput}
                InputProps={{
                  endAdornment: <InputAdornment position='end'>
                    <IconButton
                      size='small'
                      aria-label='search'
                      edge='end'
                      onClick={() => props.onChangefilter({
                        target: {
                          name: 'awb_search',
                          value: ''
                        }
                      })}
                    >
                      <Search fontSize='inherit' />
                    </IconButton>
                  </InputAdornment>,
                }}
                InputLabelProps={{ shrink: false }}
              /> */}
            </div>
            <div className={styles.tableWrapper}>
              <Box className={`${styles.tableOuter} ${docStyles.docTableOuter}`}>
                <StyledDataGrid
                  {...DataGridProps}
                  rows={props.data}
                  columns={columns}
                  param={props.data?.length}
                />
              </Box>
            </div>
          </StyledCard>
        </Grid>

      </Grid>
      {props.modal === 'attached_docs' &&
        <AttachmentContainer
          handlePopover={props.handlePopover}
          modal={props.modal}
          type={'OTHER'}
          docs={props.docs}
        />}
    </>
  );
};

export default OtherDoc;
