import React from 'react';
import { Dialog } from '@mui/material';
import styles from 'custom/style.module.css';
import { CustomButton, CustomDialogTitle, CustomDialogAction, CustomDialogContent } from 'custom/components';
import { CloseIconBtn } from 'custom/constants';
import http from 'utils/http';

const MessageModal = (props) => {
  const PrettyPrintJson = ({ data }) => (<div><pre>{JSON.stringify(data, null, 2) }</pre></div>);
  const PrettyPritText = ({ data }) => (<div><pre>{data}</pre></div>);
  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={props.modal === 'msg'} onClose={props.onClose}>
        <CustomDialogTitle id='form-dialog-title'>
          Message
          <CloseIconBtn onClick={props.onClose} />
        </CustomDialogTitle>
        <CustomDialogContent sx={{ width: 600 }}>
          {props.modal_data && props.modal_data?.content =='JSON' && PrettyPrintJson({ data: props.modal_data?.msg || {} })}
          {props.modal_data && props.modal_data?.content =='TEXT' && PrettyPritText({ data: props.modal_data?.msg || '' })}
        </CustomDialogContent>
        <CustomDialogAction className={`${styles.fSpaceBtw}`}>
          <CustomButton variant='contained' onClick={props.onClose}>Close</CustomButton>
          <CustomButton variant='contained' type='submit' onClick={() => {
            http.get('/replay/' + props.modal_data.id).then(() => {
              props.onClose();
            });
          }}>Select</CustomButton>
        </CustomDialogAction>
      </Dialog>
    </div>
  );
};
export default MessageModal;