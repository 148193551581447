import React from 'react';
import { Dialog, DialogContentText, DialogContent, Typography, DialogTitle, Divider } from '@mui/material';
import styles from '../../../custom/style.module.css';
import { CustomButton, CustomDialogAction } from '../../../custom/components';
import { CloseIconBtn } from 'custom/constants';

const StatusModal = (props) => {
  return (
    <>
      <Dialog
        open={props.statusModal}
        onClose={props.onClose}
        aria-labelledby='Success'
        aria-describedby='Success'
        maxWidth='xs'
        fullWidth={true}
      >
        <DialogTitle id='form-dialog-title'>
          <Typography variant='h6' pb={1}>{props.statusMessage}</Typography>
          <CloseIconBtn 
            onClick={props.onClose} 
          />
          <Divider direction='horizontal' className={`${styles.w100}`} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant='body2'>{`Flight report data ${props.statusMessage && props.statusMessage.toLowerCase()}.`}</Typography>
          </DialogContentText>
        </DialogContent>
        <CustomDialogAction>
          <CustomButton 
            onClick={props.onClose} 
            variant='contained'>
            <Typography variant='body2' lineHeight='normal' fontWeight='bold'>Okay</Typography>
          </CustomButton>
        </CustomDialogAction>
      </Dialog>
    </>
  );
};

export default StatusModal;