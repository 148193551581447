import React from 'react';
import { Box, Checkbox, IconButton, Stack, TableBody, TableHead, TableRow, TableCell, MenuItem, Grid, FormControl } from '@mui/material';
import { StyledCard, StyledCardTitle, CustomDatePicker, InputProps, CustomButton, StyledTable, StyledTableRow, CustomSelectRounded, CustomInputLabel, SelectInputProps } from '../../../../../custom/components';
import approvalStyles from '../../approval.module.css';
import styles from '../../../../../custom/style.module.css';
import moment from 'moment';
import dayjs from 'dayjs';
import { BinoIcon } from 'custom/constants';
import { OfflinePin } from '@mui/icons-material';
import ReviewModal from '../../../common/ReviewModal';
import FlightReportModal from '../../../common/FlightReportModal';
import cookies from 'utils/cookies';

const STACKFILTER = {
  direction:{
    xs: 'column',
    lg: 'row',
  }, 
  align:{
    xs: 'flex-start',
    lg: 'center',
  }
};

const getFlightNo = (item) => {
  return `${item.flight.airline.code}${item.flight.flight_no}/${moment(item.flight.flight_date).format('DD MMM YY')}`;
};

function formatNumber(num) {
  return num % 1 === 0 ? Math.round(num) : num.toFixed(1);
}

const FlightReport = (props) => {
  return (
    <div>
      <div 
        className={`${styles.wAuto} ${approvalStyles.headerWraper}`}>
        <StyledCard className={`${approvalStyles.filterCard}`}>
          <StyledCardTitle gutterBottom className={`${approvalStyles.filterTitle}`}>Flight Info</StyledCardTitle>
          <Grid container columnSpacing={{
            xs: 4,
            lg: 2,
            xl: '2%'
          }} rowSpacing={{
            xs: 2,
            lg: 0,
          }} justifyContent={{
            xs: 'flex-start',
            xl: 'center',
          }}>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Stack direction={{
                xs: 'column',
                lg: 'row',
              }}
              align={{
                xs: 'flex-start',
                lg: 'center',
              }}>
                <CustomInputLabel id='airline-label' aria-label='Enter-airline' className={styles.ipLabel}>Airline</CustomInputLabel>
                <FormControl variant='standard' fullWidth>
                  <CustomSelectRounded
                    {...SelectInputProps}
                    id='airline'
                    labelId='airline-label'
                    fullWidth
                    autoFocus
                    name='airline'
                    className={`${styles.brInput}`}
                    onChange={props.handleAirlineChange}
                    value={!props?.airline ? 'placeholder' : props?.airline}
                  >
                    <MenuItem value='placeholder' disabled>Select an airline</MenuItem>
                    {props.airlines.map((al, i) => (
                      <MenuItem key={i} value={al.id}>
                        {al.code}
                      </MenuItem>
                    ))}
                  </CustomSelectRounded>
                </FormControl>
              </Stack>
            </Grid>
            {props.loadInfo !== 'discrepancy' ? 
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                <Stack {...STACKFILTER}>
                  <CustomInputLabel id='contract_label' aria-label='Enter-contract' className={styles.ipLabel}>Department</CustomInputLabel>
                  <FormControl variant='standard' fullWidth>
                    <CustomSelectRounded
                      {...SelectInputProps}
                      id='airline'
                      labelId='contract-label'
                      fullWidth
                      autoFocus
                      className={`${styles.brInput}`}
                      onChange={props.handleContractType}
                      value={props.contract_Type} 
                    >
                      <MenuItem value='placeholder' disabled>Select a Department</MenuItem>
                      {cookies.get('contract_types')?.map((type, index) => (
                        <MenuItem key={index} value={type}>{type}</MenuItem>
                      ))}
                    </CustomSelectRounded>
                  </FormControl>
                </Stack>
              </Grid> : ''
            }

            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <Stack {...STACKFILTER}>
                <CustomInputLabel htmlFor='' aria-label='Enter month' className={`${styles.ipLabel}`}>Month</CustomInputLabel>
                <CustomDatePicker
                  slotProps={{
                    textField: {
                      ...InputProps,
                      placeholder: 'Enter Month',
                      fullWidth: true
                    },
                  }}
                  views={['month', 'year']}
                  className={`${styles.brInput} ${styles.w100} ${styles.minWidth125} ${styles.fOne}`}
                  openTo='month'
                  onChange={e =>
                    props.onChangeSearch({
                      target: {
                        name: 'month',
                        value: moment(e.$d).format('YYYY-MM-DD')
                      }
                    })
                  }
                  value={ props.search.from ? dayjs(props.search.from) : null }
                />
              </Stack>
            </Grid>
            {
              cookies.get('role') === 'System Admin' &&
                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                  <Stack {...STACKFILTER}>
                    <CustomInputLabel htmlFor='' aria-label='Enter period' className={`${styles.ipLabel}`}>Role</CustomInputLabel>
                    <FormControl variant='standard' fullWidth>
                      <CustomSelectRounded
                        {...SelectInputProps}
                        id='role'
                        labelId='role-label'
                        fullWidth
                        name = 'role'
                        value = {props.role}
                        onChange = {props.handleRoleChange}
                      >
                        <MenuItem value='placeholder' disabled>Select Role</MenuItem>
                        <MenuItem value='accountant'>Filter by Accountant</MenuItem>
                        <MenuItem value='air_rep'>Filter by Airline Rep</MenuItem>
                      </CustomSelectRounded>
                    </FormControl>
                  </Stack>
                </Grid>
            }
          </Grid>
        </StyledCard>
      </div>

      <StyledCard className={styles.mb4}>
        <Box className={`${styles.fSpaceBtw} ${styles.mb2}`} >
          <StyledCardTitle>Flight Report</StyledCardTitle>
        </Box>
        <div className={styles.tableWrapper}>
          <Box className={`${styles.tableOuter} ${approvalStyles.fltReportTableOuter}`}>
            <StyledTable aria-label='simple table'>
              <TableHead>
                <StyledTableRow>
                  <TableCell colSpan={4} />
                  <TableCell align='center' colSpan={4} className={styles.nestedhead}>LOOSE</TableCell>
                  <TableCell align='center' colSpan={4} className={styles.nestedhead}>INTACT</TableCell>
                  <TableCell align='center' colSpan={4} className={styles.nestedhead}>TOTAL</TableCell>
                  <TableCell></TableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <TableCell></TableCell>
                  <TableCell><Checkbox
                    onClick={(e) => props.selectAll(e.target.checked)}
                    inputProps={{ 'aria-label': 'Select' }}
                    sx={{
                      '& .MuiSvgIcon-root': { fontSize: 18 },
                      color: 'white',
                      '&.Mui-checked': { color: 'white', }, 
                    }}
                    classes={{ root: styles.p0 }}
                    name='approval'
                  /></TableCell>
                  <TableCell>Flight #</TableCell>
                  <TableCell>Org-Dest</TableCell>
                  <TableCell align='center'>AWB</TableCell>
                  <TableCell align='center'>PCS</TableCell>
                  <TableCell align='center'>WGT</TableCell>
                  <TableCell align='center'>ULDs</TableCell>

                  <TableCell align='center'>AWB</TableCell>
                  <TableCell align='center'>PCS</TableCell>
                  <TableCell align='center'>WGT</TableCell>
                  <TableCell align='center'>ULDs</TableCell>

                  <TableCell align='center'>AWB</TableCell>
                  <TableCell align='center'>PCS</TableCell>
                  <TableCell align='center'>WGT</TableCell>
                  <TableCell align='center'>ULDs</TableCell>
              
                  <TableCell align='center'>Action</TableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {
                  props.list.map((item, index) => (
                    <TableRow key={index} >
                      <TableCell align='center'> <span className={`${styles.dotLarge} ${styles.pending}`}></span></TableCell>
                      {/* <TableCell align='center'> <span className={`${styles.dotLarge} ${styles.approved}`}></span></TableCell>  >> for green dot
                        <TableCell align='center'> <span className={`${styles.dotLarge} ${styles.rejected}`}></span></TableCell>  >> for red dot */}
                      <TableCell align='center'>
                        <Checkbox
                          onClick={(e) => props.selectFlights(item.id, e.target.checked)}
                          inputProps={{ 'aria-label': 'Select' }}
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}
                          classes={{ root: styles.p0 }}
                          name='approval'
                          checked={props.ids.includes(item.id)}
                        />
                      </TableCell>
                      <TableCell>{getFlightNo(item)}</TableCell>
                      <TableCell>{`${item?.flight?.org} - ${item?.flight?.dest}`}</TableCell>
                      <TableCell align='center'>{item?.loose_awb}</TableCell>
                      <TableCell align='center'>{item?.loose_pcs}</TableCell>
                      <TableCell align='center'>{formatNumber(item?.loose_weight)}</TableCell>
                      <TableCell align='center'>
                        <Stack direction={'row'} spacing={3} justifyContent={'center'}>
                          <span>LD3: {item?.loose_LD3_count}</span>
                          <span>LD7: {item?.loose_LD7_count}</span>
                          <span>MD: {item?.loose_MD_count}</span>
                        </Stack>
                      </TableCell>

                      <TableCell align='center'>{item?.intact_awb}</TableCell>
                      <TableCell align='center'>{item?.intact_pcs}</TableCell>
                      <TableCell align='center'>{formatNumber(item?.intact_weight)}</TableCell>
                      <TableCell align='center'>
                        <Stack direction={'row'} spacing={3} justifyContent={'center'}>
                          <span>LD3: {item?.intact_LD3_count}</span>
                          <span>LD7: {item?.intact_LD7_count}</span>
                          <span>MD: {item?.intact_MD_count}</span>
                        </Stack>
                      </TableCell>
                      <TableCell align='center'>{item?.total_awb}</TableCell>
                      <TableCell align='center'>{item?.total_pcs}</TableCell>
                      <TableCell align='center'>{formatNumber(item?.total_weight)}</TableCell>
                      <TableCell align='center'>
                        <Stack direction={'row'} spacing={3} justifyContent={'center'}>
                          <span>LD3: {item?.total_LD3_count}</span>
                          <span>LD7: {item?.total_LD7_count}</span>
                          <span>MD: {item?.total_MD_count}</span>
                        </Stack>
                      </TableCell>

                      <TableCell align='center'>
                        <Stack direction={'row'} spacing={1} alignItems={'center'} justifyContent={'center'}>
                          <div className={styles.actionIcons}>
                            <BinoIcon title='View Flight Report' 
                            // className={`${styles.smActionIcons} ${flightInfoStyles.reportIcon}`}
                              onClick={() => props.handleFlight(item)} />
                          </div>
                          <IconButton onClick={(e) => props.handleReview(e.currentTarget, item )} className={`${styles.p0} ${styles.iconMid}`}> 
                            <OfflinePin color='primary' fontSize='20px' />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </StyledTable>
          </Box>
        </div>
      </StyledCard>

      <div>
        <CustomButton variant='contained' className={`${styles.mlauto} ${styles.dFlex} ${styles.w100} ${styles.maxWidth200}`} 
          name='approve'
          onClick={(e) => props.handleReview(e.currentTarget, null)}
        >Bulk Review</CustomButton> 
      </div>

      <ReviewModal anchorEl={props.anchorEl} handleReview={props.handleReview} 
        modalflight={props.modalflight} 
        submitFunc={props.UpdateFlightReport} 
        remark={props.remark} 
        setRemark={props.setRemark}
        selectedOption={props.selectedOption}
        selectOption={props.selectOption}
        handleClose={props.handleReviewClose}
      />

      <FlightReportModal 
        showModal={props.modal} closeModal={props.onClose} iframeRef={props.iframeRef}
        handleModalOpen={props.handleModalOpen}
        modalOpen={props.modalOpen}
        handleModalClose={props.handleModalClose}
        flight={props.flightReportData}
        ctype={props.ctype}
      />
    </div>
  );
};

export default FlightReport;
