import React from 'react';
import { Stack, Grid, InputAdornment, IconButton, Box, MenuItem, FormControl } from '@mui/material';
import { StyledCard, StyledCardTitle, CustomInputLabel, CustomDatePicker, CustomSearch, StyledDataGrid, DataGridProps, InputProps, SelectInputProps, CustomSelect } from '../../../../../custom/components';
import styles from '../../../../../custom/style.module.css';
import awbStyles from './airwaybill.module.css';
import { AttachFile as AttachFileIcon, Search } from '@mui/icons-material';
import dayjs from 'dayjs';

const Airwaybill = (props) => {
  const columns = [
    {
      field: 'awb',
      headerName: 'Airwaybill',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return row?.code + '-' + row?.number;
      }
    },
    {
      field: 'pcs',
      headerName: 'Pcs',
      type: 'text',
      minWidth: 80,
      flex: 1,
    },
    {
      field: 'wgt',
      headerName: 'Wgt',
      type: 'text',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'org',
      headerName: 'Org',
      type: 'text',
      minWidth: 60,
      flex: 1,
    },
    {
      field: 'dest',
      headerName: 'Dest',
      type: 'text',
      minWidth: 60,
      flex: 1,
    },
    {
      field: 'forwarder',
      headerName: 'Forwarder',
      type: 'text',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Actions',
      align: 'center',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      width: 90,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
            <div onClick={() => props.handlePopover('attached_docs', true, row.docs)}>
              <AttachFileIcon fontSize='small' className={`${styles.pointer} ${awbStyles.attIcon}`} />
            </div>
          </Stack>
        );
      }
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <StyledCard>
          <Stack direction='row' justifyContent={'space-between'}>
            <StyledCardTitle gutterBottom variant='' component='div'>Filter Airwaybill List</StyledCardTitle>
            <CustomSearch
              id='search'
              variant='outlined'
              size='small'  
              type='search'
              placeholder='Enter AWB Number'
              name='awb'
              className={`${styles.brInput} ${styles.width245}`}
              value={props.searchOptions.search}
              onChange={(e)=>props.handleChange(e, 'search')}
              InputProps={{
                endAdornment: <InputAdornment position='end'>
                  <IconButton
                    size='small'
                    aria-label='search'
                    edge='end'
                    onClick={props.search}
                  >
                    <Search fontSize='inherit' />
                  </IconButton>
                </InputAdornment>,
              }}
              onKeyDown={
                (e) => {
                  if (e.key === 'Enter') {
                    props.search(true);
                  }
                }
              }
            />
          </Stack>
          <Stack direction='row' >
            <Grid container spacing={2} columnSpacing={4}>
              <Grid item xs={12} sm={6} md>
                <CustomInputLabel htmlFor='' aria-label='Enter From Date'>From</CustomInputLabel>
                <CustomDatePicker
                  slotProps={{
                    textField: {
                      ...InputProps,
                      placeholder: 'From Date',
                      fullWidth: true
                    },
                  }}
                  className={styles.w100}
                  format='DD MMM YYYY'
                  name='from'
                  value={dayjs(props.searchOptions.from)}
                  // helperText={}
                  onChange={(e) => props.handleChange(e, 'from')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md>
                <CustomInputLabel htmlFor='' aria-label='Enter To Date'>To</CustomInputLabel>
                <CustomDatePicker
                  slotProps={{
                    textField: {
                      ...InputProps,
                      placeholder: 'To Date',
                      fullWidth: true
                    },
                  }}
                  className={styles.w100}
                  format='DD MMM YYYY'
                  name='to'
                  value={dayjs(props.searchOptions.to)}
                  // helperText={}
                  onChange={(e) => props.handleChange(e, 'to')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md>
                <CustomInputLabel aria-label='Enter Org' id='org-label'>Org</CustomInputLabel>
                <FormControl fullWidth>
                  <CustomSelect
                    {...SelectInputProps}
                    fullWidth
                    id='org'
                    labelId='org-label'
                    name='org'
                    value={props.filter.org}
                    onChange={props.onChangefilter}
                  >
                    <MenuItem value=''>All</MenuItem>
                    {props.org && props.org.map(a => {
                      return (<MenuItem key={a} value={a} >{a}</MenuItem>);
                    })}
                  </CustomSelect>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md>
                <CustomInputLabel aria-label='Enter Dest' id='dest-label'>Dest</CustomInputLabel>
                <FormControl fullWidth>
                  <CustomSelect
                    {...SelectInputProps}
                    fullWidth
                    id='dest'
                    labelId='dest-label'
                    name='dest'
                    value={props.filter.dest}
                    onChange={props.onChangefilter}
                  >
                    <MenuItem value=''>All</MenuItem>
                    {props.dest && props.dest.map(f => {
                      return (<MenuItem key={f} value={f}>{f}</MenuItem>);
                    })}
                  </CustomSelect>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={1} lg={4} className={styles.sqIconsContainer}>
                <CustomInputLabel className={styles.colorTransparent}>-</CustomInputLabel>
                <IconButton disableRipple onClick={() => props.search(false)} className={styles.p0}>
                  <Search fontSize='inherit' classes={{ root: `${styles.sqIcons} ${styles.mb0}` }} />
                </IconButton>
              </Grid>
            </Grid>
          </Stack>
        </StyledCard>
      </Grid>

      <Grid item xs={12}>
        <StyledCard className={styles.positionRelative}>
          <div className={`${styles.fSpaceBtw} ${styles.mb3}`}>
            <StyledCardTitle gutterBottom variant='' component='div' className={styles.pb0}>Airwaybills</StyledCardTitle>
            {/* <CustomSearch
              id='search'
              name='awb'
              value={props.filter.awb}
              variant='outlined'
              size='small'
              placeholder='Enter Awb Number'
              onChange={props.onChangefilter}
              type='search'
              className={styles.brInput}
              InputProps={{
                endAdornment: <InputAdornment position='end'>
                  <IconButton
                    size='small'
                    aria-label='search'
                    edge='end'
                    onClick={() => props.onChangefilter({
                      target: {
                        name: 'awb_search',
                        value: ''
                      }
                    })}
                  >
                    <Search fontSize='inherit' />
                  </IconButton>
                </InputAdornment>,
              }}
              InputLabelProps={{ shrink: false }}
            /> */}
          </div>
          <div className={styles.tableWrapper}>
            <Box className={`${styles.tableOuter} ${awbStyles.awbTableOuter}`}>
              <StyledDataGrid
                {...DataGridProps}
                rows={props.awbs}
                columns={columns}
                param={props.awbs?.length}
              />
            </Box>
          </div>
        </StyledCard>
      </Grid>

    </Grid>
  );
};

export default Airwaybill;
