import React from 'react';
import Manifest from './Manifest';
import http from 'utils/http';
import { connect } from 'react-redux';
import cookies from 'utils/cookies';

class ManifestContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: null,
      modal_data: {},
      ffm: {},
      data: [],
      productsList: [],
      uld_remarks: [],
      awb_value: null,
      product: 'ALL',
      nil: false,
      doc: false,
      edit: true,
      anchorEl: null,
      message_logs: [],
      msg_id: null,
      no_scan_awbs: [],
      is_empty: false,
      add_uld: false,
      uldFields: {},
      add_awb: false,
      awbFields: {}
    };
  }

  componentDidMount() {
    if (this.props.ffm && this.props.ffm?.id) {
      this.setState({
        ffm: this.props.ffm,
        is_empty: false
      }, () => {
        this.fetchProducts();
        this.filterData();
        this.fetchAirlines();
        // this.getConfig();
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.ffm != this.props.ffm) {
      this.setState({
        ffm: this.props.ffm,
        is_empty: false
      }, () => {
        if (this.state.productsList.length == 0) {
          this.fetchProducts();
        }
        this.filterData();
      });
    }
  }

  getConfig = () => {
    http.get('/settings?key=ffm_edit').then(({ data }) => {
      if (data.data.length > 0) {
        let edit = false;
        if (data.data[0].value && data.data[0].value.data === 'true') {
          edit = true;
        }
        this.setState({ edit });
      }
    });
  };

  fetchAirlines = () => {
    http.get(`/airline?active=${true}`)
      .then((response) => {
        this.setState({ airlineList: response.data.data });
      });
  };

  fetchProducts = () => {
    if (this.props.ffm?.airlineId)
      http.get(`/products?airlineId=${this.props.ffm?.airlineId}&common=${true}`).then(({ data }) => {
        this.setState({ productsList: data.data }, () => {
          if (this.props.printFunction) {
            this.props.printFunction(this.state.productsList, 'products');
          }
        });
      });
  };

  handleHistory = (e) => {
    this.setState({ anchorEl: e.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  onRefresh = () => {
    http.get(`/manifest/map/${this.props.ffm.id}`);
  };


  handleOpen = (modal, modal_data = {}) => {
    this.setState({
      modal,
      modal_data
    });
  };

  onClose = () => {
    this.setState({
      modal: false,
      modal_data: {}
    });
  };

  onChange = (e, uldid, awbid) => {
    let value = e.target ? e.target.value : e;
    const name = e.target.name;
    if (name === 'productsId') {
      http.put(`/manifest/awb/${awbid}`, { productsId: value });
    } else if (name === 'uld_type') {
      http.put(`/manifest/uld/${uldid}`, { uld_type: value });
    } else if (name === 'type') {
      let obj = { type: value };
      if (value === 'Loose') {
        obj = {
          type: value,
          uld_type: null
        };
      }
      http.put(`/manifest/uld/${uldid}`, obj);
    } else if (name === 'remarks') {
      let uld_remarks = [...this.state.uld_remarks];
      uld_remarks[uldid] = value;
      this.setState({ uld_remarks });
    } else if (name === 'sub_remark') {
      let uld_remarks = [...this.state.uld_remarks];
      http.put(`/manifest/uld/${uldid}`, { remark: uld_remarks[uldid] || null });
    } else if (name == 'awb') {
      this.setState({ awb_value: value });
    } else if (name == 'sub_awb') {
      http.put(`/manifest/awb/${awbid}`, { awbId: this.state.awb_value || null });
    } else if (name == 'org') {
      if (uldid != value)
        http.put(`/manifest/awb/${awbid}`, { org: value?.toUpperCase() });
    } else if (name == 'dest') {
      if (uldid != value)
        http.put(`/manifest/awb/${awbid}`, { dest: value?.toUpperCase() });
    } else if (name == 'pcs') {
      if (uldid != value)
        http.put(`/manifest/awb/${awbid}`, { pcs: value });
    } else if (name == 'wgt') {
      if (uldid != value)
        http.put(`/manifest/awb/${awbid}`, { wgt: value });
    } else if (name == 'spl_code') {
      if (uldid != value)
        http.put(`/manifest/awb/${awbid}`, { spl_code: value?.toUpperCase() });
    } else if (name == 'awb_no') {
      if (uldid != value)
        http.put(`/manifest/awb/${awbid}`, { awb_no: value?.toUpperCase() });
    } else if (name == 'awb_remark') {
      if (uldid != value)
        http.put(`/manifest/awb/${awbid}`, { remark: value });
    }

  };


  handleFilter = (value) => {
    this.setState({
      product: value,
      is_empty: false
    }, () => {
      this.filterData();
    }
    );
  };

  filterData = () => {
    const stn = cookies.get('station_code');
    let filteredData = [];
    this.state.ffm && this.state.ffm?.flight_uld?.map(uld => {
      let filter_awb = [];
      uld.flight_awbs?.filter(awb => {
        if (awb.product?.name == this.state.product || this.state.product == 'ALL') {
          filter_awb.push(awb);
        }
      });
      // if (filter_awb.length > 0) {
      let uld_data = { ...uld };
      uld_data.flight_awbs = filter_awb;
      filteredData.push(uld_data);
      // }
    });
    //map through filtered and check if  awb's org, dest, pcs, wgt is null then set is_empty to true
    filteredData.map(uld => {
      uld.flight_awbs.map(awb => {
        if (!awb.org || !awb.dest || !awb.pcs || !awb.wgt) {
          this.setState({ is_empty: true });
        }
      });
    });
    this.setState({
      data: filteredData,
      doc: this.state.ffm?.org == stn ? this.state.ffm?.out_ffm : this.state.ffm?.in_ffm,
      message_logs: this.state.ffm?.message_logs || [],
      msg_id: this.state.ffm?.msg_id || null,
      nil: this.state.ffm?.org == stn ? this.state.ffm?.out_nil_ffm : this.state.ffm?.in_nil_ffm
    }, () => {
      this.awbCount();
      if (this.props.printFunction) {
        this.props.printFunction(this.state.data, 'manifest');
      }
    });
  };

  awbCount = () => {
    let awb_no = [];
    console.log(this.state.data, 'data');
    this.state.data.map(uld => {
      uld.flight_awbs.map(awb => {
        console.log(awb, 'awbId');
        if (!awb.awbId) {
          if (awb_no && !awb_no.includes(awb.awb_no)) {
            awb_no.push(awb.awb_no);
          }
        }
      });
    });
    this.setState({ no_scan_awbs: awb_no });
  };

  onChangeNil = (e) => {
    const checked = e.target.checked;
    http.put(`/flight/nil/${this.state.ffm.id}`, { nil: checked }).then(() => {
      this.setState({ nil: checked });
    });
  };

  onChangeUld = (e) => {
    let uldFields = { ...this.state.uldFields };
    uldFields[e.target.name] = e.target.value;
    if (e.target.name == 'uld_no') {
      uldFields.uld_no = e.target.value.toUpperCase();
    }
    if (e.target.name == 'c_type') {
      uldFields.uld_no = '';
    }
    this.setState({ uldFields });
  };

  handleUld = (v) => {
    let uld_fields = {
      c_type: 'ULD',
      uld_no: '',
      type: 'Loose',
      uld_type: '',
      remarks: ''
    };
    this.setState({
      add_uld: v,
      uldFields: uld_fields
    });
  };

  onAddUld = () => {
    const { uld_no, c_type, type, uld_type, remark } = this.state.uldFields;
    http.post(`/manifest/uld/${this.state.ffm.id}`, {
      c_type,
      uld_no,
      type,
      uld_type,
      remark
    }).then(() => {
      this.setState({
        add_uld: false,
        uldFields: {}
      });
    });
  };

  deleteUld = (id) => {
    http.delete(`/manifest/uld/${id}`);
  };

  deleteAwb = (id) => {
    http.delete(`/manifest/awb/${id}`);
  };


  onChangeAwb = (e) => {
    let awbFields = { ...this.state.awbFields };
    awbFields[e.target.name] = e.target.value;
    if (e.target.name == 'awb_no') {
      let value = e.target.value.replace(/\D/g, '');
      if (value.length > 3) {
        value = `${value.slice(0, 3)}-${value.slice(3)}`;
      }
      awbFields.awb_no = value;
    }
    this.setState({ awbFields });
  };

  handleAwb = (v) => {
    let awb_fields = {
      awb_no: '',
      org: '',
      dest: '',
      pcs: '',
      wgt: '',
      spl_code: '',
      productsId: 0
    };
    this.setState({
      add_awb: v,
      awbFields: awb_fields
    });
  };

  onAddAwb = () => {
    const { awb_no, org, dest, pcs, wgt, spl_code, remark } = this.state.awbFields;
    http.post(`/manifest/awb/${this.state.ffm.id}`, {
      flight_uldId: this.state.add_awb,
      awb_no,
      org,
      dest,
      pcs,
      wgt,
      spl_code,
      remark
    }).then(() => {
      this.setState({
        add_awb: false,
        awbFields: {}
      });
    });
  };


  render() {
    return (
      <>
        <Manifest
          edit={this.state.edit}
          doc={this.state.doc}
          msg_id={this.state.msg_id}
          message_logs={this.state.message_logs}
          nil={this.state.nil}
          onChangeUld={this.onChangeUld}
          uldFields={this.state.uldFields}
          handleUld={this.handleUld}
          deleteUld={this.deleteUld}
          deleteAwb={this.deleteAwb}
          add_uld={this.state.add_uld}
          add_awb={this.state.add_awb}
          onAddUld={this.onAddUld}
          onAddAwb={this.onAddAwb}
          onChangeAwb={this.onChangeAwb}
          awbFields={this.state.awbFields}
          handleAwb={this.handleAwb}
          onChangeNil={this.onChangeNil}
          handleHistory={this.handleHistory}
          handleClose={this.handleClose}
          anchorEl={this.state.anchorEl}
          handleFilter={this.handleFilter}
          handleOpen={this.handleOpen}
          onClose={this.onClose}
          modal={this.state.modal}
          modal_data={this.state.modal_data}
          productsList={this.state.productsList}
          uld_remarks={this.state.uld_remarks}
          awb_value={this.state.awb_value}
          manifestData={this.state.data}
          ffm={this.state.ffm}
          airlineList={this.state.airlineList}
          onChange={this.onChange}
          onRefresh={this.onRefresh}
          showReportModal={this.props.showReportModal}
          noScanAwbs={this.state.no_scan_awbs}
          is_empty={this.state.is_empty}
          nil_manifest={this.props.nil}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { ffm: state.ffm };
};

export default connect(mapStateToProps, null)(ManifestContainer);
