import React from 'react';
import { Dialog, Typography } from '@mui/material';
import styles from '../../../../../custom/style.module.css';
import { CustomButton, CustomTextField, CustomDialogTitle, CustomDialogAction, CustomInputLabel, StyledCard, InputProps, CustomDialogContent } from '../../../../../custom/components';
import { CloseIconBtn } from 'custom/constants';

const DeactiveModal = (props) => {
  const { action, active, deactive } = props;
  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={props.modal === 'deactive_user'} onClose={props.onClose}>
        <CustomDialogTitle id='form-dialog-title'>
          {action == 'active' ? 'Reactivate' : 'Deactivate'} User
          <CloseIconBtn onClick={props.onClose} />
        </CustomDialogTitle>
        <form className={styles.w100} onSubmit={action == 'active' ? active : deactive} >
          <CustomDialogContent sx={{ width: 600 }}>
            <StyledCard>
              <div>
                <CustomInputLabel htmlFor='deactivate' className={styles.pl0}>Deactivate reason</CustomInputLabel>
                {action == 'active' && (
                  <Typography variant='body2' component='div'lineHeight='normal' marginBottom={0} marginTop={1}>
                    {props.userFields.reason}
                  </Typography>
                )}
                {action == 'deactive' && (<CustomTextField
                  {...InputProps}
                  fullWidth
                  id='deactivate'
                  className={`${styles.mb3}`}
                  placeholder='Add Reason'
                  type='text'
                  rows={'3'}
                  onChange={(e)=>props.onChange(e)}
                  name='reason'
                  value={props.userFields.reason}
                  error={props.userErrors.reason}
                  helperText={props.userErrors.reason}
                />)}
              </div>
            </StyledCard>
          </CustomDialogContent>
          <CustomDialogAction className={`${styles.fSpaceBtw}`}>
            <CustomButton variant='contained' onClick={props.onClose}>Close</CustomButton>
            <CustomButton variant='contained' type='submit'>{action == 'active' ? 'Reactivate' : 'Deactivate'}</CustomButton>
          </CustomDialogAction>
        </form>
      </Dialog>
    </div>
  );
};
export default DeactiveModal;