import React from 'react';
import { Dialog, Grid } from '@mui/material';
import styles from '../../../../../custom/style.module.css';
import { CustomButton, CustomDialogAction, CustomDialogContent, CustomDialogTitle, CustomInputLabel, CustomTextField, InputProps, StyledCard } from '../../../../../custom/components';
import { CloseIconBtn } from 'custom/constants';

const AirlineModal = (props) => {

  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={props.openAddAirline} onClose={props.handleClose} >
        <CustomDialogTitle id='form-dialog-title' className={styles.modalTitle}>{props.type == 'edit' ? 'Edit' : 'Add'} Airline
          <CloseIconBtn onClick={props.handleClose} />
        </CustomDialogTitle>
        <form onSubmit={props.addAirline} className={styles.w100}>
          <CustomDialogContent>
            <StyledCard>
              <Grid container spacing={2} rowSpacing={0}>
                <Grid item xs={12} md={12}>
                  <CustomInputLabel htmlFor='name'>Name</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='name'
                    fullWidth
                    autoFocus
                    placeholder='Name'
                    type='text'
                    onChange={props.onChange}
                    name='name'
                    value={props.airlineFields.name}
                    error={props.airlineErrors.name}
                    helperText={props.airlineErrors.name}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <CustomInputLabel htmlFor='code'>Code</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='code'
                    fullWidth
                    placeholder='Code'
                    type='text'
                    onChange={props.onChange}
                    name='code'
                    value={props.airlineFields.code}
                    error={props.airlineErrors.code}
                    helperText={props.airlineErrors.code}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CustomInputLabel htmlFor='awb_pre'>Airwaybill Prefix</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='awb_pre'
                    fullWidth
                    placeholder='Airwaybill Prefix'
                    type='text'
                    onChange={props.onChange}
                    name='awb_pre'
                    value={props.airlineFields.awb_pre}
                    error={props.airlineErrors.awb_pre}
                    helperText={props.airlineErrors.awb_pre}
                  />
                </Grid>
              </Grid>
            </StyledCard>
          </CustomDialogContent>
          <CustomDialogAction className={styles.fSpaceBtw}>
            <CustomButton variant='contained' onClick={props.handleClose}>Close</CustomButton>
            <CustomButton variant='contained' onClick={props.addAirline}>{props.type == 'edit' ? 'Update' : 'Submit'}</CustomButton>
          </CustomDialogAction>
        </form>
      </Dialog>
    </div>
  );
};
export default AirlineModal;