import { SET_FFM, SET_ULD, SET_AWB, DELETE_ULD, DELETE_AWB } from './actionTypes';

function setFFM(data) {
  return {
    type: SET_FFM,
    data
  };
}

function setUld(data) {
  return {
    type: SET_ULD,
    data
  };
}

function deleteUld(data) {
  return {
    type: DELETE_ULD,
    data
  };
}

function setAwb(data) {
  return {
    type: SET_AWB,
    data
  };
}

function deleteAwb(data) {
  return {
    type: DELETE_AWB,
    data
  };
}

function setFFMAction(data) {
  return (dispatch) => {
    dispatch(setFFM(data));
  };
}

function setUldAction(data) {
  return (dispatch) => {
    dispatch(setUld(data));
  };
}

function setAwbAction(data) {
  return (dispatch) => {
    dispatch(setAwb(data));
  };
}

function deleteUldAction(data) {
  return (dispatch) => {
    dispatch(deleteUld(data));
  };
}

function deleteAwbAction(data) {
  return (dispatch) => {
    dispatch(deleteAwb(data));
  };
}


export { setFFMAction, setUldAction, setAwbAction, deleteUldAction, deleteAwbAction };