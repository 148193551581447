import React from 'react';
import { Box, Stack, Grid, MenuItem } from '@mui/material';
import { StyledDataGrid, StyledCard, StyledCardTitle, DataGridProps, CustomSelect, SelectInputProps } from '../../../../../custom/components';
import { EditIcon, DeleteIcon, AddIcon, DateFormat } from '../../../../../custom/constants';
import { styled } from '@mui/material/styles';
import styles from '../../../../../custom/style.module.css';
import FlightScheduleModal from './FlightScheduleModal';
import flightStyles from './flights.module.css';
import theme from 'custom/theme';
import moment from 'moment';

const CustomStyledDataGrid = styled(StyledDataGrid)(() => ({
  '& .flightRow': {
    [`&.Mui-selected .${flightStyles.days}, &:hover .${flightStyles.days}, &.Mui-hovered .${flightStyles.days}`]: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
}));

const flightSchedule = (props) => {
  const { scheduleList, handleOpen, handleClose, onChange, handleDelete } = props;
  const columns = [
    {
      headerName: 'Flight#',
      minWidth: 100,
      flex: 1,
      editMode: 'cell',
      renderCell: (data) => {
        return (
          <div>
            {`${data.row.airline}${data.row.flight_no}`}
          </div>
        );
      },
    },
    {
      field: 'org',
      headerName: 'O/D',
      minWidth: 100,
      flex: 1,
      renderCell: (data) => {
        return (
          <div>
            {`${data.row.org} - ${data.row.dest}`}
          </div>
        );
      },
    },
    {
      field: 'ac_type',
      headerName: 'Aircraft Type',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'platform',
      headerName: 'Platform',
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'std',
      headerName: 'STD',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'sta',
      headerName: 'STA',
      minWidth: 100,
      flex: 1,
      renderCell: (data) => {
        return (
          <div>
            {`${data.row.sta} ${data.row.sta_next ? '+1' : ''}`}
          </div>
        );
      },
    },
    {
      field: 'from',
      headerName: 'From Date',
      minWidth: 110,
      flex: 1,
      renderCell: (data) => {
        return (
          <div>
            {`${moment(data.row.from, 'YYYY MM DD').format(DateFormat).toUpperCase()}`}
          </div>
        );
      },
    },
    {
      field: 'to',
      headerName: 'To Date',
      minWidth: 110,
      flex: 1,
      renderCell: (data) => {
        return (
          <div>
            {`${moment(data.row.to, 'YYYY MM DD').format(DateFormat).toUpperCase()}`}
          </div>
        );
      },
    },
    {
      field: 'days',
      headerName: 'Days of Ops',
      minWidth: 200,
      flex: 2,
      renderCell: (data) => {
        return (
          <Stack direction='row' spacing={1}>
            <span className={`${flightStyles.days} ${!data.row.mon ? styles.disabledImg : ''}`}>1</span>
            <span className={`${flightStyles.days} ${!data.row.tue ? styles.disabledImg : ''}`}>2</span>
            <span className={`${flightStyles.days} ${!data.row.wed ? styles.disabledImg : ''}`}>3</span>
            <span className={`${flightStyles.days} ${!data.row.thu ? styles.disabledImg : ''}`}>4</span>
            <span className={`${flightStyles.days} ${!data.row.fri ? styles.disabledImg : ''}`}>5</span>
            <span className={`${flightStyles.days} ${!data.row.sat ? styles.disabledImg : ''}`}>6</span>
            <span className={`${flightStyles.days} ${!data.row.sun ? styles.disabledImg : ''}`}>7</span>
          </Stack>
        );
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      align: 'center',
      flex: 1,
      headerAlign: 'center',
      minWidth: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (data) => {
        return (
          <Stack direction='row' spacing={1} justifyContent='center' className={styles.actionIcons}>
            <EditIcon onClick={() => handleOpen('edit', data.row)} />
            <DeleteIcon onClick={(e) => { handleDelete(data.row.id, e.target); }} />
          </Stack>
        );
      }
    },
  ];

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StyledCard>
            <Box className={`${styles.mb3} ${styles.fSpaceBtw}`} >
              <Stack direction='row' spacing={2} alignItems={'center'}>
                <StyledCardTitle component='div' className={styles.pb0}>Current Flight Schedule</StyledCardTitle>
                <CustomSelect
                  {...SelectInputProps}
                  id='airline'
                  labelId='airline-label'
                  fullWidth
                  autoFocus
                  onChange={props.onChangeFilter}
                  name='airline'
                  // className={billingStyles.tableTextField}
                  style={{ width: '90px' }}
                  value={!props?.filter?.airline ? false : props.filter.airline}
                  // classes={{ select: !props.flightFields.airline ? styles.colorPlaceholder : '' }}
                >
                  <MenuItem value='placeholder' disabled >Select Airline</MenuItem>
                  <MenuItem value={false} >All</MenuItem>
                  {props?.airlines?.map((airline, i) => (
                    <MenuItem key={i} value={airline}>
                      {airline}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </Stack>
              <AddIcon className={styles.addIcon} title='Add Airline' onClick={() => props.handleOpen('add')} />
            </Box>
            <div className={styles.tableWrapper}>
              <Box className={`${styles.tableOuter} ${flightStyles.fltTableOuter}`}>
                <CustomStyledDataGrid
                  {...DataGridProps}
                  rows={scheduleList}
                  columns={columns}
                  getRowClassName={() => 'flightRow'}
                />
              </Box>
            </div>
          </StyledCard>
        </Grid>
      </Grid>

      <FlightScheduleModal
        addSchedule={props.addSchedule}
        onChange={onChange}
        handleOpenAddUser={handleOpen}
        handleCloseSchedule={handleClose}
        openAddSchedule={props.openAddSchedule}
        flightFields={props.scheduleFields}
        handleOpen={handleOpen}
        flightErrors={props.scheduleErrors}
        type={props.type}
        changeDateTime={props.changeDateTime}
        changeCheck={props.changeCheck}
        airlines={props.airlineList}
        locationList={props.locationList}
        scheduleOrgDest={props.scheduleOrgDest} />
    </>
  );
};

export default flightSchedule;