import React, { forwardRef } from 'react';
import ServiceLog from './ServiceLog';
import moment from 'moment';
import http from 'utils/http';
// import { DateFormat, MonthFormat } from 'custom/constants';
import cookies from 'utils/cookies';
import { contractType, pageHandler } from 'custom/constants';
import cloneDeep from 'lodash/cloneDeep';
import { toast } from 'react-toastify';

// const timezone = cookies.get('timezone') || null;

class ServiceLogContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // airlines: [],
      // searchFields: {
      //   // from: moment().tz(timezone).format('YYYY-MM-DD'),
      //   // to: moment().add(1,'days').tz(timezone).format('YYYY-MM-DD'),
      //   month: moment().tz(timezone).format('YYYY-MM'),
      //   period: 'month',
      //   airline: null
      // },
      // contract_Type: null,
      genInvProgress: false,
      userContractTypes: this.props.userContractTypes,
      contract_Type: this.props.contract_Type,
      cont_item: this.props.cont_item,
      airlines: this.props.airlines,
      searchFields: this.props.searchFields,
      airlineCode: this.props.airlineCode,
      
      serviceLog: [],
      historyData: [],
      modalOpen: false,
      expanded: '',
      day: '',
      // billingFields: {},
      flightdata: [],
      daylogdata: [],
      monthlogdata: [],
      alcontractsData: [],
      contractData: [],
      dayContractData: [],
      monthContractData: [],
      invoice_info: null,
      accord_id: null,
      accord_type: null,
      anchorEl: null,
      progress: 10,
      modal: false,
      timer: null,
      sessionExpired: false,
    };
    this.station_code = cookies.get('station_code') || null;
    this.interval = null;
  }
  componentDidMount() {
    this.startTimer();
    console.log('ServiceLogContainer.js componentDidMount()');
    const currentPath = window.location.pathname;
    pageHandler(currentPath, cookies.get('role'));
    // this.eventSource = events('admin', 'method');
    // this.fetchUser();
    // this.fetchContractData();
    this.fetchInvoiceData();
    // this.fetchAirlines();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  // fetchUser = () => {
  //   http.get(`/user?id=${cookies.get('user')}`).then(({ data }) => {
  //     if(data && data.data && data.data.length > 0){
  //       // let statType = null;
  //       let cont_item = null;
  //       let contract_Type = null;
  //       let userContractTypes = data.data[0]?.contract_types || [];
  //       //get first key and value from contractType
  //       let firstRecord = Object.entries(contractType).find(([ , value]) => userContractTypes.includes(value));
  //       if (firstRecord) {
  //         // statType = 0;
  //         cont_item = firstRecord[1];
  //         contract_Type = firstRecord[0];
  //       }
  //       this.setState({ 
  //         userContractTypes : data.data[0]?.contract_types || [],
  //         // statType: statType,
  //         cont_item: cont_item,
  //         contract_Type: contract_Type,
  //       });
  //     }
  //   });
  // };

  // fetchAirlines = () => {
  //   http.get(`/airline?active=${true}`)
  //     .then((response) => {
  //       this.setState({
  //         airlines: response.data.data,
  //         searchFields: {
  //           ...this.state.searchFields,
  //           airline: response.data.data[0].id
  //         },
  //         airlineCode: response.data.data[0].code
  //       }, 
  //       () => {
  //         this.fetchContractData();
  //       });
  //     });
  // };

  // fetchServiceLog = () => {
  //   http.get(`/approved_service_log?from=${this.state.searchFields.from}&to=${this.state.searchFields.to}&airline=${this.state.searchFields.airline}`)
  //     .then((response) => {
  //       let airlinecode = this.state.airlines.find((airline) => airline.id === this.state.searchFields.airline).code;
  //       if(response.data.data && response.data.data.daylogs && response.data.data.monthlogs){
  //         let daylogs = response.data.data.daylogs;
  //         let monthlogs = response.data.data.monthlogs;
  //         let subdaylogs = [];
  //         let submonthlogs = [];
  //         daylogs.map((daylog) => {
  //           let obj = {
  //             type: moment(daylog.log[daylog.log.length - 1].date).format('DD MMMM YYYY'),
  //             airline: daylog.airline.code,
  //             date_month: moment(daylog.log[daylog.log.length - 1].date).format(DateFormat),
  //             description: daylog.data[0]? daylog.data[0].label : '',
  //             rate: daylog.data[0]? daylog.data[0].value : '',
  //             approved_by: `${daylog.log[daylog.log.length - 1].user.first_name} ${daylog.log[daylog.log.length - 1].user.last_name}`,
  //             status: daylog.log[daylog.log.length - 1].status,
  //             remark: daylog.log[daylog.log.length - 1].remark,
  //             logs: daylog.log.map((item, index) => ({ 
  //               id: index, 
  //               ...item 
  //             }))
  //           };
  //           subdaylogs.push(obj);
  //         });
  //         monthlogs.map((monthlog) => {
  //           let obj = {
  //             type: moment(monthlog.log[monthlog.log.length - 1].date).format('MMMM YYYY'),
  //             airline: monthlog.airline.code,
  //             date_month: moment(monthlog.log[monthlog.log.length - 1].date).format(MonthFormat),
  //             description: monthlog.data[0]? monthlog.data[0].label : '',
  //             rate: monthlog.data[0]? monthlog.data[0].value : '',
  //             approved_by: `${monthlog.log[monthlog.log.length - 1].user.first_name} ${monthlog.log[monthlog.log.length - 1].user.last_name}`,
  //             status: monthlog.log[monthlog.log.length - 1].status,
  //             remark: monthlog.log[monthlog.log.length - 1].remark,
  //             logs: monthlog.log.map((item, index) => ({ 
  //               id: index, 
  //               ...item 
  //             }))
  //           };
  //           submonthlogs.push(obj);
  //         });
  //         let newdaylogs ={
  //           type: 'Daily Service Log',
  //           airline: airlinecode,
  //           date_month: '-',
  //           approved_by: '-',
  //           status: 'Approved',
  //           remark: '',
  //           subRows: subdaylogs
  //         };
  //         let newmonthlogs ={
  //           type: 'Monthly Service Log',
  //           airline: airlinecode,
  //           date_month: '-',
  //           approved_by: '-',
  //           status: 'Approved',
  //           remark: '',
  //           subRows: submonthlogs
  //         };
  //         this.setState({ 
  //           serviceLog: [
  //             newdaylogs, newmonthlogs
  //           ]
  //         });
  //       } else {
  //         let newdaylogs ={
  //           type: 'Daily Service Log',
  //           airline: airlinecode,
  //           date_month: '-',
  //           status: 'Approved',
  //           remark: '',
  //           subRows: []
  //         };
  //         let newmonthlogs ={
  //           type: 'Monthly Service Log',
  //           airline: airlinecode,
  //           date_month: '-',
  //           status: 'Approved',
  //           remark: '',
  //           subRows: []
  //         };
  //         this.setState({ serviceLog: [newdaylogs, newmonthlogs] });
  //       }
  //     });
  // };

  triggerNewBilling = (regenerate = false,) => {
    let from = moment(this.state.searchFields.from).format('YYYY-MM-DD');
    let to = moment(this.state.searchFields.to).format('YYYY-MM-DD');
    if (this.state.searchFields.month) {
      if (this.state.searchFields.period === 'month') {
        from = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
        to = moment(this.state.searchFields.month, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
      } else if (this.state.searchFields.period === 'first') {
        from = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
        to = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').add(14, 'days').format('YYYY-MM-DD');
      } else {
        from = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').add(15, 'days').format('YYYY-MM-DD');
        to = moment(this.state.searchFields.month, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
      }
    }
    if (this.state.searchFields.airline) {
      http.get(`/populate_billing_queues?from=${from}&to=${to}&station=${this.station_code}&airline=${this.state.searchFields.airline}&type=${this.state.cont_item}&regenerate=${regenerate}`)  
        .then(({ data }) => {
          console.log('data', data);
          if(data?.data.length > 2){
            toast.success(data?.message);
          } else {
            toast.warning(data?.message);
          }
        });
    }
  };

  fetchInvoiceData = (regenerate = false) => {
    this.setState({ genInvProgress: true });
    console.log('fetchInvoiceData start', new Date().getHours(), new Date().getMinutes(), new Date().getSeconds(), new Date().getMilliseconds());
    let from = moment(this.state.searchFields.from).format('YYYY-MM-DD');
    let to = moment(this.state.searchFields.to).format('YYYY-MM-DD');
    if (this.state.searchFields.month) {
      if (this.state.searchFields.period === 'month') {
        from = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
        to = moment(this.state.searchFields.month, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
      } else if (this.state.searchFields.period === 'first') {
        from = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
        to = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').add(14, 'days').format('YYYY-MM-DD');
      } else {
        from = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').add(15, 'days').format('YYYY-MM-DD');
        to = moment(this.state.searchFields.month, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
      }
    }
    if (this.state.searchFields.airline) {
      http.get(`/generate_invoice?from=${from}&to=${to}&station=${this.station_code}&airline=${this.state.searchFields.airline}&type=${this.state.cont_item}&regenerate=${regenerate}`)
        .then(({ data }) => {
          let flightdata = [];
          let invoicedata = data.data;
          let billingData = [];
          let alcontractsData = [];
          let invoice_info = data.data.invoice_infos[0] || null;
          //getinboundContractData
          let inbound_contractData = [];
          data.data.inflightcontracts.map((item,i) => {
            data.data.inflightcontracts[i].contractData= data.data.inflightcontracts[i].contractData.map((item, index) => ({
              ...cloneDeep(item),
              id: `${index + 1}_inflight`,
              item: item.label,
              driver: item.applied_on?.split('|')[0].trim(),
              source: item.applied_on?.split('|')[1].trim(),
            }));
            item.contractData.map((rec) => {
              let index = inbound_contractData.findIndex((obj) => obj.contractId === rec.contractId && obj.cont_rowId === rec.cont_rowId);
              if (index === -1) {
                inbound_contractData.push(rec);
              } 
              // else {
              //   inbound_contractData[index].quantity = parseFloat(inbound_contractData[index].quantity) + parseFloat(rec.quantity);
              //   inbound_contractData[index].totalrate = parseFloat(inbound_contractData[index].totalrate) + parseFloat(rec.totalrate);
              // }
            });
          });
          let outbound_contractData = [];
          data.data.outflightcontracts.map((item,i) => {
            data.data.outflightcontracts[i].contractData= data.data.outflightcontracts[i].contractData.map((item, index) => ({
              ...cloneDeep(item),
              id: `${index + 1}_outflight`,
              item: item.label,
              driver: item.applied_on?.split('|')[0].trim(),
              source: item.applied_on?.split('|')[1].trim(),
            }));
            item.contractData.map((rec) => {
              let index = outbound_contractData.findIndex((obj) => obj.contractId === rec.contractId && obj.cont_rowId === rec.cont_rowId);
              if (index === -1) {
                outbound_contractData.push(rec);
              } 
              // else {
              //   outbound_contractData[index].quantity = parseFloat(outbound_contractData[index].quantity) + parseFloat(rec.quantity);
              //   outbound_contractData[index].totalrate = parseFloat(outbound_contractData[index].totalrate) + parseFloat(rec.totalrate);
              // }
            });
          });
          flightdata = data.data.inflightcontracts.concat(data.data.outflightcontracts);

          let day_contractData = [];
          data.data.daycontracts.map((item,i) => {
            data.data.daycontracts[i].contractData= data.data.daycontracts[i].contractData.map((item, index) => ({
              ...cloneDeep(item),
              id: `${index + 1}_daylog`,
              item: item.label,
              driver: item.applied_on?.split('|')[0].trim(),
              source: item.applied_on?.split('|')[1].trim(),
            }));

            item.contractData.map((rec) => {
              let index = day_contractData.findIndex((obj) => obj.contractId === rec.contractId && obj.cont_rowId === rec.cont_rowId);
              if (index === -1) {
                day_contractData.push(cloneDeep(rec));
              } 
              else {
                day_contractData[index].quantity = rec.quantity ? parseFloat(day_contractData[index].quantity) + parseFloat(rec.quantity) : parseFloat(day_contractData[index].quantity);
                day_contractData[index].totalrate = rec.totalrate ? parseFloat(day_contractData[index].totalrate) + parseFloat(rec.totalrate) : parseFloat(day_contractData[index].totalrate);
              }
            });
          });
          let month_contractData = [];
          data.data.monthcontracts.map((item,i) => {
            data.data.monthcontracts[i].contractData= data.data.monthcontracts[i].contractData.map((item, index) => ({
              ...cloneDeep(item),
              id: `${index + 1}_monthlog`,
              item: item.label,
              driver: item.applied_on?.split('|')[0].trim(),
              source: item.applied_on?.split('|')[1].trim(),
            }));

            item.contractData.map((rec) => {
              let index = month_contractData.findIndex((obj) => obj.contractId === rec.contractId && obj.cont_rowId === rec.cont_rowId);
              if (index === -1) {
                month_contractData.push(cloneDeep(rec));
              } 
              // else {
              //   month_contractData[index].quantity = parseFloat(month_contractData[index].quantity) + parseFloat(rec.quantity);
              //   month_contractData[index].totalrate = parseFloat(month_contractData[index].totalrate) + parseFloat(rec.totalrate);
              // }
            });
          });

          let inbound = {
            id: 'inbound',
            label: `${data.data.inflightcontracts.length} Inbound Flights`,
            flight_count: data.data.inflightcontracts.length,
            flights: data.data.inflightcontracts,
            contractData: inbound_contractData.map((item, index) => ({ 
              ...item, 
              id: `${index + 1}_daylog`,
              item: item.label,
              driver: item.applied_on?.split('|')[0].trim(),
              source: item.applied_on?.split('|')[1].trim(),
            })), // Need to add ids
          };
          let outbound = {
            id: 'outbound',
            label: `${data.data.outflightcontracts.length} Outbound Flights`,
            flight_count: data.data.outflightcontracts.length,
            flights: data.data.outflightcontracts,
            contractData: outbound_contractData.map((item, index) => ({ 
              ...item, 
              id: `${index + 1}_daylog`,
              item: item.label,
              driver: item.applied_on?.split('|')[0].trim(),
              source: item.applied_on?.split('|')[1].trim(),
            })), // Need to Sum up all outbound data
          };
          
          let daylog = {
            id: 'daylog',
            label: `${data.data.daycontracts.length} Daily Logs`,
            daylog_count: data.data.daycontracts.length, // Need to replace this with alldaylogs count
            daylogs: data.data.daycontracts,
            alldaylogs: data.data.daycontracts, // need to change this
            contractData: day_contractData.map((item, index) => ({ 
              ...item, 
              id: `${index + 1}_daylog`,
              item: item.label,
              driver: item.applied_on?.split('|')[0].trim(),
              source: item.applied_on?.split('|')[1].trim(),
            })), // Need to Sum up all daylogs data
          };
          let monthlog = {
            id: 'monthlog',
            label: `${data.data.monthcontracts.length} Monthly Log`,
            // monthlog_count: 1,
            monthlog_count: data.data.monthcontracts.length,
            monthlogs: data.data.monthcontracts,
            contractData: month_contractData.map((item, index) => ({ 
              ...item, 
              id: `${index + 1}_monthlog`,
              item: item.label,
              driver: item.applied_on?.split('|')[0].trim(),
              source: item.applied_on?.split('|')[1].trim(),
            })), // Need to Sum up all monthlogs data
          };
          let airline_data = {
            id: data.data?.airlinecontracts[0]?.label,
            label: `${parseInt(data.data.inflightcontracts.length) + parseInt(data.data.outflightcontracts.length)}  ${data.data?.airlinecontracts[0]?.label || (this.state.airlineCode + ' Flights')}`,
            sllabel: `${parseInt(monthlog?.monthlog_count) + parseInt(daylog?.daylog_count)} ${data.data?.airlinecontracts[0]?.slabel || (this.state.airlineCode + ' Service Logs')}`,
            flight_count: parseInt(data.data.inflightcontracts.length) + parseInt(data.data.outflightcontracts.length),
            inbound: inbound,
            outbound: outbound,
            alcontractsData: data.data.airlinecontracts[0]?.contractData.map((item, index) => ({
              ...item, 
              id: `${index + 1}_daylog`,
              item: item.label,
              driver: item.applied_on?.split('|')[0].trim(),
              source: item.applied_on?.split('|')[1].trim(),
            })) || [],
            contractData: [...monthlog.contractData, ...daylog.contractData],
            daylog: daylog,
            monthlog: monthlog,
          };        
          billingData.push(airline_data);
          let contractData = [];
          let dayContractData = [];
          let monthContractData = [];
          let accord_id = null;
          let accord_type = null;
          let daylogdata = [];
          let monthlogdata = [];
          if (billingData.length > 0) {
            alcontractsData = billingData[0]?.alcontractsData;
            contractData = billingData[0]?.contractData;
            accord_id = billingData[0]?.id;
            accord_type = 'airline';
            dayContractData = billingData[0]?.daylog?.contractData;
            monthContractData = billingData[0]?.monthlog?.contractData;
            daylogdata = billingData[0]?.daylog?.alldaylogs;
            monthlogdata = billingData[0]?.monthlog?.allmonthlogs;
          }
          // console.log('billingData', billingData);
          // console.log('flightdata', flightdata);
          // console.log('invoicedata', invoicedata);
          // console.log('daylogdata*************', daylogdata);
          // console.log('monthlogdata***********', monthlogdata);
          // console.log('contractData', contractData);
          // console.log('dayContractData', dayContractData);
          // console.log('monthContractData', monthContractData);
          this.setState({
            flightdata: flightdata || [],
            invoicedata: invoicedata || [],
            billingData: billingData || [],
            daylogdata: daylogdata || [],
            monthlogdata: monthlogdata || [],
            alcontractsData: alcontractsData,
            contractData: contractData || [],
            dayContractData: dayContractData || [],
            monthContractData: monthContractData || [],
            invoice_info: invoice_info || null,
            accord_id: accord_id,
            accord_type: accord_type,
            genInvProgress: false,
          }, () => {
            console.log('fetchInvoiceData end', new Date().getHours(), new Date().getMinutes(), new Date().getSeconds(), new Date().getMilliseconds());
            // if (this.state.billingFields.transit === 'inbound' || this.state.billingFields.transit === 'outbound') {
            //   this.filterData();
            // }
          });
        });
    } else {
      this.setState({ genInvProgress: false });
    }
  };

  fetchContractData = () => {
    let flightdata = [];
    let daylogdata = [];
    let monthlogdata = [];
    let from = moment(this.state.searchFields.from).format('YYYY-MM-DD');
    let to = moment(this.state.searchFields.to).format('YYYY-MM-DD');
    if (this.state.searchFields.month) {
      if (this.state.searchFields.period === 'month') {
        from = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
        to = moment(this.state.searchFields.month, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
      } else if (this.state.searchFields.period === 'first') {
        from = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
        to = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').add(14, 'days').format('YYYY-MM-DD');
      } else {
        from = moment(this.state.searchFields.month, 'YYYY-MM').startOf('month').add(15, 'days').format('YYYY-MM-DD');
        to = moment(this.state.searchFields.month, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
      }
    }
    if (this.state.searchFields.airline) {
      http.get(`/billing_contract?from=${from}&to=${to}&station=${this.station_code}&airline=${this.state.searchFields.airline}&type=${this.state.cont_item}`)
        .then(({ data }) => {
          this.setState({ flightList: data.data });
          if (data.data && data.data.length > 0) {
            let billingData = [];
            data.data.forEach((airline) => {

              let inbound = {};
              let outbound = {};
              let inbound_data = [];
              let outbound_data = [];

              let ib_flight_count = 0;
              if (airline?.inbound?.length > 0) {
                ib_flight_count = parseInt(airline?.inbound?.length);
                airline?.inbound.map((inflight) => {
                //map through inflight contracts and create array of contractsData
                  let contractsData = [];
                  let totalfltamt = 0;
                  if (inflight?.contracts?.length > 0) {
                    inflight?.contracts.map((contract) => {
                      if(contract.contractrows && contract?.contractrows.length > 0){
                        contract.contractrows.map((row) => {
                          totalfltamt += row.totalrate;
                          let [driver, source] = row.applied_on.split('|').map(s => s.trim());
                          let contractData = {
                            key: row.id,
                            id: row.id,
                            item: row.description,
                            driver: driver,
                            source: source,//Auto manual
                            quantity: row?.quantity,
                            rate: row?.rate_type === 'stable' ? row?.rate : 'Tiered Rate',
                            totalrate: row?.rate_type === 'stable' ? row.totalrate : 'Tiered Rate'
                          };
                          contractsData.push(contractData);
                        });
                      }
                    });
                    console.log('contractsData', contractsData);
                    console.log('totalfltamt', totalfltamt);
                  }
                  let flight = {
                    id: inflight?.id,
                    flight_no: inflight?.flight_no,
                    label:`${airline?.code}${inflight?.flight_no}/${moment(inflight?.flight_date,'YYYY-MM-DD').format('DDMMMYY')}`,
                    url:`/flight_folder/notes?id=${inflight.id}`,
                    flight_count: 1,
                    contractData: contractsData,
                    totalfltamt: '-',
                    pending: inflight?.pending,
                  };
                  inbound_data.push(flight);
                });
              }
              let incontractsData = [];
              if ( airline.inboundcontracts && airline.inboundcontracts?.length > 0 ) {
                airline.inboundcontracts.map((contract) => {
                  if(contract.contractrows && contract?.contractrows.length > 0){
                    contract.contractrows.map((row) => {
                      let [driver, source] = row.applied_on.split('|').map(s => s.trim());
                      let contractData = {
                        key: row.id,
                        id: row.id,
                        item: row.description,
                        driver: driver,
                        source: source,//Auto manual
                        quantity: row?.quantity || 0,
                        rate: row?.rate_type === 'stable' ? row?.rate : 'Tiered Rate',
                        totalrate: row?.rate_type === 'stable' ? row.totalrate : 'Tiered Rate'
                      };
                      incontractsData.push(contractData);
                    });
                  }
                });
                // console.log('contractsData',contractsData);
                // console.log('totalfltamt',totalfltamt);
              }
              flightdata = [...inbound_data];
              inbound = {
                id: `${airline?.id}_inbound`,
                label: `${ib_flight_count} Inbound Flights`,
                flight_count: ib_flight_count,
                flights: inbound_data,
                contractData: incontractsData,
              };


              let ob_flight_count = 0;
              if (airline?.outbound?.length > 0) {
                ob_flight_count = parseInt(airline?.outbound?.length);
                airline?.outbound.map((outflight) => {
                //map through inflight contracts and create array of contractsData
                  let contractsData = [];
                  let totalfltamt = 0;
                  if (outflight?.contracts?.length > 0) {
                    outflight?.contracts.map((contract) => {
                      if(contract.contractrows && contract?.contractrows.length > 0){
                        contract.contractrows.map((row) => {
                          totalfltamt += row.totalrate;
                          let [driver, source] = row.applied_on.split('|').map(s => s.trim());
                          let contractData = {
                            key: row.id,
                            id: row.id,
                            item: row.description,
                            driver: driver,
                            source: source,//Auto manual
                            quantity: row?.quantity || 0,
                            // rate: row?.rate || 0,
                            // totalrate: row.totalrate
                            rate: row?.rate_type === 'stable' ? row?.rate : 'Tiered Rate',
                            totalrate: row?.rate_type === 'stable' ? row.totalrate : 'Tiered Rate'
                          };
                          contractsData.push(contractData);
                        });
                      }
                    });
                  }
                  let flight = {
                    id: outflight?.id,
                    flight_no: outflight?.flight_no,
                    label: `${airline?.code}${outflight?.flight_no}/${moment(outflight?.flight_date,'YYYY-MM-DD').format('DDMMMYY')}`,
                    url: `/flight_folder/notes?id=${outflight.id}`,
                    flight_count: 1,
                    contractData: contractsData,
                    totalfltamt: totalfltamt,
                    pending: outflight?.pending,
                  };
                  outbound_data.push(flight);
                });
              }
              let outcontractsData = [];
              if ( airline.outboundcontracts && airline.outboundcontracts?.length > 0 ) {
                airline.outboundcontracts.map((contract) => {
                  if(contract.contractrows && contract?.contractrows.length > 0){
                    contract.contractrows.map((row) => {
                      let [driver, source] = row.applied_on.split('|').map(s => s.trim());
                      let contractData = {
                        key: row.id,
                        id: row.id,
                        item: row.description,
                        driver: driver,
                        source: source,//Auto manual
                        quantity: row?.quantity || 0,
                        rate: row?.rate_type === 'stable' ? row?.rate : 'Tiered Rate',
                        totalrate: row?.rate_type === 'stable' ? row.totalrate : 'Tiered Rate'
                      };
                      outcontractsData.push(contractData);
                    });
                  }
                });
                // console.log('contractsData',contractsData);
                // console.log('totalfltamt',totalfltamt);
              }
              flightdata = [...flightdata, ...outbound_data];
              outbound = {
                id: `${airline?.id}_outbound`,
                label: `${ob_flight_count} Outbound Flights`,
                flight_count: ob_flight_count,
                flights: outbound_data,
                contractData: outcontractsData,
              };

              let alcontractsData = [];
              if ( airline.airlinecontracts && airline.airlinecontracts?.length > 0 ) {
                airline.airlinecontracts.map((contract) => {
                  if (contract.contractrows && contract?.contractrows.length > 0) {
                    contract.contractrows.map((row) => {
                      let rate = 0;
                      // let totalrate = 0
                      if(row?.rate_type === 'stable'){
                        rate = row?.rate;
                        // totalrate = row.quantity * row.rate
                      } else if ( row?.rate_type === 'absolute'){
                        rate = row?.rate;
                        // console.log('absolute ***************************************8')
                        // if(row?.contrates && row?.contrates.length > 0){
                        //   row.contrates.sort(
                        //     (a, b) =>
                        //       parseInt(a.level) - parseInt(b.level)
                        //   );
                        //   for (let i = 0; i < row.contrates.length; i++) {
                        //     console.log('row.contrates[i]', row.contrates[i])
                        //     if (
                        //       row.quantity <
                        //         parseInt(row.contrates[i].level) ||
                        //       i === row.contrates.length - 1
                        //     ) {
                        //       row.rate = parseFloat(
                        //         row.contrates[i].rate
                        //       );
                        //       // totalrate = parseFloat(row.quantity) * parseFloat(row.contrates[i].rate);
                        //       break;
                        //     }
                        //   }
                        // }
                      } else if ( row?.rate_type === 'progressive' ) {
                        rate = 'Tiered Rate';
                        // if(row?.contrates && row?.contrates.length > 0){
                        //   row.contrates.sort(
                        //     (a, b) =>
                        //       parseInt(a.level) - parseInt(b.level)
                        //   );
                        //   let rate1 = row.contrates[0] && row.contrates[0].rate? parseFloat(row.contrates[0].rate): 0;
                        //   let rate2 = row.contrates[1] && row.contrates[1].rate? parseFloat(row.contrates[1].rate): 0;
                        //   let rate3 = row.contrates[2] && row.contrates[2].rate? parseFloat(row.contrates[2].rate): 0;
                        //   let level1 = row.contrates[0] && row.contrates[0].level? parseInt(row.contrates[0].level): 0;
                        //   let level2 = row.contrates[1] && row.contrates[1].level? parseInt(row.contrates[1].level): 0;
                        //   if (row.quantity <= level1 ||!rate2) {
                        //     rate = rate1;
                        //   } else if (row.quantity <= level2 ||!rate3) {
                        //     // rate = '('+String(rate1) + '$ * '+String(level1)+' + '+ String(rate2)+'$ * '+String(row.quantity - level1)+')';
                        //     rate = String(rate1)+'('+String(level1) +')\n'+String(rate2)+'('+String(row.quantity - level1) +')';
                        //   } else {
                        //     // rate = '('+String(rate1) + '$ * '+String(level1)+' + '+ String(rate2)+'$ * '+String(level2 - level1) + ' + '+ String(rate3)+'$ * '+String(row.quantity - level2)+')';
                        //     rate = String(rate1)+'('+String(level1) +')\n'+String(rate2)+'('+String(level2 - level1) +')\n'+String(rate3)+'('+String(row.quantity - level2) +')';
                        //   }
                        // }
                      }
                      let [driver, source] = row.applied_on.split('|').map(s => s.trim());
                      let contractData = {
                        key: row.id,
                        id: row.id,
                        item: row.description,
                        driver: driver,
                        source: source,//Auto manual
                        quantity: row?.quantity || 0,
                        rate: rate,
                        totalrate: parseFloat(row?.totalrate) || 0
                      };
                      alcontractsData.push(contractData);
                    });
                  }
                });
                // console.log('contractsData',contractsData);
                // console.log('totalfltamt',totalfltamt);
              }

              let daylog = {};
              let daylog_count = 0;
              let daylogs = [];
              let alldaylogs = [];
              let dcontractData = [];
              if ( airline.daycontracts && airline.daycontracts?.length > 0 ) {
                airline.daycontracts.map((contract) => {
                  if(contract.contractrows && contract?.contractrows.length > 0){
                    contract.contractrows.map((row) => {
                      let [driver, source] = row.applied_on.split('|').map(s => s.trim());
                      let obj = {
                        key: row.id,
                        id: row.id,
                        item: row.description,
                        driver: driver,
                        source: source,//Auto manual
                        quantity: row?.quantity || 0,
                        rate: row?.rate || 0,
                        totalrate: row.totalrate
                      };
                      dcontractData.push(obj);
                    });
                  }
                });
              }
              if (airline?.day_service_logs?.length > 0) {
                // sort day_service_logs by date
                airline?.day_service_logs.sort((a, b) => new Date(a.date) - new Date(b.date));
                daylog_count = parseInt(airline?.day_service_logs?.length);
                airline?.day_service_logs.map((daylog) => {
                  let ddcontractData = [];
                  if(daylog?.contracts?.length > 0){
                    daylog?.contracts.map((contract) => {
                      if(contract.contractrows && contract?.contractrows.length > 0){
                        contract.contractrows.map((row) => {
                          let [driver, source] = row.applied_on.split('|').map(s => s.trim());
                          let obj = {
                            key: row.id,
                            id: row.id,
                            item: row.description,
                            driver: driver,
                            source: source,//Auto manual
                            quantity: row?.quantity || 0,
                            rate: row?.rate || 0,
                            totalrate: row.totalrate
                          };
                          ddcontractData.push(obj);
                        });
                      }
                    });

                  }
                  let obj = {
                    id: daylog.id,
                    label: `${moment(daylog?.date,'YYYY-MM-DD').format('DDMMMYY')}`,
                    count: 1,
                    contractData: ddcontractData,
                  };
                  daylogs.push(obj);  

                });
                daylog = {
                  id: `${airline?.id}_daylog`,
                  label: `${daylog_count} Day Logs`,
                  daylog_count: daylog_count,
                  daylogs: daylogs,
                  contractData: dcontractData,
                };
              }
              if (airline?.allday_service_logs?.length > 0) {
                // sort allday_service_logs by date
                airline?.allday_service_logs.sort((a, b) => new Date(a.date) - new Date(b.date));
                daylog_count = parseInt(airline?.allday_service_logs?.length);
                airline?.allday_service_logs.map((daylog) => {
                  let obj = {
                    id: daylog.id,
                    label: `${moment(daylog?.date,'YYYY-MM-DD').format('DDMMMYY')}`,
                    count: 1,
                    contractData: [],
                    pending: daylog?.pending,
                  };
                  alldaylogs.push(obj);  
                });
                daylog = {
                  id: `${airline?.id}_daylog`,
                  label: `${daylog_count} Day Logs`,
                  daylog_count: daylog_count,
                  daylogs: daylogs,
                  alldaylogs: alldaylogs,
                  contractData: dcontractData,
                };
              }

              let monthlog = {};
              let monthlog_count = 0;
              let monthlogs = [];
              let mcontractData = [];
              if ( airline.monthcontracts && airline.monthcontracts?.length > 0 ) {
                airline.monthcontracts.map((contract) => {
                  if(contract.contractrows && contract?.contractrows.length > 0){
                    contract.contractrows.map((row) => {
                      let [driver, source] = row.applied_on.split('|').map(s => s.trim());
                      let obj = {
                        key: row.id,
                        id: row.id,
                        item: row.description,
                        driver: driver,
                        source: source,//Auto manual
                        quantity: row?.quantity || 0,
                        rate: row?.rate || 0,
                        totalrate: row.totalrate
                      };
                      mcontractData.push(obj);
                    });
                  }
                });
              }
              if (airline?.month_service_logs?.length > 0) {
                monthlog_count = parseInt(airline?.month_service_logs?.length);
                airline?.month_service_logs.map((monthlog) => {
                  let mmcontractData = [];
                  if(monthlog?.contracts?.length > 0){
                    monthlog?.contracts.map((contract) => {
                      if(contract.contractrows && contract?.contractrows.length > 0){
                        contract.contractrows.map((row) => {
                          let [driver, source] = row.applied_on.split('|').map(s => s.trim());
                          let obj = {
                            key: row.id,
                            id: row.id,
                            item: row.description,
                            driver: driver,
                            source: source,//Auto manual
                            quantity: row?.quantity || 0,
                            rate: row?.rate || 0,
                            totalrate: row.totalrate
                          };
                          mmcontractData.push(obj);
                        });
                      }
                    });

                  }
                  let obj = {
                    id: monthlog.id,
                    label: `${moment(monthlog?.date,'YYYY-MM-DD').format('MMMYY')}`,
                    count: 1,
                    contractData: mmcontractData,
                  };
                  monthlogs.push(obj);  

                });
                monthlog = {
                  id: `${airline?.id}_monthlog`,
                  label: `${monthlog_count} month Logs`,
                  monthlog_count: monthlog_count,
                  monthlogs: monthlogs,
                  contractData: mcontractData,
                };
              }
              
              let airline_data = {
                id: airline?.id,
                label: `${parseInt(ib_flight_count) + parseInt(ob_flight_count)} ${airline?.code} Flights`,
                sllabel: `${parseInt(monthlog_count) + parseInt(daylog_count)} ${airline?.code} Service Logs`,
                flight_count: ib_flight_count + ob_flight_count,
                // subRows: [inbound, outbound]
                inbound: inbound,
                outbound: outbound,
                alcontractsData: alcontractsData,
                contractData: [...mcontractData, ...dcontractData],
                daylog: daylog,
                monthlog: monthlog,
              };
              billingData.push(airline_data);

            });
            let alcontractsData = [];
            let contractData = [];
            let dayContractData = [];
            let monthContractData = [];
            let accord_id = null;
            let accord_type = null;
            if(billingData.length>0){
              alcontractsData = billingData[0]?.alcontractsData;
              contractData = billingData[0]?.contractData;
              accord_id = billingData[0]?.id;
              accord_type = 'airline';
              dayContractData = billingData[0]?.daylog?.contractData;
              monthContractData = billingData[0]?.monthlog?.contractData;
              daylogdata = billingData[0]?.daylog?.daylogs;
              monthlogdata = billingData[0]?.monthlog?.monthlogs;
            }
            // console.log('billingData',billingData);
            // console.log('flightdata',flightdata);
            // console.log('daylogdata*************',daylogdata);
            // console.log('monthlogdata***********',monthlogdata);
            // console.log('contractData',contractData);
            // console.log('dayContractData',dayContractData);
            // console.log('monthContractData',monthContractData);
            this.setState({ 
              billingData: billingData,
              flightdata: flightdata,
              daylogdata: daylogdata,
              monthlogdata: monthlogdata,
              alcontractsData: alcontractsData,
              contractData: contractData,
              dayContractData: dayContractData,
              monthContractData: monthContractData,
              accord_id: accord_id,
              accord_type: accord_type,
            }, () => {
              // if (this.state.billingFields.transit === 'inbound' || this.state.billingFields.transit === 'outbound') {
              //   this.filterData();
              // }
            });
          } else {
            this.setState({ billingData: [] });
          }
        });
    }
  };

  setContTBData = (data, type) => {
    console.log('setContTBData',type, 'id', data?.id);
    console.log('data?.contractData',data?.contractData);
    this.setState({ 
      contractData: data?.contractData,
      accord_id: data?.id,
      accord_type: type,
    });
  };

  // onFilterMain = (e) => {
  //   let name = e.target.name;
  //   let value = e.target.value;
  //   this.setState((prev) => ({
  //     searchFields: {
  //       ...prev.searchFields,
  //       [name]: value
  //     }
  //   }), () => {
  //     if (e.target.name === 'airline') {
  //       let airlinecode = this.state.airlines.find((rec) => rec.id === value)?.code;
  //       this.setState({ airlineCode: airlinecode },()=>{
  //         this.fetchContractData();
  //       });
  //     } else {
  //       this.fetchContractData();
  //     }
  //   });
  // };

  handleContractType = (e) => {
    let cont_item = contractType[e.target.value];
    this.setState({ 
      contract_Type: e.target.value,
      cont_item: cont_item,
    },()=>{
      // this.fetchContractData();
      this.fetchInvoiceData();
    });
    //call function to filter by contracttype
  };
  
  onViewHistory = (data) => {
    this.setState({ 
      historyData: data,
      modalOpen: true
    });
  };
  onCloseHistory = () => {
    this.setState({ 
      historyData: [],
      modalOpen: false
    });
  };

  handleChange = (panel) => (e, newExpanded) => {
    this.setState({ expanded: newExpanded ? panel : !this.state.expanded });
  };

  handleDay = (e) => {
    let value = moment(e.target.value).format('YYYY-MM-DD');
    this.setState({ day: value });
  };

  handlePopover = (e) => {
    console.log(e);
    this.setState({ anchorEl: e?.currentTarget });
  };

  closePopover = () => {
    this.setState({ anchorEl: null });
  };

  progressCount = () => {
    this.setState((prevState) => {
      if (prevState.progress >= 100) {
        clearInterval(this.progressInterval);
        this.progressInterval = null; 
        return null; 
      }
      return { progress: prevState.progress + 10 };
    });
  };

  handleOpen = (modal) => {
    if(modal === 'downloadProgress') {
      if (this.progressInterval) clearInterval(this.progressInterval);
      this.progressInterval = setInterval(this.progressCount, 1000);
    }
    this.setState({ modal });
  };

  onClose = () => {
    clearInterval(this.interval);
    this.setState({ 
      modal: false,
      sessionExpired: false,
    });
  };

  startTimer = () => {
    if(cookies.get('expiryTimer')) {
      this.timer = setInterval(() => {
        const currentTime = moment.utc();
        const expirationTime = moment.utc(cookies.get('expiryTimer'));
        const timeDifference = expirationTime - currentTime;

        // Check if the timer has expired
        if (timeDifference <= 0) {
          cookies.remove('expiryTimer', { path: '/' });
          clearInterval(this.timer);
          this.setState({ sessionExpired: true });
          this.stopInterval();
        // Handle timer expiration
        } else {
        // Calculate remaining time
          const seconds = Math.floor((timeDifference / 1000) % 60) || 0;
          const minutes = Math.floor((timeDifference / 1000 / 60) % 60) || 0;
          const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24) || 0;
          const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24)) || 0;
          let sessionExpiryText = 'Session expires in ';
          let remainingTime = 0;
          if( (hours === 0 && minutes === 30 && seconds === 0 )|| (hours === 0 && minutes === 15 && seconds === 0)) {
            remainingTime = minutes;
            toast.warning(`${sessionExpiryText} ${remainingTime} minutes`);
          }
          this.setState({ timer: `Expires in ${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds` }
            // , ()=> {console.log(this.state.timer, 'timer');}
          );
        }
      }, 1000);
    }
  };
  stopInterval = () => {
    clearInterval(this.timer);
    clearInterval(this.state.timer);
    cookies.remove('expiryTimer', { path: '/' });
  };

  render() {
    return (
      <>  
        <ServiceLog
          genInvProgress={this.props.genInvProgress}
          serviceLog={this.state.serviceLog}
          historyData={this.state.historyData}
          modalOpen={this.state.modalOpen}
          expanded={this.state.expanded}
          searchFields={this.state.searchFields}
          cont_item={this.state.cont_item}
          airlines={this.state.airlines}
          day={this.state.day}
          contract_Type={this.state.contract_Type}
          billingData={this.state.billingData}
          alcontractsData={this.state.alcontractsData}
          contractData={this.state.contractData}
          flightdata={this.state.flightdata}
          daylogdata={this.state.daylogdata}
          monthlogdata={this.state.monthlogdata}
          dayContractData={this.state.dayContractData}
          monthContractData={this.state.monthContractData}
          invoice_info={this.state.invoice_info}
          airlineCode={this.state.airlineCode}

          fetchInvoiceData={this.fetchInvoiceData}
          triggerNewBilling={this.triggerNewBilling}
          onViewHistory={this.onViewHistory}
          onCloseHistory={this.onCloseHistory}
          handleChange={this.handleChange}
          handleDay={this.handleDay} 
          // onFilterMain={this.onFilterMain}
          // flightFields={this.props.flightFields}
          handleContractType={this.handleContractType}
          setContTBData={this.setContTBData}
          accord_id={this.state.accord_id}
          accord_type={this.state.accord_type}
          handlePopover={this.handlePopover}
          closePopover={this.closePopover}
          anchorEl={this.state.anchorEl}

          handleOpen={this.handleOpen}
          modal={this.state.modal}
          progress={this.state.progress}
          onClose={this.onClose}

          expiryTimer={this.state.timer}
          sessionExpired={this.state.sessionExpired}
          startTimer={this.startTimer}
          stopInterval={this.stopInterval}
        />
      </>
    );
  }
}

// export default ServiceLogContainer;
export default (forwardRef((props, ref) => <ServiceLogContainer {...props} ref={ref} />));
