import React from 'react';
// import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ManageUsersContainer from './tabs/manageusers/ManageUserContainer';
import HandledAirlinesContainer from './tabs/handledairlines/HandledAirlinesContainer';
import FlightScheduleContainer from './tabs/flightschedule/FlightScheduleContainer';
import WhiteListContainer from './tabs/whitelist/WhiteListContainer';
import SPLMappingContainer from './tabs/splmapping/SPLMappingContainer';
import ManageUsersIcon from './../../../assets/images/adminTabIcons/manageusers.svg';
import HandledAirlinesIcon from './../../../assets/images/adminTabIcons/handledairlines.svg';
import FlightScheduleIcon from './../../../assets/images/adminTabIcons/flightschedule.svg';
import WhiteListIcon from './../../../assets/images/adminTabIcons/whitelist.svg';
import SPLIcon from './../../../assets/images/adminTabIcons/specials.svg';
import ContractIcon from './../../../assets/images/adminTabIcons/contract.svg';
import ChecklistIcon from './../../../assets/images/adminTabIcons/checklist.svg';
import InvoiceIcon from './../../../assets/images/adminTabIcons/invoice.svg';
import { adminTabs, pagelist } from '../../../custom/constants';
import { IconTab } from '../../../custom/components';
import ConfirmModalContainer from '../common/confirmModal/ConfirmModalContainer';
import adminStyles from '../admin/tabs/admin.module.css';
import styles from '../../../custom/style.module.css';
import { IconButton } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import theme from 'custom/theme';
import ReportChecklistContainer from './tabs/reportschecklist/ReportChecklistContainer';
import ManageContractsContainer from './tabs/managecontracts/ManageContractsContainer';
import cookies from 'utils/cookies';
import TaxModuleContainer from './tabs/taxmodule/TaxModuleContainer';

let filteredpagelist = pagelist.filter((page) => page?.roles.includes(cookies.get('role')));

const Admin = (props) => {
  const { value } = props;
  function TabPanel(tabPanelProps) {
    const { children, index, ...other } = tabPanelProps;
    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {tabPanelProps.value === index && (
          <Box sx={{ pt: 0 }}>
            <Typography component='span'>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(path) {
    const index = path.replace('admin/', '');
    return {
      id: `simple-tab-${index}`,
      value: index,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box>
          <Tabs value={value} onChange={(_, v) => props.handleChange(v)}
            indicatorColor='transparent'
            textColor='primary'
            variant='scrollable'
            scrollButtons='auto'
            aria-label='scrollable admin tabs'
            classes={{ flexContainer: adminStyles.fJcAround }}
            className={`${adminStyles.headerTab} ${adminStyles.adminTabs}`}
            ScrollButtonComponent={(props) => {
              if (
                props.direction === 'left'
              ) {
                return (
                  <IconButton aria-label='left arrow' {...props} className={adminStyles.tabArrows} disableRipple>
                    <ArrowLeftIcon
                      sx={{ color: props.disabled ? 'disabled' : theme.palette.primary.main }}
                    />
                  </IconButton>
                );
              } else if (
                props.direction === 'right' 
              ) {
                return (
                  <IconButton aria-label='right arrow' {...props} className={adminStyles.tabArrows} disableRipple>
                    <ArrowRightIcon
                      sx={{ color: props.disabled ? 'disabled' : theme.palette.primary.main }}
                    />
                  </IconButton>
                );
              } else {
                return null;
              }
            }}>
            {
              filteredpagelist.find((page) => page.title === 'Admin | Manage Users' ) &&
                <IconTab disableRipple={true} icon={<img src={ManageUsersIcon} alt='manage users' />} classes={{ iconWrapper : adminStyles.menuIcon }} label={adminTabs.manage_users} {...a11yProps('admin/manage_users')} />
            }
            {
              filteredpagelist.find((page) => page.title === 'Admin | Handled Airlines' ) &&
                <IconTab disableRipple={true} icon={<img src={HandledAirlinesIcon} alt='handled airlines' />} classes={{ iconWrapper : adminStyles.menuIcon }} label={adminTabs.handled_airlines} {...a11yProps('admin/handled_airlines')} />
            }
            {
              filteredpagelist.find((page) => page.title === 'Admin | Flight Schedule' ) &&
                <IconTab disableRipple={true} icon={<img src={FlightScheduleIcon} alt='flight schedule' />} classes={{ iconWrapper : adminStyles.menuIcon }} label={adminTabs.flight_schedule} {...a11yProps('admin/flight_schedule')} />
            }
            {
              filteredpagelist.find((page) => page.title === 'Admin | White List - Docs Stream' ) &&
                <IconTab disableRipple={true} icon={<img src={WhiteListIcon} alt='white list' />} classes={{ iconWrapper : `${adminStyles.menuIcon} ${adminStyles.whiteListcon}` }} label={adminTabs.white_list} {...a11yProps('admin/white_list')} />
            }
            {
              filteredpagelist.find((page) => page.title === 'Admin | SPL Mapping' ) &&
                <IconTab disableRipple={true} icon={<img src={SPLIcon} alt='spl mapping' />} classes={{ iconWrapper : `${adminStyles.menuIcon} ${adminStyles.splIcon}` }} label={adminTabs.spl_mapping} {...a11yProps('admin/spl_mapping')} />
            }
            {
              filteredpagelist.find((page) => page.title === 'Admin | Manage Contract' ) &&
                <IconTab disableRipple={true} icon={<img src={ContractIcon} alt='manage contract' />}classes={{ iconWrapper : adminStyles.menuIcon }}  label={adminTabs.manage_contract} {...a11yProps('admin/manage_contract')} />
            }
            {
              filteredpagelist.find((page) => page.title === 'Admin | Reports Checklist' ) &&
                <IconTab disableRipple={true} icon={<img src={ChecklistIcon} alt='checklist' />} classes={{ iconWrapper : adminStyles.menuIcon }} label={adminTabs.reports_checklist} {...a11yProps('admin/reports_checklist')} />
            }
            {
              filteredpagelist.find((page) => page.title === 'Admin | Invoicing' ) &&
                <IconTab disableRipple={true} icon={<img src={InvoiceIcon} alt='invoicing' className={`${styles.primarySvg}`} />} classes={{ iconWrapper : adminStyles.menuIcon }} label={adminTabs.invoicing} {...a11yProps('admin/invoicing')} />
            }
          </Tabs>
        </Box>
        {
          filteredpagelist.find((page) => page.title === 'Admin | Manage Users' ) &&
          <TabPanel value={value} index={'manage_users'}>
            <ManageUsersContainer />
          </TabPanel>
        }
        {
          filteredpagelist.find((page) => page.title === 'Admin | Handled Airlines' ) &&
          <TabPanel value={value} index={'handled_airlines'}>
            <HandledAirlinesContainer />
          </TabPanel>
        }
        {
          filteredpagelist.find((page) => page.title === 'Admin | Flight Schedule' ) &&
          <TabPanel value={value} index={'flight_schedule'}>
            <FlightScheduleContainer />
          </TabPanel>
        }
        {
          filteredpagelist.find((page) => page.title === 'Admin | White List - Docs Stream' ) &&
          <TabPanel value={value} index={'white_list'}>
            <WhiteListContainer />
          </TabPanel>
        }
        {
          filteredpagelist.find((page) => page.title === 'Admin | SPL Mapping' ) &&
          <TabPanel value={value} index={'spl_mapping'}>
            <SPLMappingContainer />
          </TabPanel>
        }
        {
          filteredpagelist.find((page) => page.title === 'Admin | Reports Checklist' ) &&
          <TabPanel value={value} index={'reports_checklist'}>
            <ReportChecklistContainer />
          </TabPanel>
        }
        {
          filteredpagelist.find((page) => page.title === 'Admin | Manage Contract' ) &&
          <TabPanel value={value} index={'manage_contract'}>
            <ManageContractsContainer />
          </TabPanel>
        }
        {
          filteredpagelist.find((page) => page.title === 'Admin | Invoicing' ) &&
          <TabPanel value={value} index={'invoicing'}>
            <TaxModuleContainer />
          </TabPanel>
        }
      </Box>
      <ConfirmModalContainer />
    </>
  );
};

export default Admin;
