import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  headerContainerInner: {
    marginTop: 10,
    width: '32%',
  },
  headerContainer: { marginTop: 24, },
  title: {
    fontSize: 12,
    fontWeight: 'bold',
    // borderBottom: '1px solid lightgrey',
    paddingBottom: 2,
  },
  label: {
    fontSize: 9,
    padding: 5,
    lineHeight: 'normal',
    textAlign: 'center',
    textTransform: 'uppercase',
    borderBottom: '1px solid lightgrey',
  },
  labelMain: {
    fontSize: 10,
    padding: 5,
    lineHeight: 'normal',
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  data: {
    fontSize: 10,
    padding: 5,
    lineHeight: 'normal',
    textAlign: 'center',
    color: 'grey',
  },
  dataOuter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'center', 
    flexGrow: 1,
  },
  // labelOuter: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   alignItems: 'flex-end',
  //   justifyContent: 'center', 
  // },
  dataWrapper: {
    fontSize: 10,
    lineHeight: 'normal',
    textAlign: 'center',
    border: '1px solid lightgrey',
    borderRight: 'none'
  },
  dataWrapperInner: {
    fontSize: 10,
    lineHeight: 'normal',
    borderRight: '1px solid lightgrey',
    borderLeft: 'none',
    borderBottom: 'none',
    borderTop: 'none',
  },

  row:{
    flexDirection: 'row',
    justifyContent: 'space-between',
    // paddingBottom: 4,
    // paddingTop: 4,
  },
});

function formatNumber(num) {
  return num % 1 === 0 ? Math.round(num) : num.toFixed(1);
}

const ManifestSummary = (props) => {
  const { summary } = props;

  return (
    <View style={styles.headerContainer}>
      <Text style={styles.title}>Manifest Summary</Text>
      <View style={styles.row}>
        <View style={styles.headerContainerInner}>
          <Text style={styles.labelMain}>loose</Text>
          <View style={styles.row}>
            <View style={styles.dataWrapper}>
              <View style={styles.labelOuter}><Text style={styles.label}>Awb</Text></View> 
              <View style={styles.dataOuter}> 
                <Text style={styles.data}>{summary?.loose?.awbs}</Text>
              </View>
            </View>        
            <View style={styles.dataWrapper}>
              <View style={styles.labelOuter}>
                <Text style={styles.label}>Pcs</Text>
              </View> 
              <View style={styles.dataOuter}> 
                <Text style={styles.data}>{summary?.loose?.pcs}</Text>
              </View>
            </View>      
            <View style={styles.dataWrapper}>
              <View style={styles.labelOuter}>
                <Text style={styles.label}>Wgt</Text>
              </View> 
              <View style={styles.dataOuter}>
                <Text style={styles.data}>{formatNumber(Math.round(summary?.loose?.wgt*100)/100)}</Text>
              </View>
            </View>  
            <View style={styles.dataWrapper}>
              <Text style={styles.label}>Ulds</Text>
              <View style={styles.row}>
                <View style={styles.dataWrapperInner}>
                  <View style={styles.labelOuter}>
                    <Text style={styles.label}>LD3</Text>
                  </View> 
                  <View style={styles.dataOuter}>
                    <Text style={styles.data}>{summary?.looseulds?.LD3}</Text>
                  </View> 
                </View>       
                <View style={styles.dataWrapperInner}>
                  <View style={styles.labelOuter}>
                    <Text style={styles.label}>LD7</Text>
                  </View> 
                  <View style={styles.dataOuter}>
                    <Text style={styles.data}>{summary?.looseulds?.LD7}</Text>
                  </View> 
                </View>       
                <View style={styles.dataWrapperInner}>
                  <View style={styles.labelOuter}>
                    <Text style={styles.label}>MD</Text>
                  </View> 
                  <View style={styles.dataOuter}>
                    <Text style={styles.data}>{summary?.looseulds?.MD}</Text>
                  </View> 
                </View> 
              </View> 
            </View>
          </View>
        </View>
        
        <View style={styles.headerContainerInner}>
          <Text style={styles.labelMain}>Intact</Text>
          <View style={styles.row}>
            <View style={styles.dataWrapper}>
              <View style={styles.labelOuter}>
                <Text style={styles.label}>Awb</Text>
              </View> 
              <View style={styles.dataOuter}>
                <Text style={styles.data}>{summary?.intact?.awbs}</Text>
              </View> 
            </View>        
            <View style={styles.dataWrapper}>
              <View style={styles.labelOuter}>
                <Text style={styles.label}>Pcs</Text>
              </View> 
              <View style={styles.dataOuter}>
                <Text style={styles.data}>{summary?.intact?.pcs}</Text>
              </View> 
            </View>      
            <View style={styles.dataWrapper}>
              <View style={styles.labelOuter}>
                <Text style={styles.label}>Wgt</Text>
              </View> 
              <View style={styles.dataOuter}>
                <Text style={styles.data}>{formatNumber(Math.round(summary?.intact?.wgt*100)/100)}</Text>
              </View> 
            </View>  
            <View style={styles.dataWrapper}>
              <View style={styles.labelOuter}>
                <Text style={styles.label}>Ulds</Text>
              </View> 
              <View style={styles.row}>
                <View style={styles.dataWrapperInner}>
                  <View style={styles.labelOuter}>
                    <Text style={styles.label}>LD3</Text>
                  </View> 
                  <View style={styles.dataOuter}>
                    <Text style={styles.data}>{summary?.intactulds?.LD3}</Text>
                  </View> 
                </View>       
                <View style={styles.dataWrapperInner}>
                  <View style={styles.labelOuter}>
                    <Text style={styles.label}>LD7</Text>
                  </View> 
                  <View style={styles.dataOuter}>
                    <Text style={styles.data}>{summary?.intactulds?.LD7}</Text>
                  </View> 
                </View>       
                <View style={styles.dataWrapperInner}>
                  <View style={styles.labelOuter}>
                    <Text style={styles.label}>MD</Text>
                  </View> 
                  <View style={styles.dataOuter}>
                    <Text style={styles.data}>{summary?.intactulds?.MD}</Text>
                  </View> 
                </View> 
              </View> 
            </View>
          </View>
        </View>

        <View style={styles.headerContainerInner}>
          <Text style={styles.labelMain}>total</Text>
          <View style={styles.row}>
            <View style={styles.dataWrapper}>
              <View style={styles.labelOuter}>
                <Text style={styles.label}>Awb</Text>
              </View> 
              <View style={styles.dataOuter}>
                <Text style={styles.data}>{summary?.total?.awbs}</Text>
              </View> 
            </View>        
            <View style={styles.dataWrapper}>
              <View style={styles.labelOuter}>
                <Text style={styles.label}>Pcs</Text>
              </View> 
              <View style={styles.dataOuter}>
                <Text style={styles.data}>{summary?.total?.pcs}</Text>
              </View> 
            </View>      
            <View style={styles.dataWrapper}>
              <View style={styles.labelOuter}>
                <Text style={styles.label}>Wgt</Text>
              </View> 
              <View style={styles.dataOuter}>
                <Text style={styles.data}>{formatNumber(Math.round(summary?.total?.wgt*100)/100)}</Text> 
              </View>        
            </View>  
            <View style={styles.dataWrapper}>
              <View style={styles.labelOuter}>
                <Text style={styles.label}>Ulds</Text>
              </View> 
              <View style={styles.row}>
                <View style={styles.dataWrapperInner}>
                  <View style={styles.labelOuter}>
                    <Text style={styles.label}>LD3</Text>
                  </View> 
                  <View style={styles.dataOuter}>
                    <Text style={styles.data}>{summary?.totalulds?.LD3}</Text>
                  </View> 
                </View>       
                <View style={styles.dataWrapperInner}>
                  <View style={styles.labelOuter}>
                    <Text style={styles.label}>LD7</Text>
                  </View> 
                  <View style={styles.dataOuter}>
                    <Text style={styles.data}>{summary?.totalulds?.LD7}</Text>
                  </View> 
                </View>       
                <View style={styles.dataWrapperInner}>
                  <View style={styles.labelOuter}>
                    <Text style={styles.label}>MD</Text>
                  </View> 
                  <View style={styles.dataOuter}>
                    <Text style={styles.data}>{summary?.totalulds?.MD}</Text>
                  </View> 
                </View> 
              </View> 
            </View>
          </View>
        </View>

      </View>
    </View>
  );
};
  
export default ManifestSummary;