export const SET_COMPANY_STATIONS = 'SET_COMPANY_STATIONS';
export const SET_DELETE = 'SET_DELETE';
export const CLEAR_DELETE = 'CLEAR_DELETE';
export const SET_DOC = 'SET_DOC';
export  const SET_FFM = 'SET_FFM';
export const SET_ULD = 'SET_ULD';
export const DELETE_ULD = 'DELETE_ULD';
export const SET_AWB = 'SET_AWB';
export const DELETE_AWB = 'DELETE_AWB';
export const SET_FLIGHT = 'SET_FLIGHT';
export const SET_FLIGHT_INFO = 'SET_FLIGHT_INFO';
