import React from 'react';
import { connect } from 'react-redux';
import FlightsInfo from './FlightsInfo';
import { dateTolocale } from 'utils/converter';
import { DateTimeFormat } from 'custom/constants';
import http from 'utils/http';

class FlightInfoContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFlight: false,
      search: '',
      editField: false,
      ctype: props.ctype,
    };
  }

  componentDidMount() {
    this.updateNote();
    if(this.props.printFunction) {
      this.props.printFunction(this.props.flight, 'flightInfo');
    }
    if(this.props.showReportModal) {
      this.setState({ showFlight: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.flight !== this.props.flight) {
      this.updateNote();
    }
    if(prevProps.ctype !== this.props.ctype) {
      this.setState({ ctype: this.props.ctype });
    }
  }

  updateNote = ()=> {
    const ffmNoteElement = document.getElementById('ffm-note');
    if (!ffmNoteElement) {
      console.error('Element with ID "ffm-note" not found.');
      return;
    }
    if (this.props.flight) {
      if (this.props.flight?.org == this.stn) {
        if(this.props.flight.out_ffm){
          ffmNoteElement.innerHTML = `Uploaded on ${dateTolocale(this.props.flight.out_ffm.uploaded_date, DateTimeFormat)}`;
        } else {
          ffmNoteElement.innerHTML = '';
        }
      } else if (this.props.flight?.dest == this.stn) {
        if(this.props.flight.in_ffm){
          ffmNoteElement.innerHTML = `Uploaded on ${dateTolocale(this.props.flight.in_ffm.uploaded_date, DateTimeFormat)}`;
        } else {
          ffmNoteElement.innerHTML = '';
        }
      } else {
        ffmNoteElement.innerHTML = '';
      }
    } else {
      ffmNoteElement.innerHTML = '';
    }
  };

  changeCheck = (e) => {
    this.setState({ showFlight: e.target.checked, });
  };

  onchangeSearch = (e) => {
    if (e.target.value.length == 3 && !isNaN(e.target.value) && this.state.search.length == 2) {
      e.target.value = e.target.value + '-';
    }

    this.setState({ search: e.target.value?.toUpperCase() });
  };

  handleSearch = () => {
    this.props.handleSearch(this.state.search);
  };

  onSelect = (e) => {
    this.props.handleCtype(e.target.value);
  };


  onEditField = (type) => {
    this.setState({ editField: type });
  };

  onChange = (field, pre_value, value) => {
    if(pre_value != value) {
      http.put(`flight_edit/${this.props.flight.id}`, { [field]: value }).then(() => {
        this.setState({ editField: false });
      });
    }else
    {
      this.setState({ editField: false });
    }
  };

  render() {
    return (
      <FlightsInfo
        ctype={this.state.ctype}
        onSelect={this.onSelect}
        editField={this.state.editField}
        onEditField={this.onEditField}
        onChange={this.onChange}
        flightData={this.props.flight}
        showFlight={this.state.showFlight}
        search={this.state.search}
        handleSearch={this.handleSearch}
        onchangeSearch={this.onchangeSearch}
        changeCheck={this.changeCheck}
        showReportModal={this.props.showReportModal} 
        printFunction={this.props.printFunction} 
      />
    );
  }
}


const mapStateToProps = (state) => {
  return { flight: state.flightInfo };
};

export default connect(mapStateToProps)(FlightInfoContainer);