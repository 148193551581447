import React from 'react';
import { Dialog, Grid, RadioGroup, Stack, Radio, FormControl, MenuItem } from '@mui/material';
import styles from '../../../../../custom/style.module.css';
import { CustomButton, CustomDialogAction, CustomDialogContent, CustomDialogTitle, CustomInputLabel, CustomTextField, InputProps, StyledCard, CustomRadio, CustomSelect, SelectInputProps, NUMBERINPUT_PROPS } from '../../../../../custom/components';
import { CloseIconBtn } from 'custom/constants';

const TaxModal = (props) => {

  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={props.openAddTax} onClose={props.handleClose} >
        <CustomDialogTitle id='form-dialog-title' className={styles.modalTitle}>{props.type == 'edit' ? 'Edit' : 'Add'} Tax
          <CloseIconBtn onClick={props.handleClose} />
        </CustomDialogTitle>
        <form onSubmit={props.addEditTax} className={styles.w100}>
          <CustomDialogContent>
            <StyledCard>
              <Grid container spacing={2} rowSpacing={0}>
                <Grid item xs={12} md={12}>
                  <CustomInputLabel htmlFor='name'>Title</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='title'
                    fullWidth
                    autoFocus
                    placeholder='Title'
                    type='text'
                    onChange={props.onModalChange}
                    name='title'
                    value={props.taxFields.title}
                    error={props.taxErrors.title}
                    helperText={props.taxErrors.title}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <CustomInputLabel htmlFor='name'>Type</CustomInputLabel>
                  <RadioGroup
                    id='type'
                    aria-labelledby='convience-fee-type-radio-buttons-group'
                    name='type'
                    row
                    onChange = {props.onModalChange}
                    value={props.taxFields.type}
                    error={props.taxErrors.type}
                    helperText={props.taxErrors.type}
                  >
                    <Stack spacing={4} direction='row'>
                      <CustomRadio control={<Radio />} value='Flat' label={'Flat'} />
                      <CustomRadio control={<Radio />} value='Percentage' label={'Percentage of Total'} />
                    </Stack>
                  </RadioGroup>
                </Grid>


                <Grid item xs={12} md={12}>
                  <CustomInputLabel htmlFor='name'>Cycle</CustomInputLabel>
                  <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={3}>
                  </Stack>
                  <FormControl variant='standard' fullWidth>
                    <CustomSelect
                      {...SelectInputProps}
                      id='cycle'
                      labelId='invoice_code_label'
                      fullWidth
                      name='cycle'
                      onChange={props.onModalChange}
                      value={!props.taxFields.cycle ? 'placeholder' : props.taxFields.cycle}
                      error={props.taxErrors.cycle}
                      helperText={props.taxErrors.cycle}
                    >
                      <MenuItem value='placeholder' disabled>Select for which Cycle to apply on</MenuItem>
                      <MenuItem value='All'>All</MenuItem>
                      <MenuItem value='Cycle1'>Cycle1</MenuItem>
                      <MenuItem value='Cycle2'>Cycle2</MenuItem>
                    </CustomSelect>
                  </FormControl>
                </Grid>
                  
                <Grid item xs={12} md={12}>
                  <CustomInputLabel htmlFor='name'>Value</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='value'
                    fullWidth
                    placeholder=''
                    name='value'
                    type='number'
                    sx={NUMBERINPUT_PROPS}
                    onChange={props.onModalChange}
                    value={props.taxFields.value}
                    error={props.taxErrors.value}
                    helperText={props.taxErrors.value}
                  />
                </Grid>
                
              </Grid>
            </StyledCard>
          </CustomDialogContent>
          <CustomDialogAction className={styles.fSpaceBtw}>
            <CustomButton variant='contained' onClick={props.handleClose}>Close</CustomButton>
            <CustomButton variant='contained' onClick={props.addEditTax}>{props.type == 'edit' ? 'Update' : 'Submit'}</CustomButton>
          </CustomDialogAction>
        </form>
      </Dialog>
    </div>
  );
};
export default TaxModal;