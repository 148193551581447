import React from 'react';
import { pageHandler } from '../../../custom/constants';
import cookies from 'utils/cookies';

class RedirectContainer extends React.Component {
  componentDidMount() {
    pageHandler(null, cookies.get('role')); 
  }

  render() {
    return (
      <>
      </>
    );
  }
}


export default RedirectContainer;