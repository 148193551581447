import React from 'react';
import { Dialog } from '@mui/material';
import styles from '../../../../../custom/style.module.css';
import { CustomButton, CustomTextField, CustomDialogTitle, CustomDialogAction, CustomInputLabel, StyledCard, InputProps, CustomDialogContent } from '../../../../../custom/components';
import { CloseIconBtn } from 'custom/constants';

const AddProductsModal = (props) => {
  const { action, addProducts, editProducts, onChangeProduct } = props;
  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={props.modal === 'product'} onClose={props.onClose} maxWidth={'sm'} fullWidth>
        <CustomDialogTitle id='form-dialog-title'>
          {action == 'add' ? 'Add' : 'Edit'} Product
          <CloseIconBtn onClick={props.onClose} />
        </CustomDialogTitle>
        <form className={styles.w100} onSubmit={action == 'add' ? addProducts : editProducts} onReset={props.onClose}>
          <CustomDialogContent>
            <StyledCard>
              <div>
                <CustomInputLabel htmlFor='products'>Product Name</CustomInputLabel>
                <CustomTextField
                  {...InputProps}
                  fullWidth
                  autoFocus
                  id='products'
                  className={`${styles.mb3}`}
                  placeholder='Product Name'
                  type='text'
                  name='name'
                  onChange={onChangeProduct}
                  value={props.productField.name || ''}
                  error={props.productErrors.name}
                  helperText={props.productErrors.name}
                />
              </div>
            </StyledCard>
          </CustomDialogContent>
          <CustomDialogAction className={`${styles.fSpaceBtw}`}>
            <CustomButton variant='contained' type='reset'> Close </CustomButton>
            <CustomButton variant='contained' type='submit'>{action == 'add' ? 'Submit' : 'Update'}</CustomButton>
          </CustomDialogAction>
        </form>
      </Dialog>
    </div>
  );
};
export default AddProductsModal;