import React, { Fragment } from 'react';
import { StyledDataGrid, DataGridProps } from 'custom/components';
import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import flightStyles from './fltloadinfo.module.css';
import styles from '../../../../../custom/style.module.css';
import { discrepancyType } from 'custom/constants';

const Discrepancy = (props) => {
  const summaryProps = {
    variant: 'body2',
    component: 'small',
    color: grey[800],
    fontSize: 13,
  };

  const summaryMainProps = {
    variant: 'body1',
    component: 'p',
    color: 'primary',
    fontWeight: 600,
    fontSize: '0.9rem',
  };

  const flightLoadColumns = [
    {
      field: 'type',
      headerName: 'Type',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'awb_no',
      headerName: 'Airwaybill',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'prod_code',
      headerName: 'Product',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'org',
      headerName: 'Origin',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'dest',
      headerName: 'Dest',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 't_pcs',
      headerName: 'Total Pcs',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 't_wgt',
      headerName: 'Total Weight',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'd_pcs',
      headerName: 'Dscpy Pcs',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'd_wgt',
      headerName: 'Dscpy Wgt',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'remark',
      headerName: 'Remarks',
      minWidth: 100,
      flex: 1,
    },
  ];
  
  return <>
    <Grid container spacing={3} className={styles.pt3}>
      {discrepancyType && discrepancyType.length > 0 ? props.showSummary && discrepancyType.map((type, index) => {
        return (
          <Grid item sm={12} md={6} lg={4} xl={3} key={index}>
            <div className={flightStyles.summaryCard}>
              <div className={`${styles.fStartSpaceBtw} ${styles.pb2}`}>
                <Typography {...summaryMainProps}>{type}</Typography>
                <Typography {...summaryMainProps}>{(props.billingData && props.billingData[0] && props.billingData[0][type]) || 0}</Typography>
              </div>
              <div className={`${styles.fSpaceBtw} ${styles.mtauto} ${styles.pt2} ${flightStyles.summaryHead}`}>
                <Stack direction='column'>
                  <Typography {...summaryProps}>Inbound</Typography>
                  <Typography {...summaryProps}>{(props.billingData && props.billingData[0]?.inbound && props.billingData[0].inbound[type]) || 0}</Typography>
                </Stack>
                <Stack direction='column'>
                  <Typography {...summaryProps}>Outbound</Typography>
                  <Typography {...summaryProps} textAlign='right'>{(props.billingData && props.billingData[0]?.outbound && props.billingData[0].outbound[type]) || 0}</Typography>
                </Stack>
              </div>
            </div>
          </Grid>
        );
      }): Array.from({ length: 9 }).map((_, index) => <Grid item sm={12} md={6} lg={4} xl={3} key={index}>
        <Skeleton variant='rectangular' width={'100%'} height={100} /> 
      </Grid>)}
    </Grid>

    {!props.showSummary &&
        <div className={`${styles.tableWrapper} ${styles.pt1}`}>
          <Box className={`${styles.tableOuter} ${flightStyles.dscptableOuter} ${styles.mt3}`}>
            <StyledDataGrid
              {...DataGridProps}
              rows={props.contractData || []}
              columns={flightLoadColumns}
              apiRef={props.apiRef}
              param={props.contractData?.length}
            />  
          </Box>
        </div>     
    }
  </>;

};

export default Discrepancy;