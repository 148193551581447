import React from 'react';
import { Box, Stack, Grid, MenuItem, InputAdornment, TableBody, TableHead, TableCell, TableRow, Table, IconButton } from '@mui/material';
import {
  StyledCard, StyledCardTitle, CustomInputLabel, CustomDatePicker, CustomSelect, CustomSearch, CustomTimePicker,
  TableBadge, StyledTableContainer, NestedTableCellContainer, SelectInputProps, InputProps,
} from '../../../custom/components';
import SearchIcon from '@mui/icons-material/Search';
import styles from '../../../custom/style.module.css';
import reportStyle from './report.module.css';
import { Search } from '@mui/icons-material';
import { WhiteCameraIcon, ExcelIcon } from 'custom/constants';
import Attachment from './attachment';
import dayjs from 'dayjs';
import moment from 'moment';

const ScreeningReport = (props) => {

  return (
    <Grid container spacing={3}>
      <div className={styles.headerFieldContainer}>
        <CustomSearch
          id='search'
          type='search'
          placeholder='Enter Awb Number'
          fullWidth
          name='awb'
          value={props.search.awb}
          onChange={props.onChangeSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <SearchIcon onClick={() => props.fetchReport(true)} />
              </InputAdornment>
            ),
          }}
          className={`${styles.brInput}`}
        />
      </div>

      <Grid item xs={12}>
        <StyledCard>
          <StyledCardTitle gutterBottom variant='' component='div'>Reports Filter</StyledCardTitle>
          <Stack direction='row' sx={{ mb: 2 }}>
            {/* <CustomInputLabel className={reportsStyles.ipLabel}> Filters:</CustomInputLabel> */}
            <Grid container spacing={2} columnSpacing={4}>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3} direction='row' container columnSpacing={1} rowSpacing={{
                xs: 2,
                sm: 0,
              }}>
                <Grid item xs={12}>
                  <CustomInputLabel htmlFor='' aria-label='Enter from date'>From</CustomInputLabel>
                </Grid>
                <Grid item xs={12} sm={6} className={styles.pt0}>
                  <CustomDatePicker
                    slotProps={{
                      textField: {
                        ...InputProps,
                        placeholder: 'From Date',
                        fullWidth: true
                      }, 
                    }}  
                    className={styles.w100}
                    onChange={(v) => props.onChangeSearch({
                      target: {
                        type: 'date',
                        name: 'from',
                        value: v.$d.toLocaleDateString().replace(/\//g, '-')
                      }
                    })}
                    format='DD MMM YYYY'
                    name='from'
                    value={dayjs(props.search.from)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTimePicker
                    slotProps={{
                      textField: {
                        ...InputProps,
                        fullWidth: true,
                        placeholder: 'From Time'
                      }, 
                    }}
                    className={styles.w100}
                    ampm={false}
                    onChange={(v) => {
                      props.onChangeSearch({
                        target: {
                          type: 'time',
                          name: 'f_time',
                          value: v ? v.format('HH:mm') : null
                        }
                      });
                    }}
                    format='HH:mm'
                    name='f_time'
                    value={dayjs(props.search.f_time || moment().format('HH:mm'), 'HH:mm')}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3} direction='row' container columnSpacing={1} rowSpacing={{
                xs: 2,
                sm: 0,
              }}>
                <Grid item xs={12}>
                  <CustomInputLabel htmlFor='' aria-label='Enter to date'>To</CustomInputLabel>
                </Grid>
                <Grid item xs={12} sm={6} className={styles.pt0}>
                  <CustomDatePicker
                    slotProps={{
                      textField: {
                        ...InputProps,
                        placeholder: 'To Date',
                        fullWidth: true
                      }, 
                    }}  
                    className={styles.w100}
                    onChange={(v) => props.onChangeSearch({
                      target: {
                        type: 'date',
                        name: 'to',
                        value: v.$d.toLocaleDateString().replace(/\//g, '-')
                      }
                    })}
                    format='DD MMM YYYY'
                    name='to'
                    value={dayjs(props.search.to)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTimePicker
                    slotProps={{
                      textField: {
                        ...InputProps,
                        fullWidth: true,
                        placeholder: 'To Time'
                      }, 
                    }}
                    className={styles.w100}
                    ampm={false}
                    onChange={(v) => {
                      props.onChangeSearch({
                        target: {
                          type: 'time',
                          name: 't_time',
                          value: v ? v.format('HH:mm') : null
                        }
                      });
                    }}
                    format='HH:mm'
                    name='t_time'
                    value={dayjs(props.search.t_time || moment().format('HH:mm'), 'HH:mm')}
                  />
                </Grid>
              </Grid>
              <Grid item xs>
                <CustomInputLabel id='airline-label' aria-label='Select airline'>Airline</CustomInputLabel>
                <CustomSelect
                  {...SelectInputProps}
                  fullWidth
                  id='airline'
                  labelId='airline-label'
                  name='airline'
                  value={props.filter.airline}
                  onChange={props.onChangefilter}
                >
                  {props.airline && props.airline.map(a => {
                    return (<MenuItem key={a} value={a} >{a}</MenuItem>);
                  })}
                </CustomSelect>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                <CustomInputLabel id='flight_number_label' aria-label='Enter flight number'>Flight Number</CustomInputLabel>
                <CustomSelect
                  fullWidth
                  {...SelectInputProps}
                  id='flight'
                  name='flight'
                  labelId='flight_number_label'
                  value={props.filter.flight}
                  onChange={props.onChangefilter}
                >
                  {props.flight && props.flight.map(f => {
                    return (<MenuItem key={f} value={f}>{f}</MenuItem>);
                  })}
                </CustomSelect>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                <CustomInputLabel id='measure_applied_label' aria-label='Enter measure applied'>Measure Applied</CustomInputLabel>
                <CustomSelect
                  {...SelectInputProps}
                  fullWidth
                  id='measure'
                  name='measure'
                  labelId='measure_applied_label'
                  value={props.filter.measure}
                  onChange={props.onChangefilter}
                >
                  {props.measure && props.measure.map(m => {
                    return (<MenuItem key={m} value={m} >{m}</MenuItem>);
                  })}
                </CustomSelect>
              </Grid>
              <Grid item display='flex' className={styles.sqIconsContainer}>
                <Search fontSize='1.7rem' classes={{ root: styles.sqIcons }} onClick={()=>props.fetchReport(false)} />
              </Grid>
            </Grid>
          </Stack>
        </StyledCard>
      </Grid>

      <Grid item xs={12}>
        <StyledCard>
          <div className={`${styles.fSpaceBtw} ${styles.mb3}`}>
            <StyledCardTitle gutterBottom variant='' component='div'>Screening Report</StyledCardTitle>
            <IconButton aria-label='download-excel' className={styles.greenSvg} onClick={props.handleExport}>
              <ExcelIcon />
            </IconButton>
          </div>
          <Box>
            <StyledTableContainer className={reportStyle.reportTable} ref={props.spanRef}>
              <Table aria-label='nested table' stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align='left'>AWB</TableCell>
                    <TableCell align='left'>T PCS</TableCell>
                    <TableCell align='left'>T WGT</TableCell>
                    <TableCell align='left'>ORG</TableCell>
                    <TableCell align='left'>DES</TableCell>
                    <TableCell align='left'>Booked Flight</TableCell>
                    <TableCell align='left'>Forwarder</TableCell>
                    <TableCell align='left'>Shipper Profile</TableCell>
                    <TableCell align='left'>Pre-Screening</TableCell>
                    <TableCell align='left'>Remarks</TableCell>
                    <TableCell align='left'>Received</TableCell>
                    <TableCell align='left'>eCSD</TableCell>
                    <TableCell align='center' className={reportStyle.headerIcon}><WhiteCameraIcon /></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.awbs && props.awbs.map(awb => {
                    return (<>
                      <TableRow>
                        <TableCell align='left' >{awb.code}-{awb.number}</TableCell>
                        <TableCell align='left'>{awb.pcs}</TableCell>
                        <TableCell align='left'>{awb.wgt}k</TableCell>
                        <TableCell align='left'>{awb.org}</TableCell>
                        <TableCell align='left'>{awb.dest}</TableCell>
                        <TableCell align='left'>{awb.flight_no}/{moment(awb.flight_date).format('DDMMMYY').toUpperCase()}</TableCell>
                        <TableCell align='left'>{awb.forwarder}</TableCell>
                        <TableCell align='left'>{awb.shipper_profile}</TableCell>
                        <TableCell align='left'>{awb.pre_screened}</TableCell>
                        <TableCell align='left'>{awb.remark}</TableCell>
                        <TableCell align='left'></TableCell>
                        <TableCell align='left'>Email</TableCell>
                        <TableCell align='center'>{awb?.docs && awb?.docs?.length > 0 && (<TableBadge color='error' badgeContent={awb?.docs?.length || 0} onClick={() => props.handlePopover(true, props.spanRef.current, awb.code + '-' + awb.number, awb.docs)} />)}</TableCell>
                      </TableRow>
                      <NestedTableCellContainer className={styles.nestedTableContainer}>
                        <TableCell style={{
                          paddingBottom: 0,
                          paddingTop: 0
                        }} colSpan={13}>
                          <Table size='small' aria-label=''>
                            <TableHead>
                              <TableRow>
                                <TableCell className={styles.minWidth135} align='left'>PCS Screened</TableCell>
                                <TableCell className={styles.minWidth145} align='left'>Measure</TableCell>
                                <TableCell className={styles.minWidth205} align='left'>Method</TableCell>
                                <TableCell align='left'>Screened For Flight</TableCell>
                                <TableCell align='left'>Screener</TableCell>
                                <TableCell align='left'>Screening Date/Time</TableCell>
                                <TableCell align='left'>Alarm?</TableCell>
                                <TableCell align='left'>Resolution</TableCell>
                                <TableCell align='left'>Resolved?</TableCell>
                                <TableCell align='left'>Remarks</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {awb.screens && awb.screens.map(screen => (
                                <TableRow key={screen.id} >
                                  <TableCell className={styles.minWidth135} align='left'>{screen.pcs}</TableCell>
                                  <TableCell className={styles.minWidth145} align='left'>{screen.measure.type}</TableCell>
                                  <TableCell className={styles.minWidth205} align='left'>{screen.method.name}</TableCell>
                                  <TableCell align='left'>{screen.flight_no}/{moment(screen.flight_date).format('DDMMMYY').toUpperCase()}</TableCell>
                                  <TableCell align='left'>{screen.user ? screen.user.first_name + ' ' + screen.user.last_name : screen?.k9_company?.name}</TableCell>
                                  <TableCell align='left'>{moment(screen.screen_date).format('DDMMMYY').toUpperCase()} {screen.screen_time}</TableCell>
                                  <TableCell align='left'>{screen.alarmed ? 'Yes' : 'No'}</TableCell>
                                  <TableCell align='left'>{screen?.alarm?.name}</TableCell>
                                  <TableCell align='left'>{screen.resolved}</TableCell>
                                  <TableCell align='left'>{screen.remark}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableCell>
                      </NestedTableCellContainer>
                    </>);
                  })}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </Box>
        </StyledCard>
        <Attachment
          handlePopover={props.handlePopover}
          anchorEl={props.anchorEl}
          attachment={props.attachment}
          spanRef={props.spanRef}
          awb={props.awb}
          docs={props.docs}
        />
      </Grid>
    </Grid>
  );
};

export default ScreeningReport;
