import React from 'react';
import styles from '../../../custom/style.module.css';
import awbStyles from './awbsearch.module.css';
import { CustomSearch, DataGridProps, InputProps, StyledCard, StyledCardTitle, StyledDataGrid } from 'custom/components';
import { Box, IconButton, InputAdornment, Stack } from '@mui/material';
import { AttachFile, EditNote, Search } from '@mui/icons-material';
import { dateTolocale } from 'utils/converter';
import { DateTimeFormat } from 'custom/constants';

const AwbSearch = (props) => {

  const columns = [
    {
      field: 'date',
      headerName: 'Date',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => dateTolocale(row.date, DateTimeFormat)
    },
    {
      field: 'type',
      headerName: 'Type',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        let textColor = 'grey';
        switch (row.type) {
          case 'Document Added':
            textColor = 'blue';
            break;
          case 'Document Scanned':
            textColor = 'orange';
            break;
          case 'Document Verified':
            textColor = 'green';
            break;
          case 'FFM':
            textColor = 'red';
            break;
          default:
            textColor = 'grey';
        }
        return (
          <>
            <div><span className={`${awbStyles.dot} ${awbStyles[textColor]}`}></span>{row.type}</div>
          </>
        );
      }
    },
    {
      field: 'data',
      headerName: 'Data',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      renderCell: ({ row }) => {
        return (
          <div>
            {row.data && Object.keys(row.data).map((key, index) => {
              return (
                <div key={index}>
                  <div className={`${awbStyles.dataLabel} ${awbStyles.capsule}`}>{key} </div> 
                  <div className={awbStyles.data}> ------ &nbsp; &nbsp; &nbsp; &nbsp;{row.data[key]}</div>
                </div>
              );

            })}
            {!row.data && '-'}
          </div>
        );
      }
    },
    {
      field: 'user',
      headerName: 'User',
      minWidth: 100,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) => {
        return (
          <>
            {row.user && row?.user?.first_name + ' ' + row?.user?.last_name}
            {row.trypod && row.trypod?.name + ' (' + row.trypod?.company+ ')'}
          </>
        );
      }

    },
    {
      field: 'attachment',
      headerName: 'Attachment',
      minWidth: 100,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      // disableColumnMenu: true,
      renderCell: ({ row }) => {
        if (row.attachment) {
          return (
            <Stack direction='row' spacing={2} justifyContent='center'>
              <div onClick={() => props.handlePopover('attached_docs', true, row.docs)}>
                <AttachFile fontSize='small' className={`${styles.pointer}`} />
              </div>
            </Stack>);
        } else {
          return (<>-</>);
        }
      }
    }
  ];


  return (
    <div>
      <div
        className={`${styles.headerFieldContainer} ${styles.headerWrapper}`}>
        <CustomSearch
          {...InputProps}
          id='awb'
          placeholder='Enter Awb Number'
          type='search'
          fullWidth
          InputProps={{
            disableUnderline: true,
            endAdornment: <InputAdornment position='end'>
              <IconButton
                size='small'
                aria-label='search'
                onClick={() => props.onSearch(true)}
                edge='end'
              >
                <Search fontSize='inherit' />
              </IconButton>
            </InputAdornment>,
          }}
          InputLabelProps={{ shrink: false }}
          onChange={props.onChange}
          name='awb'
          value={props?.search?.awb}
          className={`${styles.brInput}`}
        // error={}
        // helperText={}
        />
      </div>
      <StyledCard>
        <Box className={`${styles.fSpaceBtw} ${styles.mb2}`} >
          <StyledCardTitle className={styles.pb1}>History</StyledCardTitle>
          <IconButton
            size='large'
            aria-label='add note'
            onClick={props.openNotes}
            className={awbStyles.addNote}>
            <EditNote fontSize='inherit' color='primary' />
          </IconButton>
        </Box>
        <div className={styles.tableWrapper}>
          <Box className={`${styles.tableOuter} ${awbStyles.awbTable}`}>
            <StyledDataGrid
              {...DataGridProps}
              rows={props.history}
              columns={columns}
              getRowId={(row) => row.date}
              param={props.history.length}
              getRowHeight={() => '150px'} 
              getEstimatedRowHeight={() => 200}
              rowHeight={120}
            />
          </Box>
        </div>
      </StyledCard>
    </div>
  );
};

export default AwbSearch;