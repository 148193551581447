import React from 'react';
import Developer from './Developer';
import http from 'utils/http';

class DeveloperContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      configFields: {},
      config: [],
      stations:[],
      userList: [],
    };
  }

  componentDidMount() {
    // Add the necessary logic here
    this.getConfig();
    this.getStation();
    this.fetchUsers();
  }

  getConfig = () => {
    http.get('/settings').then(({ data }) => {
      this.setState({ config: data.data });
    }
    );
  };

  getStation = () => {
    http.get('/station/all').then(({ data }) => {
      let stations = data.data?.map((s) => s.code);
      stations = stations.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      this.setState({ stations });
    }
    );
  };

  fetchUsers = () => {
    http.get('/user').then((response) => {
      if(response.data.status === 'success') {
        let userList = response.data.data.filter((user) => user.ismanagec === true );
        console.log(userList);
        this.setState({ userList });
      }
    });
  };

  onChange = (event) => {
    const { name, checked } = event.target;
    http.put('/setting/' + name, { value: checked });
  };

  onChangeStation = (event) => {
    const { name, checked } = event.target;
    http.post('/setting/flight_api', {
      name,
      value: checked 
    });
  };

  render() {
    return (
      <Developer 
        config={this.state.config} 
        onChange={this.onChange} 
        stations={this.state.stations} 
        onChangeStation={this.onChangeStation}
        userList={this.state.userList} />
    );
  }
}

export default DeveloperContainer;