import React from 'react';
import { Dialog, FormControl, FormHelperText, MenuItem } from '@mui/material';
import styles from '../../../../../custom/style.module.css';
import { CustomButton, CustomTextField, CustomDialogTitle, CustomDialogAction, CustomInputLabel, StyledCard, InputProps, CustomSelect, CustomDialogContent, SelectInputProps } from '../../../../../custom/components';
import { CloseIconBtn, contractType, checklistRoles } from 'custom/constants';

const AddChecklistModal = (props) => {
  const { action, addChecklist, editChecklist, onChangeChecklist } = props;
  const fltReportType = [ 'Inbound Flight Report', 'Outbound Flight Report', 'Flight Report'];
  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={props.modal === 'checklist'} onClose={props.onClose} maxWidth={'sm'} fullWidth>
        <CustomDialogTitle id='form-dialog-title'>
          {action == 'add' ? 'Add' : 'Edit'} Checklist
          <CloseIconBtn onClick={props.onClose} />
        </CustomDialogTitle>
        <form className={styles.w100} onSubmit={action == 'add' ? addChecklist : editChecklist} onReset={props.onClose}>
          <CustomDialogContent>
            <StyledCard>
              <div>
                <CustomInputLabel htmlFor='description'>Checklist Description</CustomInputLabel>
                <CustomTextField
                  {...InputProps}
                  fullWidth
                  autoFocus
                  id='description'
                  className={`${styles.mb3}`}
                  placeholder='Checklist'
                  type='text'
                  name='description'
                  onChange={onChangeChecklist}
                  value={props.checklistField.description || ''}
                  error={props.checklistErrors.description}
                  helperText={props.checklistErrors.description}
                />
              </div>
              <div>
                <CustomInputLabel id='employee_group_label' aria-label='Employee-group'>To be entered by (Employee Group)</CustomInputLabel>
                <FormControl fullWidth error={props.checklistErrors.role}>
                  <CustomSelect
                    {...SelectInputProps}
                    id='role'
                    labelId='employee_group_label'
                    fullWidth
                    onChange={onChangeChecklist}
                    name='role'
                    className={`${styles.mb3}`}
                    value={!props.checklistField.role ? 'placeholder' : props.checklistField.role}
                    classes={{ select: !props.checklistField.role ? styles.colorPlaceholder : '' }}
                  >
                    <MenuItem value='placeholder' disabled={true}>Select a Role</MenuItem>
                    {checklistRoles.map((role, i) => (
                      <MenuItem key={i} value={role.value}>
                        {role.value}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                  <FormHelperText>{props.checklistErrors.role}</FormHelperText>
                </FormControl>
              </div>
              <div>
                <CustomInputLabel id='report_type_label'>Inbound/Outbound Flight Report</CustomInputLabel>
                <FormControl fullWidth error={props.checklistErrors.report_type}>
                  <CustomSelect
                    {...SelectInputProps}
                    id='report_type'
                    labelId='report_type_label'
                    fullWidth
                    onChange={onChangeChecklist}
                    name='report_type'
                    className={`${styles.mb3}`}
                    value={!props.checklistField.report_type ? 'placeholder' : props.checklistField.report_type}
                    classes={{ select: !props.checklistField.report_type ? styles.colorPlaceholder : '' }}
                  >
                    <MenuItem value='placeholder' disabled={true}>Select a type</MenuItem>
                    <MenuItem value={'All'}>All</MenuItem>
                    {fltReportType.map((type, i) => (
                      <MenuItem key={i} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                  <FormHelperText>{props.checklistErrors.report_type}</FormHelperText>
                </FormControl>
              </div>
              <div>
                <CustomInputLabel id='contract_types'>Department</CustomInputLabel>
                <FormControl fullWidth>
                  <CustomSelect
                    {...SelectInputProps}
                    fullWidth
                    id='contract_types'
                    labelId='contract_types'
                    name='contract_types'
                    onChange={onChangeChecklist}
                    value={!props.checklistField.contract_types ? 'placeholder' : props.checklistField.contract_types}
                    classes={{ select: !props.checklistField.contract_types ? styles.colorPlaceholder : '' }}
                  >
                    <MenuItem value='placeholder' disabled>Select Department</MenuItem>
                    <MenuItem value={contractType.cargo_handling}>{contractType.cargo_handling}</MenuItem>
                    <MenuItem value={contractType.flight_ops}>{contractType.flight_ops}</MenuItem>
                    <MenuItem value={contractType.drayage}>{contractType.drayage}</MenuItem>
                  </CustomSelect>
                </FormControl>
              </div>
            </StyledCard>

          </CustomDialogContent>
          <CustomDialogAction className={`${styles.fSpaceBtw}`}>
            <CustomButton variant='contained' type='reset'> Close </CustomButton>
            <CustomButton variant='contained' type='submit'>{ action == 'add' ? 'Submit' : 'Update'}</CustomButton>
          </CustomDialogAction>
        </form>
      </Dialog>
    </div>
  );
};
export default AddChecklistModal;