import React from 'react';
import { Box, Grid, Stack } from '@mui/material';
import styles from '../../../custom/style.module.css';
import { DataGridProps, StyledCard, StyledCardTitle, StyledDataGrid } from 'custom/components';
import { ExcelIcon } from 'custom/constants';
import { CsvToExcel } from 'custom/export';


const FfmDashboard = (props) => {

  const apiRef = React.useRef(null);

  const columns = [
    {
      field: 'airline',
      headerName: 'Airline',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'flight',
      headerName: 'Flight/TRK',
      type: 'text',
      minWidth: 80,
      flex: 1,
    },
    {
      field: 'seg',
      headerName: 'Segment',
      type: 'text',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'source',
      headerName: 'Sender Mail ID',
      type: 'text',
      minWidth: 60,
      flex: 1,
    },
    {
      field: 'created',
      headerName: 'Last FFM Received',
      type: 'text',
      minWidth: 60,
      flex: 1,
    }
  ];

  const handleExport = async () => {
    const csvData = await apiRef.current.getDataAsCsv();
    CsvToExcel(csvData, 'FFM');
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <StyledCard className={styles.positionRelative}>
          <div className={`${styles.fSpaceBtw} ${styles.mb3}`}>
            <StyledCardTitle gutterBottom variant='' component='div' className={styles.pb0}>FFM Dashboard</StyledCardTitle>
            <Stack direction={'row'} spacing={2}>
              <ExcelIcon title='Download File' onClick={handleExport} className={`${styles.tableIcon}`} />
            </Stack>
          </div>
          <div className={styles.tableWrapper}>
            <Box className={`${styles.tableOuter}`}>
              <StyledDataGrid
                apiRef={apiRef}
                {...DataGridProps}
                rows={props.data}
                columns={columns}
                param={props.data?.length}
              />
            </Box>
          </div>
        </StyledCard>
      </Grid>
    </Grid>
  );
};

export default FfmDashboard;