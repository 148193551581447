import React from 'react';
import { Box, Typography, TextField, Stack, InputAdornment } from '@mui/material';
import Logo from '../../../../assets/images/orgaLogoFull.png';
import { CustomButton } from '../../../../custom/components';
import loginStyles from './../login.module.css';
import styles from '../../../../custom/style.module.css';
import { Apartment, PersonRounded } from '@mui/icons-material';
import theme from 'custom/theme';
import { appName } from 'custom/constants';
import { loaderRef } from 'components/header/loader';

const ResetPassword = (props) => {
  return (
    <Box className={loginStyles.loginWrapper}>
      <div ref={loaderRef}></div>
      <img src={Logo} alt='logo' className={`${loginStyles.loginLogo} ${styles.mb3}`} />
      <Stack spacing={3} alignItems='center' className={`${styles.w100} ${styles.mb4}`}>
        <Typography fontSize={'1.8rem'} component='span' lineHeight='normal' fontWeight={500}
          className={`${loginStyles.appName} ${loginStyles.loginHeader} ${styles.mb3}`} color='primary'>
          Welcome to {appName}
        </Typography>
        <Typography variant='body1' component='span' lineHeight='normal' fontWeight={'bold'} letterSpacing={'0.4px'}
          className={`${loginStyles.loginHeader} ${styles.mb3}`} color={theme.palette.grey[700]}>
          Reset Password
        </Typography>

        {/* <div className={loginStyles.passwordDescription}>
          <p><b>This is your first login and you must change your default password </b></p>
        </div> */}
        {/* <Typography variant='h6' component='span' lineHeight='normal'>LOGIN</Typography> */}
      </Stack>

      <form className={styles.w100}>
        <Stack spacing={5} alignItems='center'>
          <TextField
            hiddenLabel
            id='Company'
            variant='standard'
            className={styles.w100}
            placeholder='Company'
            type='text'
            size='small'
            InputLabelProps={{ shrink: false }}
            classes={{ root: loginStyles.textfield }}
            onChange={props.onChange}
            name='company'
            value={props.fields.company}
            disabled={props.company}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Apartment color='primary' className={loginStyles.materialIcon} />
                </InputAdornment>
              ),
            }}
            error={props.errors.company}
            helperText={props.errors.company}
          />
          <TextField
            hiddenLabel
            id='Username'
            variant='standard'
            className={styles.w100}
            placeholder='Username / Email'
            type='text'
            size='small'
            InputLabelProps={{ shrink: false }}
            classes={{ root: loginStyles.textfield }}
            onChange={props.onChange}
            name='username'
            value={props.fields.username}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <PersonRounded color='primary' className={loginStyles.materialIcon} />
                </InputAdornment>
              ),
            }}
            error={props.errors.username}
            helperText={props.errors.username}
          />
          <CustomButton variant='contained' className={`${styles.w100} ${loginStyles.submitBtn}`} onClick={props.onSubmit} disabled={props.submit} >Submit</CustomButton>
        </Stack>
      </form>
    </Box>
  );
};

export default ResetPassword;