import { DELETE_AWB, DELETE_ULD, SET_AWB, SET_FFM, SET_ULD } from '../actions/actionTypes';

const initialState = {};

const updateUld = (state, action) => {
  if (state && state.id && state.id === action.data.flightId) {
    let ulds = [...state.flight_uld || []];
    let index = ulds.findIndex(uld => uld.id === action.data.id);
    if (index !== -1) {
      ulds[index] = {
        ...ulds[index],
        ...action.data
      };
    } else {
      ulds = [action.data, ...ulds];
    }
    return {
      ...state,
      flight_uld: ulds
    };
  } else {
    return state;
  }
};
const deleteUld = (state, action) => {
  if (state && state.id && state.id === action.data.flightId) {
    let ulds = [...state.flight_uld || []];
    let index = ulds.findIndex(uld => uld.id === action.data.id);
    if (index !== -1) {
      ulds.splice(index, 1);
    }
    return {
      ...state,
      flight_uld: ulds
    };
  } else {
    return state;
  }
};

const updateAwb = (state, action) => {
  if (state && state.id && state.id === action.data.flightId) {
    let ulds = [...state.flight_uld];
    let uld_index = ulds.findIndex(uld => uld.id === action.data.flight_uldId);
    if (uld_index !== -1) {
      let awbs = [...ulds[uld_index].flight_awbs || []];
      let awb_index = awbs.findIndex(awb => awb.id === action.data.id);
      if (awb_index !== -1) {
        awbs[awb_index] = {
          ...awbs[awb_index],
          ...action.data,
          awb: { ...(action.data.awb ? action.data.awb : ulds[uld_index].flight_awbs[awb_index].awb) || {} },
          product: { ...(action.data.product ? action.data.product : ulds[uld_index].flight_awbs[awb_index].product) || {} }
        };
      } else {
        awbs.push(action.data);
      }
      ulds[uld_index] = {
        ...ulds[uld_index],
        flight_awbs: [...awbs]
      };
    }
    return {
      ...state,
      flight_uld: ulds
    };
  }
  else {
    return state;
  }
};

const deleteAwb = (state, action) => {
  if (state && state.id && state.id === action.data.flightId) {
    let ulds = [...state.flight_uld || []];
    let uld_index = ulds.findIndex(uld => uld.id === action.data.flight_uldId);
    if (uld_index !== -1) {
      let awbs = [...ulds[uld_index].flight_awbs || []];
      let awb_index = awbs.findIndex(awb => awb.id === action.data.id);
      if (awb_index !== -1) {
        awbs.splice(awb_index, 1);
      }
      ulds[uld_index] = {
        ...ulds[uld_index],
        flight_awbs: [...awbs]
      };
    }
    return {
      ...state,
      flight_uld: ulds
    };
  }
  else {
    return state;
  }
};

export const ffmReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FFM:
      return { ...action.data };
    case SET_ULD:
      return updateUld(state, action);
    case SET_AWB:
      return updateAwb(state, action);
    case DELETE_ULD:
      return deleteUld(state, action);
    case DELETE_AWB:
      return deleteAwb(state, action);
    default:
      return state;
  }
};