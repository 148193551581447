import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import cookies from 'utils/cookies';

// const borderColor = '#90e5fc';
const InvoiceTableFooter = ({ items, borderColor, billingFields, localeObj }) => {
  const styles = StyleSheet.create({
    row: {
      flexDirection: 'row',
      borderBottomColor: borderColor,
      borderBottomWidth: 1,
      alignItems: 'center',
      height: 30,
      fontSize: 10,
    },
    description: {
      width: '80%',
      textAlign: 'right',
      // borderRightColor: borderColor,
      // borderRightWidth: 1,
      paddingRight: 8,
    },
    total: {
      width: '20%',
      fontSize: 10,
      // fontFamily: 'Open Sans',
      fontWeight: 600, 
      paddingRight: 8,
      paddingLeft: 14,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',

    },
  });

  // const total = items.map(item => /*item.quantity * item.rate*/ item.totalrate)
  //   .reduce((accumulator, currentValue) => accumulator + currentValue , 0);
  const total = items
    .map(item => {
      let total = 0;
      if(billingFields?.exclude_disc){
        if(billingFields?.transit === 'none' || !billingFields?.transit){
          // Check if totalrate is valid numbers
          const isValid = !isNaN(item.totalrate);
          // If any value is not a valid number, return 0
          if (!isValid) {
            total = 0;
          } else{
            total = item?.totalrate;
          }
        } else if(billingFields?.transit === 'inbound'){
          // Check if totalrate is valid numbers
          const isValid = !isNaN(item.in_totalrate);
          // If any value is not a valid number, return 0
          if (!isValid) {
            total = 0;
          } else{
            total = item?.in_totalrate;
          }
        } else {
          // Check if totalrate is valid numbers
          const isValid = !isNaN(item.out_totalrate);
          // If any value is not a valid number, return 0
          if (!isValid) {
            total = 0;
          } else{
            total = item?.out_totalrate;
          }
        }
      } else {
        if(billingFields?.transit === 'none' || !billingFields?.transit){
          // Check if totalrate is valid numbers
          const isValid = !isNaN(item.dis_totalrate);
          // If any value is not a valid number, return 0
          if (!isValid) {
            total = 0;
          } else{
            total = item?.dis_totalrate;
          }
        } else if(billingFields?.transit === 'inbound'){
          // Check if totalrate is valid numbers
          const isValid = !isNaN(item.dis_in_totalrate);
          // If any value is not a valid number, return 0
          if (!isValid) {
            total = 0;
          } else{
            total = item?.dis_in_totalrate;
          }
        } else {
          // Check if totalrate is valid numbers
          const isValid = !isNaN(item.dis_out_totalrate);
          // If any value is not a valid number, return 0
          if (!isValid) {
            total = 0;
          } else{
            total = item?.dis_out_totalrate;
          }
        }
      }
      return total;
    })
    // Use reduce to sum the calculated values
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);


  return(    
    <View style={styles.row}>
      <Text style={styles.description}>TOTAL</Text>
      <View style={styles.total}>
        <Text>{ cookies.get('currency') }</Text> 
        <Text>{ Number.parseFloat(total).toLocaleString(localeObj.locale, localeObj.options)}</Text> 
      </View>
    </View>
  );
};
  
export default InvoiceTableFooter;