import { combineReducers } from 'redux';
import { companyReducer } from './companyReducer';
import { deleteReducer } from './deleteReducer';
import { docReducer } from './docReducer ';
import { ffmReducer } from './ffmReducer';
import { flightReducer } from './flightReducer';
import { flightInfoReducer } from './flightInfoReducer';


export const rootReducer = combineReducers({
  stations: companyReducer,
  delete: deleteReducer,
  doc: docReducer,
  ffm: ffmReducer,
  flight: flightReducer,
  flightInfo: flightInfoReducer
});