import { SET_FLIGHT_INFO } from './actionTypes';

function setFlightInfo(data) {
  return {
    type: SET_FLIGHT_INFO,
    data
  };
}

function setFlightInfoAction(data) {
  return (dispatch) => {
    dispatch(setFlightInfo(data));
  };
}


export { setFlightInfoAction };