import React from 'react';
import ResetPassword from './ResetPassword';
import auth from 'utils/auth';
import http from 'utils/http';
import environments from 'custom/environments';
class ResetPasswordContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        username: '',
        company: environments.company ? environments.company : '',
      },
      errors: {},
      company: environments.company ? true : false,
    };
  }

  componentDidMount() {
    if (auth()) {
      this.props.history.push('/');
    }
  }

  onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      fields: {
        ...this.state.fields,
        [name]: value
      }
    });
  };

  validation = () => {

    const fields = { ...this.state.fields };
    const errors = {
      company: false,
      username: false,
    };
    let formIsValid = true;

    if (!fields.company || (fields.company).trim().length === 0) {
      formIsValid = false;
      errors.company = 'Please enter your company.';
    }

    if (!fields.username || (fields.username).trim().length === 0) {
      formIsValid = false;
      errors.username = 'Please enter your username.';
    }

    this.setState({ errors: errors });

    return formIsValid;
  };

  onSubmit = async () => {
    let formdata = { ...this.state.fields, };
    if (this.validation()) {
      this.setState({ submit: true });
      await http.post('/user/reset_password', formdata);
    }
  };

  render() {
    return (
      <ResetPassword
        company={this.state.company}
        fields={this.state.fields}
        errors={this.state.errors}
        submit={this.state.submit}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default ResetPasswordContainer;
// export default withRouter(ChangeDefaultPasswordContainer);