import React from 'react';
import { Stack } from '@mui/material';
import { CustomInputLabel, CustomDatePicker, InputProps, } from '../../../custom/components';
import styles from '../../../custom/style.module.css';
// import analyticsStyle from './analytics.module.css';
import theme from 'custom/theme';import dayjs from 'dayjs';
import moment from 'moment';

const DateFilter = (props) => {
  return (
    <Stack direction='row' spacing={3} justifyContent='center' sx={{ marginBottom: theme.spacing(2) }} className={styles.headerFieldContainer}>
      <Stack direction='row' alignItems='center'>
        <CustomInputLabel htmlFor='' aria-label='Enter Select ' className={`${styles.ipLabel}`}>From</CustomInputLabel>
        <CustomDatePicker
          slotProps={{
            textField: {
              ...InputProps,
              placeholder: 'From Date',
              fullWidth: true
            }, 
          }}  
          format='DD MMM YYYY'
          className={`${styles.brInput} ${styles.w100} ${styles.minWidth125}`}
          onChange={(v) => props.handleDateFilter(
            {
              target: {
                type: 'date',
                name: 'from_date',
                value: moment(v.$d).format('YYYY-MM-DD')
              }
            }, 'from')}
          value={dayjs(moment(props.filterDate.from_date).format('YYYY-MM-DD'))}
        />
      </Stack>

      <Stack direction='row' alignItems='center'>
        <CustomInputLabel htmlFor='' aria-label='Enter Select ' className={styles.ipLabel}>To</CustomInputLabel>
        <CustomDatePicker
          slotProps={{
            textField: {
              ...InputProps,
              placeholder: 'To Date',
              fullWidth: true
            }, 
          }}  
          format='DD MMM YYYY'
          className={`${styles.brInput} ${styles.w100} ${styles.minWidth125}`}
          onChange={(v) => props.handleDateFilter({
            target: {
              type: 'date',
              name: 'to_date',
              value: moment(v.$d).format('YYYY-MM-DD')
            }
          }, 'to')}
          value={dayjs(moment(props.filterDate.to).format('YYYY-MM-DD'))}
        />
      </Stack>
    </Stack>
  );
};

export default DateFilter;
