import React from 'react';
import { Dialog, DialogContent, IconButton, Grid, InputAdornment, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import styles from '../../../../custom/style.module.css';
import docStyles from './doc.module.css'; 
import admin from '../../admin/tabs/admin.module.css';
import { CustomButton, CustomTextField, CustomDialogTitle, CustomDialogAction, CustomInputLabel, StyledCard, CustomDatePicker, InputProps } from '../../../../custom/components';
import { BrowseIcon, CloseIconBtn } from 'custom/constants';

const AddDocModal = (props) => {
  const { action, modal, onClose, userFields, userErrors, addUser, editUser, onChangeUser, k9Fields, k9Errors, addK9, editK9, onChangeK9 } = props;
  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={modal} onClose={onClose}  maxWidth={'sm'} fullWidth>
        <CustomDialogTitle id='form-dialog-title'>
          {action == 'add' ? 'Add New' : 'Edit'}  {modal == 'user' ? 'Training' : 'Attachment'}
          <CloseIconBtn onClick={onClose} />
        </CustomDialogTitle>
        {modal == 'user' && (
          <form className={styles.w100} onSubmit={action == 'add' ? addUser : editUser}>
            <DialogContent>
              <StyledCard>
                <Grid container spacing={2}>
                  <Grid item xs={12} >
                    <CustomInputLabel htmlFor='methods'>Training</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      fullWidth
                      id='training'
                      placeholder='Training'
                      type='text'
                      name='name'
                      className={`${styles.w100}`}
                      onChange={onChangeUser}
                      value={userFields.name}
                      InputProps={{
                        // disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position='end'>
                            <Tooltip title={userFields?.file?.name}>
                              <span className={docStyles.fileName}>{userFields?.file?.name}</span>
                            </Tooltip>
                            {action == 'add' && (
                              <IconButton size='small'>
                                <input
                                  accept='application/pdf,image/*'
                                  style={{ display: 'none' }}
                                  id='user-file'
                                  type='file'
                                  name='file'
                                  onChange={onChangeUser}
                                />
                                <label htmlFor='user-file' className={`${styles.fs0} ${styles.pointer}`}>
                                  <BrowseIcon />
                                </label>
                              </IconButton> 
                            )}
                          </InputAdornment>
                        )
                      }}
                      error={userErrors.name}
                      helperText={userErrors.name}
                    />

                  </Grid>
                  <Grid item xs={6}>
                    <CustomInputLabel htmlFor='code'>Training Date</CustomInputLabel>
                    <CustomDatePicker 
                      slotProps={{
                        textField: {
                          ...InputProps,
                          placeholder: 'Training Date' 
                        }
                      }}
                      onChange={(v) => onChangeUser({
                        target: {
                          type: 'date',
                          name: 'training_date',
                          value: v.$d.toLocaleDateString().replace(/\//g, '-')
                        }
                      })}
                      variant='standard'
                      // disableUnderline
                      format='DD MMM YYYY'
                      name='training_date'
                      className={`${styles.w100}`}
                      value={dayjs(userFields.training_date)}
                      error={userErrors.training_date}
                      helperText={userErrors.training_date}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomInputLabel htmlFor='code'>Expiry Date</CustomInputLabel>
                    <CustomDatePicker
                      slotProps={{
                        textField: {
                          ...InputProps,
                          placeholder: 'Expiry Date' 
                        } 
                      }}
                      onChange={(v) => onChangeUser({
                        target: {
                          type: 'date',
                          name: 'expiry_date',
                          value: v.$d.toLocaleDateString().replace(/\//g, '-')
                        }
                      })}
                      format='DD MMM YYYY'
                      name='expiry_date'
                      className={`${styles.w100}`}
                      value={dayjs(userFields.expiry_date)}
                      error={userErrors.expiry_date}
                      helperText={userErrors.expiry_date}
                    />
                  </Grid>
                </Grid>
              </StyledCard>
            </DialogContent>
            <CustomDialogAction className={`${styles.fSpaceBtw}`}>
              <CustomButton variant='contained' type='reset' onClick={props.onClose}> Close </CustomButton>
              <CustomButton variant='contained' type='submit'>{action == 'add' ? 'Submit' : 'Update'}</CustomButton>
            </CustomDialogAction>
          </form>
        )}
        {modal == 'k9' && (
          <form className={styles.w100} onSubmit={action == 'add' ? addK9 : editK9}>
            <DialogContent className={admin.inputForm}>
              <StyledCard>
                <Grid container spacing={2}>
                  <Grid item xs={12} >
                    <CustomInputLabel htmlFor='methods'>Name</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      fullWidth
                      id='name'
                      placeholder='Name'
                      type='text'
                      name='name'
                      onChange={onChangeK9}
                      value={k9Fields.name}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <Tooltip title={k9Errors?.file?.name}>
                              <span className={docStyles.fileName}>{k9Errors?.file?.name}</span>
                            </Tooltip>
                            {action == 'add' && (
                              <IconButton size='small'>
                                <input
                                  accept='application/pdf,image/*'
                                  style={{ display: 'none' }}
                                  id='k9-file'
                                  type='file'
                                  name='file'
                                  onChange={onChangeK9}
                                />
                                <label htmlFor='k9-file' className={`${styles.fs0} ${styles.pointer}`}>
                                  <BrowseIcon />
                                </label>
                              </IconButton> 
                            )}
                          </InputAdornment>
                        )
                      }}
                      error={k9Errors.name}
                      helperText={k9Errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} >
                    <CustomInputLabel htmlFor='methods'>Remark</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      fullWidth
                      id='remark'
                      placeholder='Remark'
                      type='text'
                      name='remark'
                      onChange={onChangeK9}
                      value={k9Fields.remark}
                      error={k9Errors.remark}
                      helperText={k9Errors.remark}
                    />
                  </Grid>
                </Grid>
              </StyledCard>
            </DialogContent>
            <CustomDialogAction className={`${styles.fSpaceBtw}`}>
              <CustomButton variant='contained' type='reset' onClick={props.onClose}> Close </CustomButton>
              <CustomButton variant='contained' type='submit'>{action == 'add' ? 'Submit' : 'Update'}</CustomButton>
            </CustomDialogAction>
          </form>
        )}
      </Dialog>
    </div>
  );
};
export default AddDocModal;