import React from 'react';
import { Stack, Grid, Box, IconButton } from '@mui/material';
import { StyledCard, StyledCardTitle, CustomInputLabel, CustomDatePicker, StyledDataGrid, DataGridProps, InputProps, } from '../../../../../custom/components';
import styles from '../../../../../custom/style.module.css';
import docStyles from './otherdoc.module.css';
import { AttachFile as AttachFileIcon, Search } from '@mui/icons-material';
import dayjs from 'dayjs';
import { DateFormat, MonthFormat } from 'custom/constants';

const ServiceLog = (props) => {
  const columns = [
    {
      field: 'type',
      headerName: 'Type',
      type: 'text',
      minWidth: 80,
      flex: 1,
      renderCell: ({ row }) => {
        return row?.service_log.type;
      }
    },
    {
      field: 'code',
      headerName: 'Airline',
      type: 'text',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return row?.service_log?.airline?.code;
      }
    },
    {
      field: 'date',
      headerName: 'Date',
      type: 'date',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return row?.service_log?.type == 'DAY'? dayjs(row?.service_log.date).format(DateFormat).toUpperCase(): dayjs(row?.service_log.date).format(MonthFormat).toUpperCase();
      }
    },
    {
      field: 'action',
      headerName: 'Actions',
      align: 'center',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      width: 90,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
            <div onClick={() => props.handlePopover('attached_docs', true, row)}>
              <AttachFileIcon fontSize='small' className={`${styles.pointer} ${docStyles.attIcon}`} />
            </div>
          </Stack>
        );
      }
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <StyledCard>
          <StyledCardTitle gutterBottom variant='' component='div'>Filter Service Logs Documents List</StyledCardTitle>
          <Stack direction='row'>
            <Grid container spacing={2} columnSpacing={4}>
              <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                <CustomInputLabel htmlFor='' aria-label='Enter from date'>From</CustomInputLabel>
                <CustomDatePicker
                  slotProps={{
                    textField: {
                      ...InputProps,
                      placeholder: 'From Date',
                      fullWidth: true
                    },
                  }}
                  className={styles.w100}
                  format='DD MMM YYYY'
                  name='from'
                  value={dayjs(props.searchOptions.from)}
                  // helperText={}
                  onChange={(e) => props.handleChange(e, 'from')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
                <CustomInputLabel htmlFor='' aria-label='Enter to date '>To</CustomInputLabel>
                <CustomDatePicker
                  slotProps={{
                    textField: {
                      ...InputProps,
                      placeholder: 'To Date',
                      fullWidth: true
                    },
                  }}
                  className={styles.w100}
                  format='DD MMM YYYY'
                  name='to'
                  value={dayjs(props.searchOptions.to)}
                  // helperText={}
                  onChange={(e) => props.handleChange(e, 'to')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={8} className={styles.sqIconsContainer}>
                <CustomInputLabel className={styles.colorTransparent}>-</CustomInputLabel>
                <IconButton disableRipple onClick={() => props.search()} className={styles.p0}>
                  <Search fontSize='1.7rem' classes={{ root: `${styles.sqIcons} ${styles.mb0}` }} />
                </IconButton>
              </Grid>
            </Grid>
          </Stack>
        </StyledCard>
      </Grid>

      <Grid item xs={12}>
        <StyledCard className={styles.positionRelative}>
          <div className={`${styles.fSpaceBtw} ${styles.mb3}`}>
            <StyledCardTitle gutterBottom variant='' component='div' className={styles.pb0}>Service Logs Documents</StyledCardTitle>
            {/* <CustomSearch
              id='search'
              name='awb'
              value={props.filter.awb}
              variant='outlined'
              size='small'
              placeholder='Enter Name'
              onChange={props.onChangefilter}
              type='search'
              className={styles.brInput}
              InputProps={{
                endAdornment: <InputAdornment position='end'>
                  <IconButton
                    size='small'
                    aria-label='search'
                    edge='end'
                    onClick={() => props.onChangefilter({
                      target: {
                        name: 'awb_search',
                        value: '' 
                      } 
                    })}
                  >
                    <Search fontSize='inherit' />
                  </IconButton>
                </InputAdornment>,
              }}
              InputLabelProps={{ shrink: false }}
            /> */}
          </div>
          <div className={styles.tableWrapper}>
            <Box className={`${styles.tableOuter} ${docStyles.docTableOuter}`}>
              <StyledDataGrid
                {...DataGridProps}
                rows={props.data}
                columns={columns}
                param={props.data?.length}
              />
            </Box>
          </div>
        </StyledCard>
      </Grid>

    </Grid>
  );
};

export default ServiceLog;
