import { SET_FLIGHT_INFO } from '../actions/actionTypes';

const initialState = {};

export const flightInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FLIGHT_INFO:
      return { ...action.data };
    default:
      return state;
  }
};