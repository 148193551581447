import React from 'react';
import {
  FormControl,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  CustomButton,
  CustomDatePicker,
  CustomSelect,
  CustomTextField,
  InputProps,
  SelectInputProps
} from '../../../.././custom/components';
import styles from '../../../../custom/style.module.css';
import docStyles from '../docs.module.css';
import { Stack } from '@mui/system';
import dayjs from 'dayjs';
import DataTable from './DataTable';

const flightManifest = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={styles.w100}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
          <Typography variant='caption' component='label'>Airline</Typography>
          <div className={docStyles.addTextField}>
            <FormControl variant='standard' fullWidth>
              <CustomSelect
                {...SelectInputProps}
                id=''
                name='airlineId'
                onChange={(e) => props.handleChangeForm(e, 'airlineId')}
                value={Number(props.formFields.airlineId) || ''}
              >
                {props.airlines?.map((airline, i) => (
                  <MenuItem key={i} value={airline.id}>{airline.code}</MenuItem>
                ))}

              </CustomSelect>
            </FormControl>
          </div>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={styles.w100}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
          <Typography variant='caption' component='label' htmlFor='flight_no'>Flight Number</Typography>
          <div className={docStyles.addTextField}>
            <CustomTextField
              {...InputProps}
              id='flight_no'
              fullWidth
              placeholder='Flight Number'
              type='text'
              onChange={(e) => props.handleChangeForm(e, 'flight_no')}
              value={props.formFields.flight_no}
              name='flight_no'
            />
          </div>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={styles.w100}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
          <Typography variant='caption' component='span'>Flight Date</Typography>
          <div className={docStyles.addTextField}>
            <CustomDatePicker
              slotProps={{
                textField: {
                  ...InputProps,
                  fullWidth: true,
                  placeholder: 'Flight Date'
                },
              }}
              format='DD MMM YYYY'
              name='flight_date'
              className={styles.w100}
              onChange={(e) => props.handleChangeForm(e, 'flight_date')}
              value={props.formFields.flight_date ? dayjs(props.formFields.flight_date, 'YYYY-MM-DD') : null}
            />
          </div>
        </Stack>
      </Grid>
      {props.formFields.flights && props.formFields.flights.length > 0 && (
        <Grid item xs={12} className={styles.w100}>
          <Typography variant='caption' component='label' htmlFor='origin'>Select Route</Typography>
          <DataTable data={props.formFields.flights} type='FLIGHT' input={true} value={props.formFields.flightId} onChange={props.handleChangeForm} />
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={styles.w100}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
          <Typography variant='caption' component='label' htmlFor='origin'>Origin</Typography>
          <div className={docStyles.addTextField}>

            <CustomTextField
              {...InputProps}
              id='origin'
              fullWidth
              placeholder='Origin'
              type='text'
              onChange={(e) => props.handleChangeForm(e, 'org')}
              value={props.formFields.org || ''}
              name='org'
            />
          </div>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={styles.w100}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
          <Typography variant='caption' component='label' htmlFor='destination'>Destination</Typography>
          <div className={docStyles.addTextField}>
            <CustomTextField
              {...InputProps}
              id='destination'
              fullWidth
              placeholder='Destination'
              type='text'
              onChange={(e) => props.handleChangeForm(e, 'dest')}
              value={props.formFields.dest || ''}
              name='dest'
            />
          </div>
        </Stack>
      </Grid>
      {
        props.type != 'other' &&
        <Grid item xs={12} className={`${docStyles.docBtn} ${docStyles.docBtnOnly}`}>
          <CustomButton variant='contained' onClick={props.submit}> Update </CustomButton>
          {props.formFields.note && <Typography variant='caption' component='span' color={'red'} className={docStyles.note}>{props.formFields.note}</Typography>}
        </Grid>
      }
    </Grid>
  );
};

export default flightManifest;