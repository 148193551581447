import React from 'react';
import { Stack, Grid, Radio, Box, Typography } from '@mui/material';
import { StyledDataGrid, StyledCard, StyledCardTitle, TableBadge, DataGridProps } from '../../../../../custom/components';
import mappingStyles from './mapping.module.css';
import styles from '../../../../../custom/style.module.css';
import { LocationIcon, DeleteIcon, AddIcon, EditIcon } from '../../../../../custom/constants';
import AddSpecialsModal from './AddSpecialsModal';
import AddProductsModal from './AddProductsModal';
import LocationContainer from 'components/pages/common/location/LocationContainer';

const SUB_TITLE_FS = '0.7rem';
const SUB_TITLE_LH = 1;
const SUB_TITLE_COLOR = '#000000c4';

const SPLMapping = (props) => {
  const airlinesCol = [
    {
      field: '',
      headerName: '',
      width: 50,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Radio size='small' className={styles.p0}
            checked={props.activeAirline?.id === row?.id}
          />
        );
      }
    },
    {
      field: 'airlines',
      headerName: 'Airline Code',
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <span>{row.code}</span>
        );
      }
    },
  ];

  const productsCol = [
    {
      field: '',
      headerName: '',
      width: 50,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Radio size='small' className={styles.p0}
            checked={props.activeProd?.id === row.id}
          />
        );
      }
    },
    {
      field: 'name',
      headerName: 'Products',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      width: 90,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
            <TableBadge color='error' badgeContent={row.stations && row.stations.length}>
              <LocationIcon onClick={(e) => props.handleLocation('product', true, row, e.target)} disabled={!row?.airlineId && props.activeAirline.id !== -1 } />
            </TableBadge>
            <EditIcon onClick={() => { props.handleOpen('product', 'edit', row); }} disabled={!row?.airlineId && props.activeAirline.id !== -1 } />
            <DeleteIcon onClick={(e) => { props.handleDelete('product', row.id, e.target); }} disabled={!row?.airlineId && props.activeAirline.id !== -1 } />
          </Stack>
        );
      }
    },
  ];

  const specialsCol = [
    {
      field: 'name',
      headerName: 'SPL Codes',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      width: 90,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
            <TableBadge color='error' badgeContent={row.stations && row.stations.length}>
              <LocationIcon onClick={(e) => props.handleLocation('special_code', true, row, e.target)} disabled={!row?.airlineId && props.activeAirline.id !== -1 } />
            </TableBadge>
            <EditIcon onClick={() => { props.handleOpen('specials', 'edit', row); }} disabled={!row?.airlineId && props.activeAirline.id !== -1 } />
            <DeleteIcon onClick={(e) => { props.handleDelete('specials', row.id, e.target); }} disabled={!row?.airlineId && props.activeAirline.id !== -1 } />
          </Stack>
        );
      }
    },
  ];


  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <StyledCard>
            <Stack className={`${styles.mb2} ${mappingStyles.titleWrapper}`}>
              <StyledCardTitle gutterBottom variant='cardTitle' className={styles.pb0}>Airlines</StyledCardTitle>
              <Typography component='span' fontSize={SUB_TITLE_FS} lineHeight={SUB_TITLE_LH} color={SUB_TITLE_COLOR} pt={0.5}>List of airlines will be automatically populated based on the flight schedule</Typography>
            </Stack>
            <div className={styles.tableWrapper}>
              <Box className={`${styles.tableOuter} ${mappingStyles.airlineTableOuter}`}>
                <StyledDataGrid
                  {...DataGridProps}
                  rows={[...props.airline]}
                  columns={airlinesCol}
                  onRowClick={({ row }) => {
                    props.fetchProducts(row);
                  }}
                  param={props.airline?.length}
                />
              </Box>
            </div>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <StyledCard>
            <Stack className={`${styles.mb2} ${mappingStyles.titleWrapper}`} direction='row' justifyContent='space-between' alignItems={'flex-start'} spacing={1}>
              <Stack>
                <StyledCardTitle gutterBottom variant='cardTitle' className={styles.pb0}>Products  {`| ${props.activeAirline?.code}`}</StyledCardTitle>
                <Typography component='span' fontSize={SUB_TITLE_FS} lineHeight={SUB_TITLE_LH} color={SUB_TITLE_COLOR} pt={0.5}>Create all products that have differentiated pricing.</Typography>
              </Stack>
              <AddIcon title='Add Product' onClick={() => props.handleOpen('product', 'add')} />
            </Stack>
            <div className={styles.tableWrapper}>
              <Box className={`${styles.tableOuter} ${mappingStyles.productsTableOuter}`}>
                <StyledDataGrid
                  {...DataGridProps}
                  rows={props.products}
                  columns={productsCol}
                  onRowClick={({ row }) => props.fetchSpecials(row)}
                  param={props.products?.length}
                />
              </Box>
            </div>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <StyledCard>
            <Stack className={`${styles.mb2}`} direction='row' justifyContent='space-between' alignItems={'flex-start'} spacing={1}>
              <Stack>
                <StyledCardTitle gutterBottom variant='cardTitle' className={styles.pb0}>Special Codes {`| ${props.activeAirline?.code || 'ALL'} | ${props.activeProd?.name || ''}`}</StyledCardTitle>
                <Typography component='span' fontSize={SUB_TITLE_FS} lineHeight={SUB_TITLE_LH} color={SUB_TITLE_COLOR} pt={0.5}>Associating IATA SPL codes to the groups of specials will enable auto recognition 
                and assignment of airway bills to the relevant special groups. If no SPL codes are associated, the user will still have
                 the chance to manually identify the special groups on the manifest. Note: Manifest must include IATA SPL codes for
                  the auto recognition to work.
                </Typography>
              </Stack>
              <AddIcon title='Add Special Codes' onClick={() => props.handleOpen('specials', 'add')} disabled={!props.activeProd?.airlineId && props.activeAirline?.id !== -1} />
            </Stack>
            <div className={styles.tableWrapper}>
              <Box className={`${styles.tableOuter} ${mappingStyles.specialsTableOuter}`}>
                <StyledDataGrid
                  {...DataGridProps}
                  rows={props.splCodes}
                  columns={specialsCol}
                  param={props.splCodes?.length}
                />
              </Box>
            </div>
          </StyledCard>
        </Grid>
      </Grid>

      <LocationContainer
        anchorEl={props.anchorEl}
        open={props.location}
        type={props.locationType}
        handleClose={props.handleLocation}
        stations={props.locationType === 'product'? props.activeProd?.airline ?  props.activeProd?.airline.stations : props.stations : props.activeProd?.stations}
        name={props.locationType === 'product'? props.activeProd?.name : props.splCodes[0]?.name}
        data={props.station}
      />

      <AddSpecialsModal
        modal={props.modal}
        action={props.action}
        onClose={props.onClose}
        airline={props.airline}
        products={props.products}
        specialsField={props.specialsField}
        specialsErrors={props.specialsErrors}
        addSpecials={props.addSpecials}
        editSpecials={props.editSpecials}
        onChangeSpecials={props.onChangeSpecials}
      />

      <AddProductsModal
        modal={props.modal}
        action={props.action}
        onClose={props.onClose}
        airline={props.airline}
        productField={props.productField}
        productErrors={props.productErrors}
        addProducts={props.addProducts}
        editProducts={props.editProducts}
        onChangeProduct={props.onChangeProduct}
      /> 
    </div>
  );
};

export default SPLMapping;
