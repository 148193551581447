import React, { Fragment } from 'react';
import { StyledDataGrid, DataGridProps } from 'custom/components';
import { Box } from '@mui/material';
import styles from '../../../../../custom/style.module.css';
import fltStyles from './fltloadinfo.module.css';
// import Invoice from './invoice/Invoice';

function formatNumber(num) {
  return num % 1 === 0 ? Math.round(num) : num.toFixed(2);
}

const ContractData = (props) => {

  const flightLoadColumns = [
    {
      field: 'item',
      headerName: 'Line Item',
      minWidth: 100,
      flex: 1.5,
    },
    {
      field: 'platform',
      headerName: 'Platform',
      minWidth: 100,
      flex: 1.5,
    },
    {
      field: 'driver',
      headerName: 'Driver',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        let value = 0;
        if(props.billingFields?.exclude_disc){
          if(props.billingFields?.transit === 'none' || !props.billingFields?.transit){
            value = typeof row.quantity === 'number' ? formatNumber(Math.round(row.quantity*100)/100) : row.quantity;
          } else if(props.billingFields?.transit === 'inbound'){
            value = typeof row.in_quantity === 'number' ? formatNumber(Math.round(row.in_quantity*100)/100) : row.in_quantity;
          } else {
            value = typeof row.out_quantity === 'number' ? formatNumber(Math.round(row.out_quantity*100)/100) : row.out_quantity;
          }
        } else {
          if(props.billingFields?.transit === 'none' || !props.billingFields?.transit){
            value = typeof row.dis_quantity === 'number' ? formatNumber(Math.round(row.dis_quantity*100)/100) : row.dis_quantity;
          } else if(props.billingFields?.transit === 'inbound'){
            value = typeof row.dis_in_quantity === 'number' ? formatNumber(Math.round(row.dis_in_quantity*100)/100) : row.dis_in_quantity;
          } else {
            value = typeof row.dis_out_quantity === 'number' ? formatNumber(Math.round(row.dis_out_quantity*100)/100) : row.dis_out_quantity;
          }
        }
        return value === 0 ? 'Nil' : value;
      }
    },
    {
      field: 'source',
      headerName: 'Source',
      minWidth: 100,
      flex: 1,
    },
    // {
    //   field: 'recorded_from',
    //   headerName: 'Recorded from (# Flights)',
    //   minWidth: 100,
    //   flex: 1,
    // },
  ];
  
  return <>
    {/* {
      //To Do: add condition to show only for airline record
      props.accord_type === 'airline' ?
        <>
          <Invoice
            flightdata = {[]}
            daylogdata= {[]}
            monthlogdata= {[]}
            invoiceData={props.contractData}
            dayContractData={props.dayContractData}
            monthContractData={props.monthContractData}
            airlineCode={props.airlineCode}
          /> 
          <Invoice 
            flightdata = {props.flightdata}
            daylogdata= {props.daylogdata}
            monthlogdata= {props.monthlogdata}
            invoiceData={props.contractData}
            dayContractData={props.dayContractData}
            monthContractData={props.monthContractData}
            airlineCode={props.airlineCode}
          />
        </>
        : null
    } */}
   
    <div className={`${styles.tableWrapper} ${styles.pt1}`}>
      <Box className={`${styles.tableOuter} ${fltStyles.cdtableOuter}`}>
        <StyledDataGrid
          {...DataGridProps}
          rows={props.contractData}
          columns={flightLoadColumns}
          apiRef={props.apiRef}
          param={props.contractData?.length}
        />  
      </Box>     
    </div>
  </>;

};

export default ContractData;