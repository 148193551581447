import React from 'react';
import events from 'utils/evemts';
import http from 'utils/http';
import { setDeleteAction } from 'store/actions/deleteAction';
import { connect } from 'react-redux';
// import cookies from 'utils/cookies';
import ReportChecklist from './ReportChecklist';
import { allAirlines } from '../../../../../custom/constants';
import { cloneDeep } from 'lodash';

class ReportChecklistContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      station: [],
      airline: [],
      checklist: [],
      splCodes: [],
      modal: false,
      action: null,
      checklistField: {},
      checklistErrors: {},
      specialsField: {},
      specialsErrors: {},
      activeAirline: null, 
      activeProd: null,
      anchorEl: null,
      location: false,
      locationType: null,
    };
    this.eventSource = null;
  }

  componentDidMount() {
    this.fetchAirline();
    this.fetchChecklist(allAirlines);
    // this.fetchSpecials ();
    this.eventSource = events('admin', 'airlinechecklist');
    this.eventSource.onmessage = (event) => {
      const msg = JSON.parse(event.data || '{}');
      if (msg.data ) {
        if (msg.action == 'ADD' && msg.data.airlineId === this.state.activeAirline?.id) {
          this.setState({ checklist: [...this.state.checklist, msg.data] });
          //do something
        }
        if (msg.action == 'UPDATE') {
          const checklist = [...this.state.checklist];
          const index = checklist.findIndex(c => msg.data && c.id == msg.data.id);
          if (index > -1) {
            checklist[index] = { ...msg.data };
            this.setState({ checklist });
          }
        }
        if (msg.action == 'DELETE') {
          const checklist = [...this.state.checklist];
          const index = checklist.findIndex(c => msg.data && c.id == msg.data.id);
          if (index > -1) {
            checklist.splice(index, 1);
            this.setState({ checklist });
          }
        }
      }
    };
  }

  componentWillUnmount() {
    this.eventSource.close();
  }

  fetchAirline = () => {
    http.get(`/airline?active=${true}`)
      .then(response => {
        const { data } = response;
        if (data.status === 'success') {
          this.setState({ airline: data.data });
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  fetchChecklist = (row={}) => {
    this.setState({ activeAirline: row });
    let id = row?.id;
    if (row?.id === -1 || !row?.id ) {
      id = 'ALL';
    }

    // http.get('/airlinechecklists')
    http.get(`/airlinechecklists?airlineId=${id}`)
      .then(response => {
        const { data } = response;
        if (data.status === 'success') {
          this.setState({ checklist: data.data });

        }
      })
      .catch(error => {
        this.setState({ checklist: [] });
        console.error('Error fetching data:', error);
      });
  };
 

  onChangeChecklist = (e) => {
    let prev = cloneDeep(this.state.checklistField);
    this.setState({
      checklistField: {
        ...prev,
        [e.target.name]: e.target.value
      }
    });
  };

  validationChecklist = () => {
    const checklistFields = { ...this.state.checklistField };
    const error = {};
    let formValid = true;
    if (!checklistFields.description || (checklistFields.description.trim().length === 0)) {
      formValid = false;
      error.description = 'Please enter a description.';
    }
    if (!checklistFields.role || (checklistFields.role.toString().trim().length === 0)) {
      formValid = false;
      error.role = 'Please select a role.';
    }
    if (!checklistFields.report_type || (checklistFields.report_type.toString().trim().length === 0)) {
      formValid = false;
      error.report_type = 'Please select a report type.';
    }
    if(!checklistFields.contract_types || (checklistFields.contract_types.toString().trim().length === 0)) {
      formValid = false;
      error.contract_types = 'Please select a department.';
    }
    // if (!checklistFields.airline || (checklistFields.airline.toString().trim().length === 0)) {
    //   formValid = false;
    //   error.airline = 'Please select an airline.';
    // }
    this.setState({ checklistErrors: error });
    return formValid;
  };

  addChecklist = async (e) => {
    e.preventDefault();
    let formdata = { 
      ...this.state.checklistField,
      airlineId: this.state.activeAirline.id === -1 ?  'ALL' : this.state.activeAirline.id
    };
    if (this.validationChecklist()) {
      http.post('/airlinechecklist', formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            checklistField: {},
            checklistErrors: {},
            action: null,
          });
        }
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  };

  editChecklist = async (e) => {
    e.preventDefault();
    let formdata = { 
      ...this.state.checklistField,
      airlineId: this.state.activeAirline.id === null ?  null : this.state.activeAirline.id
    };
    // delete formdata.station;
    if (this.validationChecklist()) {
      http.put('/airlinechecklist/' + formdata.id, formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            checklistField: {},
            action: null,
          });
        }
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  };

  deleteChecklist = (id) => {
    http.delete(`/airlinechecklist/${id}`).then(({ data }) => {
      if (data.status == 'success') {
        this.props.setDelete();
      }
    });
  };

  handleDelete = (type, id, anchorEl) => {
    this.props.setDelete({
      modal: true,
      id,
      anchorEl,
      onConfirm: () => {
        switch (type) {
          case 'airline':
            this.deleteAirline(id);
            break;
          case 'checklist':
            this.deleteChecklist(id);
            break;
          default:
            break;
        }
      },
    });
  };

  validationSpecials = () => {
    const specialsField = { ...this.state.specialsField };
    const error = {};
    let formValid = true;
    if (!specialsField.name || (specialsField.name.trim().length === 0)) {
      formValid = false;
      error.name = 'Please enter a special code.';
    }
    if (specialsField.name && (specialsField.name.trim().length < 3)) {
      formValid = false;
      error.name = 'Special code should not be more than 3 characters.';
    }
    if (!specialsField.product || (specialsField.product.toString().trim().length === 0)) {
      formValid = false;
      error.product = 'Please enter a product.';
    }
    if (!specialsField.airline || (specialsField.airline.toString().trim().length === 0)) {
      formValid = false;
      error.airline = 'Please select an airline.';
    }
    this.setState({ specialsErrors: error });
    return formValid;
  };

  addSpecials = async (e) => {
    e.preventDefault();
    let formdata = { ...this.state.specialsField };
    if (this.validationSpecials()) {
      http.post('/special_code', formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            specialsField: {},
            specialsErrors: {},
            action: null,
          });
        }
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  };

  editSpecials = async (e) => {
    e.preventDefault();
    let formdata = {
      id: this.state.specialsField.id,
      name: this.state.specialsField.name,
      product: this.state.specialsField.productId,
      airlineId: this.state.specialsField.airlineId 
    };
    // delete formdata.station;
    if (this.validationSpecials()) {
      http.put('/special_code/' + formdata.id, formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            specialsField: {},
            specialsErrors: {},
            action: null,
          });
        }
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  };

  deleteSpecials = (id) => {
    http.delete(`/special_code/${id}`).then(({ data }) => {
      if (data.status == 'success') {
        this.props.setDelete();
      }
    });
  };

  handleLocation = (locationType, v, data = {}, anchorEl = null) => {
    this.setState({
      location: v,
      locationType,
      station: data,
      anchorEl
    });
  };

  handleOpen = (modal, action, data={}) => {
    this.setState({
      modal,
      action,
      checklistField: { ...data, },
      specialsField: { ...data }
    });
  };

  onClose = () => {
    this.setState({
      modal: false,
      action: false,
      checklistField: {},
      checklistErrors: {},
    });
  };

  render() {
    return (
      <>
        <ReportChecklist
          stations={this.props.stations}
          station={this.state.station}
          anchorEl={this.state.anchorEl}
          modal={this.state.modal}
          action={this.state.action}
          location={this.state.location}
          locationType={this.state.locationType}
          handleOpen={this.handleOpen}
          onClose={this.onClose}
          handleLocation={this.handleLocation}
          airline={this.state.airline}
          checklist={this.state.checklist}
          splCodes={this.state.splCodes}
          checklistField={this.state.checklistField}
          checklistErrors={this.state.checklistErrors}
          addChecklist={this.addChecklist}
          editChecklist={this.editChecklist}
          onChangeChecklist={this.onChangeChecklist}
          activeAirline={this.state.activeAirline}
          handleDelete={this.handleDelete}
          fetchChecklist={this.fetchChecklist}
          specialsField={this.state.specialsField}
          specialsErrors={this.state.specialsErrors}
          addSpecials={this.addSpecials}
          editSpecials={this.editSpecials}
          onChangeSpecials={this.onChangeSpecials}
          fetchSpecials={this.fetchSpecials}
          activeProd={this.state.activeProd}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { stations: state.stations };
};

const mapDispatchToProps = (dispatch) => {
  return { setDelete: (data) => dispatch(setDeleteAction(data)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportChecklistContainer);
