import React, { Fragment } from 'react';
import { Box, Stack, Grid, Table, TableHead, TableBody, TableCell, TableRow, Tooltip, RadioGroup, Radio, MenuItem, Badge } from '@mui/material';
import { StyledCard, StyledCardTitle, StyledTableContainer, TableBadge, CustomRadio, StyledDataGrid, DataGridProps, CustomInputLabel, CustomDatePicker, InputProps, CustomSelect, SelectInputProps } from '../../../../../custom/components';
import styles from '../../../../../custom/style.module.css';
import contractStyles from './contracts.module.css';
import { JSONTree } from 'react-json-tree';
import { AttachFile as AttachFileIcon } from '@mui/icons-material';
import { AddIcon, DateFormat, DeleteIcon, DollarRateIcon, EditIcon, jsonTheme } from '../../../../../custom/constants';
import AddContractItem from './AddContractItem';
import DocModalContainer from 'components/pages/common/docModal/DocModalContainer';
import EditActiveContract from './EditActiveContract';
import moment from 'moment';
import { dateTolocale } from 'utils/converter';
import dayjs from 'dayjs';
import cookies from 'utils/cookies';
import EncryptionContainer from 'components/pages/common/EncryptionContainer';
import Status from 'components/pages/common/Status';
import CommentIcon from '@mui/icons-material/Comment';

const ManageContracts = (props) => {

  const exclude_keys = ['id', 'createdAt', 'updatedAt', 'companyId', 'contractId', 'productId', 'deleted', 'airlineId', 'stationId'];

  const filterObject = (obj1, obj2) => {
    let result = [];
    for (const key in obj1) {
      if (exclude_keys.includes(key)) continue;
      if (obj1[key] !== obj2[key]) {
        if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
          let arr = [];
          obj1[key].forEach((item, i) => {
            arr.push(filterObject(item, obj2[key][i]));
          });
          if (arr.length > 0)
            result.push({
              key,
              value: arr
            });
        } else if (Array.isArray(obj1[key])) {
          let arr = [];
          obj1[key].forEach((item) => {
            arr.push(filterObject(item, {}));
          }
          );
          if (arr.length > 0)
            result.push({
              key,
              value: arr
            });
        } else {
          result.push({
            key,
            value: obj1[key],
            prev_value: obj2[key]
          });
        }
      }
    }
    return result;
  };

  const renderObject = (obj1, obj2) => {
    return (<div>
      {filterObject(obj1, obj2).map((item, i) => {
        if (item.key == 'start_date' || item.key == 'end_date') {
          item.value = moment(item.value).format(DateFormat);
          item.prev_value = moment(item.prev_value).format(DateFormat);
        }
        // check if item.value is not empty object
        if (item.value && Array.isArray(item.value) && item.value.length > 0 && !typeof item.value === 'object') {
          return (
            <div key={i}>
              <b>{item.key}</b>&rarr;
              <div>{item.value.map((iitem, ii) => {
                if (iitem.length > 1) {
                  return (
                    <div key={ii}>
                      &nbsp;&nbsp;&nbsp;<b>{iitem[0]?.key}&rarr; {iitem[0]?.prev_value || 'NA'}&rarr; {iitem[0]?.value}</b>
                    </div>
                  );
                }
              })}</div>
            </div>
          );
        } else if (typeof item.value === 'object') {
          //do nothing 

        }
        else {
          return (
            <div key={i}>
              <b>{item.key}&rarr; {item.prev_value || 'NA'}&rarr; {item.value}</b>
            </div>
          );
        }
      })}
    </div>);
  };

  const mapObject = (obj1) => {
    let result = {};
    for (const key in obj1) {
      if (exclude_keys.includes(key)) continue;
      if ((key == 'start_date' || key == 'end_date') && obj1[key])
        obj1[key] = moment(obj1[key]).format(DateFormat);
      if (Array.isArray(obj1[key])) {
        let arr = [];
        obj1[key].forEach((item) => {
          arr.push(mapObject(item, {}));
        }
        );
        if (arr.length > 0)
          result[key] = arr;
      } else {
        result[key] = obj1[key];
      }
    }
    return result;
  };

  const getTabs = () => {

    let tabs = [
      {
        label: 'Active',
        value: 'active'
      },
      {
        label: 'Expired',
        value: 'expired',
      }
    ];
    if (cookies.get('ismanagec') && cookies.get('ismanagec') == 'true' && cookies.get('managecdt') && moment(cookies.get('managecdt')).isAfter(moment())) {
      tabs.push({
        label: 'Logs',
        value: 'logs'
      }
      );

    }
    return tabs.map((value, index) => {
      return <Fragment key={index}>
        <CustomRadio value={value.value}
          control={<Radio className={styles.radioToggleBtn} />}
          label={value.label}
          className={`${styles.radioToggle} ${props.tab === value.value ? styles.radioToggleBtnChecked : ''}`}
          classes={{ label: styles.radioToggleLabel }}
        />
      </Fragment>;
    });
  };

  const columns = [
    {
      field: 'created',
      headerName: 'Date & Time',
      minWidth: 150,
      flex: 0.8,
      renderCell: ({ row }) => dateTolocale(row.created, 'DD MMM YYYY HH:mm')
    },
    {
      field: 'type',
      headerName: 'Type',
      minWidth: 150,
      flex: 0.8,
    },
    {
      field: 'operation',
      headerName: 'Operation',
      minWidth: 150,
      flex: 0.8,
    },
    {
      field: 'prev_details',
      headerName: 'Previous Details',
      minWidth: 100,
      flex: 1.5,
      renderCell: ({ row }) => (
        <>
          {row?.prev_details ? <JSONTree data={mapObject(row?.prev_details)}
            theme={{
              ...jsonTheme,
              tree: ({ style }) => ({
                style: {
                  ...style,
                  backgroundColor: 'transparent'
                },
                className: 'myTreeClassName'
              }),
            }}
            invertTheme={true} className='json-tree' /> : 'N/A'}
        </>
      )
    },
    {
      field: 'new_details',
      headerName: 'New Details',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => row?.new_details ? renderObject(row?.new_details, row?.prev_details || {}) : 'N/A'
    },
    {
      field: 'user',
      headerName: 'User',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => row?.user?.first_name + ' ' + row?.user?.last_name
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <StyledCard>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box className={`${styles.fSpaceBtw} ${styles.mb2}`} >
                <Stack direction='row' spacing={2} alignItems={'center'}>
                  <StyledCardTitle component='div' className={styles.pb0}>Contracts</StyledCardTitle>
                  <CustomSelect
                    {...SelectInputProps}
                    id='airline'
                    labelId='airline-label'
                    fullWidth
                    autoFocus
                    onChange={props.onChange}
                    name='airline'
                    // className={billingStyles.tableTextField}
                    style={{ width: '90px' }}
                    value={!props.searchFields.airline ? 'placeholder' : props.searchFields.airline}
                  // classes={{ select: !props.flightFields.airline ? styles.colorPlaceholder : '' }}
                  >
                    <MenuItem value='placeholder' disabled >Select an airline</MenuItem>
                    {props?.airlines?.map((airline, i) => (
                      <MenuItem key={i} value={airline.id}>
                        {airline.code}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                </Stack>
                <RadioGroup
                  aria-labelledby='contract-type-radio-buttons-group'
                  name='contract-type-radio-buttons-group'
                  // value={contractType}
                  value={props.tab}
                  row
                  onChange={props.onChangeTab}
                  className={styles.radioToggleGroup}
                // className={props.userContractTypes.length > 0 ? `${styles.radioTabContainer} ${styles.radioTabContainerJustifty}` : ''}
                >
                  {getTabs()}
                </RadioGroup>
                <div>
                  {
                    cookies.get('ismanagec') && cookies.get('ismanagec') == 'true' && !cookies.get('managecdt') &&
                    <DollarRateIcon title='Secure Billing Access' 
                      onClick={() => props.tab == 'active' && props.handleOpen('encryption', null, null, null)} disabled={props.tab != 'active'} />
                  }
                  {
                  // if user is managec and managecdt && managecdt > current date time
                    cookies.get('ismanagec') && cookies.get('ismanagec') == 'true' && cookies.get('managecdt') && moment(cookies.get('managecdt')).isAfter(moment()) &&
                    <AddIcon title='Add Contract' onClick={() => props.tab == 'active' && props.handleOpen('contract', 'add', null, null)} disabled={props.tab != 'active'} />
                  }
                </div>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {props.tab && ['active', 'expired'].includes(props.tab) && (
                <StyledTableContainer ref={props.spanRef} className={`${contractStyles.nestedCells} ${contractStyles.rowSpanTable} ${contractStyles.rowSpanTableOuter}`}>
                  <Table className={contractStyles.rowSpanTable}>
                    <TableHead className={contractStyles.tableHead}>
                      <TableRow className={contractStyles.headRow}>
                        {/* <TableCell>Airline</TableCell> */}
                        <TableCell className={`${contractStyles.tableCell}`}>Airline Code</TableCell> 
                        <TableCell className={`${contractStyles.tableCell}`}>Department</TableCell>
                        {cookies.get('ismanagec') && cookies.get('ismanagec') == 'true' && cookies.get('managecdt') && moment(cookies.get('managecdt')).isAfter(moment()) &&
                          <TableCell className={`${contractStyles.tableCell}`}>Contract Min</TableCell>
                        }
                        {/* <TableCell>Billing Email</TableCell> */}
                        <TableCell className={`${contractStyles.tableCell}`}>Start Date</TableCell>
                        <TableCell className={`${contractStyles.tableCell}`}>End Date</TableCell>
                        {cookies.get('ismanagec') && cookies.get('ismanagec') == 'true' && cookies.get('managecdt') && moment(cookies.get('managecdt')).isAfter(moment()) &&
                          <TableCell align='center' className={`${contractStyles.tableCell}`}>Attachment</TableCell>
                        }
                        {props.tab == 'active' &&
                          cookies.get('ismanagec') && cookies.get('ismanagec') == 'true' && cookies.get('managecdt') && moment(cookies.get('managecdt')).isAfter(moment()) &&
                          (<TableCell align='center' className={`${contractStyles.tableCell}`}>Add</TableCell>)}
                        <TableCell colSpan={4} className={`${contractStyles.tableCell}`}></TableCell>
                        <TableCell className={`${contractStyles.tableCell}`}>Action</TableCell>
                      </TableRow>
                      <TableRow className={contractStyles.headRow}>
                        <TableCell colSpan={ props.tab == 'active' &&
                              cookies.get('ismanagec') && cookies.get('ismanagec') == 'true' && cookies.get('managecdt') && moment(cookies.get('managecdt')).isAfter(moment()) ? 12 : 9} className={contractStyles.headerBlock}></TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody className={contractStyles.tableBody}>
                      {props?.filteredData?.map((item, i) => (
                        <Fragment key={i}>
                          <TableRow classes={{ root: contractStyles.firstRow }} >
                            {/* <TableCell rowSpan={item.contract_height + 1} className={contractStyles.brNone}>
                              <div>{item.airline?.name}</div>
                            </TableCell> */}
                            <TableCell className={`${contractStyles.tableCell}`}>
                              <div>{item.airline?.code}</div>
                            </TableCell>
                            <TableCell className={`${contractStyles.tableCell}`}>
                              <div>{item.type}</div>
                            </TableCell>
                            {cookies.get('ismanagec') && cookies.get('ismanagec') == 'true' && cookies.get('managecdt') && moment(cookies.get('managecdt')).isAfter(moment()) &&
                              <TableCell className={`${contractStyles.tableCell}`}>
                                <div>{item.is_minimum ? cookies.get('currency') + (item.minimum || 0) : 'N/A'}</div>
                              </TableCell>
                            }
                            {/* <TableCell rowSpan={item.contract_height + 1} className={contractStyles.brNone}>
                              <div>{item.mail}</div>
                            </TableCell> */}
                            <TableCell className={`${contractStyles.tableCell}`}>
                              <div>{moment(item?.start_date).format(DateFormat).toUpperCase()}</div>
                            </TableCell>
                            <TableCell className={`${contractStyles.tableCell}`}>
                              <div>{moment(item?.end_date).format(DateFormat).toUpperCase()}</div>
                            </TableCell>
                            {cookies.get('ismanagec') && cookies.get('ismanagec') == 'true' && cookies.get('managecdt') && moment(cookies.get('managecdt')).isAfter(moment()) &&
                              <TableCell align='center' className={`${contractStyles.tableCell}`}>
                                <div className={`${styles.pointer} ${contractStyles.attachIcon}`}
                                  onClick={() => props.handleDoc(item)}>
                                  <Tooltip title='Add/View Attachment'>
                                    <TableBadge color='error' badgeContent={item?.docs?.length} sx={{ position: 'absolute' }}>
                                      <AttachFileIcon fontSize='small' />
                                    </TableBadge>
                                  </Tooltip>
                                </div>
                              </TableCell>
                            }
                            {props.tab == 'active' &&
                              cookies.get('ismanagec') && cookies.get('ismanagec') == 'true' && cookies.get('managecdt') && moment(cookies.get('managecdt')).isAfter(moment()) &&
                              (<TableCell align='center' className={`${contractStyles.tableCell}`}>
                                <div>
                                  <AddIcon title='Add Contract Item' className={`${styles.actionIcons} ${contractStyles.smallIcon} ${styles.p0}`} onClick={() => props.handleOpen('contract_item', 'add', item)} />
                                </div>
                              </TableCell>)}
                            <TableCell colSpan={4} className={`${contractStyles.tableCell}`}><div></div></TableCell>
                            <TableCell classes={{ root: contractStyles.contractAction }} className={`${contractStyles.tableCell}`}>
                              <div>
                                {props.tab == 'active' &&
                               cookies.get('ismanagec') && cookies.get('ismanagec') == 'true' && cookies.get('managecdt') && moment(cookies.get('managecdt')).isAfter(moment()) &&
                                 <Stack direction='row' spacing={2} justifyContent='flex-end' className={`${styles.actionIcons} ${contractStyles.smallIcon}`} mr={'8px'}>
                                   <EditIcon onClick={() => props.handleOpen('active_contract', 'edit', item, null)} />
                                   <DeleteIcon onClick={(e) => { props.handleDelete('contract', item.id, item, e.target); }} disabled={item.contractrows && item.contractrows.length > 0} />
                                 </Stack>}
                              </div>
                            </TableCell>
                          </TableRow>
                          <TableRow className={`${styles.mb2} ${styles.dBlock}`}></TableRow>
                          <TableRow className={contractStyles.nestedHead}>
                            <TableCell className={contractStyles.nestedHeadCell}></TableCell>
                            <TableCell className={contractStyles.nestedHeadCell}>Item</TableCell>
                            {
                              cookies.get('ismanagec') && cookies.get('ismanagec') == 'true' && cookies.get('managecdt') && moment(cookies.get('managecdt')).isAfter(moment()) &&
                          <TableCell className={contractStyles.nestedHeadCell}>Rate</TableCell>
                            }
                            <TableCell className={contractStyles.nestedHeadCell}>Role</TableCell>
                            <TableCell className={contractStyles.nestedHeadCell}>Applied Per</TableCell>
                            <TableCell className={contractStyles.nestedHeadCell}>Source</TableCell>
                            {cookies.get('ismanagec') && cookies.get('ismanagec') == 'true' && cookies.get('managecdt') && moment(cookies.get('managecdt')).isAfter(moment()) &&
                          <TableCell className={contractStyles.nestedHeadCell}>Line Min</TableCell>
                            }
                            <TableCell className={contractStyles.nestedHeadCell}>Platform</TableCell>
                            <TableCell className={contractStyles.nestedHeadCell}>Record On</TableCell>
                            <TableCell className={contractStyles.nestedHeadCell}>Segment</TableCell>
                            <TableCell className={contractStyles.nestedHeadCell} align='center'>Remarks</TableCell>
                            {props.tab == 'active' &&
                          cookies.get('ismanagec') && cookies.get('ismanagec') == 'true' && cookies.get('managecdt') && moment(cookies.get('managecdt')).isAfter(moment()) &&
                          (<TableCell className={contractStyles.nestedHeadCell}>Action</TableCell>)}
                          </TableRow>
                          <TableRow className={`${styles.mb2} ${styles.dBlock}`}></TableRow>
                          {item.contractrows && item.contract_height > 0 && item.contractrows.map((detail, j) => (
                            <Fragment key={'row' + j}>
                              {detail.contrates && detail.contrates.length > 0 ? detail.contrates.map((rate) => (
                                <TableRow
                                  key={j}
                                  className={j === item.contractrows.length - 1 ? `${contractStyles.lastRow} ${contractStyles.rowEnd}` : j === 0 ? `${contractStyles.lastRow} ${contractStyles.rowStart}` : contractStyles.lastRow}>
                                  <TableCell classes={{ root: contractStyles.rowBkg }}></TableCell>
                                  <TableCell classes={{ root: contractStyles.rowBkg }}>{detail?.description}</TableCell>
                                  {cookies.get('ismanagec') && cookies.get('ismanagec') == 'true' && cookies.get('managecdt') && moment(cookies.get('managecdt')).isAfter(moment()) &&
                                    <TableCell classes={{ root: contractStyles.rowBkg }}>{rate?.tier
                                      ? `${cookies.get('currency')} ${rate?.rate} | Tier ${rate?.tier} | ${rate?.level}`
                                      : `${cookies.get('currency')} ${rate?.rate}`
                                    }</TableCell>
                                  }
                                  <TableCell classes={{ root: contractStyles.rowBkg }}><div>{detail?.applied_on?.includes('Auto') ? <span style={{ paddingLeft: '40px' }}>-</span> : detail?.roles?.length > 0 && detail?.roles?.join(', ')}</div></TableCell>
                                  <TableCell classes={{ root: contractStyles.rowBkg }}>{detail?.applied_on ? detail?.applied_on.split('|')[0] : ''}</TableCell>
                                  <TableCell classes={{ root: contractStyles.rowBkg }}>{detail?.applied_on ? detail?.applied_on.split('|')[1] : ''}</TableCell>
                                  {cookies.get('ismanagec') && cookies.get('ismanagec') == 'true' && cookies.get('managecdt') && moment(cookies.get('managecdt')).isAfter(moment()) &&
                                    <TableCell classes={{ root: contractStyles.rowBkg }}><div>{detail?.is_minimum ? ((cookies.get('currency') + detail?.minimum) || (cookies.get('currency') + '0')) : 'N/A'}</div></TableCell>
                                  }
                                  <TableCell classes={{ root: contractStyles.rowBkg }}>{detail?.platform}</TableCell>
                                  <TableCell classes={{ root: contractStyles.rowBkg }}>{detail?.record_on}</TableCell>
                                  <TableCell classes={{ root: contractStyles.rowBkg }}>{detail?.segment}</TableCell>
                                  <TableCell classes={{ root: contractStyles.rowBkg }} align='center'>
                                    {detail?.remarks ?  <Badge color='secondary' variant='dot'>
                                      <Tooltip title={detail?.remarks}><CommentIcon sx={{ fontSize: '1rem' }} color='primary' /></Tooltip>
                                    </Badge>
                                      : <CommentIcon sx={{ fontSize: '1rem' }} color='primary' /> }
                                  </TableCell>
                                  {props.tab == 'active' &&
                                    cookies.get('ismanagec') && cookies.get('ismanagec') == 'true' && cookies.get('managecdt') && moment(cookies.get('managecdt')).isAfter(moment()) &&
                                    (<TableCell classes={{ root: contractStyles.rowBkg }} className={contractStyles.lastRowAction}><Stack direction='row' spacing={2} justifyContent='center' className={`${styles.actionIcons} ${contractStyles.smallIcon}`}>
                                      <EditIcon onClick={() => props.handleOpen('contract_item', 'edit', item, detail)} />
                                      <DeleteIcon onClick={(e) => { props.handleDelete('controw', rate.id, detail, e.target); }} />
                                    </Stack></TableCell>)}
                                </TableRow>
                              )) : (
                                <TableRow
                                  key={j}
                                  className={j === item.contractrows.length - 1 ? `${contractStyles.lastRow} ${contractStyles.rowEnd}` : j === 0 ? `${contractStyles.lastRow} ${contractStyles.rowStart}` : contractStyles.lastRow}>
                                  <TableCell classes={{ root: contractStyles.rowBkg }}>{detail?.description}</TableCell>
                                  <TableCell classes={{ root: contractStyles.rowBkg }}>{null}</TableCell>
                                  <TableCell classes={{ root: contractStyles.rowBkg }}>{detail?.applied_on ? detail?.applied_on.split('|')[0] : ''}</TableCell>
                                  <TableCell classes={{ root: contractStyles.rowBkg }}>{detail?.applied_on ? detail?.applied_on.split('|')[1] : ''}</TableCell>
                                  <TableCell classes={{ root: contractStyles.rowBkg }}><div>N/A</div></TableCell>
                                  <TableCell classes={{ root: contractStyles.rowBkg }}>{detail?.record_on}</TableCell>
                                  <TableCell classes={{ root: contractStyles.rowBkg }}>{detail?.segment}</TableCell>
                                  <TableCell classes={{ root: contractStyles.rowBkg }}>{detail?.remarks}</TableCell>
                                  {props.tab == 'active' &&
                                    cookies.get('ismanagec') && cookies.get('ismanagec') == 'true' && cookies.get('managecdt') && moment(cookies.get('managecdt')).isAfter(moment()) &&
                                    (<TableCell classes={{ root: contractStyles.rowBkg }} className={contractStyles.lastRowAction}><Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
                                      <EditIcon onClick={() => props.handleOpen('contract_item', 'edit', item, detail)} />
                                      <DeleteIcon />
                                    </Stack></TableCell>)}
                                </TableRow>
                              )}
                            </Fragment>
                          ))}
                          <TableRow className={`${styles.mb3} ${styles.dBlock}`}></TableRow>
                        </Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              )}

              {props.tab == 'logs' && (
                <Grid container spacing={3}>
                  <Grid item container spacing={2} columnSpacing={4}>
                    <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                      <CustomInputLabel htmlFor='' aria-label='Enter From Date'>From</CustomInputLabel>
                      <CustomDatePicker
                        slotProps={{
                          textField: {
                            ...InputProps,
                            placeholder: 'From Date',
                            fullWidth: true
                          },
                        }}
                        className={styles.w100}
                        format='DD MMM YYYY'
                        name='from'
                        value={dayjs(moment(props.searchOptions.from, 'YYYY MM DD'))}
                        // helperText={}
                        onChange={(e) => props.handleSearchOption(e, 'from')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                      <CustomInputLabel htmlFor='' aria-label='Enter To Date'>To</CustomInputLabel>
                      <CustomDatePicker
                        slotProps={{
                          textField: {
                            ...InputProps,
                            placeholder: 'To Date',
                            fullWidth: true
                          },
                        }}
                        className={styles.w100}
                        format='DD MMM YYYY'
                        name='to'
                        value={dayjs(moment(props.searchOptions.to, 'YYYY MM DD'))}
                        // helperText={}
                        onChange={(e) => props.handleSearchOption(e, 'to')}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={styles.tableWrapper}>
                      <Box className={`${styles.tableOuter} ${contractStyles.contractLogTableOuter}`}>
                        <StyledDataGrid
                          {...DataGridProps}
                          rows={props.logs || []}
                          columns={columns}
                          classes={{
                            root: contractStyles.contractLogTable,
                            cell: contractStyles.contractLogTableCell,
                            row: contractStyles.contractLogTableRow,
                          }}
                          param={props.logs?.length}
                        />
                      </Box>
                    </div>
                  </Grid>
                </Grid>
              )}
            </Grid>

          </Grid>
        </StyledCard>

        <EncryptionContainer
          functionCall={props.fetchContract}
          startTimer={props.startTimer}
          stopInterval={props.stopInterval}
          handleOpen={props.handleOpen}
          modal={props.modal}
          onClose={props.onClose}
        />

        <Status
          statusModal={props.sessionExpired}
          onClose={props.onClose}
          statusMessage= {{
            type: 'warning',
            size: 'md',
            title: 'Session Expired',
            mainMessage:'Session Expired',
            firstMessage:'Please authenticate again to restart the session.',
            secondMessage:''
          }}
        />

        <AddContractItem
          modal={props.modal}
          action={props.action}
          onClose={props.onClose}
          airline={props.airline}
          checklist={props.checklist}
          checklistField={props.checklistField}
          checklistErrors={props.checklistErrors}
          addChecklist={props.addChecklist}
          editChecklist={props.editChecklist}
          onChangeChecklist={props.onChangeChecklist}
          products={props.products}
          recordOns={props.recordOns}
          segments={props.segments}
          controwField={props.controwField}
          contrateField={props.contrateField}
          onChangecontrowField={props.onChangecontrowField}
          onChangecontrateField={props.onChangecontrateField}
          addControw={props.addControw}
          editControw={props.editControw}
          deleteContrate={props.deleteContrate}

          controwErrors={props.controwErrors}
          contrateErrors={props.contrateErrors}

          anchorEl={props.anchorEl}
          handlePopover={props.handlePopover}
          attachment={props.attachment}
        />

        <EditActiveContract
          modal={props.modal}
          action={props.action}
          onClose={props.onClose}
          addContract={props.addContract}
          editContracts={props.editContracts}
          airlines={props.airlines}
          contractFieldEdit={props.contractFieldEdit}
          contractErrorsEdit={props.contractErrorsEdit}
          onChangecontractFieldEdit={props.onChangecontractFieldEdit}
          onChangechangeDateAndTime={props.onChangechangeDateAndTime}
        />

        <DocModalContainer />

      </Grid>
    </Grid>
  );
};

export default ManageContracts;


