
import React, { Component } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Dialog, DialogContent } from '@mui/material';
import { CustomDialogTitle } from 'custom/components';
import styles from 'custom/style.module.css';
import manifestStyle from './manifest.module.css';
import { CloseIconBtn } from 'custom/constants';
import PDFSlider from 'components/pages/common/PDFSlider';
import http from 'utils/http';
import env from 'custom/environments';

class PDFSliderManifest extends Component {
  constructor(props) {
    super(props);
    this.state = { docs: [], };
  }
  componentDidMount() {
    if (this.props.modal_data?.awbId) {
      http.get(`/archive/doc?id=${this.props.modal_data.awbId}&type=AWB`).then(({ data }) => {
        if (data.status === 'success') {
          this.setState({ docs: data.data });
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.modal_data?.awbId !== this.props.modal_data?.awbId && this.props.modal_data?.awbId) {
      http.get(`/archive/doc?id=${this.props.modal_data.awbId}&type=AWB`).then(({ data }) => {
        if (data.status === 'success') {
          this.setState({ docs: data.data });
        }
      });
    }

  }


  render() {

    return (
      <Dialog aria-labelledby='form-dialog-title' open={this.props.modal === 'view_awb'} onClose={this.props.onClose} fullWidth maxWidth={'md'}>
        <CustomDialogTitle id='form-dialog-title' className={styles.modalTitle}>AWB {this.props.modal_data?.awb_no}
          <CloseIconBtn onClick={this.props.onClose} />
        </CustomDialogTitle>
        <DialogContent>
          <div className={manifestStyle.doc}>
            {this.state.docs.map(doc => {
              const extension = doc.src?.split('.').pop().toLowerCase();
              const image = ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(extension);
              return (
                <>
                  {image ? <img src={env.apiService + 'document?key=' + doc.src} alt='attachment' className={`${styles.w100}`} crossOrigin='use-credentials' /> : <PDFSlider file={env.apiService + 'document?key=' + doc.src} className={styles.mauto} />}
                </>
              );
            })}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default PDFSliderManifest;