import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import cookies from 'utils/cookies';

// const borderColor = 'lightgrey';
const textColor = '#393939';

const totalAmount = (item, billingFields, type, method) => {
  let cycle1data = 
    type === 'flights' ?
      billingFields?.exclude_disc? 
        billingFields?.transit === 'none' || !billingFields?.transit ? 
          item?.cycle1data?.totalrate 
          : billingFields?.transit === 'inbound' ?
            item?.cycle1data?.in_totalrate
            : item?.cycle1data?.out_totalrate  
        : billingFields?.transit === 'none' || !billingFields?.transit ?
          item?.cycle1data?.dis_totalrate
          : billingFields?.transit === 'inbound' ?
            item?.cycle1data?.dis_in_totalrate
            : item?.cycle1data?.dis_out_totalrate
      : item.totalrate;
  
  let cycle2data =  billingFields?.exclude_disc ? 
    billingFields?.transit === 'none' || !billingFields?.transit ?
      !item.totalrate ? 0 : isNaN(item.totalrate) ? item.totalrate : (item.totalrate /*item.quantity * item.rate*/).toFixed(2)
      : billingFields?.transit === 'inbound' ?
        !item.in_totalrate ? 0 : isNaN(item.in_totalrate) ? item.in_totalrate : (item.in_totalrate /*item.quantity * item.rate*/).toFixed(2)
        : !item.out_totalrate ? 0 : isNaN(item.out_totalrate) ? item.out_totalrate : (item.out_totalrate /*item.quantity * item.rate*/).toFixed(2)
    : billingFields?.transit === 'none' || !billingFields?.transit ? 
      !item.dis_totalrate ? 0 : isNaN(item.dis_totalrate) ? item.dis_totalrate : (item.dis_totalrate /*item.quantity * item.rate*/).toFixed(2)
      : billingFields?.transit === 'inbound' ?
        !item.dis_in_totalrate ? 0 : isNaN(item.dis_in_totalrate) ? item.dis_in_totalrate : (item.dis_in_totalrate /*item.quantity * item.rate*/).toFixed(2)
        : !item.dis_out_totalrate ? 0 : isNaN(item.dis_out_totalrate) ? item.dis_out_totalrate : (item.dis_out_totalrate /*item.quantity * item.rate*/).toFixed(2);

  let total = parseFloat(cycle1data) + parseFloat(cycle2data);
  
  if (method === 'sub') {
    total = total - parseFloat(cycle1data);
    total = total.toFixed(2);
  }
  else if (method === 'add') {
    total;
  }
  return total;

};


const InvoiceTableRow = ({ type, items, borderColor, searchFields, billingFields, localeObj }) => {
  const styles = StyleSheet.create({
    row: {
      flexDirection: 'row',
      borderBottomColor: borderColor,
      alignItems: 'center',
      // height: 30,
      height: 30,
      fontStyle: 'bold',
    },
    description: {
      // width: '55%',
      width: '40%',
      textAlign: 'left',
      // borderRightColor: borderColor,
      // borderRightWidth: 1,
      padding: 4,
      paddingLeft: 8,
      fontSize: 9,
  
      display: 'flex',
      flexDirection: 'column',
      color: textColor,
      borderRightColor: borderColor,
      borderRightWidth: 1,
      height: '100%',
    },
    inlinedesc: {
      color: 'grey',
      fontSize: 8,
    },
    qty: {
      width: '20%',
      // borderRightColor: borderColor,
      // borderRightWidth: 1,
      padding: 4,
      paddingLeft: 8,
      fontSize: 9,
      color: textColor,
      justifyContent: 'center',
      alignItems: 'center',
      borderRightColor: borderColor,
      borderRightWidth: 1,
      height: '100%',
    },
    rate: {
      width: '20%',
      // borderRightColor: borderColor,
      // borderRightWidth: 1,
      padding: 4,
      paddingLeft: 8,
      fontSize: 9,
      color: textColor,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRightColor: borderColor,
      borderRightWidth: 1,
      height: '100%',
    },
    descriptionInner: { width: '40%', },
    qtyInner: { width: '20%', },
    rateInner: { width: '20%', },
    numberInner: { minWidth: '80%' },
    amount: {
      width: '20%',
      padding: 4,
      paddingLeft: 8,
      fontSize: 9,
      color: textColor,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // flexDirection: 'row',
      // justifyContent: 'space-between',
      // height: '100%',
    },
    qtyWrapper: {
      width: '70%',
      textAlign: 'right',
    },
    amountWrapper: {
      width: '70%',
      flexDirection: 'row',
      justifyContent: 'space-between' 
    },
    inlinedescWrapper: { flexDirection: 'row', },
    divider: {
      color: 'grey',
      fontSize: 8,
      margin: '0 4px' 
    },
    totalBorder: {
      // borderBottomColor: borderColor,
      // borderBottomWidth: 1,
      borderTopColor: borderColor,
      borderTopWidth: 1,
    },
    borderBottom: {
      borderBottomColor: borderColor,
      borderBottomWidth: 1,
      marginBottom: 4,
      paddingBottom: 4,
    },
    borderTop: {
      borderTopColor: borderColor,
      borderTopWidth: 1,
      marginTop: 2,
      paddingTop: 2,
    },
    subTotalRow: {
      flexDirection: 'row', 
      alignItems: 'center',      
    },
    offSetCol: { width: '60%', },
    advanceTotalRow: {
      width: '98%',
      margin: 'auto',
      padding: '4px 4px 0 4px',
      backgroundColor: '#f4f4f4',
      marginBottom: 2
    },
    rowDivider: {
      borderBottomColor: borderColor,
      borderBottomWidth: 1,
      width: '100%'
    },
    advDescWidth: { width: '80%', },
    minusSign : {
      position: 'absolute',
      left: '10px',
      top: '40%'
    }
  });
  const rows = items.map( item =>
    <>
      <View style={styles.row} key={item.id}>
        <View style={styles.description}>
          <Text>
            {item.item && item.item.charAt(0).toUpperCase() + item.item.slice(1)}</Text>
          <View style={styles.inlinedescWrapper}>
            <Text style={styles.inlinedesc}>{item.driver}</Text> 
            <Text style={styles.divider}>|</Text>
            <Text style={styles.inlinedesc}>{item.source}</Text>
          </View>
          {/* {
            (searchFields.period !== 'first' && searchFields.period !== 'month')&& item.cycle1data && item.rate_type !== 'stable' ?
              <View style={styles.inlinedescWrapper}> 
                <Text style={styles.inlinedesc}>Advance from Cycle 1</Text>
              </View>
              : null
          } */}
        </View>
      
        {/* <Text style={styles.description}>{item.driver}</Text>
      <Text style={styles.description}>{item.source}</Text> */}
        <View style={styles.qty}>
          <View style={styles.qtyWrapper}>
            <Text>{
              type === 'flights' ?
                billingFields?.exclude_disc? 
                  billingFields?.transit === 'none' || !billingFields?.transit ? 
                    item.quantity ? item.quantity?.toLocaleString(localeObj.locale, localeObj.options) || 0 : 0 
                    : billingFields?.transit === 'inbound' ?
                      item.in_quantity ? item.in_quantity?.toLocaleString(localeObj.locale, localeObj.options) || 0 : 0
                      : item.out_quantity ? item.out_quantity?.toLocaleString(localeObj.locale, localeObj.options) || 0 : 0
                  : billingFields?.transit === 'none' || !billingFields?.transit ?
                    item?.dis_quantity ? item.dis_quantity.toLocaleString(localeObj.locale, localeObj.options) || 0 : 0
                    : billingFields?.transit === 'inbound' ?
                      item.dis_in_quantity ? item.dis_in_quantity.toLocaleString(localeObj.locale, localeObj.options) || 0 : 0
                      : item.dis_out_quantity ? item.dis_out_quantity.toLocaleString(localeObj.locale, localeObj.options) || 0 : 0
                : item.quantity ? item.quantity?.toLocaleString(localeObj.locale, localeObj.options) || 0 : 0
            }</Text>
            {/* {
              (searchFields.period !== 'first' && searchFields.period !== 'month') && item.cycle1data && item.rate_type !== 'stable' ?
                <Text style={styles.inlinedesc}>{
                  type === 'flights' ?
                    billingFields?.exclude_disc? 
                      billingFields?.transit === 'none' || !billingFields?.transit ? 
                        item?.cycle1data?.quantity?.toFixed(2) || 0 
                        : billingFields?.transit === 'inbound' ?
                          item?.cycle1data?.in_quantity?.toFixed(2) || 0
                          : item?.cycle1data?.out_quantity?.toFixed(2) || 0
                      : billingFields?.transit === 'none' || !billingFields?.transit ?
                        item?.cycle1data?.dis_quantity?.toFixed(2) || 0
                        : billingFields?.transit === 'inbound' ?
                          item?.cycle1data?.dis_in_quantity?.toFixed(2) || 0
                          : item?.cycle1data?.dis_out_quantity?.toFixed(2) || 0
                    : item.quantity ? item.quantity?.toFixed(2) || 0 : 0
                }</Text>
                : null
            } */}
          </View>
        </View>
        <View style={styles.rate}>
          <View style={styles.amountWrapper}>
            <Text>{item.rate_type === 'stable' ? cookies.get('currency') : ''}</Text> 
            <Text style={{ textAlign: 'center' }}>{
              type === 'flights' ?
                item.rate_type === 'stable' ? billingFields?.exclude_disc? 
                  billingFields?.transit === 'none' || !billingFields?.transit ? 
                    item?.rate?.toLocaleString(localeObj.locale, localeObj.options) || 0 
                    : billingFields?.transit === 'inbound' ?
                      item?.in_rate.toLocaleString(localeObj.locale, localeObj.options) || 0
                      : item?.out_rate?.toLocaleString(localeObj.locale, localeObj.options) || 0
                  : billingFields?.transit === 'none' || !billingFields?.transit ?
                    item?.dis_rate?.toLocaleString(localeObj.locale, localeObj.options) || 0
                    : billingFields?.transit === 'inbound' ?
                      item?.dis_in_rate?.toLocaleString(localeObj.locale, localeObj.options) || 0
                      : item?.dis_out_rate?.toLocaleString(localeObj.locale, localeObj.options) || 0
                  : 'Tiered Rates'
                : item.rate?.toLocaleString(localeObj.locale, localeObj.options) || 0
            }</Text> 
          </View>
        </View>
        {/* <View style={styles.amount}> */}
        <View style={styles.amount}>
          <View style={styles.amountWrapper}>
            <Text>{ cookies.get('currency') }</Text> 
            <Text>{
              type === 'flights' ?
                billingFields?.exclude_disc ? 
                  billingFields?.transit === 'none' || !billingFields?.transit ?
                    !item.totalrate ? 0 : isNaN(item.totalrate) ? item.totalrate : (item.totalrate /*item.quantity * item.rate*/).toLocaleString(localeObj.locale, localeObj.options)
                    : billingFields?.transit === 'inbound' ?
                      !item.in_totalrate ? 0 : isNaN(item.in_totalrate) ? item.in_totalrate : (item.in_totalrate /*item.quantity * item.rate*/).toLocaleString(localeObj.locale, localeObj.options)
                      : !item.out_totalrate ? 0 : isNaN(item.out_totalrate) ? item.out_totalrate : (item.out_totalrate /*item.quantity * item.rate*/).toLocaleString(localeObj.locale, localeObj.options)
                  : billingFields?.transit === 'none' || !billingFields?.transit ? 
                    !item.dis_totalrate ? 0 : isNaN(item.dis_totalrate) ? item.dis_totalrate : (item.dis_totalrate /*item.quantity * item.rate*/).toLocaleString(localeObj.locale, localeObj.options)
                    : billingFields?.transit === 'inbound' ?
                      !item.dis_in_totalrate ? 0 : isNaN(item.dis_in_totalrate) ? item.dis_in_totalrate : (item.dis_in_totalrate /*item.quantity * item.rate*/).toLocaleString(localeObj.locale, localeObj.options)
                      : !item.dis_out_totalrate ? 0 : isNaN(item.dis_out_totalrate) ? item.dis_out_totalrate : (item.dis_out_totalrate /*item.quantity * item.rate*/).toLocaleString(localeObj.locale, localeObj.options)
                : !item.totalrate ? 0 : isNaN(item.totalrate) ? item.totalrate : (item.totalrate).toLocaleString(localeObj.locale, localeObj.options)
            }</Text>
          </View>
          {/* {
            (searchFields.period !== 'first' && searchFields.period !== 'month') && item.cycle1data && item.rate_type !== 'stable' ?
              <View style={styles.amountWrapper}>
                <Text style={styles.inlinedesc}>{ cookies.get('currency') }</Text> 
                <Text style={styles.inlinedesc}>{
                  type === 'flights' ?
                    billingFields?.exclude_disc? 
                      billingFields?.transit === 'none' || !billingFields?.transit ? 
                        item?.cycle1data?.totalrate 
                        : billingFields?.transit === 'inbound' ?
                          item?.cycle1data?.in_totalrate
                          : item?.cycle1data?.out_totalrate  
                      : billingFields?.transit === 'none' || !billingFields?.transit ?
                        item?.cycle1data?.dis_totalrate
                        : billingFields?.transit === 'inbound' ?
                          item?.cycle1data?.dis_in_totalrate
                          : item?.cycle1data?.dis_out_totalrate
                    : item.totalrate
                }</Text>
              </View>
              : null
          } */}
        </View>
      </View>
     
      {
        (searchFields.period !== 'first' && searchFields.period !== 'month')&& item.cycle1data && item.rate_type !== 'stable' ?
          <View style={styles.advanceTotalRow}> 
            <Text style={[styles.inlinedesc, { fontSize: '10px' }]}>Breakdown</Text>
            <View style={[styles.row, { height: 'auto' }]}>
              <View style={styles.descriptionInner}>
                <View style={[styles.inlinedescWrapper, styles.advDescWidth]}> 
                  <Text style={styles.inlinedesc}>Total Amount</Text>
                </View>
              </View>
              <View style={styles.qtyInner}></View>
              <View style={styles.rateInner}></View>
              <View style={[styles.amount,           
                {
                  alignItems: 'flex-end',
                  padding: 1,
                  paddingRight: 8 
                } ]}>
                <View style={styles.amountWrapper}>
                  <Text style={styles.inlinedesc}>{ cookies.get('currency') }</Text> 
                  <Text style={styles.inlinedesc}>{totalAmount(item, billingFields, type, 'add').toLocaleString(localeObj.locale, localeObj.options)}
                  </Text>
                </View>
              </View>
            </View>             
            <View style={[styles.row, { height: 'auto' }]}>
              <View style={styles.descriptionInner}>
                <View style={[styles.inlinedescWrapper, styles.advDescWidth]}> 
                  <Text style={styles.inlinedesc}>Advance from Cycle 1</Text>
                </View>
              </View>
              <View style={[styles.qtyInner, {
                alignItems: 'flex-end',
                paddingRight: 16 
              } ]}>
                {
                  (searchFields.period !== 'first' && searchFields.period !== 'month') && item.cycle1data && item.rate_type !== 'stable' ?
                    <Text style={styles.inlinedesc}>{
                      type === 'flights' ?
                        billingFields?.exclude_disc? 
                          billingFields?.transit === 'none' || !billingFields?.transit ? 
                            item?.cycle1data?.quantity?.toLocaleString(localeObj.locale, localeObj.options) || 0 
                            : billingFields?.transit === 'inbound' ?
                              item?.cycle1data?.in_quantity?.toLocaleString(localeObj.locale, localeObj.options) || 0
                              : item?.cycle1data?.out_quantity?.toLocaleString(localeObj.locale, localeObj.options) || 0
                          : billingFields?.transit === 'none' || !billingFields?.transit ?
                            item?.cycle1data?.dis_quantity?.toLocaleString(localeObj.locale, localeObj.options) || 0
                            : billingFields?.transit === 'inbound' ?
                              item?.cycle1data?.dis_in_quantity?.toLocaleString(localeObj.locale, localeObj.options) || 0
                              : item?.cycle1data?.dis_out_quantity?.toLocaleString(localeObj.locale, localeObj.options) || 0
                        : item.quantity ? item.quantity?.toLocaleString(localeObj.locale, localeObj.options) || 0 : 0
                    }</Text>
                    : null
                }
              </View>
              <View style={styles.rateInner}></View>
              <View style={[styles.amount, {
                position: 'relative',
                alignItems: 'flex-end',
                padding: 1,
                paddingRight: 8  
              }]}>
                <Text style={[styles.inlinedesc, styles.minusSign]}>-</Text>
                <View style={styles.amountWrapper}>
                  <Text style={styles.inlinedesc}>{ cookies.get('currency') }</Text> 
                  <Text style={styles.inlinedesc}>{
                    type === 'flights' ?
                      billingFields?.exclude_disc? 
                        billingFields?.transit === 'none' || !billingFields?.transit ? 
                          item?.cycle1data?.totalrate.toLocaleString(localeObj.locale, localeObj.options) 
                          : billingFields?.transit === 'inbound' ?
                            item?.cycle1data?.in_totalrate.toLocaleString(localeObj.locale, localeObj.options)
                            : item?.cycle1data?.out_totalrate  
                        : billingFields?.transit === 'none' || !billingFields?.transit ?
                          item?.cycle1data?.dis_totalrate.toLocaleString(localeObj.locale, localeObj.options)
                          : billingFields?.transit === 'inbound' ?
                            item?.cycle1data?.dis_in_totalrate.toLocaleString(localeObj.locale, localeObj.options)
                            : item?.cycle1data?.dis_out_totalrate.toLocaleString(localeObj.locale, localeObj.options)
                      : item.totalrate.toLocaleString(localeObj.locale, localeObj.options)
                  }</Text>
                </View>
              </View>
            </View>             
            <View style={[styles.row, styles.borderTop, { height: 'auto' }]}>
              <View style={[styles.inlinedescWrapper, styles.advDescWidth]}> 
                <Text style={styles.inlinedesc}></Text>
              </View>
              <View style={[styles.amount, {
                alignItems: 'flex-end',
                padding: 1,
                paddingRight: 8  
              }]}>
                <View style={styles.amountWrapper}>
                  <Text style={styles.inlinedesc}>{ cookies.get('currency') }</Text> 
                  <Text style={styles.inlinedesc}>{totalAmount(item, billingFields, type, 'sub').toLocaleString(localeObj.locale, localeObj.options)}</Text>
                </View>
              </View>
            </View>             
          </View>
          : null
      }
      <View style={styles.rowDivider}></View>
    </>

  );
  return (<Fragment>{rows}</Fragment> );
};
  
export default InvoiceTableRow;