import React from 'react';
import DocModal from './DocModal';
import http from 'utils/http';
import { setDocAction } from 'store/actions/docAction';
import { connect } from 'react-redux';
import { setDeleteAction } from 'store/actions/deleteAction';
import moment from 'moment';
import axios from 'axios';

class DocModalContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      action: null,
      userFields: {},
      userErrors: {},
      k9Fields: {},
      k9Errors: {},
      docFields: {},
      docErrors: {},
    };
  }

  handleOpen = (modal, action, data = {}) => {
    this.setState({
      modal,
      action,
      k9Fields: modal == 'k9' ? {
        ...data,
        ...data.data || {}
      } : {},
      userFields: modal == 'user' ? {
        ...data,
        ...data.data || {}
      } : {}
    });
  };

  onClose = () => {
    this.setState({
      modal: false,
      k9Fields: {},
      k9Errors: {},
      docFields: { file: '' }
    });
  };

  onChangeUser = (e) => {
    let value = e.target.value;
    if (e.target.type == 'date') {
      value = moment(e.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD');
    }
    this.setState({
      userFields: {
        ...this.state.userFields,
        [e.target.name]: e.target.name == 'file' ? e.target.files[0] : value
      }
    });
  };

  validateUser = () => {
    const userFields = { ...this.state.userFields };
    const error = {};
    let formValid = true;
    if (!userFields.name || (userFields.name.trim().length === 0)) {
      formValid = false;
      error.name = 'Please enter name.';
    }
    // if (!userFields.training_date || (userFields.training_date.trim().length === 0)) {
    //   formValid = false;
    //   error.training_date = 'Please enter training date.';
    // }
    // if (!userFields.expiry_date || (userFields.expiry_date.trim().length === 0)) {
    //   formValid = false;
    //   error.expiry_date = 'Please enter expiry date.';
    // }
    this.setState({ userErrors: error });
    return formValid;
  };

  addUser = async (e) => {
    e.preventDefault();
    if (this.validateUser()) {
      let formdata = {
        training_date: moment().format('YYYY-MM-DD'),
        expiry_date: moment().format('YYYY-MM-DD'),
        ...this.state.userFields,
        id: this.props.doc.id
      };
      http.post('/document/user', formdata, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            userFields: {},
            userErrors: {},
            action: null,
          });
        }
      });
    }
  };
  editUser = (e) => {
    e.preventDefault();
    if (this.validateUser()) {
      let formdata = { ...this.state.userFields, };
      http.put('/document/user/' + formdata.id, formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            userFields: {},
            userErrors: {},
            action: null,
          });
        }
      });
    }
  };

  deleteUser = async (id, data=null) => {
    let url = '/document/' + id;
    if(data){
      url = url + '?contractId=' + data.contractId;
    }
    http.delete(url).then(({ data }) => {
      if (data.status == 'success') {
        this.props.setDelete();
        this.closeDrawer();
      }
    });
  };

  deleteDiscrepancy = async (id, data=null) => {
    let url = '';
    if(data){
      url = `/discrepancy/doc/${this.props.doc.flightId}/${id}`;
    }
    http.delete(url).then(({ data }) => {
      if (data.status == 'success') {
        this.props.setDelete();
        this.closeDrawer();
      }
    });
  };

  deleteLogDoc = async (id, data=null) => {
    let url = '';
    if(data){
      url = `/service_log/doc/${id}`;
      http.delete(url).then(({ data }) => {
        if (data.status == 'success') {
          this.props.setDelete();
          this.closeDrawer();
        }
      });
    }
  };

  onChangeK9 = (e) => {
    let value = e.target.value;
    this.setState({
      k9Fields: {
        ...this.state.k9Fields,
        [e.target.name]: e.target.name == 'file' ? e.target.files[0] : value
      }
    });
  };

  validatek9 = () => {
    const k9Fields = { ...this.state.k9Fields };
    const error = {};
    let formValid = true;
    if (!k9Fields.name || (k9Fields.name.trim().length === 0)) {
      formValid = false;
      error.name = 'Please enter name.';
    }
    if (!k9Fields.remark || (k9Fields.remark.trim().length === 0)) {
      formValid = false;
      error.remark = 'Please enter remark.';
    }
    this.setState({ k9Errors: error });
    return formValid;
  };

  addK9 = (e) => {
    e.preventDefault();
    if (this.validatek9()) {
      let formdata = {
        ...this.state.k9Fields,
        id: this.props.doc.id
      };
      http.post('/document/k9', formdata, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            k9Fields: {},
            k9Errors: {},
            action: null,
          });
        }
      });
    }
  };

  editK9 = (e) => {
    e.preventDefault();
    if (this.validatek9()) {
      let formdata = { ...this.state.k9Fields, };
      http.put('/document/k9/' + formdata.id, formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            k9Fields: {},
            k9Errors: {},
            action: null,
          });
        }
      });
    }
  };

  deleteK9 = (id) => {
    http.delete('/document/k9/' + id).then(({ data }) => {
      if (data.status == 'success') {
        this.props.setDelete();
      }
    });
  };

  validateDoc = () => {
    const docFields = { ...this.state.docFields };
    const error = {};
    let formValid = true;
    if (!docFields.name || (docFields.name.trim().length === 0)) {
      formValid = false;
      error.name = 'Please enter name.';
    }
    if (!docFields.file) {
      formValid = false;
      error.remark = 'Please select file.';
    }
    this.setState({ docErrors: error });
    return formValid;
  };

  addDoc = (e) => {
    e.preventDefault();
    if (this.validateDoc()) {
      let formdata = { 
        ...this.state.docFields,
        id: this.props.doc.id
      };
      http.post('/document/contract', formdata, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            docFields: { name: '', },
            docFieldsErrors: {},
          });
        }
      });
    }
  };

  addDiscrepancyDoc = (e) => {
    e.preventDefault();
    if (this.validateDoc()) {
      let formdata = { ...this.state.docFields, };
      console.log(formdata, 'formdata');
      http.post(`/discrepancy/doc/${this.props.doc.flightId}/${this.props.doc.id}`, formdata, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            docFields: { name: '', },
            docFieldsErrors: {},
          });
        }
      });
    }
  };

  addLogDoc = (e) => {
    e.preventDefault();
    if (this.validateDoc()) {
      let formdata = { ...this.state.docFields, };
      http.post('/service_log/doc/' + this.props.doc?.id, formdata, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => {
        if (data.status == 'success') {
          // this.props.fetchLogs();
          this.setState({
            docModal: false,
            docFields: { name: '', },
            docErrors: {},
          });
        }
      });
    }
  };

  onChangeDoc = (e) => {
    let value = e.target.value;
    this.setState({
      docFields: {
        ...this.state.docFields,
        [e.target.name]: e.target.name == 'file' ? e.target.files[0] : value
      }
    });
  };

  handleDelete = (id, anchorEl, data = null) => {
    this.props.setDelete({
      modal: true,
      id,
      anchorEl,
      onConfirm: () => {
        if(this.props.doc.modal == 'user' || this.props.doc.modal == 'contract') {
          this.deleteUser(id, data);
        } 
        else if(this.props.doc.modal == 'discrepancy') {
          this.deleteDiscrepancy(id, data);
        }
        else if(this.props.doc.modal == 'service_log') {
          this.deleteLogDoc(id, data);
        }
        else this.deleteK9(id); 
      }
    });
  };

  closeDrawer = () => {
    this.setState({ docFields: {} });
    this.props.closeDoc();
  };

  downloadFile = (url, filename) => {
    axios({
      url: url,
      method: 'GET',
      responseType: 'blob'
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    });
  };

  onDocSubmit = (e) => {
    switch (this.props.doc.modal) {
      case 'user':
        this.addUser(e);
        break;
      case 'k9':
        this.addK9(e);
        break;
      case 'contract':
        this.addDoc(e);
        break;
      case 'discrepancy':
        this.addDiscrepancyDoc(e);
        break;
      case 'service_log':
        this.addLogDoc(e);
        break;
    }
  };

  render() {
    return (
      <>
        <DocModal
          drawer={this.props.doc.modal}
          closeDrawer={this.closeDrawer}
          name={this.props.doc.name}
          docs={this.props.doc.data}
          userFields={this.state.userFields}
          userErrors={this.state.userErrors}
          onChangeUser={this.onChangeUser}
          addUser={this.addUser}
          editUser={this.editUser}
          k9Fields={this.state.k9Fields}
          k9Errors={this.state.k9Errors}
          onChangeK9={this.onChangeK9}
          addK9={this.addK9}
          editK9={this.editK9}
          handleDelete={this.handleDelete}
          handleOpen={this.handleOpen}
          onClose={this.onClose}
          modal={this.state.modal}
          action={this.state.action}
          docfields={this.state.docFields}
          docerrors={this.state.docErrors}
          onChangeDoc={this.onChangeDoc}
          // onSubmitDoc={this.addDoc}
          downloadFile={this.downloadFile}
          // addDiscrepancyDoc={this.addDiscrepancyDoc}
          addLogDoc={this.addLogDoc}
          onDocSubmit={this.onDocSubmit}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { doc: state.doc };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeDoc: () => dispatch(setDocAction()),
    setDelete: (data) => dispatch(setDeleteAction(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocModalContainer);