import React, { Fragment } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { CustomDialogTitle } from '../../custom/components';
import styles from '../../custom/style.module.css';
import { CloseIconBtn, DateFormat } from 'custom/constants';
import moment from 'moment';

const ContractExpiry = (props) => {

  return (
    <div>
      <Dialog
        open={props.contractExpiry}
        onClose={props.onClose}
        aria-labelledby=''
        aria-describedby=''
        maxWidth={'sm'}
        fullWidth
      >
        <CustomDialogTitle id='form-dialog-title'>
          {'Expiring Contracts'}
          <CloseIconBtn onClick={props.onClose} />
        </CustomDialogTitle>
        <DialogContent>
          <DialogContentText>
            <List>
              {props.contract?.map((item, i) => {
                const today = moment();
                const targetDate = moment(item.end_date);
                const daysDifference = targetDate.diff(today, 'days');
                let duration = ` ${moment(item.start_date,'YYYY MM DD').format(DateFormat).toUpperCase()} - ${moment(item.end_date,'YYYY MM DD').format(DateFormat).toUpperCase()}`;
                let contract = <div className={styles.fSpaceBtw}>
                  <Typography variant='body2' component={'p'} fontSize={'0.98rem'} color={'primary'} marginBottom={0.5}>
                    {`${item.airline.name } - ${item.type}`}</Typography>
                  <Typography variant='body1' component={'p'} color={'error'} fontWeight={600} fontStyle={'italic'} marginBottom={0}>
                    {`${daysDifference} days left`}</Typography>
                </div>;
                if( daysDifference > 0 && daysDifference < 15) {
                  return (<Fragment  key={i}>
                    <ListItem className={styles.px0}>
                      <ListItemText primary={contract} secondary={duration} />
                    </ListItem>
                    <Divider component='li' />
                  </Fragment>
                  );
                }
              })}
            </List>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ContractExpiry;
