import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = 'lightgrey';
const fontSizeText = 9;

const InvoiceTableHeader = () => {
  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      borderBottomColor: 'lightgrey',
      backgroundColor: '#eaeaea',
      borderBottomWidth: 1,
      alignItems: 'center',
      height: 20,
      textAlign: 'center',
      // fontStyle: 'bold',
      flexGrow: 1,
      // color: 'black'
    },
    description: {
      width: '40%',
      // width: '20%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
      fontSize: fontSizeText,
      // fontFamily: 'Open Sans',
      fontWeight: 600 
    },
    qty: {
      width: '20%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
      fontSize: fontSizeText,
      // fontFamily: 'Open Sans',
      fontWeight: 600 
    },
    rate: {
      width: '20%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
      fontSize: fontSizeText,
      // fontFamily: 'Open Sans',
      fontWeight: 600 
    },
    amount: {
      width: '20%' ,
      fontSize: fontSizeText,
      // fontFamily: 'Open Sans',
      fontWeight: 600 
    },
  });
  return <View style={styles.container}>
    <Text style={styles.description}>Line Item</Text>
    {/* <Text style={styles.description}>Driver</Text>
    <Text style={styles.description}>Source</Text> */}
    <Text style={styles.qty}>Quantity</Text>
    <Text style={styles.rate}>Rate</Text>
    <Text style={styles.amount}>Amount</Text>
  </View>;
};
  
export default InvoiceTableHeader;