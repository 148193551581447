import React from 'react';
import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import InvoiceTitle from './InvioiceTitle';
import InvoiceNo from './InvoiceNo';
import BillTo from './BillTo';
import BillFrom from './BillFrom';
import InvoiceItemsTable from './InvoiceItemsTable';
import moment from 'moment';
import cookies from 'utils/cookies';
import { DateFormat } from 'custom/constants';
const timezone = cookies.get('timezone') || null;
const station_code = cookies.get('station_code') || null;


const INVOICE_NOTE = 'Note: A 5% GST will be applicable on all future services.';

const LOCALE_OBJ = {
  locale: 'en-US',
  options: {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
};

// Create styles
const styles = StyleSheet.create({
  page: {
    // fontFamily: 'Helvetica',
    // fontFamily: 'Open Sans',
    fontSize: 11,
    paddingTop: 30,
    paddingLeft:60,
    paddingRight:60,
    lineHeight: 1.5,
    flexDirection: 'column',
    paddingBottom: 65,
    paddingHorizontal: 35,
  }, 
  lastPage: { 
    // fontFamily: 'Open Sans',
    fontSize: 11,
    paddingTop: 30,
    paddingLeft:60,
    paddingRight:60,
    lineHeight: 1.5,
    flexDirection: 'column',
    paddingBottom: 75, 
    paddingHorizontal: 35,
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  invoiceHead: {
    // marginBottom: '10px',
    borderBottom: 'none' 
  },
  titleContainer:{
    flexDirection: 'row',
    justifyContent: 'space-between', 
    paddingBottom: 4,
    paddingTop: 4,
    borderBottom: '1px solid lightgrey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  invoiceNote: {
    fontSize: 8,
    textAlign: 'left',
    color: 'grey',
    marginTop: 'auto'
  },
  date: {
    fontSize: 10,
    color: 'grey',
  }, 
  label: {
    fontSize: 10,
    marginTop: 'auto',
    fontWeight: 600,
    paddingRight: 10, 
  }, 
  invoiceDateContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const getInvoiceHeader = (props) => {
  return {
    id: '5df3180a09ea16dc4b95f910',
    invoice_no: props.invoice_no,
    balance: '$2,283.74',
    company: 'MANTRIX',
    email: 'susanafuentes@mantrix.com',
    phone: '(800) 542 27 46',
    address: '2461 NW 67th AveCargo Building 700,',
    address2: ' Miami, FL 33122, USA',
    trans_date: timezone? moment().tz(timezone).format(DateFormat).toUpperCase() : null, 
    due_date: timezone? moment().add('1','months').tz(timezone).format(DateFormat).toUpperCase() : null,
    station: station_code, 
  };
};

const getInvoiceDate = (searchFields) => {
  let from = searchFields.from;
  let to = searchFields.to;
  if (searchFields.month) {
    if (searchFields.period === 'month') {
      from = moment(searchFields.month, 'YYYY-MM').startOf('month').format(DateFormat);
      to = moment(searchFields.month, 'YYYY-MM').endOf('month').format(DateFormat);
    } else if (searchFields.period === 'first') {
      from = moment(searchFields.month, 'YYYY-MM').startOf('month').format(DateFormat);
      to = moment(searchFields.month, 'YYYY-MM').startOf('month').add(14, 'days').format(DateFormat);
    } else {
      from = moment(searchFields.month, 'YYYY-MM').startOf('month').add(15, 'days').format(DateFormat);
      to = moment(searchFields.month, 'YYYY-MM').endOf('month').format(DateFormat);
    }
  }
  return `${from.toUpperCase()} - ${to.toUpperCase()}`;
};

// Create Document Component
const MyDocument = (props) => (
  <Document>
    <Page size='A4' style={styles.page} wrap={true}>
      <View>
        <View style={[styles.titleContainer, styles.invoiceHead]}>
          <InvoiceTitle />
          <InvoiceNo invoice={getInvoiceHeader(props)} />
        </View>
        <View style={styles.titleContainer}>
          <BillFrom invoice={props.invoice_info} />
          <BillTo invoice={props.invoice_info} />
        </View>
      </View>
      <View style={styles.titleContainer}>
        <View style={styles.invoiceDateContainer}>
          <Text style={styles.label}>Billing Period</Text>
          <Text style={styles.date}>{getInvoiceDate(props.searchFields)}</Text>
        </View>
        <View style={styles.invoiceDateContainer}>
          <Text style={styles.label}>Department</Text>
          <Text style={styles.date}>{props.cont_item}</Text>
        </View>
      </View>
      <InvoiceItemsTable invoice={{ 
        ...getInvoiceHeader(props),
        flightdata: props.flightdata,
        daylogdata: props.daylogdata,
        monthlogdata: props.monthlogdata,
        invoiceData: props.invoiceData,
        dayContractData: props.dayContractData,
        monthContractData: props.monthContractData,
        invoice_info: props.invoice_info,
        oneOff: props.oneOff,
        airlines_code: props.airlineCode,
        searchFields: props.searchFields,
        billingFields: props.billingFields,
        localeObj: LOCALE_OBJ,
      }} />

      <Text style={styles.invoiceNote} render={({ pageNumber, totalPages }) => (
        pageNumber === totalPages ? INVOICE_NOTE : '' 
      )} fixed />

      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed />

    </Page>
  </Document>
    
);

export default MyDocument;
