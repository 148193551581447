import React from 'react';
import DocStreamsList from './DocStreamList';
import DocViewer from './DocViewer';
import DocVerification from './DocVerification';
import { Grid } from '@mui/material';
import http from '../../../utils/http';
import flightHttp from '../../../utils/flightHttp';
import dayjs from 'dayjs';
import events from '../../../utils/evemts';
import { pdfjs } from 'react-pdf';
import AddDocModal from './AddDocModal';
import docStyles from './docs.module.css';
import cookies from 'utils/cookies';
import AwbModal from './AwbModal';
import { getLocatDate } from 'utils/converter';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { setDeleteAction } from 'store/actions/deleteAction';
import ConfirmModalContainer from '../common/confirmModal/ConfirmModalContainer';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const awbRegex = /^[0-9]{3}-[0-9]{8}$/;

class DocsStreamContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      exModal: false,
      docs: [],
      data: [],
      exData: {},
      searchOptions: {
        start: getLocatDate('DD MMM YYYY'),
        end: getLocatDate('DD MMM YYYY'),
        verified: false,
        identity: 'all',
        awb: ''
      },
      awbs: [],
      formFields: {},
      docType: 'AWB',
      docId: 0,
      airlineList: [],
      selectedDoc: {},
      docData: [],
      awbData: {},
      docFields: {},
      docErrors: {},
      updateButton: false,
      flightDates: [],
      fileType: 'local',
      addDocType: 'AWB',
      attachTo: 'OTHER',
      value: 'pending',
      isEditable: false,
      attachment: false,
      popmodal: false,
    };
    this.station_code = cookies.get('station_code') || null;
  }

  componentDidMount() {
    this.fetchAirlines();
    this.fetchDocs(false);
    this.eventSource = events('docs', 'stream');
    this.eventSource.onmessage = ({ type, data }) => {
      if (type == 'message') {
        const msg = JSON.parse(data || '{}');
        this.handleEvent(msg);
      }
    };
  }

  componentWillUnmount() {
    this.eventSource.close();
  }

  handleEvent = (msg) => {
    if ((msg.action == 'add' || msg.action == 'update') && msg.data.created) {
      const start = dayjs(this.state.searchOptions.start).format('YYYYMMDD');
      const end = dayjs(this.state.searchOptions.end).format('YYYYMMDD');
      const created = dayjs(msg.data.created).format('YYYYMMDD');
      if (created >= start && created <= end) {
        if (msg.action == 'add') {
          this.setState({ docs: [...this.state.docs, msg.data] }, () => {
            this.filterDocs();
          });
        }
        if (msg.action == 'update') {
          const docs = [...this.state.docs];
          const index = docs.findIndex(c => msg.data && c.id == msg.data.id);
          if (index > -1) {
            let notify = !docs[index].verified && msg.data.verified;
            docs[index] = { ...msg.data };
            this.setState({ docs }, () => {
              if (notify) {
                toast.success('Document verified successfully');
              }
              if (this.state.searchOptions.verified) {
                if (this.state.docId == msg.data.id) {
                  this.selectDoc(docs[index]);
                }
              } else {
                if (this.state.docId == msg.data.id && msg.data.verified) {
                  this.clearDoc();
                } else {
                  this.selectDoc(docs[index]);
                }
              }
              this.filterDocs();
            });
          } else {
            this.fetchDocs(false);
          }
        }
      }
    }
    if (msg.action == 'delete') {
      const docs = [...this.state.docs];
      const index = docs.findIndex(c => msg.data && c.id == msg.data.id);
      if (index > -1) {
        docs.splice(index, 1);
        this.setState({ docs }, () => {
          this.filterDocs();
        });
      }
    }
  };

  handleChange = (event, type) => {
    const value = event.target ? event.target.value : event;

    if (type === 'awb') {
      this.setState((prevState) => ({
        searchOptions: {
          ...prevState.searchOptions,
          [type]: value
        }
      }), () => {
        if (!value) {
          this.fetchDocs(false);
        }
      });
    } else if (type === 'docType') {
      let formFields = {};
      if (value === 'OTHER') {
        formFields = { sub_type: 'OTHER' };
      }
      this.setState({
        docType: value,
        awbs: [],
        updateButton: false,
        formFields: formFields
      });
    } else if (type === 'verified') {
      this.setState((prevState) => ({
        searchOptions: {
          ...prevState.searchOptions,
          [type]: !prevState.searchOptions.verified
        }
      }), () => {
        this.clearDoc();
        this.filterDocs();
      });
    } else if (type === 'identity') {
      this.setState((prevState) => ({
        searchOptions: {
          ...prevState.searchOptions,
          [type]: value
        }
      }), () => {
        this.clearDoc();
        this.filterDocs();
      });
    } else {
      this.setState((prevState) => ({
        searchOptions: {
          ...prevState.searchOptions,
          awb: '',
          [type]: value
        }
      }), () => {
        this.clearDoc();
        this.fetchDocs(false);
      });
    }
  };


  handleChangeForm = (event, name) => {
    let value = event.target ? event.target.value : event;

    if (name == 'awb_no') {
      value = value.replace(/\D/g, '');
      if (value.length > 3) {
        value = `${value.slice(0, 3)}-${value.slice(3)}`;
      }
    }

    let formFieldsValue = {
      ...this.state.formFields,
      [name]: ['org', 'dest']?.includes(name) ? value?.toUpperCase() : value
    };
    if (name == 'airlineId') {
      const foundObject = this.state.airlineList.find(item => item.id === value);
      formFieldsValue = {
        ...this.state.formFields,
        [name]: value,
        ['airline']: foundObject.code
      };
    }
    this.setState({ formFields: formFieldsValue }, () => {
      if (this.state.formFields.airlineId && this.state.formFields.flight_no && this.state.formFields.flight_date && ['airlineId', 'flight_no', 'flight_date'].includes(name)) {
        this.getFlightOrgDest();
      }
    });
  };

  getFlightOrgDest = () => {
    let query = '';
    const formFields = this.state.formFields;
    query = `airlineId=${formFields.airlineId}&flight_no=${formFields.flight_no}&flight_date=${dayjs(formFields.flight_date).format('YYYY-MM-DD')}`;
    http.get('/check/flight?' + query).then(({ data }) => {
      if (data.status == 'success') {
        if (data && data.data.length > 0) {
          if (data.data.length == 1) {
            this.setState((prevState) => ({
              formFields: {
                ...prevState.formFields,
                flights: null,
                org: data?.data[0].org,
                dest: data?.data[0].dest
              }
            }));
          } else {
            this.setState((prevState) => ({
              formFields: {
                ...prevState.formFields,
                flights: data.data,
                org: '',
                dest: ''
              }
            }));
          }
        }
        else {
          this.setState((prevState) => ({
            formFields: {
              ...prevState.formFields,
              flights: null,
              org: '',
              dest: ''
            }
          }));
        }
      }
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  getFlightDates = () => {
    const formFields = this.state.formFields;
    const airlineCode = this.getCodeById(formFields.airlineId);
    let url = `/flight?org=${this.station_code}&flight_no=${formFields.flight_no}&airline=${airlineCode}`;
    flightHttp.get(url)
      .then((response) => {
        this.setState({ flightDates: response.data.data });
      });
  };


  submit = async (e) => {
    e.preventDefault();
    let formdata = { ...this.state.formFields };
    // if (this.validation()) {
    formdata.type = this.state.docType;
    formdata.docId = this.state.docId;
    formdata.flight_date = formdata.flight_date ? dayjs(formdata.flight_date).format('YYYY-MM-DD') : formdata.flight_date;
    if (this.state.docType == 'AWB') {
      formdata.awbId = this.state.selectedDoc.awb ? this.state.selectedDoc.awb.id : null;
    } else if (this.state.docType == 'FFM') {
      formdata.flightId = this.state.selectedDoc.flight ? this.state.selectedDoc.flight.id : null;
    }
    if(formdata.contact_type == 'sms') {
      let country_code = formdata?.country_code;
      formdata.contact = country_code + formdata.contact;
    }
    http.put(`/doc_stream/${this.state.docType?.toLowerCase()}/${this.state.docId}`, formdata);

    // }
  };

  fetchAirlines = () => {
    http.get(`/airline?active=${true}`)
      .then((response) => {
        this.setState({ airlineList: response.data.data });
      });
  };

  checkAwb = () => {
    const { awb_no } = this.state.formFields;
    if (awb_no && awbRegex.test(awb_no)) {
      http.get(`/awb/check/${awb_no}`).then(({ data }) => {
        this.setState({ awbs: data.data });
      });
    } else {
      this.setState({ awbs: [] });
    }
  };

  searchAwb = () => {
    const { awb_no } = this.state.formFields;
    if (awb_no && awbRegex.test(awb_no)) {
      http.get(`/search_awb/${awb_no}`).then(({ data }) => {
        if (data.data.found) {
          this.setState({
            exModal: true,
            exData: data.data.awb
          });
        } else {
          this.setState({
            formFields: {
              ...this.state.formFields,
              ...data.data.awb
            }
          });
        }
      });
    } else {
      this.setState({ awbs: [] });
    }
  };

  attachAwb = () => {
    http.get(`/doc_stream/attach_awb/${this.state.docId}/${this.state.exData.id}`).then(() => {
      this.setState({
        exModal: false,
        exData: {}
      });
    });
  };

  newAWB = () => {
    const { awb_no } = this.state.formFields;
    http.get(`/awb_info/${awb_no}`).then(({ data }) => {
      this.setState({
        formFields: {
          ...this.state.formFields,
          version: this.state.exData.version + 1 || 1,
          ...data.data
        },
        exModal: false,
        exData: {}
      });
    });
  };

  fetchDocs = (search = false) => {
    let query = '';
    if (search) {
      query = `awb=${this.state.searchOptions.awb}`;
    } else {
      query = `start=${dayjs(this.state.searchOptions.start).format('YYYYMMDD')}&end=${dayjs(this.state.searchOptions.end).format('YYYYMMDD')}`;
    }
    let url = `/docs?${query}`;
    // if (id)
    //   url += `&docId=${id}`;
    this.clearDoc();
    http.get(url, this.state.searchOptions)
      .then((response) => {
        // handle success
        this.setState({ docs: response.data.data }, () => {
          this.filterDocs();
        });
      }).catch(() => {
        this.setState({ docs: [] }, () => {
          this.filterDocs();
        });
      });
  };

  filterDocs = () => {
    let data = [...this.state.docs || []];
    if (!this.state.searchOptions.awb) {
      if (this.state.searchOptions.verified) {
        data = data.filter((d) => d.verified === true);
      } else {
        data = data.filter((d) => d.verified === false);
      }
    }
    if (this.state.searchOptions.identity == 'unknown') {
      data = data.filter((d) => d.name == null);
    }
    if (this.state.searchOptions.identity == 'identified') {
      data = data.filter((d) => d.name != null);
    }
    this.setState({ data }, () => {
      let isSelectedDoc = this.state.data.find(doc => doc.id === this.state.selectedDoc.id); 
      if (data.length > 0 && !isSelectedDoc) {
        this.selectDoc(data[0]);
      }
      else if (data.length == 0) {
        this.setState({
          docData: [],
          docId: 0,
          selectedDoc: {},
        });
      }
    });
  };

  getCodeById(id) {
    const airline = this.state.airlineList.find(airline => airline.id === id);
    return airline ? airline.code : null;
  }

  handleOpen = (modal, data = {}) => {
    let modalData = data || {};
    let country_code = '';
    let contactNumber = data.requests?.[0]?.contact_type == 'email' ? data.requests?.[0]?.contact : '';
    if (modal == 'addDoc') {
      modalData = {
        ...data,
        contact_type: 'sms',
      };
    }
    if (modal == 'thirdPartyDoc') {
      if (data.requests.length > 0 && data.requests?.[0]?.contact && data.requests?.[0]?.contact_type == 'sms') {
        let mobile = `${data.requests?.[0]?.contact}`;
        const match = mobile.match(/^(\+\d{1,4})(\d{10})$/);
        if (match) {
          country_code = match[1];
          contactNumber = match[2];
        }
      }
      modalData = {
        ...data,
        ...data?.requests?.[0] || {},
        country_code,
        contact: contactNumber
      };
    }
    this.setState({
      modal,
      docFields: modalData
    });
  };

  validateDoc = () => {
    const docFields = { ...this.state.docFields };
    const error = {};
    let formValid = true;
    if (!docFields.name || (docFields.name.trim().length === 0)) {
      formValid = false;
      error.name = 'Please enter name.';
    }
    if (!docFields.file) {
      formValid = false;
      error.remark = 'Please select file.';
    }
    // if (docFields.file && docFields.file.size > 1000000) {
    //   formValid = false;
    //   error.remark = 'File size cannot be more than 1mb';
    // }
    this.setState({ docErrors: error });
    return formValid;
  };

  addDoc = (e) => {
    e.preventDefault();
    if (this.validateDoc()) {
      let formdata = { ...this.state.docFields };
      http.post('/doc_stream/add', formdata, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            docFields: {},
            docFieldsErrors: {},
          });
        }
      });
    }
  };

  onSubmitReq = (e) => {
    e.preventDefault();
    let formdata = { ...this.state.docFields };
    if(formdata.contact_type == 'sms') {
      let country_code = formdata.country_code;
      formdata.contact = country_code + formdata.contact;
    }
    http.post('/doc_req', formdata).then(({ data }) => {
      if (data.status == 'success') {
        this.setState({
          modal: false,
          docFields: {},   
          docFieldsErrors: {},
        });
      }
    }
    );
  };

  onUpdateReq = () => {
    let formdata = { ...this.state.docFields };
    if(formdata.contact_type == 'sms') {
      let country_code = formdata.country_code;
      formdata.contact = country_code + formdata.contact;
    }
    http.post(`/doc_req/${formdata.id}`, formdata).then(({ data }) => {
      if (data.status == 'success') {
        this.setState({
          modal: false,
          docFields: {},
          docFieldsErrors: {},
          isEditable: false
        });
      }
    });
  };

  clearDoc = () => {
    this.setState({
      docId: 0,
      selectedDoc: {},
      formFields: {},
      docType: 'AWB',
      docData: [],
      awbs: []
    });
  };

  selectDoc = (doc) => {
    const docId = doc.id;
    const docData = [doc];
    let formFields = { ...doc.data || {} };
    let docType = doc.type || 'AWB';

    if (doc.awb && doc.type == 'AWB') {
      formFields = {
        ...doc.awb,
        awb_no: `${doc.awb.code}-${doc.awb.number}`
      };
    } else if (doc.flight && doc.type == 'FFM') {
      formFields = { ...doc.flight };
    } else if (doc.type === 'OTHER') {
      formFields = {
        ...doc.data,
        sub_type: doc.sub_type,
        ...(doc?.flight && doc?.flight),
        ...(doc?.awb && doc?.awb),
        userId: doc.userId,
        name: doc.name,
      };
    } else if (doc.type == 'FFM') {
      let airline_code = doc?.data?.flight_no ? doc.data?.flight_no?.substring(0, 2) : '';
      let flight_no = doc?.data?.flight_no ? doc.data?.flight_no?.replaceAll(' ', '')?.substring(2) : '';
      let airlineId = this.state.airlineList.find(airline => airline.code === airline_code)?.id;
      formFields = {
        ...doc.data,
        airlineId,
        flight_no
      };
    }
    this.setState({
      docId,
      selectedDoc: doc,
      formFields,
      docType,
      docData,
      awbs: []
    }, () => {});
  };

  formatPhoneNumberForTwilio = (input) => {
    // Remove all non-digit characters from the input
    let cleaned = input.replace(/\D/g, '');
    let number = `${cleaned}`;
    return number;
  };

  onChangeDoc = (e) => {
    console.log(this.state.docFields);
    let value = e.target.value;
    if (this.state.docFields?.contact_type == 'sms' && e.target.name == 'contact') {
      value = this.formatPhoneNumberForTwilio(value);
    }
    this.setState({
      docFields: {
        ...this.state.docFields,
        [e.target.name]: e.target.name == 'file' ? e.target.files[0] : value
      }
    });
  };

  onClose = () => {
    this.setState({
      modal: false,
      exModal: false,
      isEditable: false,
      docFields: {},
      docErrors: {},
    });
  };

  handleDelete = (id, anchorEl) => {
    console.log(id, anchorEl);
    this.props.setDelete({
      modal: true,
      id,
      anchorEl,
      onConfirm: () => this.deleteDoc(id),
    });
  };

  deleteDoc = (id) => {
    this.props.setDelete({
      modal: false,
      anchorEl: null 
    });
    http.delete(`/document/${id}`).then(() => {
    });
  };

  handlePopover = (attachment, anchorEl) => {
    this.setState({
      attachment,
      anchorEl,
    });
  };

  handlePopoverClose = () => {
    this.setState({
      attachment: false,
      anchorEl: false
    });
  };

  onhandleChange = (e) => {
    let state = {};
    if (e.target.name == 'fileType') {
      if (e.target.value == 'third') {
        state['docFields'] = {
          name: '',
          type: 'AWB',
          contact_type: 'sms',
        };
      }
    } else {
      state['docFields'] = {};
    }
    state['docErrors'] = {};
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      [name]: value,
      ...state
    });
  };

  handleToggle = (e) => {
    let checked = e.target.value;
    this.setState({ value: checked });
  };

  handleEditDoc = () => {
    this.setState({ isEditable: true });
  };

  closeEditDoc = () => {
    this.setState({ isEditable: false });
  };

  handleReminder = (id) => {
    http.get(`/doc_req/reminder/${id}`).then(({ data }) => {
      if (data.status == 'success') {
        toast.success('Reminder sent successfully');
      }
    });
  };

  render() {
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item className={`${docStyles.docSec} ${docStyles.docSecList}`} order={1}>
            <DocStreamsList
              changeAwb={this.handleChange}
              searchOptions={this.state.searchOptions}
              docs={this.state.data}
              selectDoc={this.selectDoc}
              docId={this.state.docId}
              search={this.fetchDocs}
              deleteDoc={this.deleteDoc}
              handleOpen={this.handleOpen}
              handlePopover={this.handlePopover}
              handlePopoverClose={this.handlePopoverClose}
              anchorEl={this.state.anchorEl}
              attachment={this.state.attachment}
              modal={this.state.modal}
              modalData={this.state.docFields}
              modalDataErrors={this.state.docErrors}
              onClose={this.onClose}
              handleToggle={this.handleToggle}
              value={this.state.value}
              handleEditDoc={this.handleEditDoc}
              closeEditDoc={this.closeEditDoc}
              isEditable={this.state.isEditable}
              onUpdateReq={this.onUpdateReq}
              onChange={this.onChangeDoc}
              handleDelete={this.handleDelete}
              handleReminder={this.handleReminder}
            />
          </Grid>
          <Grid item xs className={`${docStyles.docView}`} order={{
            xs: 2,
            sm: 3,
            md: 3,
            lg: 2,
          }}>
            <DocViewer
              docData={this.state.docData}
            />
          </Grid>
          <Grid item className={`${docStyles.docSec} ${docStyles.docSecVerify}`} order={{
            xs: 3,
            sm: 2,
            md: 2,
            lg: 3,
          }}>
            <DocVerification
              docType={this.state.docType}
              handleChange={this.handleChange}
              changeFields={this.handleChangeForm}
              formFields={this.state.formFields}
              submit={this.submit}
              airlines={this.state.airlineList}
              docId={this.state.docId}
              awbs={this.state.awbs}
              checkAwb={this.checkAwb}
              searchAwb={this.searchAwb}
              disableButton={this.state.updateButton}
            />
          </Grid>
        </Grid>
        <AddDocModal
          onClose={this.onClose}
          modal={this.state.modal}
          fields={this.state.docFields}
          errors={this.state.docErrors}
          onChange={this.onChangeDoc}
          onSubmit={this.addDoc}
          onSubmitReq={this.onSubmitReq}
          fileType={this.state.fileType}
          addDocType={this.state.addDocType}
          attachTo={this.state.attachTo}
          onhandleChange={this.onhandleChange}
        />
        <AwbModal
          onClose={this.onClose}
          modal={this.state.exModal}
          attachAwb={this.attachAwb}
          newAWB={this.newAWB}
        />
        <ConfirmModalContainer />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return { setDelete: (data) => dispatch(setDeleteAction(data)) };
};

export default connect(null, mapDispatchToProps)(DocsStreamContainer);