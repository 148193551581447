import React from 'react';
import { Dialog, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import styles from '../../../custom/style.module.css';
import docStyles from './docs.module.css';
import { CustomDialogTitle, CustomDialogContent,TableBadge, CustomDialogAction, CustomButton } from '../../../custom/components';
import { CloseIconBtn } from 'custom/constants';
import PartyDoc from './forms/PartyDoc';
import notificationBellIcon from '../../../assets/images/actionIcons/bellNotification.svg';


const PendingDoc = (props) => {

  const { onClose, modal, onUpdateReq, modalData, onChange, handleReminder } = props;

  return (
    <div>
      <Dialog
        open={modal === 'thirdPartyDoc'}
        onClose={onClose}
        aria-labelledby=''
        aria-describedby=''
        maxWidth={'sm'}
        fullWidth
      >
        <CustomDialogTitle>
          <Typography variant='span' component='span' fontSize={'1.4rem'}>Request for third party doc details</Typography>
          <CloseIconBtn onClick={onClose} />
        </CustomDialogTitle>

        <CustomDialogContent>
          {!modalData?.request_status && <Stack direction={'row'} spacing={0.5} borderBottom={'1px solid lightgrey'} mb={'10px'} pr={'10px'} justifyContent={'flex-end'} className={docStyles.notificationBellWrapper}>
            <Typography variant='body2' component={'span'} color='primary' fontSize={'14px'} className={`${styles.bellAnimation}`}>Want to send a reminder?</Typography>
            <div className={`${docStyles.notificationBell} ${styles.iconBtn}`}>
              <TableBadge badgeContent={modalData?.requests?.length} color='error' >
                <Tooltip title='Reminder'>
                  <IconButton size='small' onClick={() => handleReminder(modalData.id)}>
                    <img className={`${styles.bell} ${styles.primarySvg}`} src={notificationBellIcon} alt='reminder-icon' width={20} height={20}  />
                  </IconButton>
                </Tooltip>
              </TableBadge>
            </div>
          </Stack>}
            
          <PartyDoc fields={modalData} onChange={onChange} />
        </CustomDialogContent>
        <CustomDialogAction className={styles.fSpaceBtw}>
          <CustomButton variant='contained' type='reset'> Close</CustomButton>
          <CustomButton variant='contained' type='submit' onClick={() => onUpdateReq()}>Update</CustomButton>
        </CustomDialogAction>
      </Dialog>
    </div>
  );
};
export default PendingDoc;