import React from 'react';
import { Document, Page, StyleSheet, View, Text } from '@react-pdf/renderer';
import moment from 'moment';
import cookies from 'utils/cookies';
import Awbs from './Awbs';
const timezone = cookies.get('timezone') || null;
const station_code = cookies.get('station_code') || null;

const invoice = {
  trans_date: timezone? moment().tz(timezone).format('DD MMM YYYY') : null, 
  due_date: timezone? moment().add('1','months').tz(timezone).format('DD MMM YYYY') : null,
  station: station_code, 
};

const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    paddingTop: 30,
    paddingLeft:60,
    paddingRight:60,
    lineHeight: 1.5,
    flexDirection: 'column',
    paddingBottom: 65,
    paddingHorizontal: 35,
  }, 
  lastPage: { 
    fontSize: 11,
    paddingTop: 30,
    paddingLeft:60,
    paddingRight:60,
    lineHeight: 1.5,
    flexDirection: 'column',
    paddingBottom: 75, 
    paddingHorizontal: 35,
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  titleContainer:{
    flexDirection: 'row',
    justifyContent: 'space-between', 
    alignItems: 'flex-end',
    // paddingBottom: 4,
    paddingTop: 4,
    borderBottom: '1px solid lightgrey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  invoiceNote: {
    fontSize: 8,
    textAlign: 'left',
    color: 'grey',
    marginTop: 'auto'
  }, 
  label: {
    fontSize: 10,
    marginTop: 'auto',
    fontWeight: 600,
    paddingRight: 10, 
  }, 
  invoiceDateContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerContainer: { marginTop: 10 },
  billTo: {
    fontSize: 10,
    paddingBottom: 3,
    lineHeight: 'normal',
  },

  invoiceNoContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  invoiceDate: {
    fontSize: 12,
    fontStyle: 'bold',
  },
  date: {
    fontSize: 10,
    color: 'grey',
    width: 100,
    textAlign: 'right',
  },
  invoiceLabel: {
    fontSize: 14,
    fontStyle: 'bold',
    textTransform: 'uppercase',
    marginTop: 'auto',
  },   
});


const MyDocument = (props) => {
  const { manifestData, ffm, airlineCode } = props;
  return (
    <Document>
      <Page size='A4' style={styles.page} wrap={true}>
        <View style={styles.titleContainer}>
          <View>
            <Text style={styles.invoiceLabel}>No scan List</Text>
            <Text style={styles.date}>{`${airlineCode}${ffm.flight_no} | ${moment(ffm.flight_date).format('DD MMM YYYY').toUpperCase()}`}</Text>
          </View>
          <Text style={styles.date}>{invoice.trans_date} | {invoice.station}</Text>
        </View>
        <Awbs manifestData={manifestData} />
  
        {/* <Text style={styles.invoiceNote} render={({ pageNumber, totalPages }) => (
          pageNumber === totalPages ? invoiceNote : '' 
        )} fixed />
  
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed /> */}
  
      </Page>
    </Document>
  );
};

export default MyDocument;
