import React from 'react';
import { Popover, Typography } from '@mui/material';
// import { DatePicker } from '@mui/x-date-pickers';
// import dayjs from 'dayjs';
import styles from '../../../../../custom/style.module.css';
import { PopoverHead, PopoverContent, PopoverFooter, CustomDatePicker, CustomButton, CustomInputLabel, InputProps } from '../../../../../custom/components';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

const RetrainingModal = (props) => {
  const { anchorEl, handleTraining, training } = props;
  return (
    <div>
      <Popover
        open={training}
        anchorEl={anchorEl}
        onClose={() => handleTraining(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <PopoverHead>
          <Typography variant='subtitle2' component='span'>Remove Training Expiry Alert</Typography>
          <CloseIcon fontSize='small' className={styles.pointer} onClick={() => handleTraining(false)} />
        </PopoverHead>
        <PopoverContent className={styles.textCenter}>
          <Typography variant='caption' component='div' color='error' fontWeight={600} lineHeight='normal' marginBottom={'1.3rem'} marginTop={'0.4rem'}>Are you sure you wish to remove the training expiry alert?</Typography>
          <CustomInputLabel htmlFor='' aria-label='Enter expiry date'>Enter updated training expiry date</CustomInputLabel>
          <CustomDatePicker
            slotProps={{
              textField: {
                ...InputProps,
                placeholder: 'Enter Expiry Date',
                fullWidth: true
              }, 
            }}  
            format='DD MMM YYYY'
            className={`${styles.minWidth125}`}
            onChange={(v) => props.onChangeUser(
              {
                target: {
                  type: 'date',
                  name: 'expiry_date',
                  value: moment(v.$d).format('YYYY-MM-DD')
                }
              }, 'expiry_date')}
            // value={dayjs(moment(props.userFields.retraining_date).format('YYYY-MM-DD'))}
            value={props.userFields?.expiry_date}
          />
        </PopoverContent>
        <PopoverFooter className={styles.textCenter}>
          <CustomButton variant='contained' size='small' className={styles.minWidth150} onClick={props.handleRetraining}>Update</CustomButton>
        </PopoverFooter>
      </Popover>
    </div>
  );
};
export default RetrainingModal;