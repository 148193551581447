import React, { Fragment } from 'react';
import styles from 'custom/style.module.css';
import flightStyles from '../flightsfolder.module.css';
import { FormControl, Grid, Typography, MenuItem, Tooltip, Checkbox } from '@mui/material';
import { CustomCheckbox, CustomInputLabel, CustomSelect, SelectInputProps, StyledCard, StyledCardTitle } from 'custom/components';

const ManifestSummary = (props) => {
  const { showReportModal } = props;

  function formatNumber(num) {
    return num % 1 === 0 ? Math.round(num) : num.toFixed(1);
  }

  return (
    <div className={styles.positionRelative}>
      <CustomCheckbox
        control={
          <Checkbox inputProps={{ 'aria-label': 'manifest', }} checked={props.showManifest} onChange={e => props.changeCheck(e)} name='view_manifest'
            sx={{
              paddingTop: '6px',
              paddingBottom: '6px',
            }}
          />
        }
        label='Hide Manifest Summary'
        className={`${styles.mr1} ${styles.fJustifyEnd} ${flightStyles.manifestCheckbox}`}
      />
      {!props.showManifest &&
        <StyledCard className={`${styles.mb4} ${flightStyles.manifestCard} ${props.nil ? styles.cardOverlay : ''}`}>
          {/* ${showReportModal ? flightStyles.printCard  : ''} */}
          <div className={`${styles.mb4} ${styles.fSpaceBtw}`} >
            <StyledCardTitle gutterBottom variant='div'>Manifest Summary</StyledCardTitle>
            <div className={showReportModal ? flightStyles.hideReport : `${styles.fvMiddle} ${styles.gap10}`}>
              <CustomInputLabel id='product-filter' aria-label='Enter product'> Filter by product:</CustomInputLabel>
              <FormControl fullWidth className={styles.width170}>
                <CustomSelect
                  {...SelectInputProps}
                  id='product'
                  labelId='product-filter'
                  fullWidth
                  autoFocus
                  name='product'
                  value={props?.product}
                  onChange={props?.handleProductChange}
                >
                  <MenuItem value='ALL'>All</MenuItem>
                  {props?.productsList?.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </FormControl>
            </div>
          </div>
          <Grid container spacing={6} justifyContent={'space-between'}>
            <Grid item xs={12} md={3.5}>
              <Typography variant='div' component='div' textAlign='center' className={flightStyles.summaryHead}>Loose</Typography>
              <Grid container spacing={2} sx={{ height: 'calc(100% - 24px)' }}>
                <Grid item xs={12} sm={3} md={6} lg={4} xl={4} className={`${flightStyles.summaryBlock} ${styles.hAuto}`}>
                  <Fragment>
                    <div className={flightStyles.title}>Awb</div>
                    <div className={`${flightStyles.data} ${flightStyles.dataAlign}`}>
                      <span className={`${flightStyles.dataInner}`}>
                        <Tooltip title={props.manifestSummary?.loose?.awbs}>
                          <>{props.manifestSummary?.loose?.awbs}</>
                        </Tooltip>
                      </span>
                    </div>
                  </Fragment>
                </Grid>
                <Grid item xs={12} sm={3} md={6} lg={4} xl={4} className={`${flightStyles.summaryBlock} ${styles.hAuto}`}>
                  <Fragment>
                    <div className={flightStyles.title}>Pieces</div>
                    <div className={`${flightStyles.data} ${flightStyles.dataAlign}`}>
                      <span className={`${flightStyles.dataInner}`}>
                        <Tooltip title={props.manifestSummary?.loose?.pcs}>
                          <>{props.manifestSummary?.loose?.pcs}</>
                        </Tooltip>
                      </span>
                    </div>
                  </Fragment>
                </Grid>
                <Grid item xs={12} sm={3} md={6} lg={4} xl={4} className={`${flightStyles.summaryBlock} ${styles.hAuto}`}>
                  <Fragment>
                    <div className={flightStyles.title}>Weight</div>
                    <div className={`${flightStyles.data} ${flightStyles.dataAlign}`}>
                      <span className={`${flightStyles.dataInner}`}>
                        <Tooltip title={props.manifestSummary?.loose?.wgt}>
                          <>{formatNumber(Math.round(props.manifestSummary?.loose?.wgt*100)/100)}</>
                        </Tooltip>
                      </span>
                    </div>
                  </Fragment>
                </Grid>
                {/* <Grid item xs={12} sm={3} md={6} lg={3} xl={3} className={flightStyles.summaryBlock}>
                  <Fragment>
                    <div className={flightStyles.title}>Ulds</div>
                    <div className={`${flightStyles.data} ${styles.fSpaceBtw}`}>
                      <div className={styles.fColumn}>
                        <small className={styles.textUppercase}>ld3</small>
                        <Tooltip title={props.manifestSummary?.looseulds?.LD3}>
                          <span className={`${flightStyles.dataInnerUld}`}>
                            {props.manifestSummary?.looseulds?.LD3}</span>
                        </Tooltip>
                      </div>
                      <div className={styles.fColumn}>
                        <small className={styles.textUppercase}>ld7</small>
                        <Tooltip title={props.manifestSummary?.looseulds?.LD7}>
                          <span className={`${flightStyles.dataInnerUld}`}>
                            {props.manifestSummary?.looseulds?.LD7}</span>
                        </Tooltip>

                      </div>
                      <div className={styles.fColumn}>
                        <small className={styles.textUppercase}>md</small>
                        <Tooltip title={props.manifestSummary?.looseulds?.MD}>
                          <span className={`${flightStyles.dataInnerUld}`}>
                            {props.manifestSummary?.looseulds?.MD}</span>
                        </Tooltip>
                      </div>
                    </div>
                  </Fragment>
                </Grid> */}
              </Grid>

            </Grid>
            <Grid item xs={12} md={4.25}>
              <Typography variant='div' component='div' textAlign='center' className={flightStyles.summaryHead}>Intact</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={6} lg={4} xl={3} className={flightStyles.summaryBlock}>
                  <Fragment>
                    <div className={flightStyles.title}>Awb</div>
                    <div className={`${flightStyles.data} ${flightStyles.dataAlign}`}>
                      <span className={`${flightStyles.dataInner}`}>
                        <Tooltip title={props.manifestSummary?.intact?.awbs}>
                          <>{props.manifestSummary?.intact?.awbs}</>
                        </Tooltip>
                      </span>
                    </div>
                  </Fragment>
                </Grid>
                <Grid item xs={12} sm={3} md={6} lg={4} xl={3} className={flightStyles.summaryBlock}>
                  <Fragment>
                    <div className={flightStyles.title}>Pieces</div>
                    <div className={`${flightStyles.data} ${flightStyles.dataAlign}`}>
                      <span className={`${flightStyles.dataInner}`}>
                        <Tooltip title={props.manifestSummary?.intact?.pcs}>
                          <>{props.manifestSummary?.intact?.pcs}</>
                        </Tooltip>
                      </span>
                    </div>
                  </Fragment>
                </Grid>
                <Grid item xs={12} sm={3} md={6} lg={4} xl={3} className={flightStyles.summaryBlock}>
                  <Fragment>
                    <div className={flightStyles.title}>Weight</div>
                    <div className={`${flightStyles.data} ${flightStyles.dataAlign}`}>
                      <span className={`${flightStyles.dataInner}`}>
                        <Tooltip title={props.manifestSummary?.intact?.wgt}>
                          <>{formatNumber(Math.round(props.manifestSummary?.intact?.wgt*100)/100)}</>
                        </Tooltip>
                      </span>
                    </div>
                  </Fragment>
                </Grid>
                <Grid item xs={12} sm={3} md={6} lg={12} xl={3} className={flightStyles.summaryBlock}>
                  <Fragment>
                    <div className={flightStyles.title}>Ulds</div>
                    <div className={`${flightStyles.data} ${styles.fSpaceBtw}`}>
                      <div className={styles.fColumn}>
                        <small className={styles.textUppercase}>ld3</small>
                        <Tooltip title={props.manifestSummary?.intactulds?.LD3}>
                          <span className={`${flightStyles.dataInnerUld}`}>
                            {props.manifestSummary?.intactulds?.LD3}</span>
                        </Tooltip>
                      </div>
                      <div className={styles.fColumn}>
                        <small className={styles.textUppercase}>ld7</small>
                        <Tooltip title={props.manifestSummary?.intactulds?.LD7}>
                          <span className={`${flightStyles.dataInnerUld}`}>
                            {props.manifestSummary?.intactulds?.LD7}</span>
                        </Tooltip>

                      </div>
                      <div className={styles.fColumn}>
                        <small className={styles.textUppercase}>md</small>
                        <Tooltip title={props.manifestSummary?.intactulds?.MD}>
                          <span className={`${flightStyles.dataInnerUld}`}>
                            {props.manifestSummary?.intactulds?.MD}</span>
                        </Tooltip>
                      </div>
                    </div>
                  </Fragment>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4.25}>
              <Typography variant='div' component='div' textAlign='center' className={flightStyles.summaryHead}>Total</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={6} lg={4} xl={3} className={flightStyles.summaryBlock}>
                  <Fragment>
                    <div className={flightStyles.title}>Awb</div>
                    <div className={`${flightStyles.data} ${flightStyles.dataAlign}`}>
                      <span className={`${flightStyles.dataInner}`}>
                        <Tooltip title={props.manifestSummary?.total?.awbs}>
                          <>{props.manifestSummary?.total?.awbs}</>
                        </Tooltip>
                      </span>
                    </div>
                  </Fragment>
                </Grid>
                <Grid item xs={12} sm={3} md={6} lg={4} xl={3} className={flightStyles.summaryBlock}>
                  <Fragment>
                    <div className={flightStyles.title}>Pieces</div>
                    <div className={`${flightStyles.data} ${flightStyles.dataAlign}`}>
                      <span className={`${flightStyles.dataInner}`}>
                        <Tooltip title={props.manifestSummary?.total?.pcs}>
                          <>{props.manifestSummary?.total?.pcs}</>
                        </Tooltip>
                      </span>
                    </div>
                  </Fragment>
                </Grid>
                <Grid item xs={12} sm={3} md={6} lg={4} xl={3} className={flightStyles.summaryBlock}>
                  <Fragment>
                    <div className={flightStyles.title}>Weight</div>
                    <div className={`${flightStyles.data} ${flightStyles.dataAlign}`}>
                      <span className={`${flightStyles.dataInner}`}>
                        <Tooltip title={props.manifestSummary?.total?.wgt}>
                          <>{formatNumber(Math.round(props.manifestSummary?.total?.wgt*100)/100)} </>
                        </Tooltip>
                      </span>
                    </div>
                  </Fragment>
                </Grid>
                <Grid item xs={12} sm={3} md={6} lg={12} xl={3} className={flightStyles.summaryBlock}>
                  <Fragment>
                    <div className={flightStyles.title}>Ulds</div>
                    <div className={`${flightStyles.data} ${styles.fSpaceBtw}`}>
                      <div className={styles.fColumn}>
                        <small className={styles.textUppercase}>ld3</small>
                        <Tooltip title={props.manifestSummary?.totalulds?.LD3}>
                          <span className={`${flightStyles.dataInnerUld}`}>
                            {props.manifestSummary?.totalulds?.LD3}</span>
                        </Tooltip>
                      </div>
                      <div className={styles.fColumn}>
                        <small className={styles.textUppercase}>ld7</small>
                        <Tooltip title={props.manifestSummary?.totalulds?.LD7}>
                          <span className={`${flightStyles.dataInnerUld}`}>
                            {props.manifestSummary?.totalulds?.LD7}</span>
                        </Tooltip>

                      </div>
                      <div className={styles.fColumn}>
                        <small className={styles.textUppercase}>md</small>
                        <Tooltip title={props.manifestSummary?.totalulds?.MD}>
                          <span className={`${flightStyles.dataInnerUld}`}>
                            {props.manifestSummary?.totalulds?.MD}</span>
                        </Tooltip>
                      </div>
                    </div>
                  </Fragment>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </StyledCard>}
    </div>
  );
};

export default ManifestSummary;