import React from 'react';
import { Checkbox, FormGroup, Popover, Typography } from '@mui/material';
import { PopoverHead, PopoverContent, PopoverFooter, CustomButton, CustomCheckbox } from '../../../../custom/components';
import userStyles from '../../admin/tabs/manageusers/users.module.css';
import styles from '../../../../custom/style.module.css';
import theme from '../../../../custom/theme';
import { CloseIconBtn } from 'custom/constants';

const Location = (props) => {
  const { handleClose, anchorEl, id, open, stations, station, onChange, onUpdate, error, name, type } = props;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClose={() => handleClose(type, false, props.data, null)}
    >
      <PopoverHead>
        <Typography component='span' fontSize={'0.9rem'} fontWeight={600} >Multi location Access</Typography>
        <CloseIconBtn onClick={() => handleClose(type, false, props.data, null)}  />
      </PopoverHead>
      <PopoverContent className={styles.textCenter} sx={{ maxWidth: '320px' }}>
        <Typography variant='subtitle2' component='p' color={theme.palette.primary.main} gutterBottom fontWeight='bold'>{name}</Typography>
        <Typography variant='caption' component='p' gutterBottom>
          {type == 'user' ? 'Check all locations this user should have access to.' : `Check all the locations that handles ${type == 'airlinechecklist' ? 'this check' : name}`}</Typography>
        <FormGroup aria-label='position' className={`${userStyles.locCheckWrapper} `}>
          {stations && stations.map(s => {
            return (
              <CustomCheckbox key={s.id} control={<Checkbox inputProps={{ 'aria-label': s.code, }} size='small' sx={{ padding: '0.4rem' }} />} value={s.id} onChange={onChange} checked={station && station.includes(s.id)} label={s.code} labelPlacement='start' />
            );
          })}
        </FormGroup>
        {error}
      </PopoverContent>
      <PopoverFooter className={styles.textCenter}>
        <CustomButton variant='contained' size='small' className={styles.minWidth150} onClick={onUpdate}>Update</CustomButton>
      </PopoverFooter>
    </Popover>
  );
};

export default Location;