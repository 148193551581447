import { Grid, Switch } from '@mui/material';
import React from 'react';

const Developer = (props) => {
  const getLable = (key) => {
    switch (key) {
      case 'ffm_flight_no':
        return 'FFM Flight No';
      case 'ffm_flight_date':
        return 'FFM Flight Date';
      case 'ffm_edit':
        return 'FFM Edit';
      default:
        return key;
    }
  };
  return (
    <Grid container spacing={5} padding={5}>
      <Grid item xs={12}>
        <div>
          <h1>Flight Manifest (Company Level)</h1>
          {props.config.map((item, index) => {
            if (item.companyId)
              return (
                <div key={index}>
                  <h3>{getLable(item.key)} <Switch name={item.id} defaultChecked={item?.value?.data ? item?.value?.data === 'true' : false} onChange={props.onChange} /></h3>
                </div>);
          })}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div>
          <h1>Flight Data API (Global)</h1>
          {props.stations.map((item, index) => {
            return (
              <div key={index}>
                <h3>{getLable(item)} <Switch name={item} defaultChecked={props.config.find((c) => c.key === 'flight_api' && c.value && c.value?.includes(item)) ? true : false} onChange={props.onChangeStation} /></h3>
              </div>);
          })}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div>
          <h1>Authorized Users</h1>
          {props.userList.map((item, index) => {
            return (
              <ul key={index}>
                <li>{item.username}</li>
              </ul>);
          })}
        </div>
      </Grid>
    </Grid>
  );
};

export default Developer;