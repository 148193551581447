import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { getStationDate } from 'utils/converter';

import moment from 'moment/moment';

const styles = StyleSheet.create({
  headerContainer: { marginTop: 10 },
  label: {
    fontSize: 10,
    paddingBottom: 5,
    lineHeight: 'normal',
  },
  flightNumberLabel: {
    fontSize: 12,
    paddingBottom: 3,
    lineHeight: 'normal',
    fontWeight: 'bold',
  },
  flightNumberData: {
    fontSize: 12,
    paddingBottom: 3,
    lineHeight: 'normal',
    color: 'grey'
  },
  data: {
    fontSize: 10,
    paddingBottom: 3,
    lineHeight: 'normal',
    color: 'grey'
  },
  fSpaceBtw:{
    flexDirection: 'row',
    justifyContent: 'space-between', 
    paddingBottom: 4,
    paddingTop: 4,
  },
  row:{
    flexDirection: 'row',
    borderRight: '1px solid lightgrey'
    // paddingBottom: 4,
    // paddingTop: 4,
  },
  directionRow:{ flexDirection: 'row', },
  info:{
    width: 'calc(100% / 6)',
    padding: 4,
    border: '1px solid lightgrey',
    borderRight: 'none'
  }
});


const FlightInfo = (props) => {
  const { info, flight_gate } = props;

  return (
    <View style={styles.headerContainer}>
      <View style={styles.fSpaceBtw}>
        <Text style={styles.flightNumberLabel}>Flight Number</Text>
        <Text style={styles.flightNumberData}>{
          info?.airline?.code + info?.flight_no + '/' + moment(info?.flight_date).format('DDMMMYY')}
        </Text>
      </View>
      <View style={styles.row}>
        <View style={styles.info}>
          <Text style={styles.label}>AC Type</Text>
          <Text style={styles.data}>{info?.ac_type}</Text>
        </View>     
        <View style={styles.info}>
          <Text style={styles.label}>Registration</Text>
          <Text style={styles.data}>{info?.reg_no}</Text>
        </View>
        <View style={styles.info}>
          <Text style={styles.label}>Routing</Text>
          <Text style={styles.data}>{info?.org + '-' + info?.dest}</Text>
        </View>
        <View style={styles.info}>
          <Text style={styles.label}>Departure</Text>
          <View style={styles.directionRow}>
            <Text style={styles.data}>{info?.act_dept ? 
              getStationDate(info?.org, info?.act_dept, 'DDMMMYY HHmm') : (info?.est_dept ? 
                getStationDate(info?.org, info?.est_dept, 'DDMMMYY HHmm') : (info?.schdl_dept ? 
                  getStationDate(info?.org, info?.schdl_dept, 'DDMMMYY HHmm') : null))}</Text>
            <Text style={styles.data}>{info?.act_dept ? '(A)' : (info?.est_dept ? '(E)' : '(S)')}</Text>
          </View>
        </View>     
        <View style={styles.info}>
          <Text style={styles.label}>Arrival</Text>
          <View style={styles.directionRow}>
            <Text style={styles.data}>{info?.act_arr ? 
              getStationDate(info?.dest, info?.act_arr, 'DDMMMYY HHmm') : (info?.est_arr ? 
                getStationDate(info?.dest, info?.est_arr, 'DDMMMYY HHmm') : (info?.schdl_arr ? 
                  getStationDate(info?.dest, info?.schdl_arr, 'DDMMMYY HHmm') : null))}</Text>
            <Text style={styles.data}>{info?.act_arr ? '(A)' : (info?.est_arr ? '(E)' : '(S)')}</Text>
          </View>
        </View>      
        <View style={styles.info}>
          <Text style={styles.label}>Gate</Text>
          <Text style={styles.data}>{flight_gate}</Text>
        </View>
      </View>
      {/* <Text style={styles.data}>{flightReportDetails.address2}</Text>
      <Text style={styles.data}>{flightReportDetails.phone}</Text> */}
      {/* <Text style={styles.data}>{invoice.email}</Text> */}
    </View>
  );
};
  
export default FlightInfo;