import React from 'react';
import { Dialog } from '@mui/material';
import styles from '../../../../../custom/style.module.css';
import { CustomButton, CustomDialogAction, CustomDialogContent, CustomDialogTitle, CustomInputLabel, CustomTextField, InputProps, StyledCard } from '../../../../../custom/components';
import { CloseIconBtn } from 'custom/constants';

const WhiteListModal = (props) => {
  const { action, mailFields, mailErrors, onChange, addMail, editMail } = props;
  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={props.whiteListModal} onClose={props.onClose} maxWidth={'sm'} fullWidth>
        <CustomDialogTitle id='form-dialog-title' className={styles.modalTitle}>
          {action == 'add' ? 'Add' : 'Edit'} Email
          <CloseIconBtn onClick={props.onClose} />
        </CustomDialogTitle>
        <form onSubmit={action == 'add' ? addMail : editMail} onReset={props.onClose} className={styles.w100}>
          <CustomDialogContent>
            <StyledCard>
              <CustomInputLabel htmlFor='mail'>Email Address</CustomInputLabel>
              <CustomTextField
                {...InputProps}
                fullWidth
                autoFocus
                id='mail'
                placeholder='Email Address'
                type='email'
                onChange={onChange}
                name='mail'
                value={mailFields.mail}
                error={mailErrors.mail}
                helperText={mailErrors.mail}
              />
            </StyledCard>
          </CustomDialogContent>
          <CustomDialogAction className={`${styles.fSpaceBtw}`}>
            <CustomButton variant='contained' type='reset'> Close</CustomButton>
            <CustomButton variant='contained' type='submit'>{action == 'add' ? 'Submit' : 'Update'}</CustomButton>
          </CustomDialogAction>
        </form>
      </Dialog>
    </div>
  );
};
export default WhiteListModal;