import React from 'react';
import {
  Box,
  Grid,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography, 
} from '@mui/material';
import Logo from '../../../../../assets/images/tristarLogo.png';
import styles from '../../../../../custom/style.module.css';
import cookies from 'utils/cookies';
import moment from 'moment';
import { StyledCard, StyledCardTitle } from 'custom/components';

const timezone = cookies.get('timezone') || null;
const station_code = cookies.get('station_code') || null;

const invoice = {
  trans_date: timezone? moment().tz(timezone).format('DDMMMYYYY').toUpperCase() : null, 
  due_date: timezone? moment().add('1','months').tz(timezone).format('DD MMM YYYY') : null,
  station: station_code, 
};

const TaxDocPreview = (props) => {
  console.log('props', props);
  return (
    <>
      <StyledCard sx={{
        overflow: 'auto',
        maxHeight: 'calc(var(--contentBox))',
        minHeight: '100%', 
      }}>
        <StyledCardTitle component='div' className={styles.pb3}>Invoice Preview</StyledCardTitle>
        <Box sx={{
          width: 'clamp(300px, 100%, 550px)',
          margin: 'auto',
          border: '1px solid #eee',
          boxShadow: '0px 0px 1px #eaeaea',
          borderRadius: '4px',
          padding: '24px',
          backgroundColor: '#fff',
        }}>
          <Grid container spacing={3}>
            <Grid item container xs={12} md={12} alignItems={'center'}>
              <Grid item xs={12} md={6}>
                <img src={Logo} alt='logo' width={200} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack direction='column' justifyContent='flex-end' alignItems='flex-end'>
                  {props.invoice_code_text ?
                    <Typography variant='h6' component='h6' color='primary' fontSize={'1.2rem'}>{props.invoice_code_text} </Typography>
                    : <Skeleton variant='text' width={'80%'} sx={{ fontSize: '1.4rem' }} />}
                  <Typography variant='caption' component='div' color='text.secondary'>
                    {invoice.trans_date} | {invoice.station}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
              
            <Grid item container xs={12} md={12} justifyContent={'space-between'} spacing={3}>
              <Grid item xs={12} md={6}>
                {Object.keys(props.from_details).some(key => props.from_details[key])  ? 
                  <>
                    <Typography variant='subtitle2' component='div' color='primary' fontSize={'0.8rem'}>{props.from_details.from_name}</Typography>
                    <Typography variant='subtitle2' component='div' color='primary' fontSize={'0.8rem'}>{props.from_details.from_address}</Typography>
                    <Typography variant='subtitle2' component='div' color='primary' fontSize={'0.8rem'}>{props.from_details.from_contact}</Typography>
                  </>
                  : 
                  <>
                    <Skeleton variant='text' width={'80%'} sx={{ fontSize: '1rem' }} />
                    <Skeleton variant='text' width={'80%'} sx={{ fontSize: '1rem' }} />
                    <Skeleton variant='text' width={'80%'} sx={{ fontSize: '1rem' }} />
                    <Skeleton variant='text' width={'50%'} sx={{ fontSize: '1rem' }} />
                  </>
                }
              </Grid>

              <Grid item xs></Grid>
              
              <Grid item xs={12} md={4.5}>
                {Object.keys(props.from_details).some(key => props.from_details[key])  ? 
                  <>
                    <Typography variant='subtitle2' component='div' color='primary' fontSize={'0.8rem'}>{props.to_details.to_name}</Typography>
                    <Typography variant='subtitle2' component='div' color='primary' fontSize={'0.8rem'}>{props.to_details.to_address}</Typography>
                    <Typography variant='subtitle2' component='div' color='primary' fontSize={'0.8rem'}>{props.to_details.to_contact}</Typography>
                  </>
                  :
                  <>
                    <Skeleton variant='text' width={'100%'} sx={{ fontSize: '1rem' }} />
                    <Skeleton variant='text' width={'100%'} sx={{ fontSize: '1rem' }} />
                    <Skeleton variant='text' width={'100%'} sx={{ fontSize: '1rem' }} />
                    <Skeleton variant='text' width={'80%'} sx={{ fontSize: '1rem' }} />
                  </>
                }
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Stack direction='row' justifyContent={'space-between'} borderTop={'1px solid lightgrey'} borderBottom={'1px solid lightgrey'} py={1}>
                <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2}>
                  <Typography variant='subtitle2' component='div' color='primary' fontSize={'0.8rem'}>Billing Period</Typography>
                  <Typography variant='subtitle2' component='div' color='text.secondary' fontSize={'0.8rem'}> 01JUL24 - 31JUL24</Typography>
                </Stack>
                <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
                  <Typography variant='subtitle2' component='div' color='primary' fontSize={'0.8rem'}>Department</Typography>
                  <Typography variant='subtitle2' component='div' color='text.secondary' fontSize={'0.8rem'}>Cargo Handling</Typography>
                </Stack>
              </Stack>
            </Grid>

            <Grid item container xs={12} md={12}>
              <Skeleton variant='rounded' width='100%' height={60} animation={false} /> 
            </Grid>
            <Grid item container xs={12} md={12}>
              <Skeleton variant='rounded' width='100%' height={60} animation={false} /> 
            </Grid>
            
            <Grid item container xs={12} md={12}>
              <Typography component='div' key={'body1'} variant={'body1'} width={'100%'}>
                <Skeleton animation={false} />
              </Typography>
              <Typography component='div' key={'body1'} variant={'body1'} width={'100%'}>
                <Skeleton animation={false} />
              </Typography>
              <Typography component='div' key={'body1'} variant={'body1'} width={'100%'}>
                <Skeleton animation={false} />
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} className={styles.mlauto}>
              <TableContainer>
                <Table size='small' >
                  <TableBody>
                    <TableRow>
                      <TableCell component='th' scope='row'>
                        <Typography variant='subtitle2' component='div' color='primary' fontSize={'0.8rem'}>SubTotal</Typography>
                      </TableCell>
                      <TableCell align='right'><Typography variant='subtitle2' component='div' color='primary' fontSize={'0.8rem'}>$ xxx,xxx.xx</Typography></TableCell>
                    </TableRow>
                    {props.taxList.map((tax, index) => (
                      <TableRow key={index}>
                        <TableCell component='th' scope='row'>
                          <Typography variant='subtitle2' component='div' color='primary' fontSize={'0.8rem'}>
                            {tax.title}{tax.type === 'Percentage' ? ` ${tax.value}%`: '' }
                          </Typography>
                        </TableCell>
                        <TableCell align='right'><Typography variant='subtitle2' component='div' color='primary' fontSize={'0.8rem'}>$ xxx,xxx.xx</Typography></TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell component='th' scope='row'>
                        <Typography variant='subtitle2' component='div' color='primary' fontSize={'0.8rem'}>
                      Grand Total
                        </Typography></TableCell>
                      <TableCell align='right'><Typography variant='subtitle2' component='div' color='primary' fontSize={'0.8rem'}>$ xxx,xxx.xx</Typography></TableCell>

                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>          
          </Grid>
        </Box>
      </StyledCard>
    </>

  );
};
export default TaxDocPreview;