import React from 'react';
import headerStyles from './header.module.css';

export const loaderRef = React.createRef();


const Loader = () => {
  return (
    <div ref={loaderRef} className={headerStyles.loaderWrapper}>
      <div className={headerStyles.loader}></div>
    </div>
  );
};

export default Loader;