import React from 'react';
import moment from 'moment';
import http from 'utils/http';
import exportFromJSON from 'export-from-json';
import ManageContracts from './ManageContracts';
import { setDeleteAction } from 'store/actions/deleteAction';
import { setDocAction } from 'store/actions/docAction';
import { connect } from 'react-redux';
// import events from 'utils/evemts';
import cloneDeep from 'lodash/cloneDeep';
import { appliedOnItems } from 'custom/constants';
import { dateToUTC, getLocatDate } from 'utils/converter';
import cookies from 'utils/cookies';
import { toast } from 'react-toastify';

class ManageContractsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      attachment: false,
      modal: false,
      action: null,
      awb: null,
      docs: [],
      data: [],
      awbs: [],
      airlineList: [],
      contract: [],
      filteredData: [], 
      logs: [],
      airline: [],
      products: [],
      flight: [],
      measure: [],
      tab: 'active',
      searchOptions: {
        from: getLocatDate('YYYY-MM-DD'),
        to: getLocatDate('YYYY-MM-DD'),
      },
      search: {
        from: moment().format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
        f_time: '00:00',
        t_time: '23:59',
        awb: null,
      },
      filter: {
        airline: null,
        airlinecode: null,
        flight: null,
        measure: null,
        is_minimum: false,
        minimum_rate: null,

        controw_modaldata: {},  //Add Contract Line Item Add button
      },
      contractFieldEdit: {},
      recordOns: [],
      segments: [],
      controwField: {
        description: null,
        applied_on: null,
        is_product: false,
        productId: null,
        rate_type: 'stable',
        is_minimum: false,
        minimum: null,
        record_on: null,
        segment: null,
        remarks: '',
        roles: null,
        platform: null,
      },
      contrateField: {
        rate: null,
        tier1: null,
        tier2: null,
        tier3: null,
        level1: null,
        level2: null,
        level3: null,
        level1low: 0,
        level2low: '-',
        level3low: '-',
        rate1: null,
        rate2: null,
        rate3: null,
        nolimit2: false,
        nolimit3: false,
      },
      contractErrors: {},
      contractErrorsEdit: {},
      controwErrors: {},
      contrateErrors: {},

      searchFields: {},
      timer: null,
      sessionExpired: false,
    };
    this.spanRef = React.createRef();
    this.eventSource = null;
  }

  componentDidMount() {
    this.startTimer();
    this.fetchAirlines();
    this.fetchContract();
    // this.eventSource = events('admin', 'contract');
    // this.eventSource.onmessage = (event) => {
    //   const msg = JSON.parse(event.data || '{}');
    //   if (msg.data && this.state.tab === 'active') {
    //     if (msg.action == 'add_contract') {
    //       this.setState({ contract: [...this.state.contract || [], msg.data] }, () => { this.sortcontrates(); });
    //     }
    //     if (msg.action == 'update_contract') {
    //       let contract = [...this.state.contract];
    //       const index = contract.findIndex(c => msg.data && c.id == msg.data.id);
    //       if (index > -1) {
    //         let contract_height = cloneDeep(contract[index].contract_height);
    //         msg.data.contractrows = contract[index].contractrows;
    //         contract[index] = { ...msg.data };
    //         contract[index].contract_height = contract_height || 0;
    //         this.setState({ contract }, () => { this.sortcontrates(); });
    //       }
    //     }
    //     if (msg.action == 'delete_contract') {
    //       let contract = [...this.state.contract];
    //       const index = contract.findIndex(c => msg.data && c.id == msg.data.id);
    //       if (index > -1) {
    //         contract.splice(index, 1);
    //         this.setState({ contract }, () => { this.sortcontrates(); });
    //       }
    //     }
    //     if (msg.action == 'add_controw') {
    //       const contract = [...this.state.contract];
    //       const index = contract.findIndex(c => msg.data && c.id == msg.data.contractId);
    //       if (index > -1) {
    //         contract[index].contractrows.push({ ...msg.data });
    //         let contract_height = contract[index].contract_height;
    //         contract_height = (contract_height || 0) + 1; // cont_row height
    //         contract[index].contract_height = contract_height;
    //         this.setState({ contract }, () => { this.sortcontrates(); });
    //       }
    //     }
    //     if (msg.action == 'update_controw') {
    //       const contract = [...this.state.contract];
    //       const index = contract.findIndex(c => msg.data && c.id == msg.data.contractId);
    //       if (index > -1) {
    //         const index2 = contract[index].contractrows.findIndex(cr => msg.data && cr.id == msg.data.id);
    //         if (index2 > -1) {
    //           msg.data.contrates = contract[index].contractrows[index2].contrates;
    //           contract[index].contractrows[index2] = { ...msg.data };
    //           this.setState({ contract }, () => { this.sortcontrates(); });
    //         }
    //       }
    //     }
    //     if (msg.action == 'delete_controw') {
    //       let contract = [...this.state.contract];
    //       const index = contract.findIndex(c => msg.data && c.id == msg.data.contractId);
    //       if (index > -1) {
    //         const index2 = contract[index].contractrows.findIndex(cr => msg.data && cr.id == msg.data.id);
    //         if (index2 > -1) {
    //           let contract_height = contract[index].contract_height;
    //           contract_height = contract_height - 1; // cont_row height
    //           contract[index].contract_height = contract_height;
    //           contract[index].contractrows.splice(index2, 1);
    //           this.setState({ contract }, () => { this.sortcontrates(); });
    //         }
    //       }
    //     }
    //     if (msg.action == 'add_contrate') {
    //       const contract = [...this.state.contract];
    //       const index = contract.findIndex(c => msg.data && c.id == msg.data.contractId);
    //       if (index > -1) {
    //         const index2 = contract[index].contractrows.findIndex(cr => msg.data && cr.id == msg.data.cont_rowId);
    //         if (index2 > -1) {
    //           if (contract[index].contractrows[index2].contrates.length !== 0) {
    //             let contract_height = contract[index].contract_height;
    //             contract_height = (contract_height || 0) + 1; // cont_row height
    //             contract[index].contract_height = contract_height;
    //           }
    //           msg.data.tier = msg?.data?.tier ? parseInt(msg?.data?.tier) : null;
    //           contract[index].contractrows[index2].contrates.push({ ...msg.data });
    //           this.setState({ contract }, () => { this.sortcontrates(); });
    //         }
    //       }
    //     }
    //     if (msg.action == 'update_contrate') {
    //       const contract = [...this.state.contract];
    //       const index = contract.findIndex(c => msg.data && c.id == msg.data.contractId);
    //       if (index > -1) {
    //         const index2 = contract[index].contractrows.findIndex(cr => msg.data && cr.id == msg.data.cont_rowId);
    //         if (index2 > -1) {
    //           const index3 = contract[index].contractrows[index2].contrates.findIndex(ct => msg.data && ct.id == msg.data.id);
    //           if (index3 > -1) {
    //             contract[index].contractrows[index2].contrates[index3] = { ...msg.data };
    //             this.setState({ contract }, () => { this.sortcontrates(); });
    //           }
    //         }
    //       }
    //     }
    //     if (msg.action == 'delete_contrate') {
    //       const contract = [...this.state.contract];
    //       const index = contract.findIndex(c => msg.data && parseInt(c.id) == parseInt(msg.data.contractId));
    //       if (index > -1) {
    //         const index2 = contract[index].contractrows.findIndex(cr => msg.data && cr.id == msg.data.cont_rowId);
    //         if (index2 > -1) {
    //           const index3 = contract[index].contractrows[index2].contrates.findIndex(ct => msg.data && ct.id == msg.data.id);
    //           if (index3 > -1) {
    //             let contract_height = contract[index].contract_height;
    //             if (contract[index].contractrows[index2].contrates.length !== 1) {
    //               contract_height = contract_height - 1; // cont_row height
    //               contract[index].contract_height = contract_height;
    //             }
    //             contract[index].contractrows[index2].contrates.splice(index3, 1);
    //             this.setState({ contract }, () => { this.sortcontrates(); });
    //           }
    //         }
    //       }
    //     }
    //     if (msg.action == 'add_doc') {
    //       let contract = [...this.state.contract];
    //       const index = contract.findIndex(c => msg.data && c.id == msg.data.contractId);
    //       if (index > -1) {
    //         contract[index].docs.push(msg.data);
    //         this.setState({ contract }, () => {
    //           this.handleDoc(contract[index]);
    //         });
    //       }
    //     }
    //     if (msg.action == 'delete_doc') {
    //       let contract = cloneDeep(this.state.contract);
    //       const index = contract.findIndex(c => msg.data && c.id == msg.data.contractId);
    //       if (index > -1) {
    //         const index2 = contract[index].docs.findIndex(cr => msg.data && cr.id == msg.data.id);
    //         if (index2 > -1) {
    //           contract[index].docs.splice(index2, 1);
    //           this.setState({ contract }, () => {
    //             this.handleDoc(contract[index]);
    //             this.filterContract();
    //           });
    //         }
    //       }
    //     }
    //   }
    // };
  }
  componentWillUnmount() {
    this.stopInterval();
    // this.eventSource.close();
  }

  startTimer = () => {
    if(cookies.get('expiryTimer')) {
      this.timer = setInterval(() => {
        const currentTime = moment.utc();
        const expirationTime = moment.utc(cookies.get('expiryTimer'));
        const timeDifference = expirationTime - currentTime;

        // Check if the timer has expired
        if (timeDifference <= 0) {
          cookies.remove('expiryTimer', { path: '/' });
          clearInterval(this.timer);
          this.setState({ sessionExpired: true });
          this.stopInterval();
        // Handle timer expiration
        } else {
        // Calculate remaining time
          const seconds = Math.floor((timeDifference / 1000) % 60) || 0;
          const minutes = Math.floor((timeDifference / 1000 / 60) % 60) || 0;
          const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24) || 0;
          const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24)) || 0;
          let sessionExpiryText = 'Session expires in ';
          let remainingTime = 0;
          if( (hours === 0 && minutes === 30 && seconds === 0 )|| (hours === 0 && minutes === 15 && seconds === 0)) {
            remainingTime = minutes;
            toast.warning(`${sessionExpiryText} ${remainingTime} minutes`);
          }
          this.setState({ timer: `Expires in ${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds` }
            // , ()=> {console.log(this.state.timer, 'timer');}
          );
        }
      }, 1000);
    }
  };

  stopInterval = () => {
    clearInterval(this.timer);
    clearInterval(this.state.timer);
    cookies.remove('expiryTimer', { path: '/' });
  };


  fetchAirlines = () => {
    http.get(`/airline?active=${true}`)
      .then((response) => {
        this.setState({ airlineList: response.data.data });
      });
  };
  fetchProducts = (row = {}) => {
    http.get(`/products?airlineId=${row === -1 ? 'ALL' : row || 'ALL'}&common=${true}`)
      .then(response => {
        const { data } = response;
        if (data.status === 'success') {
          if (data.data && data.data.length > 0) {
            this.setState({ products: data.data });
          } else {
            this.setState({ products: [] });
          }
        }
      })
      .catch(error => {
        this.setState({ products: [] });
        console.error('Error fetching data:', error);
      });
  };
  fetchContract = () => {
    http.get(`/contract?type=${this.state.tab}&date=${getLocatDate('YYYY-MM-DD')}`)
      .then(response => {
        const { data } = response;
        if (data.status === 'success') {
          data.data.map((contract, index) => {
            let contract_height = 0;
            if (contract.contractrows.length > 0) {
              contract_height = contract_height + (contract.contractrows.length);
              contract.contractrows.map((controw) => {
                if (controw?.contrates?.length > 0) {
                  contract_height = contract_height + (controw.contrates.length - 1);
                }
              });
            }
            data.data[index].contract_height = contract_height;
          });
          this.setState({ contract: data.data }, () => { this.sortcontrates(); });
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  fetchLogs = () => {
    this.setState({ contract: [] });
    http.get(`/change_log/contract?from=${dateToUTC(this.state.searchOptions.from+' 00:00', 'YYYY-MM-DD HH:mm')}&to=${dateToUTC(this.state.searchOptions.to+' 24:00', 'YYYY-MM-DD HH:mm')}`).then(({ data }) => {
      if (data.status == 'success') {
        this.setState({ logs: data.data });
      }
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  handleSearchOption = (event, type) => {
    let value = event.target ? event.target.value : event;
    // const name = event.target ? event.target.name : event;

    this.setState((prevState) => ({
      searchOptions: {
        ...prevState.searchOptions,
        [type]: value.format('YYYY-MM-DD')
      }
    }), () => {
      this.fetchLogs();
    });
  };

  onChangeContract = (e) => {
    this.setState((prev) => ({
      productField: {
        ...prev.productField,
        [e.target.name]: e.target.value
      }
    }));
  };

  onChangeTab = (e) => {
    if (e.target.value !== this.state.tab)
      this.setState({ tab: e.target.value }, () => {
        e.target.value == 'logs' ? this.fetchLogs() : this.fetchContract();
      });
  };

  validationContracts = () => {
    const contractFieldEdit = { ...this.state.contractFieldEdit };
    const error = {};
    let formValid = true;
    if (!contractFieldEdit.airline) {
      formValid = false;
      error.airline = 'Please enter a airline.';
    }
    if (!contractFieldEdit.contract_type || (contractFieldEdit.contract_type.toString().trim().length === 0)) {
      formValid = false;
      error.contract_type = 'Please select a department.';
    }
    if (!contractFieldEdit.start_date) {
      formValid = false;
      error.start_date = 'Please select a start date.';
    }
    if (!contractFieldEdit.end_date) {
      formValid = false;
      error.end_date = 'Please select a end date.';
    }
    this.setState({ contractErrors: error });
    return formValid;
  };

  validationContractsEdit = () => {
    const contractFieldEdit = { ...this.state.contractFieldEdit };
    const error = {};
    let formValid = true;
    if (!contractFieldEdit.airline) {
      formValid = false;
      error.airline = 'Please enter a airline.';
    }
    if (!contractFieldEdit.contract_type || (contractFieldEdit.contract_type.toString().trim().length === 0)) {
      formValid = false;
      error.contract_type = 'Please select a department.';
    }
    if (!contractFieldEdit.start_date) {
      formValid = false;
      error.start_date = 'Please select a start date.';
    }
    if (!contractFieldEdit.end_date) {
      formValid = false;
      error.end_date = 'Please select a end date.';
    }
    this.setState({ contractErrorsEdit: error });
    return formValid;
  };

  validationControws = () => {
    const controwField = { ...this.state.controwField };
    const contrateField = { ...this.state.contrateField };
    const error1 = {};
    const error2 = {};
    let formValid = true;
    if (!controwField.description) {
      error1.description = 'Please enter description.';
    }
    if (!controwField.applied_on) {
      error1.applied_on = 'Please enter applied on.';
    }
    if (!controwField.record_on) {
      error1.record_on = 'Please select record on.';
    }
    if (!controwField.segment) {
      error1.segment = 'Please select segment.';
    }
    if (controwField.rate_type === 'stable') {
      if (!contrateField.rate) {
        formValid = false;
        error2.rate = 'Please enter rate.';
      }
    } else {
      if ((contrateField.rate1?.trim() && !contrateField.level1?.trim()) || (!contrateField.rate1?.trim() && contrateField.level1?.trim())) {
        formValid = false;
        error2.level1 = 'Please enter level and rate.';
      }
      if ((contrateField.rate2?.trim() && !contrateField.level2?.trim() && !contrateField?.nolimit2 ) || (!contrateField.rate2?.trim() && contrateField.level2?.trim())) {
        formValid = false;
        error2.level2 = 'Please enter level and rate.';
      }
      if ((contrateField.rate3?.trim() && !contrateField.level3?.trim() && !contrateField?.nolimit3) || (!contrateField.rate3?.trim() && contrateField.level3?.trim())) {
        formValid = false;
        error2.level3 = 'Please enter level and rate.';
      }
      if(contrateField?.nolimit2 && !contrateField?.rate2?.trim()) {
        formValid = false;
        error2.level2 = 'Please enter rate.';
      }
      if(contrateField?.nolimit3 && !contrateField?.rate3?.trim()) {
        formValid = false;
        error2.level3 = 'Please enter rate.';
      }
      if (this.state.modal === 'contract_item' && this.state.action === 'edit') {
        let contract = this.state.contract.find(c => c.id == this.state.controw_modaldata?.id);
        let controw = contract?.contractrows.find(cr => cr.id == this.state.controwField?.id);
        let tier1 = controw?.contrates.find(ct => ct.tier == 1);
        let tier2 = controw?.contrates.find(ct => ct.tier == 2);
        let tier3 = controw?.contrates.find(ct => ct.tier == 3);
        if ((!contrateField.rate1?.trim() && tier1?.rate) || (!contrateField.level1?.trim() && tier1?.level)) {
          formValid = false;
          error2.level1 = 'Cannot Clear Level and Rate values on Edit.';
        }
        if ((!contrateField.rate2?.trim() && tier2?.rate) || (!contrateField.level2?.trim() && tier2?.level)) {
          formValid = false;
          error2.level2 = 'Cannot Clear Level and Rate values on Edit.';
        }
        if ((!contrateField.rate3?.trim() && tier3?.rate) || (!contrateField.level3?.trim() && tier3?.level)) {
          formValid = false;
          error2.level3 = 'Cannot Clear Level and Rate values on Edit.';
        }
      }
    }
    this.setState({
      controwErrors: error1,
      contrateErrors: error2,
    });
    return formValid;
  };

  addContract = (e) => {
    e.preventDefault();
    let formdata = {
      airlineId: this.state.contractFieldEdit?.airline?.id,
      type: this.state.contractFieldEdit?.contract_type,
      is_minimum: this.state.contractFieldEdit?.is_minimum,
      minimum_rate: this.state.contractFieldEdit?.minimum_rate,
      start_date: this.state.contractFieldEdit?.start_date,
      end_date: this.state.contractFieldEdit?.end_date,
      mail: this.state.contractFieldEdit?.mail,
    };
    if (this.validationContracts()) {
      http.post('/contract', formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            contractFieldEdit: {
              airline: null,
              airlinecode: null,
              contract_type: null,
              is_minimum: false,
              minimum: null,
            },
            contractErrors: {},
            action: null,
          },()=>{
            this.fetchContract();
          });
        }
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  };

  editContracts = async (e) => {
    e.preventDefault();
    let formdata = {
      id: this.state.contractFieldEdit?.id,
      airlineId: this.state.contractFieldEdit?.airline?.id,
      type: this.state.contractFieldEdit?.contract_type,
      is_minimum: this.state.contractFieldEdit?.is_minimum,
      minimum_rate: this.state.contractFieldEdit?.minimum_rate,
      start_date: this.state.contractFieldEdit?.start_date,
      end_date: this.state.contractFieldEdit?.end_date,
      mail: this.state.contractFieldEdit?.mail,
    };

    // delete formdata.station;
    if (this.validationContractsEdit()) {
      http.put('/contract/' + formdata.id, formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            contractFieldEdit: {},
            action: null,
          },()=>{
            this.fetchContract();
          });
        }
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  };

  deleteContract = async (id) => {
    await http.delete('/contract/' + id).then(({ data }) => {
      if (data.status == 'success') {
        this.props.setDelete();
        this.fetchContract();
      }
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  addControw = async (e) => {
    e.preventDefault();
    let rowformdata = {
      description: this.state.controwField?.description,
      rate_type: this.state.controwField?.rate_type,
      applied_on: this.state.controwField?.applied_on,
      is_product: this.state.controwField?.is_product,
      product: this.state.controwField?.is_product ? this.state.controwField?.productId : null,
      record_on: this.state.controwField?.record_on,
      segment: this.state.controwField?.segment,
      contract: this.state.controw_modaldata?.id,
      is_minimum: this.state.controwField?.is_minimum,
      minimum: this.state.controwField?.is_minimum ? this.state.controwField?.minimum : null,
      remarks: this.state.controwField?.remarks,
      roles: this.state.controwField?.roles,
      platform: this.state.controwField?.platform,
    };
    let rateformdata = {};
    let rates = [];
    if (this.state.controwField?.rate_type === 'stable') {
      let obj = {
        level: null,
        rate: this.state.contrateField?.rate,
        tier: null,
      };
      rates.push(obj);
    } else {
      let obj1 = {
        level: this.state.contrateField?.level1,
        rate: this.state.contrateField?.rate1,
        tier: 1,
      };
      let obj2 = {
        level: this.state.contrateField?.level2,
        rate: this.state.contrateField?.rate2,
        tier: 2,
        nolimit: this.state.contrateField?.nolimit2,
      };
      let obj3 = {
        level: this.state.contrateField?.level3,
        rate: this.state.contrateField?.rate3,
        tier: 3,
        nolimit: this.state.contrateField?.nolimit3,
      };
      rates.push(obj1);
      rates.push(obj2);
      rates.push(obj3);
    }
    rateformdata.rates = rates;
    if (this.validationControws()) {
      http.post('/cont_row', rowformdata).then( async ({ data }) => {
        if (data.status == 'success') {
          await Promise.all(
            rateformdata?.rates.map(async (rate) => {
              rate.cont_row = data.data.id;
              rate.contractId = this.state.controw_modaldata?.id;
              await this.addContrate(e, rate);
            })
          ).then(() => {
            // fetch contracts
            this.fetchContract();
          });
        }
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    } else {
      console.log('failed to validate the form');
    }
  };

  editControw = async (e) => {
    e.preventDefault();
    let rowformdata = {
      description: this.state.controwField?.description,
      rate_type: this.state.controwField?.rate_type,
      applied_on: this.state.controwField?.applied_on,
      is_product: this.state.controwField?.is_product,
      product: this.state.controwField?.is_product ? this.state.controwField?.productId : null,
      record_on: this.state.controwField?.record_on,
      segment: this.state.controwField?.segment,
      contract: this.state.controw_modaldata?.id,
      is_minimum: this.state.controwField?.is_minimum,
      minimum: this.state.controwField?.is_minimum ? this.state.controwField?.minimum : null,
      remarks: this.state.controwField?.remarks && this.state.controwField?.remarks,
      roles: this.state.controwField?.roles,
      platform: this.state.controwField?.platform,
    };
    let cont_rate_ids = [];
    let rateformdata = {};
    let rates = [];
    if (this.state.controwField?.rate_type === 'stable') {
      let obj = {
        level: null,
        rate: this.state.contrateField?.rate,
        id: this.state.contrateField?.id,
        tier: null,
      };
      rates.push(obj);
      cont_rate_ids.push(this.state.contrateField?.id);
    } else {
      let obj1 = {
        level: this.state.contrateField?.level1,
        rate: this.state.contrateField?.rate1,
        id: this.state.contrateField?.id1,
        tier: 1,
      };
      let obj2 = {
        level: this.state.contrateField?.level2,
        rate: this.state.contrateField?.rate2,
        id: this.state.contrateField?.id2,
        tier: 2,
        nolimit: this.state.contrateField?.nolimit2,
      };
      let obj3 = {
        level: this.state.contrateField?.level3,
        rate: this.state.contrateField?.rate3,
        id: this.state.contrateField?.id3,
        tier: 3,
        nolimit: this.state.contrateField?.nolimit3,
      };
      rates.push(obj1);
      rates.push(obj2);
      rates.push(obj3);
      cont_rate_ids.push(this.state.contrateField?.id1);
      cont_rate_ids.push(this.state.contrateField?.id2);
      cont_rate_ids.push(this.state.contrateField?.id3);
    }
    rowformdata.cont_rate_ids = cont_rate_ids;
    rateformdata.rates = rates;
    if (this.validationControws()) {
      http.put(`/cont_row/${this.state.controwField.id}`, rowformdata).then(async ({ data }) => {
        if (data.status == 'success') {
          await Promise.all(
            rateformdata?.rates.map(async (rate) => {
              rate.cont_row = data.data.id;
              rate.contractId = data.data.contractId;
              if (rate?.id) {
                if (rate?.rate) {
                  await this.editContrate(e, rate);
                } else {
                  //throw error rate once set cannot be empty, delete the rate if not required
                }
              } else {
                await this.addContrate(e, rate);
              }
            })
          ).then(() => {
            // fetch contracts
            this.fetchContract();
          });
        }
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    } else {
      console.log('failed to validate the form');
    }
  };

  deleteControw = async (controw) => {
    let formdata = { contractId: controw.contractId };
    await http.put(`/cont_row_del/${controw.id}`, formdata).then(({ data }) => {
      if (data.status == 'success') {
        this.props.setDelete();
        //get contracts list 
        this.fetchContract();
      }
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  addContrate = async (e, rateformdata) => {
    e.preventDefault();
    if (rateformdata.rate) {
      await http.post('/cont_rate', rateformdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            recordOns: [],
            segments: [],
            controwField: {
              description: null,
              rate_type: 'stable',
              applied_on: null,
              is_product: false,
              productId: null,
              record_on: null,
              segment: null,
              is_minimum: false,
              minimum: null,
              roles: null,
              platform: null,
            },
            contrateField: {
              rate: null,
              tier1: null,
              tier2: null,
              tier3: null,
              level1: null,
              level2: null,
              level3: null,
              rate1: null,
              rate2: null,
              rate3: null,
              level1low: 0,
              level2low: '-',
              level3low: '-',
              nolimit2: false,
              nolimit3: false,
            },
            controwErrors: {},
            contrateErrors: {},
            action: null,
          });
        }
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  };

  editContrate = async (e, rateformdata) => {
    e.preventDefault();
    await http.put(`/cont_rate/${rateformdata?.id}`, rateformdata).then(({ data }) => {
      if (data.status == 'success') {
        this.setState({
          modal: false,
          recordOns: [],
          segments: [],
          controwField: {
            description: null,
            rate_type: 'stable',
            applied_on: null,
            is_product: false,
            productId: null,
            record_on: null,
            segment: null,
            is_minimum: false,
            minimum: null,
            roles: null,
            platform: null,
          },
          contrateField: {
            rate: null,
            tier1: null,
            tier2: null,
            tier3: null,
            level1: null,
            level2: null,
            level3: null,
            rate1: null,
            rate2: null,
            rate3: null,
            level1low: 0,
            level2low: '-',
            level3low: '-',
            nolimit2: false,
            nolimit3: false,
          },
          controwErrors: {},
          contrateErrors: {},
          action: null,
        });
      }
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  deleteContrate = async (id, rowdata) => {
    let controw = cloneDeep(rowdata);
    let formdata = {
      cont_rowId: controw.id,
      contractId: controw.contractId,
    };
    await http.put(`/cont_rate_del/${id}`, formdata).then(({ data }) => {
      if (data.status == 'success') {
        this.props.setDelete();
        this.fetchContract();
        if (controw?.contrates && controw?.contrates.length === 1) {
          this.deleteControw(controw);
        }
      }
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  };


  fetchReport = (awb = false) => {
    let query = '';
    if (awb) {
      query = 'awb=' + this.state.search.awb;
    } else {
      query = `from=${this.state.search.from}&to=${this.state.search.to}&f_time=${this.state.search.f_time}&t_time=${this.state.search.t_time}`;
    }
    http.get('/reports?' + query).then(({ data }) => {
      if (data.status == 'success') {
        this.setState({
          data: data.data,
          filter: {
            airline: null,
            flight: null,
            measure: null
          }
        }, () => {
          this.getFilter();
          this.filterData();
        });
      }
    });
  };

  getFilter = () => {
    let airline = [];
    let flight = [];
    let measure = [];
    this.state.data.map(a => {
      let f = a.flight_no + '/' + moment(a.flight_date).format('DDMMMYY').toUpperCase();
      if (!flight.includes(f)) {
        flight.push(f);
      }
      if (a.airline) {
        if (!airline.includes(a.airline.code)) {
          airline.push(a.airline.code);
        }
      }
      a.screens.map(s => {
        if (s.measure) {
          if (!measure.includes(s.measure.type)) {
            measure.push(s.measure.type);
          }
        }
      });
    });
    this.setState({
      airline,
      flight,
      measure
    });
  };

  filterData = () => {
    let awbs = [...this.state.data || []];
    if (this.state.filter.airline && this.state.filter.airline != '') {
      awbs = awbs.filter((a) => a.airline.code === this.state.filter.airline);
    }
    if (this.state.filter.flight && this.state.filter.flight != '') {
      awbs = awbs.filter((a) => {
        let f = a.flight_no + '/' + moment(a.flight_date).format('DDMMMYY').toUpperCase();
        return f === this.state.filter.flight;
      });
    }
    if (this.state.filter.measure && this.state.filter.measure != '') {
      let filterAwbs = [];
      awbs.map(a => {
        let screens = [];
        a.screens.map(s => {
          if (s.measure.type == this.state.filter.measure) {
            screens.push(s);
          }
        });
        if (screens.length > 0) {
          filterAwbs.push({
            ...a,
            screens
          });
        }
      });
      awbs = filterAwbs;
    }

    this.setState({ awbs });
  };
  sortcontrates = () => {
    let contract = [...this.state.contract];
    // contract.sort((a, b) => a.airline?.name.localeCompare(b.airline?.name));
    contract.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    // Sort the 'controws' array within each 'contract' by 'rowname'
    contract.forEach((contractItem) => {
      contractItem.contractrows.sort((a, b) => a.description.localeCompare(b.description));

      // Sort the 'contrates' array within each 'controws' by 'ratename'
      contractItem.contractrows.forEach((controwsItem) => {
        controwsItem.contrates.sort((a, b) => a.tier - b.tier);
      });
    });
    this.setState({ contract }, () => { this.filterContract(); });
  };
  onChangeSearch = (e) => {
    let name = e.target.name;
    let value = e.target.type == 'checkbox' ? e.target.checked : e.target.value;
    if (e.target.type == 'date') {
      value = moment(e.target.value, 'DD-MM-YYYY').format('YYYY-MM-DD');
    }
    this.setState({
      search: {
        ...this.state.search,
        [name]: value
      }
    });
  };

  onChangefilter = (e) => {
    this.setState({
      filter: {
        ...this.state.filter,
        [e.target.name]: e.target.value
      }
    }, () => { this.filterData(); });
  };

  onChangecontractFieldEdit = (e) => {
    if (e.target.name === 'airline') {
      this.setState({
        contractFieldEdit: {
          ...this.state.contractFieldEdit,
          [e.target.name]: this.state.airlineList.find(airline => airline.id === e.target.value),
          ['airline_code']: this.state.airlineList.find(airline => airline.id === e.target.value)?.code

        }
      });
    } else if (e.target.name === 'is_minimum') {
      this.setState({
        contractFieldEdit: {
          ...this.state.contractFieldEdit,
          [e.target.name]: !this.state.contractFieldEdit[e.target.name]
        }
      });
    } else {
      this.setState({
        contractFieldEdit: {
          ...this.state.contractFieldEdit,
          [e.target.name]: e.target.value
        }
      });
    }

  };

  changeDateAndTime = (element, type) => {
    this.setState({
      contractFieldEdit: {
        ...this.state.contractFieldEdit,
        [type]: element.format('YYYY-MM-DD')
      }
    });
  };

  onChangecontrowField = (e) => {
    if (e.target.name === 'is_minimum' || e.target.name === 'is_product') {
      this.setState({
        controwField: {
          ...this.state.controwField,
          [e.target.name]: !this.state.controwField[e.target.name]
        }
      });
    } else if (e.target.name === 'applied_on') {
      let item = appliedOnItems.find(item => item.value === e.target.value);
      this.setState({
        recordOns: item?.recordOns || [],
        segments: item?.segments || [],
        controwField: {
          ...this.state.controwField,
          ['applied_on']: e.target.value,
          ['is_product']: item?.disable_product ? false : this.state.controwField?.is_product,
          ['productId']: item?.disable_product ? null : this.state.controwField?.productId,
          ['rate_type_options']: item?.rate_type_options,
          ['rate_type']: 'stable',
          ['segment']: item?.segments[0]?.value,
          ['record_on']: item?.recordOns[0]?.value,
          ['roles']: e.target.value?.includes('Auto') ? null : this.state.controwField?.roles,
        }
      });
    } else if (e.target.name === 'roles') {
      let controwField = this.state.controwField;
      controwField.roles = [e.target.value];
      this.setState({ controwField: controwField });
    } else if (e.target.name === 'record_on') {
      let controwField = this.state.controwField;
      controwField.record_on = e.target.value;
      controwField.platform = null;
      this.setState({ controwField: controwField });
    }
    else {
      this.setState({
        controwField: {
          ...this.state.controwField,
          [e.target.name]: e.target.value
        }
      });
    }

  };

  onChangecontrateField = (e) => {
    let name = e.target.name;
    this.setState({
      contrateField: {
        ...this.state.contrateField,
        [e.target.name]: e.target.value
      }
    },()=>{
      if(name.includes('level')){
        let level1 = this.state.contrateField?.level1;
        let level2 = this.state.contrateField?.level2;
        // let level3 = this.state.contrateField?.level3;
        let level1low = 0;
        let level2low = /*!level2 || isNaN(level2) ||*/ !level1 || isNaN(level1) ? '-' : (parseInt(level1)+parseInt(1));
        let level3low = /*!level3 || isNaN(level3) ||*/ !level2 || isNaN(level2) ? '-' : (parseInt(level2)+parseInt(1));
        this.setState({
          contrateField: {
            ...this.state.contrateField,
            ['level1low']: level1low,
            ['level2low']: level2low,
            ['level3low']: level3low,
          }
        });
      } else if (name === 'nolimit2') {
        // clear fields
        this.setState({
          contrateField: {
            ...this.state.contrateField,
            ['level2']: '',
            ['level3low']: '-',
            ['level3']: '',
            ['rate3']: '',
            ['nolimit3']: false,
          }
        });
      } else if (name === 'nolimit3') {
        // clear fields
        this.setState({
          contrateField: {
            ...this.state.contrateField,
            ['level3']: '',
          }
        });
      }
    });
  };


  handlePopover = (attachment, anchorEl, awb, docs) => {
    this.setState({
      attachment,
      anchorEl,
      awb,
      docs
    });
  };

  handlePopoverClose = () => {
    this.setState({
      attachment: false,
      anchorEl: false
    });
  };

  handleDoc = (row) => {
    this.setState({ contractFieldEdit: { id: row.id } });
    this.props.setDoc({
      modal: 'contract',
      id: row.id,
      name: row.name,
      data: row.docs
    });
  };

  handleOpen = (modal, action, data = {}, cont_row = {}) => {
    if (modal === 'contract' && action === 'add') {
      let contractField = {
        id: null,
        airline: null,
        airlinecode: null,
        contract_type: null,
        is_minimum: false,
        minimum_rate: null,
        start_date: moment().format('DD MMM YY'),
        end_date: moment().format('DD MMM YY'),
        mail: null
      };
      let contrateField = {
        ...this.state.contrateField,
        ['level1low']: 0,
      };
      this.setState({ 
        contractFieldEdit: contractField,
        contrateField: contrateField
      });
    }
    else if (modal === 'contract_item' && action === 'edit') {
      let item2 = appliedOnItems.find(item => item.value === cont_row?.applied_on);
      let controwField = {
        id: cont_row?.id,
        description: cont_row?.description,
        applied_on: cont_row?.applied_on,
        is_product: cont_row?.is_product,
        productId: cont_row?.productId,
        rate_type: cont_row?.rate_type,
        is_minimum: cont_row?.is_minimum,
        minimum: cont_row?.minimum,
        record_on: cont_row?.record_on,
        segment: cont_row?.segment,
        remarks: cont_row?.remarks,
        mail: cont_row?.mail,
        rate_type_options: item2?.rate_type_options,
        roles: cont_row.roles,
        platform: cont_row.platform,
      };
      let contrateField = {};
      let cont_rates = cont_row?.contrates;
      if (cont_rates?.length > 0) {
        cont_rates.map((rate) => {
          // if(cont_rates?.length>1){
          if (cont_row.rate_type !== 'stable') {
            contrateField['id' + (rate.tier)] = rate.id;
            contrateField['rate' + (rate.tier)] = rate.rate;
            contrateField['level' + (rate.tier)] = rate.level;
            contrateField['tier' + (rate.tier)] = rate.tier;
            contrateField['nolimit' + (rate.tier)] = rate.nolimit;
          } else {
            contrateField.id = rate.id;
            contrateField.rate = rate.rate;
          }
        });
      }
      if (cont_row.rate_type !== 'stable') {
        let level1 = contrateField?.level1;
        let level2 = contrateField?.level2;
        // let level3 = contrateField.level3;
        let level1low = 0;
        let level2low = /*!level2 || isNaN(level2) ||*/ !level1 || isNaN(level1) ? '-' : (parseInt(level1)+parseInt(1));
        let level3low = /*!level3 || isNaN(level3) ||*/ !level2 || isNaN(level2) ? '-' : (parseInt(level2)+parseInt(1));
        contrateField= {
          ...contrateField,
          ['level1low']: level1low,
          ['level2low']: level2low,
          ['level3low']: level3low,
        };
      }
      let item = appliedOnItems.find(item => item.value === cont_row?.applied_on);
      this.setState({
        controwField: controwField,
        contrateField: contrateField,
        segments: item?.segments || [],
        recordOns: item?.recordOns || [],
      });
    } else {
      let contractField = {
        id: data?.id,
        airline: data?.airline,
        airline_code: data?.airline?.code,
        contract_type: data?.type,
        is_minimum: data?.is_minimum,
        minimum_rate: data?.minimum,
        start_date: data?.start_date,
        end_date: data?.end_date,
        mail: data?.mail,
      };
      this.setState({ contractFieldEdit: contractField });
    }
    this.setState({
      modal,
      action,
      controw_modaldata: data,
    }, () => {
      if (modal === 'contract_item') {
        this.fetchProducts(data?.airlineId);
      }
    });
  };

  onClose = () => {
    this.setState({
      modal: false,
      sessionExpired: false,
      action: false,
      recordOns: [],
      segments: [],
      controwField: {
        description: null,
        rate_type: 'stable',
        applied_on: null,
        is_product: false,
        productId: null,
        record_on: null,
        segment: null,
        is_minimum: false,
        minimum: null,
        roles: null,
        platform: null,
      },
      contrateField: {
        rate: null,
        tier1: null,
        tier2: null,
        tier3: null,
        level1: null,
        level2: null,
        level3: null,
        rate1: null,
        rate2: null,
        rate3: null,
        level1low: 0,
        level2low: '-',
        level3low: '-',
        nolimit2: false,
        nolimit3: false,
      },
      controwErrors: {},
      contrateErrors: {},
    });
  };

  handleDelete = (type, id, item, anchorEl) => {
    this.props.setDelete({
      modal: true,
      id,
      anchorEl,
      onConfirm: () => {
        switch (type) {
          case 'controw':
            this.deleteContrate(id, item);
            break;
          case 'contract':
            this.deleteContract(id);
            break;
          default:
            break;
        }
      },
    });
  };

  handleExport = () => {
    // let options = {
    //   fieldSeparator: ',',
    //   quoteStrings: '"',
    //   decimalSeparator: '.',
    //   showLabels: true,
    //   showTitle: false,
    //   filename: 'Screnning Report',
    //   useTextFile: false,
    //   useBom: false,
    //   useKeysAsHeaders: false,
    //   headers: ['AWB', 'PCS', 'ORG', 'DEST', 'Measure', 'Method', 'Screened For Flight', 'Screener', 'Screening Date/Time', 'Alarm', 'Resolution', 'Resolved', 'Remarks']
    // };
    let data = [];
    this.state.awbs.map(a => {
      a.screens.map(s => {
        data.push({
          awb: a.code + '-' + a.number,
          org: a.org,
          dest: a.dest,
          pcs: s.pcs,
          measure: s?.measure?.type,
          method: s?.method?.name,
          flight: s.flight_no + '/' + moment(screen.s).format('DDMMMYY').toUpperCase(),
          screener: s.user ? s.user.first_name + ' ' + s.user.last_name : s?.k9_company?.name,
          date: moment(s.screen_date).format('DDMMMYY').toUpperCase() + ' ' + s.screen_time,
          alarm: s.alarmed ? 'Yes' : 'No',
          resolution: s?.alarm?.name,
          resolved: s.resolved,
          remark: s.remark
        });
      });
    });
    exportFromJSON({
      data,
      fileName: 'Screnning Report',
      exportType: 'csv'
    });
  };

  onChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState((prev) => ({
      searchFields: {
        ...prev.searchFields,
        [name]: value
      }
    }), () => this.filterContract()
    );
  };

  filterContract = () => {
    let filteredData = [...this.state.contract];
    if (this.state.searchFields.airline) {
      filteredData = filteredData.filter((list) => {
        return list.airline.id === this.state.searchFields.airline;
      });
    }
    this.setState({ filteredData: filteredData });
  };

  render() {
    return (
      <>
        <ManageContracts
          handlePopover={this.handlePopover}
          handlePopoverClose={this.handlePopoverClose}
          anchorEl={this.state.anchorEl}
          attachment={this.state.attachment}
          modal={this.state.modal}
          action={this.state.action}
          handleOpen={this.handleOpen}
          onClose={this.onClose}
          spanRef={this.spanRef}
          search={this.state.search}
          filter={this.state.filter}
          airline={this.state.airline}
          flight={this.state.flight}
          measure={this.state.measure}
          awbs={this.state.awbs}
          awb={this.state.awb}
          docs={this.state.docs}
          fetchReport={this.fetchReport}
          onChangeSearch={this.onChangeSearch}
          onChangefilter={this.onChangefilter}

          handleExport={this.handleExport}

          onChangecontractFieldEdit={this.onChangecontractFieldEdit}
          onChangechangeDateAndTime={this.changeDateAndTime}
          onChangecontrowField={this.onChangecontrowField}
          onChangecontrateField={this.onChangecontrateField}

          contractFieldEdit={this.state.contractFieldEdit}
          recordOns={this.state.recordOns}
          segments={this.state.segments}
          controwField={this.state.controwField}
          contrateField={this.state.contrateField}

          airlines={this.state.airlineList}
          products={this.state.products}
          contract={this.state.contract}
          filteredData={this.state.filteredData}

          addContract={this.addContract}
          editContracts={this.editContracts}
          addControw={this.addControw}
          editControw={this.editControw}
          handleDelete={this.handleDelete}

          contractErrors={this.state.contractErrors}
          contractErrorsEdit={this.state.contractErrorsEdit}
          controwErrors={this.state.controwErrors}
          contrateErrors={this.state.contrateErrors}

          handleDoc={this.handleDoc}

          tab={this.state.tab}
          onChangeTab={this.onChangeTab}
          logs={this.state.logs}
          searchOptions={this.state.searchOptions}
          handleSearchOption={this.handleSearchOption}
          searchFields={this.state.searchFields}
          onChange={this.onChange}
          fetchContract={this.fetchContract}

          expiryTimer={this.state.timer}
          sessionExpired={this.state.sessionExpired}
          startTimer={this.startTimer}
          stopInterval={this.stopInterval}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    doc: {
      ...state.doc,
      modal: 'contract'
    },
    stations: state.stations,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDelete: (data) => dispatch(setDeleteAction(data)),
    setDoc: (data) => dispatch(setDocAction(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageContractsContainer);