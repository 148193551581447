import React from 'react';
import Admin from './Admin';
import http from 'utils/http';
import { connect } from 'react-redux';
import { setStationAction } from 'store/actions/companyAction';
import { pageHandler } from 'custom/constants';
import cookies from 'utils/cookies';
class AdminContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.match.params.tab || 'manage_users' };
  }
  componentDidMount() {
    const currentPath = window.location.pathname;
    pageHandler(currentPath, cookies.get('role'));
    http.get('/station').then(({ data }) => {
      this.props.setStations(data.data);
    });
  }

  handleChange = (newValue) => {
    if (this.props.match.url.includes('admin')) {
      this.props.history.replace(`${newValue}`);
    } else {
      this.props.history.replace(`admin/${newValue}`);
    }
    this.setState({ value: newValue });
  };

  render() {
    return (
      <>
        <Admin handleChange={this.handleChange} value={this.state.value} />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return { setStations: (stations) => dispatch(setStationAction(stations)) };
};

export default connect(null, mapDispatchToProps)(AdminContainer);
