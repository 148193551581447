import React from 'react';
import Flights from './Flights';
import http from 'utils/http';
import { deleteAwbAction, deleteUldAction, setAwbAction, setFFMAction, setUldAction } from 'store/actions/ffmAction';
import { connect } from 'react-redux';
import { setFlightAction } from 'store/actions/flightAction';
import events from 'utils/evemts';
import { setFlightInfoAction } from 'store/actions/flightInfoAction';
import cookies from 'utils/cookies';
import cloneDeep from 'lodash/cloneDeep';
import ConfirmDialog from '../common/ConfirmDialog';

const typeBgColor = {
  green: 'green',
  red: 'red',
  yellow: '#ffb703',
  orange: 'orange',
  purple: 'purple',
  darkred: 'darkred',
};

class FlightsContainer extends React.Component {
  constructor(props) {
    super(props);
    const queryString = this.props.location.search;
    const params = new URLSearchParams(queryString);
    const id = params.get('id');
    const ctype = params.get('ctype') ? params.get('ctype') : cookies.get('contract_types')?.[0];
    this.state = {
      selected_flight: {},
      value: this.props.match.params.tab || 'notes',
      id: id,
      ctype: ctype,
      flightData: {},
      manifestData: {},
      uldData: [],
      modal: false,
      modalData: [],
      showFlight: false,
      showReportModal: false,
      action: false,
      flightReportDetails: {
        note: [],
        discrepancy: [],
        flightInfo: [],
        manifestSummary: [],
        flightReport: [],
        statistics: [],
        history: [],
        manifest: [],
      },
      history_data: [],
      loadingHistory: false,
      showRejected: false,
      rejectedReason: '',
      lastRejectedLog: {},
      skipComponentUpdate: false,
      loadingManifest: false,
      nil: false,
      confirmDialog: false,
    };
    this.eventSource = null;
  }
  componentDidMount() {
    //For Flight Report Modal - Iframe
    const params = new URLSearchParams(window.location.href);
    const paramExists = params.has('showModal');
    const paramValue = paramExists ? params.get('showModal') : '';
    //hide header if showModal-iframe is true
    if (paramValue) {
      const header = document.querySelector('header');
      if (header) {
        header.style.display = 'none';
      }
    }
    this.setState({ showReportModal: paramValue });
    this.fetchFlightData();
  }

  componentDidUpdate(prevProps) {
    if (this.state.skipComponentUpdate) {
      // If the flag is set, skip the logic in componentDidUpdate
      this.setState({ skipComponentUpdate: false });
      return;  // Skip execution
    }
    if (prevProps.location.search && this.props.location.search == '') {
      this.props.setUld({});
      this.props.setAwb({});
      this.props.setFFM({});
      this.props.setFlight({});
      this.props.setFlightInfo({});
      this.setState({
        note: [],
        discrepancy: [],
        flightInfo: [],
        manifestSummary: [],
        flightReport: [],
        statistics: [],
        history: [],
        manifest: [],
      });
    }
  }

  componentWillUnmount() {
    this.props.setUld({});
    this.props.setAwb({});
    this.props.setFFM({});
    this.props.setFlight({});
    this.props.setFlightInfo({});
    this.eventSource && this.eventSource.close();
  }

  startEvent = (id) => {
    this.eventSource && this.eventSource.close();
    this.eventSource = events('flight', id);
    this.eventSource.onmessage = ({ type, data }) => {
      if (type == 'message') {
        const msg = JSON.parse(data || '{}');
        this.handleEvent(msg);
      }
    };
  };

  handleEvent = (msg) => {
    console.log(msg);
    if (msg.action == 'ffm') {
      this.fetchManifestData(this.state.id);
    }
    if (['update_uld', 'add_uld', 'delete_uld'].includes(msg.action)) {
      if (msg.action == 'update_uld' || msg.action == 'add_uld') {
        this.props.setUld(msg.data);
      }else
      {
        this.props.deleteUld(msg.data);
      }
    }
    if (['update_awb', 'add_awb', 'delete_awb'].includes(msg.action)) {
      if (msg.action == 'update_awb' || msg.action == 'add_awb') {
        this.props.setAwb(msg.data);
      }else
        if (msg.action == 'delete_awb') {
          this.props.deleteAwb(msg.data);
        }
    }
    if (msg.action == 'update') {
      let flightData = { ...this.state.flightData };
      flightData = {
        ...flightData,
        ...msg.data
      };
      this.props.setFlightInfo(flightData);
    }
    if(msg.action == 'update_report'){
      let flightData = { ...this.state.flightData };
      if(msg.data.flightId === flightData.id ){
        let reportsidex = flightData.reports.findIndex((report) => report.type === msg?.data?.type && report?.id === msg?.data?.id);
        if(reportsidex !== -1){
          if(msg.data.status === 'Rejected'){
            let rejectedReason = this.state.rejectedReason;
            let lastRejectedLog = this.state.lastRejectedLog;
            if (msg?.data?.log && msg?.data?.log[msg?.data?.log?.length - 1].status.toLowerCase() === 'rejected' && msg?.data?.type === this.state.ctype) {
              rejectedReason = `${msg?.data?.log[msg?.data?.log?.length - 1].remark}`;
              lastRejectedLog = msg?.data?.log[msg?.data?.log?.length - 1];
            }
            this.setState({ 
              showRejected: true,
              rejectedReason: rejectedReason,
              lastRejectedLog: lastRejectedLog,
              skipComponentUpdate: true,
            });
          } else {
            //if url contains /flt_report, do nothing else set state 
            if(this.props.location.pathname.includes('/flt_report')){
              //do nothing
            } else {
              this.setState({ 
                showRejected: false,
                rejectedReason: '',
                lastRejectedLog: {},
                skipComponentUpdate: true,
              });
            }
          }
        }
      }
    }
  };


  fetchFlightData = (search = false) => {
    const stn = cookies.get('station_code');
    let url = '';
    if (search) {
      url = `/flight?search=${search}`;
    } else {
      url = `/flight/${this.state.id}`;
    }
    this.props.setFFM({});
    if (this.state.id || search)
      http.get(url).then(({ data }) => {
        if (data.data && data.data?.length == 1) {
          this.props.setFlight(data.data[0]);
          this.props.setFlightInfo(data.data[0]);
          //showRejected
          if(data.data[0].reports){
            let reports = data.data[0].reports;
            let rejected = reports.find((report) => report.status === 'Rejected' && report.type === this.state.ctype);
            if(rejected){
              let rejectedReason = this.state.rejectedReason;
              let lastLog = data.data[0]?.reports?.find((report) => report.type === this.state.ctype)?.log;
              let lastRejectedLog = {};
              if (lastLog && lastLog[lastLog.length - 1].status.toLowerCase() === 'rejected') {
                rejectedReason = `${lastLog[lastLog.length - 1].remark}`;
                lastRejectedLog = lastLog[lastLog.length - 1];
              }
              this.setState({ 
                showRejected: true,
                rejectedReason: rejectedReason,
                lastRejectedLog
              });
            }
          }
          this.setState({
            flightData: data.data[0],
            id: data.data[0].id,
            nil: data.data[0]?.org == stn ? data.data[0]?.out_nil_ffm : data.data[0]?.in_nil_ffm
          }, () => {
            if (search) {
              this.props.history.replace(`${this.state.value}?id=${data.data[0].id}&ctype=${this.state.ctype}`);
            }
            this.fetchHistory(); //pdf history
            this.fetchManifestData(data.data[0].id);
            this.startEvent(data.data[0].id);
          });
        } else {
          this.setState({
            modal: true,
            modalData: data.data
          });
        }
      });
  };

  fetchManifestData = (id) => {
    console.log('fetching manifest');
    http.get(`/manifest/${id}`).then(({ data }) => {
      this.props.setFFM(data.data);
      let uldData = data.data.flight_uld || [];
      this.setState({ uldData: uldData });
      if (data.status == 'success') {
        this.setState({ loadingManifest: false, });
      }
    });
  };

  handleChange = (newValue) => {
    let flightData = this.state.flightData;
    this.props.history.replace(`${newValue}?id=${flightData.id}&ctype=${this.state.ctype}`);
    this.setState({ value: newValue });
  };


  handleCtype = (ctype) => {
    this.props.history.replace(`${this.state.value}?id=${this.state.id}&ctype=${ctype}`);
    this.setState({ ctype: ctype });
  };


  handleUpload = (e) => {
    if (e.target.files[0]) {
      this.setState({ loadingManifest: true, });
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      http.post(`/flight/ffm/${this.state.id}`, formData, { headers: { ntoast: true } });
    }
  };

  handleOpen = (showReportModal, action) => {
    this.setState({
      showReportModal,
      action,
    });
  };

  closeModal = () => {
    this.setState({
      modal: false,
      showReportModal: false,
      modalData: []
    });
  };

  handleFlight = (type, data) => {
    if (type == 'flight') {
      this.setState({ selected_flight: data });
    } else {
      this.setState({
        selected_flight: {},
        flightData: this.state.selected_flight || {},
        id: this.state.selected_flight?.id,
        modal: false,
        modalData: []
      }, () => {
        this.props.history.replace(`${this.state.value}?id=${this.state.selected_flight.id}&ctype=${this.state.ctype}`);
        this.props.setFlight(this.state.flightData);
        this.props.setFlightInfo(this.state.flightData);
        this.fetchManifestData(this.state.flightData.id);
        this.startEvent(this.state.flightData.id);
      });
    }
  };

  getULDData = (uld_no) => {
    console.log('getULDData**************8', uld_no);
    if (uld_no && uld_no !== undefined) {
      console.log('getULDData', this.state.uldData);
      // return uld's which number is close to uld_no
      let filter = this.state.uldData.filter((uld) => uld.uld_no.includes(uld_no));
      return filter;
    } else {
      return [];
    }
  };

  getRejectedRemark = (ctype) => {
    const flightData = this.state.flightData;
    let lastLog = flightData?.reports?.find((report) => report.type === ctype)?.log;
    if (lastLog && lastLog[lastLog.length - 1].status.toLowerCase() === 'rejected') {
      return `${lastLog[lastLog.length - 1].remark}`;
    }
  };

  fetchHistory = () => {
    this.setState({ loadingHistory: false });
    http.get('change_log?type=Flight&flightId=' + this.state.flightData?.id)
      .then(({ data }) => {
        let dataClone = cloneDeep(data.data);
        dataClone.map((row) => {
          let operation_desc = '';
          let bgColor = '';
          switch (row.type) {
            case 'Statistics':
              bgColor = typeBgColor.green;
              break;
            case 'Report':
              if (row.new_details.status === 'Finalized') {
                bgColor = typeBgColor.yellow;
              } else if (row.new_details.status === 'Approved') {
                bgColor = typeBgColor.green;
              } else if (row.new_details.status === 'Rejected') {
                bgColor = typeBgColor.red;
              } else {
                bgColor = typeBgColor.yellow;
              }
              break;
            case 'Attachment':
              bgColor = typeBgColor.orange;
              break;
            case 'Checklist':
              bgColor = typeBgColor.purple;
              break;
            case 'Discrepancy':
              bgColor = typeBgColor.darkred;
              break;
            default:
              bgColor = typeBgColor.yellow;
              break;
          }
          if (row.type == 'Attachment') {
            if (row.operation == 'ADD') {
              operation_desc = 'Added ' + row.new_details?.name + ' Attachment';
            } else if (row.operation == 'DELETE') {
              operation_desc = 'Deleted ' + row.prev_details?.name + ' Attachment';
            }
          } else if (row.type == 'Checklist') {
            if (typeof row.new_details?.check == 'boolean') {
              operation_desc = row.operation + ' -> ' + (row.new_details?.check ? 'Checked' : 'Unchecked');
            } else {
              operation_desc = row.operation + ' -> remark -> ' + row.new_details?.remark;
            }
          } else if (row.type == 'Statistics') {
            if (row.new_details.length > 0) {
              const prev_details = Object.values(row.prev_details);
              const new_details = row.new_details;
              const isEqual = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);
              const item = new_details.find(obj1 => !prev_details.some(obj2 => isEqual(obj1, obj2)));
              item ? operation_desc = item.contractName + ' -> ' + item.name + ' -> ' + item.quantity : '';
            } else {
              operation_desc = row.operation;
            }
          } else if (row.type == 'Report') {
            operation_desc = row.new_details?.type + ' -> ' + row.operation + (row.new_details?.remark && ' -> ' + row.new_details?.remark);
          } else if (row.type == 'Discrepancy') {
            console.log('row', row);
            let operation = '';
            switch (row.operation?.toLowerCase()) {
              case 'add discrepancy':
                operation = 'Discrepancy Added';
                break;
              case 'delete discrepancy':
                operation = 'Discrepancy Deleted';
                break;
              case 'update discrepancy':
                operation = 'Discrepancy Updated';
                break;
              default:
                operation = row.operation;
                break;
            }
            operation_desc = operation + ' -> ' + row.new_details?.awb_no;
          } else {
            operation_desc = row.operation;
          }
          row.operation_desc = operation_desc;
          row.bgColor = bgColor;
          return operation_desc;
        });

        let newData = dataClone.filter((item) => { return item.operation_desc !== ''; });

        this.setState({
          history_data: newData,
          loadingHistory: true
        }, () => {
          if (this.props.printFunction) {
            this.props.printFunction(this.state.history_data, 'history');
          }
        });
      });
  };
  updateFlightData = (status, reports) => {
    let flightData = { ...this.state.flightData };
    flightData.status = status;
    flightData.reports = reports;
    this.setState({ flightData: flightData });
  };

  onChangeNil = (e) => {
    let uldData = this.state.uldData;
    const checked = e.target.checked;
    if(checked === true){
      if(uldData.length > 0){
        this.setState({ confirmDialog: true });
        this.setState({ is_nil: checked });
      }
      else {
        this.setState({ nil: checked }, () => {
          this.handleNil();
        });
      }
    }
    else {
      this.setState({ nil: checked }, () => {
        this.handleNil();
      });
    }
  };
  handleNil = () => {
    let is_nil = this.state.is_nil;
    let nil = is_nil === true ? is_nil : this.state.nil; 
    http.put(`/flight/nil/${this.state.flightData.id}`, { nil: nil }).then(() => {
      this.setState({
        nil: nil,
        is_nil: false,
        confirmDialog: false 
      });
    });
  };

  onClose = () => {
    this.setState({ confirmDialog: false });
  };

  render() {
    return (
      <>
        <Flights
          flightId={this.state.id}
          ctype={this.state.ctype}
          handleCtype={this.handleCtype}
          value={this.state.value}
          flightData={this.state.flightData}
          modal={this.state.modal}
          modalData={this.state.modalData}
          getULDData={this.getULDData}
          handleChange={this.handleChange}
          handleUpload={this.handleUpload}
          handleFlight={this.handleFlight}
          closeModal={this.closeModal}
          fetchFlightData={this.fetchFlightData}
          fetchManifestData={this.fetchManifestData}
          showReportModal={this.state.showReportModal}
          handleOpen={this.handleOpen}
          flightReportDetails={this.state.flightReportDetails}
          getRejectedRemark={this.getRejectedRemark}
          history_data={this.state.history_data}
          fetchHistory={this.fetchHistory}
          loadingHistory={this.state.loadingHistory}
          showRejected={this.state.showRejected}
          rejectedReason={this.state.rejectedReason}
          loadingManifest={this.state.loadingManifest}
          onChangeNil={this.onChangeNil}
          nil={this.state.nil}
          lastRejectedLog={this.state.lastRejectedLog}
        />
        <ConfirmDialog
          title='Confirm Action'
          confirmDialog={this.state.confirmDialog}
          onClose={this.onClose}
          confirmationMessage='This action will clear all manifest data. Do you want to proceed?'
          handleConfirm={this.handleNil}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFFM: (ffm) => dispatch(setFFMAction(ffm)),
    setUld: (uld) => dispatch(setUldAction(uld)),
    setAwb: (awb) => dispatch(setAwbAction(awb)),
    deleteUld: (uld) => dispatch(deleteUldAction(uld)),
    deleteAwb: (awb) => dispatch(deleteAwbAction(awb)),
    setFlight: (flight) => dispatch(setFlightAction(flight)),
    setFlightInfo: (flight) => dispatch(setFlightInfoAction(flight)),
  };
};

export default connect(null, mapDispatchToProps)(FlightsContainer);
