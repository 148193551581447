import React from 'react';
import { Stack, Popover, Typography } from '@mui/material';
import { PopoverHead, PopoverContent } from '../../../custom/components';
import styles from '../../../custom/style.module.css';
import reportStyle from './report.module.css';
import { CloseIconBtn, DownloadIcon } from 'custom/constants';
import environment from 'custom/environments';

const Attachment = (props) => {

  return (
    <div>
      <Popover
        open={props.attachment}
        anchorEl={props.anchorEl}
        onClose={() => props.handlePopover(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{ paper: reportStyle.attachmentPopover }}
      >
        <PopoverHead>
          <Typography variant='subtitle2' component='span'>Images: {props.awb}</Typography>
          <CloseIconBtn onClick={() => props.handlePopover(false)} />
        </PopoverHead>
        <PopoverContent className={styles.textCenter}>
          <Stack direction='column' spacing={2} alignItems='center'>
            {props.docs && props.docs.map(doc => (
              <Stack direction='row' key={doc.id} spacing={2} alignItems='center'>
                <div className={reportStyle.attImgContainer}>
                  <img src={environment.apiService + 'document?key=' + doc.src} alt='attachment' className={styles.w100} crossOrigin='use-credentials' />
                </div>
                <a href={environment.apiService + 'document?key=' + doc.src} target='new'><DownloadIcon style={{ alignSelf: 'flex-start' }} /></a>
              </Stack>))}
          </Stack>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default Attachment;