import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { dateTolocale } from 'utils/converter';
import { DateFormat } from 'custom/constants';

const textColor = '#393939';
const borderColor = 'lightgrey';
const cellProperties = {
  padding: 6,
  paddingLeft: 6,
  fontSize: 9,
  color: textColor,
  borderRightColor: borderColor,
  borderRightWidth: 1,
  height: '100%',
};
const headProperties = {
  paddingRight: 4,
  paddingLeft: 4,
  fontSize: 9,
  color: textColor,
};

const styles = StyleSheet.create({
  headerContainer: { marginTop: 24 },
  label: {
    fontSize: 10,
    paddingBottom: 3,
    lineHeight: 'normal',
  },
  data: {
    fontSize: 10,
    paddingBottom: 3,
    lineHeight: 'normal',
  },
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 20,
    borderWidth: 1,
    borderColor: 'lightgrey',
    // borderColor: borderColor,
  },
  container: {
    flexDirection: 'row',
    borderBottomColor: 'lightgrey',
    backgroundColor: '#eaeaea',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 20,
    textAlign: 'center',
    // fontStyle: 'bold',
    flexGrow: 1,
    // color: 'black'
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomColor: borderColor,
    borderRight: '1px solid lightgrey',
    borderBottomWidth: 1,
    alignItems: 'center',
    // height: 30,
    fontStyle: 'bold',
  },
  row: {
    flexDirection: 'row',
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: 'center',
    // height: 30,
    fontStyle: 'bold',
  },
  description: {
    width: '55%',
    // width: '20%',
    textAlign: 'left',
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
    padding: 4,
    paddingLeft: 8,
    fontSize: 9,

    display: 'flex',
    flexDirection: 'column',
    color: textColor,
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  inlinedesc: {
    color: 'grey',
    fontSize: 8,
  },
  qty: {
    width: '15%',
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
    fontSize: 9,
    color: textColor,

    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  rate: {
    width: '15%',
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
    paddingRight: 8,
    paddingLeft: 14,
    fontSize: 9,
    color: textColor,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  amount: {
    width: '55%',
    paddingRight: 8,
    paddingLeft: 14,
    fontSize: 9,
    color: textColor,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  inlinedescWrapper: { flexDirection: 'row', },
  divider: {
    color: 'grey',
    fontSize: 8,
    margin: '0 4px' 
  },
  header20: {
    ...headProperties,
    width: '20%',
  },
  cell20: {
    ...cellProperties,
    width: '20%',
  },
  header30: {
    ...headProperties,
    width: '30%',
  },
  cell30: {
    ...cellProperties,
    width: '30%',
  },
  header40: {
    ...headProperties,
    width: '40%',
  },
  cell40: {
    ...cellProperties,
    width: '40%',
  },
  title: { marginBottom: '-10px', },
  noDataRow: {
    fontSize: 9,
    width: '100%',
    textAlign: 'center',
    padding: 4,
    color: textColor,
    borderColor: borderColor,
    borderWidth: 1,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noBorderRight: { borderRight: 'none', },
});

const getPerformedBy = (item) => {
  let length =  item?.flight_checklist?.length - 1;
  let checklist_data = item?.flight_checklist[length]; 
  let user = checklist_data?.user;
  let userDetails = `${user?.first_name} ${user?.last_name} | ${dateTolocale(checklist_data?.created, DateFormat + ' ' + 'HH:mm')}`;
  item.performed_by = user ? userDetails : '';
  return <Text>{user ? userDetails : ''}</Text>;

};

const FLightReport = (props) => {
  const { report, statistics, history } = props;

  return (
    <>
      <View style={styles.headerContainer}>
        <Text style={styles.title}>Flight Report</Text>
        <View style={styles.tableContainer} wrap={false}>

          <View style={styles.container}>
            <Text style={styles.header30}>Checks</Text>
            <Text style={styles.header20}>Roles</Text>
            <Text style={styles.header20}>Entered By</Text>
            <Text style={styles.header30}>Remarks</Text>
          </View>

          {report && report.length > 0 ?
            report?.map( item =>
              <View style={styles.tableRow} key={item.id}>
                <Text  style={styles.cell30}>{item.description}</Text>
                <Text  style={styles.cell20}>{item.role}</Text>
                <Text  style={styles.cell20}>{getPerformedBy(item)}</Text>
                <Text  style={styles.cell30}>{item.remark}</Text>
              </View>
            ): <View  style={styles.noDataRow}>
              <Text>No Data</Text>
            </View>}

        </View>

    
        <View style={styles.tableContainer} wrap={true}>

          <View style={styles.container}>
            <Text style={styles.header40}>Line Item</Text>
            <Text style={styles.header30}>Driver</Text>
            <Text style={styles.header30}>Quantity</Text>
          </View>

          {statistics && statistics.length > 0 ?
            statistics?.map( item =>
              <View style={styles.tableRow} key={item.id}>
                <Text style={styles.cell40}>{item.description}</Text>
                <Text style={styles.cell30}>{item.applied_on}</Text>
                <Text style={[styles.cell30, styles.noBorderRight]}>{item.quantity ?( item.applied_on.includes('KG') ? Math.round(item.quantity*100)/100 : item.quantity ): ''}</Text>           
              </View>
            ): <View  style={styles.noDataRow}>
              <Text>No Data</Text>
            </View>}

        </View>

        <View style={styles.tableContainer} wrap={true}>

          <Text>History</Text>

          <View style={styles.container}>
            <Text style={styles.header20}>Date & Time</Text>
            <Text style={styles.header20}>Type</Text>
            <Text style={styles.header30}>Details</Text>
            <Text style={styles.header30}>User</Text>
          </View>

          {history && history.length > 0 ?
            history?.map( item =>
              <View style={styles.tableRow} key={item.id}>
                <Text style={styles.cell20}>{dateTolocale(item.created, 'DD MMM YYYY HH:mm')}</Text>
                <Text style={styles.cell20}>{item.type}</Text>
                <Text style={styles.cell30}>{item.operation_desc}</Text>
                <Text style={styles.cell30}>{item?.user?.first_name + ' ' + item?.user?.last_name + ` (${item?.user?.role})`}</Text>
              </View>
            ) : <View  style={styles.noDataRow}>
              <Text>No Data</Text>
            </View>}

        </View>
      </View>

    </>
  );
};
  
export default FLightReport;