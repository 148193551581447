import React from 'react';
import Location from './Location';
import http from 'utils/http';
class LocationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      station: [],
      id: false,
      error: ''
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data != this.props.data) {
      let station = this.props.data && this.props.data.stations && this.props.data.stations.map(s => s.id) || [];
      this.setState({
        station,
        id: this.props.data && this.props.data.id,
        error: ''
      });
    }
  }

  onChange = (e) => {
    let station = [...this.state.station || []];
    if (e.target.checked) {
      station.push(parseInt(e.target.value));
    } else {
      var index = station.indexOf(parseInt(e.target.value));
      if (index !== -1) {
        station.splice(index, 1);
      }
    }
    this.setState({ station });
  };

  onUpdate = () => {
    if (this.state.station && this.state.station.length > 0) {
      http.put(`/${this.props.type}/${this.state.id}`, { station: this.state.station }).then(({ data }) => {
        if (data.status == 'success') {
          this.props.handleClose(false);
        }
      });
    } else {
      this.setState({ error: 'Atleast one station should be selected' });
    }
  };

  render() {
    return (
      <>
        <Location
          open={this.props.open}
          anchorEl={this.props.anchorEl}
          handleClose={this.props.handleClose}
          stations={this.props.stations}
          name={this.props.name}
          type={this.props.type}
          station={this.state.station}
          error={this.state.error}
          onChange={this.onChange}
          onUpdate={this.onUpdate}
          data={this.props.data}
        />
      </>
    );
  }
}

export default LocationContainer;