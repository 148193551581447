

const local = {
  // name: 'ORGA MANAGER',
  name: 'ORGA MANAGER DEV',
  company: 'tristar',
  apiService: 'http://localhost:8080/',
  s3Url: 'http://localhost:8080/',
  // apiService: 'https://api.orga.orgadynamics.com/',
  // s3Url: 'https://tp-dev-gw-images.s3.us-east-2.amazonaws.com/',
  // flightScheduleService: 'http://localhost:4040/',
  // api_key: 'a573b052-2040-43ea-aff8-d7bb596f8dc4',
  flightScheduleService: 'https://api.dev.fltschdl.fdsvcs.com/',
  api_key: '096465e2-9f21-4fa3-9d6d-18554e20f711',
  appUrl: 'https://orga.orgadynamics.com/',
};

const dev = {
  name: 'ORGA MANAGER DEV',
  company: 'tristar',
  apiService: 'https://api.orga.orgadynamics.com/',
  s3Url: 'https://tp-dev-gw-images.s3.us-east-2.amazonaws.com/',
  flightScheduleService: 'https://api.dev.fltschdl.fdsvcs.com/',
  api_key: '096465e2-9f21-4fa3-9d6d-18554e20f711',
  appUrl: 'https://orga.orgadynamics.com/',
};

const stage = {
  name: 'ORGA MANAGER STAGE',
  company: 'tristar',
  apiService: 'https://api.stage.caba.orgadynamics.com/',
  s3Url: 'https://tp-dev-gw-images.s3.us-east-2.amazonaws.com/',
  flightScheduleService: 'https://api.dev.fltschdl.fdsvcs.com/',
  api_key: '2609e4a1-282d-456b-87d5-27ba5c75a237',
  appUrl: 'https://stage.caba.orgadynamics.com/',
};

const prod = {
  name: 'TRISTAR ORGA MANAGER PROD',
  ccompany: 'tristar',
  apiService: 'https://api.tristar.caba.orgadynamics.com/',
  s3Url: 'https://tp-dev-gw-images.s3.us-east-2.amazonaws.com/',
  flightScheduleService: 'https://api.dev.fltschdl.fdsvcs.com/',
  api_key: '63f9fbcc-5d23-11ee-8c99-0242ac120002',
  appUrl: 'https://tristar.caba.orgadynamics.com/'
};

let processEnv = local;

switch (process.env.REACT_APP_STAGE) {
  case 'prod':
    processEnv = prod;
    break;
  case 'dev':
    processEnv = dev;
    break;
  case 'stage':
    processEnv = stage;
    break;
  default: break;
}

export default processEnv;