import React from 'react';
import cookies from '../../../utils/cookies';
import Login from './Login';
import http from '../../../utils/http';
import auth from '../../../utils/auth';
import { withRouter } from 'react-router-dom';
import { timezones, pageHandler } from '../../../custom/constants';
import environments from 'custom/environments';
class LoginContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        username: '',
        company: environments.company ? environments.company : '',
        password: ''
      },
      errors: {
        company: false,
        username: false,
        password: false
      },
      company: environments.company ? true : false,
      submit: false,
    };
  }

  componentDidMount() {
    if (auth()) {
      // this.props.history.push('/');
      pageHandler(null, cookies.get('role'));
    }
  }

  onChange = (e) => {
    const name = e.target.name;
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({
      fields: {
        ...this.state.fields,
        [name]: value
      }
    });
  };

  validation = () => {

    const fields = { ...this.state.fields };
    const errors = {
      company: false,
      username: false,
      password: false
    };
    let formIsValid = true;

    if (!fields.company || (fields.company).trim().length === 0) {
      formIsValid = false;
      errors.company = 'Please enter your company.';
    }

    if (!fields.username || (fields.username).trim().length === 0) {
      formIsValid = false;
      errors.username = 'Please enter your username.';
    }

    if (!fields.password || (fields.password).trim().length === 0) {
      formIsValid = false;
      errors.password = 'Please enter your password.';
    }

    this.setState({ errors: errors });

    return formIsValid;
  };

  onSubmit = async (e) => {
    e.preventDefault();
    let formdata = { ...this.state.fields, };
    if (this.validation()) {
      this.setState({ submit: true });
      await http.post('/user/login', formdata).then(({ data, status }) => {
        if (status == 200 && data.status == 'success') {
          const station = data.data.station;
          cookies.set('login', true, { path: '/' });
          cookies.set('company_name', data.data.user.company_name, { path: '/' });
          cookies.set('company_mail', data.data.user.company_mail, { path: '/' });
          cookies.set('password', data.data.user.login, { path: '/' });
          cookies.set('name', data.data.user.first_name + ' ' + data.data.user.last_name, { path: '/' });
          cookies.set('user', data.data.user.id, { path: '/' });
          cookies.set('company', data.data.user.companyId, { path: '/' });
          cookies.set('role', data.data.user.role, { path: '/' });
          cookies.set('station', station && station[0] && station[0].id, { path: '/' });
          cookies.set('stations', station, { path: '/' });
          cookies.set('timezone', timezones[station[0].code], { path: '/' });
          cookies.set('station_code', station[0].code, { path: '/' });
          cookies.set('currency', station[0].currency, { path: '/' });
          cookies.set('contract_types', data.data.user.contract_types, { path: '/' });
          cookies.set('ismanagec', data.data.user.ismanagec, { path: '/' });
          // this.props.history.push('/');
          pageHandler(null, data.data.user.role);
        }
        else {
          this.setState({ submit: false });
        }
      })
        .catch(() => {
          this.setState({ submit: false });
        });
    }
  };

  render() {
    return (
      <Login
        company={this.state.company}
        fields={this.state.fields}
        errors={this.state.errors}
        submit={this.state.submit}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default withRouter(LoginContainer);