import React, { Fragment } from 'react';
import Logs from './Logs';
import { contractType } from 'custom/constants';
import http from 'utils/http';
import { getLocatDate } from 'utils/converter';
import cookies from 'utils/cookies';
import { setDeleteAction } from 'store/actions/deleteAction';
import { setDocAction } from 'store/actions/docAction';
import { connect } from 'react-redux';
import events from 'utils/evemts';
import moment from 'moment';
import { toast } from 'react-toastify';

class LogsContainer extends React.Component {
  constructor(props) {
    super(props);
    const queryString = this.props.location.search;
    const params = new URLSearchParams(queryString);
    const type = params.get('type');
    const airlineId = parseInt(params.get('airlineId')) || null;
    const date = params.get('date');
    this.state = {
      airlineId: airlineId,
      search: {
        type: type || 'DAY',
        airline: airlineId || null,
        date: date || getLocatDate('YYYY-MM-DD'),
        start_date: date || getLocatDate('YYYY-MM-DD'),
        end_date: date || getLocatDate('YYYY-MM-DD'),
        
      },
      airlines: [],
      contracts: [],
      logs: [],
      data: [],
      contract_loading: false,
      log_loading: false,
      checked: [],
      recentChecked: [],
      checkedAll: false,
      disabledCheckbox: [],
      remark: '',
      reopen: false,
      approved: false,
      rejected: false,
      finalized: false,
      accounting: false,
      contract_Type: 'Cargo Handling',
      cont_item: null,
      userContractTypes: [],
      filterLogs: 'All',
      filteredData: [],
      contractTypesList: [],
      emptyLog: [],
      popup: false,
      finalizeLogs: [],

      anchorEl3: null,
      remarks: '',
    };
    this.eventSource = null;
    this.refROBrn = React.createRef();
  }

  componentDidMount() {
    
    this.setState({ accounting: (cookies.get('role') === 'Accounting' || cookies.get('role') === 'Airline Rep') },()=>this.fetchAirlines());
    // if (this.state.search.airline && this.state.search.date && this.state.search.type) {
    //   this.fetchContracts();
    //   this.fetchUser();
    //   // this.fetchLogs();
    // }
    this.handleEvents();

  }
  componentWillUnmount() {
    this.eventSource.close();
  }

  // componentDidUpdate(prevProps) {
  //   console.log('prevProps******************',prevProps);
  //   console.log('componentDidUpdate this.state.checked', this.state.checked);
  //   console.log('componentDidUpdate this.state.recentChecked', this.state.recentChecked);
  // }

  handleEvents = () => {
    this.eventSource = events('service_log');
    this.eventSource.onmessage = (event) => {
      const msg = JSON.parse(event.data || '{}');
      if (msg.action == 'add_service_log') {
        let filteredData = [...this.state.data];
        let index = filteredData.findIndex(c => c.id == msg.data.id);
        let date = msg.data.date.split('T')[0];
        let dateIndex = filteredData.findIndex(c => c.date === date);
        if (index === -1 && dateIndex > -1) {
          let checked = this.state.checked;
          let disabledCheckbox = this.state.disabledCheckbox;
          disabledCheckbox.push(msg.data.id);
          filteredData[dateIndex] = msg.data;
          this.setState({
            data: filteredData,
            checked,
            disabledCheckbox
          }, () => { this.filterData(); });
        }
      }
      if (msg.action == 'update_service_log') {
        let filteredData = [...this.state.data];
        let index = filteredData.findIndex(c => c.id == msg.data.id);
        let checked = this.state.checked;
        let disabledCheckbox = this.state.disabledCheckbox;
        if (index > -1) {
          let msgData = msg.data;
          msgData.notes = filteredData[index].notes || [];
          this.setState((prevState) => {
            const data = [...prevState.data];
            data[index] = msgData;
            return { data };
          }, () => {});
          if(msg.data.status === 'Finalized'){
            checked.push(msg.data.id);
            disabledCheckbox = disabledCheckbox.filter(num => !checked.includes(num));
          }
          this.setState({
            checked,
            disabledCheckbox
          }, () => {this.filterData();});
        }
      }
      if(msg.action == 'add_note'){
        let filteredData = [...this.state.data];
        let index = filteredData.findIndex(c => c.id == msg.data.service_logId);
        if (index > -1) {
          if(filteredData[index].notes && filteredData[index].notes.length > 0){
            filteredData[index].notes.push(msg.data);
          }else{
            filteredData[index].notes = [msg.data] || [];
          }
          this.setState({ data: filteredData });
        }
      }

      if (msg.action == 'add_service_log_doc') {
        let filteredData = [...this.state.data];
        let index = filteredData.findIndex(c => msg.data && c.id == msg.data.service_logId);
        if (index > -1) {
          filteredData[index].docs = filteredData[index].docs || [];
          filteredData[index].docs.push(msg.data);
          this.setState({ data: filteredData }, () => {
            this.handleDoc(filteredData[index]);
          });
        }
      }

      if (msg.action == 'delete_service_log_doc') {
        let filteredData = [...this.state.data];
        const index = filteredData.findIndex(c => msg.data && c.id == msg.data.service_logId);
        if (index > -1) {
          const index2 = filteredData[index].docs.findIndex(cr => msg.data && cr.id == msg.data.id);
          if (index2 > -1) {
            filteredData[index].docs.splice(index2, 1);
            this.setState({ data: filteredData });
          }
        }
      }
    };
  };

  fetchContractTypes = () => {
    // let searchFields = this.state.searchFields;
    // let from = searchFields.from;
    // let to = searchFields.to;
    // let airline = this.state.airlines.find(airline => airline.id === this.state.search.airline);
    // console.log(airline);
    http.get(`/check_contract?airline=${this.state.search.airline}&record_on=log&from=${this.state.search.start_date}&to=${this.state.search.end_date}`)
      .then(response => {
        const { data } = response;
        if (data.status === 'success') {
          let contractTypes = [...new Set(data.data.map(item => item.type))];
          this.setState({
            contractTypesList: contractTypes,
            // contract_Type: contractTypes[0], //dnd 
            search: {
              ...this.state.search,
              contract_Type: contractTypes[0]
            }
          });
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  fetchUser = () => {
    http.get(`/user?id=${cookies.get('user')}`).then(({ data }) => {
      if(data && data.data && data.data.length > 0){
        let cont_item = null;
        let userContractTypes = data.data[0]?.contract_types || [];
        //get first key and value from contractType
        let firstRecord = Object.entries(contractType).find(([ , value]) => userContractTypes.includes(value));
        if (firstRecord) {
          cont_item = firstRecord[1];
        }
        this.setState({ 
          userContractTypes : data.data[0]?.contract_types || [],
          cont_item: cont_item,
          contract_Type: data.data[0]?.contract_types?.length > 0 ? data.data[0]?.contract_types[0] : null
        },()=> this.fetchLogs());
      }
    });
  };

  fetchAirlines = () => {
    http.get(`/airline?active=${true}`)
      .then((response) => {
        // handle success
        this.setState({ 
          airlines: response.data.data,
          search: {
            ...this.state.search,
            airline: this.state.airlineId || response.data.data[0].id
          }
        },()=>{
          this.fetchContractTypes();
          this.fetchContracts();
          this.fetchUser();
        });
      });
  };

  fetchContracts = () => {
    let url = '';
    if (this.state.search.type === 'DAY') {
      if (!this.state.search.airline || !this.state.search.start_date || !this.state.search.end_date || !this.state.search.type) return;
      url = `/contract_logs/${this.state.search.airline}?start_date=${this.state.search?.start_date}&end_date=${this.state.search?.end_date}&type=${this.state.search.type}&contract=${this.state.contract_Type}`;
    }
    else if (this.state.search.type === 'MONTH') {
      if (!this.state.search.airline || !this.state.search.date || !this.state.search.type) return;
      url = `/contract_logs/${this.state.search.airline}?date=${this.state.search?.date}&type=${this.state.search.type}&contract=${this.state.contract_Type}`; //dnd
    }
    http.get(url)
      .then((response) => {
        // handle success
        this.setState({
          contracts: response.data.data,
          contract_loading: false
        }, () => {
          this.getData();
        });
      });
  };

  fetchLogs = () => {
    let url = '';
    if (this.state.search.type === 'DAY') {
      if (!this.state.search.airline || !this.state.search.start_date || !this.state.search.end_date || !this.state.search.type) return;
      url = `/service_log/${this.state.search.airline}?start_date=${this.state.search?.start_date}&end_date=${this.state.search?.end_date}&type=${this.state.search.type}&contract=${this.state.contract_Type}`;
    }
    else if (this.state.search.type === 'MONTH') {
      if (!this.state.search.airline || !this.state.search.date || !this.state.search.type) return;
      url = `/service_log/${this.state.search.airline}?date=${this.state.search?.date}&type=${this.state.search.type}&contract=${this.state.contract_Type}`; //dnd
    }
    this.setState({ 
      log_loading: true,
      reopen: false,
      approved: false,
      rejected: false,
      finalized: false,
      remark: '',
      // accounting: false,
      checked: [],
    });
    http.get(url)
      .then((response) => {
        // map contracts to logs and assign to data
        return Promise.all(response.data.data.map(async (log) => {
          let contract = this.state.contracts.find(contract => contract.type === log.contract);
          if (contract) {
            await Promise.all(log.data.map(async (controw) => {
              let contractrow = contract.contractrows.find(cr => cr.id === controw.contractrowId);
              if (contractrow) {
                controw.roles = contractrow.roles;
              }
              return controw;
            }));

            // Push missing controw from this.state.contracts to log.data
            contract.contractrows.forEach(contractrow => {
              if (!log.data.find(controw => controw.contractrowId === contractrow.id)) {
                log.data.push({
                  contractId: contract.id,
                  contractrowId: contractrow.id,
                  roles: contractrow.roles,
                  date: log.date,
                  label: contractrow.description + ' ' + contractrow.applied_on,
                  value: null,
                });
              }
            });
          }
          return log;
        }));
      })
      .then((logs) => {
        this.setState({
          logs: logs,
          log_loading: false
        }, () => {
          this.getData();
        });
      })
      .catch((error) => {
        console.error('Error fetching logs:', error);
        this.setState({ log_loading: false });
      });
  };

  getData = () => {
    let adata = [];
    let data = [];
    let dayLogs = [];
    let monthLogs = [];
    let filteredData = [];
    const start = moment(this.state.search.start_date, 'YYYY-MM-DD');
    const end = moment(this.state.search.end_date, 'YYYY-MM-DD');
    const date = moment(this.state.search.date, 'YYYY-MM-DD');

    let current = start.clone();
    if(this.state.search.type === 'DAY'){
      while (current <= end) {
        dayLogs.push({
          date: current.format('YYYY-MM-DD'),
          id: `date_${dayLogs.length + 1}`,
          airline: this.state.airlines.find(airline => airline.id === this.state.search.airline),
          airlineId: this.state.search.airline,
          type: this.state.search.type,
          data: [],
          logs: [],
          roles: null,
        });
        current = current.add(1, 'days');
      }
      adata = [...dayLogs];
    }
    else {
      monthLogs.push({
        date: date.format('YYYY-MM-DD'),
        id: `date_${monthLogs.length + 1}`,
        airline: this.state.airlines.find(airline => airline.id === this.state.search.airline),
        airlineId: this.state.search.airline,
        type: this.state.search.type,
        data: [],
        logs: [],
        roles: null,
      });
      adata = [...monthLogs];
    }

    if (!this.state.contract_loading && !this.state.log_loading) {

      if ((this.state.contracts.length > 0 && this.state.logs.length > 0) || (this.state.contracts.length == 0 && this.state.logs.length > 0)) {
        data = [...this.state.logs];
        // push dayLogs to data if not exist
        if(this.state.search.type === 'DAY'){
          let missing = dayLogs.filter(item => !data.find(arr1Item => arr1Item.date === item.date));
          data = [...data, ...missing];
          filteredData = data;
        }
        else{
          filteredData = data;
        }
        this.setState({ data: filteredData }, () => this.filterData());
        return data;
      }

      if (this.state.contracts.length > 0 && this.state.logs.length === 0) {
        data = [...this.state.logs];
        if(this.state.search.type === 'DAY'){
          let missing = dayLogs.filter(item => !data.find(arr1Item => arr1Item.date === item.date));
          data = [...data, ...missing];
          filteredData = data;
        }
        else{
          filteredData = [...adata];
        }
        this.setState({ data: filteredData }, () => this.filterData());
        return data;
      }

      if(this.state.contracts.length === 0 && this.state.logs.length === 0){
        adata = [];
      }
    }
    this.setState({ adata }, () => {
      this.filterData();
    });
  };

  onChangeSearch = (e) => {
    let search = { ...this.state.search };
    search[e.target.name] = e.target.value;
    if (e.target.name === 'month') {
      search['date'] = moment(e.target.value).endOf('month').format('YYYY-MM-DD');
    }
    this.setState({
      search,
      data: [],
      checked: [],
      remark: ''
    }, () => {
      this.fetchContracts();
      this.fetchLogs();
      if(e.target.name === 'airline') {
        this.fetchContractTypes();
      }
    });
  };

  onCheckAll = (e=null) => {
    let checkedNew = [...this.state.checked || []];
    let recentChecked = [...this.state.recentChecked || []];
    let finalizedCheck = checkedNew;
    let pendingCheck = this.state.filteredData
      .filter((item) => !item.status && typeof item.id == 'number')
      .map((item) => item.id);
    let finalizedLogs = this.state.filteredData.filter((item) => item.status === 'Finalized').map((item) => item.id);
    let reopendLogs = this.state.filteredData.filter((item) => item.status === 'Reopen').map((item) => item.id);

    if(this.state.filterLogs === 'All'){
      if (e.target.checked) { 
        // checkedNew = [...finalizedCheck, ...reopendLogs,  ...pendingCheck];
        // recentChecked = [ ...reopendLogs,  ...pendingCheck];
        // Combine arrays and ensure uniqueness using Set
        checkedNew = [...new Set([...finalizedCheck, ...reopendLogs, ...pendingCheck])];
        recentChecked = [...new Set([...reopendLogs, ...pendingCheck])];
      } else {
        checkedNew = finalizedLogs;
        recentChecked = [];
      }
    }
    if(this.state.filterLogs === 'Pending'){
      if (e.target.checked) { 
        checkedNew = this.state.filteredData.map((item) => item.id);
        checkedNew = checkedNew.filter((item) => typeof item == 'number');
        recentChecked = checkedNew;
      } else {
        checkedNew = [];
        recentChecked = [];
      }
    }
    if(this.state.filterLogs === 'Finalized'){
      if (e.target.checked) { 
        checkedNew = [...finalizedCheck, ...finalizedLogs];
        recentChecked = [...finalizedLogs];
      } else {
        checkedNew = checkedNew.filter(item => !finalizedLogs.includes(item));
        recentChecked = [];
      }
    }
    this.setState({ 
      checked: checkedNew,
      recentChecked: recentChecked,
      checkedAll: e.target.checked
    },() => { });
  };

  onChange = (e) => {
    if (e.target.name == 'remark') {
      this.setState({ remark: e.target.value });
    }
    if (e.target.name == 'log') {
      let value = parseInt(e.target.value);
      // let recentChecked = [];
      let recentChecked = [...this.state.recentChecked || []];
      let checked = [...this.state.checked || []];
      var index = checked.indexOf(value);
      const indexRecent = recentChecked.indexOf(value);
      // var indexRecent = recentChecked.indexOf(value);
      if (index !== -1) {
        checked.splice(index, 1);
      } else {
        if(!isNaN(value)){
          checked.push(value);
        }
      }
      if (indexRecent !== -1) {
        recentChecked.splice(indexRecent, 1);
      } else {
        if(!isNaN(value)){
          recentChecked.push(value);
        }
      }
      // if(recentChecked && !recentChecked.includes(value)) {
      //   recentChecked.push(value);
      // }
      // if (indexRecent !== -1) {
      //   recentChecked.splice(indexRecent, 1);
      // } else {
      //   console.log(recentChecked, 'recentChecked before');
      //   recentChecked.push(value);
      //   console.log(recentChecked, 'recentChecked after');
      // }
      this.setState({
        checked,
        recentChecked 
      },() => {
        // const role = cookies.get('role');
        // let reopen = checked.length>0 ? this.disableButtons('Reopen') : true;
        // let approved = checked.length>0 ? this.disableButtons('Approved') : true;
        // let finalized = checked.length>0 ? this.disableButtons('Finalized') : true;
        // let rejected = checked.length>0 ? this.disableButtons('Rejected') : true;
        // this.setState({
        //   reopen: !reopen,
        //   approved: !approved,
        //   rejected: !rejected,
        //   finalized: !finalized
        // });
      });
    }
  };

  disableButtons = (type) => {
    let flag = false;
    let userRole = cookies.get('role');
    let reportLogs = this.state.data;
    if(reportLogs.length > 0){
      let found = false;
      reportLogs.map((r) => {
        if(r.contract === this.state.cont_item){
          found = true;
          let logs = r.log || [];
          if(logs.length > 0){
            let lastlog = logs[logs.length - 1];
            if(userRole === 'System Admin'){
              // do something
              if(type === 'Finalized'){
                if(lastlog.status === 'Finalized' || lastlog.status === 'Approved' || (lastlog.status === 'Rejected' && r.approved_air)){
                  flag = true;
                } else {
                  flag = false;
                }
              } else if(type == 'Approved' || type == 'Rejected'){
                if((lastlog.status === 'Approved' && (r.approved_acc && r.approved_air ))
                  || (lastlog.status === 'Reopen') || (lastlog.status === 'Rejected' && r.approved_acc )
                ){
                  flag = true;
                } else {
                  flag = false;
                }
              } else if(type === 'Reopen'){
                if((lastlog.status === 'Reopen' || r.status === 'Approved') || (lastlog.status === 'Rejected' && !r.rejected)){
                  flag = true;
                } else {
                  flag = false;
                }
              } else {
                flag = true;
              }
            } else if(userRole === 'Office Agent' || userRole === 'Senior Office Agent'){
              if(type === 'Finalized'){
                if(lastlog.status === 'Finalized' || lastlog.status === 'Approved' || (lastlog.status === 'Rejected' && r.approved_air)){
                  flag = true;
                } else {
                  flag = false;
                }
              } else {
                flag = true;
              }
            } else if(userRole === 'Accounting'){
              if(type == 'Approved' || type == 'Rejected'){
                if((lastlog.status === 'Approved' && (r.approved_acc || r.approved_air ))
                  || (lastlog.status === 'Reopen') || (lastlog.status === 'Rejected' && r.approved_air )
                ){
                  flag = true;
                } else {
                  flag = false;
                }
              } else {
                flag = true;
              }
            } else if(userRole === 'Airline Rep') {
              if(type == 'Approved' || type == 'Rejected'){
                if((lastlog.status === 'Finalized' || lastlog.status === 'Reopen') ||
                  (lastlog.status === 'Approved' && r.approved_air ) || 
                  (lastlog.status === 'Rejected' && (r.approved_acc || r.approved_air))
                ){
                  flag = true;
                } else {
                  flag = false;
                }
              } else {
                flag = true;
              }
            } else if(userRole === 'Supervisor') {
              if(type === 'Reopen'){
                if(lastlog.status === 'Reopen' || lastlog.status === 'Approved' || lastlog.status === 'Rejected'){
                  flag = true;
                } else {
                  flag = false;
                }
              } else {
                flag = true;
              }
            } else {
              flag = true;
            }
          } else {
            if(type === 'Finalized' && (userRole === 'Office Agent' || userRole === 'Senior Office Agent' || userRole === 'System Admin')){
              flag = false;
            }
            else {
              flag = true;
            }
          }
        } else {
          if(!found){
            flag = true;
          }
        }
      });
    } else {
      if(type === 'Finalized' && (userRole === 'Office Agent' || userRole === 'Senior Office Agent')){
        flag = false;
      }
      else {
        flag = true;
      }
    }
    return flag;
  };

  onSubmit = (status) => {  
    // let isLogEmpty = [...this.state.emptyLog];
    let isLogEmpty = [];
    let finalizeLogs = [];
    this.state.filteredData.map((item) => {
      if (this.state.recentChecked.includes(item.id)) {
        const emptyLog = !item.data.every((d) => d.value >= 0);
    
        if (emptyLog) {
          isLogEmpty.push(item.id);
          // this.setState({ emptyLog: isLogEmpty }, () =>{
          // });
          // toast.warning('Empty fields found.');
          // return;
        }
        else {
        //   isLogEmpty = [];
        //   this.setState({ emptyLog: isLogEmpty }, () =>{
        //   });
        //push non empty logs ids to finalizeLogs
          let push = true; 
          if(item.data.length > 0){
            Promise.all(item.data.map((d) => {
              let split = d.label.split('| ');
              let type = split[1];
              if(type === 'Manual'){
                if(d.value === 0 || d.value === '' || d.value === null){
                  isLogEmpty.push(item.id);
                  push = false;
                  //exit the map
                  return;
                }
              }
            }));
          }
          if(push)
            finalizeLogs.push(item.id);
        }
    
        return { ...item, };
      }
      return item;
    });
    // if(isLogEmpty.length > 0 && status === 'Finalized'){
    //   toast.warning('Empty fields found.');
    this.setState({ emptyLog: isLogEmpty });
    // }
    if(this.state.recentChecked.length === 0){
      toast.warning('No Records are selected.');
    } else if((finalizeLogs.length > 0  && status === 'Finalized') || (status === 'Submitted') ){
      http.put('/service_log', {
        ids: status === 'Finalized' ? finalizeLogs : this.state.recentChecked,
        remark: status === 'Finalized' ? '' : this.state.remark || '',
        status: status
      }).then(() => {
        if(status === 'Finalized'){
          this.setState({
            recentChecked: [],
            emptyLog: [],
            finalizeLogs: []
          }); //check
        } else {
          if(finalizeLogs.length > 0){
            this.setState({ 
              popup: 'finalize',
              finalizeLogs: finalizeLogs
            });
          } else {
            this.setState({
              recentChecked: [],
              emptyLog: [],
              finalizeLogs: []
            }); //check
          }
        }
      });
    }
  };

  onApproved = () => {
    http.put('/service_log', {
      ids: this.state.checked,
      remark: this.state.remark || '',
      status: 'Approved'
    }).then(() => {
      //success
    });
  };

  onReopen = () => {
    if(this.state.recentChecked && this.state.recentChecked.length > 0){
      http.put('/service_log', {
        ids: this.state.recentChecked,
        remark: this.state.remarks || '',
        status: 'Reopen'
      }).then(() => {
        this.setState({ 
          recentChecked: [],
          remarks: '',
          anchorEl3: null,
        });
      });
    } else {
      toast.warning('No Records are selected.');
    }
  };

  onRejected = () => {
    http.put('/service_log', {
      ids: this.state.checked,
      remark: this.state.remark || '',
      status: 'Rejected'
    }).then(() => {
      this.fetchLogs();
    });
  };

  handleContractType = (e) => {
    let cont_item = contractType[e.target.value];
    this.setState({ 
      contract_Type: e.target.value,
      cont_item: cont_item,
    },()=> {
      this.fetchContracts();
      this.fetchLogs();
    });
  };

  // filter
  filterData = () => {
    let filteredData = [...this.state.data];
    let disabledCheckbox = [];
    let checked = [];

    filteredData.map(item => 
    {if(item?.status && item.status === 'Finalized'){
      checked.push(item.id);
    }
    });

    if (this.state.filterLogs === 'All') {
      filteredData.forEach(item => {
        if((!item?.status || item.status === 'Reopen' || item.status === 'Submitted' ) 
        // || (item?.status && !item?.status && item.data && typeof item.id === 'number')
        //  || item.status === 'Rejected' || item.status === 'Reopen' || item.status === 'Approved'
        ){
          disabledCheckbox.push(item.id);
          new Set(disabledCheckbox);
        }
      });
      filteredData ;
      //DND
      // filteredData = filteredData.filter(item => !item.log  
      // );
      // filteredData.map((item) => {
      //   disabledCheckbox.push(item.id);
      // });
    }
    
    if(this.state.filterLogs === 'Pending'){
      filteredData = filteredData.filter(item => 
        !item.log || item.status === 'Rejected' || item.status === 'Reopen' || item.status === 'Submitted' || item.status === null
      );
      if( cookies.get('role') === 'Office Agent'|| cookies.get('role') === 'Senior Office Agent' || cookies.get('role') === 'System Admin'){
        disabledCheckbox = filteredData.map((item) => item.id);
      } else {
        disabledCheckbox = [];
      }
    }
    
    if (this.state.filterLogs === 'Finalized') {
      filteredData = filteredData.filter(item => 
        (item?.status && (item.status === 'Finalized' || item.status === 'Approved' || item.status === 'Pending' )));
      filteredData.map(item => 
      {
        if(item?.status && (item.status === 'Finalized' || item.status === 'Approved' || item.status === 'Pending' ) ){
          disabledCheckbox.push(item.id);
          checked = [];
        }
      });
    }
    else {
      filteredData ;
    }
    this.setState({
      filteredData: filteredData,
      disabledCheckbox: disabledCheckbox,
      checked: checked
    },);

  };
    
  handleDoc = (row) => {
    this.props.setDoc({
      modal: 'service_log',
      id: row.id,
      data: row.docs
    });
  }; 

  onChangeFilter = (e) => {
    this.setState({ filterLogs: e.target.value }, () => {
      this.filterData();
    });

  };

  closePopup = () => {
    this.setState({
      popup: false,
      anchorEl: null,
      recentChecked: [],
      emptyLog: [],
    });
  };

  handleClick = (anchorEl) => {
    this.setState({ 
      anchorEl3: anchorEl,
      remarks: '',
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl3: null,
      remarks: '',
    });
  };

  setRemark = (value) => {
    this.setState({ remarks: value });
  };


  render() {
    return (
      <>
        <Logs
          history={this.props.history}
          airlineId={this.state.airlineId} 
          airlines={this.state.airlines}
          search={this.state.search}
          onChangeSearch={this.onChangeSearch}
          data={this.state.data}
          contracts={this.state.contracts}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
          onApproved={this.onApproved}
          onReopen={this.onReopen}
          onRejected={this.onRejected}
          checked={this.state.checked}
          recentChecked={this.state.recentChecked}
          finalizeLogs={this.state.finalizeLogs}
          remark={this.state.remark}
          fetchLogs={this.fetchLogs}
          reopen={this.state.reopen}
          finalized={this.state.finalized}
          approved={this.state.approved}
          rejected={this.state.rejected}
          accounting={this.state.accounting}
          handleContractType={this.handleContractType}
          contract_Type={this.state.contract_Type}
          cont_item={this.state.cont_item}
          userContractTypes={this.state.userContractTypes}
          logs={this.state.logs}
          value={this.state.filterLogs}
          onChangeFilter={this.onChangeFilter} 
          filteredData={this.state.filteredData}
          onCheckAll={this.onCheckAll}
          checkedAll={this.state.checkedAll}
          disabledCheckbox={this.state.disabledCheckbox}
          contractTypesList={this.state.contractTypesList}
          emptyLog={this.state.emptyLog}
          filterData={this.filterData}
          handleDoc={this.handleDoc}
          popup={this.state.popup}
          closePopup={this.closePopup}

          anchorEl3={this.state.anchorEl3}
          handleClick={this.handleClick}
          handleClose={this.handleClose}
          setRemark={this.setRemark}
          remarks={this.state.remarks}
          refROBrn={this.refROBrn}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    doc: { ...state.doc, },
    stations: state.stations,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDelete: (data) => dispatch(setDeleteAction(data)),
    setDoc: (data) => dispatch(setDocAction(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogsContainer);
