import React from 'react';
import FlightReport from './FlightReport';
import http from 'utils/http';
import moment from 'moment';
import cookies from 'utils/cookies';

const contractTypes = cookies.get('contract_types') && cookies.get('contract_types') !== 'null' && cookies.get('contract_types').length>0 ? cookies.get('contract_types').sort() : [];
const Role = cookies.get('role');

class FlightReportContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contract_Type: contractTypes[0],
      list: [],
      airlines: [],
      airline: null,
      airlineCode: null,
      role: Role === 'Airline Rep' ? 'air_rep' :'accountant',
      search: {
        from: moment().startOf('month').format('YYYY-MM-DD'),
        to: moment().endOf('month').format('YYYY-MM-DD'),
      },
      anchorEl: null,
      ids: [],
      modalflight: null,
      selectedOption: null,
      remark: '',
      records: [],

      modalOpen: false,
      flightReportData: null,
      ctype: null,

    };
  }

  componentDidMount() {
    if (this.props.cont_item) {
      //call other functions after getFilters finishes
      Promise.resolve(this.getFilters()).then(() => {
        this.fetchAirlines();
        // this.getList();
      });
    }
  }

  fetchAirlines = () => {
    http.get(`/airline?active=${true}`)
      .then((response) => {
        if(this.state.airline && this.state.airlineCode){
          this.setState({ airlines: response.data.data }, () => { this.getList(); });
        } else {
          this.setState({
            airlines: response.data.data,
            airline: response.data.data[0].id,
            airlineCode: response.data.data[0].code
          }, () => { 
            this.storeFilters();
            this.getList(); 
          });
        }
      });
  };

  getList = () => {
    http.get(`/approval/flight?from=${this.state.search.from}&to=${this.state.search.to}&type=${this.state.contract_Type}`).then((response) => {
      let formattedData = [];
      if (response.data.data && response.data.data.length > 0) {
        formattedData = response.data.data.map((item) => {
          // calculate loose/intact/total awbs, PCS, weight, ulds.
          // calculate ulds, LD3_count, LD7_count, MD_count.
          let loose_awb = 0;
          let intact_awb = 0;
          let total_awb = 0;
          let loose_pcs = 0;
          let intact_pcs = 0;
          let total_pcs = 0;
          let loose_weight = 0;
          let intact_weight = 0;
          let total_weight = 0;
          let loose_uld = 0;
          let intact_uld = 0;
          let total_uld = 0;
          let loose_LD3_count = 0;
          let loose_LD7_count = 0;
          let loose_MD_count = 0;
          let intact_LD3_count = 0;
          let intact_LD7_count = 0;
          let intact_MD_count = 0;
          let total_LD3_count = 0;
          let total_LD7_count = 0;
          let total_MD_count = 0;
          if (item.flight.flight_uld && item.flight.flight_uld.length > 0) {
            item.flight.flight_uld.map((uld) => {
              if (uld.type === 'Loose') {
                loose_uld += 1;
                total_uld += 1;
                if (uld.flight_awbs && uld.flight_awbs.length > 0) {
                  uld.flight_awbs.map((awb) => {
                    loose_awb += 1;
                    total_awb += 1;
                    if (awb.pcs && !isNaN(awb.pcs)) {
                      loose_pcs += awb.pcs;
                      total_pcs += awb.pcs;
                    }
                    if (awb.wgt && !isNaN(awb.wgt)) {
                      loose_weight += parseFloat(awb.wgt);
                      total_weight += parseFloat(awb.wgt);
                    }
                  });
                }
                if (uld?.uld_type?.includes('LD3')) {
                  let add = 1;
                  if (uld?.uld_type?.includes('2X')) {
                    add = 2;
                  }
                  loose_LD3_count += add;
                  total_LD3_count += add;
                } else if (uld?.uld_type?.includes('LD7')) {
                  let add = 1;
                  loose_LD7_count += add;
                  total_LD7_count += add;
                } else if (uld?.uld_type?.includes('MD')) {
                  let add = 1;
                  if (uld?.uld_type?.includes('2X')) {
                    add = 2;
                  }
                  loose_MD_count += add;
                  total_MD_count += add;
                }
              } else if (uld.type === 'Intact') {
                intact_uld += 1;
                total_uld += 1;
                if (uld.flight_awbs && uld.flight_awbs.length > 0) {
                  uld.flight_awbs.map((awb) => {
                    intact_awb += 1;
                    total_awb += 1;
                    if (awb.pcs && !isNaN(awb.pcs)) {
                      intact_pcs += awb.pcs;
                      total_pcs += awb.pcs;
                    }
                    if (awb.wgt && !isNaN(awb.wgt)) {
                      intact_weight += parseFloat(awb.wgt);
                      total_weight += parseFloat(awb.wgt);
                    }
                  });
                }
                if (uld?.uld_type?.includes('LD3')) {
                  let add = 1;
                  if (uld?.uld_type?.includes('2X')) {
                    add = 2;
                  }
                  intact_LD3_count += add;
                  total_LD3_count += add;
                } else if (uld?.uld_type?.includes('LD7')) {
                  let add = 1;
                  intact_LD7_count += add;
                  total_LD7_count += add;
                } else if (uld?.uld_type?.includes('MD')) {
                  let add = 1;
                  if (uld?.uld_type?.includes('2X')) {
                    add = 2;
                  }
                  intact_MD_count += add;
                  total_MD_count += add;
                }
              } else {
                total_uld += 1;
                if (uld.flight_awbs && uld.flight_awbs.length > 0) {
                  uld.flight_awbs.map((awb) => {
                    total_awb += 1;
                    if (awb.pcs && !isNaN(awb.pcs)) {
                      total_pcs += awb.pcs;
                    }
                    if (awb.wgt && !isNaN(awb.wgt)) {
                      total_weight += parseFloat(awb.wgt);
                    }
                  });
                }
                if (uld?.uld_type?.includes('LD3')) {
                  let add = 1;
                  if (uld?.uld_type?.includes('2X')) {
                    add = 2;
                  }
                  total_LD3_count += add;
                } else if (uld?.uld_type?.includes('LD7')) {
                  let add = 1;
                  total_LD7_count += add;
                } else if (uld?.uld_type?.includes('MD')) {
                  let add = 1;
                  if (uld?.uld_type?.includes('2X')) {
                    add = 2;
                  }
                  total_MD_count += add;
                }
              }
            });
          }
          item.loose_awb = loose_awb;
          item.intact_awb = intact_awb;
          item.total_awb = total_awb;
          item.loose_pcs = loose_pcs;
          item.intact_pcs = intact_pcs;
          item.total_pcs = total_pcs;
          item.loose_weight = loose_weight;
          item.intact_weight = intact_weight;
          item.total_weight = total_weight;
          item.loose_uld = loose_uld;
          item.intact_uld = intact_uld;
          item.total_uld = total_uld;
          item.loose_LD3_count = loose_LD3_count;
          item.loose_LD7_count = loose_LD7_count;
          item.loose_MD_count = loose_MD_count;
          item.intact_LD3_count = intact_LD3_count;
          item.intact_LD7_count = intact_LD7_count;
          item.intact_MD_count = intact_MD_count;
          item.total_LD3_count = total_LD3_count;
          item.total_LD7_count = total_LD7_count;
          item.total_MD_count = total_MD_count;
          return item;
        });
      }
      this.setState({
        records: formattedData,
        list: []
      },()=>{
        this.filterData();
      });
    });
  };

  filterData = () =>{
    let data = this.state.records;
    //filter by airline
    let filteredData = data.filter((item) => {
      return item.flight && item.flight.airline.id === this.state.airline;
    });
    //filter by role 
    filteredData = filteredData.filter((item) => {
      if(this.state.role === 'accountant'){
        return item.flight && item.flight.airline.id === this.state.airline && ((item.status === 'Finalized' && item.approved_acc === false) || (item.status === 'Rejected' && item.approved_acc === false));
      } else if(this.state.role === 'air_rep'){
        return item.flight && item.flight.airline.id === this.state.airline && (item.status === 'Pending' && item.approved_air === false);
      }
    });
    // sort by date
    filteredData.sort((a, b) => {
      return new Date(a?.flight?.flight_date) - new Date(b?.flight?.flight_date);
    });
    this.setState({ 
      list: filteredData,
      ids: []
    });
  };

  UpdateFlightReport = () => {
    let ids = [];
    let option = this.state.selectedOption;
    if(this.state.modalflight){
      ids.push(this.state.modalflight.id);
    } else {
      // get all selected ids
      ids = this.state.ids;
    }
    let data = {
      ids: ids,
      status: option === 'comment' ? 'Comment' : option === 'ok' ? 'Approved' : 'Rejected',
      remark: this.state.remark,
    };
    http.post('/flight/reports', data).then((response) => {
      console.log('UpdateFlightReport',response);
      this.getList();
      this.handleReviewClose();
    });
  };

  selectAll = (flag) => {
    if(flag){
      let ids = this.state.list.map((item) => item.id);
      this.setState({ ids: ids });
    } else {
      this.setState({ ids: [] });
    }
  };

  selectFlights = (id, flag)=>{
    console.log('selectFlights', id, flag);
    if(flag){
      this.setState({ ids: [...this.state.ids, id] });
    } else {
      let filtered = this.state.ids.filter((item) => item !== id);
      this.setState({ ids: filtered });
    }
  };
  selectOption = (value) => {
    this.setState({ selectedOption: value });
  };

  setRemark = (value) => {
    this.setState({ remark: value });
  };

  onChangeSearch = (e) => {
    if (e.target.name === 'month') {
      let from = moment(e.target.value).startOf('month').format('YYYY-MM-DD');
      let to = moment(e.target.value).endOf('month').format('YYYY-MM-DD');
      this.setState({
        search: {
          ...this.state.search,
          from: from,
          to: to
        }
      }, () => {
        this.storeFilters();
        this.getList();
      });
    } else {
      this.setState({
        search: {
          ...this.state.search,
          [e.target.name]: e.target.value
        }
      }, () => {
        this.storeFilters();
        this.getList();
      });
    }
  };

  handleContractType = (e) => {
    this.setState({ contract_Type: e.target.value, },()=>{
      this.storeFilters();
      this.getList();
    });
  };

  handleAirlineChange = (e) => {
    let value = e.target.value;
    let airline = this.state.airlines.find(airline => airline.id === value).id;
    let airlineCode = this.state.airlines.find(airline => airline.id === value).code;
    this.setState({
      airline: airline,
      airlineCode: airlineCode,
    },()=>{
      this.storeFilters();
      this.filterData();
    });
  };
  handleRoleChange = (e) => {
    this.setState({ role: e.target.value },()=>{
      this.storeFilters();
      this.filterData(); 
    });
  };

  handleReview = (anchorEl, flight) => {
    console.log('handleReview', flight);
    this.setState({ 
      anchorEl,
      modalflight: flight
    });
  };

  handleReviewClose = () => {
    this.setState({ 
      anchorEl: null,
      modalflight: null,
      selectedOption: null,
      remark: '',
    });
  };

  handleFlight = (data) => {
    this.setState({
      modalOpen: true,
      flightReportData: data.flightId,
      ctype: data.type,
    });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  storeFilters = () => {
    console.log('storeFilters');
    let filters = {
      search: this.state.search,
      contract_Type: this.state.contract_Type,
      airline: this.state.airline,
      airlineCode: this.state.airlineCode,
      role: this.state.role,
    };
    // set expiry got 1 day.
    cookies.set('approvals', filters, { 
      path: '/', 
      maxAge: 86400 
    });
  };
  getFilters = () => {
    console.log('getFilters');
    let filters = cookies.get('approvals');
    if (filters) {
      if(filters.search){
        this.setState({ search: filters.search });
      }
      if(filters.contract_Type){
        this.setState({ contract_Type: filters.contract_Type });
      }
      if(filters.airline){
        this.setState({ airline: filters.airline });
      }
      if(filters.airlineCode){
        this.setState({ airlineCode: filters.airlineCode });
      }
      if(filters.role){
        this.setState({ role: filters.role });
      }
    }
  };

  render() {
    return (
      <FlightReport
        list={this.state.list}
        search={this.state.search}
        onChangeSearch={this.onChangeSearch}
        anchorEl={this.state.anchorEl}
        handleReviewClose={this.handleReviewClose}
        handleReview={this.handleReview}
        contract_Type={this.state.contract_Type}
        handleContractType={this.handleContractType}
        airlines={this.state.airlines}
        airline={this.state.airline}
        airlineCode={this.state.airlineCode}
        handleAirlineChange={this.handleAirlineChange}
        role={this.state.role}
        handleRoleChange={this.handleRoleChange}
        modalflight={this.state.modalflight}
        UpdateFlightReport={this.UpdateFlightReport}
        selectedOption={this.state.selectedOption}
        selectOption={this.selectOption}
        setRemark={this.setRemark}
        remark={this.state.remark}
        selectFlights={this.selectFlights}
        ids={this.state.ids}
        selectAll={this.selectAll}

        modalOpen={this.state.modalOpen}
        handleFlight={this.handleFlight}
        flightReportData={this.state.flightReportData}
        ctype={this.state.ctype}
        handleModalClose={this.handleModalClose}

      />
    );
  }
}

export default FlightReportContainer;
