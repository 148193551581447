/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';
import { Dialog, DialogContent, IconButton, Grid, Tooltip, InputAdornment, RadioGroup, Stack, Radio, FormControl, MenuItem } from '@mui/material';
import styles from 'custom/style.module.css';
import docStyles from './docs.module.css';
import { CustomButton, CustomTextField, CustomDialogTitle, CustomDialogAction, CustomInputLabel, StyledCard, InputProps, CustomRadio, CustomSelect, SelectInputProps, CustomDatePicker } from 'custom/components';
import { BrowseIcon, CloseIconBtn } from 'custom/constants';
import PartyDoc from './forms/PartyDoc';

const AddDocModal = (props) => {
  const { modal, onClose, fields, errors, onSubmit, onChange, onSubmitReq } = props;

  const AWB = <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={styles.w100}>
    <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
      <CustomInputLabel htmlFor='awb' aria-label='awb'>AWB #</CustomInputLabel>
      <CustomTextField
        {...InputProps}
        id='awb'
        fullWidth
        placeholder='AWB #'
        name='awb'
        InputLabelProps={{ shrink: false }}
      />
    </Stack>
  </Grid>;

  const Flight = <Fragment>
    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
        <CustomInputLabel aria-label='airline'>Airline</CustomInputLabel>
        <FormControl variant='standard' fullWidth>
          <CustomSelect
            {...SelectInputProps}
            id='airline'
            name='airlineId'
            // onChange={(e) => props.handleChangeForm(e, 'airlineId')}
            // value={Number(props.formFields.airlineId) || ''}
          >
            {/* {props.airlines?.map((airline, i) => (
                <MenuItem key={i} value={airline.id}>{airline.code}</MenuItem>
              ))} */}
          </CustomSelect>
        </FormControl>
      </Stack>
    </Grid>
    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
        <CustomInputLabel aria-label='flight_no' htmlFor='flight_no'>Flight Number</CustomInputLabel>
        <CustomTextField
          {...InputProps}
          id='flight_no'
          fullWidth
          placeholder='Flight Number'
          type='text'
          name='flight_no'
        />
      </Stack>
    </Grid>
    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
        <CustomInputLabel aria-label=''>Flight Date</CustomInputLabel>
        <CustomDatePicker
          slotProps={{
            textField: {
              ...InputProps,
              fullWidth: true,
              placeholder: 'Flight Date'
            }, 
          }}
          format='DD MMM YYYY'
          name='flight_date'
          className={styles.w100}
        />
      </Stack>
    </Grid>
    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
        <CustomInputLabel aria-label='origin' htmlFor='origin'>Origin</CustomInputLabel>
        <CustomTextField
          {...InputProps}
          id='origin'
          fullWidth
          placeholder='Origin'
          type='text'
          name='org'
        />
      </Stack>
    </Grid>

    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
        <CustomInputLabel aria-label='destination' htmlFor='destination'>Destination</CustomInputLabel>
        <CustomTextField
          {...InputProps}
          id='destination'
          fullWidth
          placeholder='Destination'
          type='text'
          name='dest'
        />
      </Stack>
    </Grid>
  </Fragment>;

  const OtherDoc = <Fragment>
    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
        <CustomInputLabel htmlFor='attachto-label' aria-label='type'>Attach To</CustomInputLabel>
        <FormControl variant='standard' fullWidth>
          <CustomSelect
            {...SelectInputProps}
            labelId='attachto-label'
            id=''
            name='attachTo'
            value={props.attachTo}
            onChange={(e) => props.onhandleChange(e)}
          >
            <MenuItem value='AWB'>Airwaybill</MenuItem>
            <MenuItem value='FFM'>Flight Manifest</MenuItem>
            <MenuItem value='OTHER'>Do not attach: File as Other</MenuItem>
          </CustomSelect>
        </FormControl>
      </Stack>
    </Grid>
  </Fragment>;

  const AttachOtherDoc = <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
    <Stack direction='row' justifyContent='space-between' alignItems='center' useFlexGap flexWrap='wrap'>
      <CustomInputLabel htmlFor='ref_id' aria-label='Refrence Id'>Reference # (optional)</CustomInputLabel>
      <CustomTextField
        {...InputProps}
        id='ref_id'
        fullWidth
        placeholder='Reference #'
        type='text'
        name='ref_id'
        inputProps={{ 'aria-label': 'Reference-id' }}
      />
    </Stack>
  </Grid>;

  const returnForm = (type, key) => {
    switch (key) {
      case 'AWB':
        return AWB;
      case 'FFM':
        return Flight;
      case (type === 'addDoc' && 'OTHER'):
        return OtherDoc;
      case (type === 'attachTo' && 'OTHER'):
        return AttachOtherDoc;
      default:
        break;
    }
  };

  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={modal === 'addDoc'} onClose={onClose} maxWidth={'sm'} fullWidth>
        <CustomDialogTitle id='form-dialog-title'>
          {'Add New Attachment'}
          <CloseIconBtn onClick={onClose} />
        </CustomDialogTitle>
        <Grid container>
          <Grid item xs={12} md={12} sx={{
            padding: '24px',
            pb: 0 
          }}>
            {props.fileType &&
            <RadioGroup
              id='type'
              aria-labelledby='radio-buttons-group'
              name='fileType'
              row
              onChange = {props.onhandleChange}
              value={props.fileType}
            // error={props.taxErrors.type}
            // helperText={props.taxErrors.type}
            >
              <Stack spacing={4} direction='row'>
                <CustomRadio control={<Radio />} value='local' label={'Update from local file'} />
                <CustomRadio control={<Radio />} value='third' label={'Request from 3rd party'} />
              </Stack>
            </RadioGroup>
            }
          </Grid>
          <Grid item xs={12} md={12}>
            {props.fileType === 'local' ?
              <form className={styles.w100} onSubmit={onSubmit}>
                <DialogContent>
                  <StyledCard>
                    <Grid container spacing={2}>
                      <Grid item xs={12} >
                        <CustomInputLabel htmlFor='methods'>Name</CustomInputLabel>
                        <CustomTextField
                          {...InputProps}
                          fullWidth
                          id='name'
                          placeholder='Name'
                          type='text'
                          name='name'
                          onChange={onChange}
                          value={fields.name}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <Tooltip title={fields?.file?.name}>
                                  <span className={docStyles.fileName}>{fields?.file?.name}</span>
                                </Tooltip>
                                <IconButton size='small'>
                                  <input
                                    accept='application/pdf,image/*'
                                    style={{ display: 'none' }}
                                    id='file'
                                    type='file'
                                    name='file'
                                    onChange={onChange}
                                  />
                                  <label htmlFor='file' className={`${styles.fs0} ${styles.pointer}`}>
                                    <BrowseIcon />
                                  </label>
                             
                                </IconButton> 
                              </InputAdornment>
                            )
                          }}
                          error={errors.name}
                          helperText={errors.name}
                        />
                      </Grid>
                    </Grid>
                  </StyledCard>
                </DialogContent>
                <CustomDialogAction className={`${styles.fSpaceBtw}`}>
                  <CustomButton variant='contained' type='reset' onClick={props.onClose}> Close </CustomButton>
                  <CustomButton variant='contained' type='submit'> Submit </CustomButton>
                </CustomDialogAction>
              </form>
              :
              <form className={styles.w100} onSubmit={onSubmitReq}>
                <DialogContent>
                  <PartyDoc {...props} />
                </DialogContent>
                <CustomDialogAction className={`${styles.fSpaceBtw}`}>
                  <CustomButton variant='contained' type='reset' onClick={props.onClose}> Close </CustomButton>
                  <CustomButton variant='contained' type='submit'> Submit </CustomButton>
                </CustomDialogAction>
              </form>
            }
          </Grid>
        </Grid>


      </Dialog>
    </div>
  );
};
/* eslint-enable no-unused-vars */
export default AddDocModal;