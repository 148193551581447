import React, { Fragment } from 'react';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ConfirmModalContainer from '../common/confirmModal/ConfirmModalContainer';
import adminStyles from '../admin/tabs/admin.module.css';
import styles from '../../../custom/style.module.css';
import { Grid, IconButton } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import theme from 'custom/theme';
import { CustomTab } from '../../../custom/components';
// import { contractType } from 'custom/constants';
import FlightReportContainer from './tabs/flightReport/FlightReportContainer';
import ServiceLogContainer from './tabs/serviceLog/ServiceLogContainer';

const BulkApproval = (props) => {
  const { value /*,  handleContractType , contract_Type*/ } = props;
  function TabPanel(tabPanelProps) {
    const { children, index, ...other } = tabPanelProps;
    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {tabPanelProps.value === index && (
          <Box sx={{ pt: 0 }}>
            <Typography component='span'>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(path) {
    const index = path.replace('approvals/', '');
    return {
      id: `simple-tab-${index}`,
      value: index,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Grid container spacing={5} className={styles.mt1}>
        <Grid item xs={12}>
          <Tabs value={value} onChange={(_, v) => props.handleChange(v)}
            ref={props.elementRef}
            className={`${styles.customTabs} ${styles.dFlex}`}
            // variant='scrollable'
            variant='fullWidth'
            aria-label='Flight tabs'
            indicatorColor='transparent'
            classes={{ scroller: styles.tabFlexContainer }}
            ScrollButtonComponent={(props) => {
              if (
                props.direction === 'left'
              ) {
                return (
                  <IconButton {...props} className={adminStyles.tabArrows} disableRipple size='small'>
                    <ArrowLeftIcon fontSize='inherit' 
                      sx={{ color: props.disabled ? 'disabled' : theme.palette.primary.main }}
                    />
                  </IconButton>
                );
              } else if (
                props.direction === 'right'
              ) {
                return (
                  <IconButton {...props} className={adminStyles.tabArrows} disableRipple>
                    <ArrowRightIcon
                      sx={{ color: props.disabled ? 'disabled' : theme.palette.primary.main }}
                    />
                  </IconButton>
                );
              } else {
                return null;
              }
            }}>
            <CustomTab label={'Flight Report'} {...a11yProps('flight_report')} />
            <CustomTab label={'Service Logs'} {...a11yProps('service_logs')} />
          </Tabs>
        </Grid>

        {/* <Grid item xs={12}>
          <RadioGroup
            aria-labelledby='contract-type-radio-buttons-group'
            name='contract-type-radio-buttons-group'
            // value={contractType}
            value={props.contract_Type}
            row
            onChange={handleContractType}
            className={`${styles.radioToggleGroup} ${styles.mauto}`}
          >
            { Object.entries(contractType).map(([key, value], index) => {
              if (props.userContractTypes?.includes(value)) {
                console.log(index, value, 'index, value');
                return <Fragment key={index}>
                  <CustomRadio key={key} id={index} value={key} 
                    control={<Radio className={styles.radioToggleBtn} 
                    />} 
                    label={value} 
                    className={`${styles.radioToggle} ${props.contract_Type === key ? styles.radioToggleBtnChecked : ''}`}
                    classes={{ label: styles.radioToggleLabel }} 
                  />
                </Fragment>;
              }
            })}
          </RadioGroup>
        </Grid> */}

        <Grid item xs={12}>
          <TabPanel value={value} index={'flight_report'}>
            <FlightReportContainer 
              contract_Type={props.contract_Type}
              cont_item={props.cont_item}
            />
          </TabPanel>
          <TabPanel value={value} index={'service_logs'}>
            <ServiceLogContainer 
              contract_Type={props.contract_Type}
              cont_item={props.cont_item}
            />
          </TabPanel>
        </Grid>

      </Grid>
      
      <ConfirmModalContainer />
    </>
  );
};

export default BulkApproval;
