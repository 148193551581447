import React from 'react';
// import cookies from '../../../utils/cookies';
import ChangeDefaultPassword from './ChangeDefaultPassword';
import http from 'utils/http';
// import http from '../../../utils/http';
import auth from '../../../../utils/auth';
import cookies from 'utils/cookies';
// import { withRouter } from 'react-router-dom';
class ChangePasswordContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userFields: {},
      userErrors: {},
      valid: false,
      reset: false,
    };
  }

  componentDidMount() {
    if (auth()) {
      if (cookies.get('password') === 'true')
        this.props.history.push('/');
      else
        this.setState({
          valid: true,
          reset: false
        });
    } else {
      if (this.props.match.params.id && this.props.match.params.token) {
        this.validateToken();
      } else
        this.props.history.push('/login');
    }
  }

  validateToken = async () => {
    await http.post('/user/token', {
      id: this.props.match.params.id,
      token: this.props.match.params.token
    }).then(({ data, status }) => {
      if (status == 200 && data.status == 'success') {
        this.setState({
          valid: true,
          reset: true
        });
      } else {
        setTimeout(() => {
          this.props.history.push('/login');
        }, 3000);
      }
    }).catch(() => {
      setTimeout(() => {
        this.props.history.push('/login');
      }, 3000);
    });
  };

  validation = () => {

    const fields = { ...this.state.userFields };
    const errors = {
      password: false,
      confirm_password: false,
    };
    let formIsValid = true;

    if (!fields.password || (fields.password).trim().length === 0) {
      formIsValid = false;
      errors.password = 'Please enter a password.';
    }

    if (!fields.confirm_password || (fields.confirm_password).trim().length === 0) {
      formIsValid = false;
      errors.confirm_password = 'Please enter a password.';
    }

    if (fields.password != fields.confirm_password) {
      formIsValid = false;
      errors.confirm_password = 'The passwords dont match.';
    }

    this.setState({ userErrors: errors });

    return formIsValid;
  };

  checkPassword = () => {
    let valid = false;
    let fields = {
      passcheck1: true,
      passcheck2: true
    };
    let passwordInputValue = this.state.userFields.password;
    if (passwordInputValue) {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      const passwordLength = passwordInputValue.length;
      const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
      const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
      const digitsPassword = digitsRegExp.test(passwordInputValue);
      const specialCharPassword = specialCharRegExp.test(passwordInputValue);
      const minLengthPassword = minLengthRegExp.test(passwordInputValue);
      let errMsg = '';
      if (passwordLength === 0) {
        fields.passcheck2 = false;
        fields.passcheck1 = false;
        errMsg = 'Password is empty! Password must contain minumum 8 characters with at least one Uppercase, one Lowercase, one digit, one Special Characters  ';
      } else if (!uppercasePassword) {
        fields.passcheck2 = false;
        errMsg = 'At least one Uppercase';
      } else if (!lowercasePassword) {
        fields.passcheck2 = false;
        errMsg = 'At least one Lowercase';
      } else if (!digitsPassword) {
        fields.passcheck2 = false;
        errMsg = 'At least one digit';
      } else if (!specialCharPassword) {
        fields.passcheck2 = false;
        errMsg = 'At least one Special Characters';
      }
      if (!minLengthPassword) {
        fields.passcheck1 = false;
        errMsg = 'At least minumum 8 characters';
      }
      if (errMsg == '') {
        valid = true;
      }
      this.setState({
        userFields: {
          ...this.state.userFields,
          ...fields
        },
        userErrors: { password: errMsg }
      });
    } else {
      this.setState({
        userFields: {
          ...this.state.userFields,
          passcheck1: false,
          passcheck2: false
        },
        userErrors: {}
      });
    }
    return valid;
  };

  onChange = (e) => {
    this.setState({
      userFields: {
        ...this.state.userFields,
        [e.target.name]: e.target.value
      }
    }, () => {
      console.log(this.state.userFields, 'userFields');

      if (e.target.name == 'password') {
        console.log('password');
        this.checkPassword();
      }
    });
  };

  onSubmit = async () => {
    if (this.state.reset) {
      let formdata = {
        ...this.state.userFields,
        id: this.props.match.params.id,
        token: this.props.match.params.token 
      };
      if (this.validation() && this.checkPassword()) {
        await http.post('/user/token_password', formdata).then(({ data, status }) => {
          if (status == 200 && data.status == 'success') {
            this.props.history.push('/login');
          }
        });
      }
    } else {
      let formdata = { ...this.state.userFields, };
      if (this.validation() && this.checkPassword()) {
        await http.post('/user/password', formdata).then(({ data, status }) => {
          if (status == 200 && data.status == 'success') {
            cookies.set('password', true, { path: '/' });
            this.props.history.push('/');
          }
        });
      }
    }
  };

  render() {
    return (
      <ChangeDefaultPassword
        valid={this.state.valid}
        fields={this.state.userFields}
        errors={this.state.userErrors}
        submit={this.state.submit}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default ChangePasswordContainer;
// export default withRouter(ChangeDefaultPasswordContainer);