import React from 'react';
import BulkApproval from './BulkApproval';
import { contractType } from 'custom/constants';
import http from 'utils/http';
import cookies from 'utils/cookies';

class BulkApprovalContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.match.params.tab || 'flight_report',
      contract_Type: null,
      // statType: null,
      cont_item: null,
      userContractTypes: [],
    };
  }
  componentDidMount() {
    this.fetchUser();
  }

  fetchUser = () => {
    http.get(`/user?id=${cookies.get('user')}`).then(({ data }) => {
      if(data && data.data && data.data.length > 0){
        // let statType = null;
        let cont_item = null;
        let contract_Type = null;
        let userContractTypes = data.data[0]?.contract_types || [];
        //get first key and value from contractType
        let firstRecord = Object.entries(contractType).find(([ , value]) => userContractTypes.includes(value));
        if (firstRecord) {
          // statType = 0;
          cont_item = firstRecord[1];
          contract_Type = firstRecord[0];
        }
        this.setState({ 
          userContractTypes : data.data[0]?.contract_types || [],
          // statType: statType,
          cont_item: cont_item,
          contract_Type: contract_Type,
        });
      }
    });
  };

  handleChange = (newValue) => {
    if (this.props.match.url.includes('approvals')) {
      this.props.history.replace(`${newValue}`);
    } else {
      this.props.history.replace(`approvals/${newValue}`);
    }
    this.setState({ value: newValue });
  };

  handleContractType = (e) => {
    let cont_item = contractType[e.target.value];
    this.setState({ 
      contract_Type: e.target.value,
      cont_item: cont_item,
    });
  };

  render() {
    return (
      <>
        <BulkApproval 
          handleChange={this.handleChange} 
          value={this.state.value} 
          handleContractType={this.handleContractType}
          // statType={this.state.statType}
          contract_Type={this.state.contract_Type}
          cont_item={this.state.cont_item}
          userContractTypes={this.state.userContractTypes}
        />
      </>
    );
  }
}

export default BulkApprovalContainer;
