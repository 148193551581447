import React from 'react';
import { Stack, Grid, Radio, Box, Typography } from '@mui/material';
import { StyledDataGrid, StyledCard, StyledCardTitle, DataGridProps, TableBadge } from '../../../../../custom/components';
import checklistStyles from './checklist.module.css';
import styles from '../../../../../custom/style.module.css';
import {  AddIcon, DeleteIcon, EditIcon, LocationIcon, allAirlines  } from '../../../../../custom/constants';
import AddChecklistModal from './AddChecklistModal';
import LocationContainer from 'components/pages/common/location/LocationContainer';

const SUB_TITLE_FS = '10px';
const SUB_TITLE_LH = 1;

const ReportChecklist = (props) => {
  const airlinesCol = [
    {
      field: 'select',
      headerName: '',
      width: 50,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Radio size='small' className={styles.p0}
            checked={props.activeAirline?.id === row?.id}
          />
        );
      }
    },
    {
      field: 'airlines',
      headerName: 'Airline Code',
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <span>{row && row.id === -1 ? 'Common for all airlines' : row.code}</span>
        );
      }
    },
  ];

  const checklistCol = [
    {
      field: 'description',
      headerName: 'Check Description',
      flex: 1,
    },
    {
      field: 'role',
      headerName: 'To be entered by (Employee Group)',
      flex: 1,
    },
    {
      field: 'report_type',
      headerName: 'Inbound/Outbound Flight Report',
      flex: 1,
    },
    {
      field: 'contract_types',
      headerName: 'Department',
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
            <TableBadge color='error' badgeContent={row.stations && row.stations.length}>
              <LocationIcon onClick={(e) => props.handleLocation('airlinechecklist', true, row, e.target)} />
            </TableBadge>
            <EditIcon onClick={() => { props.handleOpen('checklist', 'edit', row); }} />
            <DeleteIcon onClick={(e) => { props.handleDelete('checklist', row.id, e.target); }} />
          </Stack>
        );
      }
    },
  ];

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <StyledCard>
            <Stack className={`${styles.mb3}`}>
              <StyledCardTitle gutterBottom variant='cardTitle' className={styles.pb0}>Airlines</StyledCardTitle>
              <Typography component='span' fontSize={SUB_TITLE_FS} lineHeight={SUB_TITLE_LH}>List of airlines will be automatically populated based on the flight schedule</Typography>
            </Stack>
            <div className={styles.tableWrapper}>
              <Box className={`${styles.tableOuter} ${checklistStyles.airlineTableOuter}`}>
                <StyledDataGrid
                  {...DataGridProps}
                  rows={[allAirlines, ...props.airline]}
                  columns={airlinesCol}
                  onRowClick={({ row }) => {
                    props.fetchChecklist(row);
                  }}
                  param={props.airline?.length + 1}
                />
              </Box>
            </div>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={8} xl={8}>
          <StyledCard>
            <Stack className={`${styles.mb3}`} direction='row' justifyContent='space-between' spacing={1}>
              <StyledCardTitle gutterBottom>Define Flight Report Checklist</StyledCardTitle>
              <AddIcon className={styles.addIcon} title='Add Checklist' onClick={() => props.handleOpen('checklist', 'add')} />
            </Stack>
            <div className={styles.tableWrapper}>
              <Box className={`${styles.tableOuter} ${checklistStyles.productsTableOuter}`}>
                <StyledDataGrid
                  {...DataGridProps}
                  rows={props.checklist}
                  columns={checklistCol}
                  param={props.checklist?.length}
                  initialState={{
                    sorting: {
                      sortModel: [{
                        field: 'contract_types',
                        sort: 'asc' 
                      }], 
                    },
                  }}
                />
              </Box>
            </div>
          </StyledCard>
        </Grid>
      </Grid>

      <LocationContainer
        anchorEl={props.anchorEl}
        open={props.location}
        type={props.locationType}
        handleClose={props.handleLocation}
        stations={props.activeAirline && props.activeAirline.id != '-1' ? props.activeAirline.stations : props.stations}
        data={props.station}
        name={props.station?.description}
      />

      <AddChecklistModal
        modal={props.modal}
        action={props.action}
        onClose={props.onClose}
        airline={props.airline}
        checklist={props.checklist}
        checklistField={props.checklistField}
        checklistErrors={props.checklistErrors}
        addChecklist={props.addChecklist}
        editChecklist={props.editChecklist}
        onChangeChecklist={props.onChangeChecklist}
      /> 
    </div>
  );
};

export default ReportChecklist;
