import React from 'react';
import { connect } from 'react-redux';
import ManifestSummary from './ManifestSummary';
import cookies from 'utils/cookies';
import { dateTolocale } from 'utils/converter';
import { DateTimeFormat } from 'custom/constants';
// import moment from "moment";

class ManifestSummaryContainer extends React.Component {
  constructor(props) {
    super(props);
    this.stn = cookies.get('station_code');
    this.state = {
      product: 'ALL',
      productsList: [],
      manifestSummary: {
        loose: {
          awbs: 0,
          pcs: 0,
          wgt: 0,
        },
        intact: {
          awbs: 0,
          pcs: 0,
          wgt: 0,
        },
        total: {
          awbs: 0,
          pcs: 0,
          wgt: 0,
        },
        looseulds: {
          LD3: 0,
          LD7: 0,
          MD: 0,
        },
        intactulds: {
          LD3: 0,
          LD7: 0,
          MD: 0,
        },
        totalulds: {
          LD3: 0,
          LD7: 0,
          MD: 0,
        },
      },
      showManifest: false,
    };
  }

  componentDidMount() {
    this.processSummary();
    this.get_products();
    if(this.props.showReportModal) {
      this.setState({ showManifest: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.ffm !== this.props.ffm) {
      this.processSummary();
      this.get_products();
    }
  }

  get_products = () => {
    let productList = [];
    this.props.ffm?.flight_uld?.map((item) => {
      item?.flight_awbs?.map((awb) => {
        if (awb.product?.name && !productList.includes(awb.product.name)) {
          productList.push(awb.product.name);
        }
      });
    });
    this.setState({ productsList: productList });
  };

  handleProductChange = (event) => {
    this.setState({ product: event.target.value }, () => {
      this.props.handleFilter && this.props.handleFilter(this.state.product);
      this.processSummary();
    });
  };

  processSummary = () => {
    const ffmNoteElement = document.getElementById('ffm-note');
    if (!ffmNoteElement) {
      console.error('Element with ID "ffm-note" not found.');
      return;
    }
    if (this.props.ffm) {
      if (this.props.ffm?.org == this.stn) {
        if(this.props.ffm.out_ffm){
          ffmNoteElement.innerHTML = `Uploaded on ${dateTolocale(this.props.ffm.out_ffm.uploaded_date, DateTimeFormat)}`;
        } else {
          ffmNoteElement.innerHTML = '';
        }
      } else if (this.props.ffm?.dest == this.stn) {
        if(this.props.ffm.in_ffm){
          ffmNoteElement.innerHTML = `Uploaded on ${dateTolocale(this.props.ffm.in_ffm.uploaded_date, DateTimeFormat)}`;
        } else {
          ffmNoteElement.innerHTML = '';
        }
      } else {
        ffmNoteElement.innerHTML = '';
      }
    } else {
      ffmNoteElement.innerHTML = '';
    }
    let manifestData = this.props.ffm?.flight_uld;
    let loose = {
      awbs: 0,
      pcs: 0,
      wgt: 0,
    };
    let intact = {
      awbs: 0,
      pcs: 0,
      wgt: 0,
    };
    let total = {
      awbs: 0,
      pcs: 0,
      wgt: 0,
    };
    let looseulds = {
      LD3: 0,
      LD7: 0,
      MD: 0,
    };
    let intactulds = {
      LD3: 0,
      LD7: 0,
      MD: 0,
    };
    let totalulds = {
      LD3: 0,
      LD7: 0,
      MD: 0,
    };
    let totalAwbLoose = [];
    let totalAwbIntact = [];
    let totalAwbNo = [];
    manifestData?.map((item) => {
      let searchString = ['LD3', 'LD7', 'MD'];
      let uldtype = searchString.find(val => item.uld_type?.includes(val));
      let inc = 1;
      if (item.uld_type?.includes('2X')) {
        inc = 2;
      }
      if (item.type === 'Loose') {
        looseulds[uldtype] += inc;
      }
      if (item.type === 'Intact') {
        intactulds[uldtype] += inc;
      }
      totalulds[uldtype] += inc;
      item?.flight_awbs?.map((awb) => {
        if (this.state.product == 'ALL' || awb.product?.name == this.state.product) {
          if (item.type === 'Loose') {
            if(totalAwbLoose && !totalAwbLoose.includes(awb.awb_no)) {
              totalAwbLoose.push(awb.awb_no);
            }
            loose.awbs = totalAwbLoose.length;
            loose.pcs += parseInt(awb.pcs || 0);
            loose.wgt += parseFloat(awb.wgt || 0);
          }
          if (item.type === 'Intact') {
            if(totalAwbIntact && !totalAwbIntact.includes(awb.awb_no)) {
              totalAwbIntact.push(awb.awb_no);
            }
            intact.awbs = totalAwbIntact.length;
            intact.pcs += parseInt(awb.pcs || 0);
            intact.wgt += parseFloat(awb.wgt || 0);
          }
          if(totalAwbNo && !totalAwbNo.includes(awb.awb_no)) {
            totalAwbNo.push(awb.awb_no);
          }
          total.awbs = totalAwbNo.length;
          total.pcs += parseInt(awb.pcs || 0);
          total.wgt += parseFloat(awb.wgt || 0);
        }
      });
    });
    this.setState({
      manifestSummary: {
        loose,
        intact,
        total,
        looseulds,
        intactulds,
        totalulds,
      },
    }, () => {
      if(this.props.printFunction) {
        this.props.printFunction(this.state.manifestSummary, 'manifestSummary');
      }
    });
  };

  changeCheck = (e) => {
    this.setState({ showManifest: e.target.checked }, console.log(this.state.showManifest));
  };

  render() {
    return (
      <div>
        <ManifestSummary
          manifestSummary={this.state.manifestSummary}
          productsList={this.state.productsList}
          product={this.state.product}
          handleProductChange={this.handleProductChange}
          showManifest={this.state.showManifest}
          changeCheck={this.changeCheck}
          showReportModal={this.props.showReportModal}
          nil={this.props.nil}
        />
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  return { ffm: state.ffm };
};

export default connect(mapStateToProps)(ManifestSummaryContainer);