import React from 'react';
import PrintPassword from './PrintPassword';

class PrintPasswordContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { userdetails: '' };
    this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
  }

  componentDidMount() {
    const storedData = localStorage.getItem('user_details');
    const details = JSON.parse(storedData);
    this.setState({ userdetails: details });
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  handleBeforeUnload(event) {
    event.preventDefault();
    localStorage.removeItem('user_details');
  }

  render() {
    return (
      <>
        <PrintPassword
          userdetails={this.state.userdetails}
        />
      </>
    );
  }
}

export default PrintPasswordContainer;
