import React from 'react';
import ServiceLog from './ServiceLog';
import http from 'utils/http';
import moment from 'moment';
import cookies from 'utils/cookies';

const contractTypes = cookies.get('contract_types') && cookies.get('contract_types') !== 'null' && cookies.get('contract_types').length>0 ? cookies.get('contract_types').sort() : [];
const Role = cookies.get('role');

class ServiceLogContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      contract_Type: contractTypes[0] || null,
      airlines: [],
      airline: null,
      airlineCode: null,
      role: Role === 'Airline Rep' ? 'air_rep' :'accountant',
      logType: 'DAY',
      search: {
        from: moment().startOf('month').format('YYYY-MM-DD'), 
        to: moment().endOf('month').format('YYYY-MM-DD'),
      },
      filter: { showCompleted: false },
      anchorEl: null,
      ids: [],
      modalslog: null,
      selectedOption: null,
      remark: '',
      records: [],
      logColumnHead: [],
    };
  }

  componentDidMount() {
    if(this.props.cont_item){
      //call other functions after getFilters finishes
      Promise.resolve(this.getFilters()).then(() => { this.fetchAirlines(); });
    }
  }

  fetchAirlines = () => {
    http.get(`/airline?active=${true}`)
      .then((response) => {
        if(this.state.airline && this.state.airlineCode){
          this.setState({ airlines: response.data.data }, () => { this.getList(); });
        } else {
          this.setState({
            airlines: response.data.data,
            airline: response.data.data[0].id,
            airlineCode: response.data.data[0].code
          }, () => { 
            this.storeFilters();
            this.getList(); 
          });
        }
      });
  };

  getList = () => {
    http.get(`/approval/service_log?from=${this.state.search.from}&to=${this.state.search.to}&contract=${this.state.contract_Type}`).then((response) => {
      this.setState({ 
        records: response.data.data,
        list: []
      },()=>{ this.filterData(); });
    });
  };

  filterData = () =>{
    let data = this.state.records;
    let logColumnHead = [];
    //filter by airline
    let filteredData = data.filter((item) => {
      return item.airlineId  === this.state.airline;
    });
    //filter by role 
    filteredData = filteredData.filter((item) => {
      if(this.state.role === 'accountant'){
        return ((item.status === 'Finalized' && item.approved_acc === false) || (item.status === 'Rejected' && !item.approved_acc === false && item.approved_air === false));
      } else if(this.state.role === 'air_rep'){
        return (item.status === 'Pending' && item.approved_air === false);
      }
    });
    //filter by logType
    filteredData = filteredData.filter((item) => {
      return item.type === this.state.logType;
    });
    // sort by date
    filteredData.sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });
    // get column names
    filteredData.forEach((item) => {
      if(item.data && item.data.length > 0){
        item?.data && item?.data?.forEach((d) => {
          if(!logColumnHead.includes(d.label)) {
            logColumnHead.push(d.label);
          }
        });
      }
    });
    this.setState({ 
      list: filteredData,
      logColumnHead,
      ids: []
    });
  };

  UpdateServiceLog = () => {
    let ids = [];
    let option = this.state.selectedOption;
    if(this.state.modalslog){
      ids.push(this.state.modalslog.id);
    } else {
      // get all selected ids
      ids = this.state.ids;
    }
    let data = {
      ids: ids,
      status: option === 'comment' ? 'Comment' : option === 'ok' ? 'Approved' : 'Rejected',
      remark: this.state.remark,
    };
    http.put('/service_log', data).then((response) => {
      console.log('UpdateServiceLog',response);
      this.getList();
      this.handleReviewClose();
    });
  };

  selectAll = (flag) => {
    if(flag){
      let ids = this.state.list.map((item) => item.id);
      this.setState({ ids: ids });
    } else {
      this.setState({ ids: [] });
    }
  };
   
  selectServiceLog = (id, flag)=>{
    console.log('selectServiceLog', id, flag);
    if(flag){
      this.setState({ ids: [...this.state.ids, id] });
    } else {
      let filtered = this.state.ids.filter((item) => item !== id);
      this.setState({ ids: filtered });
    }
  };

  selectOption = (value) => {
    this.setState({ selectedOption: value });
  };

  setRemark = (value) => {
    this.setState({ remark: value });
  };

  onChangeSearch = (e) => {
    if(e.target.name === 'month'){
      let from = moment(e.target.value).startOf('month').format('YYYY-MM-DD');
      let to = moment(e.target.value).endOf('month').format('YYYY-MM-DD');
      this.setState({
        search: {
          ...this.state.search,
          from: from,
          to: to
        }
      },()=>{
        this.storeFilters();
        this.getList();
      });
    } else {
      this.setState({
        search: {
          ...this.state.search,
          [e.target.name]: e.target.value
        }
      },()=>{
        this.storeFilters();
        this.getList();
      });
    }
  };


  filterList = () => {
    let list = this.state.list;
    return list;
  };

  handleContractType = (e) => {
    this.setState({ contract_Type: e.target.value, },()=>{
      this.storeFilters();
      this.getList();
    });
  };
  
  handleAirlineChange = (e) => {
    let value = e.target.value;
    let airline = this.state.airlines.find(airline => airline.id === value).id;
    let airlineCode = this.state.airlines.find(airline => airline.id === value).code;
    this.setState({
      airline: airline,
      airlineCode: airlineCode,
    },()=>{
      this.storeFilters();
      this.filterData();
    });
  };
  handleRoleChange = (e) => {
    this.setState({ role: e.target.value },()=>{
      this.storeFilters();
      this.filterData(); 
    });
  };
  handleLogTypeChange = (e) => {
    this.setState({ logType: e.target.value },()=>{ this.filterData(); });
  };

  handleReview = (anchorEl, serviceLog) => {
    console.log('handleReview', serviceLog);
    this.setState({ 
      anchorEl,
      modalslog: serviceLog
    });
  };

  handleReviewClose = () => {
    this.setState({ 
      anchorEl: null,
      modalslog: null,
      selectedOption: null,
      remark: '',
    });
  };

  storeFilters = () => {
    console.log('storeFilters');
    let filters = {
      search: this.state.search,
      contract_Type: this.state.contract_Type,
      airline: this.state.airline,
      airlineCode: this.state.airlineCode,
      role: this.state.role,
    };
    // set expiry got 1 day.
    cookies.set('approvals', filters, { 
      path: '/', 
      maxAge: 86400 
    });
  };
  getFilters = () => {
    console.log('getFilters');
    let filters = cookies.get('approvals');
    if (filters) {
      if(filters.search){
        this.setState({ search: filters.search });
      }
      if(filters.contract_Type){
        this.setState({ contract_Type: filters.contract_Type });
      }
      if(filters.airline){
        this.setState({ airline: filters.airline });
      }
      if(filters.airlineCode){
        this.setState({ airlineCode: filters.airlineCode });
      }
      if(filters.role){
        this.setState({ role: filters.role });
      }
    }
  };

  render() {
    return (
      <>  
        <ServiceLog
          list={this.state.list}
          contract_Type= {this.state.contract_Type}
          airlines= {this.state.airlines}
          airline= {this.state.airline}
          airlineCode= {this.state.airlineCode}
          search={this.state.search} 
          filter={this.state.filter}
          role={this.state.role}
          ids={this.state.ids}
          logType={this.state.logType}
          handleLogTypeChange={this.handleLogTypeChange}
          modalslog={this.state.modalslog}
          selectedOption={this.state.selectedOption}
          remark={this.state.remark}
          records={this.state.records}
          selectServiceLog={this.selectServiceLog}
          selectOption={this.selectOption}
          setRemark={this.setRemark}
          filterList={this.filterList}
          handleRoleChange={this.handleRoleChange}
          handleReviewClose={this.handleReviewClose}
          onChangeSearch={this.onChangeSearch} 
          onChangeFilter={this.onChangeFilter} 
          anchorEl={this.state.anchorEl}
          handleReview={this.handleReview}
          handleAirlineChange={this.handleAirlineChange}
          UpdateServiceLog={this.UpdateServiceLog}
          selectAll={this.selectAll}
          handleContractType={this.handleContractType}
          logColumnHead={this.state.logColumnHead}
          
        />
      </>
    );
  }
}

export default ServiceLogContainer;


