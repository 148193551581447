import React, { Fragment } from 'react';
import {
  Dialog,
  Box,
  Tooltip,
  Grid,
  IconButton,
} from '@mui/material';
import styles from '../../../../../custom/style.module.css';
import userStyles from './users.module.css';
import { CustomDialogTitle, CustomDialogContent, StyledDataGrid, DataGridProps, StyledCard, CustomInputLabel, CustomTextField, InputProps } from '../../../../../custom/components';
import { CloseIconBtn } from '../../../../../custom/constants';
import cookies from 'utils/cookies';
import { Search } from '@mui/icons-material';


const UserModal = (props) => {

  const columns = [
    {
      field: 'first_name',
      headerName: 'First Name',
      minWidth: 100,
      flex: 0.8,
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      minWidth: 100,
      flex: 0.8,
    },
    {
      field: 'username',
      headerName: 'Email / User Name',
      type: 'email',
      minWidth: 150,
      flex: 1,

    },
    {
      field: 'role',
      headerName: 'Role',
      minWidth: 120,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Fragment>
            {row.role + ' '}{<div>&nbsp; &nbsp;</div>}
            <Tooltip title={row.airlines && row.airlines[cookies.get('station_code')]?.join(', ')}>
              <div>
                {row.airlines &&
                  (row.airlines[cookies.get('station_code')]?.map((airlines, index) => (
                    <Fragment key={index}>
                      <span>{airlines}</span>
                    </Fragment>
                  )))}
              </div>
            </Tooltip>
          </Fragment>
        );
      }
    },
    {
      field: 'contract_types',
      headerName: 'Department',
      minWidth: 120,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Fragment>
            <Tooltip title={row.contract_types?.join(', ')}>
              <div>
                {row.contract_types &&
                  (row.contract_types?.map((contract_type, index) => (
                    <Fragment key={index}>
                      <span className={userStyles.airlines}>{contract_type}</span>
                    </Fragment>
                  )))}
              </div>
            </Tooltip>
          </Fragment>
        );
      }
    },
  ];

  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={props.modal == 'other_user'} onClose={()=>props.handleOpen('user', 'add', {})} maxWidth={'lg'} fullWidth>
        <CustomDialogTitle id='form-dialog-title' className={styles.modalTitle}>
          Trypod User
          <CloseIconBtn onClick={()=>props.handleOpen('user', 'add', {})} />
        </CustomDialogTitle>
        <CustomDialogContent>
          <StyledCard>
            <Grid container spacing={2.6}>
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12} sm={5} md={4}>
                  <CustomInputLabel htmlFor='first_name'>First Name</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='first_name'
                    fullWidth
                    autoFocus
                    placeholder='First Name'
                    type='text'
                    name='first_name'
                    value={props.tryUserFields.first_name}
                    onChange={props.onChangeTry}
                  />
                </Grid>
                <Grid item xs={12} sm={5} md={4}>
                  <CustomInputLabel htmlFor='last_name'>Last Name</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='last_name'
                    fullWidth
                    placeholder='Last Name'
                    type='text'
                    name='last_name'
                    value={props.tryUserFields.last_name}
                    onChange={props.onChangeTry}
                  />
                </Grid>
                <Grid item className={styles.sqIconsContainer}>
                  <CustomInputLabel className={styles.colorTransparent}>-</CustomInputLabel>
                  <IconButton disableRipple onClick={props.onSearchTry} className={styles.p0}>
                    <Search fontSize='1.7rem' classes={{ root: styles.sqIcons }} />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div className={styles.tableWrapper}>
                  <Box className={`${styles.tableOuter} ${userStyles.trypodUsers}`}>
                    <StyledDataGrid
                      {...DataGridProps}
                      rows={props.otherUserList}
                      getRowId={(row) => row.appId }
                      columns={columns}
                      onRowClick={(row) => props.handleOpen('user', 'edit', row.row)}
                      param={props.otherUserList?.length}
                    />
                  </Box>
                </div>
              </Grid>
            </Grid>
          </StyledCard>
        </CustomDialogContent>
      </Dialog>
    </div>
  );
};
export default UserModal;