import React from 'react';
import { Box, Typography, TextField, Stack, InputAdornment } from '@mui/material';
import Logo from '../../../../assets/images/orgaLogoFull.png';
import { CustomButton } from '../../../../custom/components';
import loginStyles from './../login.module.css';
import styles from '../../../../custom/style.module.css';
import { Check, Clear, Lock } from '@mui/icons-material';
import theme from 'custom/theme';
import { appName } from 'custom/constants';
import { loaderRef } from 'components/header/loader';

const ChangeDefaultPassword = (props) => {
  return (
    <Box className={loginStyles.loginWrapper}>
      <div ref={loaderRef}></div>
      <img src={Logo} alt='logo' className={`${loginStyles.loginLogo} ${styles.mb3}`} />
      <Stack spacing={3} alignItems='center' className={`${styles.w100} ${styles.mb4}`}>
        <Typography fontSize={'1.2rem'} component='span' lineHeight='normal' fontWeight={500}
          className={`${loginStyles.appName} ${loginStyles.loginHeader} ${styles.mb3}`} color='primary'>
          Welcome to {appName}
        </Typography>
        <Typography variant='body1' component='span' lineHeight='normal' fontWeight={'bold'} letterSpacing={'0.4px'}
          className={`${loginStyles.loginHeader} ${styles.mb3}`} color={theme.palette.grey[700]}>
          Set your new password
        </Typography>
      </Stack>

      {props.valid && <form className={styles.w100}>
        <Stack spacing={5} alignItems='center'>
          <TextField
            hiddenLabel
            id='password'
            variant='standard'
            className={styles.w100}
            placeholder='Password'
            type='password'
            size='small'
            InputLabelProps={{ shrink: false }}
            classes={{ root: loginStyles.textfield }}
            onChange={props.onChange}
            name='password'
            value={props.fields.password}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Lock color='primary' className={loginStyles.materialIcon} />
                </InputAdornment>
              ),
            }}
            error={props.errors.password}
            helperText={props.errors.password}
          />
          <TextField
            hiddenLabel
            id='confirm_password'
            variant='standard'
            className={styles.w100}
            placeholder='Confirm Password'
            type='password'
            size='small'
            InputLabelProps={{ shrink: false }}
            classes={{ root: loginStyles.textfield }}
            onChange={props.onChange}
            name='confirm_password'
            value={props.fields.confirm_password}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Lock color='primary' className={loginStyles.materialIcon} />
                </InputAdornment>
              ),
            }}
            error={props.errors.confirm_password}
            helperText={props.errors.confirm_password}
          />
          {/* <TextField
            hiddenLabel
            id='phone'
            variant='standard'
            className={styles.w100}
            placeholder='Phone number (Optional)'
            type='number'
            size='small'
            InputLabelProps={{ shrink: false }}
            classes={{ root: loginStyles.textfield }}
            // onChange={props.onChange}
            // name='password'
            // value={props.fields.password}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Phone color='primary' className={ loginStyles.materialIcon } />
                </InputAdornment>
              ),
            }}
            // error={props.errors.password}
            // helperText={props.errors.password}
          /> */}
          <CustomButton variant='contained' className={`${styles.w100} ${loginStyles.submitBtn}`} onClick={props.onSubmit} disabled={props.submit} >Submit</CustomButton>
          <div className={loginStyles.passwordPolicyContainer}>
            <div className={loginStyles.passwordPolicyHeader}>
              Password Policy:
            </div>
            <div className={loginStyles.passwordPolicyConditions}>
              {props.fields.passcheck1 ?
                <Check style={{
                  color: 'green',
                  fontSize: '14px'
                }} /> :
                <Clear style={{
                  color: 'red',
                  fontSize: '14px'
                }} />
              }
              <span className={loginStyles.greyText}>Must be at least 8 characters</span>
            </div>
            <div className={loginStyles.passwordPolicyConditions}>
              {props.fields.passcheck2 ?
                <Check style={{
                  color: 'green',
                  fontSize: '14px'
                }} />
                :
                <Clear style={{
                  color: 'red',
                  fontSize: '14px'
                }} />}
              <span className={loginStyles.greyText}>Must contain one upper case letter, one number and a special character</span>
            </div>
          </div>
        </Stack>
      </form>}
      {!props.valid && <Typography variant='body1' component='span' lineHeight='normal' fontWeight={'bold'} letterSpacing={'0.4px'}
        className={`${loginStyles.loginHeader} ${styles.mb3}`} color={theme.palette.grey[700]}>
          Verifying your account...
      </Typography>}
    </Box>
  );
};

export default ChangeDefaultPassword;
