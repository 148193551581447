import React, { Fragment } from 'react';
import { Dialog, FormControl, FormHelperText, MenuItem, Checkbox, RadioGroup, Radio, Stack, Grid, Popover, Typography, Box } from '@mui/material';
import styles from '../../../../../custom/style.module.css';
import contractStyles from './contracts.module.css';
import { CustomButton, CustomTextField, CustomDialogTitle, CustomDialogAction, CustomInputLabel, StyledCard, InputProps, CustomSelect, CustomCheckbox, CustomRadio, CustomDialogContent, SelectInputProps } from '../../../../../custom/components';
import { CloseIconBtn, appliedOnItems, roles, platforms } from 'custom/constants';
import { InfoOutlined } from '@mui/icons-material';

const AddContractItem = (props) => {
  const { action } = props;
  const LABEL_PROPS = {
    direction: {
      xs: 'column',
      sm: 'row' 
    },
    alignItems: {
      xs: 'flex-start',
      sm: 'center' 
    }
  };

  const NUMBERINPUT_PROPS = {
    '&[type=number]': { '-moz-appearance': 'textfield', },
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': { display: 'none', },
    '& input[type=number]': { MozAppearance: 'textfield', },
  };

  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={props.modal === 'contract_item'} onClose={props.onClose} maxWidth={'lg'} fullWidth>
        <CustomDialogTitle id='form-dialog-title'>
          {action == 'add' ? 'Add' : 'Edit'} Contract Line Item
          <CloseIconBtn onClick={props.onClose} />
        </CustomDialogTitle>
        <form className={styles.w100}>
          <CustomDialogContent>
            <StyledCard>
              <Grid container spacing={{
                xs: 1,
                sm: 3,
              }}>
                <Grid item xs={12} sm={12}>
                  <Stack {...LABEL_PROPS}>
                    <CustomInputLabel htmlFor='description' required className={contractStyles.labelInline}>Item Description</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      fullWidth
                      autoFocus
                      id='description'
                      placeholder='Description'
                      type='text'
                      name='description'
                      value={props.controwField.description}
                      onChange={props.onChangecontrowField}
                      error={props.controwErrors.description}
                      helperText={props.controwErrors.description}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack {...LABEL_PROPS}>
                    <CustomInputLabel id='applied_on_label' required className={contractStyles.labelInline}>Applied On</CustomInputLabel>
                    <FormControl variant='standard' fullWidth error={props.controwErrors.applied_on}>
                      <CustomSelect
                        {...SelectInputProps}
                        id='applied_on'
                        labelId='applied_on_label'
                        fullWidth
                        name='applied_on'
                        value={!props.controwField.applied_on ? 'placeholder' : props.controwField.applied_on}
                        onChange={props.onChangecontrowField}
                        classes={{ select: !props.controwField.applied_on ? styles.colorPlaceholder : '' }}
                      >
                        <MenuItem value='placeholder' disabled>Select Applied On</MenuItem>
                        {appliedOnItems.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                      <FormHelperText >{props.controwErrors.applied_on}</FormHelperText>
                    </FormControl>
                    <InfoOutlined color='inherit' fontSize='small' sx={{
                      opacity: 0.4,
                      marginTop: '4px',
                      marginBottom: 'auto' 
                    }}  onClick={(e) => props.handlePopover(true, e.target, null, null)} className={`${styles.ml2} ${styles.pointer}`} />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack {...LABEL_PROPS}>
                    <CustomCheckbox
                      disabled={props.controwField.applied_on ? (appliedOnItems.find(item => item.value === props.controwField.applied_on))?.disable_product : true}
                      control={
                        <Checkbox checked={props.controwField.is_product} onChange={props.onChangecontrowField} name='is_product' size='small' />
                      }
                      className={styles.spaceNowrap}
                      label='Product Specific'
                    />
                    <FormControl variant='standard' fullWidth>
                      <CustomSelect
                        {...SelectInputProps}
                        id='productId'
                        labelId='product-label'
                        fullWidth
                        name='productId'
                        value={!props.controwField.productId ? 'placeholder' : props.controwField.productId}
                        disabled={props.controwField.is_product ? false : true}
                        onChange={props.onChangecontrowField}
                        classes={{ select: !props.controwField.productId ? styles.colorPlaceholder : '' }}
                      >
                        <MenuItem value='placeholder' disabled >Select Product</MenuItem>
                        {props.products.map((product, i) => (
                          <MenuItem key={i} value={product.id}>
                            {product.name}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Stack {...LABEL_PROPS}>
                    <CustomInputLabel htmlFor='rate_type' required className={contractStyles.labelInline}>Rate Type</CustomInputLabel>
                    <RadioGroup aria-labelledby='' name='rate_type' id='rate_type' value={props.controwField.rate_type} onChange={props.onChangecontrowField} row >
                      <CustomRadio control={<Radio size='small' />} value='stable' label='Stable' className={styles.mr5} />
                      {/* {(props.controwField.applied_on === 'Units (Pcs) | Manual' || (props.controwField.applied_on === 'Units (Hourly) | Manual')) ? '' :  */}
                      { props.controwField.rate_type_options !== 'All' ? '' : 
                        <>
                          <CustomRadio control={<Radio size='small' />} value='progressive' label='Tiered - Progressive' className={styles.mr5} />
                          <CustomRadio control={<Radio size='small' />} value='absolute' label='Tiered - Absolute' />
                        </>}
                    </RadioGroup>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Stack {...LABEL_PROPS}>
                    <CustomInputLabel htmlFor='rate' required className={contractStyles.labelInline}>Rate</CustomInputLabel>
                    { 
                      props.controwField.rate_type == 'stable' ?
                        <Grid container rowSpacing={2} columnSpacing={2}>
                          <Grid item xs={12} sm={12} md={4.3}>
                            <Stack direction='row' alignItems='center' spacing={1}>
                              <CustomTextField
                                {...InputProps}
                                fullWidth
                                id='rate'
                                placeholder='Stable Rate'
                                type='text'
                                name='rate'
                                value={props.contrateField.rate}
                                onChange={props.onChangecontrateField}
                                error={props.contrateErrors.rate}
                                helperText={props.contrateErrors.rate}
                              />
                            </Stack>
                          </Grid>
                        </Grid>
                        :
                        <Grid container rowSpacing={2} columnSpacing={2}>
                          <Grid item xs={12} sm={12} md={4}>
                            <CustomInputLabel  htmlFor='level1 rate1' aria-label='Enter Tier 1' className={`${contractStyles.labelInline} ${styles.mt0}`}>Tier1</CustomInputLabel>
                            <FormControl variant='standard' fullWidth error={props.contrateErrors.level1 || props.contrateErrors.rate1}>
                              <Stack direction={'row'} alignItems='center' columnGap={1} rowGap={1} flexWrap={'wrap'}>
                                <CustomTextField
                                  {...InputProps}
                                  id='level1low'                         
                                  placeholder='Tier1'
                                  type='number'
                                  name='level1low'
                                  value= {props.contrateField.level1low}
                                  disabled
                                  sx={NUMBERINPUT_PROPS}
                                  className={contractStyles.rateInput}
                                />
                                <CustomTextField
                                  {...InputProps}
                                  id='level1'                         
                                  placeholder='Tier Limit'
                                  type='number'
                                  name='level1'
                                  value={props.contrateField.level1}
                                  onChange={props.onChangecontrateField}
                                  sx={NUMBERINPUT_PROPS}
                                  className={contractStyles.rateInput}
                                />
                                <CustomTextField
                                  {...InputProps}
                                  id='rate1'                         
                                  placeholder='Rate'
                                  type='number'
                                  name='rate1'
                                  value={props.contrateField.rate1}
                                  onChange={props.onChangecontrateField}
                                  disabled={!props.contrateField.level1 || isNaN(props.contrateField.level1) ? true : false}
                                  sx={NUMBERINPUT_PROPS}
                                  className={contractStyles.rateInput}
                                />
                              </Stack>
                              <FormHelperText>{props.contrateErrors.level1 || props.contrateErrors.rate1}</FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} md={4}>
                            <CustomInputLabel htmlFor='level2 rate2' aria-label='Enter Tier2' className={`${contractStyles.labelInline} ${styles.mt0}`}>Tier2</CustomInputLabel>                          
                            <FormControl variant='standard' fullWidth error={props.contrateErrors.level2 || props.contrateErrors.rate2}>
                              <Stack direction={'row'} alignItems='center' columnGap={1} rowGap={1} flexWrap={'wrap'}>
                                <CustomTextField
                                  {...InputProps}
                                  id='level2low'                         
                                  placeholder='Tier2'
                                  type='number'
                                  name='level2low'
                                  value={props.contrateField.level2low}
                                  disabled
                                  sx={NUMBERINPUT_PROPS}
                                  className={contractStyles.rateInput}
                                />
                                <Box className={`${contractStyles.rateInput} ${styles.fColumn}`} sx={{ marginTop: '-20px' }}>
                                  <CustomCheckbox
                                    className={contractStyles.noLimitWrapper}
                                    control={
                                      <Checkbox inputProps={{ 'aria-label': 'no limit', }} disableRipple className={`${styles.py0} ${styles.pr1}`} 
                                        checked={props.contrateField.nolimit2} 
                                        onChange={() => {
                                          props.onChangecontrateField({
                                            target: {
                                              name: 'nolimit2',
                                              value: !props.contrateField.nolimit2 
                                            }
                                          });
                                        }} 
                                        name='nolimit2'
                                      />
                                    }
                                    label='No Limit'
                                  />
                                  <CustomTextField
                                    {...InputProps}
                                    id='level2'                         
                                    placeholder='Tier Limit'
                                    type='number'
                                    name='level2'
                                    value={props.contrateField.level2}
                                    onChange={props.onChangecontrateField}
                                    sx={NUMBERINPUT_PROPS}
                                    disabled={props.contrateField.nolimit2}
                                  />
                                </Box>
                                <CustomTextField
                                  {...InputProps}
                                  id='rate2'                         
                                  placeholder='Rate'
                                  type='number'
                                  name='rate2'
                                  value={props.contrateField.rate2}
                                  onChange={props.onChangecontrateField}
                                  disabled={props.contrateField.nolimit2 ? false : !props.contrateField.level2 || isNaN(props.contrateField.level2) ? true : false}
                                  sx={NUMBERINPUT_PROPS}
                                  className={contractStyles.rateInput}
                                />
                              </Stack>
                              <FormHelperText>{props.contrateErrors.level2 || props.contrateErrors.rate2}</FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} md={4}>
                            <CustomInputLabel  htmlFor='level3 rate3' aria-label='Enter Tier3' className={`${contractStyles.labelInline} ${styles.mt0}`}>Tier3</CustomInputLabel>
                            <FormControl variant='standard' fullWidth error={props.contrateErrors.level3 || props.contrateErrors.rate3}>
                              <Stack direction={'row'} alignItems='center' columnGap={1} rowGap={1} flexWrap={'wrap'}>
                                <CustomTextField
                                  {...InputProps}
                                  fullWidth
                                  id='level3low'                         
                                  placeholder='Tier3'
                                  type='number'
                                  name='level3low'
                                  value={props.contrateField.level3low}
                                  disabled
                                  sx={NUMBERINPUT_PROPS}
                                  className={contractStyles.rateInput}
                                />
                                <Box className={`${contractStyles.rateInput} ${styles.fColumn}`} sx={{ marginTop: '-20px' }}>
                                  <CustomCheckbox
                                    className={contractStyles.noLimitWrapper}
                                    control={
                                      <Checkbox inputProps={{ 'aria-label': 'no limit', }} disableRipple className={`${styles.py0} ${styles.pr1}`} 
                                        checked={props.contrateField.nolimit3} 
                                        onChange={() => props.onChangecontrateField({
                                          target: {
                                            name: 'nolimit3',
                                            value: !props.contrateField.nolimit3 
                                          }
                                        })} 
                                        name='nolimit3'
                                        disabled={props.contrateField.nolimit2 || props.contrateField.level3low === '-'}
                                      />
                                    }
                                    label='No Limit'
                                  />
                                  <CustomTextField
                                    {...InputProps}
                                    fullWidth
                                    id='level3'                         
                                    placeholder='Tier Limit'
                                    type='number'
                                    name='level3'
                                    value={props.contrateField.level3}
                                    onChange={props.onChangecontrateField}
                                    sx={NUMBERINPUT_PROPS}
                                    disabled={props.contrateField.nolimit3 || props.contrateField.nolimit2 || props.contrateField.level3low === '-' }
                                  />
                                </Box>
                                <CustomTextField
                                  {...InputProps}
                                  fullWidth
                                  id='rate3'                         
                                  placeholder='Rate'
                                  type='number'
                                  name='rate3'
                                  value={props.contrateField.rate3}
                                  onChange={props.onChangecontrateField}
                                  disabled={props.contrateField.nolimit3 ? false : props.contrateField.nolimit2 || !props.contrateField.level3 || isNaN(props.contrateField.level3) ? true : false}
                                  sx={NUMBERINPUT_PROPS}
                                  className={contractStyles.rateInput}
                                />
                              </Stack>
                              <FormHelperText>{props.contrateErrors.level3 || props.contrateErrors.rate3}</FormHelperText>
                            </FormControl>
                          </Grid>
                        </Grid>
                    }
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack {...LABEL_PROPS}>
                    <CustomInputLabel htmlFor='minimum' aria-label='Minimum' className={contractStyles.labelInline}>Minimum</CustomInputLabel>
                    <>
                      <CustomCheckbox
                        control={
                          <Checkbox inputProps={{ 'aria-label': 'minimum', }}  checked={props.controwField.is_minimum} onChange={props.onChangecontrowField} name='is_minimum' size='small' />
                        }
                        label=''
                        classes={{ root: styles.mr2 }}
                      />
                      <CustomTextField
                        {...InputProps}
                        fullWidth
                        id='minimum'
                        placeholder='Minimum'
                        type='text'
                        InputProps={{
                          ...InputProps.InputProps,
                          classes: { root: contractStyles.minIp } 
                        }}
                        sx={{
                          minWidth: '51.5%',
                          maxWidth: '100%',
                          width: 'auto' 
                        }}
                        name='minimum'
                        value={props.controwField.minimum}
                        onChange={props.onChangecontrowField}
                        disabled={props.controwField.is_minimum ? false : true}
                      // error={}
                      // helperText={}
                      />
                    </>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack {...LABEL_PROPS}>
                    <CustomInputLabel id='role_label' required className={contractStyles.labelInline}>To be entered by (Employee Group)</CustomInputLabel>
                    <FormControl variant='standard' fullWidth error={props.controwErrors.roles}>
                      <CustomSelect
                        {...SelectInputProps}
                        id='role'
                        labelId='role_label'
                        fullWidth
                        name='roles'
                        disabled={!props.controwField.applied_on ? true : props.controwField?.applied_on?.includes('Auto') ? true: false}
                        value={!props.controwField.roles? 'placeholder' : props.controwField.roles[0] || props.controwField.roles}
                        onChange={props.onChangecontrowField}
                        classes={{ select: !props.controwField.roles ? styles.colorPlaceholder : '' }}
                      >
                        <MenuItem value='placeholder' disabled>Select Role</MenuItem>
                        <MenuItem value={'N/A'}>N/A</MenuItem>
                        { roles.map((role) => (
                          <MenuItem key={role.id} value={role.value}>
                            {role.value}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                      <FormHelperText>{props.controwErrors.role}</FormHelperText>
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack {...LABEL_PROPS}>
                    <CustomInputLabel id='record_on_label' required className={contractStyles.labelInline}>Record On</CustomInputLabel>
                    <FormControl variant='standard' fullWidth error={props.controwErrors.record_on}>
                      <CustomSelect
                        {...SelectInputProps}
                        id='record_on'
                        labelId='record_on_label'
                        fullWidth
                        name='record_on'
                        value={!props.controwField.record_on ? 'placeholder' : props.controwField.record_on}
                        onChange={props.onChangecontrowField}
                        classes={{ select: !props.controwField.record_on ? styles.colorPlaceholder : '' }}
                        disabled={props.controwField.applied_on ? false : true}
                      >
                        <MenuItem value='placeholder' disabled>Select Record On</MenuItem>
                        {/* <MenuItem value={'Flight Report'}>Flight Report</MenuItem>
                        <MenuItem value={'Inbound Flight Report'}>Inbound Flight Report</MenuItem>
                        <MenuItem value={'Outbound Flight Report'}>Outbound Flight Report</MenuItem>
                        <MenuItem value={'Day Log'}>Day Log</MenuItem>
                        <MenuItem value={'Month Log'}>Month Log</MenuItem> */}
                        {
                          props.recordOns.map((record) => (
                            <MenuItem key={record} value={record}>
                              {record}
                            </MenuItem>
                          ))
                        }
                      </CustomSelect>
                      <FormHelperText>{props.controwErrors.record_on}</FormHelperText>
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={5.6} marginRight={{
                  xs: 0,
                  md: '3.33%',
                }}>
                  <Stack {...LABEL_PROPS}>
                    <CustomInputLabel htmlFor='platform' aria-label='Platform' className={contractStyles.labelInline}>Platform</CustomInputLabel>
                    <>
                      <CustomSelect
                        {...SelectInputProps}
                        id='platform'
                        labelId='platform-label'
                        onChange={props.onChangecontrowField}
                        name='platform'
                        value={!props.controwField.platform ? 'placeholder' : props.controwField.platform}
                        classes={{ select: !props.controwField.platform ? styles.colorPlaceholder : '' }}
                        fullWidth
                        disabled={ !props.controwField.record_on ? true : props.controwField.record_on === 'Month Log' || props.controwField.record_on ===  'Day Log' ? true : false }
                      >
                        <MenuItem value='placeholder'>Select a Platform</MenuItem>
                        <MenuItem value={'N/A'}>N/A</MenuItem>
                        {platforms.map((name) => (
                          <MenuItem key={name} value={name}>{name}</MenuItem>
                        ))}
                      </CustomSelect>
                    </>
                  </Stack>
                </Grid>
                
                <Grid item xs={12} sm={12}>
                  <Stack {...LABEL_PROPS}>
                    <CustomInputLabel id='segment_label' required className={contractStyles.labelInline}>Segment</CustomInputLabel>
                    <FormControl variant='standard' fullWidth error={props.controwErrors.segment}>
                      <CustomSelect
                        {...SelectInputProps}
                        id='segment'
                        labelId='segment_label'
                        fullWidth
                        name='segment'
                        value={!props.controwField.segment ? 'placeholder' : props.controwField.segment}
                        onChange={props.onChangecontrowField}
                        classes={{ select: !props.controwField.segment ? styles.colorPlaceholder : '' }}
                        disabled={props.controwField.applied_on ? false : true}
                      >
                        <MenuItem value='placeholder' disabled>Select Segment</MenuItem>
                        {/* <MenuItem value={'Import'}>Import</MenuItem>
                        <MenuItem value={'Export'}>Export</MenuItem>
                        <MenuItem value={'Transit'}>Transit</MenuItem> */}
                        {
                          props.segments.map((segment) => (
                            <MenuItem key={segment} value={segment}>
                              {segment}
                            </MenuItem>
                          ))
                        }
                        <MenuItem value={'N/A'}>N/A</MenuItem>
                      </CustomSelect>
                      <FormHelperText>{props.controwErrors.segment}</FormHelperText>
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Stack {...LABEL_PROPS}>
                    <CustomInputLabel htmlFor='remarks' className={contractStyles.labelInline}>Remarks</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      fullWidth
                      id='remarks'
                      placeholder=''
                      type='text'
                      name='remarks'
                      value={props.controwField.remarks}
                      onChange={props.onChangecontrowField}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </StyledCard>

          </CustomDialogContent>
          <CustomDialogAction className={`${styles.fSpaceBtw}`}>
            <CustomButton variant='contained' type='reset' onClick={props.onClose}> Close </CustomButton> 
            <CustomButton variant='contained' type='submit' onClick={action == 'add' ? props?.addControw : props.editControw}> 
              {action == 'add' ? 'Submit' : 'Update'} 
            </CustomButton> 
          </CustomDialogAction>
        </form>
      </Dialog>

      <Popover
        open={props.attachment}
        anchorEl={props.anchorEl}
        onClose={() => props.handlePopover(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Box className={contractStyles.popoverWrapper}>
          {appliedOnItems.map((item, i) => 
            <div key={i} className={`${styles.dFlex} ${contractStyles.desc}`}>
              <Typography variant='caption' component={'p'} marginBottom={0} whiteSpace={'nowrap'} className={`${styles.minWidth170}`}>
                {`${item.label}`}
              </Typography>
              <Typography variant='caption' component={'p'} marginBottom={0}>
                {`${item.desc}`}
              </Typography>
            </div>
          )}
        </Box>
      </Popover>
    </div>
  );
};
export default AddContractItem;