import { Axios } from 'axios';
import cookies from './cookies';
import { toast } from 'react-toastify';
import environment from '../custom/environments';
import { loaderRef } from 'components/header/loader';

const flightHttp = new Axios({
  baseURL: environment.flightScheduleService,
  headers: {
    'Content-Type': 'application/json',
    name: environment.name,
    key: environment.api_key,
  },
  withCredentials: true
});
flightHttp.interceptors.request.use(
  (config) => {
    if(!config.headers.hideloader) loaderRef.current.style.display = 'block';
    config.headers.company = cookies.get('company') || null;
    config.headers.user = cookies.get('user') || null;
    return config;
  }
);

flightHttp.interceptors.response.use((response) => {
  loaderRef.current.style.display = 'none';
  response.data = response.data ? JSON.parse(response.data) : {};
  // if (response && response.config && response.config.method != 'get') {
  //   toast.success(response.data.message);
  // }
  return response;
}, error => {
  loaderRef.current.style.display = 'none';
  console.log('API ERROR=====>', error);
  if (error) {
    toast.error(error.response && error.response.data.message || error.message);
  }
  return Promise.reject(error);
});

export default flightHttp;