import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  invoiceNoContainer: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: 'auto',
  },
  invoiceDate: {
    fontSize: 12,
    fontStyle: 'bold',
  },
  date: {
    fontSize: 10,
    color: 'grey',
    width: 100,
    textAlign: 'right',
  },
  invoiceLabel: {
    fontSize: 14,
    fontStyle: 'bold',
    textTransform: 'uppercase',
    marginTop: 'auto',
  },   
});


const InvoiceNo = ({ invoice }) => (
  // <View style={styles.invoiceDetails}>
  <View style={styles.invoiceNoContainer}>
    <Text style={styles.invoiceLabel}>{invoice.invoice_no}</Text>
    <Text style={styles.date}>{invoice.trans_date} | {invoice.station}</Text>
  </View>
);
  
export default InvoiceNo;