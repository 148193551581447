import React from 'react';
import { Dialog, Grid, FormGroup, InputAdornment, Checkbox, FormControl, MenuItem, FormHelperText } from '@mui/material';
import styles from '../../../../../custom/style.module.css';
import admin from '../admin.module.css';
import { CustomButton, CustomDialogTitle, StyledCard, CustomTextField, CustomDatePicker, CustomCheckbox, CustomSelect, CustomInputLabel, CustomDialogAction, InputProps, CustomAutocomplete, SelectInputProps, CustomDialogContent } from '../../../../../custom/components';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import dayjs from 'dayjs';
import moment from 'moment';
import { CloseIconBtn } from 'custom/constants';

const platforms = [
  'Pax',
  'Freighter',
  'Domestic Pax',
  'Domestic Freighter',
  'Truck',
];

const flightScheduleModal = (props) => {
  const { changeCheck } = props;
  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={props.openAddSchedule} onClose={props.handleCloseAirline} >
        <CustomDialogTitle id='form-dialog-title' className={styles.modalTitle}>{props.type == 'edit' ? 'Edit' : 'Add'} Flight Schedule
          <CloseIconBtn onClick={() => props.handleCloseSchedule('add')} />
        </CustomDialogTitle>
        <form onSubmit={props.addSchedule} className={styles.w100}>
          <CustomDialogContent>
            <StyledCard>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CustomInputLabel id='airline-label' aria-label='Enter-airline'>Airline</CustomInputLabel>
                  <FormControl variant='standard' fullWidth error={props.flightErrors.airline}>
                    <CustomSelect
                      {...SelectInputProps}
                      id='airline'
                      labelId='airline-label'
                      fullWidth
                      autoFocus
                      onChange={props.onChange}
                      name='airline'
                      value={!props.flightFields.airline ? 'placeholder' : props.flightFields.airline}
                      classes={{ select: !props.flightFields.airline ? styles.colorPlaceholder : '' }}
                    >
                      <MenuItem value='placeholder' disabled>Select an airline</MenuItem>
                      {props.airlines.map((airline, i) => (
                        <MenuItem key={i} value={airline.code}>
                          {airline.code}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                    <FormHelperText>{props.flightErrors.airline}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInputLabel htmlFor='number' aria-label='Enter-number'>Flight Number</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='number'
                    fullWidth
                    placeholder='Flight Number'
                    type='text'
                    onChange={props.onChange}
                    name='flight_no'
                    value={props.flightFields.flight_no}
                    error={props.flightErrors.flight_no}
                    helperText={props.flightErrors.flight_no}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInputLabel htmlFor='org' aria-label='Enter-origin'>Origin</CustomInputLabel>
                  <FormControl variant='standard' fullWidth error={props.flightErrors.org}>
                    <CustomAutocomplete
                      disablePortal
                      id='org'
                      options={props.locationList}
                      getOptionLabel={(option) => option.iata_code}
                      onChange={props.onChange}
                      name='org'
                      value={props.scheduleOrgDest.org}
                      renderInput={(params) => 
                        <CustomTextField 
                          {...InputProps}
                          {...params} 
                          placeholder='Origin'
                          // onChange={(newValue) => {
                          //   props.onChange(newValue);
                          // }} 
                        />}
                      fullWidth
                      slotProps={{ paper: { className: styles.selectPaperIp }, }}
                      error={props.flightErrors.org}
                      helperText={props.flightErrors.org}
                    />
                    <FormHelperText>{props.flightErrors.org}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInputLabel htmlFor='dest' aria-label='Enter-destionation '>Destination</CustomInputLabel>
                  <FormControl variant='standard' fullWidth error={props.flightErrors.dest}>
                    <CustomAutocomplete    
                      disablePortal
                      id='dest'
                      options={props.locationList}
                      getOptionLabel={(option) => option.iata_code}
                      onChange={props.onChange}
                      name='dest'
                      value={ props.scheduleOrgDest.dest}
                      renderInput={(params) => 
                        <CustomTextField 
                          {...InputProps}
                          {...params} 
                          placeholder='Destination'
                          // onChange={(newValue) => {
                          //   props.onChange(newValue);
                          // }}                       
                        />}
                      fullWidth
                      slotProps={{ paper: { className: styles.selectPaperIp } }}
                      className={styles.autoCompleteIp}
                    />
                    <FormHelperText>{props.flightErrors.dest}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInputLabel id='platform-label' aria-label='Select-platform'>Platform</CustomInputLabel>
                  <FormControl variant='standard' fullWidth error={props.flightErrors.platform}>
                    <CustomSelect
                      {...SelectInputProps}
                      id='platform'
                      labelId='platform-label'
                      onChange={props.onChange}
                      name='platform'
                      value={!props.flightFields.platform ? 'placeholder' :props.flightFields.platform}
                      classes={{ select: !props.flightFields.platform ? styles.colorPlaceholder : '' }}
                    >
                      <MenuItem value='placeholder' disabled>Select a Platform</MenuItem>
                      {platforms.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                    <FormHelperText>{props.flightErrors.platform}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInputLabel htmlFor='type' aria-label='Enter-aircraft-type '>Aircraft Type</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='type'
                    fullWidth
                    placeholder='Aircraft Type'
                    type='text'
                    onChange={props.onChange}
                    name='ac_type'
                    value={props.flightFields.ac_type}
                    error={props.flightErrors.ac_type}
                    helperText={props.flightErrors.ac_type}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInputLabel htmlFor='std' aria-label='Enter-std '>STD</CustomInputLabel>
                  <FormControl variant='standard' fullWidth error={props.flightErrors.std}>
                    <input
                      id='std'
                      label='STD'
                      name='std'
                      type='time'
                      value={props.flightFields.std}
                      onChange={(val) => props.changeDateTime(val, 'std')}
                      className={`${styles.ipTimePicker} ${styles.w100}`}
                    />
                    <FormHelperText>{props.flightErrors.std}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInputLabel htmlFor='sta' aria-label='Enter-sta' className={styles.fSpaceBtw}>STA
                    <CustomCheckbox
                      control={
                        <Checkbox inputProps={{ 'aria-label': '+1', }} checked={props.flightFields.sta_next} onChange={changeCheck} name='sta_next' disableRipple className={styles.p0} />
                      }
                      label='+1'
                      className={styles.mr0}
                    />
                  </CustomInputLabel>
                  <FormControl variant='standard' fullWidth error={props.flightErrors.sta}>
                    <input
                      id='sta'
                      label='STA'
                      name='sta'
                      type='time'
                      value={props.flightFields.sta}
                      onChange={(val) => props.changeDateTime(val, 'sta')}
                      className={`${styles.ipTimePicker} ${styles.w100}`}

                    />
                    <FormHelperText>{props.flightErrors.sta}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInputLabel htmlFor='from_date' aria-label='Enter-from-date '>From Date</CustomInputLabel>
                  <FormControl variant='standard' fullWidth error={props.flightErrors.from}>
                    <CustomDatePicker
                      slotProps={{
                        textField: {
                          ...InputProps,
                          placeholder: 'From Date',
                          fullWidth: true
                        },
                      }}  
                      clearable
                      format='DD MMM YYYY'
                      value={dayjs(moment(props.flightFields.from, 'YYYY MM DD'))}
                      error={props.flightErrors.from}
                      helperText={props.flightErrors.from}
                      onChange={(val) => props.changeDateTime(val, 'from')}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end' >
                            <QueryBuilderIcon color='primary' />
                          </InputAdornment>
                        )
                      }}
                    />
                    <FormHelperText>{props.flightErrors.from}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInputLabel htmlFor='to_date' aria-label='Enter-to-date'>To Date</CustomInputLabel>
                  <FormControl variant='standard' fullWidth error={props.flightErrors.to}>
                    <CustomDatePicker
                      slotProps={{
                        textField: {
                          ...InputProps,
                          placeholder: 'To Date',
                          fullWidth: true
                        }, 
                      }}                    
                      clearable
                      format='DD MMM YYYY'
                      minDate={dayjs(moment(props.flightFields.from, 'YYYY MM DD'))}
                      value={dayjs(moment(props.flightFields.to, 'YYYY MM DD'))}
                      error={props.flightErrors.to}
                      helperText={props.flightErrors.to}
                      onChange={(val) => props.changeDateTime(val, 'to')}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end' >
                            <QueryBuilderIcon color='primary' />
                          </InputAdornment>
                        )
                      }}
                    />
                    <FormHelperText>{props.flightErrors.to}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <CustomInputLabel htmlFor='' aria-label='Select-days-of-operation'>Days of operation</CustomInputLabel>
                  <FormGroup className={admin.blocks}>
                    <CustomCheckbox
                      control={
                        <Checkbox inputProps={{ 'aria-label': 'Monday', }} checked={props.flightFields.mon} onChange={changeCheck} name='mon' />
                      }
                      label='1'
                    />
                    <CustomCheckbox
                      control={
                        <Checkbox inputProps={{ 'aria-label': 'Tuesday', }} checked={props.flightFields.tue} onChange={changeCheck} name='tue' />
                      }
                      label='2'
                    />
                    <CustomCheckbox
                      control={
                        <Checkbox inputProps={{ 'aria-label': 'Wednesday', }} checked={props.flightFields.wed} onChange={changeCheck} name='wed' />
                      }
                      label='3'
                    />
                    <CustomCheckbox
                      control={
                        <Checkbox inputProps={{ 'aria-label': 'Thursday', }} checked={props.flightFields.thu} onChange={changeCheck} name='thu' />
                      }
                      label='4'
                    />
                    <CustomCheckbox
                      control={
                        <Checkbox inputProps={{ 'aria-label': 'Friday', }} checked={props.flightFields.fri} onChange={changeCheck} name='fri' />
                      }
                      label='5'
                    />
                    <CustomCheckbox
                      control={
                        <Checkbox inputProps={{ 'aria-label': 'Saturday', }} checked={props.flightFields.sat} onChange={changeCheck} name='sat' />
                      }
                      label='6'
                    />
                    <CustomCheckbox
                      control={
                        <Checkbox inputProps={{ 'aria-label': 'Sunday', }} checked={props.flightFields.sun} onChange={changeCheck} name='sun' />
                      }
                      label='7'
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </StyledCard>
          </CustomDialogContent>
          <CustomDialogAction className={styles.fSpaceBtw}>
            <CustomButton variant='contained' onClick={() => props.handleCloseSchedule('add')}>Close</CustomButton>
            <CustomButton variant='contained' onClick={props.addSchedule}>{props.type == 'edit' ? 'Update' : 'Submit'}</CustomButton>
          </CustomDialogAction>
        </form>
      </Dialog>
    </div>
  );
};
export default flightScheduleModal;