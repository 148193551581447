import React, { Fragment } from 'react';
import { StyledCard, StyledCardTitle, SelectInputProps, CustomInputLabel, CustomSelectRounded, CustomDatePicker, InputProps } from 'custom/components';
import { FormControl, Grid, MenuItem, Stack } from '@mui/material';
import styles from '../../../custom/style.module.css';
import billingStyles from '../billing/billing.module.css';
import { contractType } from 'custom/constants';
import moment from 'moment';
import dayjs from 'dayjs';

const STACKFILTER = {
  direction:{
    xs: 'column',
    lg: 'row',
  }, 
  align:{
    xs: 'flex-start',
    lg: 'center',
  }
};

const BillingFilter = (props) => {

  return <>
    <div 
      className={`${styles.wAuto} ${billingStyles.headerWraper}`}>
      <StyledCard className={`${billingStyles.filterCard}`}>
        <StyledCardTitle gutterBottom className={`${billingStyles.filterTitle}`}>Flight Info</StyledCardTitle>
        <Grid container columnSpacing={{
          xs: 4,
          lg: 2,
          xl: '2%',
        }} rowSpacing={{
          xs: 2,
          lg: 0,
        }} justifyContent={{
          xs: 'flex-start',
          xl: 'center',
        }}>
          <Grid item xs={12} sm={6} md={3} lg>
            <Stack direction={{
              xs: 'column',
              lg: 'row',
            }}
            align={{
              xs: 'flex-start',
              lg: 'center',
            }}>
              <CustomInputLabel id='airline-label' aria-label='Enter-airline' className={styles.ipLabel}>Airline</CustomInputLabel>
              <FormControl variant='standard' fullWidth>
                <CustomSelectRounded
                  {...SelectInputProps}
                  id='airline'
                  labelId='airline-label'
                  fullWidth
                  autoFocus
                  name='airline'
                  className={`${styles.brInput}`}
                  onChange={props.onFilterMain}
                  value={!props.searchFields?.airline ? 'placeholder' : props.searchFields?.airline}
                >
                  <MenuItem value='placeholder' disabled>Select an airline</MenuItem>
                  {props.airlines.map((airline, i) => (
                    <MenuItem key={i} value={airline.id}>
                      {airline.code}
                    </MenuItem>
                  ))}
                </CustomSelectRounded>
              </FormControl>
            </Stack>
          </Grid>
          {props.loadInfo !== 'discrepancy' ? 
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3} className={billingStyles.minWidthIp}>
              <Stack {...STACKFILTER}>
                <CustomInputLabel id='contract_label' aria-label='Enter-contract' className={styles.ipLabel}>Department</CustomInputLabel>
                <FormControl variant='standard' fullWidth>
                  <CustomSelectRounded
                    {...SelectInputProps}
                    id='airline'
                    labelId='contract-label'
                    fullWidth
                    autoFocus
                    className={`${styles.brInput}`}
                    onChange={props.handleContractType}
                    value={props.contract_Type} 
                  >
                    <MenuItem value='placeholder' disabled>Select a Department</MenuItem>
                    {Object.entries(contractType).map(([ key, value]) => {
                      return <MenuItem key={key}value={key}>{value}</MenuItem>;
                    })}
                    {/* {
                      props.contractTypes.map((contractType, index) => (
                        <MenuItem key={index} value={contractType}>{contractType}</MenuItem>
                      ))
                    } */}
                  </CustomSelectRounded>
                </FormControl>
              </Stack>
            </Grid> : ''
          }

          <Grid item xs={12} sm={6} md={3} lg={3} xl={3} className={billingStyles.minWidthIp}>
            <Stack {...STACKFILTER}>
              <CustomInputLabel htmlFor='' aria-label='Enter month' className={`${styles.ipLabel}`}>Month</CustomInputLabel>
              <CustomDatePicker
                slotProps={{
                  textField: {
                    ...InputProps,
                    placeholder: 'Enter Month',
                    fullWidth: true
                  },
                }}
                views={['month', 'year']}
                className={`${styles.brInput} ${styles.w100}`}
                openTo='month'
                onChange={e =>
                  props.onFilterMain({
                    target: {
                      name: 'month',
                      value: moment(e.$d).format('YYYY-MM-DD')
                    }
                  })
                }
                value={dayjs(moment(props.searchFields.month).format('YYYY-MM-DD'))}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3} className={billingStyles.minWidthIp}>
            <Stack {...STACKFILTER}>
              <CustomInputLabel htmlFor='' aria-label='Enter period' className={`${styles.ipLabel}`}>Period</CustomInputLabel>
              <FormControl variant='standard' fullWidth>
                <CustomSelectRounded
                  {...SelectInputProps}
                  id='period'
                  labelId='period-label'
                  fullWidth
                  autoFocus
                  onChange={props.onFilterMain}
                  name='period'
                  value={!props.searchFields.period ? 'placeholder' : props.searchFields.period}
                  // classes={{ select: !props.flightFields.airline ? styles.colorPlaceholder : '' }}
                >
                  <MenuItem value='placeholder' disabled>Select period</MenuItem>
                  <MenuItem value='first'>1st Cycle (1st - 15th)</MenuItem>
                  <MenuItem value='second'>2nd Cycle (16th - EOM)</MenuItem>
                  <MenuItem value='month'>Complete Month</MenuItem>
                </CustomSelectRounded>
              </FormControl>
            </Stack>
          </Grid>

          {/* <Grid item xs={12} sm={6} md={3} lg={3}>
            <Stack {...STACKFILTER}>
              <CustomInputLabel htmlFor='' aria-label='Enter date' className={`${styles.ipLabel}`}>From</CustomInputLabel>
              <CustomDatePicker
                slotProps={{
                  textField: {
                    ...InputProps,
                    placeholder: 'From Date',
                    fullWidth: true
                  },
                }}
                className={`${styles.brInput} ${styles.w100} ${styles.minWidth125}`}
                onChange={(v) => props.onFilterMain(
                  {
                    target: {
                      type: 'date',
                      name: 'from',
                      value: moment(v.$d).format('YYYY-MM-DD')
                    }
                  }, 'dste')}
                value={dayjs(moment(props.searchFields.from).format('YYYY-MM-DD'))}
              />

            </Stack>
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Stack {...STACKFILTER}>
              <CustomInputLabel htmlFor='' aria-label='Enter date' className={`${styles.ipLabel}`}>To</CustomInputLabel>
              <CustomDatePicker
                slotProps={{
                  textField: {
                    ...InputProps,
                    placeholder: 'To Date',
                    fullWidth: true
                  },
                }}
                className={`${styles.brInput} ${styles.w100} ${styles.minWidth125}`}
                onChange={(v) => props.onFilterMain(
                  {
                    target: {
                      type: 'date',
                      name: 'to',
                      value: moment(v.$d).format('YYYY-MM-DD')
                    }
                  }, 'dste')}
                value={dayjs(moment(props.searchFields.to).format('YYYY-MM-DD'))}
              />
            </Stack>
          </Grid> */}
        </Grid>
      </StyledCard>
    </div>
  </>;

};

export default BillingFilter;