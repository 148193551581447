import React from 'react';
import { Dialog, DialogContentText, DialogContent, Typography, DialogTitle, Divider, Stack } from '@mui/material';
import styles from '../../../custom/style.module.css';
import { CustomButton, CustomDialogAction } from '../../../custom/components';
import { CloseIconBtn } from 'custom/constants';

const ConfirmDialog = (props) => {
  return (
    <>
      <Dialog
        open={props.confirmDialog}
        onClose={props.onClose}
        aria-labelledby='Confirm'
        aria-describedby='Confirm'
        maxWidth='xs'
        fullWidth={true}
      >
        <DialogTitle id='form-dialog-title'>
          <Typography variant='h6' pb={1}>{props.title || 'Confirm Action'}</Typography>
          <CloseIconBtn 
            onClick={props.onClose} 
          />
          <Divider direction='horizontal' className={`${styles.w100}`} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant='body2'>{`${props.confirmationMessage}`}</Typography>
          </DialogContentText>
        </DialogContent>
        <CustomDialogAction>
          <Stack pt={'2rem'} spacing={1} direction='row' justifyContent={'space-between'}>
            <CustomButton variant='contained' size='small' onClick={() => { props.handleConfirm(); }}  > <Typography variant='body2' lineHeight='normal' fontWeight='bold'>Okay</Typography></CustomButton>
            <CustomButton variant='outlined' size='small' onClick={props.onClose}> <Typography variant='body2' lineHeight='normal'>Cancel</Typography></CustomButton>
          </Stack>
        </CustomDialogAction>
      </Dialog>
    </>
  );
};

export default ConfirmDialog;