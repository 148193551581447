import React from 'react';
import Discrepancy from './Discrepancy';
import http from 'utils/http';
import { connect } from 'react-redux';
import evemts from 'utils/evemts';
import { setDeleteAction } from 'store/actions/deleteAction';
import { setDocAction } from 'store/actions/docAction';
// import moment from "moment";

class DiscrepancyContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      locationList: [],
      discrepancyList: [],
      discrepancyFields: {},
      discrepancyErrors: {},
      productsList: [],
      flightId: null,
      attachment: false,
      anchorEl: null,
      doc: null,
      modal: false,
      action: '',
    };
    this.eventSource = null;
  }

  componentDidMount() {
    console.log('DiscrepancyContainer',this.props);
    if (this.props.flight && this.props.flight.id) {
      {
        this.setState({ flightId: this.props.flight.id }, () => { this.fetchDiscrepancy(); this.fetchProducts(); this.startEvent();});
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.flight.id !== this.props.flight.id && this.props.flight.id) {
      this.eventSource && this.eventSource.close();
      this.setState({ flightId: this.props.flight.id }, () => { this.fetchDiscrepancy(); this.fetchProducts();this.startEvent(); });
    }
  }

  componentWillUnmount() {
    this.eventSource && this.eventSource.close();
  }

  startEvent = ()=> {
    this.eventSource = evemts('flight', this.state.flightId);
    this.eventSource.onmessage = (event) => {
      let discrepancyList = [...this.state.discrepancyList || []];
      const msg = JSON.parse(event.data || '{}');
      if (msg.data && msg.data.flight_id != this.state.flightId) {
        if (msg.action === 'update_discrepancy') {
          let index = discrepancyList.findIndex(d => d.id == parseInt(msg.data?.id));
          if (index != -1) {
            discrepancyList[index] = {
              ...discrepancyList[index],
              ...msg.data 
            };
            this.setState({ discrepancyList: [...discrepancyList] });
          }
        }
        if (msg.action === 'add_discrepancy') {
          this.setState({ discrepancyList: [...discrepancyList, msg.data] });
        }
        if (msg.action === 'delete_discrepancy') {
          let index = discrepancyList.findIndex(d => d.id == parseInt(msg.data?.id));
          if (index != -1) {
            discrepancyList.splice(index, 1);
            this.setState({ discrepancyList: discrepancyList });
          }
        }
        if (msg.action == 'add_discrepancy_doc') {
          let discrepancyList = [...this.state.discrepancyList];
          const index = discrepancyList.findIndex(c => msg.data && c.id == msg.data.flight_discrepancyId);
          if (index > -1) {
            discrepancyList[index].docs = [...(discrepancyList[index].docs || []), msg.data];
            this.setState({ discrepancyList }, () => {
              this.handleDoc(discrepancyList[index]);
            });
          }
        }
        if (msg.action == 'delete_discrepancy_doc') {
          let discrepancyList = [...this.state.discrepancyList];
          const index = discrepancyList.findIndex(c => msg.data && c.id == msg.data.flight_discrepancyId);
          if (index > -1) {
            const index2 = discrepancyList[index].docs.findIndex(cr => msg.data && cr.id == msg.data.id);
            if (index2 > -1) {
              discrepancyList[index].docs.splice(index2, 1);
              this.setState({ discrepancyList });
            }
          }
        }
      }
    };
  };


  fetchLocations = () => {
    http.get('/locations')
      .then((response) => {
        this.setState({ locationList: response.data.data });
      });
  };

  fetchDiscrepancy = () => {
    http.get('/discrepancy/' + this.state.flightId)
      .then((response) => {
        if (response.data.data.length > 0) {
          this.setState({ discrepancyList: response.data.data }, () => {
            if(this.props.printFunction) {
              this.props.printFunction(this.state.discrepancyList, 'discrepancy');
            }
          });
        }
      });
  };

  fetchProducts = () => {
    if (this.props.flight?.airlineId)
      http.get(`/products?airlineId=${this.props.flight?.airlineId}&common=${true}`).then(({ data }) => {
        console.log('fetchProducts',data.data);
        this.setState({ productsList: data.data });
      });
  };

  addEditDiscrepancy = async () => {
    console.log('addEditDiscrepancy');
    if(this.state.loading) {
      return;
    } else {
      // e.preventDefault();
      this.setState({ loading: true });
      const formdata = new FormData();
      for (const key in this.state.discrepancyFields) {
        formdata.append(key, ['t_pcs', 'd_pcs']?.includes(key) ? parseInt(this.state.discrepancyFields[key]) : this.state.discrepancyFields[key]);
      }
      if (this.validation()) {
        if (this.state.discrepancyFields.id) {
          console.log(' here 1');
          this.updateDiscrepancy(this.state.discrepancyFields.id, formdata);
        } else {
          console.log(' here 2');
          await http.post('/discrepancy/' + this.state.flightId, formdata)
            .then(() => {
              this.setState({
                loading: false,
                discrepancyErrors: {},
                discrepancyFields: {
                  type: '',
                  uld_no: '',
                  is_bulk: true,
                  uld_type: '',
                  handled_as: '',
                  awb_no: '',
                  org: '',
                  dest: '',
                  t_pcs: '',
                  t_wgt: '',
                  d_pcs: '',
                  d_wgt: '',
                  remark: '',
                  file: '',
                },
                modal: false,
              });
            });
        }
      } else {
        this.setState({ loading: false });
      }
    }
  };

  updateDiscrepancy = async (id, data) => {
    await http.put('/discrepancy/' + id, data)
      .then(() => {
        this.setState({
          loading: false,
          discrepancyErrors: {},
          discrepancyFields: {
            type: '',
            uld_no: '',
            is_bulk: true,
            uld_type: '',
            handled_as: '',
            awb_no: '',
            org: '',
            dest: '',
            t_pcs: '',
            t_wgt: '',
            d_pcs: '',
            d_wgt: '',
            remark: '',
            file: '',
          },
          modal: false,
        });
      });
  };

  validation = () => {
    const discrepancyFields = { ...this.state.discrepancyFields };
    const error = {};
    let formValid = true;

    if ( discrepancyFields.uld_no && discrepancyFields.uld_no != 'BULK' && (!discrepancyFields.uld_type || (discrepancyFields.uld_type == ''))) {
      formValid = false;
      error.uld_type = 'Please select a ULD Type';
    }
    if (!discrepancyFields.handled_as || (discrepancyFields.handled_as == '')) {
      formValid = false;
      error.handled_as = 'Please select a Handled As';
    }
    if (!discrepancyFields.type || (discrepancyFields.type == '')) {
      formValid = false;
      error.type = 'Please select a Type';
    }
    if (!discrepancyFields.awb_no || (discrepancyFields.awb_no.trim().length === 0)) {
      formValid = false;
      error.awb_no = 'Please enter AWB Number';
    }
    if (!discrepancyFields.org || (discrepancyFields.org.trim().length === 0)) {
      formValid = false;
      error.org = 'Please Select an origin';
    }
    if (!discrepancyFields.dest || (discrepancyFields.dest.trim().length === 0)) {
      formValid = false;
      error.dest = 'Please Select a destination';
    }
    if (!discrepancyFields.t_pcs) {
      formValid = false;
      error.t_pcs = 'Please enter total pcs';
    }
    if (!discrepancyFields.t_wgt || (discrepancyFields.t_wgt.trim().length === 0)) {
      formValid = false;
      error.t_wgt = 'Please enter Total Weight';
    }
    if (!discrepancyFields.d_pcs) {
      formValid = false;
      error.d_pcs = 'Please enter discrepancy pcs';
    }
    if (!discrepancyFields.d_wgt || (discrepancyFields.d_wgt.trim().length === 0)) {
      formValid = false;
      error.d_wgt = 'Please enter discrepancy weight';
    }
    if (!discrepancyFields.remark || (discrepancyFields.remark.trim().length === 0)) {
      formValid = false;
      error.remark = 'Please enter a remark';
    }
    this.setState({ discrepancyErrors: error },()=>{
      console.log('discrepancyErrors',this.state.discrepancyErrors);
    });
    return formValid;
  };

  onChange = (e) => {
    console.log('onChange e',e.target);
    let discrepancyFields = { ...this.state.discrepancyFields };
    let value = e.target ? e.target.value : e;
    const name = e.target.name;
    console.log('name',name);
    if (name == 'file') {
      value = e.target.files[0];
    }
    if(name === 'uld_no'){
      if(value){
        discrepancyFields['is_bulk'] = false;
      } else {
        discrepancyFields['is_bulk'] = true;
      }
    }
    if(name === 'prod_code'){
      discrepancyFields['productsId'] = e.target.id;
    }
    this.setState({
      discrepancyFields: {
        ...discrepancyFields,
        [name]: value,
        org: name === 'org' ? e.target.value.toUpperCase() : discrepancyFields.org,
        dest: name === 'dest' ? e.target.value.toUpperCase() : discrepancyFields.dest,
      }
    });
  };
  getULDData = (uld_no) => {
    if (uld_no && uld_no !== undefined) {
      // return uld's which number is close to uld_no
      let filter = this.props.ffm?.flight_uld.filter((uld) => uld.uld_no.includes(uld_no));
      return filter;
    } else {
      return [];
    }
  };

  onULDChange = (value) => {
    if(value?.target?.value){
      value = value.target.value;
    }
    let discrepancyFields = { ...this.state.discrepancyFields };

    let uldlist = this.getULDData(value);
    if(value && value !== undefined){
      discrepancyFields['is_bulk'] = false;
    } else {
      discrepancyFields['is_bulk'] = true;
      discrepancyFields['uld_type'] = '';
      discrepancyFields['handled_as'] = '';
    }
    if(uldlist.length>0 && uldlist[0].uld_no === value){
      discrepancyFields['uld_type'] = uldlist[0].uld_type;
      discrepancyFields['handled_as'] = uldlist[0].type;
    }
    console.log('discrepancyFields ULD before',discrepancyFields);
    this.setState({
      discrepancyFields: {
        ...discrepancyFields,
        ['uld_no']: value || '',
      },
    },()=>console.log('discrepancyFields****** ULD after',this.state.discrepancyFields));
    
  };
  onAWBChange = (value) => {
    if(!value.includes('-')){
      if (value.length <= 3 || value.includes('-')) {
        return value;
      }
      else {
        value = value.slice(0, 3) + '-' + value.slice(3);
      }
    }
    if(value?.target?.value){
      value = value.target.value;
    }
    let discrepancyFields = { ...this.state.discrepancyFields };
    if(value && value !== undefined){
      let awblist = this.getULDData(this.state.discrepancyFields.uld_no)[0]?.flight_awbs || [];
      let awbData = awblist.find(awb => awb.awb_no === value);
      if(awbData){
        discrepancyFields['org'] = awbData.org;
        discrepancyFields['dest'] = awbData.dest;
        discrepancyFields['t_pcs'] = awbData.pcs || 0;
        discrepancyFields['t_wgt'] = awbData.wgt || 0;
        discrepancyFields['prod_code'] = awbData?.product?.name || null;
      }
    } else {
      discrepancyFields['org'] = '';
      discrepancyFields['dest'] = '';
      discrepancyFields['t_pcs'] = 0;
      discrepancyFields['t_wgt'] = 0;
      discrepancyFields['prod_code'] = null;
    }

    this.setState({
      discrepancyFields: {
        ...discrepancyFields,
        ['awb_no']: value || '',
      },
    });
    
  };

  onInputChange = (value, type) => {
    console.log('onInputChange 1',value, type);
    if(value?.target?.value){
      value = value.target.value;
    }
    console.log('onInputChange2',value, type);
    let discrepancyFields = { ...this.state.discrepancyFields };
    if( type === 'uld_no'){
      let uldlist = this.getULDData(value);
      
      if(value){
        discrepancyFields['is_bulk'] = false;
      } else {
        discrepancyFields['is_bulk'] = true;
        discrepancyFields['uld_type'] = '';
        discrepancyFields['handled_as'] = '';
      }
      if(uldlist.length>0 && uldlist[0].uld_no === value){
        discrepancyFields['uld_type'] = uldlist[0].uld_type;
        discrepancyFields['handled_as'] = uldlist[0].type;
      }
      this.setState({
        discrepancyFields: {
          ...discrepancyFields,
          [type]: value || '',
        },
      },()=>console.log('discrepancyFields****** ULD',this.state.discrepancyFields));
    } 
    else if(type === 'awb_no'){
      console.log('B');
      console.log('awb_no',value);
      let awblist = this.getULDData(this.state.discrepancyFields.uld_no)[0]?.flight_awbs || [];
    
      console.log('awblist',awblist);
      if(awblist.length>0 && awblist[0].awb_no === value){
        console.log('here2');
        discrepancyFields['org'] = awblist[0].org;
        discrepancyFields['dest'] = awblist[0].dest;
        discrepancyFields['t_pcs'] = awblist[0].pcs || 0;
        discrepancyFields['t_wgt'] = awblist[0].wgt || 0;
        discrepancyFields['prod_code'] = awblist[0].product.name || null;
      }
      console.log('discrepancyFields',discrepancyFields);
      this.setState({
        discrepancyFields: {
          ...discrepancyFields,
          [type]: value || '',
        },
      },()=>console.log('discrepancyFields****** AWB',this.state.discrepancyFields));
    }
  };

  onEdit = (discrepancyFields = {}) => {
    if(!this.state.modal !== 'discrepancy'){
      this.setState({
        discrepancyFields: { ...discrepancyFields },
        discrepancyErrors: {},
        modal: 'discrepancy',
        action: 'edit',
      });
    }
  };

  deleteDis = async (id) => {
    this.setState({ loading: true });
    await http.delete(`/discrepancy/${this.state.flightId}/${id}`).then(({ data }) => {
      this.setState({ loading: false });
      if (data.status == 'success') {
        this.props.setDelete({
          modal: false,
          id: false,
          anchorEl: false,
          onConfirm: () => { },
        });
      }
    });
  };

  deleteAttachment = async (id) => {
    await http.delete(`/discrepancy/attachment/${id}`).then(({ data }) => {
      if (data.status == 'success') {
        this.props.setDelete({
          modal: false,
          id: false,
          anchorEl: false,
          onConfirm: () => { },
        });
        this.handlePopover(false);
      }
    }
    );
  };

  handleOpen = (action) => {
    this.setState({
      modal: 'discrepancy',
      action,

    },()=>{
      if (action === 'add') {
        this.setState({
          discrepancyFields: {
            type: '',
            uld_no: '',
            is_bulk: true,
            uld_type: '',
            handled_as: '',
            awb_no: '',
            org: '',
            dest: '',
            t_pcs: '',
            t_wgt: '',
            d_pcs: '',
            d_wgt: '',
            remark: '',
            file: '',
          },
          discrepancyErrors: {},
        });
      }
    });
  };
  onClose = () => {
    this.setState({ modal: false });
  };

  handleDelete = (id, anchorEl) => {
    this.props.setDelete({
      modal: true,
      id,
      anchorEl,
      onConfirm: () => this.deleteDis(id),
    });
  };

  handleDeleteAttachment = (id, anchorEl) => {
    this.props.setDelete({
      modal: true,
      id,
      anchorEl,
      onConfirm: () => this.deleteAttachment(id),
    });
  };

  handleDoc = (row) => {
    this.props.setDoc({
      modal: 'discrepancy',
      flightId: row.flightId,
      id: row.id,
      name: row.name,
      data: row.docs
    });
  };

  handlePopover = (attachment, anchorEl, doc) => {
    this.setState({
      attachment,
      anchorEl,
      doc,
    });
  };

  render() {
    return (
      <>
        <Discrepancy
          loading={this.state.loading}
          attachment={this.state.attachment}
          anchorEl={this.state.anchorEl}
          doc={this.state.doc}
          locationList={this.state.locationList}
          productsList={this.state.productsList}
          discrepancyFields={this.state.discrepancyFields}
          discrepancyErrors={this.state.discrepancyErrors}
          addEditDiscrepancy={this.addEditDiscrepancy}
          discrepancyList={this.state.discrepancyList}
          onDelete={this.handleDelete}
          onDeleteAttachment={this.handleDeleteAttachment}
          onEdit={this.onEdit}
          onChange={this.onChange}
          onULDChange={this.onULDChange}
          onAWBChange={this.onAWBChange}
          onInputChange={this.onInputChange}
          handlePopover={this.handlePopover}
          showReportModal={this.props.showReportModal}
          handleDoc={this.handleDoc}
          getULDData={this.getULDData}

          action={this.state.action}
          modal={this.state.modal}
          handleOpen={this.handleOpen}
          onClose={this.onClose}
          nil_manifest={this.props.nil}
        />
      </>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    flight: state.flight,
    doc: {
      ...state.doc,
      modal: 'discrepancy',
    },
    ffm: state.ffm,
    ctype: state.ctype,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setDelete: (data) => dispatch(setDeleteAction(data)),
    setDoc: (data) => dispatch(setDocAction(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscrepancyContainer);

