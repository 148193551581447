import React from 'react';
import {
  Dialog, MenuItem,
  FormControl, FormHelperText,
  Grid,
  Stack,
  Button,
} from '@mui/material';
import styles from '../../../../../custom/style.module.css';
import admin from '../admin.module.css';
import { CustomButton, CustomDialogTitle, CustomDialogAction, StyledCard, CustomTextField, CustomInputLabel, CustomSelect, InputProps, CustomDialogContent, SelectInputProps } from '../../../../../custom/components';
import { CloseIconBtn, roles, contractType, countryCode } from '../../../../../custom/constants';
import cookies from 'utils/cookies';

const finalroles = cookies.get('role') === 'Supervisor'
  ? roles.filter(role => role.value !== 'System Admin' && role.value !== 'Manager')
  : cookies.get('role') === 'Manager' ? roles.filter(role => role.value !== 'System Admin')
    : roles;


const AddUserModal = (props) => {

  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={props.modal == 'user'} onClose={props.onClose}>
        <CustomDialogTitle id='form-dialog-title' className={styles.modalTitle}>
          {props.action === 'edit' ? 'Edit' : 'Add'} User
          <CloseIconBtn onClick={props.onClose} />
        </CustomDialogTitle>
        <form onSubmit={props.action == 'add' ? props.addUser : props.editUser} className={styles.w100} onReset={props.onClose}>
          <CustomDialogContent>
            {props.action === 'add' ? (<Button variant='text' onClick={props.handleUserTypeModal} onClickCapture={() => props.handleOpen('other_user', 'add', {})}
            >Add Trypod User</Button>) : null}
            <StyledCard>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CustomInputLabel htmlFor='first_name'>First Name</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='first_name'
                    fullWidth
                    autoFocus
                    placeholder='First Name'
                    type='text'
                    onChange={props.onChange}
                    name='first_name'
                    value={props.userFields.first_name ?? ''}
                    error={props.userErrors.first_name}
                    helperText={props.userErrors.first_name}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInputLabel htmlFor='last_name'>Last Name</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='last_name'
                    fullWidth
                    className={admin.addTextField}
                    placeholder='Last Name'
                    type='text'
                    onChange={props.onChange}
                    name='last_name'
                    value={props.userFields.last_name ?? ''}
                    error={props.userErrors.last_name}
                    helperText={props.userErrors.last_name}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <CustomInputLabel htmlFor='username'>User Name</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    fullWidth
                    id='username'
                    placeholder='User Name'
                    type='email'
                    inputProps={{
                      autoComplete: 'new-password',
                      form: { autocomplete: 'off', },
                    }}
                    onChange={props.onChange}
                    name='username'
                    value={props.userFields.username ?? ''}
                    error={props.userErrors.username}
                    helperText={props.userErrors.username}
                    disabled={props.userFields.app} 
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <CustomInputLabel htmlFor='email'>Email</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    fullWidth
                    id='email'
                    placeholder='Email'
                    type='email'
                    onChange={props.onChange}
                    name='email'
                    value={props.userFields.email ?? ''}
                    error={props.userErrors.email}
                    helperText={props.userErrors.email}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <CustomInputLabel htmlFor='mobile'>Mobile</CustomInputLabel>
                  <Stack direction={'row'} spacing={2}>
                    <CustomSelect
                      {...SelectInputProps}
                      id='country_code'
                      labelId='country_code_label'
                      onChange={props.onChange}
                      name='country_code'
                      value={!props.userFields.country_code ? '+1' : props.userFields.country_code}
                      // error={props.userErrors.country_code}
                      classes={{ root: styles.minWidth125, }}
                      renderValue={(selected) => {
                        if (!selected) {
                          return 'Select a Country Code';
                        }
                        return selected;
                      }}
                    >
                      <MenuItem value='placeholder' disabled>Select a Country Code</MenuItem>
                      {countryCode.map((code) => (
                        <MenuItem key={code.code} value={code.dial_code}>
                          {code.name} ({code.dial_code})
                        </MenuItem>
                      ))}
                    </CustomSelect>
                    <CustomTextField
                      {...InputProps}
                      fullWidth
                      id='mobile'
                      placeholder='Mobile'
                      type='mobile'
                      onChange={props.onChange}
                      name='mobile'
                      value={props.userFields.mobile ?? ''}
                      error={props.userErrors.mobile}
                      helperText={props.userErrors.mobile}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <CustomInputLabel id='role_label'>Role</CustomInputLabel>
                  <FormControl
                    fullWidth error={props.userErrors.role ? true : false}>
                    <CustomSelect
                      {...SelectInputProps}
                      id='role'
                      labelId='role_label'
                      onChange={props.onChange}
                      name='role'
                      value={!props.userFields.role ? 'placeholder' : props.userFields.role}
                      error={props.userErrors.role}
                      classes={{ select: !props.userFields.role ? styles.colorPlaceholder : '' }}
                    >
                      <MenuItem value='placeholder' disabled>Select a Role</MenuItem>
                      {finalroles.map((name) => (
                        <MenuItem key={name.id} value={name.value}>
                          {name.value}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                    <FormHelperText>{props.userErrors.role}</FormHelperText>
                  </FormControl>
                </Grid>
                <>
                  {props.userFields.role === 'Airline Rep' ? (
                    <Grid item xs={12} lg={12}>
                      <CustomInputLabel id='role_label'>Airlines</CustomInputLabel>
                      <FormControl fullWidth error={props.userErrors.airlines ? true : false}>
                        <CustomSelect
                          {...SelectInputProps}
                          id='airlines'
                          labelId='airlines_label'
                          onChange={props.onChange}
                          name='airlines'
                          value={props.userFields.airlines}
                          error={props.userErrors.airlines}
                          classes={{ select: !props.userFields.airlines ? styles.colorPlaceholder : '' }}
                          multiple
                        >
                          <MenuItem value='placeholder' disabled>
                            Select a Airlines
                          </MenuItem>
                          {props.airlineList.map((name) => (
                            <MenuItem key={name.id} value={name.code}>
                              {name.code}
                            </MenuItem>
                          ))}
                        </CustomSelect>
                        <FormHelperText>{props.userErrors.role}</FormHelperText>
                      </FormControl>
                    </Grid>
                  ) : null}
                </>
                <Grid item xs={12} lg={12}>
                  <CustomInputLabel id='contract_type_label'>Department (Contract)</CustomInputLabel>
                  <FormControl
                    fullWidth error={props.userErrors.contract_types ? true : false}>
                    <CustomSelect
                      {...SelectInputProps}
                      id='contract_type'
                      labelId='contract_type_label'
                      onChange={props.onChange}
                      name='contract_types'
                      value={props.userFields.contract_types || []}
                      error={props.userErrors.contract_types}
                      classes={{ select: !props.userFields.contract_types ? styles.colorPlaceholder : '' }}
                      multiple
                    >
                      {Object.entries(contractType).map(([key, value]) => (
                        <MenuItem key={key} value={value}>{value}</MenuItem>
                      ))}
                    </CustomSelect>
                    <FormHelperText>{props.userErrors.contract_types}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </StyledCard>
          </CustomDialogContent>
          <CustomDialogAction className={styles.fSpaceBtw}>
            <CustomButton variant='contained' type='reset'> Close</CustomButton>
            <CustomButton variant='contained' type='submit'> {props.action == 'edit' ? 'Update' : 'Submit'}</CustomButton>
          </CustomDialogAction>
        </form>
      </Dialog>
    </div>
  );
};
export default AddUserModal;