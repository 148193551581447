import React, { Fragment } from 'react';
import { Box, FormLabel, Stack, RadioGroup, Radio, Grid, Tooltip } from '@mui/material';
import { StyledDataGrid, StyledCard, StyledCardTitle, TableBadge, CustomRadio, DataGridProps } from '../../../../../custom/components';
import AddUserModal from './AddUserModal';
import { EditIcon, LockIcon, KeyIcon, LocationIcon, UnlockIcon, AddIcon } from '../../../../../custom/constants';
import userStyles from './users.module.css';
import styles from '../../../../../custom/style.module.css';
import LocationModal from '../../../common/location/LocationContainer';
import ChangePassword from './ChangePassword';
import DocModalContainer from 'components/pages/common/docModal/DocModalContainer';
import DeactiveModal from './DeactiveUserModal';
import RemoveExpiryModal from './RemoveExpiryModal';
import cookies from 'utils/cookies';
import UserModal from './UserModal';


const checkperms = (row) => {
  let usecookie = cookies.get('role');
  let flag = false;
  if(row.role === 'System Admin' && (usecookie === 'Supervisor' || usecookie === 'Manager')){
    flag = true;
  } else if( row.role === 'Manager' && usecookie === 'Supervisor'){
    flag = true;
  }
  return flag;
};
const ManageUser = (props) => {
  const { anchorEl, /*handleDoc,*/ handleTraining, training, doc, filter, onChangeFilter, generatePassword, action, modal, airlineList, userFields, userErrors, handleOpen, onClose, addUser, editUser, stations, onChange, handleChangePassword } = props;

  const columns = [
    {
      field: 'first_name',
      headerName: 'First Name',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'username',
      headerName: 'Email / User Name',
      type: 'email',
      minWidth: 150,
      flex: 1,

    },
    {
      field: 'role',
      headerName: 'Role',
      minWidth: 120,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Fragment>
            {row.role + ' '}{ <div>&nbsp; &nbsp;</div>}
            <Tooltip title={row.airlines && row.airlines[cookies.get('station_code')]?.join(', ')}>
              <div className={userStyles.roles}>
                {row.airlines && 
                  (row.airlines[cookies.get('station_code')]?.map((airlines, index) => (
                    <Fragment key={index}>
                      <span className={userStyles.airlines}>{airlines}</span>
                    </Fragment>
                  )))}
              </div>
            </Tooltip>
          </Fragment>
        );
      }
    },
    {
      field: 'contract_types',
      headerName: 'Department',
      minWidth: 120,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Fragment>
            <Tooltip title={row.contract_types?.join(', ')}>
              <div className={userStyles.roles}>
                {row.contract_types && 
                  (row.contract_types?.map((contract_type, index) => (
                    <Fragment key={index}>
                      <span className={userStyles.airlines}>{contract_type}</span>
                    </Fragment>
                  )))}
              </div>
            </Tooltip>
          </Fragment>
        );
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150, 
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
            {row.active ? (<LockIcon title='Deactivate User' onClick={() => props.handleOpen('deactive_user', 'deactive', row)} disabled={checkperms(row)} />) 
              : (<div onClick={() => props.handleOpen('deactive_user', 'active', row)}><UnlockIcon title='Reactivate User' disabled={checkperms(row)} /></div>)}
            <KeyIcon title='Manage Password' onClick={() => props.handleOpen('user_pass', 'edit', row)} disabled={checkperms(row) || !row?.master} />
            <TableBadge color='error' badgeContent={row.stations && row.stations.length} className={checkperms(row) ? styles.disabledImg: ''}>
              <LocationIcon onClick={(e) => props.handleLocation('user', true, row, e.target)} disabled={checkperms(row)} />
            </TableBadge>
            <EditIcon onClick={() => props.handleOpen('user', 'edit', row)} disabled={checkperms(row)} />
          </Stack>
        );
      }
    },
  ];
  return (
    <div>
      <StyledCard>
        <Box className={`${styles.mb3} ${styles.fSpaceBtw}`} >
          <Grid container columnSpacing={1}>
            <Grid item xs={12} sm={6} md={10} lg={1} order={{
              xs: 1,
              sm: 1,
              md: 1,
              lg: 1,
            }}>
              <StyledCardTitle gutterBottom className={styles.pt1} >User List</StyledCardTitle>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={10} order={{
              xs: 2,
              sm: 3,
              md: 3,
              lg: 2,
            }}>
              <Stack direction='row' spacing={2} justifyContent={{
                lg: 'flex-end',
                md: 'left' 
              }}
              alignItems={'center'} flexWrap='wrap'>
                <FormLabel component='small' className={`${styles.xsW100} ${styles.pr2}`}><small>Filter:</small> </FormLabel>
                <RadioGroup aria-labelledby='user-status' name='type' value={filter.type} defaultValue='ALL' onChange={onChangeFilter} row className={styles.ml0}>
                  <Stack direction='row' spacing={2} className={`${styles.fMiddle}`} flexWrap='wrap'>
                    <CustomRadio control={<Radio size='small' />} value='ALL' label='All Users' />
                    <CustomRadio control={<Radio size='small' />} value='ACTIVE' label='Active Users' />
                    <CustomRadio control={<Radio size='small' />} value='INACTIVE' label='Inactive Users' />
                    <CustomRadio control={<Radio size='small' />} value='DEACTIVATED' label='Deactivated Users' />
                  </Stack>
                </RadioGroup>
              </Stack>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={6} lg={5} order={{
              xs: 3,
              sm: 4,
              md: 4,
              lg: 3,
            }} className={styles.textCenter} >
              <CustomCheckbox name='expiry' checked={filter.expiry} control={<Checkbox inputProps={{ 'aria-label': '', }} size='small' onChange={onChangeFilter} />}
                label={<div style={{ marginTop: '4px' }}>Show users with training expiring in &nbsp;
                  <TextField
                    className={userStyles.tableTextField}
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*'
                    }}
                    onChange={onChangeFilter}
                    name='day'
                    value={filter.day}
                  /> days</div>} 
              />
            </Grid> */}
            <Grid item container justifyContent='flex-end' spacing={1} xs={12} sm={6} md={2} lg={1} order={{
              xs: 4,
              sm: 2,
              md: 2,
              lg: 4,
            }}>
              <Grid item>
                <AddIcon onClick={() => props.handleOpen('user', 'add', {})} title='Add User' />
              </Grid>
            </Grid>
          </Grid>

        </Box>
        <div className={styles.tableWrapper}>
          <Box className={`${styles.tableOuter} ${userStyles.userTableOuter}`}>
            <StyledDataGrid
              {...DataGridProps}
              rows={props.filteredData}
              columns={columns}
              param={props.filteredData?.length}
            />
          </Box>
        </div>
      </StyledCard>
      <UserModal modal={modal} onClose={onClose} otherUserList={props.otherUserList} tryUserFields={props.tryUserFields} onChangeTry = {props.onChangeTry} onSearchTry={props.onSearchTry} handleOpen={props.handleOpen} />
      <AddUserModal
        modal={modal}
        action={action}
        handleOpen={handleOpen}
        onClose={onClose}
        airlineList={airlineList}
        userFields={userFields}
        userErrors={userErrors}
        addUser={addUser}
        editUser={editUser}
        onChange={onChange}
        stations={stations}
      />

      <ChangePassword
        modal={modal}
        handleOpen={handleOpen}
        onClose={onClose}
        userFields={userFields}
        userErrors={userErrors}
        handleChangePassword={handleChangePassword}
        onChange={onChange}
        generatePassword={generatePassword}
        passwordVal={props.passwordVal}
        successModal={props.successModal}
      />

      <DeactiveModal
        modal={modal}
        action={action}
        userFields={userFields}
        userErrors={userErrors}
        active={props.active}
        deactive={props.deactive}
        onChange={onChange}
        onClose={onClose}
      />

      <LocationModal
        anchorEl={anchorEl}
        open={props.location}
        type={props.locationType}
        handleClose={props.handleLocation}
        name={props.station?.first_name + ' ' + props.station?.last_name}
        stations={props.stations}
        data={props.station}
      />

      <RemoveExpiryModal
        anchorEl={anchorEl}
        handleTraining={handleTraining}
        training={training}
        doc={doc}
        handleRetraining={props.handleRetraining}
        onChangeUser={props.onChangeUser}
      />

      <DocModalContainer />

    </div>
  );
};

export default ManageUser;
