import React from 'react';
import { Box, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import styles from 'custom/style.module.css';
import flightMainStyles from '../flights/flightsfolder.module.css';
import commonStyles from './common.module.css';
import flightInfoStyles from '../billing/tabs/flightloadinfo/fltloadinfo.module.css';
import { CloseIconBtn } from 'custom/constants';
import { CheckCircle } from '@mui/icons-material';

const ProgressModal = (props) => {
  return (
    <div>
      <Dialog
        open={props.modal === 'downloadProgress'}  
        // onClose={props.onClose}
        aria-labelledby='Flight Report'
        aria-describedby='Flight Report'
        maxWidth='lg'
        fullWidth={true}
      >
        <DialogTitle className={styles.fSpaceBtw} sx={{ borderBottom: '1px solid #f0f0f0' }}>
          <Typography variant='h6' component={'h6'} color={'primary'}>Download Invoice</Typography> 
          <CloseIconBtn onClick={() => props.onClose()} />
        </DialogTitle>
        

        <DialogContent>
          <div className={flightMainStyles.downloadWrapper}>
            {/* <Typography variant='h6' component={'h6'} color={'primary'} textAlign={'center'} mb={3}>Download Invoice</Typography> */}
            <div className={`${styles.textCenter} ${styles.positionRelative}`}>
              {props.progress === 100 ? 
                <>
                  <CheckCircle sx={{ fontSize: 80 }} color='secondary' />
                  <Typography variant='h6' component={'h6'} color={'primary'} textAlign={'center'} mb={3} mt={3}>Invoice Generated Successfully.</Typography>
                </>
                :
                <>
                  {props.progress && props.progress < 100 &&
                  <Box sx={{ position: 'relative' }}>
                    <CircularProgress
                      variant='determinate'
                      value={props.progress}
                      color='secondary'
                      size={140}
                      thickness={6}
                      sx={{ transition: 'all 0.5s ease', }}
                    />
                    <Typography variant='h6' component={'h6'} color={'primary'} textAlign={'center'} className={commonStyles.progressCount}>{props.progress}%</Typography>
                  </Box>
                  }
                  <Typography variant='h6' component={'h6'} color={'primary'} textAlign={'center'} mb={3}>Generating Invoice</Typography>
                </>
              }
            </div>
            {
              props.progress === 100 ? 
                <DialogContentText fontSize={'0.9rem'} textAlign={'center'} mt={3}>
            Invoice Generated <br />
            Invoice is ready for download.
                </DialogContentText>
                :
                <DialogContentText fontSize={'0.9rem'} textAlign={'center'} mt={3}>
            Preparing your Invoice <br />
            Please wait while we prepare your invoice for download.
                </DialogContentText>
            }
            <Box sx={{
              [`& .${flightInfoStyles.downloadInvoice}`]: {
                display: 'flex',
                justifyContent: 'space-evenly',
                marginTop: '2rem',
                flexWrap: 'wrap',
                gap: '1rem',
              },
              [`& .${flightInfoStyles.downloadInvoice} button`]: { minWidth: '200px', }
            }}>
              {props.invoiceDownload}
            </Box>
          </div>
        </DialogContent>

      </Dialog>
    </div>
  );
};
export default ProgressModal;