import React from 'react';
import BillingFilter from './BillingFilter';

class BillingFilterContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  componentDidMount() {
  }

  render() {
    return (
      <BillingFilter
        onFilterMain={this.props.onFilterMain}
        flightFields={this.props.flightFields}
        searchFields={this.props.searchFields}
        airlines={this.props.airlines}
        handleContractType={this.props.handleContractType}
        contract_Type={this.props.contract_Type}
        loadInfo={this.props.loadInfo}
      />
    );
  }
}

export default BillingFilterContainer;
