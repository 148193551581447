import React from 'react';
import FlightReport from './FlightReport';
import http from 'utils/http';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import events from '../../../../../utils/evemts';
import cookies from 'utils/cookies';
import { setDeleteAction } from 'store/actions/deleteAction';
import ConfirmModalContainer from 'components/pages/common/confirmModal/ConfirmModalContainer';
import { contractType, statisticsRoles } from 'custom/constants';


class FlightReportContainer extends React.Component {
  constructor(props) {
    super(props);
    console.log('this.props.location', this.props);
    const ctype = this.props.ctype;
    const contractTypeValues = Object.values(contractType);
    const statTypel = this.props.ctype ? contractTypeValues.indexOf(this.props.ctype) : null;
    const cont_iteml = this.props.ctype ? this.props.ctype : null;
    this.state = {
      userContractTypes: [],
      flightId: null,
      org: null,
      dest: null,
      ctype: ctype,
      history: {},
      // view_history: false,
      remarks: '',
      flightData: {},
      checklist: [],
      checklistRemarks: {},
      checkedChecklist: {},
      checklistIndex: null,
      checklistId: null,
      checkboxId: null,
      statManual: {},
      attachment: [],
      editRemark: false,
      productsList: [],
      ffm: {},
      ffmData : {},
      statList: [],
      statListObj: {},
      reportLogs: [],
      lastLog: {},

      docmodal: false,
      attachmentFields: {},
      statType: statTypel,
      cont_item: cont_iteml,

      statusModal: false,
      statusMessage: '',

      popup: false,
      anchorEl: null,
      popup2: false,
      anchorEl2: null,
      anchorEl3: null,

      filteredData: [],
      rowSelectionModel: [],
      onChangeCheckboxId: {},
      statRows: [],
      contindex: null,
      rowindex: null,
      note: '',
      showMoreNote: {},
      statRowData: {},
      redChecklist: false,
      selectableArray: [],
      availableArray: [],
      history_data: [],
      contractList: [],

      selectedOption: null,
      filteredReportLogs: [],
      nil: false,
    };
    this.refBtn = React.createRef();
    this.refROBrn = React.createRef();
  }

  componentDidMount() {
    const stn = cookies.get('station_code');
    if (this.props.flight && this.props.flight.id) {
      this.setState({
        flightId: this.props.flight.id,
        org: this.props.flight.org,
        dest: this.props.flight.dest,
        ffm: this.props.ffm,
        nil: this.props.ffm?.org == stn ? this.props.ffm?.out_nil_ffm : this.props.ffm?.in_nil_ffm
      }, () => {
        this.fetchUser();
        this.fetchFFMData();
      });
    }
  }

  componentDidUpdate(prevProps) {
    const stn = cookies.get('station_code');
    if (prevProps.flight.id !== this.props.flight.id && this.props.flight.id) {
      this.eventSource && this.eventSource.close();
      this.setState({
        flightId: this.props.flight.id,
        org: this.props.flight.org,
        dest: this.props.flight.dest,
        ffm: this.props.ffm,
        nil: this.props.ffm?.org == stn ? this.props.ffm?.out_nil_ffm : this.props.ffm?.in_nil_ffm
      }, () => { this.fetchFlight('load'); this.startEvent(); this.fetchFFMData(); });
    }
  }

  componentWillUnmount() {
    this.eventSource && this.eventSource.close();
  }

  startEvent = () => {
    this.eventSource = events('flight', this.state.flightId);
    this.eventSource.onmessage = (event) => {
      const msg = JSON.parse(event.data || '{}');
      if (msg.action === 'add_history') {
        this.fetchFlight();
      }
      if (msg.action == 'add_checklist') {
        let checklist = cloneDeep(this.state.checklist);
        let checklistIndex = checklist.findIndex(n => n.id == msg.data.airlinechecklistsId);
        if (checklistIndex > -1) {
          checklist[checklistIndex] = {
            ...checklist[checklistIndex],
            flight_checklist: [...checklist[checklistIndex].flight_checklist, msg.data]
          };
        }
        this.setState({ checklist: checklist }, () => { this.filterChecklist(); });
      }
      if (msg.action == 'update_checklist') {
        let checklist = cloneDeep(this.state.checklist);
        let checklistIndex = checklist.findIndex(n => n.id == msg.data.airlinechecklistsId);
        checklist.findIndex((n) => {
          let flightChecklistIndex = n.flight_checklist.findIndex(x => x.id == msg.data.id);
          if (flightChecklistIndex > -1) {
            let newArray = [...checklist[checklistIndex].flight_checklist];
            newArray[flightChecklistIndex] = {
              ...msg.data,
              user: newArray[flightChecklistIndex]?.user || {}
            };
            checklist[checklistIndex] = {
              ...checklist[checklistIndex],
              flight_checklist: newArray,
            };
          }
        });
        this.setState({
          checklist: checklist,
          // set the checklistRemarks
          checklistRemarks: {
            ...this.state.checklistRemarks,
            [msg.data.airlinechecklistsId]: {
              ...this.state.checklistRemarks[msg.data.airlinechecklistsId],
              [msg.data.id]: msg.data.remark,
              ['arrayId_' + msg.data.id]: msg.data.id
            }
          }
        }, () => this.filterChecklist() );
      }
      if (msg.action == 'add_doc') {
        let attachment = [...this.state.attachment];
        attachment.unshift(msg.data);
        this.setState({ attachment }, () => { });
      }
      if (msg.action == 'delete_doc') {
        let attachment = cloneDeep(this.state.attachment);
        const index = attachment.findIndex(c => msg.data && c.id == msg.data.id);
        if (index > -1) {
          attachment.splice(index, 1);
          this.setState({ attachment });
        }
      }
      if (msg.action === 'update') {
        this.fetchFlight();
      }
      if (msg.action == 'update_report' || msg.action == 'add_report') {
        let flightData = this.state.flightData;
        let index = flightData[0].reports.findIndex((n) => n.id == msg.data.id);
        if (index > -1) {
          flightData[0].reports[index] = msg.data;
        } else {
          flightData[0].reports.push(msg.data);
        }
        this.setState({ flightData: flightData }, () => { this.filterStatictics(); });
      }
      if (msg.action == 'add_cont_row_note') {
        let contractList = this.state.contractList || [];
        if(contractList?.length === 0 ) {
          contractList.push(msg.data);
        }
        let index = contractList?.findIndex((n) =>  parseInt(n.id) == parseInt(msg.data.cont_rowId) );
        console.log(index, 'index');
        let flightData = this.state.flightData;
        if(flightData[0]?.contracts[this.state.cont_item]){
          flightData[0]?.contracts[this.state.cont_item]?.map((contract) => {
            contract.contractrows.map((r) => {
              if (r.id === msg.data.cont_rowId) {
                if (index > -1) {
                  contractList[index]?.notes?.push(msg.data);
                  contractList[index]?.note?.push(msg.data);
                } 
                else {
                  contractList?.push(r);
                  r.note = [msg.data];
                  r.notes = [msg.data];
                }
              }
            });
          });
          this.setState({ 
            flightData: flightData,
            contractList: contractList 
          }, () => { });
        }
      }};
  };

  fetchUser = () => {
    http.get(`/user?id=${cookies.get('user')}`).then(({ data }) => {
      if (data && data.data && data.data.length > 0) {
        this.setState({ userContractTypes: data.data[0]?.contract_types || [] }, () => { this.fetchFlight('load'); this.startEvent(); });
      }
    });
  };
  fetchProducts = () => {
    http.get(`/products?airlineId=${this.props.flight?.airlineId}&common=${true}`).then(({ data }) => {
      this.setState({ productsList: data.data }, () => { this.handleStatistic(); });
    });
  };

  fetchFlight = (operation = null) => {
    http.get(`/getfreport/${this.state.flightId}`).then(({ data }) => {
      let statType = this.state.statType || null;
      let cont_item = this.state.cont_item || null;
      let userContractTypes = this.state.userContractTypes;
      let lastLog = {};
      //get first key and value from contractType
      let firstRecord = Object.entries(contractType).find(([, value]) => userContractTypes.includes(value));
      if (firstRecord && !statType) {
        statType = 0;
        cont_item = firstRecord[1];
      }
      
      data.data.length > 0 && data.data[0] && data.data[0].reports.map((r) => {
        if (r.type === this.state.cont_item) {
          let logs = r.log || [];
          if (logs.length > 0) {
            lastLog = logs[logs.length - 1];
          }
        }
      });
      this.setState({
        statType: statType,
        cont_item: cont_item,
        flightData: data.data,
        reportLogs: data.data.length > 0 && data.data[0] && data.data[0].reports || [],
        filteredReportLogs: { log:  data.data.length > 0 && data.data[0] && data.data[0].reports && data.data[0].reports[0]?.log?.filter((r) => r.remark) },
        lastLog: lastLog
      }, () => {
        if (operation === 'load') {
          this.filterStatictics();
          this.fetchProducts();
          this.fetchReports();
          this.fetchAttachment();
        } else {
          this.filterStatictics();
        }
      });
    });
  };

  fetchFFMData = () => {
    if(this.state.ffm && this.state.ffm.org && this.state.ffm.dest){
      if(this.state.ffm.org === cookies.get('station_code')){
        this.setState({ ffmData: this.state.ffm?.out_ffm || {} });
      } else if(this.state.ffm.dest === cookies.get('station_code')){
        this.setState({ ffmData: this.state.ffm?.in_ffm || {} });
      }
    }
  };

  disableButtons = (type) => {
    let {
      cont_item,
      flightData
    } = this.state;
    let statflag = false;
    let reportLogs = this.state.reportLogs || [];
    let userRole = cookies.get('role');
    if (reportLogs.length > 0) {
      console.log('reportLogs greater');
      let found = false;
      reportLogs.map((r) => {
        if (r.type === this.state.cont_item) {
          found = true;
          if (type === 'Save') {
            if(r.status === 'Submitted' || r.status === null || r.status === 'Reopen' || r.status === 'Rejected'){
              statflag = false;
            } else {
              statflag = true;
            }
          } else if (type === 'Submitted') {
            if (this.state.statRows.length > 0) {
              // check if records with Manual exist
              let manual = this.state.statRows.filter((r) => r.applied_on.includes('Manual'));
              manual = manual.filter((r) => r.roles && r.roles.length > 0 && 
                (((r.roles[0] === 'Accounting' || r.roles[0] === 'Senior Accounting') && (userRole === 'Accounting' || userRole === 'Senior Accounting'))
                  || ((r.roles[0] === 'Office Agent' || r.roles[0] === 'Senior Office Agent') && (userRole === 'Office Agent' || userRole === 'Senior Office Agent'))
                  || ((r.roles[0] === 'Warehouse Agent' || r.roles[0] === 'Senior Warehouse Agent') && (userRole === 'Warehouse Agent' || userRole === 'Senior Warehouse Agent'))
                  || r.roles[0] === userRole
                )
              );
              let manualEntry = manual.every((item) => item.quantity !== null && item.quantity !== undefined && item.quantity !== '') ? true : false;
              if (manual) {
                if (((userRole === 'Accounting' || userRole === 'Senior Accounting') && manualEntry) || manualEntry) {
                  statflag = false;
                } else {
                  statflag = true;
                }
              }
            } else {
              statflag = true;
            }
          } else if (type === 'Approved' && (userRole === 'Accounting' || userRole === 'Senior Accounting' || userRole === 'System Admin' || userRole === 'Office Agent' || userRole === 'Senior Office Agent')) {
            // if status is Finalized, set flag to true
            if (r.status === 'Finalized' && (userRole === 'Accounting' || userRole === 'Senior Accounting')) {
              statflag = false;
            } else if (r.status === 'Pending' && userRole === 'Airline Rep') {
              statflag = false;
            } else if ((r.status === 'Finalized' || r.status === 'Pending' ) && userRole === 'System Admin' ) {
              statflag = false;
            } else {
              statflag = true;
            }
          } else if ( type === 'Reopen' && (userRole === 'Supervisor' || userRole === 'System Admin')){
            if (r.status === 'Finalized' || r.status === 'Approved' || r.status === 'Pending') {
              statflag = false;
            } else {
              statflag = true;
            }
          } else {
            statflag = true;
          }
        }
      });
      if (!found && type === 'Save') {
        if (!(cont_item && flightData[0] && flightData[0].contracts[cont_item] && flightData[0].contracts[cont_item].length > 0)) {
          statflag = true;
        } else {
          statflag = false;
        }
      } else if (!found && (type === 'Finalized' || type === 'Reopen')) {
        if (this.state.statRows.length > 0) {
          // check if records with Manual exist
          let manual = this.state.statRows.find((r) => r.applied_on.includes('Manual'));
          // if manual found set to true
          if (manual) {
            statflag = true;
          } else {
            statflag = false;
          }
        } else {
          statflag = true;
        }
      }
    } else {
      if (type === 'Save') {
        if (userRole === 'Office Agent' || userRole === 'Senior Office Agent' || userRole === 'System Admin' || userRole === 'Accounting' || userRole === 'Senior Accounting') {
          if (!(cont_item && flightData[0] && flightData[0].contracts[cont_item] && flightData[0].contracts[cont_item].length > 0)) {
            statflag = true;
          } else {
            statflag = false;
          }
        } else {
          statflag = true;
        }
      } else if (type === 'Submitted') {
        if (this.state.statRows.length > 0) {
          // check if records with Manual exist
          let manual = this.state.statRows.filter((r) => r.applied_on.includes('Manual') && r.roles && r.roles.length > 0 && (r.roles[0] !== 'Accounting' || r.roles[0] !== 'Senior Accounting'));
          let manualEntry = manual.every((item) => item.quantity !== null && item.quantity !== undefined && item.quantity !== '') ? true : false;
          if (manual && manual.length > 0) {
            if ((JSON.stringify(this.state.availableArray) === JSON.stringify(this.state.rowSelectionModel) && manualEntry)) {
              statflag = false;
            } else {
              statflag = true;
            }
          } else {
            statflag = false;
          }
        } else {
          if (JSON.stringify(this.state.availableArray) === JSON.stringify(this.state.rowSelectionModel)) {
            statflag = false;
          } else {
            statflag = true;
          }
        }

      } else {
        statflag = true;
      }
    }
    console.log('type', type);
    console.log('statflag', statflag);
    

    // get filteredData by role
    let filteredData = this.state.filteredData.filter((r) => (r.role === cookies.get('role')
    || (r.role === 'Accounting' || r.role === 'Senior Accounting') && (cookies.get('role') === 'Accounting' || cookies.get('role') === 'Senior Accounting')
    || (r.role === 'Office Agent' || r.role === 'Senior Office Agent') && (cookies.get('role') === 'Office Agent' || cookies.get('role') === 'Senior Office Agent')
    || (r.role === 'Warehouse Agent' || r.role === 'Senior Warehouse Agent') && (cookies.get('role') === 'Warehouse Agent' || cookies.get('role') === 'Senior Warehouse Agent')
    ) && r.flight_checklist && r.flight_checklist.length > 0 &&r.flight_checklist[r.flight_checklist.length-1].checked === false
    );
    console.log('filteredData', filteredData);
    if(statflag){
      return statflag;
    } else {
      if((type === 'Submitted' || type === 'Finalized') && filteredData.length > 0){
        return true;
      } else {
        return false;
      }
    }
  };

  fetchReports = () => {
    http.get(`/checklist/${this.state.flightId}/${this.props.flight?.airlineId}`)
      .then(response => {
        const { data } = response;
        if (data.status === 'success') {
          this.setState({ checklist: data.data }, () => {
            this.filterChecklist();
          });

        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  filterChecklist = () => {
    let filteredData = [];
    if ((this.state.ffm.org === cookies.get('station_code') )|| (this.props.flight?.org === cookies.get('station_code')) && this.state.cont_item) {
      filteredData = this.state.checklist.filter((n) => 
        (n.report_type === 'Outbound Flight Report' || n.report_type === 'All' || n.report_type === 'Flight Report') )
        .filter((n) => (n?.contract_types === this.state.cont_item));
    }
    if ((this.state.ffm.dest === cookies.get('station_code')) || (this.props.flight?.dest === cookies.get('station_code')) && this.state.cont_item) {
      filteredData = this.state.checklist.filter((n) => (n.report_type === 'Inbound Flight Report' || n.report_type === 'All' || n.report_type === 'Flight Report'))
        .filter((n) => (n?.contract_types === this.state.cont_item));
    }
    let rowSelectionModel = [];
    if(filteredData.length > 0){
      filteredData.map((c) => { 
        if(c?.flight_checklist.length > 0) {
          let length = c?.flight_checklist?.length - 1;
          c?.flight_checklist[length]?.checked === true ? rowSelectionModel.push(c.id) : [];
          this.setState(prev => ({
            checklistRemarks: {
              ...prev.checklistRemarks,
              [c?.flight_checklist[length]?.id]: { remark: c?.flight_checklist[length]?.remark, }
            },
          }));
        }

        if(
          (c.role === 'Warehouse Agent' || c.role === 'Senior Warehouse Agent') && (cookies.get('role') === 'Warehouse Agent' || cookies.get('role') === 'Senior Warehouse Agent')
          || (c.role === 'Office Agent' || c.role === 'Senior Office Agent') && (cookies.get('role') === 'Office Agent' || cookies.get('role') === 'Senior Office Agent')
          || (c.role === 'Accounting' || c.role === 'Senior Accounting') && (cookies.get('role') === 'Accounting' || cookies.get('role') === 'Senior Accounting')
        ){
          c.isSelectable = true;
        }
        else if(c.role == cookies.get('role')){
          c.isSelectable = true;
        }
        else if(c.role != cookies.get('role')){
          c.isSelectable = false;
        }
      }
      );}
    this.setState({
      rowSelectionModel: rowSelectionModel,
      filteredData: filteredData
    }, () => {
      if(this.state.filteredData) {
        let isSelectableData = cloneDeep(this.state.filteredData);
        let availableArray = [];
        let selectableArray = [];

        isSelectableData.map((arr) => availableArray.push(arr.id));
        let isSelectableDataArr = isSelectableData.filter((r) => r.isSelectable === true);
        isSelectableDataArr.map((arr) => selectableArray.push(arr.id));
        
        this.setState({
          selectableArray: selectableArray,
          availableArray: availableArray 
        });
      }
      if(this.props.printFunction) {
        this.fetchHistory();
        this.props.printFunction(this.state.filteredData, 'flightReport');
      }
    });
  };

  fetchHistory = () => {
    this.setState({ loadingHistory: false });
    http.get('change_log?type=Flight&flightId=' + this.state.flightId)
      .then(({ data }) => {
        let dataClone = cloneDeep(data.data);
        dataClone.map((row) => {
          let operation_desc = '';
          let bgColor = '';
          if (row.type == 'Attachment') {
            if (row.operation == 'ADD') {
              operation_desc = 'Added ' + row.new_details?.name + ' Attachment';
            } else if (row.operation == 'DELETE') {
              operation_desc = 'Deleted ' + row.prev_details?.name + ' Attachment';
            }
          } else if (row.type == 'Checklist') {
            if (typeof row.new_details?.check == 'boolean') {
              operation_desc = row.operation + ' -> ' + (row.new_details?.check ? 'Checked' : 'Unchecked');
            } else {
              operation_desc = row.operation + ' -> remark -> ' + row.new_details?.remark;
            }
          } else if (row.type == 'Statistics') {
            if (row.new_details.length > 0) {
              const prev_details = Object.values(row.prev_details);
              const new_details = row.new_details;
              const isEqual = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);
              const item = new_details.find(obj1 => !prev_details.some(obj2 => isEqual(obj1, obj2)));
              item ? operation_desc = item.contractName + ' -> ' + item.name + ' -> ' + item.quantity : '';
            } else {
              operation_desc = row.operation;
            }
          } else if (row.type == 'Report') {
            operation_desc = row.new_details?.type + ' -> ' + row.operation + (row.new_details?.remark && ' -> ' + row.new_details?.remark);
          } else if (row.type == 'Discrepancy') {
            console.log('row', row);
            let operation = '';
            switch (row.operation?.toLowerCase()) {
              case 'add discrepancy':
                operation = 'Discrepancy Added';
                break;
              case 'delete discrepancy':
                operation = 'Discrepancy Deleted';
                break;
              case 'update discrepancy':
                operation = 'Discrepancy Updated';
                break;
              default:
                operation = row.operation;
                break;
            }
            operation_desc = operation + ' -> ' + row.new_details?.awb_no;
          } else {
            operation_desc = row.operation;
          }
          row.operation_desc = operation_desc;
          row.bgColor = bgColor;
          return operation_desc;
        });

        let newData = dataClone.filter((item) => { return item.operation_desc !== ''; });

        this.setState({
          history_data: newData,
          loadingHistory: true
        }, () => {
          if (this.props.printFunction) {
            this.props.printFunction(this.state.history_data, 'history');
          }
        });
      });
  };

  filterStatictics = () => {
    if(this.state.cont_item ){
      if(this.state.flightData[0]?.contracts[this.state.cont_item]){
        this.state.flightData[0]?.contracts[this.state.cont_item]?.map((contract, contindex) => {
          contract.contractrows.map((r, i) => { 
            r.rowIndex = i;
            if(this.state.flightData[0]?.reports[0]?.report){
              this.state.flightData[0]?.reports[0]?.report.map((n) => {
                if(n.contractrowId === r.id){
                  r.note = n.note;
                  r.quantity = n?.applied_on?.includes('Manual') && (n.userId === undefined || n.date === undefined) ? null : n.quantity;
                  r.date = n.date;
                  r.userId = n.userId;
                  r.user = n.user; 
                  r.role = n.role;
                }
              });
            } else if( r.applied_on.includes('Manual') &&
            (r.quantity === 0 || r.userId === undefined || r.userId === null || r.userId === '' || r.date === undefined || r.date === null || r.date === '' || r.note === undefined || r.note === null || r.note === '' )){
              r.quantity = null;
            }
            if(this.state.contractList){
              this.state.contractList.map((c) => {
                if(c.id === r.id){
                  r.note = c.notes;
                }
              });
            }
          });
          this.setState({ 
            statRows : contract.contractrows,
            contindex
          }, () => {
            if(this.props.printFunction) {
              this.props.printFunction(this.state.statRows, 'statistics');
            }
          });
        }); 
      } else {
        this.setState({ 
          statRows: [],
          contindex: null,
        }, () => {
          if(this.props.printFunction) {
            this.props.printFunction(this.state.statRows, 'statistics');
          }
        });
      }
    } 
  };

  updateChecklist = async () => {
    let formdata = {
      id: this.state.productField.id,
      name: this.state.productField.name,
      airlineId: this.state.activeAirline.id === -1 ? 'ALL' : this.state.activeAirline?.id,
    };
    await http.put('/checklist/' + this.state.flightId, formdata, { headers: { ntoast: true } })
      .then(() => { });
  };

  handleRemarks = (e) => {
    this.setState({ remarks: e.target.value });
  };

  handleStats = (e, contindex, rowindex) => {
    // let name  = e.target.name;
    // let value = e.target.value;
    // let type = this.state.flightData.org === cookies.get('station_code') ? 'OUT' : 'IN';
    // this.setState(prevState => ({
    //   statManual: {
    //     ...prevState.statManual,
    //     type: type,
    //     [name]: value
    //   }
    // }));
    // let flightData = this.state.flightData;
    // e.target.name === 'note' ? 
    //   flightData[0].contracts[this.state.cont_item][contindex].contractrows[rowindex].note = e.target.value :
    //   flightData[0].contracts[this.state.cont_item][contindex].contractrows[rowindex].quantity = e.target.value;

    const { cont_item, flightData } = this.state;
    const { name, value } = e.target;
    let contractRow = flightData[0]?.contracts[cont_item][contindex].contractrows[rowindex];
    if(contractRow) {
      contractRow[name] = value;
      if(name === 'quantity'){
        contractRow.date = new Date().toISOString();
        contractRow.userId = cookies.get('user');
      }
    }
    this.setState({
      flightData: flightData,
      note:  ''
      // note:  flightData[0].contracts[this.state.cont_item][contindex].contractrows[rowindex].note 
    });
  };

  handleNotes = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
  };

  handleNoteBlur = () => {
    let { note, flightId, statRowData } = this.state;
    if(note.trim() === '') return;
    let formdata = { note: note };
    http.post(`/note/controw/${flightId}/${statRowData?.id}`, formdata, { headers: { ntoast: true } }).then(({ data }) => {
      if (data.status == 'success') {
        this.setState({
          note: '',
          popup: false 
        });
      }
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  handleReadMore = (index) => {
    this.setState((prevState) => ({
      showMoreNote: {
        ...prevState.showMoreNote,
        [index]: !prevState.showMoreNote[index]
      },
    }));
  };


  handleStatType = (e, key) => {
    this.setState({
      statType: e,
      cont_item: key
    }, () => {
      this.filterChecklist();
      this.filterStatictics();
    });
  };

  handleChecklistRemarks = async (e, data = {}, type) => {
    if (type == 'add') {
      let arrayLength = data?.flight_checklist?.length - 1;
      this.setState(prev => ({
        editRemark: true,
        flightChecklist: data,
        checklistRemarks: {
          ...prev.checklistRemarks,
          dataId: data.id,
          [data?.flight_checklist[arrayLength]?.id]: { remark: e.target.value, }
        },
        checklistId: data?.flight_checklist[arrayLength]?.id,
      }));
    }

  };

  setChecklistId = (data) => {
    let arrayLength = data?.flight_checklist?.length - 1;
    this.setState({ checklistId: data?.flight_checklist[arrayLength]?.id });
  };

  // handleCheckbox = async (e, id) => {
  //   let index = this.state.checklist.findIndex(x => x.id === id);
  //   if (index > -1) {
  //     this.setState(prevState => ({
  //       checkedChecklist: {
  //         ...prevState.checkedChecklist,
  //         [id]: e.target.checked
  //       },
  //       checklistIndex: index,
  //       checkboxId: id,
  //     }), () => this.fetchReports);
  //     await http.post(`/checklist/${this.state.flightId}/${id}`)
  //       .then((data) => {
  //         console.log(data, 'success');
  //       });
  //   }
  // };
  checkboxSelectionHandler = async (checked=[], unchecked=[]) => {
    let checkedId = checked[0];
    let uncheckedId = unchecked[0];
    this.setState({
      onChangeCheckboxId: {
        checkedId,
        uncheckedId
      } 
    });
    let id = checkedId ? checkedId : uncheckedId; 
    await http.post(`/checklist/${this.state.flightId}/${id}`, '', { headers: { ntoast: true } })
      .then((data) => {
        console.log(data, 'success');
      });
  };

  onRowsSelectionHandler = (ids) => {
    this.setState({ rowSelectionModel: ids });
  };

  disabledChecklistRemarks = (row) => { 
    let flightChecklist = row?.flight_checklist && row?.flight_checklist[row?.flight_checklist.length - 1];
    let disabled = false;
    if(!flightChecklist?.checked || row?.role !== cookies.get('role')) {
      disabled = true;
    }
    if(row?.role === cookies.get('role') && this.state.onChangeCheckboxId){
      if (row.id === this.state.onChangeCheckboxId.checkedId) {
        disabled = false;
      }
    }
    if(flightChecklist?.checked){
      if(flightChecklist?.user) {
        flightChecklist?.user?.first_name + ' ' + flightChecklist?.user?.last_name === cookies.get('name') ? disabled = false : disabled = true;
      }
    }
    return disabled;
  }; 

  checkAccessArray = (rowrole, userrole) => {
    let flag = false;
    let foundroles = statisticsRoles.find((statRole) => statRole.role === rowrole);
    if(foundroles?.access.includes(userrole)){ 
      flag = true; 
    }
    return flag;
  };

  isRowSelectable = (row) => {
    let isSelectable = false;
    let flightChecklist = row?.flight_checklist && row?.flight_checklist[row?.flight_checklist.length - 1];
    if(row.role == cookies.get('role') || this.checkAccessArray(row.role, cookies.get('role'))){
      isSelectable = true;
      row.isRedCheckist = true;
    }
    if(flightChecklist?.checked){
      if(flightChecklist?.user) {
        flightChecklist?.user?.first_name + ' ' + flightChecklist?.user?.last_name === cookies.get('name') ? 
          (isSelectable = true, row.isRedCheckist = true)
          : (isSelectable = false, row.isRedCheckist = false);
      }
    }
    // let save = this.disableButtons('Save');
    // if(save){
    //   isSelectable = false;
    // }
    return isSelectable;
  };

  editRemark = (data) => {
    this.setState({ checklistField: { ...data, }, });
  };

  saveRemark = () => { 
    let formdata = { remark: this.state.checklistRemarks[this.state.checklistId]?.remark || '' };
    if(this.state.checklistId) {
      http.put('/checklist/' + this.state.checklistId, formdata, { headers: { ntoast: true } }).then(({ data }) => {
        if (data.status == 'success') {
          console.log(data, 'data');
        }
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  };

  handleStatistic = () => {
    http.get(`/contract?airline=${this.props.flight?.airlineId}&flight=${this.props.flight?.id}`,).then(({ data }) => {
      if (data.status == 'success') {
        this.setState({ statList: [] }, () => { });
        let statData = [];
        let statListData = {};

        data.data.map(c => {
          c.contractrows.map(r => {
            if (r.applied_on && r.applied_on.includes('Day', 'Month')) {
              //ignore
            } else {
              statData.push(r);
              statListData.applied_on = statData.map((item) => {
                let index = this.state.productsList.findIndex(x => x.id === item.productId);
                return item.applied_on + (index > -1 ? ' | ' + this.state.productsList[index]?.name : ' ');
              });
              statListData.applied_on = statListData.applied_on.filter((value, index, self) => {
                return self.indexOf(value) === index;
              });
              return statData;
            }
          });
        });

        this.setState({
          contractList: data.data[0]?.contractrows,
          statList: statData,
          statListObj: {
            ...this.state.statListObj,
            statData: statData,
            statListData: statListData,
          }
        }, () => { this.filterStatictics (); });
      }
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  };
  handleStatisticPost = (action = null) => {
    console.log('handleStatisticPost');
    let flightData = this.state.flightData;
    let statManual = [];
    let contracts = flightData[0].contracts;
    if (contracts && typeof contracts === 'object') {
      for (const [contractName, contractArray] of Object.entries(contracts)) {
        if (Array.isArray(contractArray)) {
          contractArray.map((r) => {
            if (r.type === this.state.cont_item) {
              r.contractrows.map((cr) => {
                let obj = {};
                // push all manual and edited Auto records.
                //contractRow.userId
                if (cr.applied_on && cr.applied_on.includes('Manual') || cr.userId) {
                  obj = {
                    contractName,
                    name: cr.description+ ' - ' + cr.applied_on,
                    contractrowId: cr.id,
                    quantity: cr.quantity,
                    note: cr.note,
                    date: cr.date,
                    userId: cr.userId,
                    user: cr.user,
                    role: cr?.roles && cr.roles[0] ? cr.roles[0] : '-',
                  };
                  statManual.push(obj);
                }
              });
            }
          });
        }
      }
    }
    let type = this.state.cont_item;
    let formdata = {
      report: statManual,
      type: type,
    };
    http.post(`/statistics/${this.state.flightId}`, formdata, { headers: { ntoast: true } }).then(({ data }) => {
      if (data.status == 'success') {
        this.setState({
          popup: false,
          anchorEl: null,
        },()=>{
          if(action === 'Finalized'){
            this.handleChange('Finalized');
          } else if(action === 'Submitted'){
            this.handleChange('Submitted', true);
          }
        });
      }
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  handleChange = (status, repeat = false, anchorEl) => {
    let proceed = true;
    let submitproceed = true;
    if(status === 'Submitted'){
      //check if All checklist are performed before finalizing
      // let checkedAll = true;
      let checklists  = this.state.filteredData;
      checklists.map((c) => {
        let length = c?.flight_checklist?.length - 1;
        console.log('checklists length',length);
        if(length > -1 && !c?.flight_checklist[length]?.checked && c?.role === cookies.get('role')){
          submitproceed = false;
        } else if(length === -1){
          submitproceed = false;
        }
      });
    }
    if(status === 'Finalized'){
      submitproceed = false;
      //check if All checklist are performed before finalizing
      // let checkedAll = true;
      let checklists  = this.state.filteredData;
      checklists.map((c) => {
        let length = c?.flight_checklist?.length - 1;
        console.log('length',length);
        if(length > -1 && !c?.flight_checklist[length]?.checked){
          proceed = false;
        } else if(length === -1){
          proceed = false;
        }
      });
    }
    if(proceed || submitproceed){
      // e.preventDefault();
      let milestone = '';
      let history = {
        milestone: `Flight Report ${milestone}`,
        // status: `Flight Report ${status}`,
        status: status,
        remark: this.state.remarks,
      };
      if (this.state.org === cookies.get('station_code')) {
        history.out_status = status;
      } else {
        history.in_status = status;
      }
      this.setState({ history: history });

      let formdata = {};
      if (this.state.selectedOption) {
        if(status === 'Comment') {
          formdata = { 
            status: 'Comment',
            remark: this.state.remarks 
          }; 
        }
        else formdata = { 
          status: this.state.selectedOption === 'comment' ? 'Comment' : this.state.selectedOption === 'ok' ? 'Approved' : 'Rejected',
          remark: this.state.remarks 
        };
      }
      else formdata = {
        status: status,
        remark: this.state.remarks
      };

      //get logid
      let logId = null;
      let reportLogs = this.state.reportLogs || [];
      if (reportLogs.length > 0) {
        reportLogs.map((r) => {
          if (r.type === this.state.cont_item) {
            logId = r.id;
          }
        });
      }
      if (logId) {
        http.put(`/report/${logId}`, formdata, { headers: { ntoast: true } }).then(({ data }) => {
          if (data.status == 'success') {
            this.setState({ 
              remarks: '',
              status: '',
              anchorEl: null,
              anchorEl2: null,
              anchorEl3: null,
              selectedOption: null,
              remark: '',
            }, () => {
              this.fetchFlight();
              //  if no manual records exist or all manual records are filled. open popup
              if(status === 'Submitted'){
                this.setState({ redChecklist: false });
                let manual = this.state.statRows.filter((r) => r.applied_on.includes('Manual'));
                if((!manual || manual.length === 0) && proceed){
                  this.openPopup('finalize', anchorEl, null, null, null);
                } else {
                  let manualEntry = manual.every((item) => item.quantity !== null && item.quantity !== undefined && item.quantity !== '' && item.quantity !== 0 ) ? true : false;
                  if(manualEntry && proceed){
                    this.openPopup('finalize', anchorEl, null, null, null);
                  }
                  else this.handleStatusOpen(true, status);
                }
              }
            });
          }
        }
        ).catch(error => {
          console.error('Error fetching data:', error);
        });
      } else {
        if(this.state.statRows.length > 0){
          // check if records with Auto exist
          let manual = null;
          // get all manual records where role is not 'Accounting'
          if(status === 'Submitted')
            manual = this.state.statRows.find((r) => r.applied_on.includes('Manual') && r.roles && r.roles.length > 0 && (r.roles[0]  !== 'Accounting' || r.roles[0] !== 'Senior Accounting'));
          else 
            manual = this.state.statRows.find((r) => r.applied_on.includes('Manual'));
          console.log('manual',manual);
          // if manual not found 
          if( !manual && (!repeat || repeat == false)){
            this.handleStatisticPost(status);
          } else {
            //do nothing
          }
        }
      }
      this.handleStatistic();
    } else {
      this.setState({ redChecklist: true });
    }
  };

  fetchAttachment = () => {
    http.get(`/attachments/${this.state.flightId}`).then(({ data }) => {
      if (data.status == 'success') {
        let sortedAttachments = data.data.sort((a, b) => new Date(b.created) - new Date(a.created));
        this.setState({ attachment: sortedAttachments });
      }
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  handleOpen = (type) => {
    this.setState({ docmodal: type, });
  };

  handleStatusOpen = (type, msg) => {
    this.setState({
      statusModal: type,
      statusMessage: msg
    });
  };

  handleStatusClose = () => {
    this.setState({
      statusModal: false,
      statusMessage: ''
    });
  };

  onChangeDoc = (e) => {
    let value = e.target.value;
    this.setState(prevState => ({
      attachmentFields: {
        ...prevState.attachmentFields,
        [e.target.name]: e.target.name == 'file' ? e.target.files[0] : value
      }
    }));
  };

  addDoc = (e) => {
    e.preventDefault();
    let formdata = {
      ...this.state.attachmentFields,
      remark: '',
      id: this.state.flightId
    };
    http.post(`/flight/doc/${this.state.flightId}`, formdata, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => {
      if (data.status == 'success') {
        this.setState({
          docmodal: false,
          attachmentFields: {},
        }, () => { });
      }
    });
  };

  deleteAttachment = async (id) => {
    await http.delete(`/flight/doc/${id}`).then(({ data }) => {
      if (data.status == 'success') {
        this.props.setDelete({
          modal: false,
          id: false,
          anchorEl: false,
          onConfirm: () => { },
        });
      }
    });
  };

  handleDelete = (id, anchorEl) => {
    this.props.setDelete({
      modal: true,
      id,
      anchorEl,
      onConfirm: () => this.deleteAttachment(id),
    });
  };

  openPopup = (popup, anchorEl, contindex, rowIndex, row) => {
    if (popup === 'notes') {
      let noteList = this.state.contractList?.find((r) => r.id === row.id);
      let filteredNotes = noteList?.notes?.filter((n) => { return n.note != null; });
      row.notes = filteredNotes || [];
    }
    this.setState({ 
      popup,
      anchorEl: anchorEl,
      // note: row?.note,
      statRowData: {
        note: row?.note,  
        ...row,
        // contindex,
        // rowIndex
      }
    });
  };

  closePopup = () => {
    this.setState({
      popup: false,
      anchorEl: null,
    });
  };

  handleClick = (anchorEl, type) => {
    console.log('handleClick', type);
    if(type === 2){
      this.setState({ 
        anchorEl2: anchorEl,
        remarks: '',
      });
    } else if(type === 3){
      this.setState({ 
        anchorEl3: anchorEl,
        remarks: '',
      });
    }
  };

  handleClose = () => {
    this.setState({
      anchorEl2: null,
      anchorEl3: null,
      selectedOption: null,
      remarks: '',
    });
  };

  filterReportLogs = () => {
    let reportLogs = [...this.state.reportLogs];
    let filteredLogs = [];
    if(reportLogs.length > 0){
      filteredLogs = reportLogs[0].log.filter((r) => r.remark);
    }
    if(reportLogs[0]?.log) {
      reportLogs[0].log = filteredLogs;
    }
    this.setState({ filteredReportLogs: reportLogs });
  };

  selectOption = (value) => {
    this.setState({ selectedOption: value });
  };

  setRemark = (value) => {
    this.setState({ remarks: value });
  };

  UpdateFlightReport = () => {
    let option = this.state.selectedOption;
    if(option === 'ok') {
      this.handleChange('Approved');
    }
    if(option === 'comment') {
      this.handleChange('Comment');
    }
    if(option === 'not_ok') {
      this.handleChange('Rejected');
    }
  };

  render() {
    return (
      <>
        <FlightReport
          flightId={this.state.flightId}
          manifestSummary={this.props.manifestSummary}
          userContractTypes={this.state.userContractTypes}
          productsList={this.props.productsList}
          handleChange={this.handleChange}
          handleRemarks={this.handleRemarks}
          remark={this.state.remarks}
          flightData={this.state.flightData}
          disableButtons={this.disableButtons}
          checklist={this.state.checklist}
          setChecklistId={this.setChecklistId}
          handleChecklistRemarks={this.handleChecklistRemarks}
          checklistRemarks={this.state.checklistRemarks}
          checkedChecklist={this.state.checkedChecklist}
          checklistIndex={this.state.checklistIndex}
          saveRemark={this.saveRemark}
          statList={this.state.statList}
          handleStats={this.handleStats}
          statManual={this.state.statManual}
          handleStatisticPost={this.handleStatisticPost}
          attachment={this.state.attachment}
          ffmData={this.state.ffmData}
          editRemark={this.editRemark}
          statListObj={this.state.statListObj}
          reportLogs={this.state.reportLogs}
          nil={this.state.nil}

          handleOpen={this.handleOpen}
          onClose={this.onClose}
          modal={this.state.docmodal}
          fields={this.state.attachmentFields}
          errors={this.state.docErrors}
          onChange={this.onChangeDoc}
          onSubmit={this.addDoc}
          handleDelete={this.handleDelete}

          statType={this.state.statType}
          cont_item={this.state.cont_item}
          handleStatType={this.handleStatType}

          statusModal={this.state.statusModal}
          statusMessage={this.state.statusMessage}
          handleStatusOpen={this.handleStatusOpen}
          handleStatusClose={this.handleStatusClose}

          popup={this.state.popup}
          anchorEl={this.state.anchorEl}
          openPopup={this.openPopup}
          closePopup={this.closePopup}

          showReportModal={this.props.showReportModal}
          filteredData={this.state.filteredData}
          onRowsSelectionHandler={this.onRowsSelectionHandler}
          rowSelectionModel={this.state.rowSelectionModel}
          checkboxSelectionHandler={this.checkboxSelectionHandler}
          disabledChecklistRemarks={this.disabledChecklistRemarks}
          statRows={this.state.statRows}
          contindex={this.state.contindex}
          rowindex={this.state.rowindex}
          checklistId={this.state.checklistId}
          isRowSelectable={this.isRowSelectable}
          note={this.state.note}
          statRowData={this.state.statRowData}
          history_data={this.state.history_data}
          printFunction={this.props.printFunction}
          redChecklist={this.state.redChecklist}
          handleOpenDownload={this.props.handleOpen}

          handleNoteBlur={this.handleNoteBlur}
          handleNotes={this.handleNotes}
          handleReadMore={this.handleReadMore}
          showMoreNote={this.state.showMoreNote}
          contractList={this.state.contractList}
          fetchHistory={this.fetchHistory}
          ctype={this.state.ctype}

          // handleReview={this.handleReview}
          // handleReviewClose={this.handleReviewClose}
          selectedOption={this.state.selectedOption}
          selectOption={this.selectOption}
          setRemark={this.setRemark}
          updateFlightReport={this.UpdateFlightReport}
          filteredReportLogs={this.state.filteredReportLogs}
          anchorEl2={this.state.anchorEl2}
          anchorEl3={this.state.anchorEl3}
          popup2={this.state.popup2}
          handleClick={this.handleClick}
          handleClose ={this.handleClose}
          refBtn={this.refBtn}
          refROBrn={this.refROBrn}
          filterReportLogs={this.filterReportLogs}
          lastLog={this.state.lastLog}
          nil_manifest={this.props.nil}
        />

        <ConfirmModalContainer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    flight: state.flight,
    ffm: state.ffm,
    // ctype: state.ctype,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { setDelete: (data) => dispatch(setDeleteAction(data)) };
};

const MemoizedFlightReportContainer = React.memo(connect(mapStateToProps, mapDispatchToProps)(FlightReportContainer));

export default connect(mapStateToProps, mapDispatchToProps)(MemoizedFlightReportContainer);
