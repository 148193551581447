import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import headerStyles from './header.module.css';
import styles from '../../custom/style.module.css';
import { menulist } from '../../custom/constants';
import { openedMixin } from './headerconstants';
import { Link } from 'react-router-dom';
import cookies from 'utils/cookies';
import { ExitToApp, Info } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import ContractExpiry from './ContractExpiry';

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflow: 'hidden',
  width: 0,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 2),
  height: '210px',
  marginBottom: '10px',
  flexDirection: 'column',
  ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open, sidebarwidth }) => ({
    width: sidebarwidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    margin: 0,
    marginTop: `calc(-${theme.spacing(3)} + 1px)`,
    ...(open && {
      ...openedMixin(theme, sidebarwidth),
      '& .MuiDrawer-paper': openedMixin(theme, sidebarwidth),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
  }),
);

const ListItemStyle = () => ({
  display: 'flex',
  alignItems: 'center',
  height: '75px',
  color: 'var(--grey)',
  borderBottom: '1px solid #c9c9c93d',
  px: 3
});

const ListStyle = () => ({
  height: 'calc(100vh - 280px - var(--splayout) - var(--headerheight))',
  overflow: 'auto',
});

const SideBar = (props) => {
  const { sidebarwidth, open, theme, handleDrawerOpen, contractExpiryIcon } = props;

  return (
    <div className={headerStyles.headerSidbarWrapper}>
      <Box sx={{
        display: 'flex',
        position: 'fixed',
        zIndex: 100
      }}>
        <CssBaseline />
        <div className={open ? headerStyles.overlay : ''} onClick={handleDrawerOpen}></div>
        <Drawer variant='permanent' anchor='left' open={open} sidebarwidth={sidebarwidth} className={headerStyles.sidebar}>
          <DrawerHeader>
            <Typography gutterBottom variant='h6' component='h6' color={'white'}>{cookies.get('name')}</Typography>
            <Typography variant='subtitle1' component='span' color={'white'}>{cookies.get('role')}</Typography>
          </DrawerHeader>
          <button className={ headerStyles.signOutBtn } onClick={ props.signout } >           
            <ExitToApp className={headerStyles.signOut} />
                Sign Out 
          </button>
          <Divider />
          <Box className={headerStyles.menuTitle}>MENU</Box>
          <List sx={ListStyle} className={headerStyles.menuList}>
            {menulist.map((menu) => {
              return menu.roles.indexOf(String(cookies.get('role'))) > '-1' ? (
                <React.Fragment key={menu.key}>
                  <ListItem
                    as={Link}
                    to={menu.link}
                    onClick={handleDrawerOpen}
                    disablePadding
                    sx={ListItemStyle}
                    style={{
                      display:
                        process.env.REACT_APP_STAGE === 'prod' &&
                        !menu.env.includes('production')
                          ? 'none'
                          : 'flex',
                    }}
                  >
                    <ListItemButton
                      sx={{
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                        color: 'white',
                      }}
                      disableRipple
                    >
                      <ListItemIcon
                        className={`${headerStyles.menuIcon}`}
                        sx={{
                          minWidth: '24px',
                          width: '24px',
                          marginRight: theme.spacing(8),
                        }}
                      >
                        {menu.icon}
                      </ListItemIcon>
                      <Typography variant='subtitle2' component='span' className={`${styles.positionRelative} ${styles.w100}`}>
                        { contractExpiryIcon && menu.title === 'Admin' ? <span>{menu.title} 
                          <Tooltip title='View Expiring Contracts'>
                            <IconButton aria-label='contract info' size='small' onClick={props.handleContractExpiry} className={`${headerStyles.contractIcon} ${styles.redSvg}`}>
                              <Info fontSize='inherit' />
                            </IconButton>
                          </Tooltip>
                        </span> : menu.title}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                </React.Fragment>
              ) : null;
            })}
          </List>
        </Drawer>
      </Box>
      <ContractExpiry contractExpiry={ props.contractExpiry} contract={props.contract} onClose={props.onClose} />
    </div>
  );
};

export default SideBar;
