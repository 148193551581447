import React from 'react';
import {
  IconButton,
  Grid,
  InputAdornment,
  Checkbox,
  RadioGroup,
  Radio,
  Typography,
  Popover,
  Box,
  Stack,
} from '@mui/material';
import {
  StyledCard, StyledCardTitle,
  CustomInputLabel,
  CustomDatePicker,
  CustomCheckbox,
  CustomRadio,
  CustomSearch,
  SectionDivider,
  InputProps,
} from '../../../custom/components';
import styles from '../../../custom/style.module.css';
import docStyle from './docs.module.css';
import { AddIcon, DeleteIcon } from '../../../custom/constants';
import { CheckCircleOutline, InfoOutlined, Search } from '@mui/icons-material';
import moment from 'moment';
import dayjs from 'dayjs';
import { dateTolocale } from 'utils/converter';
import cookies from 'utils/cookies';
import PendingDoc from './PendingDoc';
// import moment from 'moment';

const docStreamListComponent = (props) => {
  const docs = props.docs.map((doc, i) => {
    let name = doc.name || 'Unknown';
    if (doc.type == 'AWB') {
      if(doc.awb)
        name = `${doc?.awb?.code}-${doc?.awb?.number}`;
      else
        name = doc.data?.awb_no ? doc.data?.awb_no : name;
    } else if (doc.type == 'FFM') {
      if (doc.flight)
        name = `${doc.flight?.airline?.code}${doc?.flight?.flight_no}/${moment(doc?.flight?.flight_date).format('DDMMMYY').toUpperCase()}`;
      else {
        name = doc.data?.flight_no ? doc.data?.flight_no?.replace(' ', '') + '/' + moment(doc?.data?.flight_date).format('DDMMMYY').toUpperCase() : doc.name;
      }
    }
    if (doc.request && (!doc.request_status || !name)) {
      if (doc.type == 'AWB') {
        name = 'Airway Bill';
      } else if (doc.type == 'FFM') {
        name = 'Flight Manifest';
      } else if (doc.type == 'OTHER') {
        name = 'Other Document';
      }
    }

    const getSatusColor = (doc, verified, received, requested ) => {
      if (doc.verified && doc.request_status) {
        return verified;
      } else if (doc.request_status) {
        return received;
      } else {
        return requested;
      }
    };

    return (
      <Grid container key={i} justifyContent='space-between' spacing={0} className={`${docStyle.px4} ${styles.colorPrimary}`}>
        <RadioGroup aria-labelledby='documents' name='user-status' defaultValue='all' row className={`${styles.fSpaceBtw} ${styles.w100}`} onChange={() => props.selectDoc(doc)} value={props.docId} >
          <Grid item container>
            <Grid item sx={{ width: '30px' }}>
              <CustomRadio control={<Radio size='small' inputProps={{ 'aria-label': 'documents' }} />} value={doc.id} label='' />
            </Grid>
            <Grid item xs container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant='subtitle2' component='div' fontWeight={900} flexWrap={'nowrap'}>
                  {name}
                </Typography>
                {/* {!doc.verified && doc.request && doc.request_status && <Typography variant='caption' color='text.error' fontWeight={'700'} lineHeight='normal'> Recivied </Typography>}
                {!doc.verified && doc.request && !doc.request_status && <Typography variant='caption' color='red' fontWeight={'700'} lineHeight='normal'> Pending </Typography>}
                {(!doc.request || doc.verified) && <Typography variant='caption' color='text.secondary' lineHeight='normal'> {dateTolocale(doc.created, 'DD MMM YY HH:mm')} </Typography> } */}
                <Typography variant='caption' color='text.secondary' lineHeight='normal'> {dateTolocale(doc.created, 'DD MMM YY HH:mm')} </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Stack direction={'column'} height={'100%'}>
                  <Typography variant='subtitle2' component='div' fontWeight={900}>
                    {doc.awb ? `${doc.awb.pcs}` : <span className={styles.vHidden}>-</span>}
                  </Typography>
                  <Typography variant='caption' color='text.secondary' lineHeight='normal' flexGrow={1} alignItems={'flex-end'} display={'flex'}>
                    {doc.numPages} Pages
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography variant='subtitle2' component='div' fontWeight={900}>
                  {doc.awb ? `${doc.awb.wgt}K` : <span className={styles.vHidden}>-</span>}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              {doc.verified ? <CheckCircleOutline color='success' /> : (!doc.request || (doc.request && !doc.request_status) ? <DeleteIcon className={docStyle.delIcon} onClick={(e) => props.handleDelete(doc.id, e.target)} /> : '')}
            </Grid>
            {doc.awb ? <Grid item xs={12}>
              <Typography variant='caption' color='text.secondary' lineHeight='normal' sx={{ paddingLeft: '30px' }}>
                {doc.awb.forwarder}
              </Typography>
            </Grid>
              : <span ></span>} 
            {doc.request && 
            <Grid item xs={12}>
              <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}
                p={'2px 24px 2px 54px'}
                m={'8px -24px -16px -24px'}
                bgcolor={getSatusColor(doc, '#2e7d3214', '#ff7e0d14', '#ff000014')}
                zIndex={1}
                position={'relative'}
                onClick={() => props.handleOpen('thirdPartyDoc', doc)}
                className={styles.pointer}
              >
                <Typography variant='caption' component='div' flexWrap={'nowrap'} color={'#000000bf'}>Request for doc from 3rd party</Typography>
                <small style={{
                  color: '#515151',
                  fontSize: '10px',
                  fontWeight: 700,
                  display: 'block',
                  marginLeft: 'auto',
                }}>STATUS :&nbsp;</small>
                <Typography variant='caption' component={'small'} fontWeight={700}
                  color={getSatusColor(doc, '#2e7d32', '#ff7e0d', 'error')}>
                  {/* {getSatusColor(doc, 'Doc Verified', 'Doc Received', 'Request Sent')} */}
                  {getSatusColor(doc, 'Verified', 'Received', 'Requested')}
                </Typography>
              </Stack>
            </Grid>
            }
            <Grid item xs={12}>
              <SectionDivider className={styles.my3} />
            </Grid>
          </Grid>
        </RadioGroup>
      </Grid>
    );

  });
  return (
    <div className={docStyle.h100}>
      <div className={styles.headerFieldContainer}>
        <CustomSearch
          {...InputProps}
          id='search'
          placeholder='Enter Awb Number'
          type='search'
          fullWidth
          InputProps={{
            disableUnderline: true,
            endAdornment: <InputAdornment position='end'>
              <IconButton
                size='small'
                aria-label='search'
                onClick={() => props.search(true)}
                edge='end'
              >
                <Search fontSize='inherit' />
              </IconButton>
            </InputAdornment>,
          }}
          InputLabelProps={{ shrink: false }}
          onChange={(e) => props.changeAwb(e, 'awb')}
          name='awb'
          value={props.searchOptions.awb}
          className={`${styles.brInput}`}
        // error={}
        // helperText={}
        />
      </div>

      <StyledCard className={docStyle.incomingDocH100}>
        <div className={docStyle.incomingDocHead}>
          <div className={`${styles.mb2} ${styles.fSpaceBtw}`}>
            <StyledCardTitle gutterBottom><span>Incoming Documents</span>
              <InfoOutlined color='inherit' fontSize='small' sx={{
                opacity: 0.4,
                marginBottom: '-4px'
              }} onClick={(e) => props.handlePopover(true, e.target)} className={`${styles.ml2} ${styles.pointer}`} /></StyledCardTitle>
            <AddIcon title='Add' onClick={() => props.handleOpen('addDoc')} />
          </div>
          <Grid container rowSpacing={1} columnSpacing={{
            xs: 2,
            lg: 2
          }}>
            <Grid item>
              <CustomInputLabel>From</CustomInputLabel>
              <CustomDatePicker
                slotProps={{
                  textField: {
                    ...InputProps,
                    fullWidth: true,
                    placeholder: 'From Date'
                  },
                }}
                format='DD MMM YY'
                name='start'
                // error={}
                value={dayjs(props.searchOptions.start)}
                // helperText={}
                onChange={(e) => props.changeAwb(e, 'start')}
                className={docStyle.datePicker}
              />
            </Grid>
            <Grid item>
              <CustomInputLabel>To</CustomInputLabel>
              <CustomDatePicker
                slotProps={{
                  textField: {
                    ...InputProps,
                    fullWidth: true,
                    placeholder: 'To Date'
                  },
                }}
                format='DD MMM YY'
                name='end'
                value={dayjs(props.searchOptions.end)}
                // // error={}
                // // helperText={}
                onChange={(e) => props.changeAwb(e, 'end')}
                className={docStyle.datePicker}
              />
            </Grid>
            <Grid item xs className={styles.mt3}>
              <CustomCheckbox control={<Checkbox size='small' />} classes={{ root: `${styles.mr0} ${docStyle.checkWrapper}` }}
                label={'Show Verified Docs'} value={props.searchOptions.verified} name='verified' onChange={(e) => props.changeAwb(e, 'verified')} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <RadioGroup aria-labelledby='doc-status' name='user-status' defaultValue='all' row className={`${styles.fSpaceBtw} ${docStyle.mdmt}`} value={props.searchOptions.identity} onChange={(e) => props.changeAwb(e, 'identity')}>
                <CustomRadio control={<Radio size='small' />} value='all' label='All ' />
                <CustomRadio control={<Radio size='small' />} value='unknown' label='Unknown' />
                <CustomRadio control={<Radio size='small' />} value='identified' label='Identified' />
              </RadioGroup>
            </Grid>
          </Grid>
        </div>
        <SectionDivider className={styles.my3} />
        <div className={docStyle.listDocBody}>
          {docs}
        </div>
      </StyledCard>

      <Popover
        open={props.attachment}
        anchorEl={props.anchorEl}
        onClose={() => props.handlePopover(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}>
        <Box>
          <Typography variant='body2' component={'div'} p={2}>Send documents to <b>{cookies.get('company_mail')}</b></Typography>
        </Box>
      </Popover>

      <PendingDoc
        modal={props.modal}
        modalData={props.modalData}
        modalDataErrors={props.modalDataErrors}
        onClose={props.onClose}
        handleEditDoc={props.handleEditDoc}
        closeEditDoc={props.closeEditDoc}
        isEditable={props.isEditable}
        onChange={props.onChange}
        onUpdateReq={props.onUpdateReq}
        handleReminder={props.handleReminder}
      />

    </div>
  );
};

export default (docStreamListComponent);
