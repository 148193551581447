import React from 'react';
import DocUpload from './DocUpload';
import http from 'utils/http';

class DocUploadContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: false,
      valid: false,
      fields: {},
      type: '',
      fieldsErrors: {},
      submit: false
    };
  }

  componentDidMount() {
    if (this.props.match.params.token) {
      this.validateDoc(this.props.match.params.token);
    } else {
      this.props.history.push('/login');
    }
  }

  validateDoc = (token) => {
    http.get('doc_req/' + token,).then(({ data }) => {
      if (data.data.id) {
        this.setState({
          valid: true,
          id: data.data.id,
          type: data.data.type
        });
      } else {
        this.props.history.push('/login');
      }
    }).catch(() => {
      this.props.history.push('/login');
    }
    );
  };

  onChange = (e) => {
    let value = e.target.value;
    this.setState({
      fields: {
        ...this.state.fields,
        [e.target.name]: e.target.name == 'file' ? e.target.files[0] : value
      }
    });
  };

  validateFields = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields['file']) {
      formIsValid = false;
      errors['file'] = 'Please select the document to upload';
    }

    this.setState({ fieldsErrors: errors });
    return formIsValid;
  };

  onSubmit = () => {
    if (this.validateFields()) {
      let formdata = { ...this.state.fields };
      http.put('/doc_req/'+this.state.id, formdata, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => {
        if (data) {
          this.setState({ submit: true });
          setTimeout(() => {
            this.props.history.push('/login');
          }, 3000);
        }
      });
    }
  };

  handleDragAndDrop = (file) => {
    this.setState({
      fields: {
        ...this.state.fields,
        file
      }
    });
  };

  render() {
    return (
      <DocUpload 
        onSubmit={this.onSubmit} 
        onChange={this.onChange} 
        submit={this.state.submit} 
        fieldsErrors={this.state.fieldsErrors} 
        type={this.state.type} 
        fields={this.state.fields}
        handleDragAndDrop={this.handleDragAndDrop}
      />
    );
  }
}

export default DocUploadContainer;