import React from 'react';
import { Dialog, Grid } from '@mui/material';
import styles from 'custom/style.module.css';
import { CustomButton, CustomDialogTitle, CustomDialogAction, CustomDialogContent } from 'custom/components';
import { CloseIconBtn } from 'custom/constants';

const DuplicateAwbModal = (props) => {
  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={props.modal === 'awb'} onClose={props.onClose}>
        <CustomDialogTitle id='form-dialog-title'>
          Multiple airwaybills found
          <CloseIconBtn onClick={props.onClose} />
        </CustomDialogTitle>
        <CustomDialogContent sx={{ width: 600 }}>
          Please select the one you wish to view
          <Grid container spacing={3}>
            {
              props?.modal_data?.awb_conflict?.map((awb, index) => {
                return (<Grid item xs={12} key={index}><input type='radio' name='awb' value={awb.id} checked={awb.id == props.awb_value} onClick={() => props.onChange({
                  target: {
                    name: 'awb',
                    value: awb.id 
                  } 
                })} /> {awb.code}-{awb.number}  {awb.org} - {awb.dest}  {awb.pcs}PCS  {awb.wgt}KG</Grid>);
              })
            }
          </Grid>
        </CustomDialogContent>
        <CustomDialogAction className={`${styles.fSpaceBtw}`}>
          <CustomButton variant='contained' onClick={props.onClose}>Close</CustomButton>
          <CustomButton variant='contained' type='submit' disabled={!props.awb_value} onClick={() => props.onChange({ target: { name: 'sub_awb' } }, null, props.modal_data.id)}>Update</CustomButton>
        </CustomDialogAction>
      </Dialog>
    </div>
  );
};
export default DuplicateAwbModal;