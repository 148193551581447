import React from 'react';
import {
  Dialog, MenuItem,
  FormControl, FormHelperText,
  Grid,
} from '@mui/material';
import styles from '../../../../../custom/style.module.css';
import { CustomButton, CustomDialogTitle, CustomDialogAction, StyledCard, CustomTextField, CustomInputLabel, CustomSelect, InputProps, CustomDialogContent, SelectInputProps, CustomStyle, CustomAutocomplete } from '../../../../../custom/components';
import { CloseIconBtn, uldTypes, handledAs } from '../../../../../custom/constants';
import Select, { components } from 'react-select';
import { ArrowDropDown } from '@mui/icons-material';
import cookies from 'utils/cookies';
import discrepancyStyles from './discrepancy.module.css';

const disablefields = cookies.get('role') === 'Airline Rep' ? true : false;
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDown />
    </components.DropdownIndicator>
  );
};
const CustomStyleProd = {
  ...CustomStyle,
  menuList: (base) => ({
    ...base,
    maxHeight: '150px',
  }),

};

const AddDiscrepancyModal = (props) => {

  const [isFirstULDRender, setIsFirstULDRender] = React.useState(true);
  const [isFirstAWBRender, setIsFirstAWBRender] = React.useState(true);

  const type = [
    'Missing Cargo',
    'Found Cargo',
    'Missing Airwaybill',
    'Found Airwaybill',
    'Short Shipped',
    'Over Carried',
    'Damaged Cargo',
    'Other'
  ];

  const getType = (id) => {
    if (id) {
      let value = null;
      type && type.map((m) => {
        if (m == props.discrepancyFields.type) {
          value = {
            label: m,
            valueL: m
          };
        }
      });
      return value;
    } else {
      return null;
    }
  };

  const getProduct = (name) => {
    if (name) {
      let value = null;
      props.productsList && props.productsList.map((m) => {
        if (m.name == props.discrepancyFields.prod_code) {
          value = {
            label: m.name,
            value: m.name,
            value2: m.name
          };
        }
      });
      return value;
    } else {
      return null;
    }
  };


  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={props.modal == 'discrepancy'}
        onClose={() => {
          props.onClose();
          setIsFirstULDRender(true);
          setIsFirstAWBRender(true);
        }}
      >
        <CustomDialogTitle id='form-dialog-title' className={styles.modalTitle}>
          {props.action === 'edit' ? 'Edit' : 'Add'} Discrepancy
          <CloseIconBtn onClick={() => {
            props.onClose();
            setIsFirstULDRender(true);
            setIsFirstAWBRender(true);
          }}
          />
        </CustomDialogTitle>
        <form className={styles.w100} onReset={props.onClose}>
          <CustomDialogContent>
            <StyledCard>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
                  <CustomInputLabel id='type-label' aria-label='Enter type'>Type</CustomInputLabel>
                  <FormControl variant='standard' fullWidth error={props.discrepancyErrors.type}>
                    <Select
                      className='basic-single'
                      classNamePrefix='select'
                      onChange={(selectedOption) => {
                        props.onChange({
                          target: {
                            name: 'type',
                            value: selectedOption.value
                          }
                        });
                      }}
                      name='measureId'
                      value={getType(props.discrepancyFields.type)}
                      options={type && type.map(m => ({
                        value: m,
                        label: m
                      }))}
                      components={{ DropdownIndicator }}
                      styles={CustomStyle}
                    />
                    <FormHelperText>{props.discrepancyErrors.type}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
                  <CustomInputLabel id='type-label' aria-label='Enter uld_no'>ULD No.</CustomInputLabel>
                  <FormControl variant='standard' fullWidth error={props.discrepancyErrors.uld_no}>
                    <CustomAutocomplete
                      disablePortal
                      id='uld_no'
                      name='uld_no'
                      freeSolo
                      options={props.getULDData(props?.discrepancyFields?.uld_no) || []}
                      getOptionLabel={(option) => option.uld_no}
                      onInputChange={(_, newValue) => {
                        if (isFirstULDRender && props.action === 'edit') {
                          setIsFirstULDRender(false);
                        } else {
                          props.onULDChange(newValue.toUpperCase());
                        }
                      }}
                      onChange={(_, value) => props.onULDChange(value?.uld_no.toUpperCase() || '')}
                      value={{ uld_no: props.discrepancyFields.uld_no }}
                      renderInput={(params) =>
                        <CustomTextField
                          {...InputProps}
                          {...params}
                          placeholder='ULD No.'
                        />}
                      fullWidth
                    />
                    <FormHelperText>{props.discrepancyErrors.uld_no}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
                  <CustomInputLabel id='type-label' aria-label='Enter ULD Type'>ULD Type</CustomInputLabel>
                  <FormControl variant='standard' fullWidth error={props.discrepancyErrors.uld_type}>
                    <CustomSelect
                      {...SelectInputProps}
                      id='uld_type'
                      name='uld_type'
                      classes={{ input: discrepancyStyles.smSelect }}
                      value={props.discrepancyFields.uld_type}
                      onChange={props.onChange}
                      error={props.discrepancyErrors.uld_type}
                      helperText={props.discrepancyErrors.uld_type}
                      disabled={disablefields || !props.discrepancyFields.uld_no || props.discrepancyFields?.uld_no == 'BULK'}
                    >
                      {
                        uldTypes.map((item) => (
                          <MenuItem key={item.id} id={item.id} value={item.value}>{item.value}</MenuItem>
                        ))
                      }
                    </CustomSelect>
                    <FormHelperText>{props.discrepancyErrors.uld_type}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
                  <CustomInputLabel id='type-label' aria-label='Loose/Intact'>Handled as</CustomInputLabel>
                  <FormControl variant='standard' fullWidth error={props.discrepancyErrors.handled_as}>
                    <CustomSelect
                      {...SelectInputProps}
                      id='handled_as'
                      name='handled_as'
                      classes={{ input: discrepancyStyles.smSelect }}
                      value={props.discrepancyFields.handled_as}
                      onChange={props.onChange}
                      error={props.discrepancyErrors.handled_as}
                      helperText={props.discrepancyErrors.handled_as}
                      disabled={disablefields}
                    >
                      {
                        handledAs.map((item) => (
                          <MenuItem key={item.id} id={item.id} value={item.value}>{item.value}</MenuItem>
                        ))
                      }
                    </CustomSelect>
                    <FormHelperText>{props.discrepancyErrors.handled_as}</FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
                  <CustomInputLabel htmlFor='awb_no' aria-label='Enter airwaybill'>Airwaybill</CustomInputLabel>
                  {/* <CustomTextField
                    {...InputProps}
                    id='awb_no'
                    fullWidth
                    autoFocus
                    placeholder=''
                    type='text'
                    name='awb_no'
                    value={props.discrepancyFields.awb_no}
                    onChange={props.onChange}
                    error={props.discrepancyErrors.awb_no}
                    helperText={props.discrepancyErrors.awb_no}
                    disabled={disablefields}
                  /> */}
                  <CustomAutocomplete
                    disablePortal
                    id='awb_no'
                    name='awb_no'
                    freeSolo
                    options={props.getULDData(props.discrepancyFields.uld_no)[0]?.flight_awbs || []}
                    getOptionLabel={(option) => option.awb_no}
                    onInputChange={(_, newValue) => {
                      if (isFirstAWBRender && props.action === 'edit') {
                        setIsFirstAWBRender(false);
                      } else {
                        props.onAWBChange(newValue.toUpperCase());
                      }
                    }}
                    onChange={(_, value) => props.onAWBChange(value?.awb_no.toUpperCase() || '')}
                    value={{ awb_no: props.discrepancyFields.awb_no }}
                    renderInput={(params) =>
                      <CustomTextField
                        {...InputProps}
                        {...params}
                        placeholder='AWB No.'
                      />}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
                  <CustomInputLabel id='type-label' aria-label='Enter type'>Prod Code</CustomInputLabel>
                  <FormControl variant='standard' fullWidth error={props.discrepancyErrors.prod_code}>
                    <Select
                      className='basic-single'
                      classNamePrefix='select'
                      onChange={(selectedOption) => {
                        props.onChange({
                          target: {
                            name: 'prod_code',
                            value: selectedOption.value,
                            value2: selectedOption.value2,
                            id: selectedOption.id
                          }
                        });
                      }}
                      name='prod_code'
                      value={getProduct(props.discrepancyFields.prod_code)}
                      options={props.productsList && props.productsList.map(p => ({
                        value: p.name,
                        label: p.name,
                        value2: p.name,
                        id: p.id
                      }))}
                      components={{ DropdownIndicator }}
                      styles={CustomStyleProd}
                    />
                    <FormHelperText>{props.discrepancyErrors.prod_code}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <CustomInputLabel htmlFor='org' aria-label='Enter Origin'>Origin</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='org'
                    fullWidth
                    onChange={props.onChange}
                    name='org'
                    value={props.discrepancyFields.org}
                    error={props.discrepancyErrors.org}
                    helperText={props.discrepancyErrors.org}
                    disabled={disablefields}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <CustomInputLabel htmlFor='dest' aria-label='Enter destination'>Dest</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='dest'
                    fullWidth
                    onChange={props.onChange}
                    name='dest'
                    value={props.discrepancyFields.dest}
                    error={props.discrepancyErrors.dest}
                    helperText={props.discrepancyErrors.dest}
                    disabled={disablefields}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <CustomInputLabel htmlFor='t_pcs' aria-label='Enter Total pcs '>Total Pcs</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='t_pcs'
                    fullWidth
                    autoFocus
                    placeholder=''
                    type='text'
                    name='t_pcs'
                    onChange={props.onChange}
                    value={props.discrepancyFields.t_pcs}
                    error={props.discrepancyErrors.t_pcs}
                    helperText={props.discrepancyErrors.t_pcs}
                    disabled={disablefields}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <CustomInputLabel htmlFor='t_wgt' aria-label='Enter total wgt'>Total Weight</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='t_wgt'
                    fullWidth
                    autoFocus
                    placeholder=''
                    type='text'
                    name='t_wgt'
                    onChange={props.onChange}
                    value={props.discrepancyFields.t_wgt}
                    error={props.discrepancyErrors.t_wgt}
                    helperText={props.discrepancyErrors.t_wgt}
                    disabled={disablefields}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <CustomInputLabel htmlFor='d_pcs' aria-label='Enter Discrepancy pieces'>Dscpy Pcs</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='d_pcs'
                    fullWidth
                    autoFocus
                    placeholder=''
                    type='text'
                    name='d_pcs'
                    onChange={props.onChange}
                    value={props.discrepancyFields.d_pcs}
                    error={props.discrepancyErrors.d_pcs}
                    helperText={props.discrepancyErrors.d_pcs}
                    disabled={disablefields}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <CustomInputLabel htmlFor='d_wgt' aria-label='Enter Discrepancy wgt'>Dscpy Wgt</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='d_wgt'
                    fullWidth
                    autoFocus
                    placeholder=''
                    type='text'
                    name='d_wgt'
                    onChange={props.onChange}
                    value={props.discrepancyFields.d_wgt}
                    error={props.discrepancyErrors.d_wgt}
                    helperText={props.discrepancyErrors.d_wgt}
                    disabled={disablefields}
                  />
                </Grid>
                <Grid item xs>
                  <CustomInputLabel htmlFor='remark' aria-label='Enter Remarks'>Remarks</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='remark'
                    fullWidth
                    autoFocus
                    placeholder=''
                    type='text'
                    name='remark'
                    onChange={props.onChange}
                    value={props.discrepancyFields.remark}
                    error={props.discrepancyErrors.remark}
                    helperText={props.discrepancyErrors.remark}
                    disabled={disablefields}
                  />
                </Grid>
              </Grid>
            </StyledCard>
          </CustomDialogContent>
          <CustomDialogAction className={styles.fSpaceBtw}>
            <CustomButton variant='contained' type='reset'> Close</CustomButton>
            <CustomButton variant='contained' type='button' onClick={() => props.addEditDiscrepancy()} disabled={disablefields || props.loading} > {props.action == 'edit' ? 'Update' : 'Submit'}</CustomButton>
          </CustomDialogAction>
        </form>
      </Dialog>
    </div>
  );
};
export default AddDiscrepancyModal;