import { SET_FLIGHT } from './actionTypes';

function setFlight(data) {
  return {
    type: SET_FLIGHT,
    data
  };
}

function setFlightAction(data) {
  return (dispatch) => {
    dispatch(setFlight(data));
  };
}


export { setFlightAction };